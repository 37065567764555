import {
	OrderBaseChildRequest,
	OrderBaseChildrenRequest,
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderProductRequest,
	OrderProductsRequest,
	OrderType,
	ShippingType,
	StorageType,
} from 'common';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { OrderTypeConfig } from './orderTypeConfig';
import { doCreateOrder } from '@store/order';
import { PayloadAction } from '@reduxjs/toolkit';
import { InternalOrderHeader } from '../header/internal/InternalOrderHeader';
import { InternalSuborderRow } from '../suborder/rows/internal/InternalSuborderRow';
import { InternalSuborderProductsContainer } from '../suborder/rows/internal/InternalSuborderProductsContainer';

export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(true),
		fromType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		shippingType: new OrderElementConfigItem(
			(directionType: OrderDirectionType, storageType?: StorageType) => {
				switch (directionType) {
					case OrderDirectionType.warehouse:
						if (storageType) {
							switch (storageType) {
								case StorageType.office:
								case StorageType.subStorage:
								case StorageType.trash:
								case StorageType.processing:
									return [ShippingType.handDelivered];
								case StorageType.store:
								case StorageType.external:
									return [ShippingType.internalCarrier, ShippingType.externalCarrier];
								default:
									return null;
							}
						} else
							return [
								ShippingType.externalCarrier,
								ShippingType.internalCarrier,
								ShippingType.handDelivered,
							];
					default:
						return null;
				}
			},
		),
		toType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		toSubType: new OrderElementConfigItem(
			(directionType: OrderDirectionType, shippingType: ShippingType): StorageType[] | null => {
				switch (directionType) {
					case OrderDirectionType.warehouse:
						switch (shippingType) {
							case ShippingType.handDelivered:
								return [
									StorageType.main,
									StorageType.office,
									StorageType.subStorage,
									StorageType.trash,
									StorageType.processing,
								];
							case ShippingType.internalCarrier:
							case ShippingType.externalCarrier:
								return [StorageType.main, StorageType.store, StorageType.external];
							default:
								return null;
						}
					default:
						return null;
				}
			},
		),
		toWarehouseType: new OrderElementConfigItem(true),
		subOrderTypes: new OrderElementConfigItem(() => {
			return [
				StorageType.office,
				StorageType.subStorage,
				StorageType.trash,
				StorageType.processing,
			];
		}),
	},
	headerElement: InternalOrderHeader,
	maxSubOrders: 1,
	subOrderRow: InternalSuborderRow,
	subOrderProductsContainer: InternalSuborderProductsContainer,
	isAddSuborderDisabled: (order, subOrders) => {
		if (!order.fromId || !order.toId) {
			return true;
		}
		return subOrders.some((so) => !so.content || so.content?.length <= 0);
	},
	isCreateOrderDisabled: () => false,
	executeOrderAction: async (data) => {
		const tempOrderProducts: OrderProductsRequest = [];
		const request = { ...data.baseRequest };

		const newChildren: OrderBaseRequest[] = [];
		if (data.subOrders) {
			data.subOrders.map((so) => {
				const tempInnerOrderProducts: OrderProductsRequest = [];
				so.content?.map((op) => {
					if (op.orderQty === undefined || op.orderQty === null || op.orderQty === 0) {
						return;
					}

					let sourceOp: OrderProductRequest | undefined;

					if (data.sources && op.sourceId) {
						sourceOp = data.sources[Number(op.sourceId)];
					}

					console.log(sourceOp);
					console.log(op);

					const newOrderProduct: OrderProductRequest = {
						...op,
						actualPrice: sourceOp?.actualPrice,
						importTaxes: sourceOp?.importTaxes,
						transportFees: sourceOp?.transportFees,
						prevOrderId: op.orderId ? +op.orderId : null,
						orderId: undefined,
					};
					tempOrderProducts.push(newOrderProduct);
					tempInnerOrderProducts.push(newOrderProduct);
				});
			});
		}

		request.content = tempOrderProducts;
		request.children = newChildren;
		request.orderType = OrderType.Internal;

		const validatedData = OrderBaseCreateSchema.safeParse(request);
		if (!validatedData.success) {
			return Promise.reject(validatedData.error);
		}

		console.log(validatedData.data);

		// return Promise.reject('Not implemented');

		const internalOrderResponse = (await data.dispatch(
			doCreateOrder(validatedData.data),
		)) as PayloadAction<OrderBaseResponse>;

		if (internalOrderResponse.type !== 'orders/create/fulfilled') {
			return Promise.reject('Internal order creation failed');
		}
		return internalOrderResponse.payload;
	},
};
