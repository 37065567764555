import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import useProducts from '../../hooks/useProducts';
import useMerchants from '../../hooks/useMerchants';
import usePrices from '../../hooks/usePrices';

import { Grid } from '@mui/material';
import { NewInputGroup, Option } from '../NewInputGroup';
import { ChildSettings } from '../NewInputGroupItem';
import { ChildType } from '../NewInputGroupItemChildren';
import {
	AppFunction,
	BuyingPricesRequest,
	MerchantType,
	TranslationTypes,
	VariantsResponse,
} from 'common';

import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { Check } from '@mui/icons-material';
import _ from 'lodash';
import { TabInnerTitle } from '../tabs/TabInnerTitle';
import useFeedbacks from '../../hooks/useFeedbacks';

interface Props {
	variantId: number;
}

export const InputBuyingPricesGroup = (props: Props) => {
	const { productList } = useProducts();
	const { merchantList } = useMerchants();
	const { editBuyingPrices, deleteBuyingPrices } = usePrices();
	const { showSnackBar } = useFeedbacks();

	const currencies = useAppSelector((state) => state.products.currencies);
	const [options, setOptions] = useState<Option[]>([]);
	const [editedOptions, setEditedOptions] = useState<Option[]>([]);
	const [dirty, setDirty] = useState(false);

	const childrenSettings: ChildSettings[] = [
		{
			childType: ChildType.NumberField,
			label: 'product.price',
			size: 8,
		},
		{
			childType: ChildType.SelectField,
			label: 'general.currency',
			size: 4,
			selectionItems: currencies.map((x) => x.currency),
		},
	];

	const onRetrieveValues = (newOptions: Option[]) => {
		setEditedOptions(newOptions);
	};

	const onEdit = async () => {
		const originalEntries = options.filter((entry) => {
			if (entry.inputs) {
				const inputs = entry.inputs.filter((y) => y.defaultValue != undefined);
				if (inputs.length > 0) return inputs;
			}
		});

		const originalEntriesIndexes = originalEntries.map((x) => x.selectionId);
		const editedEntriesIndexes = editedOptions.map((x) => x.selectionId);
		const deleted = originalEntriesIndexes.filter((x) => !editedEntriesIndexes.includes(x));

		const deleteRequestParams: number[] = [];
		const variantCollection: VariantsResponse = [];
		productList.map((pr) => {
			pr.variants?.map((vr) => variantCollection.push(vr));
		});
		const prices = variantCollection.map((vr) => vr.merchantBuyingPrices);
		prices.map((pArray) => {
			pArray?.map((x) => {
				if (deleted.includes(x.merchantId) && x.id) {
					deleteRequestParams.push(x.id);
				}
			});
		});
		if (deleteRequestParams.length > 0) {
			await deleteBuyingPrices({ ids: deleteRequestParams });
		}

		const createRequestParams: BuyingPricesRequest = [];
		editedOptions.map((op) => {
			if (op.inputs) {
				const price = op.inputs[0].value ? +op.inputs[0].value : 0;
				let currency = 2;
				if (op.inputs != undefined && op.inputs.length > 1) {
					const compareValue = op.inputs[1] ?? 'Euro';
					currency = op.inputs[1].value
						? currencies.find((x) => x.currency === compareValue.value)?.id ?? 5
						: 2;
				}
				createRequestParams.push({
					price: price,
					currencyId: currency,
					variantId: props.variantId,
					merchantId: op.selectionId,
				});
			}
		});

		if (createRequestParams.length > 0) {
			await editBuyingPrices(createRequestParams);
			showSnackBar({ message: '値段は正しく保存されました', severity: 'success' });
		}
	};

	useEffect(() => {
		let equal = false;
		equal = options.every((op, index) => {
			if (editedOptions[index]) {
				return _.isEqual(op.inputs, editedOptions[index].inputs);
			}
			return false;
		});
		setDirty(!equal);
	}, [editedOptions]);

	useEffect(() => {
		const product = productList.find((x) => x.variants?.find((y) => y.id === props.variantId));
		const variant = product?.variants?.find((x) => x.id === props.variantId);
		const newOptions: Option[] = [];

		childrenSettings[0].label = variant?.variable ? 'price per grams' : 'price';

		merchantList.map((merchant) => {
			const buyingPrice = variant?.merchantBuyingPrices?.find((x) => x.merchantId === merchant.id);
			if (merchant.type !== MerchantType.maker) {
				newOptions.push({
					selectionId: merchant.id,
					selectionName: merchant.id.toString(),
					inputs: [
						{ defaultValue: buyingPrice?.price, value: buyingPrice?.price },
						{
							defaultValue: currencies.find((x) => x.id === buyingPrice?.currencyId)?.currency,
							value: currencies.find((x) => x.id === buyingPrice?.currencyId)?.currency,
						},
					],
				});
			}
		});
		setOptions([...newOptions].sort());
	}, [productList, merchantList, props.variantId]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='product.merchantPrices' translate size='small' />
				<NewInputGroup
					options={options}
					optionsLabel={'merchant.name'}
					childrenSettings={childrenSettings}
					onRetrieveValues={onRetrieveValues}
					autocomplete
					translate={{ category: AppFunction.Merchant, type: TranslationTypes.name }}
				/>
			</Grid>
			<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
				<AnimatedIconButton
					text='operations.save'
					translate
					disabled={!dirty}
					icon={<Check />}
					onClick={onEdit}
				/>
			</Grid>
		</Grid>
	);
};
