import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	text: string;
	dialogOpen: boolean;
	onClose: () => void;
	onAccept: () => void;
	confirmButtonText?: string;
	cancelButtonText?: string;
}

export const DeleteItemDialog = (props: Props) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={props.dialogOpen}
			onClose={props.onClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>{props.text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.onAccept();
						props.onClose();
					}}
				>
					{props.confirmButtonText ?? t('dialog.ok')}
				</Button>
				<Button
					onClick={() => {
						props.onClose();
					}}
					autoFocus
				>
					{props.cancelButtonText ?? t('dialog.cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
