import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useTask } from '@contexts/index';

import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';

import { Grid, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { SubItemProps } from './ImportChildItem';

import { Edit, Restore } from '@mui/icons-material';
import { TaskStatus } from 'common';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface ImportChildMainRowProps extends SubItemProps {
	locked: boolean;
}

export const ImportChildMainRow = ({
	content,
	value,
	iterator,
	locked,
	variable,
	updateField,
	handleQuantityChange,
}: ImportChildMainRowProps) => {
	const { taskState } = useTask();
	const { t } = useTranslation();

	const canEdit = taskState === TaskStatus.inprogress || taskState === TaskStatus.stub;

	const orderProductId = getParsedId(content.id);
	if (!orderProductId) {
		console.error('Invalid order product ID');
		return null;
	}

	return (
		<Grid container columnSpacing={1} item>
			<Grid item flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
				<InputGridField type={ComponentType.TextField}>
					<TextField
						label={t('product.arrivedQuantity')}
						size='small'
						type='number'
						disabled={!canEdit || value.notArrived}
						InputProps={{
							inputProps: { min: 0, max: locked ? content.orderQty : undefined },
						}}
						fullWidth
						value={value.newValues[iterator].arrivedQty ?? content.orderQty}
						onChange={(e) => handleQuantityChange(parseInt(e.target.value), iterator)}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
			</Grid>
			{variable && (
				<InputGridField flexGrow={1} type={ComponentType.TextField}>
					<TextField
						label={t('product.weight')}
						size='small'
						type='number'
						disabled={!canEdit || value.notArrived}
						InputProps={{
							inputProps: { min: 0 },
						}}
						fullWidth
						value={value.newValues[iterator].weight ?? content.weight}
						onChange={(e) =>
							updateField(orderProductId, 'weight', parseInt(e.target.value), iterator)
						}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
			)}
			<Grid item flexGrow={1} sx={{ display: 'flex', justifyContent: 'right' }}>
				<InputGridField type={ComponentType.TextField}>
					<DatePicker
						value={value.newValues[iterator].expiresAt?.startOf('day') ?? null}
						onChange={(newValue) =>
							updateField(
								orderProductId,
								'expiresAt',
								newValue ? newValue.startOf('day') : moment().startOf('day'),
								iterator,
							)
						}
						disablePast
						disabled={!canEdit || value.notArrived}
						renderInput={(params) => (
							<TextField size='small' fullWidth {...params} sx={{ backgroundColor: 'white' }} />
						)}
						// TODO: we'll have to work with zIndexes later on
						PopperProps={{
							style: { zIndex: 9999 },
						}}
					/>
				</InputGridField>
			</Grid>
			<Grid item>
				<IconButton
					disabled={
						(taskState !== 'in_progress' && taskState !== 'stub') || value.newValues.length > 1
					}
					onClick={() => updateField(orderProductId, 'add', 0, iterator, variable)}
				>
					<Edit />
				</IconButton>
				<IconButton
					disabled={taskState !== 'in_progress' && taskState !== 'stub'}
					onClick={() => updateField(orderProductId, 'restore', 0, iterator)}
				>
					<Restore />
				</IconButton>
			</Grid>
		</Grid>
	);
};
