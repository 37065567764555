import { Theme } from '@emotion/react';
import useLocale from '@hooks/useLocale';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AutoCompleteOptionProps {
	id: number | string;
}

interface AdvancedAutoCompleteProps<T extends AutoCompleteOptionProps> {
	options: T[];
	disabled?: boolean;
	appFunction: AppFunction;
	value: number;
	sx?: SxProps<Theme>;
	onChange: (value: number | ChangeEvent<Element>) => void;
	onCustomOptionLabel?: (option: T) => string;
}

const AdvancedAutoComplete = <T extends AutoCompleteOptionProps>({
	options,
	disabled,
	appFunction,
	value,
	sx,
	onChange,
	onCustomOptionLabel,
}: AdvancedAutoCompleteProps<T>) => {
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				if (disabled) return;
				return setOpen(true);
			}}
			onClose={() => setOpen(false)}
			options={options}
			size='small'
			value={options.find((option) => option.id === value) || null}
			getOptionLabel={(option) =>
				onCustomOptionLabel?.(option) ??
				getTranslatedString(appFunction, option.id, TranslationTypes.name)
			}
			onChange={(_, newValue) => onChange(newValue?.id ? +newValue.id : -1)}
			selectOnFocus={false}
			renderInput={(params) => (
				<TextField
					{...params}
					variant='outlined'
					label={t('carrier.price.origin')}
					sx={{
						userSelect: disabled ? 'none' : 'auto',
					}}
					inputProps={{
						...params.inputProps,
						...(disabled && { tabIndex: -1 }),
					}}
				/>
			)}
			openOnFocus={false}
			sx={sx}
		/>
	);
};

AdvancedAutoComplete.displayName = 'AdvancedAutocomplete';
export default AdvancedAutoComplete;
