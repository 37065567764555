import { OrderType, WarehouseProductCutItems } from 'common';
import { Moment } from 'moment';

import { InboundCloseOrderFieldContainer } from '../modules/inbound/InboundCloseOrderFieldContainer';
import { InternalCloseOrderFieldContainer } from '../modules/internal/InternalCloseOrderFieldContainer';
import { OutboundCloseOrderFieldContainer } from '../modules/outbound/OutboundCloseOrderFieldContainer';
import { ProcessingCloseOrderFieldContainer } from '../modules/processing/ProcessingCloseOrderFieldContainer';

import { OrderTypeConfigMap } from '@components/taskworkflow/tasks/config';

export interface CloseOrderFieldItemValue {
	variantId: number;
	arrivedQty: number;
	weight: number;
	expiresAt: Moment | null;
	sourceId: number | null;
	estimated: boolean;
	actualExpiration?: Moment | null;
}

export interface CloseOrderValue {
	oldValues: CloseOrderFieldItemValue;
	newValues: CloseOrderFieldItemValue[];
	notArrived?: boolean;
	returnValue?: CloseOrderFieldItemValue;
	inspectionValue?: CloseOrderFieldItemValue;
}

export type CloseOrderValueMap = { [key: number]: CloseOrderValue };

export type CloseProcessingOrderValueMap = { [key: number]: WarehouseProductCutItems };

export type CloseOrderFieldAction =
	| 'notArrived'
	| 'addReturn'
	| 'removeReturn'
	| 'addInspection'
	| 'removeInspection'
	| 'expiresAt'
	| 'arrivedQty'
	| 'weight'
	| 'estimated'
	| 'actualExpiration'
	| 'delete'
	| 'restore'
	| 'add';

export const config: OrderTypeConfigMap = {
	[OrderType.Inbound]: {
		containerComponent: InboundCloseOrderFieldContainer,
	},
	[OrderType.Internal]: {
		containerComponent: InternalCloseOrderFieldContainer,
	},
	[OrderType.Outbound]: {
		containerComponent: OutboundCloseOrderFieldContainer,
	},
	[OrderType.Processing]: {
		containerComponent: ProcessingCloseOrderFieldContainer,
	},
};
