import { Chip } from '@mui/material';
import { AppFunction, OrderStatus } from 'common';
import { useTranslation } from 'react-i18next';

interface TableOrderStatusBodyProps {
	status: OrderStatus;
}

export const TableOrderStatusBody = <T extends TableOrderStatusBodyProps>({ status }: T) => {
	const { t } = useTranslation();

	let color: 'default' | 'error' | 'warning' | 'info' | 'success' = 'default';
	switch (status) {
		case OrderStatus.Done:
			color = 'success';
			break;
		case OrderStatus.Pending:
			color = 'warning';
			break;
		case OrderStatus.Booked:
			color = 'error';
			break;
	}
	return (
		<Chip
			size='small'
			color={color}
			label={t(`${AppFunction.Order}.statuses.${status?.toLowerCase()}`)}
			sx={{ width: '100%' }}
		/>
	);
};
