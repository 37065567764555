import { Breadcrumbs, Chip, ChipProps, emphasize, keyframes, styled } from '@mui/material';
import { useLayout } from '@contexts/layoutContext/LayoutContext';
import { BreadCrumb } from '@contexts/layoutContext/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';
import { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface StyledBreadcrumbProps extends ChipProps {
	isButton?: boolean;
	animate?: 'add' | 'delete' | 'change' | 'transition';
	startWidth?: number;
	animIndex: number;
}

const addChip = keyframes`
    from {
        transform: translateX(-20px);
        opacity: 0;
        visibility: visible;
    }
    to {
        opacity: translateX(0);
        opacity: 1;
    }
`;

const deleteChip = keyframes`
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-50px);
        opacity: 0;
        visibility: hidden;
    }
`;

const changeChip = keyframes`
  0% {
    transform: scaleX(1) rotateX(0deg);
  }
  50% {
    transform: scaleX(1.1) rotateX(90deg);
  }
  100% {
    transform: scaleX(1) rotateX(0deg);
  }
`;

const transition = keyframes`
    from {
        transform: scaleX(1) rotateX(0deg);
    }
    to {
        transform: scaleX(1.1) rotateX(90deg);
    }
`;

const createWidthChangeKeyframes = (startWidth: number) => {
	return `
        @keyframes widthChange-${startWidth} {
            from {
                width: ${startWidth}px;
            }
            to {
                width: auto;
            }
        }
    `;
};

const animSpeed = 0.2;
const delaySpeed = 0.2;

const StyledBreadcrumb = styled(Chip, {
	shouldForwardProp: (prop) =>
		prop !== 'isButton' && prop !== 'animate' && prop !== 'animIndex' && prop !== 'startWidth',
})<StyledBreadcrumbProps>(({ theme, isButton, animate, animIndex, startWidth }) => {
	const backgroundColor =
		theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800];
	let animationStyle;
	let animStartConditions;
	switch (animate) {
		case 'add':
			animationStyle = {
				animation: `${addChip} ${animSpeed}s ease-in-out forwards ${animIndex * delaySpeed}s`,
			};
			animStartConditions = { opacity: 0 };
			break;
		case 'delete':
			animationStyle = {
				animation: `${deleteChip} ${animSpeed}s ease-in-out both ${animIndex * delaySpeed}s`,
			};
			animStartConditions = { opacity: 1 };
			break;
		case 'change':
			animationStyle = {
				animation: `${changeChip} ${animSpeed}s ease-in-out, widthChange-${startWidth} ${animSpeed}s ease-in-out`,
			};
			break;
		case 'transition':
			animationStyle = { animation: `${transition} ${animSpeed / 2}s ease-in-out` };
			break;
	}

	return {
		backgroundColor,
		height: theme.spacing(3),
		color: theme.palette.text.primary,
		// fontSize: '1rem',
		fontWeight: theme.typography.fontWeightRegular,
		...(animStartConditions && animStartConditions),
		...(animationStyle && animationStyle),
		transition: 'width 450ms ease',
		pointerEvents: isButton ? 'auto' : 'none',
		...(isButton && {
			'&:hover, &:focus': {
				backgroundColor: emphasize(backgroundColor, 0.06),
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(backgroundColor, 0.12),
			},
		}),
	};
});

const createDuplicateChipStyles = (originalChip: HTMLElement) => {
	const rect = originalChip.getBoundingClientRect();
	return {
		position: 'fixed',
		top: `${rect.top}px`,
		left: `${rect.left}px`,
		width: `${rect.width}px`,
		zIndex: 3000,
	};
};

export const BreadCrumbsHandler = () => {
	const { breadCrumbs, registerElement } = useLayout();
	const { t, i18n } = useTranslation();
	const [breadCrumbElements, setBreadCrumbElements] = useState<
		{ element: ReactNode; id: string }[]
	>([]);
	const [oldBreadCrumbs, setOldBreadCrumbs] = useState<BreadCrumb[]>([]);
	const [oldLanguage, setOldLanguage] = useState<string>('');
	const navigate = useNavigate();
	const location = useLocation();

	const handleAnimationEnd = (id: string) => {
		setBreadCrumbElements((oldElements) => oldElements.filter((oe) => oe && oe.id !== id));
	};

	useEffect(() => {
		registerElement('breadCrumbsHandler', 'top', 50);
	}, []);

	useEffect(() => {
		if (_.isEqual(oldBreadCrumbs, breadCrumbs) && oldLanguage === i18n.language) {
			return;
		}

		const newElements: { element: ReactNode; id: string }[] = [];
		let persisting = 0;
		let startWidth: number | undefined;
		breadCrumbs.forEach((breadCrumb, idx) => {
			const oldBreadCrumb = oldBreadCrumbs[idx];
			let animation: 'add' | 'delete' | 'change' | undefined;

			if (!oldBreadCrumb) {
				animation = 'add';
			} else if (breadCrumb.label !== oldBreadCrumb.label) {
				animation = 'change';
				const originalChip = document.getElementById(`${idx}-${oldBreadCrumb.label}`);
				if (originalChip) {
					const duplicateStyles = createDuplicateChipStyles(originalChip);
					const duplicateChipElement = (
						<StyledBreadcrumb
							key={`duplicate-${idx}-${oldBreadCrumb.label}`}
							sx={{ ...duplicateStyles }}
							animIndex={0}
							onAnimationEnd={() => handleAnimationEnd(`duplicate-${idx}-${oldBreadCrumb.label}`)}
							label={t(oldBreadCrumb.label)}
							icon={oldBreadCrumb.icon}
							animate='transition'
						/>
					);

					const injectKeyframes = (keyframes: string) => {
						const styleSheet = document.createElement('style');
						styleSheet.innerText = keyframes;
						document.head.appendChild(styleSheet);
					};
					startWidth = parseInt(duplicateStyles.width);
					injectKeyframes(createWidthChangeKeyframes(startWidth));

					newElements.push({
						element: duplicateChipElement,
						id: `duplicate-${idx}-${oldBreadCrumb.label}`,
					});
				}
				persisting++;
			} else {
				persisting++;
			}

			const breadcrumbProps = {
				key: `${idx}-${breadCrumb.label}`,
				id: `${idx}-${breadCrumb.label}`,
				label: t(breadCrumb.label),
				icon: breadCrumb.icon,
				animate: animation,
				animIndex: _.clamp(idx - persisting, 100),
				isButton: breadCrumb.route || breadCrumb.callback ? true : false,
				...(startWidth && { startWidth: startWidth }),
				...((breadCrumb.route || breadCrumb.callback) && {
					onClick: () => {
						if (breadCrumb.route) {
							navigate(breadCrumb.route);
						}
						breadCrumb.callback?.();
					},
				}),
			};

			newElements.push({
				element: <StyledBreadcrumb {...breadcrumbProps} />,
				id: `${idx}-${breadCrumb.label}`,
			});
		});

		if (oldBreadCrumbs.length > breadCrumbs.length) {
			const deleteStartIdx = breadCrumbs.length;
			oldBreadCrumbs.slice(deleteStartIdx).forEach((obc, idx) => {
				const actualIdx = deleteStartIdx + idx;
				const breadcrumbsToDelete = oldBreadCrumbs.slice(deleteStartIdx);
				const totalToDelete = breadcrumbsToDelete.length;
				const reverseIdx = totalToDelete - idx - 1;
				newElements.push({
					element: (
						<StyledBreadcrumb
							key={`${actualIdx}-${obc.label}`}
							id={`${actualIdx}-${obc.label}`}
							animate='delete'
							label={t(oldBreadCrumbs[actualIdx].label)}
							icon={oldBreadCrumbs[actualIdx].icon}
							animIndex={reverseIdx}
							onAnimationEnd={() => handleAnimationEnd(`${actualIdx}-${obc.label}`)}
						/>
					),
					id: `${actualIdx}-${obc.label}`,
				});
			});
		}

		setOldLanguage(i18n.language);
		setOldBreadCrumbs(breadCrumbs);
		setBreadCrumbElements(newElements);
	}, [breadCrumbs, location.pathname, navigate, oldBreadCrumbs, i18n.language]);

	return (
		<div
			style={{
				height: '50px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				position: 'sticky',
				top: 0,
				zIndex: 8000,
				pointerEvents: 'none',
			}}
		>
			<div
				style={{
					width: '100%',
					height: '50px',
					zIndex: 7000,
					position: 'absolute',
					top: 0,
					maskImage:
						'linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 35%, rgba(0, 0, 0, 0) 100%)',
					backdropFilter: 'blur(10px)',
					pointerEvents: 'none',
				}}
			/>
			<div
				style={{
					width: '100%',
					height: '50px',
					paddingLeft: '20px',
					zIndex: 8000,
					display: 'flex',
					alignItems: 'center',
					pointerEvents: 'none',
				}}
			>
				{breadCrumbElements
					.filter((element) => element.id.includes('duplicate'))
					.map((breadCrumbElement) => breadCrumbElement.element)}
				<Breadcrumbs
					aria-label='breadcrumbs'
					separator={<NavigateNext fontSize='small' />}
					sx={{ width: 'auto' }}
				>
					{breadCrumbElements
						.filter((element) => !element.id.includes('duplicate'))
						.map((breadCrumbElement) => breadCrumbElement.element)}
				</Breadcrumbs>
			</div>
		</div>
	);
};
