import React, { createContext, Children, useContext, useState, useMemo, forwardRef } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { DialogComponentProps, DialogResult } from './types';

// Type definitions for dialog actions and dialog data
interface DialogAction {
	label: string;
	action?: () => void;
	icon?: JSX.Element;
	resolve?: boolean;
}

type DialogType = 'alert' | 'withActions' | 'confirm' | 'component';

interface DialogData {
	title?: string;
	type: DialogType;
	message?: string;
	actions?: DialogAction[];
	content?: React.ReactNode;
	fullScreen?: boolean;
	minWidth?: number;
	onClose?: (event: React.SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

interface DialogComponentData<T extends DialogResult, U> extends DialogData {
	component: React.FC<DialogComponentProps<T, U>>;
	type: 'component';
	data?: U;
}

interface FeedbacksContextType {
	pushDialog: (dialog: DialogData) => Promise<boolean>;
	pushComponentDialog: <T extends DialogResult, U>(
		dialogConfig: DialogComponentData<T, U>,
	) => Promise<T | false>;
	activeDialogBoundingRect: DOMRect | null;
}

const defaultContext: FeedbacksContextType = {
	pushDialog: () => Promise.reject('FeedbacksContext not initialized'),
	pushComponentDialog: () => Promise.reject('FeedbacksContext not initialized'),
	activeDialogBoundingRect: null,
};

// Create the context
const FeedbacksContext = createContext<FeedbacksContextType>(defaultContext);

// Transition component for the dialog animation
const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction='up' timeout={1500} ref={ref} {...props} />;
});

// Context provider component
export const FeedbacksProvider = ({ children }: { children: React.ReactNode }) => {
	const [dialogs, setDialogs] = useState<DialogData[]>([]);
	const [closeDialog, setCloseDialog] = useState<boolean>(false);
	// TODO: this is set only at the end of the transition, so it's not always accurate
	// we'll keep it for now, but we might need to attach an event to the window resize
	const [activeDialogBoundingRect, setActiveDialogBoundingRect] = useState<DOMRect | null>(null);
	const { t } = useTranslation();
	const theme = useTheme();

	const activeDialogRef = React.useRef<HTMLDivElement | null>(null);

	const pushDialog = (dialog: DialogData): Promise<boolean> => {
		return new Promise<boolean>((mainResolve) => {
			const handleDialogClose = (
				_: React.SyntheticEvent,
				reason: 'backdropClick' | 'escapeKeyDown',
			) => {
				if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
					mainResolve(false);
				}
				setCloseDialog(true);
			};

			let updatedActions: DialogAction[] = [];

			switch (dialog.type) {
				case 'alert':
					updatedActions = [
						{
							label: t('operations.close'),
							action: () => {
								mainResolve(true);
							},
						},
					];
					break;
				case 'confirm':
					updatedActions = [
						{
							label: t('dialog.cancel'),
							action: () => {
								mainResolve(false);
							},
						},
						{
							label: t('dialog.ok'),
							action: () => {
								mainResolve(true);
							},
						},
					];
					break;
				case 'withActions':
					updatedActions = dialog.actions?.map((action) => {
						return {
							...action,
							action: () => {
								action.action?.();
								mainResolve(action.resolve ?? true);
							},
						};
					}) as DialogAction[];
					break;
			}

			if (dialog.content) {
				console.log(Children.toArray(dialog.content));
			}

			setDialogs((prev) => [
				...prev,
				{
					...dialog,
					actions: updatedActions,
					onClose: handleDialogClose,
				},
			]);
		});
	};

	const pushComponentDialog = <T extends DialogResult, U>(
		dialogConfig: DialogComponentData<T, U>,
	): Promise<T | false> => {
		return new Promise<T | false>((resolve) => {
			const handleClose = (result?: T) => {
				resolve(result || false);
				setCloseDialog(true);
			};

			dialogConfig.onClose = (_, reason) => {
				if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
					resolve(false);
					setCloseDialog(true);
				} else {
					handleClose();
				}
			};

			const DialogComponent = () => (
				<dialogConfig.component close={handleClose} data={dialogConfig.data} />
			);

			// console.log(dialogConfig.minWidth);

			setDialogs((prev) => [
				...prev,
				{
					...dialogConfig,
					content: <DialogComponent />,
					onClose: () => handleClose(),
					type: 'component',
				},
			]);
		});
	};

	const renderedDialogs = useMemo(() => {
		const activeDialog = dialogs[0];
		return activeDialog ? (
			<Dialog
				open={!closeDialog}
				onClose={activeDialog.onClose}
				TransitionComponent={Transition}
				onTransitionEnd={() =>
					setActiveDialogBoundingRect(activeDialogRef.current?.getBoundingClientRect() ?? null)
				}
				sx={{
					zIndex: 9999,
					...(activeDialog.minWidth && {
						'& .MuiDialog-container': {
							'& .MuiPaper-root': {
								minWidth: `${activeDialog.minWidth}vw`,
							},
						},
					}),
					'& .MuiDialog-paper': {
						/**
						 * this is necessary when we are using a drag and drop inside of the dialog
						 * might remove/add it conditionally if it causes issues in other places
						 */
						overflowY: 'visible',
					},
				}}
				fullScreen={activeDialog.fullScreen}
				onTransitionExited={() => {
					setCloseDialog(false);
					setDialogs((prev) => prev.slice(1));
				}}
			>
				{activeDialog.title && (
					<DialogTitle
						sx={{
							background: theme.palette.gradient.primary,
							color: theme.palette.primary.contrastText,
							textAlign: 'center',
							textShadow: '0px 0px 2px rgba(255, 255, 255, 0.2)',
						}}
					>
						{activeDialog.title}
					</DialogTitle>
				)}
				<DialogContent sx={{ paddingTop: '1rem !important' }} ref={activeDialogRef}>
					{activeDialog.message && <DialogContentText>{activeDialog.message}</DialogContentText>}
					<div style={{ margin: '1rem 0' }}>{activeDialog.content}</div>
				</DialogContent>
				{activeDialog.actions && (
					<DialogActions>
						{activeDialog.actions.map((action, actionIndex) => (
							<Button
								key={actionIndex}
								onClick={() => {
									action.action?.();
									setCloseDialog(true);
								}}
							>
								{action.label}
							</Button>
						))}
					</DialogActions>
				)}
			</Dialog>
		) : null;
	}, [dialogs, closeDialog]);

	const contextValue = {
		pushDialog,
		pushComponentDialog,
		activeDialogBoundingRect,
	};

	return (
		<FeedbacksContext.Provider value={contextValue}>
			{renderedDialogs}
			{children}
		</FeedbacksContext.Provider>
	);
};

export const useFeedbacks = () => useContext(FeedbacksContext);
