import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AffectedRowsResponse,
	CustomerTypeCreateSchema,
	CustomerTypeRequest,
	CustomerTypeResponse,
	CustomerTypeSchema,
	CustomerTypesResponse,
	GenericIdRequest,
	LocaleEntryAction,
	TranslationTypes,
} from 'common';
import {
	doCreateCustomerTypes,
	doDeleteCustomerTypes,
	doEditCustomerTypes,
} from '../store/customers';
import { AvailableLangsArray } from '../store/app';
import { PayloadAction } from '@reduxjs/toolkit';

function useCustomerTypes() {
	const dispatch = useAppDispatch();
	const customerTypesSlice = useAppSelector((state) => state.customers.customerTypes);
	const formState = useAppSelector((state) => state.customers.op_status);

	const [customerTypesList, setCustomerTypesList] =
		useState<CustomerTypesResponse>(customerTypesSlice);

	useEffect(() => {
		setCustomerTypesList((currentList) => {
			if (!_.isEqual(currentList, customerTypesSlice)) {
				return customerTypesSlice;
			}
			return currentList;
		});
	}, [customerTypesSlice]);

	const getDefaultCustomerType = (
		id?: number,
		lang?: string,
		newName?: string,
	): CustomerTypeResponse => {
		return {
			id: id ?? -1,
			strings: [
				{
					type: TranslationTypes.name,
					langCode: lang ?? AvailableLangsArray[0],
					value: newName ?? 'New Customer Type',
					action: LocaleEntryAction.CREATE,
				},
			],
			canHaveChildren: false,
			canHaveParent: false,
		};
	};

	const deleteCustomerType = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCustomerTypes(data));
	};

	const createCustomerType = async (data: CustomerTypeRequest): Promise<CustomerTypeResponse> => {
		const parsedData = CustomerTypeCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid customer type data');
		}
		const response = (await dispatch(
			doCreateCustomerTypes(parsedData.data),
		)) as PayloadAction<CustomerTypeResponse>;
		if (response.type === 'customers/types/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error creating customer type');
	};

	const editCustomerType = async (data: CustomerTypeResponse): Promise<AffectedRowsResponse> => {
		const parsedData = CustomerTypeSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid customer type data');
		}
		const response = (await dispatch(
			doEditCustomerTypes(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'customers/types/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing customer type');
	};

	// TODO: we are returning an empty string whenever the old code is undefined
	//       check the requirements and if we need to return undefined instead
	const applyCodePrefix = (typeId: number | null | undefined, oldCode: string): string => {
		if (!oldCode) {
			return '';
		}

		const prefix = customerTypesList.find((ct) => ct.id === typeId)?.customerCodePrefix ?? '';
		if (prefix === '') {
			return oldCode;
		}
		let newCode = oldCode;
		const parts = newCode.split('-');
		if (parts.length > 1) {
			parts[0] = prefix;
			newCode = parts.join('-');
		} else if (parts.length === 1) {
			newCode = `${prefix}-${newCode}`;
		}
		return newCode;
	};

	return {
		formState,
		customerTypesList,
		getDefaultCustomerType,
		createCustomerType,
		editCustomerType,
		deleteCustomerType,
		applyCodePrefix,
	};
}

export default useCustomerTypes;
