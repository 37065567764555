import { Grid, TextField, Typography } from '@mui/material';
import { useTask } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import { AppFunction, TranslationTypes } from 'common';
import { NumberFieldValue } from '@contexts/taskContext/config/numberFieldConfig';
import { useEffect } from 'react';

interface Props {
	taskFieldId: number;
}

export const NumberField = ({ taskFieldId }: Props) => {
	const { getTranslatedString } = useLocale();
	const { taskState, cachedValues, getTaskFieldValues, setTaskFieldValues } = useTask();

	const fieldData = getTaskFieldValues<NumberFieldValue>(taskFieldId);

	useEffect(() => {
		const stubData = getTaskFieldValues<NumberFieldValue>(taskFieldId);
		if (stubData.length > 0) {
			setTaskFieldValues(taskFieldId, [{ value: stubData[0].value }]);
		}
	}, [cachedValues]);

	return (
		<Grid container style={{ padding: '1rem' }} alignItems='center'>
			<Grid item flexGrow={1}>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item>
				<TextField
					fullWidth
					type='number'
					disabled={taskState !== 'in_progress' && taskState !== 'stub'}
					value={fieldData.length > 0 ? fieldData[0].value : 0}
					InputProps={{
						inputProps: { min: 0 },
					}}
					onChange={(e) => setTaskFieldValues(taskFieldId, [{ value: parseFloat(e.target.value) }])}
				/>
			</Grid>
		</Grid>
	);
};
