import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderProductsResponse,
	OrderProductsUpdateRequest,
	OrdersBaseResponse,
	OrderTemplateRequest,
	OrderTemplateResponse,
	OrderTemplatesResponse,
} from 'common';

import * as api from './externalApi/common/apiCalls';

export interface OrderState {
	orders: OrdersBaseResponse;
	orderTemplates: OrderTemplatesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: OrderState = {
	orders: [],
	orderTemplates: [],
	op_status: 'idle',
};

export const doListOrders = api.apiList<OrdersBaseResponse>('orders/list', 'orders');

export const doSetOrderProductsData = api.apiBulkCreateWithOptions<
	OrderProductsUpdateRequest,
	AffectedRowsResponse
>('orders/products/set', 'orders/:orderId/products');

export const doCreateOrder = api.apiCreate<OrderBaseRequest, OrderBaseResponse>(
	'orders/create',
	'orders',
);

export const doEditOrder = api.apiEdit<OrderBaseResponse, AffectedRowsResponse>(
	'orders/edit',
	'orders',
);

export const doEditOrderContent = api.apiBulkCreateWithOptions<
	OrderProductsUpdateRequest,
	AffectedRowsResponse
>('orders/edit/content', 'orders/content');

export const doDeleteOrder = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'orders/delete',
	'orders',
);

export const doCloseOrder = api.apiListById<GenericIdRequest, AffectedRowsResponse>(
	'orders/close',
	'orders/:id/close',
);

export const doListTemplates = api.apiList<OrderTemplatesResponse>(
	'orders/templates',
	'orders/templates',
);

export const doCreateTemplate = api.apiCreate<OrderTemplateRequest, OrderTemplateResponse>(
	'orders/templates/create',
	'orders/templates',
);

export const doOrderChuckNorris = api.apiList<null>('orders/chucknorris', 'orders/reset');

export const doTasksChuckNorris = api.apiList<null>('tasks/chucknorris', 'tasks/reset');

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// #region Create Order
		builder.addCase(doCreateOrder.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateOrder.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateOrder.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		builder.addCase(doListOrders.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListOrders.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.orders = action.payload;
		});
		builder.addCase(doListOrders.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #region List Template
		builder.addCase(doListTemplates.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListTemplates.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.orderTemplates = action.payload;
		});
		builder.addCase(doListTemplates.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Create Template
		builder.addCase(doCreateTemplate.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateTemplate.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateTemplate.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
	},
});

export default orderSlice.reducer;
