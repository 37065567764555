import API from '../index';
import { UserLoginRequest, UserLoginResponse } from 'common';

export const execute = async (params: UserLoginRequest): Promise<UserLoginResponse> => {
	const response = await API.post('/api/v1/users/login', {
		userormail: params.userormail,
		password: params.password,
	});

	if (response.status !== 200 && response.status !== 201) {
		throw new Error('Invalid username/password');
	}
	const data = (await response.data.payload) as UserLoginResponse;
	return data;
};
