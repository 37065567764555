import { FormatListBulleted } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { TaskFieldSetArchetypeResponse } from 'common';
import { DraggableProvided } from 'react-beautiful-dnd';

interface Props {
	archetype: TaskFieldSetArchetypeResponse;
	isDragging: boolean;
	provided?: DraggableProvided;
}

export const FieldSetItem = ({ provided, archetype, isDragging }: Props) => {
	return (
		<ListItem
			ref={provided?.innerRef}
			{...provided?.draggableProps}
			{...provided?.dragHandleProps}
			style={provided ? provided.draggableProps.style : {}}
			sx={{
				backgroundColor: 'white',
				opacity: provided ? 1 : 0.5,
				transform: isDragging ? undefined : 'translate(0px, 0px) !important',
				borderRadius: '3px',
				border: provided ? '1px solid black' : '1px dashed black',
				marginBottom: '3px',
				padding: '0.3rem',
			}}
		>
			<ListItemIcon sx={{ minWidth: '30px', position: 'relative' }}>
				<FormatListBulleted />
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'black',
						color: 'white',
						borderRadius: '50%',
						width: '0.8rem',
						height: '0.8rem',
						fontSize: '0.6rem',
						position: 'absolute',
						top: 0,
						right: '0.5rem',
					}}
				>
					{archetype.types.length}
				</div>
			</ListItemIcon>
			<ListItemText sx={{ userSelect: 'none' }}>[{archetype.name}]</ListItemText>
		</ListItem>
	);
};
