import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import useProducts from '@hooks/useProducts';
import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { ProductType } from 'common';
import { InternalSuborderProduct } from './InternalSuborderProduct';

export const InternalSuborderProductsContainer = ({ subOrder }: SubOrderProductsContainerProps) => {
	const { addEmptyVariant } = useOrderEditor();
	const { productList } = useProducts();

	const subOrderCode = subOrder?.code;
	if (!subOrder || !subOrderCode) return null;

	return (
		<Grid item xs={12} container spacing={2}>
			{subOrder?.content?.map((op, it) => {
				return (
					<InternalSuborderProduct
						key={it}
						index={it}
						data={op}
						subOrderCode={subOrderCode}
						unavailableVariantIds={[]}
					/>
				);
			})}
			<Grid item xs={12}>
				<Button
					variant='outlined'
					disabled={productList.filter((p) => p.productType === ProductType.product).length === 0}
					fullWidth
					onClick={() => addEmptyVariant(subOrderCode)}
					sx={{
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						transform: 'scale(1)',
						'&:hover': {
							transform: 'scale(1.01)',
						},
					}}
				>
					<Add />
				</Button>
			</Grid>
		</Grid>
	);
};
