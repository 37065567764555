import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface NestedSlidingMenuItem {
	id: number;
	name: string;
	icon?: ReactElement;
	suffix?: ReactElement;
	children?: NestedSlidingMenuItem[];
}

interface RootMenuItem extends Omit<NestedSlidingMenuItem, 'id' | 'children'> {
	id?: number;
}

interface Props {
	root: RootMenuItem;
	items: NestedSlidingMenuItem[];
	currentSelection: number | 'root';
	onSelectionChange: (id: number | 'root') => void;
	onRootClick: () => void;
}

const isDescendantSelected = (item: NestedSlidingMenuItem, id: number): boolean => {
	let selected = false;
	if (item.id === id) {
		return true;
	}
	if (item.children) {
		for (const child of item.children) {
			if (isDescendantSelected(child, id)) {
				selected = true;
			}
		}
	}
	return selected;
};

// TODO: document the component
export const NestedSlidingMenu = ({
	root,
	items,
	currentSelection,
	onSelectionChange,
	onRootClick,
}: Props) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [buttonPlacements, setButtonPlacements] = useState<{
		[key: number]: { left: number; width: number };
	}>({});
	const [currentButtonRefId, setCurrentButtonRefId] = useState<number | null>(null);

	// const [currentButtonRefIdMap, setCurrentButtonRefIdMap] = useState<{ [key: number]: number | null }>({});

	const buttonRefIdMap = useRef<{ [key: number]: number | null }>({});
	const rootRef = useRef<HTMLButtonElement | null>(null);
	const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
	const boxRef = useRef<HTMLDivElement | null>(null);

	const initRef = useRef<boolean>(false);

	useLayoutEffect(() => {
		if (boxRef.current) {
			const boxRect = boxRef.current.getBoundingClientRect(); // Get the bounding box of the Box container
			const placements: Record<number, { left: number; width: number }> = {};
			if (!initRef.current) {
				initRef.current = true;
				buttonRefs.current.forEach((button, index) => {
					if (button) {
						const buttonRect = button.getBoundingClientRect();
						const relativeLeft = buttonRect.left - boxRect.left;
						placements[index] = { left: relativeLeft, width: buttonRect.width };
					}
				});
				setButtonPlacements(placements);
			}
		}
	});

	useEffect(() => {
		if (currentSelection === 'root') {
			setCurrentButtonRefId(null);
		} else {
			setCurrentButtonRefId(buttonRefIdMap.current[currentSelection] ?? null);
		}
	}, [currentSelection]);

	const isRootDescendantSelected = (): boolean => {
		if (currentSelection === 'root') {
			return !root.id;
		}
		return isDescendantSelected({ ...root, id: root.id ?? 0, children: items }, currentSelection);
	};

	const getStackTransform = (): string | undefined => {
		if (!currentButtonRefId) {
			return undefined;
		}

		if (!root.id) {
			return `translateX(-${
				buttonPlacements[currentButtonRefId]?.left + (currentButtonRefId - 1) * 1.1
			}px)`;
		}

		if (isRootDescendantSelected()) {
			return `translateX(-${
				buttonPlacements[currentButtonRefId]?.left + (currentButtonRefId - 1) * 1.1
			}px)`;
		}

		return undefined;
	};

	const getStackOpacity = (): number => {
		if (!root.id) {
			return 1;
		}
		if (currentSelection === 'root') {
			return 0;
		}
		return isDescendantSelected({ ...root, id: root.id ?? 0, children: items }, currentSelection)
			? 1
			: 1;
	};

	const buttons = (itemArray: NestedSlidingMenuItem[]): ReactNode[] => {
		const buttonArray: ReactNode[] = [];

		const show = (item: NestedSlidingMenuItem): boolean => {
			if (
				item.id === currentSelection ||
				currentSelection === 'root' ||
				currentSelection === root.id ||
				isDescendantSelected(item, currentSelection)
			) {
				return true;
			}
			return false;
		};

		itemArray.forEach((item, index) => {
			if (!root && index === 0) {
				return;
			}
			buttonRefIdMap.current[item.id] = index + 1;
			buttonArray.push(
				<Box
					key={item.id}
					sx={{
						position: 'relative',
						opacity: show(item) ? 1 : 0,
						transform: show(item) ? 'scale(1)' : 'scale(0)',
						transition: 'all 0.4s ease',
						transitionDelay:
							currentButtonRefId && index - currentButtonRefId >= 0
								? (index - currentButtonRefId) * 0.05 + 's'
								: index * 0.05 + 's',
					}}
					ref={(el: HTMLDivElement) => (buttonRefs.current[index + 1] = el)}
				>
					{item.children && item.children.length > 0 ? (
						<NestedSlidingMenu
							root={item}
							items={item.children}
							currentSelection={currentSelection}
							onSelectionChange={(id) => {
								if (id !== 'root') {
									buttonRefIdMap.current[id] = index + 1;
									onSelectionChange(id);
								}
							}}
							onRootClick={() => {
								onSelectionChange(item.id);
							}}
						/>
					) : (
						<Button
							size='small'
							variant={currentSelection === item.id ? 'contained' : 'outlined'}
							sx={{
								borderRadius: '0.5rem',
								height: 'calc(100% + 1px !important)',
								display: 'flex',
								alignItems: 'center',
								gap: '0.5rem',
								pointerEvents: show(item) ? 'auto' : 'none',
							}}
							onClick={() => {
								// TODO: this shouldn't be here
								// setCurrentButtonRefIdMap({ ...currentButtonRefIdMap, [item.id]: index + 1 });
								onSelectionChange(item.id);
							}}
						>
							{item.icon}
							{item.name}
							{item.suffix}
						</Button>
					)}
				</Box>,
			);
		});
		return buttonArray;
	};

	const getStackLeft = (): string => {
		let show = true;
		if (root.id && !isRootDescendantSelected()) {
			show = false;
		}
		return rootRef.current && show
			? `calc(${rootRef.current.getBoundingClientRect().width}px + ${root.id ? 0.5 : 1}rem)`
			: '0px';
	};

	return (
		<Box display='flex' justifyContent='flex-start'>
			<Box
				ref={rootRef}
				sx={{
					backgroundColor: theme.palette.background.default,
					zIndex: 1,
					position: 'relative',
				}}
			>
				<Button
					key={root.id ? `root-${root.id}` : 'root'}
					size='small'
					variant={isRootDescendantSelected() ? 'contained' : 'outlined'}
					sx={{
						borderRadius: '0.5rem',
						height: 'calc(100% + 1px !important)',
						display: 'flex',
						alignItems: 'center',
						gap: '0.2rem',
					}}
					onClick={() => {
						onRootClick();
					}}
				>
					{root.icon}
					{root.id ? root.name : t('common.all')}
					{root.suffix}
					{root.id ? (
						isRootDescendantSelected() && currentSelection !== root.id ? (
							<ArrowLeft />
						) : (
							<ArrowRight />
						)
					) : (
						<></>
					)}
				</Button>
			</Box>
			<Stack
				position='absolute'
				left={getStackLeft()}
				direction='row'
				justifyItems='space-between'
				gap='0.5rem'
				ref={boxRef}
				sx={{
					transform: getStackTransform(),
					opacity: getStackOpacity(),
					backgroundColor: theme.palette.background.default,
					// FIXME: find a better way to define the width
					width: '70vw',
					transition: 'all 0.4s',
				}}
			>
				{buttons(items)}
			</Stack>
		</Box>
	);
};
