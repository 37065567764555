import { useAuth } from '@hooks/auth';

import { AppFunction, OrderType, ProductType } from 'common';

import { AccessAlarm, ChevronLeft, Home, Keyboard, Logout, Settings } from '@mui/icons-material';
import { IconButton, List, styled, Toolbar, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { DrawerListItem } from './DrawerListItem';
import { useFeedbacks, useLayout } from '@contexts/index';
import { useTranslation } from 'react-i18next';
import useLayoutObserver from '@hooks/useLayoutObserver';
import { useEffect } from 'react';

const drawerWidth = '18rem';
const closedWidth = '5rem';

const CustomDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create(['width', 'margin', 'background'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		padding: 0,
		border: 'none',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create(['width', 'margin', 'background'], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.leavingScreen,
			}),
			// width: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				width: closedWidth,
			},
		}),
		...(open && {
			background: 'rgba(34,89,107,1)',
			color: 'white !important',
		}),
	},
}));

export const Drawer = () => {
	const { setDrawerOpen, drawerOpen, registerElement, unregisterElement } = useLayout();
	const { pushDialog } = useFeedbacks();
	const { t } = useTranslation();
	const theme = useTheme();
	const auth = useAuth();

	const observe = (_: string, contentRect: DOMRectReadOnly) => {
		registerElement('drawer', 'left', contentRect.width);
	};

	const menuRef = useLayoutObserver<HTMLDivElement>('drawer', 'size', observe);

	useEffect(() => {
		return () => {
			unregisterElement('drawer');
		};
	}, []);

	function callLogoutConfirmDialog() {
		pushDialog({
			title: t('dialog.logout.title'),
			type: 'confirm',
			message: t('dialog.logout.message'),
		}).then((result) => {
			if (result) {
				auth.logout();
			}
		});
	}

	const list = () => (
		<List>
			<DrawerListItem customIcon={<Home />} name='dashboard' />
			<DrawerListItem
				appFunction={AppFunction.Product}
				subFunction={ProductType.product}
				checkPermissions
			/>
			<DrawerListItem
				appFunction={AppFunction.Product}
				subFunction={ProductType.packaging}
				path='packaging'
				name='packaging'
				checkPermissions
			/>
			<DrawerListItem appFunction={AppFunction.Inventory} checkPermissions />
			<DrawerListItem appFunction={AppFunction.Customer} checkPermissions />
			<DrawerListItem appFunction={AppFunction.Order} path='orders' checkPermissions />
			<DrawerListItem
				// TODO: add appfunction.schedule or scheduledEvent
				// appFunction={AppFunction.Order}
				customIcon={<AccessAlarm />}
				path='scheduledEvent'
				name='scheduledEvent'
			/>
			<DrawerListItem
				appFunction={AppFunction.Order}
				subFunction={OrderType.Inbound}
				path='orders/create/import'
				name='createInOrder'
				checkPermissions
			/>
			<DrawerListItem
				appFunction={AppFunction.Order}
				subFunction={OrderType.Outbound}
				path='orders/create/sales'
				name='createOutOrder'
				checkPermissions
			/>
			<DrawerListItem
				appFunction={AppFunction.Order}
				subFunction={OrderType.Internal}
				path='inventory/move'
				name='createMoveOrder'
				checkPermissions
			/>
			<DrawerListItem
				appFunction={AppFunction.Order}
				subFunction={OrderType.Processing}
				path='inventory/process'
				name='createProcessingOrder'
				checkPermissions
			/>
			<DrawerListItem customIcon={<Settings />} path='settings' name='settings' />
			<DrawerListItem
				customIcon={<Logout />}
				path='logout'
				name='logout'
				customAction={callLogoutConfirmDialog}
			/>
			<DrawerListItem customIcon={<Keyboard />} path='controlRoom' name='controlRoom' />
		</List>
	);

	return (
		<CustomDrawer
			variant='permanent'
			ref={menuRef}
			theme={theme}
			open={drawerOpen}
			sx={{
				display: { xs: 'none', sm: 'block' },
				zIndex: 8000,
			}}
		>
			<Toolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					px: [1],
					zIndex: 9999,
				}}
			>
				<IconButton onClick={() => setDrawerOpen(false)}>
					<ChevronLeft sx={{ color: drawerOpen ? 'white' : 'grey.600' }} />
				</IconButton>
			</Toolbar>
			{list()}
		</CustomDrawer>
	);
};
