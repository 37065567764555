import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
// import useTasks from '@hooks/useTasks';
import { useEffect, useMemo, useState } from 'react';
import useProducts from '@hooks/useProducts';
import { ProcessingOrderSubType, Recipe } from 'common';
import { ContainerComponentProps } from '@components/taskworkflow/tasks/config';
import { SimpleDivisionContainer } from './modules/simpleDivision/SimpleDivisionContainer';
import { MultipleDivisionContainer } from './modules/multipleDivision/MultipleDivisionContainer';
import { CompoundDivisionContainer } from './modules/compoundDivision/CompoundDivisionContainer';

export const ProcessingCloseOrderFieldContainer: React.FC<ContainerComponentProps> = ({
	taskFieldId,
}: ContainerComponentProps) => {
	const { targetId } = useTask();
	const { orderList } = useOrders();
	const { variantList } = useProducts();
	// TODO: we use this if we need to asses the task in the chain and its properties
	// const { getTaskInChainByOrderIdFieldType } = useTasks();
	const [recipe, setRecipe] = useState<Recipe | null>(null);

	const order = useMemo(() => {
		return orderList.find((order) => order.id === targetId);
	}, [orderList, targetId]);

	const resetRecipe = () => {
		if (!order) {
			return;
		}

		const targetProduct = variantList.find(
			(variant) => variant.id === order?.content?.[0].variantId,
		);
		if (!targetProduct) {
			return;
		}
		if (!targetProduct.recipe) {
			return;
		}

		setRecipe(targetProduct.recipe);
	};

	useEffect(() => {
		resetRecipe();
	}, [targetId, order]);

	// const getCompoundCutDivisionValues = (order: OrderBaseResponse): CloseOrderValueMap => {
	// 	const result: CloseOrderValueMap = {};
	// 	order.content?.forEach((content) => {
	// 		const oldValues = {
	// 			variantId: content.variantId ?? -1,
	// 			expiresAt: content.expiresAt ? moment(content.expiresAt) : null,
	// 			arrivedQty: order.targetQuantity ?? 0,
	// 			sourceId: content.sourceId ? +content.sourceId : null,
	// 			weight: content.weight ?? 0,
	// 			estimated: false,
	// 		};
	// 		// console.log(content);
	// 		if (content.pickedWeight !== content.orderQty) {
	// 			// we are dealing here with the part we cut
	// 			const cutWeight = (order.targetQuantity ?? 0) - (content.pickedWeight ?? 0);
	// 			const leftoverWeight = (content.pickedWeight ?? 0) - cutWeight;

	// 			const tempValues: CloseOrderValue = {
	// 				oldValues,
	// 				newValues: [
	// 					{
	// 						variantId: content.variantId ?? -1,
	// 						expiresAt: content.expiresAt ? moment(content.expiresAt) : null,
	// 						arrivedQty: cutWeight,
	// 						sourceId: content.sourceId ? +content.sourceId : null,
	// 						weight: content.weight ?? 0,
	// 						estimated: false,
	// 					},
	// 					{
	// 						variantId: content.variantId ?? -1,
	// 						expiresAt: content.expiresAt ? moment(content.expiresAt) : null,
	// 						arrivedQty: leftoverWeight,
	// 						sourceId: content.sourceId ? +content.sourceId : null,
	// 						weight: content.weight ?? 0,
	// 						estimated: true,
	// 					},
	// 				],
	// 			};

	// 			if (!result[content.variantId ?? -1]) {
	// 				result[content.variantId ?? -1] = tempValues;
	// 			} else {
	// 				// we want the cut part/s to be the first element/s in the array
	// 				result[content.variantId ?? -1].newValues.unshift(...tempValues.newValues);
	// 			}
	// 		} else {
	// 			// we are dealing here with the already existing pieces
	// 			const tempValues: CloseOrderValue = {
	// 				oldValues,
	// 				newValues: [
	// 					{
	// 						variantId: content.variantId ?? -1,
	// 						expiresAt: content.expiresAt ? moment(content.expiresAt) : null,
	// 						arrivedQty: content.pickedWeight ?? 0,
	// 						sourceId: content.sourceId ? +content.sourceId : null,
	// 						weight: content.weight ?? 0,
	// 						estimated: content.estimated ?? false,
	// 					},
	// 				],
	// 			};

	// 			if (!result[content.variantId ?? -1]) {
	// 				result[content.variantId ?? -1] = tempValues;
	// 			} else {
	// 				result[content.variantId ?? -1].newValues.push(...tempValues.newValues);
	// 			}
	// 		}
	// 	});
	// 	return result;
	// };

	// /**
	//  * Gets the parent product of the current order and calculates the
	//  * old values and new values for a processing order
	//  * @param {OrderBaseResponse} order - the current order
	//  * @returns {CloseOrderValueMap}
	//  */
	// const getProcessingValues = (order: OrderBaseResponse): CloseOrderValueMap => {
	// 	const newValues: CloseOrderValueMap = {};
	// 	order.content
	// 		?.filter((c) => c.variantId !== undefined && c.variantId !== null)
	// 		.forEach((content) => {
	// 			let oldExpiresAt: Moment | null = null;
	// 			let oldOrderProductId: number | null = null;
	// 			if (order.parentId !== null && order.parentId !== undefined) {
	// 				const parentOrder = orderList.find((o) => o.id === order.parentId);

	// 				if (parentOrder && order.orderType) {
	// 					const parentProduct = getParentProduct?.(
	// 						taskFieldId,
	// 						order.orderType,
	// 						parentOrder,
	// 						content,
	// 					);
	// 					oldExpiresAt = parentProduct?.expiresAt
	// 						? moment(parentProduct.expiresAt).startOf('day')
	// 						: null;
	// 					oldOrderProductId = parentProduct?.id ? +parentProduct.id : null;
	// 				}
	// 			}

	// 			const value = {
	// 				expiresAt: content.expiresAt ? moment(content.expiresAt) : oldExpiresAt,
	// 				arrivedQty: content.orderQty ?? 0,
	// 				variantId: content.variantId ?? -1,
	// 				sourceId: oldOrderProductId,
	// 				weight: content.weight ?? 0,
	// 				estimated: content.estimated ?? false,
	// 			};
	// 			newValues[content.variantId ?? -1] = {
	// 				oldValues: value,
	// 				newValues: [value],
	// 			};
	// 		});
	// 	return newValues;
	// };

	if (!order || !order.orderSubType || !recipe) {
		return null;
	}

	// const multipleCutOrder = (): JSX.Element => (
	// 	<List>
	// 		{order?.content
	// 			?.filter((c) => c.variantId !== undefined && c.variantId !== null)
	// 			.map((content, it) => {
	// 				if (values[content.variantId ?? -1]) {
	// 					return (
	// 						<MultipleDivisionItem
	// 							key={it}
	// 							content={content}
	// 							value={values[content.variantId ?? -1]}
	// 						/>
	// 					);
	// 				}
	// 			})}
	// 	</List>
	// );

	// const compoundCutCutOrder = (): JSX.Element => (
	// 	<List>
	// 		{values &&
	// 			Object.values(values).map((value) => {
	// 				if (order.content) {
	// 					return (
	// 						<CompoundCutDivisionItem
	// 							key={order.content[0].variantId}
	// 							content={order.content}
	// 							values={value}
	// 							updateField={updateField}
	// 							toggleLeftovers={toggleLeftovers}
	// 							resetValues={resetValues}
	// 						/>
	// 					);
	// 				}
	// 			})}
	// 	</List>
	// );

	const content = (): JSX.Element => {
		switch (order.orderSubType) {
			case ProcessingOrderSubType.simple:
				return <SimpleDivisionContainer order={order} recipe={recipe} taskFieldId={taskFieldId} />;
			case ProcessingOrderSubType.multiple:
				return (
					<MultipleDivisionContainer order={order} recipe={recipe} taskFieldId={taskFieldId} />
				);
			case ProcessingOrderSubType.compound:
				break;
			case ProcessingOrderSubType.compoundCut:
				return <CompoundDivisionContainer />;
		}
		return <>no corresponding order subtype</>;
	};

	return <div style={{ padding: '1rem' }}>{content()}</div>;
};
