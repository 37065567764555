export enum DropDownActionType {
	edit = 'edit',
	delete = 'delete',
	details = 'details',
	print = 'print',
	export = 'export',
	import = 'import',
	send = 'send',
	refresh = 'refresh',
}
