import useInventory from '@hooks/useInventory';
import useLocale from '@hooks/useLocale';
import { Box, Grid, Stack, ToggleButton, useTheme } from '@mui/material';
import { AppFunction, Recipe, TranslationTypes } from 'common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessingIngredientRow } from './ProcessingIngredientRow';

interface Props {
	recipe: Recipe;
	subOrderCode: string;
}

export const ProcessingIngredientsRow = ({ recipe, subOrderCode }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { findAllInventoryEntriesByVariantId } = useInventory();

	const [autoMode, setAutoMode] = useState(true);

	return (
		<Grid item xs={12} container gap={1} display='flex'>
			<Grid item xs={12}>
				<ToggleButton
					value='check'
					fullWidth
					size='small'
					selected={autoMode}
					onChange={() => setAutoMode((oldMode) => !oldMode)}
				>
					auto
				</ToggleButton>
			</Grid>
			<Grid
				item
				p='0.5rem'
				borderRadius='0.5rem'
				display='flex'
				alignItems='center'
				sx={{
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				}}
			>
				{`${t(`${AppFunction.Product}.ingredient`, { count: recipe.ingredients.length })} (${
					recipe.ingredients.length
				})`}
			</Grid>
			<Grid item flexGrow={1}>
				<Stack direction='column' rowGap={1}>
					{recipe.ingredients.map((ing, idx) => (
						<ProcessingIngredientRow
							key={idx}
							subOrderCode={subOrderCode}
							automatic={autoMode}
							ingredient={ing}
						/>
					))}
				</Stack>
			</Grid>
		</Grid>
	);
};
