import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useAuth } from '@hooks/auth';
import useAppFunctions from '@hooks/useAppFunctions';
import { Chip, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { AppFunction, PermissionEditRequest, SectionEditRequest } from 'common';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { entityId, control, errors, formAction } = useGenericForm<SectionEditRequest>();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const auth = useAuth();

	const editDisabled = formAction === 'view';

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TabInnerTitle title={t('user.userInformation')} size='small' />
			</Grid>
			<Controller
				control={control}
				name='name'
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							label='name'
							value={field.value}
							disabled={editDisabled}
							onChange={field.onChange}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				control={control}
				name='permissions'
				render={({ field }) => {
					const handleSwitchChange = useCallback(
						(permissionKey: AppFunction, property: 'read' | 'write', currentValue: boolean) => {
							let updatedPermissions: PermissionEditRequest[] = [];
							if (field.value) {
								updatedPermissions = [...field.value];
							}
							const permissionIndex = updatedPermissions.findIndex(
								(p) => p.function === permissionKey,
							);

							if (permissionIndex >= 0) {
								updatedPermissions[permissionIndex] = {
									...updatedPermissions[permissionIndex],
									[property]: !currentValue,
								};
							} else {
								updatedPermissions.push({
									function: permissionKey,
									[property]: true,
								});
							}

							field.onChange(updatedPermissions);
						},
						[field],
					);

					return (
						<Grid item container xs={12}>
							{Object.values(AppFunction).map((permission, it) => {
								// console.log(field.value);
								const values = field.value?.find((p) => p.function === permission);
								return (
									<Grid
										item
										container
										key={it}
										sx={{ minHeight: '30px', padding: '0 1rem 0 0' }}
										display='flex'
									>
										<Grid item flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
											<Chip
												label={t(`appFunctions.${permission}`)}
												icon={getIcon(permission) ?? <></>}
											/>
										</Grid>
										<Grid item>
											{/* <InputGridField width={2} > */}
											<FormControlLabel
												control={<Switch checked={values?.write ? true : values?.read ?? false} />}
												label={t('operations.read')}
												labelPlacement='start'
												disabled={values?.write ?? false}
												onChange={() =>
													handleSwitchChange(permission, 'read', values?.read ?? false)
												}
												sx={{ width: '100%' }}
											/>
											{/* </InputGridField> */}
										</Grid>
										<Grid item>
											<FormControlLabel
												control={<Switch checked={values?.write ?? false} />}
												label={t('operations.write')}
												labelPlacement='start'
												onChange={() =>
													handleSwitchChange(permission, 'write', values?.write ?? false)
												}
											/>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					);
				}}
			/>
		</Grid>
	);
};
