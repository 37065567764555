import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Dashboard } from '@mui/icons-material';
import { useEffect } from 'react';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { HomeCalendar } from '../../components/home/HomeCalendar';
import { TaskBox } from './TaskBox';

export default function HomePage() {
	const { setBreadCrumbs, pushSubMenuElements } = useLayout();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'Dashboard',
				icon: <Dashboard />,
			},
		]);
		pushSubMenuElements(null);
	}, []);

	return (
		<FadeWrapper fadeTime={500}>
			<Container maxWidth={false} sx={{ position: 'absolute', height: 'calc(100% - 64px)' }}>
				<Grid container spacing={2} sx={{ height: '100%' }} direction='row' display='flex'>
					<Grid
						item
						flexGrow={1}
						flexBasis={0}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}
					>
						<TaskBox tasksType='today' />
					</Grid>
					<Grid
						item
						flexGrow={1}
						flexBasis={0}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}
					>
						<TaskBox tasksType='assigned' />
					</Grid>
					<HomeCalendar />
				</Grid>
			</Container>
		</FadeWrapper>
	);
}
