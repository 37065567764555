import { useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

// TODO: ADD LABEL

interface Props {
	value: string;
	onChange: (value: string) => void;
	label?: string;
}

type Vector3 = {
	x: number;
	y: number;
	z: number;
};

function parseStringToVector3(str: string): Vector3 | null {
	const regex = /^\[(\d+),(\d+),(\d+)\]$/;
	const match = str.match(regex);

	if (match) {
		const [, num1, num2, num3] = match;
		const result: Vector3 = { x: Number(num1), y: Number(num2), z: Number(num3) };
		return result;
	}

	return null;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		hover: {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.primary.main,
			},
		},
		focus: {
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: '2px',
			},
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderWidth: '2px',
			},
		},
		leftTextField: {
			'& .MuiOutlinedInput-root': {
				borderRadius: theme.spacing(1, 0, 0, 1),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderRight: 'none',
			},
		},
		centerTextField: {
			'& .MuiOutlinedInput-root': {
				borderRadius: theme.spacing(0, 0, 0, 0),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderRight: 'none',
				borderLeft: 'none',
			},
		},
		rightTextField: {
			'& .MuiOutlinedInput-root': {
				borderRadius: theme.spacing(0, 1, 1, 0),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderLeft: 'none',
			},
		},
	}),
);

export const VectorThreeInput = ({ value, onChange }: Props) => {
	const classes = useStyles();
	const [sizes, setSizes] = useState<Vector3>({ x: 0, y: 0, z: 0 });
	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		setSizes(parseStringToVector3(value) ?? { x: 0, y: 0, z: 0 });
	}, [value]);

	const onValueChange = (index: number, value: string) => {
		const newValue = `[${index === 0 ? value : sizes.x},${index === 1 ? value : sizes.y},${
			index === 2 ? value : sizes.z
		}]`;
		onChange(newValue);
	};

	return (
		<Grid
			container
			spacing={0}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className={isFocused ? classes.focus : ''}
		>
			<Grid item xs={4}>
				<TextField
					fullWidth
					variant='outlined'
					type='number'
					inputProps={{ min: 0 }}
					InputProps={{
						startAdornment: <InputAdornment position='start'>W:</InputAdornment>,
					}}
					value={sizes.x}
					onChange={(ev) => onValueChange(0, ev.target.value)}
					className={`${classes.leftTextField} ${isHovered || isFocused ? classes.hover : ''} ${
						isFocused ? classes.focus : ''
					}`}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					fullWidth
					variant='outlined'
					type='number'
					inputProps={{ min: 0 }}
					InputProps={{
						startAdornment: <InputAdornment position='start'>H:</InputAdornment>,
					}}
					value={sizes.y}
					onChange={(ev) => onValueChange(1, ev.target.value)}
					className={`${classes.centerTextField} ${isHovered || isFocused ? classes.hover : ''} ${
						isFocused ? classes.focus : ''
					}`}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					fullWidth
					variant='outlined'
					type='number'
					inputProps={{ min: 0 }}
					InputProps={{
						startAdornment: <InputAdornment position='start'>D:</InputAdornment>,
					}}
					value={sizes.z}
					onChange={(ev) => onValueChange(2, ev.target.value)}
					className={`${classes.rightTextField} ${isHovered || isFocused ? classes.hover : ''} ${
						isFocused ? classes.focus : ''
					}`}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
			</Grid>
		</Grid>
	);
};
