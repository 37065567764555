import { CombinedIcon } from '@components/common/CombinedIcon';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';
import { CalendarMonth, Edit } from '@mui/icons-material';
import {
	Box,
	Chip,
	ChipProps,
	PaletteColor,
	SxProps,
	Theme,
	Tooltip,
	useTheme,
} from '@mui/material';
import { AppFunction } from 'common';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @description The properties of the expiration date chip
 * @property {Date | null | undefined} [expiresAt] - The expiration date of the product
 * @property {Date | null | undefined} [actualExpiration] - The actual expiration date of the product (if it was modified)
 */
interface ExpirationProps {
	expiresAt?: string[] | Date[] | null;
	actualExpiration?: string[] | Date[] | null;
	sx?: SxProps<Theme>;
}

export const MultipleChip = ({
	expiresAt,
	actualExpiration,
	sx,
}: ExpirationProps): JSX.Element | null => {
	const [calendarColors, setCalendarColors] = useState<PaletteColor[]>([]);
	const { timeZone, dateFormat } = useLocationTimeZone();
	const expirationDate = actualExpiration || expiresAt;
	const theme = useTheme();
	const { t } = useTranslation();

	const getColor = (expDate: string | Date | null): PaletteColor => {
		if (!expDate) return theme.palette.info;

		const exp = new Date(expDate);
		const now = new Date();
		let tempColor: PaletteColor = theme.palette.info;

		if (isNaN(exp.getTime())) {
			console.error('Invalid expiration date');
			return theme.palette.error;
		}

		const diffInMilliseconds = exp.getTime() - now.getTime();
		const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

		if (diffInMilliseconds < 0) {
			tempColor = theme.palette.error;
		} else if (diffInDays < 2) {
			tempColor = theme.palette.warning;
		} else if (diffInDays < 7) {
			tempColor = theme.palette.info;
		} else {
			tempColor = theme.palette.success;
		}
		return tempColor;
	};

	useEffect(() => {
		if (!expirationDate || expirationDate.length !== 2) return;

		const colorOne = getColor(expirationDate[0]);
		const colorTwo = getColor(expirationDate[1]);

		setCalendarColors([colorOne, colorTwo]);
	}, [expirationDate]);

	if (
		!expirationDate ||
		expirationDate.length !== 2 ||
		(actualExpiration && actualExpiration.length !== 2) ||
		calendarColors.length !== 2
	)
		return null;

	const modified =
		actualExpiration &&
		(!_.isEqual(actualExpiration?.[0], expiresAt?.[0]) ||
			!_.isEqual(actualExpiration?.[1], expiresAt?.[1]));
	return (
		<Tooltip
			title={`
                ${t(`${AppFunction.Inventory}.originalExpiration`)}: 
                ${moment(expiresAt?.[0]).tz(timeZone).format(dateFormat)} - 
                ${moment(expiresAt?.[1]).tz(timeZone).format(dateFormat)}
            `}
			disableFocusListener={!modified}
			disableHoverListener={!modified}
			disableTouchListener={!modified}
			placement='left'
			disableInteractive
		>
			<Chip
				label={moment(expirationDate[0]).tz(timeZone).format(dateFormat)}
				icon={
					modified ? (
						<CombinedIcon
							main={<CalendarMonth />}
							sub={<Edit />}
							sx={{ color: `${calendarColors[0].contrastText} !important` }}
							subSx={{ color: `${calendarColors[0].contrastText} !important`, fontSize: '0.6em' }}
							fontSize='1.3rem'
						/>
					) : (
						<CalendarMonth sx={{ color: `${calendarColors[0].contrastText} !important` }} />
					)
				}
				variant={modified ? 'outlined' : undefined}
				size='small'
				sx={{
					'& .MuiChip-label': {
						flexGrow: 1,
						display: 'flex',
						justifyContent: 'center',
					},
					display: 'flex',
					background: `linear-gradient(110deg, ${
						modified ? calendarColors[0].light : calendarColors[0].main
					} 40%, ${modified ? calendarColors[1].light : calendarColors[1].main} 60%)`,
					borderColor: calendarColors[0].dark,
					color: calendarColors[0].contrastText,
					cursor: modified ? 'help' : 'default',
					...sx,
				}}
			/>
		</Tooltip>
	);
};
