import { Offset } from 'common';
import { ParsedOffset } from './types';

export const parseOffset = (value: Offset): ParsedOffset => {
	if (!value) {
		return {
			months: 0,
			weeks: 0,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			weekDay: undefined,
		};
	}

	const [offset, weekday] = value.split(',');

	const parseUnit = (str: string, unit: string) => {
		const match = str.match(new RegExp(`(\\d+)${unit}`));
		const value = match ? parseInt(match[1]) : undefined;
		return value && value !== 0 ? value : undefined;
	};

	return {
		months: parseUnit(offset, 'M'),
		weeks: parseUnit(offset, 'w'),
		days: parseUnit(offset, 'd'),
		hours: parseUnit(offset, 'h'),
		minutes: parseUnit(offset, 'm'),
		seconds: parseUnit(offset, 's'),
		weekDay: weekday as ParsedOffset['weekDay'],
	};
};

export function updateProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
	obj[key] = value;
}
