import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/customers/type/GeneralInfo';
import { InputCustomerTypePricesGroup } from '@components/products/InputCustomerTypePricesGroup';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormAction, FormType } from '@contexts/formContext/types';
import useCustomerTypes from '@hooks/useCustomerTypes';
import { Settings } from '@mui/icons-material';
import { AppFunction, CustomerTypeResponse, CustomerTypeSchema } from 'common';

/**
 * CustomerTypeForm Props
 * @param {number} [forcedId] - The id of the customer type to be forced
 * @param {FormAction} formAction - The action to be performed
 * @param {FormType} [formType] - The type of the form ('full' or 'quick')
 * @param {(data: CustomerTypeResponse) => void} [onDataSaved] - Callback to be called when data is saved (used with quick forms)
 * @param {CustomerTypeResponse} [forcedValues] - The values to be forced in the form (used with quick forms)
 */
interface Props {
	forcedId?: number;
	formAction: FormAction;
	formType?: FormType;
	onDataSaved?: (data: CustomerTypeResponse) => void;
	forcedValues?: CustomerTypeResponse;
}

const CustomerTypeFormContent = withFormLogic<CustomerTypeResponse>(() => <></>);

export const CustomerTypeForm = ({
	forcedId,
	formAction,
	formType,
	onDataSaved,
	forcedValues,
}: Props) => {
	const { customerTypesList, createCustomerType, editCustomerType } = useCustomerTypes();

	return (
		<CustomerTypeFormContent
			forcedId={forcedId}
			formType={formType}
			formAction={formAction}
			list={customerTypesList}
			validationSchema={CustomerTypeSchema}
			createFunction={createCustomerType}
			editFunction={editCustomerType}
			defaultValues={
				forcedValues ?? {
					id: -1,
					canHaveChildren: false,
					canHaveParent: false,
				}
			}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			sideFabs={[FormSidefabType.Info, FormSidefabType.Notes]}
			appFunction={AppFunction.CustomerType}
			customAfterCreateLink='customerType/:id/edit'
			onDataSaved={onDataSaved}
		>
			{(id) => (
				<>
					<TabbedElement title='customerType.generalInfo'>
						<GeneralInfo />
					</TabbedElement>
					<TabbedElement title='customer.discountedPrice' onBeforeShowTab={() => id !== -1}>
						<InputCustomerTypePricesGroup customerTypeId={id} />
					</TabbedElement>
				</>
			)}
		</CustomerTypeFormContent>
	);
};
