import { IconButton, ImageListItem, TextField, useTheme } from '@mui/material';
import useFeedbacks from '../../hooks/useFeedbacks';
import useImages from '../../hooks/useImages';

import { ImageResponse } from 'common';
import { useState } from 'react';
import { EditShowGridField } from '../common/EditShowGridField';
import { Cancel, Delete, Edit, Save } from '@mui/icons-material';

import styles from './ImageListComponent.module.css';

interface Props {
	image: ImageResponse;
}

export const ImageListComponent = ({ image }: Props) => {
	const theme = useTheme();

	const { editImage, deleteImages } = useImages();
	const { showSnackBar } = useFeedbacks();
	const [name, setName] = useState(image.name);
	const [isEditing, setEditing] = useState(false);
	const [hover, setHover] = useState(false);

	const onStartEditing = () => {
		setEditing(true);
	};

	const onStopEditing = () => {
		setEditing(false);
	};

	const onEdit = async () => {
		await editImage({ url: image.url, id: image.id, name: name });
		showSnackBar({ message: 'image edited succesfully', severity: 'success' });
		onStopEditing();
	};

	const onDelete = async () => {
		await deleteImages({ ids: [image.id] });
		showSnackBar({ message: 'image deleted succesfully', severity: 'success' });
	};

	return (
		<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(isEditing)}>
			<ImageListItem className={styles.imageContainer}>
				<img
					src={image.url ?? null}
					alt={image.name ?? ''}
					loading='lazy'
					style={{
						filter: hover ? 'brightness(1.4)' : 'brightness(1)',
						transition: 'filter 500ms',
					}}
				/>
				<IconButton
					sx={{
						scale: isEditing ? '0' : '1',
						position: 'absolute',
						top: hover ? '4px' : '-4rem',
						right: '4px',
						backgroundColor: theme.palette.error.main,
						color: theme.palette.error.contrastText,
						transition: 'all 400ms',
						'&:hover': {
							backgroundColor: theme.palette.error.contrastText,
							color: theme.palette.error.main,
						},
					}}
					onClick={onDelete}
				>
					<Delete />
				</IconButton>
				<div className={styles.panel} style={{ bottom: hover ? 0 : '-4rem' }}>
					<EditShowGridField isEditing={isEditing}>
						<TextField
							value={name ?? 'unnamed'}
							onChange={(e) => setName(e.target.value)}
							sx={{ backgroundColor: 'white', borderRadius: '4px' }}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									onEdit();
								}
							}}
						/>
					</EditShowGridField>
					{isEditing ? (
						<>
							<IconButton
								onClick={() => onEdit()}
								sx={{
									scale: '0.8',
									backgroundColor: theme.palette.success.main,
									color: theme.palette.success.contrastText,
									'&:hover': {
										backgroundColor: theme.palette.success.contrastText,
										color: theme.palette.success.main,
									},
								}}
							>
								<Save />
							</IconButton>
							<IconButton
								onClick={() => {
									setName(image.name);
									onStopEditing();
								}}
								sx={{
									scale: '0.8',
									backgroundColor: theme.palette.error.main,
									color: theme.palette.error.contrastText,
									'&:hover': {
										backgroundColor: theme.palette.error.contrastText,
										color: theme.palette.error.main,
									},
								}}
							>
								<Cancel />
							</IconButton>
						</>
					) : (
						<IconButton
							onClick={onStartEditing}
							sx={{
								backgroundColor: theme.palette.info.main,
								color: theme.palette.info.contrastText,
								'&:hover': {
									backgroundColor: theme.palette.info.contrastText,
									color: theme.palette.info.main,
								},
							}}
						>
							<Edit />
						</IconButton>
					)}
				</div>
			</ImageListItem>
		</div>
	);
};
