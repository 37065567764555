import { ForwardedRef, useCallback, useEffect, useState } from 'react';
import useFeedbacks from '../../hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	AffectedRowsResponse,
	AppFunction,
	LocaleEntries,
	LocaleEntryAction,
	PersonJobTitleCreateSchema,
	PersonJobTitleResponse,
	PersonJobTitleSchema,
	TranslationTypes,
} from 'common';
import { Controller, useForm } from 'react-hook-form';
import useLocale from '../../hooks/useLocale';
import { zodResolver } from '@hookform/resolvers/zod';
import usePeople from '../../hooks/usePeople';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	LinearProgress,
	Switch,
	Typography,
} from '@mui/material';

import commonStyles from '../../styles/Common.module.css';
import _ from 'lodash';
import { PresetInputLangGroup } from '../../components/PresetInputLangGroup';
import { PayloadAction } from '@reduxjs/toolkit';
import processLocaleEntries from '../../helpers/processLocaleEntries';
import { EditShowGridField } from '../../components/common/EditShowGridField';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { TabbedSection } from '@components/tabs/TabbedSection';
import { TabFooterBody } from '@components/tabs/TabFooterBody';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { useLayout } from '@contexts/index';
import { Add, Badge, Edit, Settings } from '@mui/icons-material';
import { CombinedIcon } from '@components/common/CombinedIcon';

interface Props {
	innerRef?: ForwardedRef<unknown>;
	isModal?: boolean;
	closeModal?: (createdId?: number) => void;
	newName?: string;
	viewMode?: boolean;
}

export const JobTitleCreate = ({ isModal, closeModal, newName, viewMode }: Props) => {
	const { getCurrentLanguage, getTranslatedString } = useLocale();
	const { opStatus, jobTitlesList, createJobTitle, editJobTitle } = usePeople();
	const { showSnackBar } = useFeedbacks();
	const { t } = useTranslation();
	const { setBreadCrumbs } = useLayout();

	const { jobTitleId: routeId } = useParams();

	const [id, setId] = useState(routeId ? +routeId : -1);
	const [dirty, setDirty] = useState(true);
	const [isViewMode] = useState(viewMode != undefined ? viewMode : false);
	const [cachedData, setCachedData] = useState<PersonJobTitleResponse | null>(null);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<PersonJobTitleResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			id: id,
			strings: [
				{
					langCode: getCurrentLanguage(),
					value: newName ?? t('jobTitles.new'),
					type: TranslationTypes.name,
					action: LocaleEntryAction.CREATE,
				},
			],
		},
		resolver: zodResolver(PersonJobTitleSchema),
	});

	const refreshBreadCrumbs = () => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.jobTitles',
				route: '/settings/jobtitle',
				icon: <Badge />,
			},
			{
				label:
					id === -1
						? 'breadCrumbs.create'
						: getTranslatedString(AppFunction.JobTitle, id, TranslationTypes.name),
				icon: <CombinedIcon main={<Badge />} sub={id === -1 ? <Add /> : <Edit />} />,
			},
		]);
	};

	useEffect(() => {
		refreshBreadCrumbs();
	}, []);

	useEffect(() => {
		if (cachedData?.strings && cachedData.strings.length > 0) {
			refreshBreadCrumbs();
		}
	}, [id, cachedData]);

	useEffect(() => {
		if (isModal) {
			return;
		}

		if (id !== -1 && jobTitlesList.length > 0) {
			const toEditJobTitle = jobTitlesList.find((jt) => jt.id === id) as PersonJobTitleResponse;
			reset(toEditJobTitle);
			setCachedData(toEditJobTitle);
		}
	}, [id, jobTitlesList]);

	const onSubmit = useCallback(
		async (data: PersonJobTitleResponse) => {
			if (id === -1) {
				const createData = PersonJobTitleCreateSchema.safeParse(data);
				// if (createData.success) {
				// 	const newJobTitle = (await createJobTitle(
				// 		createData.data,
				// 	)) as PayloadAction<PersonJobTitleResponse>;
				// 	if (newJobTitle.type === 'people/jobtitles/create/fulfilled') {
				// 		showSnackBar({ message: t('people.jobTitles.createSuccess'), severity: 'success' });
				// 		closeModal?.(newJobTitle.payload.id);
				// 		if (!isModal) {
				// 			setId(newJobTitle.payload.id);
				// 		}
				// 		return;
				// 	} else {
				// 		showSnackBar({ message: t('people.jobTitles.createFail'), severity: 'error' });
				// 	}
				// }
				showSnackBar(t('people.jobTitles.createFail'));
			} else if (cachedData) {
				const newStrings = processLocaleEntries(data.strings ?? [], cachedData.strings ?? []);
				// const editResult = (await editJobTitle({
				// 	...data,
				// 	strings: newStrings,
				// })) as PayloadAction<AffectedRowsResponse>;
				// if (editResult.type === 'people/jobtitles/edit/fulfilled') {
				// 	showSnackBar({ message: t('people.jobTitles.editSuccess'), severity: 'success' });
				// 	return;
				// }
				showSnackBar(t('people.jobTitles.editFail'));
			}
		},
		[id, cachedData],
	);

	function FormContent(): JSX.Element {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant='overline' display='block' gutterBottom sx={{ fontSize: '0.9rem' }}>
						<span style={{ backgroundColor: 'white' }}>{t('jobTitles.jobTitle')}</span>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Controller
						name='strings'
						control={control}
						render={({ field }) => (
							<PresetInputLangGroup
								useReactHookForm
								type={TranslationTypes.name}
								strings={field.value ?? []}
								onChange={(data: LocaleEntries) => {
									field.onChange(data);
								}}
								disableEdit={isViewMode}
							/>
						)}
					/>
				</Grid>
				<Controller
					key='separateFromParent'
					name='mandatory'
					control={control}
					render={({ field }) => (
						<EditShowGridField width={12} isEditing={true} label>
							<FormControlLabel
								control={
									<Switch
										{...field}
										value={field.value ?? false}
										checked={field.value ?? false}
										onChange={(ev) => field.onChange(ev.target.checked)}
									/>
								}
								label={t('jobTitles.mandatory')}
							/>
						</EditShowGridField>
					)}
				/>
				{isModal && (
					<Grid item xs={12}>
						<Button
							type='submit'
							variant='outlined'
							onClick={handleSubmit(onSubmit)}
							disabled={opStatus === 'pending' || !_.isEmpty(errors)}
							fullWidth
							sx={{ marginTop: '24px', marginBottom: '10px' }}
						>
							{t('operations.save')}
						</Button>
					</Grid>
				)}
			</Grid>
		);
	}

	if (id !== -1 && cachedData === undefined) {
		return <CircularProgress />;
	} else if (isModal) {
		return (
			<div className={commonStyles.modalBox}>
				<FormContent />
			</div>
		);
	} else {
		return (
			<FadeWrapper>
				<Container
					maxWidth='md'
					sx={{
						position: 'relative',
					}}
				>
					<TabbedSection
						title={`${
							id === -1
								? 'create user'
								: `edit ${getTranslatedString(AppFunction.JobTitle, id, TranslationTypes.name)}`
						}`}
						hideMenu
						footerBody={
							<TabFooterBody
								canEdit={!isViewMode}
								showEditButton
								onSubmit={handleSubmit(onSubmit)}
							/>
						}
					>
						<TabbedElement title={t('jobTitles.jobTitle')}>
							<FormContent />
						</TabbedElement>
					</TabbedSection>
					{opStatus === 'pending' && <LinearProgress />}
				</Container>
			</FadeWrapper>
		);
	}
};
