import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import useUsers from '../../hooks/useUsers';

import { AppFunction, GenericIdRequest, TranslationTypes, UserResponse } from 'common';

import { Chip, Grid } from '@mui/material';
import { Column } from 'primereact/column';

import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Settings } from '@mui/icons-material';
import useLocale from '../../hooks/useLocale';
import { TableContextProvider } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { DropDownActionType } from '@contexts/tableContext/types';

const appFunction = AppFunction.User;

export const UserList = () => {
	const { userList, deleteUser } = useUsers();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { pushDialog } = useFeedbacks();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.users',
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const nameBodyTemplate = (rowData: UserResponse) => {
		if (rowData.person) {
			return (
				<>
					{rowData.person.name} {rowData.person.surname}
				</>
			);
		}
		return <>{t('user.nameNotSet')}</>;
	};

	const rolesBodyTemplate = (rowData: UserResponse) => {
		const roles: JSX.Element[] = [];
		rowData.roles?.map((r, it) => {
			roles.push(
				<Grid item key={it}>
					<Chip label={r.section?.name} size='small' />
				</Grid>,
			);
		});

		return roles.length > 0 ? (
			<Grid container spacing={1}>
				{roles}
			</Grid>
		) : (
			<>no roles</>
		);
	};

	const workplacesBodyTemplate = (rowData: UserResponse) => {
		const workplaces: JSX.Element[] = [];
		rowData.workplaces?.map((w, it) => {
			workplaces.push(
				<Grid item key={it}>
					<Chip
						label={getTranslatedString(AppFunction.Location, w.warehouseId, TranslationTypes.name)}
						size='small'
					/>
				</Grid>,
			);
		});

		return workplaces.length > 0 ? (
			<Grid container spacing={1}>
				{workplaces}
			</Grid>
		) : (
			<>{t(`${appFunction}.noWorkplaces`)}</>
		);
	};

	function callDeleteUserDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteUser(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			pageIdentifier={appFunction}
			values={userList}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: (data: GenericIdRequest) => callDeleteUserDialog(data),
				},
			]}
		>
			<Column field='name' header='nameSurname' body={nameBodyTemplate} />
			<Column field='userNumber' header='userNumber' />
			<Column body={rolesBodyTemplate} header='roles' />
			<Column body={workplacesBodyTemplate} header='workplaces' />
			<Column field='email' header='email' />
		</TableContextProvider>
	);
};
