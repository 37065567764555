import React, { useEffect, useState } from 'react';
import { DialogComponentProps, DialogResult } from '@contexts/feedbacksContext/types';
import { Button, FormControl, Stack, TextField } from '@mui/material';
import useOrders from '@hooks/useOrders';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export interface ModifyOrderDialogData {
	orderId: number;
}

export interface ModifyOrderDialogResult extends DialogResult {
	newDate: Date | null;
}

export const ModifyOrderDialog: React.FC<
	DialogComponentProps<ModifyOrderDialogResult, ModifyOrderDialogData>
> = ({ close, data }) => {
	const { t } = useTranslation();
	const { orderList } = useOrders();

	const [newDate, setNewDate] = useState<Date | null>(null);

	useEffect(() => {
		const order = orderList.find((order) => order.id === data?.orderId);
		if (order?.ead) {
			setNewDate(new Date(order.ead));
		}
	}, [data?.orderId, orderList]);

	const handleClose = () => {
		close({
			newDate: newDate,
		});
	};

	return (
		<Stack direction='column' spacing={2} sx={{ minWidth: '20rem' }}>
			<FormControl>
				<DatePicker
					label={t('order.estimatedTime')}
					value={moment(newDate)}
					disablePast
					onChange={(date) => {
						if (date) {
							setNewDate(date.toDate());
						}
					}}
					renderInput={(params) => <TextField {...params} />}
					PopperProps={{ style: { zIndex: 9999 } }}
				/>
			</FormControl>
			<Button onClick={handleClose} variant='contained'>
				{t('operations.modify')}
			</Button>
		</Stack>
	);
};
