import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/countries/GeneralInfo';
import { Regions } from '@components/Regions';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormAction } from '@contexts/formContext/types';
import useGeography from '@hooks/useGeography';
import { Settings } from '@mui/icons-material';
import { AppFunction, CountryResponse, CountrySchema } from 'common';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

const CountryFormContent = withFormLogic<CountryResponse>(() => <></>);

const CountryForm = ({ forcedId, formAction }: Props) => {
	const { countryList, createCountry, editCountry } = useGeography();

	return (
		<CountryFormContent
			forcedId={forcedId}
			formAction={formAction}
			list={countryList}
			validationSchema={CountrySchema}
			createFunction={createCountry}
			editFunction={editCountry}
			defaultValues={{ id: -1 }}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			sideFabs={[FormSidefabType.Image, FormSidefabType.Info, FormSidefabType.Notes]}
			appFunction={AppFunction.Country}
			customAfterCreateLink='country/:id/edit'
		>
			{(id) => (
				<>
					<TabbedElement title='country.generalInfo'>
						<GeneralInfo />
					</TabbedElement>
					<TabbedElement title='country.regions' onBeforeShowTab={() => id !== -1}>
						<Regions />
					</TabbedElement>
				</>
			)}
		</CountryFormContent>
	);
};

export default CountryForm;
