import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useNotes from '@hooks/useNotes';
import { NoteAdd } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { NoteComponent } from './NoteComponent';

export const NotesTab = () => {
	const { entityId, appFunction } = useGenericForm();
	const { noteList } = useNotes();

	const [editingNote, setEditingNote] = useState<number>(0);

	const notes = noteList?.filter(
		(note) => note.entityId === entityId && note.entityType === appFunction,
	);

	if (!appFunction || !entityId) return null;

	return (
		<Stack
			sx={{
				margin: '2rem 0 2rem 0',
				padding: '1rem',
				borderRadius: '2rem',
				backgroundColor: 'white',
			}}
			direction='column'
			spacing={2}
		>
			{notes?.map((note) => {
				return (
					<NoteComponent
						key={note.id}
						noteData={note}
						onClose={() => setEditingNote(0)}
						onEdit={() => setEditingNote(note.id)}
						entityType={appFunction}
						entityId={entityId}
						editingNote={editingNote}
					/>
				);
			})}
			{editingNote === -1 && (
				<NoteComponent
					onClose={() => setEditingNote(0)}
					onEdit={() => setEditingNote(-1)}
					entityType={appFunction}
					entityId={entityId}
					editingNote={-1}
				/>
			)}
			{editingNote === 0 && (
				<div
					style={{
						// width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '1rem',
					}}
				>
					<AnimatedIconButton
						text='add note'
						colorType='info'
						icon={<NoteAdd />}
						onClick={() => setEditingNote(-1)}
					/>
				</div>
			)}
		</Stack>
	);
};
