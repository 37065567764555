import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	GenericIdSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderBaseSchema,
	OrderProductResponse,
	OrderProductsUpdateRequest,
	OrderProductsUpdateSchema,
	OrderTemplateRequest,
	OrdersBaseResponse,
} from 'common';
import {
	doCreateOrder,
	doCreateTemplate,
	doDeleteOrder,
	doEditOrder,
	doEditOrderContent,
} from '../store/order';
import { PayloadAction } from '@reduxjs/toolkit';

function useOrders() {
	const dispatch = useAppDispatch();
	const ordersSlice = useAppSelector((state) => state.orders.orders);
	const templatesSlice = useAppSelector((state) => state.orders.orderTemplates);

	const [templateList, setTemplateList] = useState<OrderTemplateRequest[]>([]);
	const [orderList, setOrderList] = useState<OrdersBaseResponse>([]);

	useEffect(() => {
		setTemplateList((oldTemplates) => {
			if (!_.isEqual(oldTemplates, templatesSlice)) {
				return templatesSlice;
			}
			return oldTemplates;
		});
	}, [templatesSlice]);

	useEffect(() => {
		setOrderList((oldOrders) => {
			if (!_.isEqual(oldOrders, ordersSlice)) {
				return ordersSlice;
			}
			return oldOrders;
		});
	}, [ordersSlice]);

	const createTemplate = async (data: OrderTemplateRequest) => {
		return await dispatch(doCreateTemplate(data));
	};

	const createOrder = async (data: OrderBaseRequest) => {
		return await dispatch(doCreateOrder(data));
	};

	const modifyOrder = async (data: OrderBaseResponse) => {
		data.createdAt = undefined;
		const parsedData = OrderBaseSchema.safeParse(data);
		if (parsedData.success) {
			return await dispatch(doEditOrder(parsedData.data));
		}
		console.log('Invalid data', parsedData.error);
		return Promise.reject('Invalid data');
	};

	const modifyOrderContent = async (
		data: OrderProductsUpdateRequest,
	): Promise<AffectedRowsResponse> => {
		const parsedData = OrderProductsUpdateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid data');
		}
		const response = (await dispatch(
			doEditOrderContent(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'orders/edit/content/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing order content');
	};

	const deleteOrder = async (data: GenericIdRequest) => {
		const parsedData = GenericIdSchema.safeParse(data);
		if (parsedData.success) {
			return await dispatch(doDeleteOrder(parsedData.data));
		}
		return Promise.reject('Invalid data');
	};

	const findContentById = (id: number): OrderProductResponse | undefined => {
		for (const order of orderList) {
			if (!order.content) continue;
			const found = order.content.find((content) => content.id === id);
			if (found) return found;
		}
		return undefined;
	};

	const isSameOrRelated = (
		targetId: number,
		currentId: number,
		visited: Set<number> = new Set(),
	): boolean => {
		if (visited.has(currentId)) {
			return false;
		}
		visited.add(currentId);

		const content = findContentById(currentId);
		// No content found, end of chain
		if (!content) {
			return false;
		}

		// Direct match found
		if (content.sourceId === targetId || targetId === currentId) {
			return true;
		}

		// No further sourceId to check, end of chain
		if (content.sourceId === null || content.sourceId === undefined) {
			return false;
		}

		return isSameOrRelated(targetId, +content.sourceId, visited);
	};

	const findHighestParent = (orderId: number): OrderBaseResponse | undefined => {
		const parentOrder = orderList.find((o) => o.id === orderId);
		if (!parentOrder) return undefined;
		if (parentOrder.parentId) {
			const nextParentOrder = orderList.find((o) => o.id === parentOrder.parentId);
			if (!nextParentOrder) {
				return parentOrder;
			}
			return findHighestParent(parentOrder.parentId);
		} else {
			return parentOrder;
		}
	};

	return {
		templateList,
		orderList,
		isSameOrRelated,
		findHighestParent,
		findContentById,
		createTemplate,
		createOrder,
		modifyOrder,
		modifyOrderContent,
		deleteOrder,
	};
}

export default useOrders;
