import { useEffect } from 'react';
import { Column } from 'primereact/column';

import useGeography from '@hooks/useGeography';
import useLocale from '@hooks/useLocale';

import { AppFunction, GenericIdRequest, TranslationTypes } from 'common';

import { TableContextProvider, useFeedbacks, useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { Settings } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const appFunction = AppFunction.RegionType;

export const RegionTypeList = () => {
	const { regionTypeList, deleteRegionType } = useGeography();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	function callDeleteRegionTypeDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteRegionType(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={regionTypeList}
			pageIdentifier={appFunction}
			hideCreate
			// actions={[
			// 	{ type: DropDownActionType.edit },
			// 	{ type: DropDownActionType.details },
			// 	{ type: DropDownActionType.delete,
			// 		action: callDeleteRegionTypeDialog
			// 	},
			// ]}
		>
			<Column
				field='name'
				header='name'
				body={(data) => getTranslatedString(AppFunction.RegionType, data.id, TranslationTypes.name)}
			/>
			<Column
				field='countryId'
				header='country'
				body={(data) =>
					getTranslatedString(AppFunction.Country, data.countryId, TranslationTypes.name)
				}
			/>
		</TableContextProvider>
	);
};
