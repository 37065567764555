import { Box, InputAdornment, Slide, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment, useState } from 'react';
import { FieldArchetypeDrawer } from './drawers/FieldArchetypeDrawer';
import { FieldSetDrawer } from './drawers/FieldSetDrawer';
import { TaskArchetypeDrawer } from './drawers/TaskArchetypeDrawer';

interface Props {
	test?: string;
	tabs?: TaskEditorSidebarTab[];
}

export enum TaskEditorSidebarTab {
	FieldArchetype = 'FieldArchetype',
	TaskArchetype = 'TaskArchetype',
	FieldSet = 'FieldSet',
}

export const TaskEditorSidebar = ({ tabs }: Props) => {
	const [search, setSearch] = useState<string>('');

	return (
		<Fragment>
			<Slide direction='left' in={tabs && tabs?.length > 0} mountOnEnter unmountOnExit>
				<Box
					sx={{
						backgroundColor: 'white',
						height: '95vh',
						position: 'fixed',
						right: 0,
						top: 64,
						zIndex: 9999,
					}}
				>
					<div
						style={{ padding: '0 1rem 0 1rem', margin: '1rem 0 1rem 0' }}
						id='chain-editor-sidebar'
					>
						<TextField
							size='small'
							fullWidth
							label='search'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</div>
					{tabs &&
						tabs.map((tab) => {
							switch (tab) {
								case TaskEditorSidebarTab.FieldArchetype:
									return <FieldArchetypeDrawer key='field-archetype-list' searchWord={search} />;
								case TaskEditorSidebarTab.TaskArchetype:
									return <TaskArchetypeDrawer key='task-archetype-list' searchWord={search} />;
								case TaskEditorSidebarTab.FieldSet:
									return <FieldSetDrawer key='field-set-list' searchWord={search} />;
								default:
									return <></>;
							}
						})}
				</Box>
			</Slide>
		</Fragment>
	);
};
