import { CompactMultipleTask } from '@components/taskworkflow/tasks/widgets/CompactMultipleTask';
import { CompactTask } from '@components/taskworkflow/tasks/widgets/CompactTask';
import { useAuth } from '@hooks/auth';
import useTasks from '@hooks/useTasks';
import { Box, FormControlLabel, List, Switch, useTheme } from '@mui/material';
import { AppFunction, TaskResponse, TaskStatus, TasksResponse } from 'common';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	tasksType: 'today' | 'assigned';
}

export const TaskBox = ({ tasksType }: Props) => {
	const { t } = useTranslation();
	const { taskList } = useTasks();
	const { user } = useAuth();
	const theme = useTheme();

	const [switchState, setSwitchState] = useState(false);
	const [title, setTitle] = useState('');

	const filterAction = useCallback(
		(task: TaskResponse): boolean => {
			switch (tasksType) {
				case 'today': {
					const value =
						!task.assignedTo &&
						task.status !== TaskStatus.blocked &&
						moment(task.assignableAt).startOf('day').isSame(moment().startOf('day'));

					setTitle(`${AppFunction.Task}.unassigned`);
					return value;
				}
				case 'assigned': {
					const value =
						task.assignedTo === user?.id &&
						task.status !== TaskStatus.blocked &&
						task.status !== TaskStatus.completed &&
						moment(task.assignableAt).startOf('day').isSame(moment().startOf('day'));

					setTitle(`${AppFunction.Task}.assignedToMe`);
					return value;
				}
			}
		},
		[tasksType, user],
	);

	const renderedTasks = useMemo((): JSX.Element => {
		if (switchState) {
			const looseTasks = taskList.filter(filterAction);
			const groupedTasks: Record<string, TasksResponse> = {};
			looseTasks.forEach((task) => {
				if (groupedTasks[task.archetypeId]) {
					groupedTasks[task.archetypeId].push(task);
				} else {
					groupedTasks[task.archetypeId] = [task];
				}
			});

			const elements: JSX.Element[] = [];
			Object.values(groupedTasks).map((grouped, it) => {
				elements.push(<CompactMultipleTask tasks={grouped} key={it} it={it} />);
			});

			return <>{elements}</>;
		} else {
			return (
				<>
					{taskList.filter(filterAction).map((task, it) => (
						<CompactTask key={task.id} task={task} it={it} />
					))}
				</>
			);
		}
	}, [taskList, switchState, filterAction]);

	return (
		<>
			<Box
				sx={{
					background: theme.palette.gradient.primaryAlpha,
					borderRadius: '1rem',
					padding: 1,
					marginBottom: 1,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				{t(title)}
				<FormControlLabel
					control={
						<Switch
							checked={switchState}
							onChange={() => setSwitchState((oldState) => !oldState)}
							color='primary'
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					}
					label={t(`${AppFunction.Task}.showGrouped`)}
					labelPlacement='start'
				/>
			</Box>
			<List
				sx={{
					background: theme.palette.gradient.primaryAlpha,
					borderRadius: '1rem',
					padding: 1,
					height: '100%',
					overflowY: 'auto',
				}}
			>
				{renderedTasks}
			</List>
		</>
	);
};
