import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { Chip, SxProps, Theme } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';

interface Props {
	warehouseId: number;
	sx?: SxProps<Theme>;
}

export const WarehouseChip = ({ warehouseId, sx }: Props): JSX.Element | null => {
	const { warehouseList } = useWarehouses();
	const { getIcon } = useAppFunctions();
	const { getTranslatedString } = useLocale();

	const warehouse = warehouseList.find((warehouse) => warehouse.id === warehouseId);

	if (!warehouse) {
		return null;
	}

	return (
		<Chip
			size='small'
			label={getTranslatedString(AppFunction.Location, warehouse.id, TranslationTypes.name)}
			icon={getIcon(AppFunction.Location, warehouse.storageType) ?? <></>}
			sx={{
				...sx,
			}}
		/>
	);
};
