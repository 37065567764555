import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { OrderTreeNode } from '@pages/Orders/OrderDetails';
import { OrderBaseResponse, OrderProductResponse } from 'common';
import { OrderTree } from './OrderTree';
import { OrderDirection } from './OrderDirection';
import { OrderStatusChip } from './OrderStatusChip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	data: OrderBaseResponse;
	orderTree: OrderTreeNode;
	afterTree?: OrderTreeNode[];
	beforeTree?: OrderTreeNode[];
	selectSuborder?: (subOrderId: number) => void;
	selectedSubOrder?: number;
	orderProduct?: OrderProductResponse;
}

export const OrderHeader = ({
	data,
	orderTree,
	afterTree,
	beforeTree,
	selectSuborder,
	selectedSubOrder,
	orderProduct,
}: Props) => {
	const [onlyRelated, setOnlyRelated] = useState(true);
	const [showAfterTree, setShowAfterTree] = useState(false);
	const { t } = useTranslation();

	return (
		<Container
			maxWidth={false}
			sx={{
				margin: '1rem 0 1rem 0',
				backgroundColor: 'white',
				borderRadius: '1rem',
				padding: '1rem 0 1rem 0',
			}}
		>
			<Grid container item xs={12} spacing={2}>
				<Grid item>
					<Typography
						variant='h4'
						sx={{
							fontWeight: 'bold',
						}}
					>
						{orderTree.code?.toUpperCase()}
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<OrderStatusChip data={data} />
				</Grid>
				<Grid
					item
					xs={12}
					container
					display='flex'
					sx={{
						marginTop: '1rem',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Grid
						item
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<OrderDirection
							type={orderTree.fromType}
							id={orderTree.fromId}
							status={orderTree.status}
						/>
					</Grid>
					<Grid item>
						<OrderTree
							orderChildren={[orderTree]}
							selectSuborder={selectSuborder}
							selectedSubOrder={selectedSubOrder}
							afterTree={showAfterTree ? afterTree : []}
							orderProductId={orderProduct?.id ? +orderProduct.id : undefined}
							onlyRelated={onlyRelated}
						/>
					</Grid>
				</Grid>
				{orderProduct && (
					<Grid item xs={12}>
						<Stack direction='row' justifyContent='left' spacing={1}>
							<Button
								onClick={() => setOnlyRelated((oldValue) => !oldValue)}
								size='small'
								variant='contained'
							>
								{onlyRelated ? t('order.showFullTree') : t('order.showOnlyRelated')}
							</Button>
							{afterTree && afterTree.length > 0 && (
								<Button
									onClick={() => setShowAfterTree((oldValue) => !oldValue)}
									size='small'
									variant='contained'
								>
									{showAfterTree ? t('order.hideAfterTree') : t('order.showAfterTree')}
								</Button>
							)}
						</Stack>
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
