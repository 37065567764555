import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store/index';
import reportWebVitals from './reportWebVitals';
import CacheBuster from 'react-cache-buster';
import './i18n';
import './index.css';
import * as serviceWorkerRegistration from './service-worker-registration';
import { checkRefreshToken } from './store/cookies';
import process from 'process';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	// <React.StrictMode>
	<CacheBuster
		currentVersion={process.env.REACT_APP_VERSION}
		isEnabled={process.env.REACT_APP_CACHE_BUSTER === 'true'}
		isVerboseMode={true}
	>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</CacheBuster>,
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

checkRefreshToken();

serviceWorkerRegistration.register();
