import { Box, Button, Chip, Collapse, Grid, IconButton, MenuItem, Select } from '@mui/material';
import { AppFunction, AssignableTo, TranslationTypes } from 'common';
import { useEffect, useState } from 'react';
import useUsers from '../../hooks/useUsers';
import { Cancel, Delete, KeyboardArrowRight } from '@mui/icons-material';
import useWarehouses from '../../hooks/useWarehouses';
import useLocale from '../../hooks/useLocale';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { useTranslation } from 'react-i18next';

interface Props {
	value: AssignableTo;
	isEditing: boolean;
	onChange: (value: AssignableTo) => void;
	dontFold?: boolean;
}

export const AssignableToInput = ({ value, isEditing, onChange, dontFold }: Props) => {
	const { userList, roleList } = useUsers();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const [open, setOpen] = useState(dontFold ?? false);

	useEffect(() => {
		setOpen(isEditing);
	}, [isEditing]);

	const getUserName = (id: number) => {
		const user = userList.find((u) => u.id === id);
		return user?.person?.name + ' ' + user?.person?.surname;
	};

	const handleDelete = (id: number) => {
		const newUsers = value.users.filter((u) => u !== id);
		onChange({
			...value,
			users: newUsers,
		});
	};

	const setUsersToValue = (users: number[]) => {
		onChange({
			...value,
			users,
		});
	};

	const handleAddRoleWorkplace = () => {
		onChange({
			...value,
			roleWorkplaces: [
				...value.roleWorkplaces,
				{
					sectionId: -1,
					warehouseId: -1,
				},
			],
		});
	};

	const handleDeleteRoleWorkplace = (index: number) => {
		const newRoleWorkplaces = value.roleWorkplaces.filter((rw, i) => i !== index);
		onChange({
			...value,
			roleWorkplaces: newRoleWorkplaces,
		});
	};

	return (
		<>
			{!dontFold && (
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						cursor: 'pointer',
						userSelect: 'none',
					}}
					onClick={() => setOpen((state) => !state)}
				>
					<KeyboardArrowRight sx={{ transform: `rotate(${open ? 90 : 0}deg)` }} />
					<div>Assignable To</div>
				</Grid>
			)}
			<Collapse in={open} sx={{ width: '100%', marginBottom: '0.5rem' }}>
				<Grid container rowGap={2}>
					<Grid item container rowGap={1}>
						{value.roleWorkplaces !== undefined &&
							value.roleWorkplaces.map((roleWorkplace, index) => {
								return (
									<Grid
										item
										key={index}
										xs={12}
										sx={{
											padding: '1rem',
											border: '1px solid #000000',
											borderRadius: '5px',
											marginBottom: '2px',
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}
										container
										gap={1}
									>
										<Grid item container spacing={1} flexGrow={1} flexBasis={0}>
											<InputGridField
												width={isEditing ? 12 : 6}
												muiLabel={{
													label: 'Role',
													labelId: `role-label-${index}`,
												}}
												type={ComponentType.Select}
											>
												<Select
													labelId={`role-label-${index}`}
													id='role'
													label='Role'
													size='small'
													fullWidth
													disabled={!isEditing}
													value={roleWorkplace.sectionId}
													onChange={(e) => {
														const newRoleWorkplaces = [...value.roleWorkplaces];
														newRoleWorkplaces[index].sectionId = e.target.value as number;
														onChange({
															...value,
															roleWorkplaces: newRoleWorkplaces,
														});
													}}
												>
													<MenuItem value={-1}>
														<em>None</em>
													</MenuItem>
													{roleList.map((role) => (
														<MenuItem key={role.id} value={role.id}>
															{role.name}
														</MenuItem>
													))}
												</Select>
											</InputGridField>
											<InputGridField
												width={isEditing ? 12 : 6}
												muiLabel={{
													label: 'Workplace',
													labelId: `warehouse-label-${index}`,
												}}
												type={ComponentType.Select}
											>
												<Select
													labelId={`warehouse-label-${index}`}
													id='warehouse'
													label='Workplace'
													fullWidth
													disabled={!isEditing}
													size='small'
													value={roleWorkplace.warehouseId}
													onChange={(e) => {
														const newRoleWorkplaces = [...value.roleWorkplaces];
														newRoleWorkplaces[index].warehouseId = e.target.value as number;
														onChange({
															...value,
															roleWorkplaces: newRoleWorkplaces,
														});
													}}
												>
													<MenuItem value={-1}>
														<em>None</em>
													</MenuItem>
													{warehouseList.map((warehouse) => (
														<MenuItem key={warehouse.id} value={warehouse.id}>
															{getTranslatedString(
																AppFunction.Location,
																warehouse.id,
																TranslationTypes.name,
															)}
														</MenuItem>
													))}
												</Select>
											</InputGridField>
										</Grid>
										{isEditing && (
											<Grid item>
												<IconButton onClick={() => handleDeleteRoleWorkplace(index)}>
													<Delete />
												</IconButton>
											</Grid>
										)}
									</Grid>
								);
							})}
						{isEditing && (
							<Grid item xs={12}>
								<Button variant='outlined' fullWidth onClick={handleAddRoleWorkplace}>
									{t(`${AppFunction.TaskArchetype}.addRoleWorkplace`)}
								</Button>
							</Grid>
						)}
					</Grid>
					<InputGridField
						type={ComponentType.Select}
						muiLabel={{
							label: t(`${AppFunction.TaskArchetype}.assignToUsers`),
							labelId: 'assignable-to-label',
						}}
					>
						<Select
							labelId='assignable-to-label'
							id='assignable-to'
							label={t(`${AppFunction.TaskArchetype}.assignToUsers`)}
							multiple
							fullWidth
							size='small'
							value={value.users}
							onChange={(e) => setUsersToValue(e.target.value as number[])}
							disabled={!isEditing}
							renderValue={(selected) => {
								return (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => (
											<Chip
												sx={{ zIndex: 200 }}
												size='small'
												key={value}
												label={getUserName(value)}
												onMouseDown={(e) => e.stopPropagation()}
												deleteIcon={<Cancel />}
												onDelete={() => handleDelete(value)}
											/>
										))}
									</Box>
								);
							}}
						>
							{userList.map((user) => (
								<MenuItem key={user.id} value={user.id}>
									{user.person?.name} {user.person?.surname}
								</MenuItem>
							))}
						</Select>
					</InputGridField>
				</Grid>
			</Collapse>
		</>
	);
};
