import { EditShowGridField } from '@components/common/EditShowGridField';
import {
	DirectionType,
	createAdornment,
} from '@components/taskworkflow/chain/DestinationTypeSelector';
import { useChainArchetypeEditor } from '@contexts/index';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, OrderDirectionType, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
	directionType: DirectionType;
}

export const CustomerSelector = ({ directionType }: Props) => {
	const { triggerControl, setTriggerValue, watchTrigger } = useChainArchetypeEditor();
	const { customerList } = useCustomers();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const currentType = directionType === 'from' ? watchTrigger('fromType') : watchTrigger('toType');
	const currentId = directionType === 'from' ? watchTrigger('fromId') : watchTrigger('toId');

	if (
		currentType !== OrderDirectionType.customer &&
		currentType !== OrderDirectionType.branch &&
		currentId !== -1
	) {
		return null;
	}

	const getValue = (value: number): number => {
		if (currentType === OrderDirectionType.customer) {
			return customerList.some((c) => c.id === value) || value === -2 ? value : -1;
		} else if (currentType === OrderDirectionType.branch) {
			const customer = customerList.find((c) => c.customerBranches?.some((b) => b.id === value));
			if (customer) {
				return customer.id ?? -1;
			}
		}
		return -1;
	};

	return (
		<Controller
			name={directionType === 'from' ? 'fromId' : 'toId'}
			control={triggerControl}
			render={({ field }) => (
				<EditShowGridField isEditing={true} width={12}>
					<InputLabel id='customer-select-label'>
						{t(`appFunctions.${AppFunction.Customer}`)}
					</InputLabel>
					<Select
						id='customer-select'
						fullWidth
						label={t(`appFunctions.${AppFunction.Customer}`)}
						labelId='customer-select-label'
						value={getValue(field.value ?? -2)}
						size='small'
						onChange={(e) => {
							const value = e.target.value as number;
							if (!value || isNaN(value)) {
								return;
							}
							if (value === -1) {
								setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							} else {
								setTriggerValue(
									directionType === 'from' ? 'fromType' : 'toType',
									OrderDirectionType.customer,
								);
							}
							field.onChange(value);
						}}
						endAdornment={createAdornment(field.value ?? -2, () => {
							setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							field.onChange(-1);
						})}
					>
						<MenuItem
							value={-1}
							sx={{
								backgroundColor: 'rgba(255, 0, 0, 0.2)',
							}}
						>
							{t('common.none')}
						</MenuItem>
						<MenuItem
							value={-2}
							sx={{
								backgroundColor: 'rgba(0, 255, 0, 0.2)',
							}}
						>
							{t('common.all')}
						</MenuItem>
						{customerList.map((customer) => {
							if (customer.id) {
								return (
									<MenuItem key={`customer_${customer.id}`} value={customer.id}>
										{getTranslatedString(AppFunction.Customer, customer.id, TranslationTypes.name)}
									</MenuItem>
								);
							}
						})}
					</Select>
				</EditShowGridField>
			)}
		/>
	);
};
