import { useEffect } from 'react';

import { useLayout } from '@contexts/layoutContext/LayoutContext';
import { TableContextProvider } from '@contexts/index';
import useTasks from '@hooks/useTasks';

import { Column } from 'primereact/column';
import { AppFunction, ChainArchetypeResponse, GenericIdRequest } from 'common';

import { ChainListDirectionTemplate } from '@components/taskworkflow/list/ChainListDirectionTemplate';

import { Settings } from '@mui/icons-material';
import useAppFunctions from '@hooks/useAppFunctions';
import { useTranslation } from 'react-i18next';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { DropDownActionType } from '@contexts/tableContext/types';

const appFunction = AppFunction.TaskChainArchetype;

export const ChainArchetypesList = () => {
	const { chainArchetypesList, deleteTaskChainArchetype, triggerList } = useTasks();
	const { pushDialog } = useFeedbacks();
	const { t } = useTranslation();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const chainDirectionTemplate = (rowData: ChainArchetypeResponse) => {
		const trigger = triggerList.find((trigger) => trigger.id === rowData.triggerId);
		if (trigger) {
			return <ChainListDirectionTemplate trigger={trigger} />;
		}
		return <></>;
	};

	const triggerTypeTemplate = (rowData: ChainArchetypeResponse) => {
		const trigger = triggerList.find((trigger) => trigger.id === rowData.triggerId);
		if (trigger) {
			return <>{t(`${appFunction}.triggerTypes.${trigger.subtype}`)}</>;
		}
		return <></>;
	};

	function callDeleteChainDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteTaskChainArchetype(+data.id).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			pageIdentifier={appFunction}
			values={chainArchetypesList}
			actions={[
				{ type: DropDownActionType.edit },
				{
					type: DropDownActionType.delete,
					action: (data: GenericIdRequest) => callDeleteChainDialog(data),
				},
			]}
		>
			<Column field='name' header='name' sortable />
			<Column field='trigger.subtype' header='triggerType' body={triggerTypeTemplate} sortable />
			<Column field='direction' header='direction' body={chainDirectionTemplate} sortable />
			<Column
				field='taskArchetypeIds'
				header='tasks'
				body={(rowData) => rowData.taskArchetypeIds?.length ?? 'No Tasks in this chain'}
			/>
		</TableContextProvider>
	);
};
