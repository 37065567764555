import React, { useState } from 'react';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogComponentProps, DialogResult } from '@contexts/feedbacksContext/types';
import useOrders from '@hooks/useOrders';
import { OrderType } from 'common';

export interface DownloadPdfDialogData {
	orderId: number;
}

export const DownloadPdfDialog: React.FC<
	DialogComponentProps<DialogResult, DownloadPdfDialogData>
> = ({ close, data }) => {
	const { t } = useTranslation();
	const { orderList } = useOrders();
	const [language, setLanguage] = useState('jp');
	const [pdfType, setPdfType] = useState<string>('pickingList');
	const [fullOrder, setFullOrder] = useState(true);

	const order = orderList.find((order) => order.id === data?.orderId);

	if (!order) {
		return null;
	}

	const handleSave = () => {
		close({
			orderId: order.id,
			language,
			fullOrder,
			pdfType,
		});
	};

	return (
		<Stack direction='column' spacing={2} sx={{ minWidth: '20rem' }}>
			<FormControl>
				<InputLabel id='language-select'>{t('order.language')}</InputLabel>
				<Select
					label={t('order.language')}
					labelId='language-select'
					name='language'
					value={language}
					onChange={(e) => setLanguage(e.target.value)}
					fullWidth
					MenuProps={{
						style: { zIndex: 9999 },
					}}
				>
					<MenuItem value='jp'>{t('langs.jp')}</MenuItem>
					<MenuItem value='it'>{t('langs.it')}</MenuItem>
					<MenuItem value='en'>{t('langs.en')}</MenuItem>
				</Select>
			</FormControl>
			{order.orderType === OrderType.Outbound && (
				<FormControl>
					<InputLabel id='pdf-type-select'>{t('order.output.type')}</InputLabel>
					<Select
						label={t('order.output.type')}
						labelId='pdf-type-select'
						name='documentType'
						value={pdfType ?? 'pickingList'}
						onChange={(e) => setPdfType(e.target.value)}
						fullWidth
						MenuProps={{
							style: { zIndex: 9999 },
						}}
					>
						<MenuItem value='pickingList'>{t('order.output.types.pickingList')}</MenuItem>
						<MenuItem value='invoice'>{t('order.output.types.invoice')}</MenuItem>
					</Select>
				</FormControl>
			)}
			{order.orderType === OrderType.Inbound && (
				<ToggleButton
					value='check'
					color='success'
					selected={fullOrder}
					onChange={() => setFullOrder(!fullOrder)}
					fullWidth
				>
					{fullOrder ? t('order.fullOrder') : t('order.partialOrder')}
				</ToggleButton>
			)}
			<Button
				onClick={handleSave}
				variant='contained'
				sx={{
					borderRadius: '2rem',
				}}
			>
				{t('operations.download')}
			</Button>
		</Stack>
	);
};
