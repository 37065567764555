import { Box, Grid, Typography, useTheme } from '@mui/material';
import { TaskResponse, TaskStatus } from 'common';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { useAppSelector } from '@store/hooks';
import { TaskFieldView } from './TaskFieldView';
import { TaskProvider } from '@contexts/index';
import { CloseTaskButton } from '../../shared/CloseTaskButton';
import { SaveTaskStubButton } from '../../shared/SaveTaskStubButton';
import useTasks from '@hooks/useTasks';
import { OrderButton } from '../../shared/OrderButton';

interface Props {
	taskId: number;
	popupId?: string;
}

export const TaskView = ({ taskId, popupId }: Props) => {
	const { getOrderFromTask } = useTasks();

	const chains = useAppSelector((state) => state.tasks.activeChains);
	const tasks = useAppSelector((state) => state.tasks.tasks);
	const theme = useTheme();
	const task = tasks.find((task) => task.id === taskId) as TaskResponse;

	// TODO: we are assuming that the targetType of the chain is an order
	// this will be true for most of the cases, and surely during MVP phase.
	// However, we should handle this properly in the future.
	const orderId = chains.find((chain) => chain.id === task.chainId)?.targetId ?? -1;
	const order = getOrderFromTask(task);

	return (
		<FadeWrapper fadeTime={500}>
			<Box
				sx={{
					borderRadius: '1rem',
					paddingTop: 2,
					paddingBottom: 2,
					boxShadow: '0 0 1rem 0 rgba(0,0,0,0.1)',
					background: theme.palette.gradient.secondary,
					position: 'relative',
					height: '100%',
				}}
			>
				<TaskProvider task={task} targetId={orderId}>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
						<Grid container display='flex'>
							<Grid
								item
								flexShrink={1}
								sx={{
									padding: 2,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography variant='h5'>{task.title}</Typography>
							</Grid>
							{order && (
								<Grid
									item
									sx={{
										padding: 2,
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<OrderButton orderId={order.id} />
								</Grid>
							)}
						</Grid>
						<Box
							sx={{
								backgroundColor: 'white',
								borderRadius: '20px',
								overflow: 'auto',
								flexGrow: 1,
								maxHeight: '84vh',
							}}
						>
							{/* TODO: we need to take account of fieldsets when showing the fields
									it can be done later since it doesn't create functionality problems */}
							{task.archetype?.taskFieldArchetypeId.map((field, it) => (
								// TODO: we are under a TaskProvider, so we can get all the data we need from the context
								// make this (including the map) a single component that gets the data from the context
								<TaskFieldView
									key={`taskfield-${it}-${field}`}
									archetypeId={field}
									targetId={orderId}
								/>
							))}
						</Box>
						<Grid container justifyContent='flex-end' sx={{ padding: 2 }}>
							{task.status !== TaskStatus.pending && (
								<Grid item xs={6}>
									<SaveTaskStubButton popupId={popupId} />
								</Grid>
							)}
							<Grid item xs={task.status === TaskStatus.pending ? 12 : 6}>
								<CloseTaskButton popupId={popupId} />
							</Grid>
						</Grid>
					</div>
				</TaskProvider>
			</Box>
		</FadeWrapper>
	);
};
