import {
	BuyingPricesRequest,
	GenericIdsRequest,
	PriceCustomersRequest,
	PriceTypesRequest,
} from 'common';
import { useAppDispatch } from '../store/hooks';
import {
	doDeleteBuyingPrices,
	doEditBuyingPrices,
	doDeleteCustomerTypePrices,
	doEditCustomerTypePrices,
	doEditCustomerPrices,
	doDeleteCustomerPrices,
} from '../store/products';
import useProducts from './useProducts';

function usePrices() {
	const dispatch = useAppDispatch();
	const { variantList } = useProducts();

	const editBuyingPrices = async (data: BuyingPricesRequest) => {
		return await dispatch(doEditBuyingPrices(data));
	};

	const deleteBuyingPrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteBuyingPrices(data));
	};

	const editCustomerTypePrices = async (data: PriceTypesRequest) => {
		return await dispatch(doEditCustomerTypePrices(data));
	};

	const deleteCustomerTypePrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteCustomerTypePrices(data));
	};

	const editCustomerPrices = async (data: PriceCustomersRequest) => {
		return await dispatch(doEditCustomerPrices(data));
	};

	const deleteCustomerPrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteCustomerPrices(data));
	};

	const getBuyingPrice = (variantId: number, amount: number, sellerId?: number): number | null => {
		const variant = variantList.find((variant) => variant.id === variantId);

		if (!variant) {
			return null;
		}

		let buyingPrice = 0;

		if (!sellerId) {
			const prices = variant.merchantBuyingPrices
				?.filter((p) => p.price !== null && p.price !== undefined)
				.map((p) => p.price);

			buyingPrice =
				prices && prices.length > 0
					? prices.reduce((sum, price) => sum + price, 0) / prices.length
					: 0;
		} else {
			buyingPrice =
				variant.merchantBuyingPrices?.find(
					(price) => price.merchantId === sellerId && price.variantId === variantId,
				)?.price || 0;
		}

		const unit = variant.purchaseUnitWeight;

		if (!buyingPrice || unit === null || unit === undefined) {
			return null;
		}

		return buyingPrice * amount;
	};

	const getBatchSinglePrice = (
		variantId: number,
		remainingWeight?: number | null,
		sellerId?: number,
	): { price: number; currency: string } => {
		const variant = variantList.find((variant) => variant.id === variantId);

		if (!variant || !remainingWeight) {
			return { price: 0, currency: '' };
		}

		let buyingPrice = 0;

		if (!sellerId) {
			const prices = variant.merchantBuyingPrices
				?.filter((p) => p.price !== null && p.price !== undefined)
				.map((p) => p.price);

			buyingPrice =
				prices && prices.length > 0
					? prices.reduce((sum, price) => sum + price, 0) / prices.length
					: 0;
		} else {
			buyingPrice =
				variant.merchantBuyingPrices?.find(
					(price) => price.merchantId === sellerId && price.variantId === variantId,
				)?.price || 0;
		}

		const unit = variant.purchaseUnitWeight;

		if (!buyingPrice || unit === null || unit === undefined) {
			return { price: 0, currency: '' };
		}

		if (unit === 0) {
			return { price: buyingPrice, currency: 'EUR' };
		} else {
			return { price: (buyingPrice * remainingWeight) / unit, currency: 'EUR' };
		}
	};

	return {
		editBuyingPrices,
		deleteBuyingPrices,
		editCustomerTypePrices,
		deleteCustomerTypePrices,
		editCustomerPrices,
		deleteCustomerPrices,
		getBuyingPrice,
		getBatchSinglePrice,
	};
}

export default usePrices;
