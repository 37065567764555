import { FormAction } from '@contexts/formContext/types';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import { Box, useTheme } from '@mui/material';
import { AppFunction, ProductType, TranslationTypes } from 'common';
import { useNavigate } from 'react-router-dom';

interface Props {
	formAction: FormAction;
	variantId: number;
	productType: ProductType;
}

export const VariantHeader = ({
	formAction,
	variantId,
	productType,
}: Props): JSX.Element | null => {
	const { getTranslatedString } = useLocale();
	const { variantList } = useProducts();
	const theme = useTheme();
	const navigate = useNavigate();

	const productId = variantList.find((variant) => variant.id === variantId)?.productId;

	if (!productId) {
		return null;
	}

	return (
		<Box
			sx={{
				cursor: 'pointer',
				background: theme.palette.gradient.secondaryAlpha,
				borderRadius: '2rem',
				padding: '1rem',
				marginBottom: '1rem',
				boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)',
				maxWidth: 'md',
				flexGrow: 1,
				transition:
					'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1), box-shadow 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
				'&:hover': {
					background: theme.palette.gradient.secondary,
					transform: 'scale(1.02)',
					boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
				},
			}}
			onClick={() => {
				navigate(
					formAction === 'edit'
						? `/${productType}/${productId}/edit`
						: `/${productType}/${productId}`,
				);
			}}
		>
			{getTranslatedString(AppFunction.Product, productId, TranslationTypes.name)}
		</Box>
	);
};
