import { useEffect, useState, ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormType, FormAction, FormRenderType } from '@contexts/formContext/types';
import _ from 'lodash';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { AffectedRowsResponse, AppFunction } from 'common';
import { ZodSchema } from 'zod';

interface WithFormLogicProps<T extends { id: string | number }> {
	forcedId?: number;
	formType?: FormType;
	formAction: FormAction;
	list: T[];
	validationSchema: ZodSchema<T>;
	createFunction: (data: T) => Promise<T>;
	editFunction: (data: T) => Promise<AffectedRowsResponse>;
	hideMenu?: boolean;
	defaultValues: T;
	pagePrefix?: string;
	prefixIcon?: JSX.Element;
	sideFabs?: FormSidefabType[];
	customAfterCreateLink?: string;
	appFunction: AppFunction;
	children?: (id: number) => React.ReactNode;
	onDataSaved?: (data: T) => void;
}

export function withFormLogic<T extends { id: string | number }>(
	WrappedComponent: ComponentType<Record<string, unknown>>,
) {
	const FormLogicComponent = (props: WithFormLogicProps<T>) => {
		const {
			forcedId,
			formType,
			formAction,
			list,
			validationSchema,
			createFunction,
			editFunction,
			hideMenu,
			defaultValues,
			pagePrefix,
			prefixIcon,
			sideFabs,
			customAfterCreateLink,
			appFunction,
			children,
			onDataSaved,
		} = props;

		const routeId = parseInt(useParams().id ?? '-1');
		const [formValues, setFormValues] = useState<T>(defaultValues);
		const [id, setId] = useState<number>(-1);

		const setValues = (newId: number) => {
			const item = list.find((c) => c.id === newId);
			if (item) {
				setFormValues(item);
			}
		};

		useEffect(() => {
			if (routeId !== id && routeId !== -1) {
				setId(routeId);
				setValues(routeId);
			}
		}, [routeId]);

		useEffect(() => {
			if (id !== routeId && routeId !== -1) {
				setValues(id);
			}
		}, [id]);

		useEffect(() => {
			if (forcedId) {
				setId(forcedId);
				setValues(forcedId);
			}
		}, [forcedId]);

		useEffect(() => {
			const newValues = list.find((c) => c.id === id);
			const parsedNewValues = validationSchema.safeParse(newValues);
			if (!parsedNewValues.success) {
				return;
			}
			if (parsedNewValues.data && !_.isEqual(parsedNewValues.data, formValues)) {
				setFormValues(parsedNewValues.data);
			}
		}, [list]);

		return (
			<FormContextProvider
				appFunction={appFunction}
				createFunction={createFunction}
				editFunction={editFunction}
				renderType={
					forcedId || formType === FormType.quick ? FormRenderType.popup : FormRenderType.standalone
				}
				entityId={forcedId ?? routeId}
				formType={formType ?? FormType.full}
				hideMenu={hideMenu}
				formAction={formAction}
				formValues={formValues as T}
				validationSchema={validationSchema}
				customAfterCreateLink={customAfterCreateLink}
				defaultValues={defaultValues}
				pagePrefix={pagePrefix}
				prefixIcon={prefixIcon}
				useFab
				sideFabs={sideFabs}
				onDataSaved={onDataSaved}
			>
				{children && children(id)}
			</FormContextProvider>
		);
	};

	const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
	FormLogicComponent.displayName = `withFormLogic(${wrappedComponentName})`;

	return FormLogicComponent;
}
