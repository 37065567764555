import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	WarehouseCreateSchema,
	WarehouseRequest,
	WarehouseResponse,
	WarehouseSchema,
	WarehousesResponse,
} from 'common';
import { doCreateWarehouse, doDeleteWarehouse, doEditWarehouse } from '../store/warehouses';
import { PayloadAction } from '@reduxjs/toolkit';

function useWarehouses() {
	const dispatch = useAppDispatch();
	const warehousesSlice = useAppSelector((state) => state.storages.warehouses);

	const [nameFilter, setNameFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState('');
	const [warehouseList, setWarehouseList] = useState<WarehousesResponse>(warehousesSlice);
	const [currentWarehouseList, setCurrentWarehouseList] =
		useState<WarehousesResponse>(warehousesSlice);

	useEffect(() => {
		setWarehouseList((currentList) => {
			if (!_.isEqual(currentList, warehousesSlice)) {
				return warehousesSlice;
			}
			return currentList;
		});
	}, [warehousesSlice]);

	useEffect(() => {
		filterWarehouses();
	}, [warehouseList, nameFilter, typeFilter]);

	const setNameFilterParam = (keyword: string) => setNameFilter(keyword);
	const setTypeFilterParam = (keyword: string) => setTypeFilter(keyword);
	const setFilterParams = (name: string, type: string) => {
		setNameFilterParam(name);
		setTypeFilterParam(type);
	};

	const filterWarehouses = () => {
		const newList: WarehousesResponse = warehouseList.filter((warehouse) => {
			if (warehouse.storageType === typeFilter || typeFilter === undefined || typeFilter === '') {
				if (nameFilter === '') {
					return true;
				}

				const children = warehouseList.filter((sub) => sub.parentId === warehouse.id);

				if (children.length > 0) {
					let returnValue = false;
					for (let i = 0; i < children.length; i++) {
						children[i].strings?.filter((string) => {
							if (string.value.toLowerCase().includes(nameFilter.toLowerCase())) {
								returnValue = true;
							}
						});
					}
					if (returnValue) return true;
				}

				const strings = warehouse.strings?.filter((string) => {
					if (string.value.toLowerCase().includes(nameFilter.toLowerCase())) {
						return true;
					}
				});
				if (strings && strings.length > 0) {
					return true;
				}
			}
			return false;
		});

		setCurrentWarehouseList((currentList) => {
			if (!_.isEqual(currentList, newList)) {
				return newList;
			}
			return currentList;
		});
	};

	const getStoredWarehouseById = (id: number): WarehouseResponse | undefined => {
		return warehouseList.find((warehouse) => warehouse.id === id);
	};

	const getSubWarehouses = (id: number): WarehousesResponse => {
		const warehouses: WarehousesResponse = [];

		warehouseList.map((warehouse) => {
			if (warehouse.parentId === id) {
				warehouses.push(warehouse);
			}
		});

		return warehouses;
	};

	const getWarehouseIdPath = (id: number): number[] => {
		const warehouse = warehouseList.find((w) => w.id === id);
		if (!warehouse) {
			return [];
		}
		if (!warehouse.parentId) {
			return [warehouse.id];
		}
		const parentWarehouse = warehouseList.find((w) => w.id === warehouse.parentId);
		if (!parentWarehouse) {
			return [warehouse.id];
		}

		const parentName = getWarehouseIdPath(parentWarehouse.id);
		return [...parentName, warehouse.id];
	};

	const createWarehouse = async (
		data: WarehouseRequest | WarehouseResponse,
	): Promise<WarehouseResponse> => {
		const parsedData = WarehouseCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid warehouse data');
		}
		const response = (await dispatch(
			doCreateWarehouse(parsedData.data),
		)) as PayloadAction<WarehouseResponse>;
		if (response.type === 'warehouse/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to create warehouse');
	};

	const editWarehouse = async (data: WarehouseResponse): Promise<AffectedRowsResponse> => {
		const parsedData = WarehouseSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid warehouse data');
		}
		const response = (await dispatch(
			doEditWarehouse(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'warehouse/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to edit warehouse');
	};

	const deleteWarehouse = async (
		request: GenericIdRequest,
	): Promise<AffectedRowsResponse | null> => {
		const response = (await dispatch(
			doDeleteWarehouse(request),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'warehouse/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	return {
		warehouseList: currentWarehouseList,
		filterByName: setNameFilterParam,
		filterByType: setTypeFilterParam,
		setFilterParams,
		getStoredWarehouseById,
		getSubWarehouses,
		getWarehouseIdPath,
		createWarehouse,
		editWarehouse,
		deleteWarehouse,
	};
}

export default useWarehouses;
