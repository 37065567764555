import { useTranslation } from 'react-i18next';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { FormProperty, FormRenderType } from '@contexts/formContext/types';
import useLocale from '@hooks/useLocale';
import useAppFunctions from '@hooks/useAppFunctions';

import { Box, Fab, Stack, Typography, alpha, keyframes, useTheme } from '@mui/material';

import { AppFunction, LocaleEntries, TranslationTypes } from 'common';

import styles from '../TabbedSection.module.css';
import { useMemo, useState } from 'react';
import { ArrowLeft, ArrowRight, Close, Edit } from '@mui/icons-material';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { Controller } from 'react-hook-form';
import { PresetInputLangGroup } from '@components/PresetInputLangGroup';
import usePeople from '@hooks/usePeople';

const fade = keyframes`
    from {
        backdrop-filter: blur(0);
    }
    to {
        backdrop-filter: blur(1px);
    }
`;

export const TabheaderBody = () => {
	const {
		renderType,
		appFunction,
		subFunction,
		formAction,
		formProperties,
		entityId,
		control,
		getValues,
		getNext,
		getPrev,
	} = useGenericForm();

	const { getTranslatedString, currentLanguage } = useLocale();
	const { peopleList } = usePeople();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const theme = useTheme();

	const [editing, setEditing] = useState(false);
	const [animating, setAnimating] = useState(false);
	const [lastEntityId, setLastEntityId] = useState(entityId);

	const icon = appFunction ? getIcon(appFunction, subFunction, true) : null;

	const newTitle = useMemo(() => {
		// When creating a new element we just check if strings are set
		// and if they are not we return a standard title based on the appfunction
		if (entityId === -1 && appFunction) {
			const strings = getValues('strings') as LocaleEntries;
			if (strings && strings.length > 0) {
				const stringsTitle = strings.find(
					(s) => s.langCode === currentLanguage && s.type === TranslationTypes.name,
				)?.value;
				return `${stringsTitle}*` ?? stringsTitle;
			} else {
				return t('operations.createItem', { item: t(`appFunctions.${appFunction}`) });
			}
		}

		const title = appFunction
			? getTranslatedString(appFunction, entityId, TranslationTypes.name)
			: 'test';

		// If the entity id changes, we reset the editing state and
		// ignore for a moment the title change check
		if (lastEntityId !== entityId) {
			setLastEntityId(entityId);
			setEditing(false);
		} else {
			const strings = getValues('strings') as LocaleEntries;
			if (strings) {
				const stringsTitle = strings.find(
					(s) => s.langCode === currentLanguage && s.type === TranslationTypes.name,
				)?.value;
				return `${stringsTitle}${stringsTitle !== title ? '*' : ''}` ?? stringsTitle;
			}
		}

		return title;
	}, [getValues('strings'), currentLanguage, entityId, appFunction, peopleList]);

	function handleEditButton(value: boolean) {
		setAnimating(true);
		setEditing(value);
	}

	return (
		<Box
			className={styles.header}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
				margin: '1rem 0 1rem 0',
			}}
		>
			{entityId !== -1 && renderType === FormRenderType.standalone && getPrev && (
				<div
					style={{
						position: 'fixed',
						left: '1rem',
					}}
				>
					<Fab size='small' onClick={() => getPrev()}>
						<ArrowLeft />
					</Fab>
				</div>
			)}
			{entityId !== -1 && renderType === FormRenderType.standalone && getNext && (
				<div
					style={{
						position: 'fixed',
						right: '1rem',
					}}
				>
					<Fab size='small' onClick={() => getNext()}>
						<ArrowRight />
					</Fab>
				</div>
			)}
			{editing && (
				<Box
					sx={{
						position: 'fixed',
						width: '100vw',
						height: '100vh',
						top: 0,
						backgroundColor: 'transparent',
						zIndex: 10,
						animation: `${fade} 0.4s ease-in-out forwards`,
					}}
					onClick={() => handleEditButton(false)}
				/>
			)}
			<Box
				sx={{
					position: editing ? 'absolute' : animating ? 'absolute' : 'relative',
					top: editing ? '0.5rem' : animating ? '0.5rem' : 0,
					minWidth: editing ? 'calc(100% - 9rem)' : '1%',
					minHeight: editing ? '100px' : '1%',
					zIndex: editing ? 20 : 2,
					backgroundColor: 'white',
					borderRadius: '2rem',
					boxShadow: `2px 2px 5px 0 ${alpha(theme.palette.primary.main, 0.2)} inset`,
					transition: 'min-width 0.4s ease-in-out, min-height 0.4s ease-in-out',
				}}
				onAnimationEnd={() => setAnimating(false)}
			>
				<Stack>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{formAction !== 'view' && formProperties.has(FormProperty.strings) && (
							<div
								style={{
									position: 'absolute',
									right: '-48px',
									zIndex: 1,
								}}
							>
								<Fab
									size='small'
									onClick={() => handleEditButton(!editing)}
									color={editing ? 'error' : 'primary'}
								>
									{editing ? <Close /> : <Edit />}
								</Fab>
							</div>
						)}
						{icon && (
							<div
								style={{
									fontSize: '3rem',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '1rem',
									flexShrink: 1,
								}}
							>
								{icon}
							</div>
						)}
						<Typography
							variant='h5'
							className={styles.title}
							sx={{
								padding: '0 1rem 0 0',
								flexGrow: 1,
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{newTitle ? t(`${newTitle}`) : 'No Title'}
						</Typography>
					</Box>
					{editing && !animating && (
						<FadeWrapper>
							<Box
								sx={{
									width: '100%',
									padding: '2rem',
								}}
							>
								<Controller
									name='strings'
									control={control}
									render={({ field }) => (
										<PresetInputLangGroup
											useReactHookForm
											type={TranslationTypes.name}
											strings={field.value ?? []}
											onChange={(data: LocaleEntries) => {
												field.onChange(data);
											}}
										/>
									)}
								/>
							</Box>
						</FadeWrapper>
					)}
				</Stack>
			</Box>
		</Box>
	);
};
