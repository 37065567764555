import { useChainArchetypeEditor } from '@contexts/index';
import { TextDecrease, TextIncrease } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';

export const ChainButtons = () => {
	const { increaseEditorWidth } = useChainArchetypeEditor();
	const theme = useTheme();

	return (
		<Box
			style={{
				display: 'flex',
				justifyContent: 'flex-start',
				gap: '0.5rem',
				position: 'absolute',
				top: '-3rem',
				right: 0,
				width: '100%',
			}}
		>
			<IconButton
				onClick={() => increaseEditorWidth(-4)}
				sx={{
					color: 'white',
					backgroundColor: theme.palette.primary.light,
				}}
			>
				<TextDecrease fontSize='small' />
			</IconButton>
			<IconButton
				onClick={() => increaseEditorWidth(4)}
				sx={{
					color: 'white',
					backgroundColor: theme.palette.primary.light,
				}}
			>
				<TextIncrease fontSize='small' />
			</IconButton>
		</Box>
	);
};
