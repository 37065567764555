import { useAppSelector } from '../../store/hooks';
import { Column } from 'primereact/column';
import { AppFunction, GenericIdRequest } from 'common';
import { Badge, Settings } from '@mui/icons-material';
import { useEffect } from 'react';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { TableContextProvider, useFeedbacks } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import { useTranslation } from 'react-i18next';
import useUsers from '@hooks/useUsers';

export const SectionList = () => {
	const sections = useAppSelector((state) => state.roles.roles);
	const { setBreadCrumbs } = useLayout();
	const { deleteSection } = useUsers();
	const { pushDialog } = useFeedbacks();
	const { t } = useTranslation();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.sections',
				icon: <Badge />,
			},
		]);
	}, []);

	const callDeleteSectionDialog = (data: GenericIdRequest) => {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteSection(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	};

	return (
		<TableContextProvider
			pageIdentifier={AppFunction.Role}
			values={sections}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: (data: GenericIdRequest) => callDeleteSectionDialog(data),
				},
			]}
		>
			<Column field='name' header='sectionName' sortable />
			<Column field='active' header='active' sortable />
		</TableContextProvider>
	);
};
