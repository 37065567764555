import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { ProcessingSuborderProduct } from './ProcessingSuborderProduct';
import { Grid } from '@mui/material';

export const ProcessingSuborderContainer = ({ subOrder }: SubOrderProductsContainerProps) => {
	const subOrderCode = subOrder?.code;
	if (!subOrder || !subOrderCode) return null;

	return (
		<Grid item xs={12} container>
			{subOrder?.content?.[0] && (
				<ProcessingSuborderProduct
					subOrderCode={subOrderCode}
					index={0}
					data={subOrder.content[0]}
					unavailableVariantIds={[]}
				/>
			)}
		</Grid>
	);
};
