import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import { AppFunction, ProductType, ShipBy, VariantResponse, VariantSchema } from 'common';
import useProducts from '@hooks/useProducts';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { GeneralInfo } from '@components/forms/variants/GeneralInfo';
import { TransformRecipe } from '@components/forms/variants/TransformRecipe';
import { InputBuyingPricesGroup } from '@components/products/InputBuyingPricesGroup';
import { InputVariantPricesByCustomerGroup } from '@components/InputVariantPricesByCustomerGroup';
import { InputVariantPricesByCustomerTypeGroup } from '@components/InputVariantPricesByCustomerTypeGroup';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { VariantHeader } from './VariantHeader';

interface Props {
	productType: ProductType;
	formAction: FormAction;
	renderType?: FormRenderType;
	forceId?: number;
}

export const VariantForm = ({ productType, formAction, renderType, forceId }: Props) => {
	const productId = parseInt(useParams().productId ?? '-1');
	const routeId = parseInt(useParams().variantId ?? '-1');
	const { productList, variantList, createVariant, editVariant } = useProducts();

	const [formValues, setFormValues] = useState({} as VariantResponse);
	const [id, setId] = useState<number>(-1);

	const [next, setNext] = useState<number | null>(null);
	const [prev, setPrev] = useState<number | null>(null);

	const setValues = (newId: number) => {
		const variant = variantList.find((p) => p.id === newId);
		if (variant) {
			setFormValues(variant);
		}
	};

	useEffect(() => {
		setValues(forceId ?? id);
	}, [variantList]);

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (routeId !== -1) {
			setValues(id);
		}
		setNext(getNext());
		setPrev(getPrev());
	}, [id]);

	function onBeforeShowTab(): boolean {
		return routeId !== -1;
	}

	function getNext(): number | null {
		const filteredList = productList
			.filter((product) => product.productType === productType)
			.flatMap((product) => product.variants ?? []);

		const higherIds = filteredList.map((entity) => entity.id).filter((entityId) => entityId > id);

		if (higherIds.length === 0) {
			return null;
		}

		return Math.min(...higherIds);
	}

	function getPrev(): number | null {
		const filteredList = productList
			.filter((product) => product.productType === productType)
			.flatMap((product) => product.variants ?? []);

		const lowerIds = filteredList.map((entity) => entity.id).filter((entityId) => entityId < id);

		if (lowerIds.length === 0) {
			return null;
		}

		return Math.max(...lowerIds);
	}

	function goToNext() {
		if (next) {
			setId(next);
		}
	}

	function goToPrev() {
		if (prev) {
			setId(prev);
		}
	}

	return (
		<FormContextProvider
			appFunction={AppFunction.Variant}
			elementPrefixes={[
				{
					appFunction: AppFunction.Product,
					subFunction: productType,
					route: formAction === 'edit' ? `/${productType}/:value/edit` : `/${productType}/:value`,
					valueName: 'productId',
				},
			]}
			createFunction={createVariant}
			subFunction={productType}
			editFunction={editVariant}
			renderType={renderType ?? FormRenderType.standalone}
			entityId={forceId ?? id}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			validationSchema={VariantSchema}
			useFab
			sideFabs={[FormSidefabType.Image, FormSidefabType.Info, FormSidefabType.Notes]}
			customAfterCreateLink='product/:productId/edit/:id'
			defaultValues={
				{
					productId: productId,
					sku: '商品コード',
					janCode: 'JANコード',
					shelfLife: 0,
					shipBy: ShipBy.both,
					sizePerBox: '',
					purchasePrice: 0,
					purchaseCurrency: 2,
					sellPrice: 0,
					sellCurrency: 1,
					status: 0,
					variable: false,
					unitsPerBox: 1,
					weight: 0,
					currentlySold: true,
					id: routeId,
				} as VariantResponse
			}
			customHeader={
				<VariantHeader formAction={formAction} variantId={id} productType={productType} />
			}
			getNext={next ? () => goToNext() : undefined}
			getPrev={prev ? () => goToPrev() : undefined}
		>
			<TabbedElement title='product.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
			{productType === ProductType.product && (
				<TabbedElement title='product.transform' onBeforeShowTab={onBeforeShowTab} hideEditButton>
					<TransformRecipe />
				</TabbedElement>
			)}
			<TabbedElement title='product.price' onBeforeShowTab={onBeforeShowTab} hideEditButton>
				<InputBuyingPricesGroup variantId={id} />
			</TabbedElement>
			{productType === ProductType.product && (
				<TabbedElement
					title='product.customerPrice'
					onBeforeShowTab={onBeforeShowTab}
					hideEditButton
				>
					<InputVariantPricesByCustomerGroup variantId={id} />
				</TabbedElement>
			)}
			{productType === ProductType.product && (
				<TabbedElement
					title='product.customerTypePrice'
					onBeforeShowTab={onBeforeShowTab}
					hideEditButton
				>
					<InputVariantPricesByCustomerTypeGroup variantId={id} />
				</TabbedElement>
			)}
		</FormContextProvider>
	);
};
