import { useGenericForm } from '@contexts/formContext/FormContext';
import { FormRenderType } from '@contexts/formContext/types';
import { useTabs } from '@contexts/tabContext/TabContext';
import { Box, Stack, keyframes, useTheme } from '@mui/material';

import styles from '@contexts/tabContext/TabContext.module.css';
import { TabFab } from './tabfabs/TabFab';
import { tabFabConfig } from './tabfabs/config';

const appear = keyframes`
    from {
        opacity: 0;
        transform: scale(0.4);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const TabFabContainer = () => {
	const { entityId, sideFabs, renderType } = useGenericForm();
	const { openFab } = useTabs();

	const theme = useTheme();

	const uniqueFabs = new Set(sideFabs);

	const config = openFab ? tabFabConfig[openFab] : null;

	return (
		<>
			<div
				style={{
					height: 0,
					width: '100%',
					overflow: 'visible',
					position: 'sticky',
					top: 30,
					zIndex: 1100,
				}}
			>
				<Stack
					direction='column'
					gap={1}
					sx={{
						position: 'absolute',
						right: '-56px',
					}}
				>
					{Array.from(uniqueFabs).map((fab) => {
						return <TabFab key={fab} fabType={fab} />;
					})}
				</Stack>
			</div>
			{openFab !== null && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						width: '100%',
						height: '200px',
						padding: '0 3.5rem 0 0',
						zIndex: 1,
						animation: `${appear} 0.4s`,
					}}
				>
					<div
						className={styles.wrapper}
						style={{
							background: theme.palette.gradient.secondary,
							marginBottom: renderType === FormRenderType.popup ? '0px' : '2.5rem',
						}}
					>
						{config?.editorTab}
					</div>
				</Box>
			)}
		</>
	);
};
