import API from '../index';
import { AffectedRowsResponse, GenericIdsRequest } from 'common';

export const execute = async (params: GenericIdsRequest): Promise<AffectedRowsResponse> => {
	const response = await API.delete('/api/v1/inventory/variants/customersprices', { data: params });
	if (response.status < 200 || response.status > 300) {
		throw new Error(response.data);
	}
	const data = (await response.data) as AffectedRowsResponse;
	return data;
};
