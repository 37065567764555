import { OrderBaseResponse, OrderDirectionType, WarehouseProductModifyRequest } from 'common';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import {
	EnhancedOrderBaseChildRequest,
	OrderApiActionData,
	OrderTypeConfig,
} from './orderTypeConfig';
import { doModifyInventoryProduct } from '@store/inventory';
import { ModifyOrderProductRow } from '../suborder/rows/ModifySuborderProductRow';
import { SubOrder } from '../suborder/SubOrder';

export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(false),
		fromType: new OrderElementConfigItem(false),
		shippingType: new OrderElementConfigItem(false),
		toType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		toSubType: new OrderElementConfigItem(false),
		toWarehouseType: new OrderElementConfigItem(false),
		subOrderTypes: new OrderElementConfigItem(false),
	},
	maxSubOrders: 1,
	subOrderRow: SubOrder,
	subOrderProductsContainer: ModifyOrderProductRow,
	subOrderElements: {
		borderBottom: new OrderElementConfigItem(false),
		maxWidth: new OrderElementConfigItem('100%'),
	},
	isAddSuborderDisabled: (order, subOrders) => {
		if (!order.fromId || !order.toId) {
			return true;
		}
		return subOrders.some((so) => !so.content || so.content?.length <= 0);
	},
	isCreateOrderDisabled: () => false,
	executeOrderAction: async (data: OrderApiActionData) => {
		if (
			data.subOrders?.length !== 2 ||
			data.subOrders[0]?.content?.length !== 1 ||
			!data.subOrders[0].content[0]?.orderId ||
			data.subOrders[1]?.content?.length !== 1 ||
			!data.subOrders[1].content[0]?.orderId
		) {
			return Promise.reject('Not a valid Modify Order');
		}

		const newOp = data.subOrders[0].content[0];
		const oldOp = data.subOrders[1].content[0];
		const modifyRequest: WarehouseProductModifyRequest = {
			oldProduct: {
				warehouseId: data.subOrders[1].fromId ?? -1,
				orderId: +data.subOrders[1].content[0].orderId,
				quantity: oldOp.arrivedQty ?? -1,
				weight: oldOp.weight ?? -1,
				expiresAt: new Date(oldOp.expiresAt ?? 0),
				variantId: oldOp.variantId ?? -1,
				estimated: oldOp.estimated ?? false,
				unit: oldOp.unit ?? undefined,
			},
			newProduct: {
				warehouseId: data.subOrders[0].fromId ?? -1,
				orderId: +data.subOrders[0].content[0].orderId,
				quantity: newOp.arrivedQty ?? -1,
				weight: newOp.weight ?? -1,
				expiresAt: new Date(newOp.expiresAt ?? 0),
				variantId: newOp.variantId ?? -1,
				actualExpiration: new Date(newOp.expiresAt ?? 0),
				pickedWeight: newOp.pickedWeight ?? null,
				estimated: newOp.estimated ?? false,
				unit: newOp.unit ?? undefined,
			},
			sourceOrderPrductId: data.sourceOrderProduct?.id ? +data.sourceOrderProduct.id : -1,
		};

		console.log(modifyRequest);

		const response = await data.dispatch(doModifyInventoryProduct(modifyRequest));

		if (response.type === 'inventoryState/modify/fulfilled') {
			return response.payload as OrderBaseResponse;
		}
		return Promise.reject('Modify order error');
	},
};
