import { JwtToken } from './user';
import jwt_decode from 'jwt-decode';
import moment from 'moment/moment';
import API from './externalApi';
import Cookies from 'js-cookie';

const MINUTES = 1;
const TOKEN_EXPIRE = MINUTES * 60 * 1000;

export const doCheckRefresh = async () => {
	const token = Cookies.get('access_token');
	if (!token) return;

	const token_data: JwtToken = jwt_decode(token);
	if (!token_data) return;

	const cookiesContent = Cookies.get('refresh_token');
	if (!cookiesContent) return;

	const refreshToken: string = cookiesContent;
	const expire = moment.unix(token_data.exp);
	const diff = moment().diff(expire);

	if (diff > -6000) {
		const refreshRequest = await API.get('/api/v1/users/refresh', {
			headers: {
				Authorization: 'Bearer ' + refreshToken,
			},
		});
		if (refreshRequest.status !== 200 && refreshRequest.status !== 201) {
			Cookies.remove('access_token');
			Cookies.remove('refresh_token');
			Cookies.remove('user');
		} else {
			const newToken = refreshRequest.data.accessToken;
			const newRefresh = refreshRequest.data.refreshToken;
			Cookies.set('access_token', newToken, { path: '/' });
			Cookies.set('refresh_token', newRefresh, { path: '/' });
			console.warn('Token refreshed: ' + refreshRequest.status, refreshRequest.data.refreshToken, {
				path: '/',
			});
		}
	}
};

export const checkRefreshToken = () => {
	doCheckRefresh().then(() => console.log('Check Token Refreshed'));

	setInterval(async () => {
		await doCheckRefresh();
	}, TOKEN_EXPIRE / 10);
};
