/**
 * @file This file contains utility functions for manipulating strings.
 *
 * Task field configuration file.
 * This file contains the configuration for each task field type.
 * Each task field type has a configuration object of type {@link TaskFieldConfig}.
 *
 * @module taskFieldConfig
 * @version 1.0.0
 * @since 2024-06-19
 * @author Giuseppe Strano
 */

import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Schema } from 'zod';

import {
	OrderBaseResponse,
	OrderProductResponse,
	OrderProductsResponse,
	OrderType,
	TaskFieldArchetypesResponse,
	TaskFieldType,
} from 'common';

import {
	checkboxFieldConfig,
	closeOrderFieldConfig,
	dateFieldConfig,
	dropdownFieldConfig,
	fileInputFieldConfig,
	modifyOrderFieldConfig,
	numberFieldConfig,
	stringFieldConfig,
	selectvesselFieldConfig,
	confirmpricesFieldConfig,
	pickingFieldConfig,
} from './config';

/**
 * Data sent to {@link TaskFieldConfig.executeApiAction} function
 * @param {number} taskId - ID of the task
 * @param {number} fieldId - ID of the field
 * @param {unknown[]} values - Values to be sent to the API. Set to unknown[] and parsed inside each field config
 * @param {OrderType} orderType - Type of the order
 * @param {ThunkDispatch<unknown, unknown, Action<unknown>>} dispatch - Redux dispatch function
 * @param {OrderProductsResponse} orderProducts - Order products data
 * @param {Record<string, unknown>} additionalData - Additional data to be sent to the function. Used for complex fields
 * and fields in which we need to send more data than just the values. CloseOrderField is an example of this.
 */
export interface TaskApiActionData {
	taskId: number;
	fieldId: number;
	values: unknown[];
	orderType: OrderType;
	dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>;
	orderProducts?: OrderProductsResponse;
	additionalData?: Record<string, unknown>;
}

/**
 * Configuration for a task field
 * @param {function} stringToValues - Function to parse a string into values
 * @param {function} valuesToString - Function to parse values into a string
 * @param {function} validateStructure - Function to validate the structure of the values.
 * Returns true if the structure is valid and the task can be closed, false otherwise.
 * @param {function} performApiAction - Function to perform the API action when the task is closed
 * This function should call the API to save the task values and additionally
 * (if needed) call the API to close the task / modify order products and so on.
 * @param {function} saveStub - Function to save the stub values without closing the task.
 * @param {function} getParentProduct - Function to get the parent product of a product in a task field.
 */
export interface TaskFieldConfig {
	stringToValues: (value: string) => unknown[];
	valuesToString: (values: unknown[]) => string;
	validateStructure: (value: unknown[], orderType?: OrderType) => boolean;
	executeApiAction: (data: TaskApiActionData) => Promise<void>;
	saveStub?: (data: TaskApiActionData) => Promise<void>;
	getParentProduct?: (
		orderType: OrderType,
		parentOrder: OrderBaseResponse,
		content: OrderProductResponse,
	) => OrderProductResponse | undefined;
}

/**
 * Map of task field types to their configuration
 * @param {TaskFieldType} - Task field type
 * @param {TaskFieldConfig} - Task field configuration
 */
export type TaskFieldConfigMap = {
	[key in TaskFieldType]: TaskFieldConfig | null;
};

/**
 * Map of task field types to their configuration
 */
export const taskFieldConfigMap: TaskFieldConfigMap = {
	checkbox: checkboxFieldConfig,
	closeorder: closeOrderFieldConfig,
	confirmorder: null,
	date: dateFieldConfig,
	dropdown: dropdownFieldConfig,
	fileinput: fileInputFieldConfig,
	modifyorder: modifyOrderFieldConfig,
	number: numberFieldConfig,
	string: stringFieldConfig,
	selectvessel: selectvesselFieldConfig,
	confirmprices: confirmpricesFieldConfig,
	picking: pickingFieldConfig,
};

/**
 * Get the task field configuration based on the archetype list and the field ID.
 * If the archetype list is not defined or the archetype is not found, return null.
 * @param {TaskFieldArchetypesResponse | undefined} archetypeList
 * @param {number} id
 * @returns
 */
export const getTaskFieldConfig = (
	archetypeList: TaskFieldArchetypesResponse | undefined,
	id: number,
): TaskFieldConfig | null => {
	if (!archetypeList) return null;
	const archetype = archetypeList.find((archetype) => archetype.id === id);
	if (!archetype) return null;
	return taskFieldConfigMap[archetype.type];
};

/**
 * Parse values to string. This is used to save the values in the database as a string.
 * @param {Schema} schema - the schema to parse the values
 * @param {unknown[]} values - the values to parse
 * @returns {string} the stringified values.
 */
export const parseValuesToString = (schema: Schema, values: unknown[]): string => {
	try {
		const parsed = schema.safeParse(values);
		if (parsed.success) {
			return JSON.stringify(parsed.data);
		} else {
			throw new Error('Error parsing values', parsed.error);
		}
	} catch (error) {
		throw new Error('Error parsing values:', error as Error);
	}
};

/**
 * Parse string to object. This is used to parse the values from the database to an object.
 * @param {Schema} schema - the schema to parse the values
 * @param {string} value - the string to parse
 * @returns {T}	the parsed object
 */
export const parseObjectToValues = <T>(schema: Schema, value: string): T => {
	try {
		const parsed = schema.safeParse(value);
		if (parsed.success) {
			return parsed.data;
		} else {
			throw new Error('Error parsing string:', parsed.error);
		}
	} catch (error) {
		throw new Error('Error parsing string:', error as Error);
	}
};
