import { Container, Paper } from '@mui/material';
import useFeedbacks from '../../hooks/useFeedbacks';
import useTasks from '../../hooks/useTasks';

import commonStyles from '../../styles/Common.module.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GuardedCreateButton } from '../../components/GuardedCreateButton';
import { AppFunction, TaskFieldSetArchetypeResponse } from 'common';
import { ContextualDropdownMenu } from '../../components/ContextualDropdownMenu';
import { Delete, Edit, Settings } from '@mui/icons-material';
import { useEffect } from 'react';
import { TableContextProvider, useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';

const appFunction = AppFunction.TaskFieldSetArchetype;

export const TaskFieldSetList = () => {
	const { taskFieldSetsList, deleteTaskFieldSetArchetype } = useTasks();
	const { pushDeletionDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const actionsBodyTemplate = (rowData: TaskFieldSetArchetypeResponse) => {
		return (
			<ContextualDropdownMenu
				entries={[
					{
						entryLink: `/tasks/field/sets/${rowData.id}/edit`,
						entryIcon: <Edit />,
						entryName: 'Edit',
					},
					{
						entryAction: () => {
							pushDeletionDialog(
								{
									title: 'Delete task field archetype',
									message: `Are you sure you want to delete the task field archetype ${rowData.name}?`,
								},
								() => deleteTaskFieldSetArchetype(rowData.id as number),
							);
						},
						entryIcon: <Delete />,
						entryName: 'Delete',
					},
				]}
			/>
		);
	};

	return (
		<TableContextProvider values={taskFieldSetsList} pageIdentifier={appFunction}>
			<Column field='name' header='name' sortable />
			<Column field='types' header='types' />
			<Column
				headerStyle={{ minWidth: '10rem', alignItems: 'right' }}
				bodyStyle={{ display: 'flex', justifyContent: 'flex-end' }}
				field='actions'
				body={actionsBodyTemplate}
			/>
		</TableContextProvider>
	);
};
