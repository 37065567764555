import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as apiUserLogin from './externalApi/user/apiUserLogin';
import * as apiUserSetMeta from './externalApi/user/apiUserSetMeta';

import * as api from './externalApi/common/apiCalls';

import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	UserCreateRequest,
	UserEditRequest,
	UserLoginRequest,
	UserLoginResponse,
	UserResponse,
	UsersResponse,
} from 'common';

export const doLogin = createAsyncThunk(
	'users/login',
	async (data: UserLoginRequest): Promise<UserLoginResponse> => {
		return await apiUserLogin.execute(data);
	},
);

export const doCreateUser = api.apiCreate<UserCreateRequest, UserResponse>(
	'users/create',
	'users/signup',
);

export const doEditUser = api.apiEdit<UserEditRequest, UserResponse>('users/edit', 'users');

export const doListUsers = api.apiList<UsersResponse>('users/list', 'users');

export const doDeleteUser = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'user/delete',
	'users/',
);

export const setUserMeta = createAsyncThunk(
	'users/meta',
	async (data: apiUserSetMeta.UserMetaParams): Promise<any> => {
		return await apiUserSetMeta.execute(data);
	},
);

const loggedInCookie = Cookies.get('access_token');
const refreshTokenCookie = Cookies.get('refresh_token');
const userCookie = Cookies.get('user');

export interface User {
	id: number;
	name: string;
	surname: string;
	email: string;
	roles: any[];
}

export interface JwtToken {
	exp: number;
	iat: number;
	permissions: string[];
	userid: number;
}

export interface UserRefreshFromCookie {
	accessToken: string;
	refreshToken: string;
	user: any;
}

export interface UserState {
	loggedIn: boolean;
	op_status: NetworkOperationStatus;
	accessToken: string;
	refreshToken: string;
	userData: User | null;
	permissions: string[];
	userList: UsersResponse;
}

const initialState: UserState = {
	op_status: 'idle',
	loggedIn: !!loggedInCookie,
	accessToken: loggedInCookie ? loggedInCookie : '',
	refreshToken: refreshTokenCookie ? refreshTokenCookie : '',
	userData: userCookie ? (JSON.parse(userCookie) as User) : null,
	permissions: [],
	userList: [],
};

export interface UserLoginAction {
	username: string;
	password: string;
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		refreshFromCookies: (state, action: PayloadAction<UserRefreshFromCookie>) => {
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;

			// old stuff, check if needed or kill it
			// const userData = JSON.parse(action.payload.user) as UserLoginS2C;
			// state.permissions = userData.payload.permissions;

			state.loggedIn = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(doLogin.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doLogin.fulfilled, (state, action) => {
			const payload = action.payload;

			state.op_status = 'succeeded';
			state.accessToken = payload.accessToken;
			state.permissions = (jwt_decode(state.accessToken) as JwtToken).permissions;
			state.refreshToken = payload.refreshToken;
			state.loggedIn = true;
			state.userData = {
				id: payload.id,
				name: payload.username,
				surname: payload.username,
				email: payload.email,
				roles: payload.roles,
			};
		});
		builder.addCase(doLogin.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doListUsers.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListUsers.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.userList = action.payload;
		});
		builder.addCase(doListUsers.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateUser.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateUser.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateUser.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export const { refreshFromCookies } = userSlice.actions;
export default userSlice.reducer;
