import { CombinedIcon } from '@components/common/CombinedIcon';
import { FormAction } from '@contexts/formContext/types';
import { useLayout } from '@contexts/layoutContext/LayoutContext';
import useAppFunctions from '@hooks/useAppFunctions';
import useOrders from '@hooks/useOrders';
import { Edit, Sell } from '@mui/icons-material';
import { AppFunction, OrderBaseResponse } from 'common';
import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import {
	Control,
	useForm,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface OrderModifyContextType {
	cachedData: OrderBaseResponse | null;
	control: Control<OrderBaseResponse>;
	formAction: FormAction;
	setValue: UseFormSetValue<OrderBaseResponse>;
	getValues: UseFormGetValues<OrderBaseResponse>;
	handleSubmit: UseFormHandleSubmit<OrderBaseResponse>;
}

interface OrderModifyContextProps {
	orderId: number;
	formAction: FormAction;
	children: React.ReactNode;
}

const defaultContext: OrderModifyContextType = {
	cachedData: null,
	control: {} as Control<OrderBaseResponse>,
	formAction: 'create',
	setValue: {} as UseFormSetValue<OrderBaseResponse>,
	getValues: {} as UseFormGetValues<OrderBaseResponse>,
	handleSubmit: {} as UseFormHandleSubmit<OrderBaseResponse>,
};

const OrderModifyContext = createContext<OrderModifyContextType>(defaultContext);

export const OrderModifyProvider: React.FC<OrderModifyContextProps> = ({
	orderId,
	formAction,
	children,
}: OrderModifyContextProps) => {
	const { orderList } = useOrders();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const [cachedData, setCachedData] = useState<OrderBaseResponse | null>(null);

	const { control, reset, getValues, setValue, handleSubmit, watch } = useForm<OrderBaseResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: cachedData ?? {},
	});

	useEffect(() => {
		if (!cachedData || !cachedData.orderType) return;
		reset(cachedData);
		setBreadCrumbs([
			{
				label: t(`appFunctions.${AppFunction.Order}`),
				icon: <Sell />,
				route: '/orders',
			},
			{
				label: t('operations.editItem', {
					item: cachedData.code,
				}),
				icon: (
					<CombinedIcon
						main={getIcon(AppFunction.Order, cachedData.orderType) ?? <Sell />}
						sub={<Edit />}
					/>
				),
			},
		]);
	}, [cachedData]);

	useEffect(() => {
		const order = orderList.find((order) => order.id === orderId);
		setCachedData((oldOrder) => {
			if (order && (!oldOrder || !_.isEqual(oldOrder, order))) {
				return order;
			}
			return oldOrder;
		});
	}, [orderId, orderList]);

	const contextValue: OrderModifyContextType = {
		cachedData,
		control,
		formAction,
		setValue,
		getValues,
		handleSubmit,
	};

	return <OrderModifyContext.Provider value={contextValue}>{children}</OrderModifyContext.Provider>;
};

export const useOrderModify = () => useContext(OrderModifyContext);
