import { ChainCompletionTimeChip } from '@components/common/chips/ChainCompletionTimeChip';
import { TableNameBody } from '@components/common/tablecells/TableNameBody';
import { TableContextProvider, useFeedbacks, useLayout } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import useAppFunctions from '@hooks/useAppFunctions';
import useTasks from '@hooks/useTasks';
import { Settings } from '@mui/icons-material';
import { AppFunction, GenericIdRequest } from 'common';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const appFunction = AppFunction.TaskArchetype;

export const TaskArchetypeList = () => {
	const { taskArchetypesList, chainArchetypesList, deleteTaskArchetype } = useTasks();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	function callDeleteTaskArchetypeDialog(data: GenericIdRequest) {
		const chainsUsingTaskArchetype = chainArchetypesList.filter((chainArchetype) =>
			chainArchetype.taskArchetypeIds?.includes(+data.id),
		);
		console.log(chainsUsingTaskArchetype);
		const taskArchetypeName = taskArchetypesList.find(
			(taskArchetype) => taskArchetype.id === data.id,
		)?.name;
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('dialog.confirmDelete', { deleting: taskArchetypeName }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteTaskArchetype(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={taskArchetypesList}
			pageIdentifier={appFunction}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: callDeleteTaskArchetypeDialog,
				},
			]}
		>
			<Column field='name' header='nickname' sortable />
			<Column
				field='name'
				header='name'
				body={(data) => <TableNameBody {...data} appFunction={AppFunction.TaskArchetype} />}
			/>
			<Column
				field='nextAssignableAt'
				header='nextAssignableAt'
				sortable
				body={(data) => <ChainCompletionTimeChip timeString={data.nextAssignableAt} />}
			/>
		</TableContextProvider>
	);
};
