import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AffectedRowsResponse,
	GenericIdRequest,
	MerchantCreateSchema,
	MerchantRequest,
	MerchantResponse,
	MerchantSchema,
	MerchantsResponse,
} from 'common';
import { doCreateMerchant, doDeleteMerchant, doEditMerchant } from '../store/merchants';
import { PayloadAction } from '@reduxjs/toolkit';

function useMerchants() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.merchants.op_status);
	const merchantsSlice = useAppSelector((state) => state.merchants.merchants);

	const [merchantList, setMerchantList] = useState<MerchantsResponse>(merchantsSlice);

	useEffect(() => {
		setMerchantList((currentList) => {
			if (!_.isEqual(currentList, merchantsSlice)) {
				return merchantsSlice;
			}
			return currentList;
		});
	}, [merchantsSlice]);

	const createMerchant = async (
		data: MerchantRequest | MerchantResponse,
	): Promise<MerchantResponse> => {
		const parsedData = MerchantCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid merchant data');
		}
		const response = (await dispatch(
			doCreateMerchant(parsedData.data),
		)) as PayloadAction<MerchantResponse>;
		console.log(response.type);
		if (response.type === 'merchant/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error creating merchant');
	};

	const editMerchant = async (data: MerchantResponse): Promise<AffectedRowsResponse> => {
		const parsedData = MerchantSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid merchant data');
		}
		const response = (await dispatch(
			doEditMerchant(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'merchant/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing merchant');
	};

	const deleteMerchant = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteMerchant(data));
	};

	return {
		opStatus,
		merchantList,
		createMerchant,
		editMerchant,
		deleteMerchant,
	};
}

export default useMerchants;
