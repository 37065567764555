import useProducts from '@hooks/useProducts';
import { CloseOrderValue } from '../../config';
import { Box, Grid } from '@mui/material';
import { AppFunction, OrderProductResponse } from 'common';
import { ChevronRight } from '@mui/icons-material';
import { WeightChip } from '@components/common/chips/WeightChip';
import { QuantityChip } from '@components/common/chips/QuantityChip';
import { CloseOrderFieldListItem } from '../../shared/CloseOrderFieldListItem';
import { useTranslation } from 'react-i18next';

interface Props {
	content: OrderProductResponse;
	value: CloseOrderValue;
	iterator: number;
}

export const InboundCloseOrderFieldItem = ({ content, value, iterator }: Props) => {
	const { variantList } = useProducts();
	const { t } = useTranslation();

	const variant = variantList.find((v) => v.id === content.variantId);

	if (!variant || !value?.oldValues?.expiresAt) {
		return null;
	}

	const getFixedTotal = (quantity: number): number => {
		const result = (content.weight ?? 0) * (quantity ?? 0);
		return parseFloat(result.toFixed(2));
	};

	const getVariableSingle = (quantity: number): number => {
		const result = (content.weight ?? 0) / (quantity ?? 0);
		return parseFloat(result.toFixed(2));
	};

	return (
		<CloseOrderFieldListItem content={content} value={value} iterator={iterator}>
			<Grid
				item
				display='flex'
				justifyContent='center'
				alignItems='center'
				p={1}
				sx={{
					backgroundColor: 'grey.300',
					borderRadius: '1rem',
					height: '100%',
				}}
			>
				{t(`${AppFunction.Order}.orderedQuantity`)}
			</Grid>
			<Grid
				item
				flexGrow={1}
				flexBasis={0}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'right',
					gap: 1,
				}}
			>
				<QuantityChip quantity={content.orderQty} />
				{content.weight && content.weight > 0 ? (
					<Box
						display='flex'
						flexDirection='row'
						gap={1}
						sx={{
							width: '100%',
						}}
					>
						<WeightChip
							weight={variant.variable ? getVariableSingle(content.orderQty ?? 0) : content.weight}
							sx={{ flexGrow: 1, flexBasis: 0 }}
							singleWeight
							estimated={variant.variable}
						/>
						<WeightChip
							weight={variant.variable ? content.weight : getFixedTotal(content.orderQty ?? 0)}
							sx={{ flexGrow: 1, flexBasis: 0 }}
						/>
					</Box>
				) : null}
			</Grid>
			<Grid
				item
				display='flex'
				flexDirection='column'
				justifyContent='space-evenly'
				height='100%'
				gap={1}
			>
				<ChevronRight />
				{content.weight && content.weight > 0 ? <ChevronRight /> : null}
			</Grid>
			<Grid
				item
				flexGrow={1}
				flexBasis={0}
				container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'right',
					gap: 1,
				}}
			>
				<QuantityChip quantity={content.arrivedQty} />
				{content.weight && content.weight > 0 ? (
					<Box
						display='flex'
						flexDirection='row'
						gap={1}
						sx={{
							width: '100%',
						}}
					>
						<WeightChip
							weight={
								variant.variable
									? getVariableSingle(content.arrivedQty ?? content.orderQty ?? 0)
									: content.weight
							}
							sx={{ flexGrow: 1, flexBasis: 0 }}
							singleWeight
							estimated={variant.variable}
						/>
						<WeightChip
							weight={
								variant.variable
									? content.weight
									: getFixedTotal(content.arrivedQty ?? content.orderQty ?? 0)
							}
							sx={{ flexGrow: 1, flexBasis: 0 }}
						/>
					</Box>
				) : null}
			</Grid>
			<Grid
				item
				display='flex'
				justifyContent='center'
				alignItems='center'
				p={1}
				sx={{
					backgroundColor: 'grey.300',
					borderRadius: '1rem',
					height: '100%',
				}}
			>
				{t(`${AppFunction.Order}.arrivedQuantity`)}
			</Grid>
		</CloseOrderFieldListItem>
	);
};
