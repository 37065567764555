import {
	AffectedRowsResponse,
	GenericIdRequest,
	OrderBaseResponse,
	WarehouseInventoriesResponse,
	WarehouseInventoryResponse,
	WarehouseProductModifyRequest,
	WarehouseProductSplitRequest,
} from 'common';
import { NetworkOperationStatus } from '.';
import * as api from './externalApi/common/apiCalls';
import { createSlice } from '@reduxjs/toolkit';
import {
	WarehouseProductCutRequest,
	WarehouseProductDeleteRequest,
} from 'common/interfaces/inventorystate';

export interface InventoryState {
	inventoryStates: { [key: number]: WarehouseInventoriesResponse };
	op_status: NetworkOperationStatus;
}

const initialState: InventoryState = {
	inventoryStates: {},
	op_status: 'idle',
};

export const doFetchInventoryState = api.apiList<WarehouseInventoriesResponse>(
	'inventoryState/single/list',
	'inventory/state/3',
);

export const doFetchAllInventoryStates = api.apiList<WarehouseInventoriesResponse>(
	'inventoryState/all/list',
	'inventory/state',
);

export const doCutInventoryProduct = api.apiCreate<
	WarehouseProductCutRequest,
	AffectedRowsResponse
>('inventoryState/cut', 'inventory/state/cut');

export const doSplitInventoryProduct = api.apiCreate<
	WarehouseProductSplitRequest,
	AffectedRowsResponse
>('inventoryState/split', 'inventory/state/split');

export const doModifyInventoryProduct = api.apiCreate<
	WarehouseProductModifyRequest,
	OrderBaseResponse
>('inventoryState/modify', 'inventory/state/modify');

export const doDeleteInventoryProduct = api.apiCreate<
	WarehouseProductDeleteRequest,
	AffectedRowsResponse
>('inventoryState/delete', 'inventory/state/delete');

// TODO: add missing actions to matchers
export const inventoryState = createSlice({
	name: 'inventoryState',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doFetchInventoryState.pending.type,
					doFetchAllInventoryStates.pending.type,
					doSplitInventoryProduct.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		),
			builder.addMatcher(
				(action) =>
					[
						doFetchInventoryState.fulfilled.type,
						doFetchAllInventoryStates.fulfilled.type,
						doSplitInventoryProduct.fulfilled.type,
					].includes(action.type),
				(state, action) => {
					state.op_status = 'succeeded';
					switch (action.type) {
						case doFetchInventoryState.fulfilled.type: {
							if (action.payload.length === 0) {
								break;
							}
							const warehouseId = action.payload[0]?.warehouseId;
							if (warehouseId !== undefined) {
								state.inventoryStates[warehouseId] = action.payload;
								state.op_status = 'succeeded';
							} else {
								console.error('Warehouse ID is missing from the payload');
							}
							break;
						}
						case doFetchAllInventoryStates.fulfilled.type: {
							state.inventoryStates = action.payload.reduce(
								(
									acc: { [key: number]: WarehouseInventoriesResponse },
									item: WarehouseInventoryResponse,
								) => {
									if (!acc[item.warehouseId]) {
										acc[item.warehouseId] = [];
									}
									acc[item.warehouseId].push(item);
									return acc;
								},
								{} as { [key: number]: WarehouseInventoriesResponse },
							);
							break;
						}
					}
				},
			),
			builder.addMatcher(
				(action) =>
					[
						doFetchInventoryState.rejected.type,
						doFetchAllInventoryStates.rejected.type,
						doSplitInventoryProduct.rejected.type,
					].includes(action.type),
				(state) => {
					state.op_status = 'failed';
				},
			);
	},
});

export default inventoryState.reducer;
