import { IconButton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import CheckTextWidth from '../../helpers/checkTextWidth';

interface Props {
	text: string;
	colorType?: 'primary' | 'error' | 'warning' | 'info' | 'success';
	translate?: boolean;
	icon: JSX.Element;
	disabled?: boolean;
	forceOpen?: boolean;
	onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
}

export const AnimatedIconButton = ({
	text,
	colorType,
	translate,
	icon,
	disabled,
	forceOpen,
	onClick,
}: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [translatedText, setTranslatedText] = useState('');
	const [textLength, setTextLength] = useState(0);
	const [inactive, setInactive] = useState(disabled ?? false);

	useEffect(() => {
		const newText = translate ? t(text) : text;
		setTranslatedText(newText);
	}, [text, getI18n().language]);

	useEffect(() => {
		const textLengthInRem = CheckTextWidth(translatedText);
		setTextLength(textLengthInRem);
	}, [translatedText]);

	useEffect(() => {
		setInactive(disabled ?? false);
	}, [disabled]);

	const forcedOpenStyle = () => {
		const force = forceOpen || false;
		if (force) {
			return {
				width: `${textLength + 2}rem`,
				borderRadius: '2rem',
				backgroundColor: theme.palette[colorType ?? 'primary'].main,
				color: 'transparent',
			};
		}
		return {};
	};

	const forcedOpenAfterStyle = () => {
		const force = forceOpen || false;
		if (force) {
			return {
				left: '50%',
				opacity: '1',
				transform: 'translate(-50%, -50%)',
				color: theme.palette[colorType ?? 'primary'].contrastText,
			};
		}
		return {};
	};

	return (
		<IconButton
			size='small'
			type='submit'
			disabled={inactive}
			sx={{
				transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
				backgroundColor: theme.palette[colorType ?? 'primary'].main,
				color: theme.palette[colorType ?? 'primary'].contrastText,
				width: '2.3rem',
				height: '2.3rem',
				borderRadius: '2rem',
				overflow: 'hidden',
				position: 'relative',
				...forcedOpenStyle(),
				'&:hover': {
					width: `${textLength + 2}rem`,
					borderRadius: '2rem',
					backgroundColor: theme.palette[colorType ?? 'primary'].main,
					color: 'transparent',
				},
				'&::after': {
					content: `"${translatedText}"`,
					position: 'absolute',
					top: '50%',
					left: '120%',
					transform: 'translateY(-50%)',
					opacity: '0',
					transition: '300ms all ease-in-out',
					whiteSpace: 'nowrap',
					...forcedOpenAfterStyle(),
				},
				'&:hover::after': {
					left: '50%',
					opacity: '1',
					transform: 'translate(-50%, -50%)',
					color: theme.palette[colorType ?? 'primary'].contrastText,
				},
			}}
			onClick={onClick}
		>
			{icon}
		</IconButton>
	);
};
