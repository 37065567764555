import { useEffect, useState } from 'react';
import {
	FocusedElementData,
	popSnackbar,
	pushFocusedElement,
	removeFocusedElement,
	FocusedElementType,
	doEditListSettings,
	doEditLanguageSettings,
} from '../store/app';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ThemeNames } from '../styles/themes/themes';
import _ from 'lodash';
import { ListSettingsRequest } from '../store/internalApi/interfaces/listSettings';
import { LanguageSettingsRequest } from '../store/internalApi/interfaces/generalSettings';

function useApp() {
	const dispatch = useAppDispatch();
	// TODO: bring theme to the settings!
	const theme = ThemeNames.light;
	const appSlice = useAppSelector((state) => state.app);
	const userRole = useAppSelector((state) => state.roles.userRole);
	const notifications = appSlice.notifications;
	const snackbarMessages = appSlice.messages;
	const editingElements = appSlice.focusedElements;
	const deletionDialog = appSlice.deletionDialog;
	const settingsSlice = useAppSelector((state) => state.app.settings);

	const [settings, setSettings] = useState(settingsSlice);

	useEffect(() => {
		setSettings((oldSettings) => {
			if (!_.isEqual(oldSettings, settingsSlice)) {
				return settingsSlice;
			}
			return oldSettings;
		});
	}, [settingsSlice]);

	const popSnackBar = () => dispatch(popSnackbar());

	const pushElement = (element: FocusedElementData) => dispatch(pushFocusedElement(element));
	const removeElement = (id: number, type: FocusedElementType) =>
		dispatch(removeFocusedElement({ id: id, type: type, zIndex: 0 }));

	const editListSettings = async (params: ListSettingsRequest) =>
		dispatch(doEditListSettings(params));

	const editLanguageSettings = async (params: LanguageSettingsRequest) =>
		dispatch(doEditLanguageSettings(params));

	return {
		theme,
		settings,
		userRole,
		notifications,
		snackbarMessages,
		editingElements,
		deletionDialog,
		popSnackBar,
		pushFocusedElement: pushElement,
		removeFocusedElement: removeElement,
		editListSettings,
		editLanguageSettings,
	};
}

export default useApp;
