import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiBulkRequestWithOptions, IApiRequestWithId, IApiRequestWithIds } from './types';
import * as genericApiList from './apiList';
import * as genericApiCreate from './apiCreate';
import * as genericApiBulkCreate from './apiBulkCreate';
import * as genericApiBulkCreateWithOptions from './apiBulkCreateWithOptions';
import * as genericApiEdit from './apiEdit';
import * as genericApiBulkEdit from './apiBulkEdit';
import * as genericApiDelete from './apiDelete';
import * as genericApiBulkDelete from './apiBulkDelete';
import * as genericApiBulkDeleteIds from './apiBulkDeleteIds';

/**
 * Extracts the ids from the path and replaces them with the values from the params
 * ids are expected to be in the format :[variableName]
 * @param {string} path
 * @param {Req} params
 * @returns {string}
 */
function generateUrlWithIds<Req extends Record<string, unknown>>(
	path: string,
	params: Req,
): string {
	const segments = path.split('/');
	const result: string[] = [];

	for (const segment of segments) {
		if (segment.startsWith(':')) {
			const parsedValue = params[`${segment.slice(1)}`];
			result.push(`${parsedValue}`);
		} else if (segment !== '') {
			result.push(segment);
		}
	}

	return result.join('/');
}

/**
 * Generic API call for listing [GET]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiList = <Res>(thunkName: string, url: string) => {
	return createAsyncThunk(thunkName, async (): Promise<Res> => {
		return await genericApiList.execute<Res>(url);
	});
};

/**
 * Generic API call for listing by id [GET]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiListById = <Req extends Record<string, unknown>, Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		const parsedUrl = generateUrlWithIds(url, params);
		return await genericApiList.execute<Res>(parsedUrl);
	});
};

/**
 * Generic API call for creating [POST]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiCreate = <Req extends Record<string, unknown>, Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		const parsedUrl = generateUrlWithIds(url, params);
		return await genericApiCreate.execute<Req, Res>(params, parsedUrl);
	});
};

/**
 * Generic API call for creating multiple [POST]
 * Generally handled by the backend as a bulk create with update on duplicate
 * @param {string} thunkName
 * @param {string} url
 * @returns
 */
export const apiBulkCreate = <Req extends Record<string, unknown>[], Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		const parsedUrl = generateUrlWithIds(url, params[0]);
		return await genericApiBulkCreate.execute<Req, Res>(params, parsedUrl);
	});
};

/**
 * Generic API call for creating multiple with options [POST]
 * It must contain a field called entries which is an array of objects
 * Options can be passed as separate fields, no constraints
 * @param {string} thunkName
 * @param {string} url
 * @returns
 */
export const apiBulkCreateWithOptions = <Req extends IApiBulkRequestWithOptions, Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		return await genericApiBulkCreateWithOptions.execute<Req, Res>(params, url);
	});
};

/**
 * Generic API call for editing [PUT]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiEdit = <Req extends IApiRequestWithId, Res>(thunkName: string, url: string) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		return await genericApiEdit.execute<Req, Res>(params, url);
	});
};

/**
 * Generic API call for editing multiple [PUT]
 * Generally handled by the backend as a bulk edit with update on duplicate
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiBulkEdit = <Req extends Record<string, unknown>[], Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		const parsedUrl = generateUrlWithIds(url, params[0]);
		return await genericApiBulkEdit.execute<Req, Res>(params, parsedUrl);
	});
};

/**
 * Generic API call for deleting [DELETE]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiDelete = <Req extends IApiRequestWithId, Res>(thunkName: string, url: string) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		return await genericApiDelete.execute<Req, Res>(params, url);
	});
};

/**
 * Generic API call for deleting multiple [DELETE]
 * Req extends {@link IApiRequestWithIds}
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiBulkDeleteIds = <Req extends IApiRequestWithIds, Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		return await genericApiBulkDeleteIds.execute<Req, Res>(params, url);
	});
};

/**
 * Generic API call for deleting multiple [DELETE]
 * Req extends Record<string, unknown>[]
 * @param {string} thunkName
 * @param {string} url
 * @returns AsyncThunk
 */
export const apiBulkDelete = <Req extends Record<string, unknown>[], Res>(
	thunkName: string,
	url: string,
) => {
	return createAsyncThunk(thunkName, async (params: Req): Promise<Res> => {
		return await genericApiBulkDelete.execute<Req, Res>(params, url);
	});
};
