import { DeliveryPlans } from '@components/forms/carriers/DeliveryPlans';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCarriers from '@hooks/useCarriers';
import { CarrierPlansResponse, CarrierResponse, GenericIdRequest } from 'common';
import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeliveryPlansContextType {
	editing: number | null;
	editingPrice: string | null;
	creating: boolean;
	creatingPrice: string | null;
	plans: CarrierPlansResponse;
	setCreating: (value: boolean) => void;
	setEditing: (value: number | null) => void;
	setCreatingPrice: (value: string | null) => void;
	setEditingPrice: (value: string | null) => void;
	callDeleteDeliveryPlanDialog: (data: GenericIdRequest) => void;
	callDeleteCarrierPriceDialog: (data: GenericIdRequest) => void;
}

const defaultContext: DeliveryPlansContextType = {
	editing: null,
	editingPrice: null,
	creating: false,
	creatingPrice: null,
	plans: [],
	setCreating: () => console.log('setCreating not implemented'),
	setEditing: () => console.log('setEditing not implemented'),
	setCreatingPrice: () => console.log('setCreatingPrice not implemented'),
	setEditingPrice: () => console.log('setEditingPrice not implemented'),
	callDeleteDeliveryPlanDialog: () => console.log('callDeleteDeliveryPlanDialog not implemented'),
	callDeleteCarrierPriceDialog: () => console.log('callDeleteCarrierPriceDialog not implemented'),
};

const DeliveryPlansContext = createContext<DeliveryPlansContextType>(defaultContext);

export const DeliveryPlansProvider = () => {
	const { t } = useTranslation();
	const { getValues } = useGenericForm<CarrierResponse>();
	const { pushDialog } = useFeedbacks();
	const { deleteDeliveryPlan, deleteCarrierPrice } = useCarriers();
	const [creating, setCreating] = useState(false);
	const [creatingPrice, setCreatingPrice] = useState<string | null>(null);
	const [editing, setEditing] = useState<number | null>(null);
	const [editingPrice, setEditingPrice] = useState<string | null>(null);
	const [plans, setPlans] = useState<CarrierPlansResponse>(getValues('carrierPlans') ?? []);

	useEffect(() => {
		const newPlans = getValues('carrierPlans');
		if (_.isEqual(plans, newPlans) || !newPlans) return;
		setPlans(newPlans);
	}, [getValues('carrierPlans')]);

	function callDeleteDeliveryPlanDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteDeliveryPlan(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	function callDeleteCarrierPriceDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteCarrierPrice(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	const contextValue = {
		creating,
		editing,
		creatingPrice,
		editingPrice,
		plans,
		setCreating,
		setEditing,
		setCreatingPrice,
		setEditingPrice,
		callDeleteDeliveryPlanDialog,
		callDeleteCarrierPriceDialog,
	};

	return (
		<DeliveryPlansContext.Provider value={contextValue}>
			<DeliveryPlans />
		</DeliveryPlansContext.Provider>
	);
};

export const useDeliveryPlans = () => useContext(DeliveryPlansContext);
