import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';
import { Typography } from '@mui/material';
import moment from 'moment';

interface TableEadBodyProps {
	ead: string | Date | undefined | null;
	createdAt: string | Date | undefined | null;
}

/**
 * Table cell component for displaying estimated arrival data
 * If the ead is not available, it will display the createdAt date
 * If the createdAt date is not available, it will display the current date
 * @param param0
 * @returns
 */
export const TableEadBody = <T extends TableEadBodyProps>({ ead, createdAt }: T) => {
	const { dateFormat } = useLocationTimeZone();

	if ((ead === undefined || ead === null) && (createdAt === undefined || createdAt === null)) {
		return <Typography variant='body1'>{moment().format(dateFormat)}</Typography>;
	}
	return <Typography variant='body1'>{moment(ead ?? createdAt).format(dateFormat)}</Typography>;
};
