import useLocale from '@hooks/useLocale';
import { ArrowRight } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { AppFunction, OrderDirectionType, OrderStatus, TranslationTypes } from 'common';

interface Props {
	type: OrderDirectionType | null | undefined;
	id: number | null | undefined;
	action?: () => void;
	status: OrderStatus | undefined;
	isSelected?: boolean;
	icon?: JSX.Element;
}

export const OrderDirection = ({ type, id, action, status, isSelected, icon }: Props) => {
	const { getTranslatedString } = useLocale();

	if (!type || !id) return <>N/A</>;
	let fromName = '';

	switch (type) {
		case OrderDirectionType.warehouse:
			fromName = getTranslatedString(AppFunction.Location, id, TranslationTypes.name);
			break;
		case OrderDirectionType.customer:
			fromName = getTranslatedString(AppFunction.Customer, id, TranslationTypes.name);
			break;
		case OrderDirectionType.merchant:
			fromName = getTranslatedString(AppFunction.Merchant, id, TranslationTypes.name);
			break;
		case OrderDirectionType.branch:
			fromName = getTranslatedString(AppFunction.CustomerBranch, id, TranslationTypes.name);
			break;
		case OrderDirectionType.entityGroup:
			fromName = 'EG';
			break;
		default:
			fromName = 'N/A';
			break;
	}

	if (action) {
		return (
			<Chip
				label={fromName}
				size='small'
				onClick={() => action?.()}
				sx={{
					userSelect: 'none',
					transform: isSelected ? 'scale(1.1)' : 'scale(1)',
					opacity: isSelected ? 1 : 0.8,
					transition: 'all 0.2s',
					cursor: isSelected ? 'default' : 'pointer',
					pointerEvents: isSelected ? 'none' : 'auto',
				}}
				color={status === OrderStatus.Done ? 'success' : 'warning'}
				icon={icon}
			/>
		);
	} else {
		return (
			<Chip
				label={fromName}
				size='small'
				sx={{
					userSelect: 'none',
				}}
				color={status === OrderStatus.Done ? 'success' : 'warning'}
				icon={icon}
			/>
		);
	}
};
