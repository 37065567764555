import { FadeWrapper } from '@components/common/FadeWrapper';
import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/orderEditorContext/OrderEditorContext';
import useLocale from '@hooks/useLocale';
import { Add, Delete, Restore } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AppFunction, TranslationTypes } from 'common';
import moment from 'moment';
import useProducts from '@hooks/useProducts';
import { InventoryItemBanner } from '@components/common/banners/InventoryItemBanner';
import useInventory from '@hooks/useInventory';

export const SplitOrderProductRow = ({ subOrder }: SubOrderProductsContainerProps) => {
	const { changeOrderProduct: changeVariant, addEmptyVariant, deleteVariant } = useOrderEditor();
	const { inventories } = useInventory();
	const { variantList } = useProducts();
	const { getTranslatedString } = useLocale();

	if (!subOrder) return null;
	const subOrderCode = subOrder.code;
	const content = subOrder.content?.[0];
	const inventoryItem = inventories[subOrder.toId ?? 0]?.find(
		(item) => item.orderProduct?.id === content?.id,
	);
	const variant = variantList.find((v) => v.id === content?.variantId);

	if (!content || !subOrderCode || !inventoryItem || !variant) return null;

	return (
		<FadeWrapper key={subOrder.code ?? 'empty-suborder'}>
			<Grid container item display='flex' spacing={2}>
				<InventoryItemBanner inventoryItem={inventoryItem} />
				<Grid container item xs={12} rowSpacing={2}>
					{subOrder.content?.map((product, it) => {
						return (
							<Grid container item xs={12} key={it} columnSpacing={2} display='flex'>
								<Grid
									item
									flexGrow={1}
									flexBasis={0}
									sx={{ display: 'flex', alignItems: 'center' }}
								>
									<TextField
										size='small'
										type='number'
										label='qty'
										variant='outlined'
										fullWidth
										value={product.arrivedQty ?? 0}
										onChange={(e) => {
											changeVariant(
												{
													arrivedQty: parseInt(e.target.value),
												},
												subOrderCode,
												it,
											);
										}}
									/>
								</Grid>
								{variant?.variable && (
									<Grid
										item
										flexGrow={1}
										flexBasis={0}
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<TextField
											size='small'
											type='number'
											label='weight'
											variant='outlined'
											fullWidth
											value={product.weight ?? 0}
											onChange={(e) => {
												changeVariant(
													{
														weight: parseInt(e.target.value),
													},
													subOrderCode,
													it,
												);
											}}
											InputProps={{
												endAdornment: <InputAdornment position='end'>g</InputAdornment>,
											}}
										/>
									</Grid>
								)}
								<Grid item flexGrow={1} flexBasis={0}>
									<DatePicker
										label='expiresAt'
										value={moment(product.expiresAt).startOf('day')}
										disablePast
										onChange={(newValue) => {
											changeVariant(
												{
													expiresAt: newValue?.startOf('day').toDate(),
												},
												subOrderCode,
												it,
											);
										}}
										renderInput={(params) => (
											<TextField {...params} size='small' variant='outlined' fullWidth />
										)}
										PopperProps={{ style: { zIndex: 9999 } }}
									/>
								</Grid>
								<Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
									<IconButton
										onClick={() => addEmptyVariant(subOrderCode ?? '')}
										disabled={subOrder.content && it !== subOrder.content.length - 1}
									>
										<Add />
									</IconButton>
								</Grid>
								<Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
									{it === 0 ? (
										<IconButton onClick={() => console.log('restore')}>
											<Restore />
										</IconButton>
									) : (
										<IconButton onClick={() => deleteVariant(1, subOrderCode ?? '')}>
											<Delete />
										</IconButton>
									)}
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</FadeWrapper>
	);
};
