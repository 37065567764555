import { FadeWrapper } from '@components/common/FadeWrapper';
import { useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import useOrders from '@hooks/useOrders';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { OrderDetails } from '@pages/Orders/OrderDetails';
import { AppFunction, OrderBaseResponse, OrderProductResponse, OrderType } from 'common';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Props {
	rowData: OrderBaseResponse;
	useIcon?: boolean;
	orderProduct?: OrderProductResponse;
	type?: 'this' | 'parent';
	sx?: SxProps<Theme>;
}

export const OrderCodeBodyTemplate = ({
	rowData,
	type = 'this',
	useIcon,
	orderProduct,
	sx,
}: Props) => {
	const { findHighestParent } = useOrders();
	const { pushPopup } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();

	if (!rowData.orderType || !rowData.code) {
		return <>order type not recognized</>;
	}

	function getFormattedCode(orderType: OrderType, code: string) {
		switch (orderType) {
			case OrderType.Manual:
			case OrderType.Delete:
			case OrderType.Modify:
			case OrderType.Split:
			case OrderType.Processing:
			case OrderType.Internal:
				return t(`${AppFunction.Order}.orderTypes.${orderType.toLowerCase()}`);
			default:
				return code;
		}
	}

	const thisOrder = type === 'this' ? rowData : findHighestParent(rowData.id);

	if (!thisOrder || !thisOrder.orderType || !thisOrder.code) {
		return <></>;
	}

	return (
		<FadeWrapper fadeTime={200}>
			<Button
				onClick={() =>
					pushPopup(<OrderDetails id={thisOrder.id} orderProduct={orderProduct} />, false, true)
				}
				variant='contained'
				size='small'
				color={type === 'this' ? 'info' : 'success'}
				sx={{
					display: 'flex',
					gap: 1,
					justifyContent: 'space-between',
					borderRadius: '0.5rem',
					padding: '0.2rem 0.5rem',
					...(sx && { ...sx }),
				}}
			>
				<Box display='flex' alignItems='center'>
					{useIcon && getIcon(AppFunction.Order, thisOrder.orderType, true)}
				</Box>
				<Box display='flex' alignItems='center'>
					{`${getFormattedCode(thisOrder.orderType, thisOrder.code)}${
						thisOrder.orderType === OrderType.Inbound
							? ''
							: ` - [${moment(thisOrder.createdAt).format('DD/MM/YYYY')}]`
					}`}
				</Box>
			</Button>
		</FadeWrapper>
	);
};
