import { TableNameBody } from '@components/common/tablecells/TableNameBody';
import { TableProductNameBody } from '@components/common/tablecells/TableProductNameBody';
import { TableContextProvider, useFeedbacks, useLayout } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useTaskFieldParameters from '@hooks/useTaskFieldParameters';
import useTasks from '@hooks/useTasks';
import { Settings } from '@mui/icons-material';
import {
	AppFunction,
	GenericIdRequest,
	TaskFieldParameterResponse,
	TranslationTypes,
} from 'common';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const appFunction = AppFunction.TaskFieldParameter;

export const TaskFieldParameterList = () => {
	const { taskFieldParametersList, deleteTaskFieldParameters } = useTaskFieldParameters();
	const { getTranslatedString } = useLocale();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${AppFunction.TaskFieldParameter}`,
				icon: getIcon(AppFunction.TaskFieldParameter) ?? <></>,
			},
		]);
	}, []);

	function callDeleteFieldParameterDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('dialog.confirmDelete', {
				deleting: getTranslatedString(appFunction, data.id, TranslationTypes.name),
			}),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteTaskFieldParameters(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	const paramsBodyTemplate = (rowData: TaskFieldParameterResponse): JSX.Element => {
		return <span>{rowData.params.length}</span>;
	};

	return (
		<TableContextProvider
			values={taskFieldParametersList}
			pageIdentifier={appFunction}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: callDeleteFieldParameterDialog,
				},
			]}
		>
			<Column
				field='name'
				header='name'
				body={(data) => <TableNameBody {...data} appFunction={appFunction} />}
			/>
			<Column field='params' header='paramsNumber' body={paramsBodyTemplate} />
		</TableContextProvider>
	);
};
