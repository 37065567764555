import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	AffectedRowsResponse,
	CarrierCreateSchema,
	CarrierDeliveryAreaCreateSchema,
	CarrierDeliveryAreaRequest,
	CarrierDeliveryAreaResponse,
	CarrierDeliveryAreasResponse,
	CarrierDeliveryTimeRequest,
	CarrierDeliveryTimeResponse,
	CarrierDeliveryTimesResponse,
	CarrierPlanCreateSchema,
	CarrierPlanRequest,
	CarrierPlanResponse,
	CarrierPriceCreateSchema,
	CarrierPriceRequest,
	CarrierPriceResponse,
	CarrierPriceSchema,
	CarrierRequest,
	CarrierResponse,
	CarrierSchema,
	CarriersResponse,
	DeliveryAreaToRegionLinkCreateSchema,
	DeliveryAreaToRegionLinkRequest,
	DeliveryAreaToRegionsLinkCreateSchema,
	DeliveryAreaToRegionsLinkRequest,
	GenericIdRequest,
	GenericIdSchema,
} from 'common';
import _ from 'lodash';
import {
	doCreateCarriers,
	doCreateDeliveryArea,
	doCreateDeliveryPlan,
	doCreateDeliveryPrice,
	doCreateDeliveryTime,
	doDeleteCarriers,
	doDeleteDeliveryPlan,
	doDeleteDeliveryPrice,
	doDeleteDeliveryTime,
	doEditCarriers,
	doEditDeliveryPrice,
	doEditDeliveryTime,
	doLinkDeliveryAreaToRegions,
	doUnlinkDeliveryAreaFromRegion,
} from '../store/carriers';
import { PayloadAction } from '@reduxjs/toolkit';

function useCarriers() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.carriers.op_status);
	const carriersSlice = useAppSelector((state) => state.carriers.carriers);
	const deliveryTimesSlice = useAppSelector((state) => state.carriers.deliveryTimes);
	const deliveryAreasSlice = useAppSelector((state) => state.carriers.deliveryAreas);
	// const plansSlice = useAppSelector((state) => state.carriers.);

	const [carrierList, setCarrierList] = useState<CarriersResponse>(carriersSlice);
	const [deliveryTimeList, setDeliveryTimeList] =
		useState<CarrierDeliveryTimesResponse>(deliveryTimesSlice);
	const [deliveryAreaList, setDeliveryAreaList] =
		useState<CarrierDeliveryAreasResponse>(deliveryAreasSlice);

	useEffect(() => {
		setCarrierList((currentList) => {
			if (!_.isEqual(currentList, carriersSlice)) {
				return carriersSlice;
			}
			return currentList;
		});
	}, [carriersSlice]);

	useEffect(() => {
		setDeliveryTimeList((currentList) => {
			if (!_.isEqual(currentList, deliveryTimesSlice)) {
				return deliveryTimesSlice;
			}
			return currentList;
		});
	}, [deliveryTimesSlice]);

	useEffect(() => {
		setDeliveryAreaList((currentList) => {
			if (!_.isEqual(currentList, deliveryAreasSlice)) {
				return deliveryAreasSlice;
			}
			return currentList;
		});
	}, [deliveryAreasSlice]);

	const createCarrier = async (
		data: CarrierRequest | CarrierResponse,
	): Promise<CarrierResponse> => {
		const parsedData = CarrierCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Data');
		} else {
			const response = (await dispatch(
				doCreateCarriers(parsedData.data),
			)) as PayloadAction<CarrierResponse>;
			if (response.type === 'carriers/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Failed to create carrier');
		}
	};

	const editCarrier = async (data: CarrierResponse): Promise<AffectedRowsResponse> => {
		const parsedData = CarrierSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Data');
		} else {
			const response = (await dispatch(
				doEditCarriers(parsedData.data),
			)) as PayloadAction<AffectedRowsResponse>;
			if (response.type === 'carriers/edit/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Failed to edit carrier');
		}
	};

	const deleteCarrier = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCarriers(data));
	};

	const createDeliveryTime = async (data: CarrierDeliveryTimeRequest) => {
		return await dispatch(doCreateDeliveryTime(data));
	};

	const editDeliveryTime = async (data: CarrierDeliveryTimeResponse) => {
		return await dispatch(doEditDeliveryTime(data));
	};

	const deleteDeliveryTime = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteDeliveryTime(data));
	};

	const linkDeliveryAreaToRegions = async (
		data: DeliveryAreaToRegionsLinkRequest,
	): Promise<AffectedRowsResponse> => {
		const parsedData = DeliveryAreaToRegionsLinkCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Delivery Area To Region Link Data');
		}
		const response = (await dispatch(
			doLinkDeliveryAreaToRegions(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'carriers/areas/link/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to link delivery areas to regions');
	};

	const unlinkDeliveryAreaFromRegion = async (
		data: DeliveryAreaToRegionLinkRequest,
	): Promise<AffectedRowsResponse> => {
		const parsedData = DeliveryAreaToRegionLinkCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Delivery Area To Region Link Data');
		}
		const response = (await dispatch(
			doUnlinkDeliveryAreaFromRegion(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'carriers/areas/unlink/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to unlink delivery area from region');
	};

	const createCarrierDeliveryArea = async (
		data: CarrierDeliveryAreaRequest,
	): Promise<CarrierDeliveryAreaResponse> => {
		const parsedData = CarrierDeliveryAreaCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Delivery Area Data');
		}
		const response = (await dispatch(
			doCreateDeliveryArea(parsedData.data),
		)) as PayloadAction<CarrierDeliveryAreaResponse>;
		if (response.type === 'carriers/areas/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to create carrier delivery area');
	};

	const createCarrierPlan = async (data: CarrierPlanRequest): Promise<CarrierPlanResponse> => {
		const parsedData = CarrierPlanCreateSchema.safeParse(data);
		if (!parsedData.success) {
			console.log(parsedData.error);
			return Promise.reject('Invalid Carrier Plan Data');
		}
		const response = (await dispatch(
			doCreateDeliveryPlan(parsedData.data),
		)) as PayloadAction<CarrierPlanResponse>;
		if (response.type === 'carriers/plans/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to create carrier plan');
	};

	const deleteDeliveryPlan = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const parsedData = GenericIdSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Plan Data');
		}
		const response = (await dispatch(
			doDeleteDeliveryPlan(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'carriers/plans/delete/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to delete carrier plan');
	};

	const createCarrierPrice = async (data: CarrierPriceRequest): Promise<CarrierPriceResponse> => {
		const parsedData = CarrierPriceCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Price Data');
		}
		const response = (await dispatch(
			doCreateDeliveryPrice(parsedData.data),
		)) as PayloadAction<CarrierPriceResponse>;
		if (response.type === 'carriers/prices/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to create carrier price');
	};

	const editCarrierPrice = async (data: CarrierPriceResponse): Promise<AffectedRowsResponse> => {
		const parsedData = CarrierPriceSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Price Data');
		}
		const response = (await dispatch(
			doEditDeliveryPrice(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'carriers/prices/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to edit carrier price');
	};

	const deleteCarrierPrice = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const parsedData = GenericIdSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid Carrier Price Data');
		}
		const response = (await dispatch(
			doDeleteDeliveryPrice(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'carriers/prices/delete/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Failed to delete carrier price');
	};

	return {
		opStatus,
		carrierList,
		deliveryTimeList,
		deliveryAreaList,
		createCarrier,
		editCarrier,
		deleteCarrier,
		createDeliveryTime,
		editDeliveryTime,
		deleteDeliveryTime,
		createCarrierDeliveryArea,
		linkDeliveryAreaToRegions,
		unlinkDeliveryAreaFromRegion,
		createCarrierPlan,
		deleteDeliveryPlan,
		createCarrierPrice,
		editCarrierPrice,
		deleteCarrierPrice,
	};
}

export default useCarriers;
