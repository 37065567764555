import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { DeliveryTime } from '@components/delivery/DeliveryTime';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCarriers from '@hooks/useCarriers';
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { CarrierDeliveryTimesResponse, CarrierResponse } from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export const DeliveryTimes = () => {
	const { entityId } = useGenericForm<CarrierResponse>();
	const { deliveryTimeList } = useCarriers();
	const [isCreating, setCreating] = useState(false);

	const filterDeliveryTimes = (
		dTimes: CarrierDeliveryTimesResponse,
	): CarrierDeliveryTimesResponse => {
		return dTimes
			.filter((dt) => dt.carrierId === entityId)
			.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
	};
	const [deliveryTimes, setDeliveryTimes] = useState<CarrierDeliveryTimesResponse>(
		filterDeliveryTimes(deliveryTimeList),
	);

	useEffect(() => {
		const newTimes = filterDeliveryTimes(deliveryTimeList);
		setDeliveryTimes((oldTimes) => {
			if (!_.isEqual(oldTimes, newTimes)) {
				return newTimes;
			}
			return oldTimes;
		});
	}, [deliveryTimeList]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='carrier.deliveryTime' translate size='small' />
			</Grid>
			<Grid item xs={12}>
				{deliveryTimes.map((dt, it) => (
					<DeliveryTime key={it} time={dt} />
				))}
			</Grid>
			{isCreating ? (
				<Grid item xs={12}>
					<DeliveryTime
						time={{ id: -1, carrierId: entityId, startTime: '00:00:00', endTime: '00:00:00' }}
						isCreating
						onCancelCreate={() => setCreating(false)}
					/>
				</Grid>
			) : (
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<AnimatedIconButton
						text='operations.create'
						translate
						icon={<Add />}
						onClick={() => setCreating(true)}
						colorType='info'
					/>
				</Grid>
			)}
		</Grid>
	);
};
