import { useLayout } from '@contexts/index';
import useApp from '@hooks/useApp';
import useAppFunctions from '@hooks/useAppFunctions';
import {
	keyframes,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	SxProps,
	Theme,
} from '@mui/material';
import { AppFunction } from 'common';
import { checkReadPermission } from 'permissions';
import { cloneElement, JSXElementConstructor, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const fillBackground = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const unfillBackground = keyframes`
  0% {
	width: 100%;
	opacity: 1;
  }
  100% {
	width: 0;
	opacity: 0;
  }
`;

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
	position: 'relative',
	overflow: 'hidden',
	pointerEvents: selected ? 'none' : 'auto',
	transition: 'color 0.3s',
	'& .MuiSvgIcon-root': {
		...(selected && { transform: 'scale(1.3)' }),
	},
	'&::before': {
		content: '""',
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: '0%',
		background: theme.palette.grey[200],
		zIndex: 0,
		transition: 'width 0.3s',
		animation: `${unfillBackground} 0.2s forwards`,
	},
	'&:hover::before': {
		left: 0,
		animation: `${fillBackground} 0.2s forwards`,
	},
	'&:hover': {
		color: 'rgba(34,89,107,1)',
	},
	'&:hover .MuiSvgIcon-root': {
		color: 'rgba(34, 89, 107, 1)',
		transform: 'scale(1.3)',
	},
	'&:hover .MuiTypography-root': {
		fontWeight: 'bold',
	},
	'& > *': {
		position: 'relative',
		zIndex: 1,
	},
}));

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export type ElementWithSxProps<T = {}> = ReactElement<
	T & { sx?: SxProps<Theme> },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	JSXElementConstructor<any>
>;

interface DrawerListItemProps {
	appFunction?: AppFunction;
	subFunction?: string;
	path?: string;
	name?: string;
	customIcon?: ElementWithSxProps;
	checkPermissions?: boolean;
	customAction?: () => void;
}

export const DrawerListItem = ({
	appFunction,
	subFunction,
	path,
	name,
	customIcon,
	checkPermissions = true,
	customAction,
}: DrawerListItemProps): JSX.Element | null => {
	const { userRole } = useApp();
	const { drawerOpen } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const selected = useMemo(() => {
		if (location.pathname !== undefined && path === undefined && appFunction === undefined) {
			return location.pathname === '/';
		}
		if (location.pathname === '/') {
			return false;
		}

		const basePath = `/${path ?? appFunction ?? ''}`;

		const exactMatch = location.pathname === basePath;
		const locationStartsWithBase = location.pathname.startsWith(basePath);
		const baseStartsWithLocation = basePath.startsWith(location.pathname);

		if (location.pathname.length > basePath.length) {
			const locationArray = location.pathname.split('/');
			let inverseCheck = false;

			/** the worst hack in history */
			for (const locationPart of locationArray) {
				if (parseInt(locationPart)) {
					inverseCheck = true;
					break;
				}
			}

			if (inverseCheck) {
				return locationStartsWithBase;
			} else {
				return baseStartsWithLocation;
			}
		}

		return exactMatch;
	}, [location.pathname, path, appFunction]);

	// FIXME: fix transition (it is rerended now)
	const icon = useMemo(() => {
		let tempIcon: JSX.Element | null = null;
		if (customIcon) {
			tempIcon = customIcon;
		} else if (appFunction) {
			tempIcon = getIcon(appFunction, subFunction);
		} else {
			return null;
		}
		let icon: JSX.Element | null = null;
		if (tempIcon) {
			const tempIconProps = (tempIcon as ReactElement).props;
			const existingSx = tempIconProps.sx || {};
			icon = cloneElement(tempIcon, {
				...tempIconProps,
				sx: {
					...existingSx,
					transition: 'transform 0.3s',
					color: drawerOpen ? 'white' : 'grey.700',
					transform: selected ? 'scale(1.2)' : 'scale(1)',
				},
			});
		}
		return icon;
	}, [appFunction, subFunction, customIcon, drawerOpen, selected]);

	if (checkPermissions && appFunction) {
		const hasPermission = checkReadPermission(userRole, appFunction);
		if (!hasPermission) {
			return null;
		}
	}

	return (
		<ListItem disablePadding disableGutters>
			<StyledListItemButton
				onClick={() => {
					if (customAction) {
						customAction();
						return;
					}
					navigate(`/${path ?? appFunction ?? ''}`);
				}}
				selected={selected}
				disableGutters
			>
				<ListItemIcon
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minWidth: '3rem',
						width: '5rem',
					}}
				>
					{icon}
				</ListItemIcon>
				<ListItemText primary={t(`appBar.${name ?? appFunction}`)} />
			</StyledListItemButton>
		</ListItem>
	);
};
