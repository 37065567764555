import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import useInventory from '@hooks/useInventory';
import useLocale from '@hooks/useLocale';
import useOrders from '@hooks/useOrders';
import useProducts from '@hooks/useProducts';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Button, Chip, Grid, InputAdornment, TextField } from '@mui/material';
import { AppFunction, OrderProductResponse, OrderStatus, TranslationTypes } from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	content: OrderProductResponse;
	sourceContent?: OrderProductResponse;
	setProductAsPicked: (
		orderProductId: number | undefined,
		pickedQuantity?: number,
		pickedWeight?: number,
		shorted?: boolean,
	) => void;
}

export const PickingFieldListItem = ({ content, setProductAsPicked, sourceContent }: Props) => {
	const { getTranslatedString } = useLocale();
	const { variantList } = useProducts();
	const { orderList } = useOrders();
	const { findInventoryEntryById, inventories } = useInventory();
	const { t } = useTranslation();
	const [checked, setChecked] = useState<boolean>(false);
	const [shorted, setShorted] = useState<boolean>(false);
	const [pickedQty, setPickedQty] = useState<number>(content.orderQty ?? 0);
	const [pickedWeight, setPickedWeight] = useState<number>(0);

	const variant = variantList.find((v) => v.id === content.variantId);

	useEffect(() => {
		console.log(content);
		if (variant) {
			let newWeight = content.weight ?? 0;
			if (variant.variable && content.weight && content.unit === 'pieces' && content.sourceId) {
				const source = findInventoryEntryById(+content.sourceId);
				if (source?.orderProduct?.arrivedQty) {
					const baseBatchWeight = source.weight / source.orderProduct.arrivedQty;
					newWeight = Math.round(baseBatchWeight * (content.orderQty ?? 1));
				}
			}
			setPickedWeight(newWeight);
		}
	}, [content.id, inventories]);

	if (!variant || !content.id) return null;

	const sourceOrder = orderList.find((o) => o.id === sourceContent?.orderId);
	const canEdit = !sourceContent || sourceOrder?.status === OrderStatus.Done;

	return (
		<Grid
			container
			columnGap={1}
			display='flex'
			sx={{
				marginBottom: '0.5rem',
				position: 'relative',
				fontSize: '1.3rem',
				'&:before': {
					content: '""',
					position: 'absolute',
					height: shorted ? '4px' : '1px',
					top: 'calc(50% - 1px)',
					left: '0',
					background: shorted ? 'red' : 'black',
					transition: 'width 0.5s, height 0.5s',
					width: checked ? '100%' : '0',
					backgroundColor: shorted ? 'red' : 'black',
					zIndex: 8888,
				},
				'&:hover': {
					cursor: canEdit ? 'pointer' : 'not-allowed',
					backgroundColor: canEdit ? 'rgba(0, 0, 0, 0.1)' : '',
				},
			}}
		>
			<Grid
				item
				container
				columnGap={1}
				display='flex'
				alignItems='center'
				onClick={() => {
					if (!sourceContent || sourceOrder?.status === OrderStatus.Done) {
						setChecked((oldValue) => !oldValue);
						setShorted(false);
						setProductAsPicked(
							content.id ? +content.id : undefined,
							pickedQty,
							variant.variable ? pickedWeight : undefined,
							false,
						);
					}
				}}
				flexGrow={1}
				flexBasis={0}
			>
				<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
					{checked ? <CheckBox /> : <CheckBoxOutlineBlank />}
				</Grid>
				<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
					{getTranslatedString(
						AppFunction.Product,
						variant?.productId ?? -1,
						TranslationTypes.name,
					)}
				</Grid>
				<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
					{getTranslatedString(
						AppFunction.Variant,
						content?.variantId ?? -1,
						TranslationTypes.name,
					)}
				</Grid>
				{sourceContent && (
					<Grid
						item
						sx={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '0.6rem',
						}}
					>
						<Chip
							label={sourceOrder?.code ?? 'nope'}
							size='small'
							color={sourceOrder?.status === OrderStatus.Pending ? 'lightWarning' : 'success'}
							// onClick={() => console.log('culo')}
						/>
					</Grid>
				)}
			</Grid>
			{/* {variant?.variable && content.unit !== 'grams' && ( */}
			{variant?.variable && (
				<InputGridField width='auto' type={ComponentType.TextField}>
					<TextField
						label={t(`${AppFunction.Product}.weight`)}
						size='small'
						type='number'
						variant='outlined'
						fullWidth
						disabled={!canEdit || checked}
						value={pickedWeight}
						onChange={(e) => setPickedWeight(+e.target.value)}
						sx={{
							backgroundColor: 'white',
						}}
					/>
				</InputGridField>
			)}
			{/* {variant?.variable && content.unit === 'grams' && (
				<Grid item xs='auto'>
					{content.pickedWeight}
				</Grid>
			)} */}
			<InputGridField width='auto' type={ComponentType.TextField}>
				<TextField
					label={t(`${AppFunction.Product}.quantity`)}
					size='small'
					type='number'
					variant='outlined'
					fullWidth
					disabled={!canEdit || checked || content.unit === 'grams'}
					value={pickedQty}
					onChange={(e) => {
						setPickedQty(+e.target.value);
						console.log(e.target.value);

						// If we have a variable product that has been cut (hence the 'grams' unit)
						// we need to update the picked weight as well
						if (variant.variable && content.unit === 'grams') {
							setPickedWeight(+e.target.value);
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{t(`${AppFunction.Product}.units.pieces`, { count: pickedQty })}
							</InputAdornment>
						),
					}}
					sx={{
						backgroundColor: 'white',
					}}
				/>
			</InputGridField>
			<Grid item display='flex' alignItems='center'>
				<Button
					color='error'
					disabled={!canEdit || checked}
					onClick={() => {
						setChecked((oldValue) => !oldValue);
						setShorted((oldValue) => !oldValue);
						setPickedQty(0);
						setPickedWeight(0);
						setProductAsPicked(content.id ? +content.id : undefined, 0, 0, true);
					}}
					variant={shorted ? 'contained' : 'outlined'}
				>
					欠品
				</Button>
			</Grid>
		</Grid>
	);
};
