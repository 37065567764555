import { useLayout } from '@contexts/index';
import useLayoutObserver from '@hooks/useLayoutObserver';
import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
	children: ReactNode;
}

export const SubMenuWrapper = ({ children }: Props) => {
	const { subMenuElements, subMenuFullScreen, setSubMenuPosition } = useLayout();
	const theme = useTheme();

	const callback = (id: string, rect: DOMRectReadOnly) => {
		if (id === 'subMenu') {
			setSubMenuPosition(rect);
		}
	};

	const subMenuRef = useLayoutObserver('subMenu', 'position', callback);

	return (
		<Box
			ref={subMenuRef}
			style={{
				height: !subMenuElements || subMenuElements.length === 0 ? '0rem' : '2.8rem',
				opacity: !subMenuElements || subMenuElements.length === 0 ? 0 : 1,
				overflow: 'hidden',
				padding: !subMenuElements || subMenuElements.length === 0 ? '0rem' : '0.2rem',
				background: 'transparent',
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: subMenuFullScreen ? 'stretch' : 'space-evenly',
				alignItems: 'center',
				gap: subMenuFullScreen ? '2rem' : '0.5rem',
				position: 'relative',
				transition: 'all 0.3s',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background: theme.palette.gradient.primary,
					opacity: subMenuFullScreen ? 0 : 1,
					borderRadius: subMenuFullScreen ? 0 : '0 0 0.5rem 0.5rem',
					zIndex: -1,
					transition: 'opacity 2s, border-radius 1s',
				}}
			/>
			{children}
		</Box>
	);
};
