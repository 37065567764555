import * as React from 'react';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Button, Grow, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useApp from '../../hooks/useApp';

import { Fullscreen, FullscreenExit, Info, TextDecrease, TextIncrease } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import { useLayout } from '@contexts/index';
import { ChangelogView } from '../changelog/Changelog';
import useLayoutObserver from '@hooks/useLayoutObserver';

import { Drawer } from './Drawer';

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const drawerWidth = '18rem';

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.enteringScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth})`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function MainAppBar() {
	const auth = useAuth();
	const navigate = useNavigate();
	const theme = useTheme();
	const { notifications } = useApp();
	const { drawerOpen, setDrawerOpen } = useLayout();
	const { pushPopup, popupStates, registerElement, setFontSize } = useLayout();
	const { settings, editLanguageSettings } = useApp();
	const { i18n } = useTranslation();

	const [currentFlag, setCurrentFlag] = React.useState('');

	React.useEffect(() => {
		if (settings?.language !== i18n.language) {
			i18n.changeLanguage(settings?.language);
		}
	}, [settings]);

	React.useEffect(() => {
		let flag = '';
		switch (i18n.language) {
			case 'it':
				flag = '🇮🇹';
				break;
			case 'en':
				flag = '🇺🇸';
				break;
			case 'jp':
				flag = '🇯🇵';
				break;
		}
		setCurrentFlag(flag);
	}, [i18n.language]);

	const changeLanguage = async (language: 'jp' | 'it' | 'en') => {
		if (auth.user) {
			await i18n.changeLanguage(language);
			await editLanguageSettings({ language: language, userId: auth.user?.id });
		}
	};

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		setOpen(Boolean(anchorEl));
	}, [anchorEl]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleVersionPopup = () => {
		pushPopup(<ChangelogView />, false, true, 'changelog');
	};

	const callBack = (id: string, rect: DOMRectReadOnly) => {
		registerElement(id, 'top', rect.height);
	};

	const handleFullScreen = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else {
			document.body.requestFullscreen();
		}
	};

	const appBarRef = useLayoutObserver('appBar', 'size', callBack);

	return (
		<>
			<AppBar
				position='absolute'
				theme={theme}
				open={drawerOpen}
				sx={{
					display: { xs: 'none', sm: 'block' },
					zIndex: 9999,
					background: theme.palette.gradient.primary,
				}}
				ref={appBarRef}
			>
				<Toolbar
					sx={{
						pr: '24px',
					}}
				>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={() => setDrawerOpen(true)}
						sx={{
							marginRight: '36px',
							maxWidth: '3rem',
							transition: theme.transitions.create(['transform', 'maxWidth', 'margin'], {
								easing: theme.transitions.easing.easeInOut,
								duration: 300,
							}),
							...(drawerOpen && {
								transform: 'scale(0)',
								maxWidth: 0,
								marginRight: 0,
								transition: theme.transitions.create(['transform', 'maxWidth', 'margin'], {
									easing: theme.transitions.easing.easeInOut,
									duration: 250,
								}),
							}),
						}}
					>
						<MenuIcon />
					</IconButton>
					<img
						src='https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/008/770_L.png'
						style={{ cursor: 'pointer' }}
						alt='fdm_logo'
						width='60'
						height='60'
						onClick={() => {
							navigate('/');
						}}
					/>
					<Typography
						component='h1'
						variant='h6'
						color='inherit'
						noWrap
						sx={{
							flexGrow: 1,
							marginLeft: '10px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							gap: 1,
						}}
					>
						FiorDiMaso Japan Integrated{' '}
						<Button
							onClick={handleVersionPopup}
							type='button'
							variant='contained'
							color='info'
							disabled={popupStates['changelog']}
							sx={{
								display: 'flex',
								alignItems: 'center',
								fontSize: '0.7rem',
							}}
						>
							{process.env.REACT_APP_VERSION}
							<Info sx={{ fontSize: '1rem', marginLeft: '0.3rem' }} />
						</Button>
					</Typography>
					<IconButton onClick={() => setFontSize(-1)} color='inherit'>
						<TextDecrease />
					</IconButton>
					<IconButton onClick={() => setFontSize(1)} color='inherit'>
						<TextIncrease />
					</IconButton>
					<IconButton color='inherit'>
						<Badge badgeContent={notifications.length} color='secondary'>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<IconButton
						color='inherit'
						aria-controls={open ? 'fade-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						{currentFlag}
					</IconButton>
					<IconButton color='inherit' onClick={handleFullScreen}>
						{document.fullscreenElement ? <FullscreenExit /> : <Fullscreen />}
					</IconButton>
					<div id='menu-anchor' style={{ marginTop: '30px' }} />
					<Menu
						id='fade-menu'
						anchorEl={() => document.querySelector('#menu-anchor')!}
						open={open}
						onClose={handleClose}
						TransitionComponent={Grow}
						sx={{ zIndex: 9999 }}
					>
						<MenuItem
							selected={i18n.language === 'jp'}
							onClick={async () => {
								handleClose();
								await changeLanguage('jp');
							}}
						>
							🇯🇵 JA
						</MenuItem>
						<MenuItem
							selected={i18n.language === 'it'}
							onClick={async () => {
								handleClose();
								await changeLanguage('it');
							}}
						>
							🇮🇹 IT
						</MenuItem>
						<MenuItem
							selected={i18n.language === 'en'}
							onClick={async () => {
								handleClose();
								await changeLanguage('en');
							}}
						>
							🇺🇸 EN
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer />
		</>
	);
}
