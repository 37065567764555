import { useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import useOrders from '@hooks/useOrders';
import { Button, Tooltip } from '@mui/material';
import { OrderDetails } from '@pages/Orders/OrderDetails';
import { AppFunction } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	orderId: number;
	isPopup?: boolean;
}

export const OrderButton = ({ orderId, isPopup }: Props) => {
	const { t } = useTranslation();
	const { orderList } = useOrders();
	const { pushPopup } = useLayout();
	const { getIcon } = useAppFunctions();

	const order = orderList.find((order) => order.id === orderId);

	if (!order) {
		return null;
	}

	return (
		<Tooltip
			title={t(`${AppFunction.Order}.orderTypes.${order.orderType?.toLowerCase()}`)}
			placement={isPopup ? 'right-end' : 'left-end'}
			PopperProps={{ style: isPopup ? { zIndex: 9999 } : {} }}
		>
			<Button
				size='small'
				onClick={(e) => {
					pushPopup(<OrderDetails id={order.id} />, false, true);
					e.stopPropagation();
				}}
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
				startIcon={order.orderType ? getIcon(AppFunction.Order, order.orderType) ?? <></> : <></>}
			>
				{`${t(`${AppFunction.Order}.code`)}: ${order.code}`}
			</Button>
		</Tooltip>
	);
};
