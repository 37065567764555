import { useOrderEditor } from '@contexts/index';
import { EnhancedVariant, EnhancedVariants } from '@contexts/orderEditorContext/types';
import useProducts from '@hooks/useProducts';
import { Grid } from '@mui/material';
import { OrderProductResponse, RecipeType } from 'common';
import { useMemo, useState } from 'react';
import { OrderProductRowWrapper } from '../../common/OrderProductRowWrapper';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import ProductAutocomplete from '@components/common/inputs/products/ProductAutocomplete';
import { ProcessingIngredientsRow } from './ProcessingIngredientsRow';

interface Props {
	index: number;
	data: OrderProductResponse;
	subOrderCode: string;
	unavailableVariantIds: number[];
}

export const ProcessingSuborderProduct = ({
	index,
	data,
	subOrderCode,
	unavailableVariantIds,
}: Props) => {
	const { changeOrderProduct, getValues } = useOrderEditor();
	const { variantList, productList } = useProducts();

	const [editMode, setEditMode] = useState(true);

	const availableVariants = useMemo((): EnhancedVariants => {
		const tempVariants: EnhancedVariants = [];
		variantList.map((vr) => {
			if (vr.currentlySold && vr.recipe && vr.recipe.ingredients.length > 0) {
				tempVariants.push(vr);
			}
		});
		return tempVariants;
	}, [productList, variantList, unavailableVariantIds, getValues]);

	const value = useMemo((): EnhancedVariant | null => {
		let foundVariant = null;

		const variant = availableVariants.find((vr) => vr.id === data.variantId);
		if (variant) {
			foundVariant = variant;
		}

		return foundVariant;
	}, [productList, variantList, availableVariants]);

	const ProductContent = useMemo(() => {
		if (variantList.length === 0) {
			return <>nope</>;
		}
		return (
			<OrderProductRowWrapper
				index={index}
				subOrderCode={subOrderCode}
				data={data}
				hasValue={!!value}
				hideNotes={!editMode || !value ? true : false}
				hideDelete
			>
				<Grid item xs={12}>
					first header
				</Grid>
				<InputGridField flexGrow={1} type={ComponentType.ProductAutocomplete}>
					<ProductAutocomplete
						value={value?.id ?? null}
						availableIds={availableVariants.map((v) => v.id)}
						onChange={(newValue) => {
							if (newValue) {
								const expiresAt = newValue.expiresAt ?? null;
								// TODO: change here
								changeOrderProduct(
									{
										variantId: newValue.id,
										expiresAt: expiresAt,
										unit: newValue.unit,
										sourceId: newValue.orderProductId,
										weight: newValue.weight,
										orderQty: newValue.unitsPerBox ?? 1,
									},
									subOrderCode,
									index,
								);
							}
						}}
					/>
				</InputGridField>
				{value && value.recipe?.recipeType === RecipeType.processing && (
					<ProcessingIngredientsRow recipe={value.recipe} subOrderCode={subOrderCode} />
				)}
			</OrderProductRowWrapper>
		);
	}, [variantList, data, availableVariants]);

	return (
		<Grid container spacing={2} item xs={12} sx={{ minHeight: '60px' }}>
			{ProductContent}
		</Grid>
	);
};
