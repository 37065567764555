import { DialogComponentProps, DialogResult } from '@contexts/feedbacksContext/types';
import { FormType } from '@contexts/formContext/types';
import { TaskFieldParameterForm } from '@pages/Tasks/TaskFieldParameterForm';
import { LocaleEntryAction, TranslationTypes } from 'common';
import i18n from 'i18n';
import React from 'react';

export interface QuickTaskFieldParameterDialogData {
	newName?: string;
	editId?: number;
}

export const QuickTaskFieldParameterDialog: React.FC<
	DialogComponentProps<DialogResult, QuickTaskFieldParameterDialogData>
> = ({ close, data }) => {
	return (
		<TaskFieldParameterForm
			forcedId={data?.editId}
			formAction={data?.newName ? 'create' : data?.editId ? 'edit' : 'view'}
			formType={FormType.quick}
			onDataSaved={(data) => close({ id: data.id })}
			forcedValues={
				data?.newName
					? {
							id: -1,
							params: [],
							strings: [
								{
									type: TranslationTypes.name,
									value: data.newName,
									langCode: i18n.language,
									action: LocaleEntryAction.CREATE,
								},
							],
					  }
					: undefined
			}
		/>
	);
};
