import { FormRenderType } from '@contexts/formContext/types';
import { useLayout } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import { Typography } from '@mui/material';
import { VariantForm } from '@pages/Products/VariantForm';
import { AppFunction, ProductType, TranslationTypes, VariantResponse } from 'common';

interface TableProductNameBodyProps {
	variant?: VariantResponse;
}

export const TableProductNameBody = <T extends TableProductNameBodyProps>({
	variant,
}: T): JSX.Element | null => {
	const { getTranslatedString } = useLocale();
	const { pushPopup } = useLayout();

	if (!variant) {
		return null;
	}

	const productName = getTranslatedString(
		AppFunction.Product,
		variant.productId ?? -1,
		TranslationTypes.name,
	);

	const variantName = getTranslatedString(AppFunction.Variant, variant.id, TranslationTypes.name);

	return (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() =>
				pushPopup(
					<VariantForm
						productType={ProductType.product}
						formAction='view'
						renderType={FormRenderType.popup}
						forceId={variant.id}
					/>,
				)
			}
		>
			<Typography
				sx={{
					position: 'relative',
					userSelect: 'none',
					width: 'fit-content',
					'&:hover': {
						textDecoration: 'underline',
						color: 'blue',
						'&:after': {
							right: '-1rem',
							opacity: 1,
						},
					},
					'&:after': {
						content: '">"',
						position: 'absolute',
						right: 0,
						opacity: 0,
						transition: 'right 0.3s, opacity 0.2s',
					},
				}}
			>
				{`${productName} ${variantName}`}
			</Typography>
		</div>
	);
};
