import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useGenericForm } from '../FormContext';
import { AppFunction, CustomerResponse, InvoicingMethod, TranslationTypes } from 'common';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import useAddresses from '@hooks/useAddresses';
import useCustomers from '@hooks/useCustomers';
import { Chip, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuickPersonDataRequest } from '@components/customers/QuickPersonCreate';
import _ from 'lodash';
import usePeople from '@hooks/usePeople';
import useLocale from '@hooks/useLocale';

interface CustomerFormContextType {
	editDisabled: boolean;
	cachedParent?: CustomerResponse | null;
	updatePersonData?: (data: QuickPersonDataRequest, id: number) => void;
	setCachedParent?: (parent: CustomerResponse | null) => void;
	setNewParentId?: (id: number | null) => void;
	onAfterDeleteUsedAddress?: (id: number) => void;
	addressMenuItems?: (includeParentAddresses: boolean) => JSX.Element[];
}

const defaultContext: CustomerFormContextType = {
	editDisabled: false,
	cachedParent: null,
};

interface CustomerFormContextProps {
	children: ReactNode;
	entityId: number;
	editDisabled?: boolean;
}

const CustomerFormContext = createContext<CustomerFormContextType>(defaultContext);

export const CustomerFormContextProvider = ({
	children,
	editDisabled,
}: CustomerFormContextProps) => {
	const { formValues, getValues, setValue, setBeforeCreate, setCustomSubmit, setAfterCreate } =
		useGenericForm<CustomerResponse>();
	const { pushDialog } = useFeedbacks();
	const { addressList, renderAddress, getAddressesByTypeId } = useAddresses();
	const { getTranslatedString } = useLocale();
	const { customerList } = useCustomers();
	const { jobTitlesList } = usePeople();
	const { t } = useTranslation();

	const [cachedParent, setCachedParent] = useState<CustomerResponse | null>(null);
	const [newParentId, setNewParentId] = useState<number | null>(null);
	const [tempPeopleData, setTempPeopleData] = useState<Record<
		number,
		QuickPersonDataRequest
	> | null>(null);

	const tempPeopleDataRef = useRef(tempPeopleData);
	useEffect(() => {
		tempPeopleDataRef.current = tempPeopleData;
	}, [tempPeopleData]);

	const onChangeParentAccept = () => {
		const oldParentId = getValues('parentId');
		if (oldParentId) {
			const addresses = getAddressesByTypeId(AppFunction.Customer, oldParentId);
			addresses.map((address) => {
				if (address.id === getValues('fiscalAddressId')) {
					setValue('fiscalAddressId', null);
				}
				if (address.id === getValues('physicalAddressId')) {
					setValue('physicalAddressId', null);
				}
				if (address.id === getValues('deliveryAddressId')) {
					setValue('deliveryAddressId', null);
				}
			});
		}

		const newParent = customerList.find((c) => c.id === newParentId);
		setCachedParent(newParent ?? null);
		setValue('parentId', newParentId !== -1 ? newParentId : null);
		setNewParentId(-1);
	};

	const onAfterDeleteUsedAddress = (id: number) => {
		if (id !== -1) {
			if (id === getValues('fiscalAddressId')) {
				setValue('fiscalAddressId', null);
			}
			if (id === getValues('physicalAddressId')) {
				setValue('physicalAddressId', null);
			}
			if (id === getValues('deliveryAddressId')) {
				setValue('deliveryAddressId', null);
			}
		}
	};

	const addressMenuItems = (includeParentAddresses: boolean) =>
		useMemo(() => {
			const items: JSX.Element[] = [];

			items.push(
				<MenuItem value={-1} key={'none'}>
					None
				</MenuItem>,
			);

			if (includeParentAddresses) {
				cachedParent?.addresses?.map((address, index) => {
					const parsedAddress = (
						<MenuItem value={address.id ?? -1} key={index}>
							{renderAddress(address.id ?? null, true, InvoicingMethod.mail)}
						</MenuItem>
					);
					if (parsedAddress) {
						items.push(parsedAddress);
					}
				});
			}

			formValues?.addresses?.map((address, index) => {
				const parsedAddress = (
					<MenuItem value={address.id ?? -1} key={(cachedParent?.addresses?.length ?? 0) + index}>
						{renderAddress(address.id ?? null, false, InvoicingMethod.mail, [
							formValues?.fiscalAddressId === address.id ? (
								<Chip
									size='small'
									label={t('customer.fiscal')}
									key='fiscal'
									sx={{ marginLeft: '0.3rem' }}
								/>
							) : (
								<div key='noFiscal' style={{ visibility: 'hidden' }} />
							),
							formValues?.deliveryAddressId === address.id ? (
								<Chip
									size='small'
									label={t('customer.delivery')}
									key='delivery'
									sx={{ marginLeft: '0.3rem' }}
								/>
							) : (
								<div key='noDelivery' style={{ visibility: 'hidden' }} />
							),
							formValues?.physicalAddressId === address.id ? (
								<Chip
									size='small'
									label={t('customer.physical')}
									key='physical'
									sx={{ marginLeft: '0.3rem' }}
								/>
							) : (
								<div key='noPhysical' style={{ visibility: 'hidden' }} />
							),
							formValues?.detailsAddressId === address.id ? (
								<Chip
									size='small'
									label={t('customer.details')}
									key='details'
									sx={{ marginLeft: '0.3rem' }}
								/>
							) : (
								<div key='noDetails' style={{ visibility: 'hidden' }} />
							),
						])}
					</MenuItem>
				);
				if (parsedAddress) {
					items.push(parsedAddress);
				}
			});

			return items;
		}, [cachedParent, formValues, addressList]);

	const updatePersonData = (data: QuickPersonDataRequest, id: number) => {
		setTempPeopleData((prevPersonData) => {
			const newPeopleData = { ...prevPersonData, [id]: data };
			if (!_.isEqual(prevPersonData, newPeopleData)) {
				return newPeopleData;
			}
			return prevPersonData;
		});
	};

	useEffect(() => {
		// TODO: maybe we should watch the parent and cache it
		if (newParentId) {
			pushDialog({
				title: `newParentId, ${newParentId}`,
				message: 'customer.change.parent.warning',
				type: 'withActions',
				actions: [
					{
						label: 'Cancel',
						action: () => setNewParentId(null),
					},
					{
						label: 'Ok',
						action: onChangeParentAccept,
					},
				],
			});
		}
	}, [newParentId]);

	useEffect(() => {
		setCustomSubmit((data: CustomerResponse): CustomerResponse => {
			return data;
		});

		return () => {
			setCustomSubmit();
		};
	}, [setCustomSubmit]);

	useEffect(() => {
		setAfterCreate((data: CustomerResponse): Promise<boolean> => {
			console.log('Customer created', data);
			return Promise.resolve(true);
		});

		return () => {
			setAfterCreate();
		};
	}, [setAfterCreate]);

	useEffect(() => {
		const beforeCreateFn = (): Promise<boolean> => {
			const isPeopleDataValid = Object.values(tempPeopleDataRef.current ?? {}).every(
				(personData) => personData.name.length > 0 && personData.surname.length > 0,
			);

			if (isPeopleDataValid) {
				return Promise.resolve(true);
			} else {
				const mandatoryTitles = jobTitlesList.filter((jt) => jt.mandatory === true);
				let jobTitles = '';
				const separator = ', ';
				mandatoryTitles.map((mt, it) => {
					const jobTitleName = getTranslatedString(
						AppFunction.JobTitle,
						mt.id,
						TranslationTypes.name,
					);
					jobTitles += `${jobTitleName}${it === mandatoryTitles.length - 1 ? '' : separator}`;
				});

				return pushDialog({
					title: t(`feedbacks.${AppFunction.Customer}.people.dataWarning.title`),
					message: t(`feedbacks.${AppFunction.Customer}.people.dataWarning.message`, { jobTitles }),
					type: 'withActions',
					actions: [
						{
							label: t(`feedbacks.${AppFunction.Customer}.people.dataWarning.cancel`),
							action: () => console.log(tempPeopleDataRef.current),
							resolve: false,
						},
						{
							label: t(`feedbacks.${AppFunction.Customer}.people.dataWarning.ok`),
							action: () => {
								console.log('ok');
							},
							resolve: true,
						},
					],
				});
			}
		};

		setBeforeCreate(beforeCreateFn);

		return () => setBeforeCreate();
	}, [setBeforeCreate]);

	const contextValue = {
		editDisabled: editDisabled ?? false,
		cachedParent,
		updatePersonData,
		setCachedParent,
		setNewParentId,
		onAfterDeleteUsedAddress,
		addressMenuItems,
	};

	return (
		<CustomerFormContext.Provider value={contextValue}>{children}</CustomerFormContext.Provider>
	);
};

export const useCustomerForm = () => useContext(CustomerFormContext);
