import useLocale from '@hooks/useLocale';
import { AppFunction, TranslationTypes } from 'common';

interface TableNameBodyProps {
	id?: string | number;
	appFunction: AppFunction;
}

export const TableNameBody = <T extends TableNameBodyProps>({
	id,
	appFunction,
}: T): JSX.Element | null => {
	const { getTranslatedString } = useLocale();

	if (!id) {
		return null;
	}

	const name = getTranslatedString(appFunction, id, TranslationTypes.name);

	return <>{name}</>;
};
