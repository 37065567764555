import { SxProps } from '@mui/material';
import { AppFunction } from 'common';
import { appFunctionsConfig } from 'config';
import React, { cloneElement } from 'react';

function useAppFunctions() {
	const getIcon = (
		appFunction: AppFunction,
		// TODO: make a compound type for subFunction in Common
		subFunction?: string,
		inheritFontSize?: boolean,
		sx?: SxProps,
	) => {
		const functionConfig = appFunctionsConfig[appFunction];

		if (!functionConfig) return null;

		const icon: JSX.Element | null = functionConfig[subFunction ?? 'default'] ?? null;

		if (icon && React.isValidElement(icon)) {
			const addedProps = inheritFontSize ? { fontSize: 'inherit' } : {};
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const iconSx = icon.props.sx as SxProps;
			const styledIcon = cloneElement(icon, {
				sx: { ...iconSx, ...sx },
				...addedProps,
			} as React.Attributes & {
				sx?: SxProps;
			});
			return styledIcon;
		}
		return null;
	};

	return {
		getIcon,
	};
}

export default useAppFunctions;
