import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import { useAuth } from './auth';

import {
	AffectedRowsResponse,
	CustomerBillingRequest,
	CustomerBillingResponse,
	CustomerBranchRequest,
	CustomerBranchResponse,
	CustomerCreateSchema,
	CustomerInvoicingTimeRequest,
	CustomerInvoicingTimeResponse,
	CustomerNoteRequest,
	CustomerNoteResponse,
	CustomerNotesResponse,
	CustomerRequest,
	CustomerResponse,
	CustomersResponse,
	GenericIdRequest,
} from 'common';
import {
	doCreateCustomer,
	doCreateCustomerBilling,
	doCreateCustomerBranch,
	doCreateCustomerInvoicingTime,
	doCreateCustomersBulk,
	doDeleteCustomerBilling,
	doDeleteCustomerBranch,
	doDeleteCustomerInvoicingTime,
	doEditCustomer,
	doEditCustomerBilling,
	doEditCustomerBranch,
	doEditCustomerInvoicingTime,
} from '../store/customers';
import { PayloadAction } from '@reduxjs/toolkit';

function useCustomers() {
	const dispatch = useAppDispatch();
	const { user } = useAuth();
	const customersSlice = useAppSelector((state) => state.customers.customers);

	const [filter, setFilter] = useState('');
	const [customerList, setCustomerList] = useState<CustomersResponse>(customersSlice);
	const [currentCustomerList, setCurrentCustomerList] = useState<CustomersResponse>(customersSlice);

	useEffect(() => {
		setCustomerList((currentList) => {
			if (!_.isEqual(currentList, customersSlice)) {
				return customersSlice;
			}
			return currentList;
		});
	}, [customersSlice]);

	useEffect(() => {
		filterCustomers();
	}, [customerList, filter]);

	const setFilterParams = (keyword: string) => setFilter(keyword);

	// this is a stub, we will have to filter customers looking
	// at the localized strings for the name,
	// but also looking at their customer code and maybe other params
	const filterCustomers = () => {
		let newList: CustomersResponse = [];
		if (filter !== '') {
			newList = customerList.filter((customer) => customer.customerCode.includes(filter));
		} else {
			newList = customerList;
		}

		setCurrentCustomerList((currentList) => {
			if (!_.isEqual(currentList, newList)) {
				return newList;
			}
			return currentList;
		});
	};

	const createCustomer = async (
		data: CustomerRequest | CustomerResponse,
	): Promise<CustomerResponse> => {
		const parsedData = CustomerCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid customer data');
		} else {
			const response = (await dispatch(
				doCreateCustomer(parsedData.data),
			)) as PayloadAction<CustomerResponse>;
			if (response.type === 'customers/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('customers.create.rejected');
		}
	};

	const bulkCreateCustomers = async (data: CustomerRequest[]) => {
		return await dispatch(doCreateCustomersBulk(data));
	};

	const editCustomer = async (data: CustomerResponse): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doEditCustomer(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'customers/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('customers.edit.rejected');
	};

	const getNotesByCustomer = (customerId: number): CustomerNotesResponse => {
		const foundCustomer = customerList.find((c) => c.id === customerId);
		const notes: CustomerNotesResponse = [];
		if (foundCustomer?.customerNotes) {
			foundCustomer.customerNotes
				.slice()
				.sort((a, b) => {
					if (a.updatedAt && b.updatedAt) {
						const aDate = a.updatedAt ? new Date(a.updatedAt) : null;
						const bDate = b.updatedAt ? new Date(b.updatedAt) : null;

						if (aDate && bDate) {
							return bDate.getTime() - aDate.getTime();
						}

						return b.id - a.id;
					}
					return b.id - a.id;
				})
				.forEach((note) => {
					if (note.private) {
						if (note.userId === user?.id) {
							notes.push(note);
						}
					} else {
						notes.push(note);
					}
				});
		}
		return notes;
	};

	const createInvoicingTime = async (data: CustomerInvoicingTimeRequest) => {
		return await dispatch(doCreateCustomerInvoicingTime(data));
	};

	const editInvoicingTime = async (data: CustomerInvoicingTimeResponse) => {
		return await dispatch(doEditCustomerInvoicingTime(data));
	};

	const deleteInvoicingTime = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCustomerInvoicingTime(data));
	};

	const createCustomerBilling = async (data: CustomerBillingRequest) => {
		return await dispatch(doCreateCustomerBilling(data));
	};

	const editCustomerBilling = async (data: CustomerBillingResponse) => {
		return await dispatch(doEditCustomerBilling(data));
	};

	const deleteCustomerBilling = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCustomerBilling(data));
	};

	const createCustomerBranch = async (data: CustomerBranchRequest) => {
		return await dispatch(doCreateCustomerBranch(data));
	};

	const editCustomerBranch = async (data: CustomerBranchResponse) => {
		return await dispatch(doEditCustomerBranch(data));
	};

	const deleteCustomerBranch = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCustomerBranch(data));
	};

	return {
		customerList: currentCustomerList,
		filterCustomers: setFilterParams,
		createCustomer,
		bulkCreateCustomers,
		editCustomer,
		getNotesByCustomer,
		createInvoicingTime,
		editInvoicingTime,
		deleteInvoicingTime,
		createCustomerBilling,
		editCustomerBilling,
		deleteCustomerBilling,
		createCustomerBranch,
		editCustomerBranch,
		deleteCustomerBranch,
	};
}

export default useCustomers;
