import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AffectedRowsResponse,
	GenericIdRequest,
	PeopleResponse,
	PersonJobTitleCreateSchema,
	PersonJobTitleRequest,
	PersonJobTitleResponse,
	PersonJobTitleSchema,
	PersonJobTitlesResponse,
	PersonRequest,
	PersonResponse,
	PersonToJobTitleLinkRequest,
} from 'common';
import {
	doCreateJobTitle,
	doCreatePerson,
	doDeleteJobTitle,
	doDeletePerson,
	doEditJobTitle,
	doEditPerson,
	doLinkPersonToJobTitle,
	doUnlinkPersonFromJobTitle,
} from '../store/people';
import { PayloadAction } from '@reduxjs/toolkit';

function usePeople() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.people.op_status);
	const peopleSlice = useAppSelector((state) => state.people.people);
	const jobTitlesSlice = useAppSelector((state) => state.people.jobTitles);

	// const [filter, setFilter] = useState('');

	const [peopleList, setPeopleList] = useState<PeopleResponse>(peopleSlice);
	const [jobTitlesList, setJobTitlesList] = useState<PersonJobTitlesResponse>(jobTitlesSlice);

	useEffect(() => {
		setPeopleList((currentList) => {
			if (!_.isEqual(currentList, peopleSlice)) {
				return peopleSlice;
			}
			return currentList;
		});
	}, [peopleSlice]);

	useEffect(() => {
		setJobTitlesList((currentList) => {
			if (!_.isEqual(currentList, jobTitlesSlice)) {
				return jobTitlesSlice;
			}
			return currentList;
		});
	}, [jobTitlesSlice]);

	const createPerson = async (data: PersonRequest) => {
		return await dispatch(doCreatePerson(data));
	};

	const editPerson = async (data: PersonResponse) => {
		return await dispatch(doEditPerson(data));
	};

	const deletePerson = async (data: GenericIdRequest) => {
		return await dispatch(doDeletePerson(data));
	};

	const createJobTitle = async (data: PersonJobTitleRequest): Promise<PersonJobTitleResponse> => {
		const parsedData = PersonJobTitleCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid job title data');
		}
		const response = (await dispatch(
			doCreateJobTitle(parsedData.data),
		)) as PayloadAction<PersonJobTitleResponse>;
		if (response.type === 'people/jobtitles/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error creating job title');
	};

	const editJobTitle = async (data: PersonJobTitleResponse): Promise<AffectedRowsResponse> => {
		const parsedData = PersonJobTitleSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid job title data');
		}
		const response = (await dispatch(
			doEditJobTitle(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'people/jobtitles/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing job title');
	};

	const deleteJobTitle = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteJobTitle(data));
	};

	const linkPersonToJobTitle = async (data: PersonToJobTitleLinkRequest) => {
		return await dispatch(doLinkPersonToJobTitle(data));
	};

	const unlinkPersonFromJobTitle = async (data: PersonToJobTitleLinkRequest) => {
		return await dispatch(doUnlinkPersonFromJobTitle(data));
	};

	return {
		opStatus,
		peopleList,
		jobTitlesList,
		createPerson,
		editPerson,
		deletePerson,
		createJobTitle,
		editJobTitle,
		deleteJobTitle,
		linkPersonToJobTitle,
		unlinkPersonFromJobTitle,
	};
}

export default usePeople;
