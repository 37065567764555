import { PayloadAction } from '@reduxjs/toolkit';
import { doSetTaskValues } from '../../../store/tasks';
import { TaskApiActionData, parseValuesToString, TaskFieldConfig } from '../taskFieldConfig';
import { TaskFieldValueResponse, TaskFieldValuesResponse } from 'common';
import { z } from 'zod';

const FileInputFieldValueSchema = z.object({
	fileName: z.string(),
	uri: z.string(),
});
const FileInputFieldValuesSchema = z.array(FileInputFieldValueSchema);

export type FileInputFieldValue = z.infer<typeof FileInputFieldValueSchema>;
export type FileInputFieldValues = z.infer<typeof FileInputFieldValuesSchema>;

export const fileInputFieldConfig: TaskFieldConfig = {
	stringToValues: (value: string) => {
		try {
			const values = JSON.parse(value);
			const parsed = FileInputFieldValuesSchema.safeParse(values);
			if (parsed.success) {
				return parsed.data;
			} else {
				console.error('Error parsing string:', parsed.error);
				return [];
			}
		} catch (error) {
			console.error('Error parsing string:', error);
			return [];
		}
	},
	valuesToString: (values: unknown[]) => parseValuesToString(FileInputFieldValuesSchema, values),
	validateStructure: (values: unknown[]): boolean => {
		const parsed = FileInputFieldValuesSchema.safeParse(values);
		if (parsed.success) {
			return !parsed.data.some((value) => value.fileName === '' || value.uri === '');
		} else {
			return false;
		}
	},
	executeApiAction: async (data: TaskApiActionData) => {
		const stringified = parseValuesToString(FileInputFieldValuesSchema, data.values);
		const setValuesRequest = [
			{
				taskFieldArchetypeId: data.fieldId,
				value: stringified,
				taskId: data.taskId,
			},
		];
		const setValuesResult = (await data.dispatch(
			doSetTaskValues(setValuesRequest),
		)) as PayloadAction<TaskFieldValuesResponse | TaskFieldValueResponse>;
		if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
			return Promise.reject('Set task values API call failed');
		}
		return Promise.resolve();
	},
};
