import { EnhancedOrderBaseChildRequest } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { OrderTimeScheduler } from '../common/OrderTimeScheduler';
import moment from 'moment';

interface Props {
	currentSubOrder?: EnhancedOrderBaseChildRequest;
}

export const InboundSubOrderTimeScheduler = ({ currentSubOrder }: Props) => {
	const { loading, subOrdersChainData, getValues } = useOrderEditor();
	const { setSuborderProperties } = useOrderEditor();

	if (!currentSubOrder?.code) {
		return null;
	}

	const changeEad = (date: Date) => {
		if (currentSubOrder.code) {
			setSuborderProperties({
				subOrderCode: currentSubOrder.code,
				ead: date,
			});
		}
	};

	const archetype = subOrdersChainData[currentSubOrder.code];
	const minDate = getValues('ead') ? moment(getValues('ead')) : null;
	const value = currentSubOrder.ead ? moment(currentSubOrder.ead) : null;

	return (
		<OrderTimeScheduler
			loading={loading}
			currentChainArchetype={archetype}
			changeEad={changeEad}
			minDate={minDate}
			value={value}
		/>
	);
};
