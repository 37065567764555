import { Fragment, useEffect, useState } from 'react';
import useTasks from '../../../hooks/useTasks';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { TaskArchetypeListItem } from '../TaskArchetypeListItem';
import { DragZoneName } from '../../../pages/Tasks/TaskChainArchetypeCreate';

interface Props {
	searchWord?: string;
}

export const TaskArchetypeDrawer = ({ searchWord }: Props) => {
	const { taskArchetypesList } = useTasks();
	const [open, setOpen] = useState(true);
	const [filtered, setFiltered] = useState(taskArchetypesList);

	useEffect(() => {
		if (searchWord && searchWord !== '') {
			const filtered = taskArchetypesList.filter((archetype) => {
				return archetype.name.toLowerCase().includes(searchWord.toLowerCase());
			});
			setFiltered(filtered);
		} else {
			setFiltered(taskArchetypesList);
		}
	}, [searchWord, taskArchetypesList]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					cursor: 'pointer',
					userSelect: 'none',
					padding: '0 1rem 0 1rem',
				}}
				onClick={() => setOpen((state) => !state)}
			>
				<KeyboardArrowRight sx={{ transform: `rotate(${open ? 90 : 0}deg)` }} />
				<div>Task Archetypes</div>
			</div>
			<Collapse in={open}>
				<Droppable droppableId={DragZoneName.taskArchetypeDroppableList} isDropDisabled={true}>
					{(provided) => (
						<List ref={provided.innerRef} {...provided.droppableProps} sx={{ padding: '1rem' }}>
							{filtered.map((archetype, index) => (
								<Draggable draggableId={archetype.id.toString()} index={index} key={archetype.id}>
									{(provided, snapshot) => {
										return (
											<Fragment>
												<TaskArchetypeListItem
													provided={provided}
													archetype={archetype}
													isDragging={snapshot.isDragging}
												/>
												{snapshot.isDragging && (
													<TaskArchetypeListItem archetype={archetype} isDragging={false} />
												)}
											</Fragment>
										);
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</List>
					)}
				</Droppable>
			</Collapse>
		</>
	);
};
