import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import { Grid, Typography } from '@mui/material';
import { AppFunction, OrderProductResponse, TranslationTypes } from 'common';

interface Props {
	content: OrderProductResponse;
	selected?: boolean;
}

export const ManualEntryItem = ({ content, selected }: Props) => {
	const { getTranslatedString } = useLocale();
	const { variantList } = useProducts();

	const variant = variantList.find((variant) => variant.id === content.variantId);

	return (
		<Grid
			item
			xs={12}
			container
			gap={1}
			p={1}
			sx={{
				backgroundColor: selected ? 'yellow' : 'white',
			}}
		>
			<Grid item>
				<Typography variant='body1'>
					{getTranslatedString(
						AppFunction.Product,
						variant?.productId ?? -1,
						TranslationTypes.name,
					)}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant='body1'>
					{getTranslatedString(AppFunction.Variant, content.variantId ?? -1, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant='body1'>{content.arrivedQty}</Typography>
			</Grid>
		</Grid>
	);
};
