import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { useOrderEditor } from '../../../contexts/orderEditorContext/OrderEditorContext';
import {
	AppFunction,
	CustomerBranchesResponse,
	CustomersResponse,
	MerchantsResponse,
	OrderDirectionType,
	OrderType,
	ShippingType,
	StorageType,
	TranslationTypes,
	WarehousesResponse,
} from 'common';
import { useWarehouses, useMerchants, useLocale, useCustomers } from '@hooks/index';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
	subOrderCode: string;
}

type OptionsType =
	| WarehousesResponse
	| MerchantsResponse
	| CustomersResponse
	| CustomerBranchesResponse;

export const SubOrder = ({ subOrderCode }: Props) => {
	const {
		renderType,
		orderType,
		subOrders,
		config,
		configValues,
		setSuborderProperties,
		removeSubOrder,
	} = useOrderEditor();

	const { warehouseList } = useWarehouses();
	const { merchantList } = useMerchants();
	const { customerList } = useCustomers();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	/**
	 * Unavailable variant ids are fetched from the suborders list
	 */
	const unavailableVariantIds: number[] = subOrders.reduce((acc: number[], soc) => {
		if (soc.code === subOrderCode) {
			const values = soc.content?.map((c) => c.variantId ?? -1);
			if (values) {
				acc.push(...values);
			}
		}
		return acc;
	}, []);

	/**
	 * Fetches available directions from the config (toType)
	 * @returns {OrderDirectionType[]} - the available directions
	 */
	const availableDirections = (): OrderDirectionType[] => {
		if (configValues?.subOrderTypes?.isValueOfType<OrderDirectionType[]>()) {
			return configValues.subOrderTypes.value;
		}
		return [] as OrderDirectionType[];
	};

	/**
	 * Fetches the available options for the order direction
	 * based on the current suborder's toType
	 * Unavailable toIds are filtered out.
	 * @returns {OptionsType} Available dropdown options
	 */
	const getOptions = (): OptionsType => {
		/**
		 * Fetches the unavailable toIds for the current suborder
		 * from the suborders list. Already selected toIds are not available.
		 * @returns {number[]} the unavailable toIds
		 */
		const unavailableToIds = subOrders.reduce((acc: number[], soc) => {
			if (soc.toType === currentSubOrder?.toType && soc.toId !== currentSubOrder?.toId) {
				acc.push(Number(soc.toId));
			}
			return acc;
		}, []);
		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse:
				return warehouseList.filter(
					(w) =>
						w.storageType !== StorageType.port &&
						w.storageType !== StorageType.airport &&
						!unavailableToIds.includes(w.id),
				);
			case OrderDirectionType.merchant:
				return merchantList.filter((m) => !unavailableToIds.includes(m.id));
			case OrderDirectionType.customer:
				return customerList.filter((c) => c.id && !unavailableToIds.includes(c.id));
			case OrderDirectionType.branch:
				return customerList
					.map((c) => c.customerBranches ?? [])
					.flat()
					.filter((cb) => !unavailableToIds.includes(cb.id));
			default:
				return [];
		}
	};

	const getIconForType = () => {
		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse:
				return '🏭';
			case OrderDirectionType.merchant:
				return '🏬';
			case OrderDirectionType.customer:
				return '🏢';
			case OrderDirectionType.branch:
				return '🏤';
			default:
				return '';
		}
	};

	/**
	 * Fetches the name of the direction based on the index
	 * @param {number} index - the index of the direction
	 * @returns {string} - the name of the direction
	 */
	const getName = (index: number): string => {
		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse:
				return getTranslatedString(AppFunction.Location, index, TranslationTypes.name);
			case OrderDirectionType.merchant:
				return getTranslatedString(AppFunction.Merchant, index, TranslationTypes.name);
			case OrderDirectionType.customer:
				return getTranslatedString(AppFunction.Customer, index, TranslationTypes.name);
			case OrderDirectionType.branch: {
				const customer = customerList.find((c) =>
					c.customerBranches?.some((cb) => cb.id === index),
				);
				const customerName = getTranslatedString(
					AppFunction.Customer,
					customer?.id ?? -1,
					TranslationTypes.name,
				);
				const branchName = getTranslatedString(
					AppFunction.CustomerBranch,
					index,
					TranslationTypes.name,
				);
				return `[${customerName}] ${branchName}`;
			}
			default:
				return '';
		}
	};

	/**
	 * Deletes the current suborder from the list
	 * Uses the removeSubOrder function from the context with the current suborder code
	 */
	const deleteSubOrderContent = () => removeSubOrder(subOrderCode);

	// TODO: we should have a SuborderHeader component
	const getSuborderHeader = () => {
		if (
			orderType === OrderType.Processing ||
			orderType === OrderType.Modify ||
			orderType === OrderType.Split
		) {
			return null;
		}
		if (orderType === OrderType.Manual && warehouseList.length > 0) {
			return (
				<Grid item xs={12} sx={{ marginBottom: '1rem' }}>
					<Autocomplete
						options={warehouseList.map((w) => w.id)}
						getOptionLabel={(option) =>
							option === -1 || option === undefined ? 'None' : getName(option)
						}
						value={currentSubOrder?.toId ?? warehouseList[0].id}
						onChange={(_, newValue) => {
							const newDirectionId = newValue ?? warehouseList[0].id;
							setSuborderProperties({
								subOrderCode: subOrderCode,
								fromId: newDirectionId,
								toId: newDirectionId,
							});
						}}
						renderOption={(props, option) => <li {...props}>{getName(option)}</li>}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`appFunctions.${AppFunction.Location}`)}
								variant='outlined'
							/>
						)}
						size='small'
						disablePortal={renderType === 'popup'}
					/>
				</Grid>
			);
		}
		return (
			<Grid item container columnSpacing={2}>
				<Grid item xs={4} sx={{ marginBottom: '1rem' }}>
					<Autocomplete
						options={Object.values(OrderDirectionType).filter((odt) =>
							availableDirections().includes(odt),
						)}
						value={currentSubOrder?.toType ?? availableDirections()[0]}
						size='small'
						onChange={(_, newValue) => {
							setSuborderProperties({
								subOrderCode: subOrderCode,
								toType: newValue ?? OrderDirectionType.warehouse,
							});
						}}
						renderInput={(params) => (
							<TextField {...params} label={t('order.toType')} variant='outlined' />
						)}
						getOptionLabel={(option) => t(`order.to.${option.toLowerCase()}`)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={['-1', ...getOptions().map((o) => o.id?.toString())]}
						getOptionLabel={(option) =>
							option === '-1' || option === undefined ? 'None' : getName(parseInt(option))
						}
						value={currentSubOrder?.toId?.toString() ?? '-1'}
						size='small'
						onChange={(_, newValue) => {
							const newDirectionId = newValue ? parseInt(newValue) : -1;
							setSuborderProperties({
								subOrderCode: subOrderCode,
								toId: newDirectionId,
							});
						}}
						renderOption={(props, option) => (
							<li {...props}>
								{option === '-1' ? <></> : getIconForType()}
								{option === '-1' || option === undefined ? 'None' : getName(parseInt(option))}
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`order.to.${currentSubOrder?.toType?.toLowerCase()}`)}
								variant='outlined'
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={Object.values(ShippingType)}
						value={currentSubOrder?.shippingType ?? 'none'}
						size='small'
						onChange={(_, newValue) => {
							setSuborderProperties({
								subOrderCode: subOrderCode,
								shippingType: newValue !== 'none' ? (newValue as ShippingType) : undefined,
							});
						}}
						renderInput={(params) => (
							<TextField {...params} label={t('order.shippingType')} variant='outlined' />
						)}
						getOptionLabel={(option) =>
							option === 'none' || option === undefined
								? t('order.shippingTypes.undefined')
								: t(`order.shippingTypes.${option.toLowerCase()}`)
						}
						renderOption={(props, option) => (
							<li {...props}>
								{option === 'none' ? <></> : '🚚'}
								{option === 'none'
									? t('order.shippingTypes.undefined')
									: t(`order.shippingTypes.${option.toLowerCase()}`)}
							</li>
						)}
					/>
				</Grid>
			</Grid>
		);
	};

	if (!config) return null;

	const hasBottomBorder = configValues?.borderBottom?.isValueOfType<boolean>()
		? configValues.borderBottom.value
		: true;

	return (
		<Grid
			item
			xs={12}
			container
			sx={{
				margin: '1rem 0 1rem 0',
				padding: '1rem',
				borderBottom: subOrders.length > 1 && hasBottomBorder ? '1px solid #000000' : '',
			}}
			spacing={orderType === OrderType.Processing ? 0 : 2}
			display='flex'
		>
			<Grid
				item
				container
				flexGrow={1}
				sx={{
					width: 'auto',
					maxWidth:
						orderType !== OrderType.Modify && orderType !== OrderType.Split
							? 'calc(100% - 4rem)'
							: '100%',
				}}
			>
				{config.maxSubOrders &&
					(config.maxSubOrders === 'unlimited' || config.maxSubOrders > 1) &&
					getSuborderHeader()}
				{
					// temprarily disabled this check because we are dealing with Cut orders
					// this could be checked in the config
					// currentSubOrder?.toId !== -1 &&
					orderType !== null && config.subOrderProductsContainer && (
						<config.subOrderProductsContainer
							key={`${subOrderCode}-${orderType.toLowerCase()}`}
							subOrder={currentSubOrder}
						/>
					)
				}
			</Grid>
			{orderType !== OrderType.Modify && orderType !== OrderType.Split && (
				<Grid
					item
					flexShrink={1}
					flexBasis={0}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<IconButton onClick={deleteSubOrderContent}>
						<Delete />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};
