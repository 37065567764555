import { Autocomplete, Box, Chip, Grid, MenuItem, Select, TextField } from '@mui/material';
import { AppFunction, RecipeType, StorageType, TranslationTypes, VariantResponse } from 'common';
import { Controller } from 'react-hook-form';

import commonStyles from '@styles/Common.module.css';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { Add } from '@mui/icons-material';
import useWarehouses from '@hooks/useWarehouses';
import useLocale from '@hooks/useLocale';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { RecipeIngredient } from '@components/products/recipes/RecipeIngredient';
import { useTranslation } from 'react-i18next';

export const TransformRecipe = () => {
	const { control, entityId } = useGenericForm<VariantResponse>();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	return (
		<Box className={`${commonStyles.itemBox} ${commonStyles.rounded}`}>
			<TabInnerTitle title='product.transformRecipe' translate size='small' />
			<Controller
				name='recipe'
				control={control}
				render={({ field }) => (
					<Grid container rowGap={2}>
						{field.value?.ingredients && field.value.ingredients.length > 0 && (
							<>
								<Grid item xs={12}>
									<Select
										id='recipeType-selector'
										value={field.value?.recipeType}
										fullWidth
										onChange={(e) => {
											const recipeType = e.target.value as RecipeType;
											if (recipeType === RecipeType.division) {
												field.onChange({
													processingLocation: field.value?.processingLocation ?? [],
													recipeType: e.target.value as RecipeType,
													ingredients: [
														{
															variantId: entityId,
															unitWeight: 0,
															usedUnit: 0,
														},
													],
												});
											} else {
												field.onChange({
													processingLocation: field.value?.processingLocation ?? [],
													recipeType: e.target.value as RecipeType,
													ingredients: field.value?.ingredients || [],
												});
											}
										}}
									>
										{Object.values(RecipeType).map((type) => (
											<MenuItem id={`recipeType-${type}`} value={type} key={type}>
												{t(`${AppFunction.Product}.recipeTypes.${type}`)}
											</MenuItem>
										))}
									</Select>
								</Grid>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										// disablePortal={renderMode === 'popup' ? true : false}
										options={warehouseList.filter((w) => w.storageType === StorageType.processing)}
										getOptionLabel={(option) =>
											option?.id
												? getTranslatedString(
														AppFunction.Location,
														option.id,
														TranslationTypes.name,
												  )
												: ''
										}
										value={warehouseList.filter((wh) =>
											field.value?.processingLocation.includes(wh.id),
										)}
										onChange={(_, newValue) => {
											field.onChange({
												processingLocation: newValue.map((wh) => wh.id),
												recipeType: field.value?.recipeType ?? RecipeType.division,
												ingredients: field.value?.ingredients || [],
											});
										}}
										renderTags={(value, getTagProps) =>
											value.map((option, index) => (
												<Chip
													// icon={<Add />}
													variant='outlined'
													label={getTranslatedString(
														AppFunction.Location,
														option.id,
														TranslationTypes.name,
													)}
													{...getTagProps({ index })}
													key={option.id}
												/>
											))
										}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='outlined'
												label={t(`${AppFunction.Product}.processingLocation`)}
												placeholder='select location'
											/>
										)}
										isOptionEqualToValue={(option, value) => option.id === value.id}
									/>
								</Grid>
								<Grid item xs={12}>
									<TabInnerTitle
										title={t(`${AppFunction.Product}.ingredient`, {
											count: field.value?.ingredients.length ?? 1,
										})}
										translate
										size='small'
									/>
								</Grid>
							</>
						)}
						{field.value?.ingredients?.map((value, it) => (
							<RecipeIngredient
								key={`${value.variantId}-${it}`}
								values={field.value?.ingredients || []}
								index={it}
								isDivisionIngredient={field.value?.recipeType === RecipeType.division}
								onChange={(values) => {
									// We set the values to null if the array is empty
									// this will completely delete the recipe from the variant
									// which in case of new recipe addition will create a completely new recipe,
									// thus setting it automatically to division
									if (values.length === 0) {
										field.onChange(null);
									} else {
										field.onChange({
											processingLocation: field.value?.processingLocation ?? [],
											recipeType: field.value?.recipeType ?? RecipeType.division,
											ingredients: values,
										});
									}
								}}
							/>
						))}
						{
							// Division type recipes can only have one ingredient
							!(
								field.value?.ingredients &&
								field.value.ingredients.length > 0 &&
								field.value?.recipeType === RecipeType.division
							) && (
								<Grid
									item
									xs={12}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<AnimatedIconButton
										icon={<Add />}
										text='operations.create'
										translate
										colorType='info'
										onClick={() => {
											if (!field.value) {
												field.onChange({
													processingLocation: [],
													recipeType: RecipeType.division,
													ingredients: [{ variantId: entityId, unitWeight: 0, usedUnit: 0 }],
												});
											} else {
												const newValue = field.value?.ingredients || [];
												field.onChange({
													processingLocation: field.value?.processingLocation ?? [],
													recipeType: field.value?.recipeType ?? RecipeType.division,
													ingredients: [...newValue, { variantId: -1, unitWeight: 0, usedUnit: 0 }],
												});
											}
										}}
									/>
								</Grid>
							)
						}
					</Grid>
				)}
			/>
		</Box>
	);
};
