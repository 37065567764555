import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import { PayloadAction } from '@reduxjs/toolkit';
import {
	AffectedRowsResponse,
	EntitiesEntityGroupRequest,
	EntitiesEntityGroupResponse,
	EntityGroupRequest,
	EntityGroupResponse,
	EntityGroupsResponse,
	GenericIdRequest,
} from 'common';
import {
	doCreateGroup,
	doDeleteGroup,
	doEditGroup,
	doLinkEntityToGroup,
	doUnlinkEntityFromGroup,
} from '../store/groups';

function useGroups() {
	const dispatch = useAppDispatch();
	const op_status = useAppSelector((state) => state.groups.op_status);
	const groupsSlice = useAppSelector((state) => state.groups.groups);
	const linksSlice = useAppSelector((state) => state.groups.links);

	const [groupList, setGroupList] = useState(groupsSlice);
	const [linksList, setLinksList] = useState(linksSlice);

	useEffect(() => {
		setGroupList((currentList) => {
			if (!_.isEqual(currentList, groupsSlice)) {
				return groupsSlice;
			}
			return currentList;
		});
	}, [groupsSlice]);

	useEffect(() => {
		setLinksList((currentList) => {
			if (!_.isEqual(currentList, linksSlice)) {
				return linksSlice;
			}
			return currentList;
		});
	}, [linksSlice]);

	const createGroup = async (data: EntityGroupRequest): Promise<EntityGroupResponse> => {
		const response = (await dispatch(doCreateGroup(data))) as PayloadAction<EntityGroupResponse>;
		return response.payload;
	};

	const editGroup = async (data: EntityGroupResponse): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doEditGroup(data))) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const deleteGroup = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doDeleteGroup(data))) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const linkEntitiesToGroup = async (
		data: EntitiesEntityGroupRequest,
	): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doLinkEntityToGroup(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const unlinkEntitiesFromGroup = async (
		data: EntitiesEntityGroupRequest,
	): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doUnlinkEntityFromGroup(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const getLinkedGroups = (entityId: number): EntityGroupsResponse | null => {
		return groupList.filter((m) => {
			const link = linksList.find((l) => l.entityId === entityId && l.entityGroupId === m.id);
			return link != null;
		});
	};

	const getLinkedEntities = (groupId: number): EntitiesEntityGroupResponse | null => {
		return linksList.filter((m) => m.entityGroupId === groupId);
	};

	return {
		op_status,
		groupList,
		createGroup,
		editGroup,
		deleteGroup,
		linksList,
		linkEntitiesToGroup,
		unlinkEntityFromGroup: unlinkEntitiesFromGroup,
		getLinkedGroups,
		getLinkedEntities,
	};
}

export default useGroups;
