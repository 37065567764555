import {
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderProductRequest,
	ShippingType,
} from 'common';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { OrderApiActionData, OrderTypeConfig } from './orderTypeConfig';
import { doCreateOrder } from '@store/order';
import { PayloadAction } from '@reduxjs/toolkit';
import { ManualSuborderProductsContainer } from '../suborder/rows/manual/ManualSuborderProductsContainer';
import { ManualSuborderRow } from '../suborder/rows/manual/ManualSuborderRow';
import { ManualOrderHeader } from '../header/manual/ManualOrderHeader';
import moment from 'moment';

export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(false),
		fromType: new OrderElementConfigItem(false),
		shippingType: new OrderElementConfigItem(false),
		toType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		toSubType: new OrderElementConfigItem(false),
		toWarehouseType: new OrderElementConfigItem(false),
		subOrderTypes: new OrderElementConfigItem(false),
	},
	maxSubOrders: 'unlimited',
	// TODO: delete
	headerElement: ManualOrderHeader,
	subOrderRow: ManualSuborderRow,
	subOrderProductsContainer: ManualSuborderProductsContainer,
	isAddSuborderDisabled: () => false,
	isCreateOrderDisabled: () => false,
	executeOrderAction: async (data: OrderApiActionData) => {
		const tempOrderProducts: OrderProductRequest[] = [];
		const request = { ...data.baseRequest };
		if (data.subOrders) {
			data.subOrders.map((so) => {
				so.content?.map((op) => {
					if (op.orderQty === undefined || op.orderQty === null || op.orderQty === 0) {
						return;
					}
					const existingProduct = tempOrderProducts.find((top) => top.variantId === op.variantId);
					if (existingProduct && existingProduct.orderQty !== undefined) {
						existingProduct.orderQty = existingProduct.orderQty + op.orderQty;
						return;
					}
					// op.weight = op.pickedWeight;
					op.arrivedQty = op.orderQty;
					op.estimated = false;
					if (op.expiresAt) {
						op.expiresAt = moment(op.expiresAt).startOf('day').toDate();
					}
					tempOrderProducts.push(op);
				});
			});
			request.content = tempOrderProducts;
			// What we are doing here is to remove the suborders that are not going to be used
			// in the order, so we don't send them to the backend. This happens for example
			// when we are moving things internally or when a suborder is used as proxy
			const newChildren: OrderBaseRequest[] = [];
			data.subOrders.forEach((so, it) => {
				if (so.toId) {
					newChildren.push({ ...so, code: `${request.code}-${it}` });
				}
			});
			request.children = newChildren;
			request.shippingType = ShippingType.handDelivered;
			request.code = `manual_${Date.now().toString()}`;
			request.fromId = request.children[0].toId;
			request.toId = request.children[0].toId;
			request.fromType = OrderDirectionType.warehouse;
			request.toType = OrderDirectionType.warehouse;
		}

		const validatedData = OrderBaseCreateSchema.safeParse(request);
		if (!validatedData.success) {
			return Promise.reject('Invalid data');
		}

		const manualOrderResponse = (await data.dispatch(
			doCreateOrder(validatedData.data),
		)) as PayloadAction<OrderBaseResponse>;

		if (manualOrderResponse.type !== 'orders/create/fulfilled') {
			return Promise.reject('Manual order creation failed');
		}
		return manualOrderResponse.payload;
	},
};
