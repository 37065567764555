import { Image, Info, NoteAdd, Place } from '@mui/icons-material';
import { InfoTab } from './InfoTab';
import { NotesTab } from './NotesTab';
import { AddressTab } from './AddressTab';
import { PicturesTab } from './PicturesTab';

interface TabFabConfigElement {
	icon: JSX.Element;
	tooltip: string;
	editorTab: JSX.Element;
}

export enum FormSidefabType {
	Notes = 'notes',
	Info = 'info',
	Address = 'address',
	Image = 'image',
}

type TabFabConfig = { [key in FormSidefabType]: TabFabConfigElement };

export const tabFabConfig: TabFabConfig = {
	[FormSidefabType.Info]: {
		icon: <Info />,
		tooltip: 'common.info',
		editorTab: <InfoTab />,
	},
	[FormSidefabType.Notes]: {
		icon: <NoteAdd />,
		tooltip: 'appFunctions.note',
		editorTab: <NotesTab />,
	},
	[FormSidefabType.Address]: {
		icon: <Place />,
		tooltip: 'appFunctions.address',
		editorTab: <AddressTab />,
	},
	[FormSidefabType.Image]: {
		icon: <Image />,
		tooltip: 'appFunctions.image',
		editorTab: <PicturesTab />,
	},
};
