import { Theme, alpha, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
	interface PaletteOptions {
		lightWarning?: PaletteOptions['primary'];
		gradient?: {
			[key: string]: string | undefined; // set each key to be optional
		};
	}

	interface Palette {
		lightWarning: Palette['primary'];
		gradient: {
			primary: string;
			[key: string]: string;
		};
	}

	interface PaletteColor {
		ultraLight?: string;
	}

	interface SimplePaletteColorOptions {
		ultraLight?: string;
	}
}

declare module '@mui/material/Chip' {
	interface ChipPropsColorOverrides {
		lightWarning: true;
	}
}

declare module '@mui/material/SvgIcon' {
	interface SvgIconPropsColorOverrides {
		lightWarning: true;
	}
}

type ThemeMap = {
	[key in ThemeNames]: Theme;
};

export enum ThemeNames {
	light = 'light',
	dark = 'dark',
}

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#101b60',
		},
		secondary: {
			main: '#9bdcff',
		},
		background: {
			default: '#dbddef',
		},
		divider: '#9fa8da',
	},
	transitions: {
		duration: {
			leavingScreen: 100,
			enteringScreen: 250,
		},
	},
	typography: {
		fontSize: 14,
	},
});

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#000841',
		},
		secondary: {
			main: '#f44336',
		},
		error: {
			light: '#f6685e',
			main: '#f44336',
			dark: '#aa2e25',
			contrastText: '#ffffff',
			ultraLight: alpha('#f44336', 0.1),
		},
		lightWarning: {
			light: '#f6685e',
			main: '#f44336',
			dark: '#aa2e25',
			contrastText: '#ffffff',
			ultraLight: alpha('#f44336', 0.1),
		},
		background: {
			default: '#ffffff',
		},
		divider: '#9fa8da',
		gradient: {
			primary:
				'linear-gradient(58deg, rgba(34,89,107,1) 0%, rgba(93,37,110,1) 50%, rgba(0,8,65,1) 100%)',
			primaryAlpha:
				'linear-gradient(58deg, rgba(34,89,107,0.1) 0%, rgba(93,37,110,0.1) 50%, rgba(0,8,65,0.1) 100%)',
			secondary: 'linear-gradient(180deg, rgba(223, 224, 242, 1) 0%, rgba(207, 239, 255, 1) 100%)',
			secondaryAlpha:
				'linear-gradient(180deg, rgba(223, 224, 242, 0.4) 0%, rgba(207, 239, 255, 0.4) 100%)',
		},
	},
	transitions: {
		duration: {
			leavingScreen: 100,
			enteringScreen: 250,
		},
	},
	typography: {
		fontSize: 14,
	},
});

export const themes: ThemeMap = {
	light: lightTheme,
	dark: darkTheme,
};
