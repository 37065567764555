import {
	OrderBaseChildRequest,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderProductResponse,
	OrderType,
	WarehouseInventoriesResponse,
} from 'common';
import { config as inboundOrderConfig } from './inboundOrderConfig';
import { config as internalOrderConfig } from './internalOrderConfig';
import { config as outboundOrderConfig } from './outboundOrderConfig';
import { config as cutOrderConfig } from './processingOrderConfig';
import { config as splitOrderConfig } from './splitOrderConfig';
import { config as manualOrderConfig } from './manualOrderConfig';
import { config as modifyOrderConfig } from './modifyOrderConfig';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ControllerRenderProps } from 'react-hook-form';

/**
 * EnhancedOrderBaseChildRequest is a type that extends {@link OrderBaseChildRequest}
 * It is used to enhance the OrderBaseChildRequest with additional properties used only
 * internally in this context and its children. At api call time all the additional properties
 * are ignored.
 * @interface EnhancedOrderBaseChildRequest
 * @extends {OrderBaseChildRequest}
 * @property {number} through - the through property is used in Cut orders to keep track of the
 * warehouse in which the cut operation is processed.
 */
export interface EnhancedOrderBaseChildRequest extends OrderBaseChildRequest {
	through?: number;
}

export enum OrderElement {
	code = 'code',
	fromType = 'fromType',
	toType = 'toType',
	toSubType = 'toSubType',
	toWarehouseType = 'toWarehouseType',
	shippingType = 'shippingType',
	subOrderTypes = 'subOrderTypes',
}

export enum SubOrderElement {
	borderBottom = 'borderBottom',
	maxWidth = 'maxWidth',
}

export type OrderElementConfig = {
	[key in OrderElement]: OrderElementConfigItem;
};

export interface SubOrderRowProps {
	subOrderCode: string;
}

export interface InfoElementProps {
	uuid: string;
}

export interface SubOrderProductsContainerProps {
	subOrder: EnhancedOrderBaseChildRequest | undefined;
}

export interface ModifySubOrderProductsContainerProps {
	subOrder: OrderBaseChildRequest;
	field: ControllerRenderProps<OrderBaseResponse, 'children'>;
}

export type SubOrderElementConfig = {
	[key in SubOrderElement]: OrderElementConfigItem;
};

/**
 * OrderApiActionData is used to pass the necessary data to the executeOrderAction function
 * @interface OrderApiActionData
 * @property {ThunkDispatch<unknown, unknown, Action<unknown>>} dispatch - the injectet dispatch function
 * @property {OrderBaseRequest} baseRequest - the base request object
 * @property {EnhancedOrderBaseChildRequest[]} [subOrders] - the suborders array
 * @property {{ [key: number]: WarehouseInventoriesResponse }} [inventories] - the inventories object
 */
export interface OrderApiActionData {
	dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>;
	baseRequest: OrderBaseRequest;
	subOrders?: EnhancedOrderBaseChildRequest[];
	inventories?: { [key: number]: WarehouseInventoriesResponse };
	sourceOrderProduct?: OrderProductResponse | null;
	sources?: Record<number, OrderProductResponse>;
}

export interface OrderTypeConfig {
	headerElement?: React.FC;
	infoElements?: React.FC<InfoElementProps>[];
	headerElements: OrderElementConfig;
	maxSubOrders: number | 'unlimited';
	subOrderElements?: SubOrderElementConfig;
	subOrderRow: React.FC<SubOrderRowProps>;
	subOrderProductsContainer?: React.FC<SubOrderProductsContainerProps>;
	modifySubOrderProductsContainer?: React.FC<ModifySubOrderProductsContainerProps>;
	isAddSuborderDisabled: (
		order: OrderBaseRequest,
		subOrders: EnhancedOrderBaseChildRequest[],
	) => boolean;
	// TODO: add this
	// isAddProductToSuborderDisabled: (order: OrderBaseRequest, subOrder: EnhancedOrderBaseChildRequest) => boolean;
	// TODO: do we need to pass the order here? (spoiler: yes)
	isCreateOrderDisabled: (subOrders: EnhancedOrderBaseChildRequest[]) => boolean;
	executeOrderAction?: (data: OrderApiActionData) => Promise<OrderBaseResponse>;
}

export type OrderTypeConfigMap = { [key in OrderType]: OrderTypeConfig };

export const orderConfigMap: OrderTypeConfigMap = {
	Inbound: inboundOrderConfig,
	Outbound: outboundOrderConfig,
	Internal: internalOrderConfig,
	Processing: cutOrderConfig,
	Modify: modifyOrderConfig,
	Split: splitOrderConfig,
	Manual: manualOrderConfig,
	Delete: manualOrderConfig,
};
