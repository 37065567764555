import { useOrderEditor } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useMerchants from '@hooks/useMerchants';
import useWarehouses from '@hooks/useWarehouses';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { AppFunction, MerchantType, ShippingType, StorageType, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InboundOrderTimeScheduler } from '../timescheduler/InboundOrderTimeScheduler';

const shippingValues = [ShippingType.notdefined, ShippingType.air, ShippingType.sea];

export const InboundOrderHeader = () => {
	const { control, getValues, renderType } = useOrderEditor();

	const { merchantList } = useMerchants();
	const { warehouseList } = useWarehouses();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const fromList = () => {
		return merchantList
			.filter((m) => m.type === MerchantType.both || m.type === MerchantType.seller)
			.map((value, it) => (
				<MenuItem key={it} value={value.id}>
					{getTranslatedString(AppFunction.Merchant, value.id, TranslationTypes.name)}
				</MenuItem>
			));
	};

	const toList = () => {
		const shippingType = getValues('shippingType');
		switch (shippingType) {
			case ShippingType.air:
				return warehouseList
					.filter((w) => w.storageType === StorageType.airport)
					.map((value, it) => (
						<MenuItem key={it} value={value.id}>
							{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
						</MenuItem>
					));
			case ShippingType.sea:
				return warehouseList
					.filter((w) => w.storageType === StorageType.port)
					.map((value, it) => (
						<MenuItem key={it} value={value.id}>
							{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
						</MenuItem>
					));
			default:
				return [];
		}
	};

	return (
		<Grid item container columnSpacing={2} rowGap={2}>
			<Grid item xs={3}>
				<Controller
					name='code'
					control={control}
					render={({ field }) => (
						<TextField
							variant='outlined'
							size='small'
							label={t('order.code')}
							value={field.value}
							fullWidth
							onChange={(event) => field.onChange(event.target.value)}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<Controller
					name='fromId'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='from'>{t('order.from.merchant')}</InputLabel>
							<Select
								labelId='from'
								id='from'
								size='small'
								label={t('order.from.merchant')}
								value={field.value ?? -1}
								fullWidth
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? undefined : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.from.undefined')}
								</MenuItem>
								{fromList()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<Controller
					name='shippingType'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='ship-by'>{t('order.shipBy')}</InputLabel>
							<Select
								labelId='ship-by'
								id='ship-by'
								size='small'
								label={t('order.shipBy')}
								value={field.value ?? -1}
								disabled={false}
								onChange={(event) => {
									field.onChange(
										event.target.value === '-1'
											? ShippingType.notdefined
											: (event.target.value as ShippingType),
									);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
							>
								{shippingValues?.map((value, it) => (
									<MenuItem key={it} value={value}>
										{t(`order.shippingTypes.${value.toLowerCase()}`)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<Controller
					name='toId'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='through-label'>{t('order.target.warehouse')}</InputLabel>
							<Select
								labelId='through-label'
								size='small'
								id='through'
								label={t('order.target.warehouse')}
								value={field.value ?? -1}
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? null : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.target.undefined')}
								</MenuItem>
								{toList()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			<InboundOrderTimeScheduler />
		</Grid>
	);
};
