import { GeneralInfo } from '@components/forms/users/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import { UserFormContextProvider } from '@contexts/formContext/subContexts/UserFormContext';
import useUsers from '@hooks/useUsers';
import { Settings } from '@mui/icons-material';
import { AppFunction, UserEditRequest, UserEditRequestSchema } from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

export const UserForm = ({ forcedId, formAction }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { userList, createUser, editUser } = useUsers();

	const [formValues, setFormValues] = useState<UserEditRequest>({} as UserEditRequest);
	const [id, setId] = useState<number>(-1);

	const setValues = (newId: number) => {
		const user = userList.find((c) => c.id === newId);
		if (user) {
			const correctedUser = { ...user, password: '', password_repeat: '' };
			const parsedUser = UserEditRequestSchema.safeParse(correctedUser);
			if (parsedUser.success) {
				setFormValues({ ...parsedUser.data, newPassword: undefined });
			} else {
				console.error('UserEditRequestSchema error', parsedUser.error);
			}
		}
	};

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (id !== routeId && routeId !== -1) {
			setValues(id);
		}
	}, [id]);

	useEffect(() => {
		if (forcedId) {
			setId(forcedId);
			setValues(forcedId);
		}
	}, [forcedId]);

	useEffect(() => {
		const newValues = userList.find((c) => c.id === id);
		const parsedNewValues = UserEditRequestSchema.safeParse(newValues);
		if (!parsedNewValues.success) {
			return;
		}
		if (parsedNewValues.data && !_.isEqual(parsedNewValues.data, formValues)) {
			setFormValues(parsedNewValues.data);
		}
	}, [userList]);

	return (
		<FormContextProvider
			appFunction={AppFunction.User}
			createFunction={createUser}
			editFunction={editUser}
			renderType={forcedId ? FormRenderType.popup : FormRenderType.standalone}
			entityId={forcedId ?? routeId}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			formComponent={UserFormContextProvider}
			validationSchema={UserEditRequestSchema}
			defaultValues={{ id: -1 } as UserEditRequest}
			hideMenu
			useFab
			sideFabs={[FormSidefabType.Image, FormSidefabType.Address]}
			customAfterCreateLink='user/:id/edit'
		>
			<TabbedElement title='user.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
		</FormContextProvider>
	);
};
