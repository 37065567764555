import { useMemo, useRef } from 'react';
import { DeleteItemDialog } from '../DeleteItemDialog';
import useFeedbacks from '../../hooks/useFeedbacks';

export default function DeletionDialogHandler() {
	const { deletionDialog, removeDeletionDialog } = useFeedbacks();
	const testRef = useRef(false);

	const dialog = useMemo(() => {
		if (deletionDialog) {
			return (
				<DeleteItemDialog
					dialogOpen={true}
					onAccept={() => {
						testRef.current = true;
						removeDeletionDialog(true);
					}}
					onClose={() => {
						if (testRef.current !== true) removeDeletionDialog(false);
					}}
					title={deletionDialog.title}
					text={deletionDialog.message}
					confirmButtonText={deletionDialog.confirmButtonText}
					cancelButtonText={deletionDialog.cancelButtonText}
				/>
			);
		} else {
			return <></>;
		}
	}, [deletionDialog]);

	return dialog;
}
