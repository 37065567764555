import AddIcon from '@mui/icons-material/Add';
import { Button, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { checkWritePermission } from '../permissions';
import { AppFunction } from 'common';

const fabStyle = {
	position: 'fixed',
	bottom: 16,
	right: 16,
} as React.CSSProperties;

export enum ButtonType {
	FAB = 'fab',
	FULLWIDTH = 'fullWidth',
}

export interface GuardedCreateButtonProps {
	targetUrl: string;
	customAction?: () => void;
	type?: ButtonType;
	permission: AppFunction;
	init?: boolean;
}

export const GuardedCreateButton = (props: GuardedCreateButtonProps) => {
	const navigate = useNavigate();
	const userRole = useAppSelector((state) => state.roles.userRole);

	if (!userRole) return <></>;
	if (!checkWritePermission(userRole, props.permission)) {
		return <></>;
	} else {
		if (props.type) {
			switch (props.type) {
				case ButtonType.FULLWIDTH:
					return (
						<Button fullWidth aria-label='add' onClick={() => navigate(props.targetUrl)}>
							<AddIcon />
						</Button>
					);

				case ButtonType.FAB:
					return (
						<Fab
							color='primary'
							aria-label='add'
							style={fabStyle}
							onClick={() =>
								props.customAction ? props.customAction?.() : navigate(props.targetUrl)
							}
							sx={{
								transform: `${
									props.init === undefined ? 'scale(1)' : props.init ? 'scale(1)' : 'scale(0)'
								}`,
								transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
								'&:hover': {
									transform: 'scale(1.1)',
								},
							}}
						>
							<AddIcon />
						</Fab>
					);
			}
		} else {
			return (
				<Fab
					color='primary'
					aria-label='add'
					style={fabStyle}
					onClick={() => navigate(props.targetUrl)}
					sx={{
						transform: 'scale(1) rotate(0)',
						'&:hover': {
							transform: 'scale(1.05)',
						},
					}}
				>
					<AddIcon />
				</Fab>
			);
		}
	}
};
