import { PayloadAction } from '@reduxjs/toolkit';
import { doSetTaskValues } from '../../../store/tasks';
import { TaskApiActionData, TaskFieldConfig, parseValuesToString } from '../taskFieldConfig';
import { TaskFieldValueResponse, TaskFieldValuesResponse } from 'common';
import { z } from 'zod';

const ChecknoxFieldValueSchema = z.object({
	checked: z.boolean(),
});
const CheckboxFieldValuesSchema = z.array(ChecknoxFieldValueSchema);

export type CheckboxFieldValue = z.infer<typeof ChecknoxFieldValueSchema>;
export type CheckboxFieldValues = z.infer<typeof CheckboxFieldValuesSchema>;

async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(CheckboxFieldValuesSchema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
}

export const checkboxFieldConfig: TaskFieldConfig = {
	stringToValues: (value: string) => [value === 'true'],
	valuesToString: (values: unknown[]) => parseValuesToString(CheckboxFieldValuesSchema, values),
	validateStructure: (values: unknown[]) => {
		const parsed = CheckboxFieldValuesSchema.safeParse(values);
		if (parsed.success) {
			return parsed.data.every((value) => value.checked === true);
		} else {
			return false;
		}
	},
	executeApiAction: async (data: TaskApiActionData): Promise<void> => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
	saveStub: async (data: TaskApiActionData): Promise<void> => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
};
