import useAppFunctions from '@hooks/useAppFunctions';
import useTasks from '@hooks/useTasks';
import { ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material';
import { AppFunction, TaskResponse } from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderButton } from '../shared/OrderButton';

interface Props {
	task: TaskResponse;
	it: number;
}

export const MiniTask = ({ task, it }: Props) => {
	const { getOrderFromTask, taskFieldArchetypeList } = useTasks();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const [icons, setIcons] = useState<JSX.Element[]>([]);

	useEffect(() => {
		const tempIcons: JSX.Element[] = [];
		task.archetype?.taskFieldArchetypeId.map((id) => {
			const field = taskFieldArchetypeList.find((field) => field.id === id);
			if (field) {
				const icon = getIcon(AppFunction.TaskFieldArchetype, field.type);
				if (icon) {
					tempIcons.push(
						<Tooltip key={id} title={t(`${AppFunction.TaskFieldArchetype}.types.${field.type}`)}>
							{icon}
						</Tooltip>,
					);
				}
			}
		});
		setIcons(tempIcons);
	}, [taskFieldArchetypeList, task]);

	const order = getOrderFromTask(task);

	return (
		<ListItem
			key={task.id}
			sx={{
				backgroundColor: 'white',
				borderRadius: '1rem',
				marginTop: it !== 0 ? '1rem' : 0,
			}}
		>
			<ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>{icons}</ListItemAvatar>
			{/* <ListItemText sx={{ width: '100%' }} primary={task.title} /> */}
			{order && <OrderButton orderId={order.id} />}
		</ListItem>
	);
};
