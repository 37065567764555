import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import useProducts from '@hooks/useProducts';
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	List,
	ListItem,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmPricesItem } from './ConfirmPricesItem';
import {
	ConfirmPricesFieldHeader,
	ConfirmPricesFieldItem,
	ConfirmPricesFieldValue,
} from '@contexts/taskContext/config/confirmpricesFieldConfig';
import { debounce } from 'lodash';
import { ConfirmPricesOthers } from './ConfirmPricesOthers';
import { WeightSliders } from './WeightSliders';

interface Props {
	taskFieldId: number;
}

export const ConfirmPricesField = ({ taskFieldId }: Props) => {
	const { cachedValues, setTaskFieldValues, getTaskFieldValues, targetId } = useTask();
	const { currencies } = useProducts();

	const { orderList } = useOrders();
	const { variantList } = useProducts();

	const [init, setInit] = useState(false);
	const [weights, setWeights] = useState({ size: 0.33, weight: 0.33, cost: 0.34 });
	const [values, setValues] = useState<{ [key: number]: ConfirmPricesFieldItem }>({});
	const [headerValues, setHeaderValues] = useState<ConfirmPricesFieldHeader>({
		duties: { value: 0, currency: 1 },
		others: [],
		finalPriceCurrency: 1,
		exchangeRate: 1,
	});

	const order = orderList.find((order) => order.id === targetId);

	function findRootOrderFromId(orderId: number): number | null {
		const foundOrder = orderList.find((o) => o.id === orderId);
		if (!foundOrder) {
			return null;
		}
		if (!foundOrder.parentId) {
			return foundOrder.fromId ?? null;
		}
		return findRootOrderFromId(foundOrder.parentId);
	}
	const merchantId = findRootOrderFromId(targetId);

	useEffect(() => {
		if (order) {
			const newValues: { [key: number]: ConfirmPricesFieldItem } = {};

			order.content
				?.filter((c) => c.variantId !== undefined && c.variantId !== null)
				.forEach((content) => {
					if (!content.variantId) return;
					let value: ConfirmPricesFieldItem = {
						variantId: content.variantId,
						basePrice: -1,
						duties: 0,
						freight: 0,
						finalPrice: 0,
					};
					if (values[content.variantId] !== undefined) {
						value = { ...value, ...values[content.variantId] };
					}
					newValues[content.variantId ?? -1] = value;
				});

			setValues(newValues);
		}
	}, [order, taskFieldId]);

	useEffect(() => {
		const stubData = getTaskFieldValues<ConfirmPricesFieldValue>(taskFieldId);

		if (stubData.length > 0 && !init) {
			setInit(true);
			console.log(stubData);
			if (stubData.length > 0) {
				setHeaderValues(stubData[0]);
			}
			if (stubData[0].values.length > 0) {
				const newValues: { [key: number]: ConfirmPricesFieldItem } = {};
				stubData[0].values.forEach((value) => {
					newValues[value.variantId] = {
						variantId: value.variantId,
						basePrice: value.basePrice,
						duties: value.duties,
						freight: value.freight,
						finalPrice: value.finalPrice,
					};
				});
				setValues(newValues);
			}
		}
	}, [cachedValues]);

	const debouncedSetTaskFieldValues = useCallback(
		debounce((fieldId, fieldValues) => {
			setTaskFieldValues(fieldId, fieldValues);
		}, 600),
		[setTaskFieldValues],
	);

	useEffect(() => {
		const newValues = Object.entries(values).map(([key, value]) => {
			return {
				variantId: parseInt(key),
				basePrice: value.basePrice,
				duties: value.duties,
				freight: value.freight,
				finalPrice: value.finalPrice,
			};
		});
		debouncedSetTaskFieldValues(taskFieldId, [{ ...headerValues, values: newValues }]);
	}, [taskFieldId, headerValues, values]);

	const updateProductValue = (variantId: number, value: ConfirmPricesFieldItem) => {
		setValues((prevValues) => {
			const newValues = { ...prevValues, [variantId]: { ...value } };
			return newValues;
		});
	};

	const calculateTransportFees = (mode: 'transport' | 'duty' | 'both') => {
		if (!order?.content) return;

		const feesTotal = headerValues.others.reduce((acc, curr) => {
			return acc + curr.value;
		}, 0);
		const dutyTotal =
			headerValues.duties?.currency !== headerValues.finalPriceCurrency
				? headerValues.duties?.value * headerValues.exchangeRate
				: headerValues.duties?.value;

		const items = order.content.map((content) => {
			const variant = variantList.find((v) => v.id === content.variantId);
			let volume = 0;
			if (variant?.sizePerBox) {
				const numbers = JSON.parse(variant.sizePerBox) as number[];
				const product = numbers.reduce((acc, current) => acc * current, 1);
				volume = product;
			}
			const item = values[content.variantId ?? -1];
			const buyingPrice = variant?.merchantBuyingPrices?.find((p) => p.merchantId === merchantId);
			let variantCost = 0;
			if (buyingPrice) {
				variantCost =
					(buyingPrice.currencyId !== headerValues.finalPriceCurrency
						? buyingPrice.price * headerValues.exchangeRate
						: buyingPrice.price) * (content?.orderQty ?? 1);
			}
			return { ...item, space: volume, weight: variant?.weight ?? 0, cost: variantCost };
		});

		const totalSpace = items.reduce((acc, item) => acc + item.space, 0);
		const totalWeight = items.reduce((acc, item) => acc + item.weight, 0);
		const totalCost = items.reduce((acc, item) => acc + item.cost, 0);

		const weightedShares = items.map((item) => {
			const propSpace = item.space / totalSpace;
			const propWeight = item.weight / totalWeight;
			const propCost = item.cost / totalCost;

			const share =
				propSpace * weights.size + propWeight * weights.weight + propCost * weights.cost;
			return share;
		});

		const totalShare = weightedShares.reduce((acc, share) => acc + share, 0);

		const weightedItems = items.map((item, index) => {
			const additionalTransportCost = (weightedShares[index] / totalShare) * feesTotal;
			const additionalDutyCost = (weightedShares[index] / totalShare) * dutyTotal;
			return {
				...item,
				additionalCost: additionalTransportCost,
				additionalDuty: additionalDutyCost,
			};
		});

		let newValues = { ...values };
		weightedItems.forEach((item) => {
			if (mode === 'transport' || mode === 'both') {
				newValues = {
					...newValues,
					[item.variantId]: {
						...newValues[item.variantId],
						freight: Math.ceil(item.additionalCost),
					},
				};
			}
			if (mode === 'duty' || mode === 'both') {
				newValues = {
					...newValues,
					[item.variantId]: {
						...newValues[item.variantId],
						duties: Math.ceil(item.additionalDuty),
					},
				};
			}
		});

		setValues(newValues);
	};

	if (!order) return null;
	return (
		<div style={{ padding: '1rem' }}>
			<Grid container spacing={2}>
				<Grid item xs={10}>
					<TextField
						id='dutyFees'
						label='Total Duty Fees'
						type='number'
						fullWidth
						size='small'
						InputProps={{ inputProps: { min: 0 } }}
						value={headerValues.duties?.value ?? 0}
						onChange={(e) => {
							const newValue = parseFloat(e.target.value);
							const parsed = !isNaN(newValue) ? newValue : 0;
							setHeaderValues((oldValues) => {
								return {
									...oldValues,
									duties: {
										value: parsed,
										currency: oldValues.duties?.currency ?? 1,
									},
								};
							});
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<FormControl fullWidth size='small'>
						<InputLabel id='duties-currency-label'>Currency</InputLabel>
						<Select
							labelId='duties-currency-label'
							id='duties-currency'
							label='Currency'
							value={headerValues.duties?.currency ?? 1}
							onChange={(event) => {
								const newValues = { ...headerValues };
								newValues.duties = {
									value: headerValues.duties?.value ?? 0,
									currency: event.target.value as number,
								};
								setHeaderValues(newValues);
							}}
							MenuProps={{ style: { zIndex: 9999 } }}
						>
							{currencies.length > 0 &&
								currencies.map((currency) => (
									<MenuItem key={currency.id} value={currency.id}>
										{currency.currency}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<ConfirmPricesOthers
						values={headerValues.others}
						setValues={(values) =>
							setHeaderValues((prevValues) => {
								return { ...prevValues, others: values };
							})
						}
					/>
				</Grid>
				<WeightSliders weights={weights} setWeights={setWeights} />
				<Grid item xs={4}>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={() => calculateTransportFees('duty')}
					>
						Calculate Prices (duties)
					</Button>
				</Grid>
				<Grid item xs={4}>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={() => calculateTransportFees('transport')}
					>
						Calculate Prices (transport)
					</Button>
				</Grid>
				<Grid item xs={4}>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						onClick={() => calculateTransportFees('both')}
					>
						Calculate Prices (both)
					</Button>
				</Grid>
			</Grid>
			<List>
				<ListItem
					sx={{
						border: '1px solid #e0e0e0',
						borderRadius: '5px',
						padding: '1rem 0.5rem 0.5rem 0.5rem',
						'&:hover': {
							backgroundColor: '#f5f5f5',
						},
						marginBlock: '0.5rem',
					}}
				>
					<div style={{ width: '3rem' }} />
					<Grid container spacing={2}>
						<Grid item xs={2} />
						<Grid item xs={10} container spacing={2}>
							<Grid item xs={1} />
							<Grid item xs={2}>
								<TextField
									id='exchangeRate'
									label='Exchange Rate'
									type='number'
									fullWidth
									size='small'
									InputProps={{ inputProps: { min: 0 } }}
									value={headerValues.exchangeRate}
									onChange={(e) => {
										const newValue = parseFloat(e.target.value);
										const parsed = !isNaN(newValue) ? newValue : 0;
										setHeaderValues((oldValues) => {
											return {
												...oldValues,
												exchangeRate: parsed,
											};
										});
									}}
								/>
							</Grid>
							<Grid item xs={3} />
							<Grid item xs={3} />
							<Grid item xs={3}>
								<FormControl fullWidth size='small'>
									<InputLabel id='final-price-currency-label'>Currency</InputLabel>
									<Select
										labelId='final-price-currency-label'
										id='final-price-currency'
										label='Currency'
										value={headerValues.finalPriceCurrency}
										onChange={(event) => {
											const newValues = { ...headerValues };
											newValues.finalPriceCurrency = event.target.value as number;
											setHeaderValues(newValues);
										}}
										MenuProps={{ style: { zIndex: 9999 } }}
									>
										{currencies.length > 0 &&
											currencies.map((currency) => (
												<MenuItem key={currency.id} value={currency.id}>
													{currency.currency}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</ListItem>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content, it) => {
						const variant = variantList.find((v) => v.id === content.variantId);
						if (variant && values[content.variantId ?? -1] !== undefined && order.fromId) {
							return (
								<ConfirmPricesItem
									key={it}
									variant={variant}
									content={content}
									merchantId={merchantId ?? -1}
									value={values[content.variantId ?? -1]}
									exchangeRate={headerValues.exchangeRate}
									currency={headerValues.finalPriceCurrency}
									updateField={updateProductValue}
								/>
							);
						}
					})}
			</List>
		</div>
	);
};
