import { IconButton, alpha, useTheme } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import commonStyles from '../../styles/Common.module.css';

interface Props {
	canEdit?: boolean;
	disabled?: boolean;
	showEditButton?: boolean;
	onSubmit: () => void;
}

export const TabFooterBody = (props: Props) => {
	const theme = useTheme();

	if (props.canEdit) {
		return (
			<div className={commonStyles.tabFooterButtonContainer}>
				{props.showEditButton && (
					<IconButton
						size='medium'
						type='submit'
						sx={{
							// transform: `scale(${
							// 	formState === 'pending' || !dirty ? '0.9, 0.9' : '1.1, 1.1'
							// })`,
							transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							'&:hover': {
								backgroundColor: alpha(theme.palette.primary.main, 0.5),
							},
						}}
						disabled={props.disabled}
						onClick={(e) => {
							e.stopPropagation();
							props.onSubmit();
						}}
					>
						<SaveIcon />
					</IconButton>
				)}
			</div>
		);
	}
	return <></>;
};
