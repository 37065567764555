import { CompanyChildrenSelector } from '@components/CompanyChildrenSelector';
import { EditShowGridField } from '@components/common/EditShowGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';
import useAddresses from '@hooks/useAddresses';
import useCustomerTypes from '@hooks/useCustomerTypes';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import { Autocomplete, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { AppFunction, CustomerResponse, TranslationTypes } from 'common';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CompanyStructure = () => {
	const { entityId, control, watch, formAction, getValues } = useGenericForm<CustomerResponse>();
	const { setCachedParent, setNewParentId } = useCustomerForm();

	const { renderI18NField, getI18NString } = useLocale();
	const { getAddressesByTypeId } = useAddresses();
	const { t } = useTranslation();
	const { customerTypesList } = useCustomerTypes();
	const { customerList } = useCustomers();

	const canEdit = formAction !== 'view';
	const typeId = watch('typeId');

	const onParentValueChange = (field: FieldValues['parentId'], newValue: number) => {
		let showWarning = false;
		if (field.value) {
			const addresses = getAddressesByTypeId(AppFunction.Customer, field.value);

			addresses.map((address) => {
				if (
					address.id &&
					[
						getValues('fiscalAddressId'),
						getValues('physicalAddressId'),
						getValues('deliveryAddressId'),
					].includes(address.id)
				) {
					showWarning = true;
				}
			});
		}

		if (!showWarning) {
			const parent = customerList.find((c) => c.id === newValue);
			setCachedParent?.(parent ?? null);
			field.onChange(newValue !== -1 ? newValue : null);
		} else {
			setNewParentId?.(newValue);
		}
	};

	const elements: JSX.Element[] = [];
	if (typeId) {
		const customerType = customerTypesList.find((ct) => ct.id === typeId);
		if (customerType?.canHaveParent) {
			elements.push(
				<Controller
					key='parentSelection'
					name='parentId'
					control={control}
					render={({ field }) => (
						<EditShowGridField
							isEditing={canEdit}
							width={12}
							label
							noTranslation
							forceLabel={t('customer.parentCompany')}
							body={(data) => {
								const parent = customerList.find((c) => c.id === +data);
								if (parent) {
									return (
										<div style={{ display: 'inline-flex' }}>
											<div style={{ marginRight: '1rem' }}>{parent.customerCode}</div>
											{renderI18NField(
												getI18NString(AppFunction.Customer, parent.id ?? -1, TranslationTypes.name),
											)}
										</div>
									);
								}
								return <></>;
							}}
						>
							<Autocomplete
								id='parentCompany'
								options={
									customerList?.filter((customer) => {
										const type = customerTypesList.find((ct) => ct.id === customer.typeId);
										if (type && type.canHaveChildren && customer.id) {
											return (
												customer.id !== entityId &&
												customer.id != undefined &&
												customer.parentId == undefined
											);
										}
										return false;
									}) ?? []
								}
								getOptionLabel={(option) =>
									option.customerCode.replace('-', '') +
									' ' +
									(option.id != undefined && option.id !== -1
										? t(`${AppFunction.Customer}-${option.id}-${TranslationTypes.name}`, {
												ns: 'locale',
										  })
										: '')
								}
								value={
									field.value != undefined
										? customerList.find((customer) => customer.id === field.value)
										: null
								}
								onChange={(_, newValue) => {
									const valueToSet = newValue?.id != undefined ? newValue.id : -1;
									onParentValueChange(field, valueToSet ?? -1);
								}}
								isOptionEqualToValue={(option, value) => {
									if (value === null || value === undefined) {
										return option.id === -1;
									}
									return option.id === value.id;
								}}
								renderInput={(params) => {
									const inputValue = params.inputProps.value;
									const selectedOption = customerList.find(
										(customer) => customer.customerCode === inputValue,
									);

									return (
										<TextField
											{...params}
											label={t('customer.parentCompany')}
											value={
												selectedOption ? (
													<div style={{ display: 'flex' }}>
														<div style={{ display: 'inline-flex', marginRight: '1rem' }}>
															{selectedOption.customerCode}
														</div>
														<div style={{ display: 'inline-flex' }}>
															{selectedOption?.id ? (
																renderI18NField(
																	getI18NString(
																		AppFunction.Customer,
																		selectedOption?.id ?? -1,
																		TranslationTypes.name,
																	),
																)
															) : (
																<></>
															)}
														</div>
													</div>
												) : (
													inputValue
												)
											}
										/>
									);
								}}
							/>
						</EditShowGridField>
					)}
				/>,
			);
			elements.push(
				<Controller
					key='separateFromParent'
					name='separateData'
					control={control}
					render={({ field }) => (
						<EditShowGridField width={12} isEditing={canEdit} label>
							<FormControlLabel
								control={
									<Switch
										{...field}
										value={field.value ?? false}
										checked={field.value ?? false}
										onChange={(ev) => field.onChange(ev.target.checked)}
									/>
								}
								label={t('customer.useSeparateData')}
							/>
						</EditShowGridField>
					)}
				/>,
			);
		}
		if (customerType?.canHaveChildren) {
			elements.push(
				<Grid item xs={12} key='childSelection'>
					<CompanyChildrenSelector clientId={entityId} />
				</Grid>,
			);
			elements.push(
				<Controller
					key='sendDetails'
					name='sendDetails'
					control={control}
					render={({ field }) => (
						<EditShowGridField width={12} isEditing={canEdit} label>
							<FormControlLabel
								control={
									<Switch
										{...field}
										value={field.value ?? false}
										checked={field.value ?? false}
										onChange={(ev) => field.onChange(ev.target.checked)}
									/>
								}
								label={t('customer.sendDetails')}
							/>
						</EditShowGridField>
					)}
				/>,
			);
		}
	}
	return <>{elements}</>;
};
