import { Checkbox } from '@mui/material';
import { useTask } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import { AppFunction, TranslationTypes } from 'common';
import { CheckboxFieldValue } from '@contexts/taskContext/config/checkboxFieldConfig';
import { useEffect } from 'react';

interface Props {
	taskFieldId: number;
}

export const CheckboxField = ({ taskFieldId }: Props) => {
	const { cachedValues, getTaskFieldValues, setTaskFieldValues, taskState } = useTask();
	const { getTranslatedString } = useLocale();

	useEffect(() => {
		const stubData = getTaskFieldValues<CheckboxFieldValue>(taskFieldId);
		if (stubData.length > 0) {
			setTaskFieldValues(taskFieldId, [{ checked: stubData[0].checked }]);
		}
	}, [cachedValues]);

	// TODO: not optimal but it works for now
	const fieldValues = getTaskFieldValues<CheckboxFieldValue>(taskFieldId);

	return (
		<div style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
			<Checkbox
				disabled={taskState !== 'in_progress' && taskState !== 'stub'}
				checked={fieldValues.length > 0 ? fieldValues[0].checked : false}
				onChange={(event) => {
					setTaskFieldValues(taskFieldId, [{ checked: event.target.checked }]);
				}}
			/>
			<div>
				{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
			</div>
		</div>
	);
};
