import { useEffect, useState } from 'react';
import useCustomers from '../../hooks/useCustomers';

import { BranchType, TransactionType } from 'common';

import { Box } from '@mui/material';
import { CustomerBranch } from './CustomerBranch';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { Add } from '@mui/icons-material';
import { ScopedLayer } from '../scopedlayer/ScopedLayer';
import { FocusedElementType } from '../../store/app';
import { TabInnerTitle } from '../tabs/TabInnerTitle';

interface Props {
	customerId: number;
	branchTypeOptions?: BranchType[];
	view?: boolean;
}

export const CustomerBranches = ({ customerId, branchTypeOptions, view }: Props) => {
	const { customerList } = useCustomers();
	const [id, setId] = useState(customerId);
	const [expandedId, setExpandedId] = useState<number | null>(null);
	const canEdit = view ? !view : true;

	useEffect(() => {
		setId(customerId);
	}, [customerId]);

	const onStartBranchCreating = () => {
		setExpandedId(-1);
	};

	const branches = () => {
		if (id !== -1) {
			const customer = customerList.find((x) => x.id === id);
			if (!customer) return <></>;
			const branches: JSX.Element[] = [];
			customer.customerBranches?.map((x, i) => {
				branches.push(
					<CustomerBranch
						key={i}
						index={i}
						branchData={x}
						branchTypeOptions={branchTypeOptions}
						transactionTypeOptions={[TransactionType.canOrder]}
						onExpand={(id: number | null) => setExpandedId(id)}
						expandedId={expandedId}
						view={view}
					/>,
				);
			});
			if (expandedId === -1) {
				branches.push(
					<CustomerBranch
						key='create'
						index={branches.length}
						branchTypeOptions={[BranchType.subCustomer]}
						transactionTypeOptions={[TransactionType.canOrder]}
						branchData={{
							id: -1,
							customerId: customerId,
							type: BranchType.subCustomer,
							transactionType: TransactionType.canOrder,
						}}
						onExpand={() => setExpandedId(null)}
						expandedId={expandedId}
					/>,
				);
			}
			return <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>{branches}</Box>;
		} else {
			return <></>;
		}
	};

	return (
		<Box sx={{ position: 'relative' }}>
			<ScopedLayer scopes={[FocusedElementType.branch]} />
			<TabInnerTitle title='customer.directDelivery' translate />
			{branches()}
			{expandedId !== -1 && canEdit && (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<AnimatedIconButton
						onClick={onStartBranchCreating}
						colorType='info'
						icon={<Add />}
						text='operations.create'
						translate
					/>
				</div>
			)}
		</Box>
	);
};
