import { Collapse, Grid, MenuItem, Select, TextField, ToggleButton } from '@mui/material';
import { AppFunction, Offset } from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { KeyboardArrowRight } from '@mui/icons-material';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { useTranslation } from 'react-i18next';
import { ChainCompletionTimeChip } from '@components/common/chips/ChainCompletionTimeChip';

interface Props {
	value: Offset | null | undefined;
	isEditing: boolean;
	onChange: (value: Offset | null) => void;
	dontFold?: boolean;
}

const ParsedOffsetSchema = z.object({
	months: z.number().optional(),
	weeks: z.number().optional(),
	days: z.number().optional(),
	hours: z.number().optional(),
	minutes: z.number().optional(),
	seconds: z.number().optional(),
	weekDay: z.enum(['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']).optional(),
});

type ParsedOffset = z.infer<typeof ParsedOffsetSchema>;

const parseOffset = (value: Offset): ParsedOffset => {
	if (!value) {
		return {
			months: 0,
			weeks: 0,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			weekDay: undefined,
		};
	}
	const [offset, weekday] = value.split(',');

	const parseUnit = (str: string, unit: string) => {
		const match = str.match(new RegExp(`(\\d+)${unit}`));
		const value = match ? parseInt(match[1]) : undefined;
		return value && value !== 0 ? value : undefined;
	};

	return {
		months: parseUnit(offset, 'M'),
		weeks: parseUnit(offset, 'w'),
		days: parseUnit(offset, 'd'),
		hours: parseUnit(offset, 'h'),
		minutes: parseUnit(offset, 'm'),
		seconds: parseUnit(offset, 's'),
		weekDay: weekday as ParsedOffset['weekDay'],
	};
};

const formatOffset = (value: ParsedOffset): Offset => {
	const offset = [
		value.months ? `${value.months}M` : '',
		value.weeks ? `${value.weeks}w` : '',
		value.days ? `${value.days}d` : '',
		value.hours ? `${value.hours}h` : '',
		value.minutes ? `${value.minutes}m` : '',
		value.seconds ? `${value.seconds}s` : '',
	].join('');
	return value.weekDay ? `${offset},${value.weekDay}` : offset;
};

export const NextAssignableAtInput = ({ value, isEditing, onChange, dontFold }: Props) => {
	const [open, setOpen] = useState<boolean>(dontFold ?? false);
	const [useOffset, setUseOffset] = useState<boolean>(
		value !== null && value !== undefined && value !== '',
	);
	const [useWeekday, setUseWeekday] = useState<boolean>(false);
	const [parsedOffset, setParsedOffset] = useState<ParsedOffset>(
		value
			? parseOffset(value)
			: ({
					months: 0,
					weeks: 0,
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
					weekDay: undefined,
			  } as ParsedOffset),
	);

	const { t } = useTranslation();

	useEffect(() => {
		setUseOffset(value !== null && value !== undefined && value !== '');
		const newOffset = value ? parseOffset(value) : ({} as ParsedOffset);
		setUseWeekday(newOffset.weekDay !== undefined);
		setParsedOffset((oldOffset) => (_.isEqual(oldOffset, newOffset) ? oldOffset : newOffset));
	}, [value]);

	useEffect(() => {
		if (useOffset && value !== formatOffset(parsedOffset)) {
			const formattedValue = formatOffset(parsedOffset);
			onChange(formattedValue === '' ? null : formattedValue);
		}
	}, [parsedOffset, onChange]);

	useEffect(() => {
		setOpen(isEditing);
	}, [isEditing]);

	const toggleOffset = (use: boolean) => {
		const formattedValue = formatOffset(parsedOffset);
		const changedValue = use ? formattedValue : '';
		if ((use && formattedValue !== '') || !use) {
			onChange(changedValue === '' ? null : changedValue);
			setUseOffset(use);
		} else {
			setUseOffset(true);
		}
	};

	return (
		<>
			{!dontFold && (
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						cursor: 'pointer',
						userSelect: 'none',
					}}
					onClick={() => setOpen((state) => !state)}
				>
					<KeyboardArrowRight sx={{ transform: `rotate(${open ? 90 : 0}deg)` }} />
					<div>{t(`${AppFunction.TaskArchetype}.nextAssignableAt`)}</div>
					{!open && value !== '' && value !== null && (
						<ChainCompletionTimeChip
							timeString={value}
							sx={{
								marginLeft: '0.5rem',
								flexGrow: 1,
							}}
						/>
					)}
				</Grid>
			)}
			<Collapse in={open} sx={{ width: '100%', marginBottom: '0.5rem' }}>
				<Grid container spacing={2}>
					<InputGridField width={12} type={ComponentType.ToggleButton}>
						<ToggleButton
							value='check'
							selected={useOffset}
							onChange={() => {
								if (useOffset) {
									onChange(null);
								}
								return toggleOffset(!useOffset);
							}}
							disabled={!isEditing}
							fullWidth
							size='small'
						>
							{useOffset
								? t(`${AppFunction.TaskArchetype}.useOffset`)
								: t(`${AppFunction.TaskArchetype}.noOffset`)}
						</ToggleButton>
					</InputGridField>
					{useOffset && (
						<>
							<Grid item xs={12} container spacing={2}>
								<Grid item xs={4}>
									<TextField
										label={t('time.month', { count: parsedOffset.months ?? 0 })}
										size='small'
										value={parsedOffset.months ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												months: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>M</>,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('time.week', { count: parsedOffset.weeks ?? 0 })}
										size='small'
										value={parsedOffset.weeks ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												weeks: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>w</>,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('time.day', { count: parsedOffset.days ?? 0 })}
										size='small'
										value={parsedOffset.days ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												days: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>d</>,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('time.hour', { count: parsedOffset.hours ?? 0 })}
										size='small'
										value={parsedOffset.hours ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												hours: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>h</>,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('time.minute', { count: parsedOffset.minutes ?? 0 })}
										size='small'
										value={parsedOffset.minutes ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												minutes: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>m</>,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('time.second', { count: parsedOffset.seconds ?? 0 })}
										size='small'
										value={parsedOffset.seconds ?? 0}
										onChange={(event) =>
											setParsedOffset((prevState) => ({
												...prevState,
												seconds: parseInt(event.target.value) || undefined,
											}))
										}
										disabled={!isEditing}
										fullWidth
										InputProps={{
											type: 'number',
											inputProps: {
												min: 0,
												className: 'panExcluded',
											},
											endAdornment: <>s</>,
										}}
									/>
								</Grid>
							</Grid>
							<InputGridField width={12} type={ComponentType.ToggleButton}>
								<ToggleButton
									value='check'
									selected={useWeekday}
									onChange={() => {
										setParsedOffset((prevState) => ({
											...prevState,
											weekDay: useWeekday ? undefined : 'mo',
										}));
										setUseWeekday((state) => !state);
									}}
									disabled={
										!isEditing || parsedOffset.weeks === undefined || parsedOffset.weeks === 0
									}
									fullWidth
									size='small'
								>
									{useOffset
										? t(`${AppFunction.TaskArchetype}.useWeekday`)
										: t(`${AppFunction.TaskArchetype}.noWeekday`)}
								</ToggleButton>
							</InputGridField>
							{useWeekday && (
								<Grid item xs={12}>
									<InputGridField
										type={ComponentType.Select}
										width={12}
										muiLabel={{
											label: 'Weekday',
											labelId: 'weekday-label',
										}}
									>
										<Select
											fullWidth
											labelId='weekday-label'
											label='weekday'
											value={parsedOffset.weekDay ?? null}
											onChange={(event) =>
												setParsedOffset((prevState) => ({
													...prevState,
													weekDay: event.target.value as ParsedOffset['weekDay'],
												}))
											}
											disabled={!isEditing}
											size='small'
											inputProps={{
												className: 'panExcluded',
											}}
										>
											<MenuItem value='mo'>{t('time.monday')}</MenuItem>
											<MenuItem value='tu'>{t('time.tuesday')}</MenuItem>
											<MenuItem value='we'>{t('time.wednesday')}</MenuItem>
											<MenuItem value='th'>{t('time.thursday')}</MenuItem>
											<MenuItem value='fr'>{t('time.friday')}</MenuItem>
											<MenuItem value='sa'>{t('time.saturday')}</MenuItem>
											<MenuItem value='su'>{t('time.sunday')}</MenuItem>
										</Select>
									</InputGridField>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</Collapse>
		</>
	);
};
