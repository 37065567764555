import { Container } from '@mui/material';
import { ChainArchetypeEditor } from '../../components/taskworkflow/chain/ChainArchetypeEditor';
import { ChainEditorProvider } from '../../contexts/chainEditorContext/ChainEditorContext';
import { ChainArchetypeEditorProvider } from '../../contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Add, Link, Settings } from '@mui/icons-material';
import { CombinedIcon } from '../../components/common/CombinedIcon';

interface Props {
	viewMode?: boolean;
}

export enum DragZoneName {
	taskArchetypeDroppableList = 'task-archetype-droppable-list',
	fieldArchetypeDroppableList = 'field-archetype-droppable-list',
	fieldSetArchetypeDroppableList = 'field-set-archetype-droppable-list',
}

export enum DropZoneName {
	taskArchetypeDropZone = 'task-archetype-drop-zone',
	taskFieldDropZone = 'task-field-drop-zone',
}

export interface DragDropState {
	dragDropState: 'none' | 'dragging' | 'draggingOver' | 'dropped';
	sourceType?: DragZoneName;
	sourceId?: number;
	destinationType?: DropZoneName;
	destinationId?: number;
	insertIndex?: number;
	draggedElement?: Element | null;
}

export const TaskChainArchetypeCreate = ({ viewMode }: Props) => {
	const { id: routeId } = useParams();
	const { setBreadCrumbs } = useLayout();
	const id = routeId ? parseInt(routeId) : -1;

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.taskChainArchetype',
				route: '/settings/taskChainArchetype',
				icon: <Link />,
			},
			{
				label: 'breadCrumbs.add',
				icon: <CombinedIcon main={<Link />} sub={<Add />} />,
			},
		]);
	}, []);

	return (
		<ChainEditorProvider>
			<Container maxWidth={false} sx={{ backgroundColor: '000000' }}>
				<ChainArchetypeEditorProvider chainId={id}>
					<ChainArchetypeEditor chainIndex={0} viewMode={viewMode} />
				</ChainArchetypeEditorProvider>
			</Container>
		</ChainEditorProvider>
	);
};
