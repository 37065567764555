import { OrderModifier } from '@components/orders/OrderModifier';
import { FormAction } from '@contexts/formContext/types';
import { OrderModifyProvider } from '@contexts/orderModifyContext/OrderModifyContext';
import { useParams } from 'react-router-dom';

interface Props {
	formAction: FormAction;
}

export const OrderModify = ({ formAction }: Props) => {
	const id = useParams()?.id;

	return (
		<OrderModifyProvider orderId={id ? +id : -1} formAction={formAction}>
			<OrderModifier />
		</OrderModifyProvider>
	);
};
