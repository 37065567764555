import { useOrderEditor } from '@contexts/orderEditorContext/OrderEditorContext';
import { Button, Grid } from '@mui/material';
import useProducts from '@hooks/useProducts';
import { ProductType } from 'common';
import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { OutboundSuborderProduct } from './OutboundSuborderProduct';

export const OutboundSuborderProductsContainer = ({ subOrder }: SubOrderProductsContainerProps) => {
	const { addEmptyVariant } = useOrderEditor();
	const { productList } = useProducts();

	const subOrderCode = subOrder?.code;
	if (!subOrder || !subOrderCode) return null;

	return (
		<Grid item xs={12} container spacing={2}>
			{subOrder?.content?.map((op, it) => {
				return (
					<OutboundSuborderProduct
						key={it}
						index={it}
						data={op}
						subOrderCode={subOrderCode}
						unavailableVariantIds={[]}
					/>
				);
			})}
			<Grid item xs={12}>
				<Button
					variant='outlined'
					disabled={productList.filter((p) => p.productType === ProductType.product).length === 0}
					fullWidth
					onClick={() => addEmptyVariant(subOrderCode)}
				>
					+
				</Button>
			</Grid>
		</Grid>
	);
};
