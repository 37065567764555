import GroupedWarehouseAutocomplete from '@components/common/inputs/warehouses/GroupedWarehouseAutocomplete';
import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Grid, IconButton } from '@mui/material';
import { ManualSuborderProductsContainer } from './ManualSuborderProductsContainer';
import { Delete } from '@mui/icons-material';
import AdvancedWarehouseAutocomplete from '@components/common/inputs/warehouses/AdvancedWarehouseAutocomplete';

export const ManualSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { subOrders, setSuborderProperties, removeSubOrder } = useOrderEditor();
	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	if (!currentSubOrder || !currentSubOrder.code) {
		return null;
	}

	return (
		<Grid
			item
			container
			p={1}
			sx={{
				margin: '1rem 0 1rem 0',
				overflow: 'show',
			}}
			rowGap={2}
			display='flex'
		>
			<Grid item flexGrow={1} flexBasis={0} container rowGap={2} display='flex'>
				<Grid item xs={12}>
					<GroupedWarehouseAutocomplete
						value={currentSubOrder.toId ?? null}
						onChange={(value) => {
							setSuborderProperties({
								subOrderCode,
								toId: value ?? undefined,
							});
						}}
					/>
					{/* <AdvancedWarehouseAutocomplete
						value={currentSubOrder.toId ?? null}
						onChange={(value) => {
							setSuborderProperties({
								subOrderCode,
								fromId: value ?? undefined,
							});
						}}
					/> */}
				</Grid>
				<Grid item xs={12}>
					<ManualSuborderProductsContainer subOrder={currentSubOrder} />
				</Grid>
			</Grid>
			<Grid
				item
				flexShrink={1}
				flexBasis={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<IconButton onClick={() => removeSubOrder(currentSubOrder.code ?? '')}>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};
