import { EditShowGridField } from '@components/common/EditShowGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCarriers from '@hooks/useCarriers';
import { Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { CustomerResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CarrierDeliveryTime = () => {
	const { control, watch, formAction } = useGenericForm<CustomerResponse>();
	const { deliveryTimeList } = useCarriers();
	const { t } = useTranslation();

	const carrierId = watch('carrierId');

	const editDisabled = formAction === 'view';

	// TODO: this should be a function outside this file, we use it in many places
	function formatTime(time: string) {
		const [hours, minutes] = time.split(':').map(Number);
		const paddedHours = String(hours).padStart(2, '0');
		const paddedMinutes = String(minutes).padStart(2, '0');
		return `${paddedHours}:${paddedMinutes}`;
	}

	function showDeliveryTime(value: string) {
		const dt = deliveryTimeList.find((dt) => dt.id === +value);
		return (
			<>
				{formatTime(dt?.startTime ?? '00:00')} ~ {formatTime(dt?.endTime ?? '00:00')}
			</>
		);
	}

	let element = <></>;
	if (carrierId !== null) {
		const deliveryTimes = deliveryTimeList.filter((dt) => dt.carrierId === carrierId);

		if (deliveryTimes.length > 0) {
			element = (
				<Controller
					key='deliveryTime'
					name='deliveryTimeId'
					control={control}
					render={({ field }) => (
						<InputGridField
							width={12}
							muiLabel={{
								label: t('customer.preferredDeliveryTime'),
								labelId: 'delivery-time',
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='delivery-time'
								id='deliveryTime'
								fullWidth
								size='small'
								label={t('customer.preferredDeliveryTime')}
								value={field.value ?? -1}
								onChange={(ev) => field.onChange(+ev.target.value !== -1 ? +ev.target.value : null)}
							>
								<MenuItem value={-1} key={'none'}>
									None
								</MenuItem>
								{deliveryTimeList
									?.filter((dt) => dt.carrierId === carrierId)
									.map((u, index) => {
										if (u.id) {
											return (
												<MenuItem value={u.id} key={index}>
													{`${formatTime(u.startTime)} ~ ${formatTime(u.endTime)}`}
												</MenuItem>
											);
										}
									})}
							</Select>
						</InputGridField>
					)}
				/>
			);
		} else {
			element = (
				<Grid item xs={12}>
					{t('carrier.deliveryTimes.none')}
				</Grid>
			);
		}
	}

	return element;
};
