import { z } from 'zod';
import {
	TaskApiActionData,
	TaskFieldConfig,
	parseObjectToValues,
	parseValuesToString,
} from '../taskFieldConfig';
import { doSetTaskValues } from '../../../store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import { TaskFieldValueResponse, TaskFieldValuesResponse } from 'common';

const DropdownFieldValueSchema = z.object({
	value: z.string(),
});
const DropdownFieldValuesSchema = z.array(DropdownFieldValueSchema);

export type DropdownFieldValue = z.infer<typeof DropdownFieldValueSchema>;
export type DropdownFieldValues = z.infer<typeof DropdownFieldValuesSchema>;

async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(DropdownFieldValuesSchema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
}

export const dropdownFieldConfig: TaskFieldConfig = {
	stringToValues: (value: string): DropdownFieldValues =>
		parseObjectToValues<DropdownFieldValues>(DropdownFieldValuesSchema, value),
	valuesToString: (values: unknown[]) => parseValuesToString(DropdownFieldValuesSchema, values),
	validateStructure: (values: unknown[]) => DropdownFieldValuesSchema.safeParse(values).success,
	executeApiAction: async (data: TaskApiActionData) => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
	saveStub: async (data: TaskApiActionData) => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
};
