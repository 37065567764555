import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { Grid, MenuItem, Select } from '@mui/material';
import { CarrierResponse, CarrierType } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control, formAction } = useGenericForm<CarrierResponse>();
	const { t } = useTranslation();

	const editDisabled = formAction === 'view';

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='carrier.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='carrierType'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'carrier-type',
							label: t('carrier.type'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='carrier-type'
							id='carriertype'
							label={t('carrier.type')}
							disabled={editDisabled}
							size='small'
							fullWidth
							{...field}
							value={field.value ?? CarrierType.external}
							onChange={(ev) => field.onChange(ev.target.value as CarrierType)}
						>
							{(Object.keys(CarrierType) as (keyof typeof CarrierType)[]).map((key, index) => (
								<MenuItem value={CarrierType[key]} key={index}>
									{t(`carrier.types.${CarrierType[key]}`)}
								</MenuItem>
							))}
						</Select>
					</InputGridField>
				)}
			/>
		</Grid>
	);
};
