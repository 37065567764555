import React from 'react';
import { DialogComponentProps, DialogResult } from '@contexts/feedbacksContext/types';
import { CustomerTypeForm } from '@pages/Customers/CustomerTypeForm';
import { FormType } from '@contexts/formContext/types';
import { CustomerTypeResponse, LocaleEntryAction, TranslationTypes } from 'common';
import i18n from 'i18n';

export interface QuickCustomerTypeDialogData {
	newName: string;
}

export const QuickCustomerTypeDialog: React.FC<
	DialogComponentProps<DialogResult, QuickCustomerTypeDialogData>
> = ({ close, data }) => {
	return (
		<CustomerTypeForm
			formAction='create'
			formType={FormType.quick}
			forcedValues={
				{
					id: -1,
					canHaveChildren: false,
					canHaveParent: false,
					strings: [
						{
							type: TranslationTypes.name,
							value: data?.newName ?? '',
							langCode: i18n.language,
							action: LocaleEntryAction.CREATE,
						},
					],
				} as CustomerTypeResponse
			}
			onDataSaved={(data) => close({ id: data.id })}
		/>
	);
};
