import { useOrderEditor } from '@contexts/orderEditorContext/OrderEditorContext';
import { Button, Grid } from '@mui/material';
import useProducts from '@hooks/useProducts';
import { ProductType } from 'common';
import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { ManualSuborderProduct } from './ManualSuborderProduct';

export const ManualSuborderProductsContainer = ({
	subOrder,
}: SubOrderProductsContainerProps): JSX.Element | null => {
	const { addEmptyVariant, subOrders } = useOrderEditor();
	const { productList } = useProducts();

	if (!subOrder) {
		return null;
	}
	const subOrderCode = subOrder.code;
	if (!subOrderCode) {
		return null;
	}

	return (
		<Grid item xs={12} container spacing={2}>
			{subOrders
				.find((so) => so.code === subOrderCode)
				?.content?.map((op, it) => {
					return (
						<ManualSuborderProduct
							key={`${op.id}-${it}`}
							orderProduct={op}
							subOrderCode={subOrderCode}
							iterator={it}
						/>
					);
				})}
			<Grid item xs={12}>
				<Button
					variant='outlined'
					disabled={productList.filter((p) => p.productType === ProductType.product).length === 0}
					fullWidth
					onClick={() => addEmptyVariant(subOrderCode)}
				>
					+
				</Button>
			</Grid>
		</Grid>
	);
};
