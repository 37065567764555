import { createContext, useContext, useEffect, useState } from 'react';
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch, useForm } from 'react-hook-form';
import useTasks from '@hooks/useTasks';
import {
	AppFunction,
	ChainArchetypeRequest,
	ChainArchetypeResponse,
	OrderDirectionType,
	OrderType,
	TaskArchetypeResponse,
	TriggerRequest,
	TriggerType,
} from 'common';
import useFeedbacks from '@hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';

interface ChainArchetypeEditorContextType {
	isEditing: boolean;
	allowedFromTypes: OrderDirectionType[];
	allowedToTypes: OrderDirectionType[];
	tasks: number[];
	setIsEditing: (editing: boolean) => void;
	addTasks: (taskArchetypeIds: number[]) => void;
	addTaskAtIndex: (index: number, taskArchetypeId?: number) => void;
	modifyTaskAtIndex: (index: number, taskArchetype: TaskArchetypeResponse) => void;
	moveTaskAtIndex: (sourceIndex: number, destinationIndex: number) => void;
	deleteTaskAtIndex: (index: number) => void;
	// NEW
	editorWidth: number;
	increaseEditorWidth: (width: number) => void;
	chainControl: Control<ChainArchetypeRequest>;
	triggerControl: Control<TriggerRequest>;
	setAllowedTypes: (subType: string, reset?: boolean) => void;
	getTriggerValues: UseFormGetValues<TriggerRequest>;
	setTriggerValue: UseFormSetValue<TriggerRequest>;
	watchTrigger: UseFormWatch<TriggerRequest>;
	onSubmit?: () => void;
}

interface ChainArchetypeEditorContextProps {
	children: React.ReactNode;
	chainId: number;
}

const defaultContext: ChainArchetypeEditorContextType = {
	isEditing: false,
	allowedFromTypes: [],
	allowedToTypes: [],
	tasks: [],
	setIsEditing: () =>
		console.warn('setEditingMode was called without being provided by a context provider'),
	addTaskAtIndex: () =>
		console.warn('addNewTaskAtIndex was called without being provided by a context provider'),
	modifyTaskAtIndex: () =>
		console.warn('modifyTaskAtIndex was called without being provided by a context provider'),
	addTasks: () => console.warn('addTasks was called without being provided by a context provider'),
	moveTaskAtIndex: () =>
		console.warn('moveTaskAtIndex was called without being provided by a context provider'),
	deleteTaskAtIndex: () =>
		console.warn('deleteTaskAtIndex was called without being provided by a context provider'),
	// NEW
	editorWidth: 34,
	increaseEditorWidth: () =>
		console.warn('setEditorWidth was called without being provided by a context provider'),
	chainControl: {} as Control<ChainArchetypeRequest>,
	triggerControl: {} as Control<TriggerRequest>,
	setAllowedTypes: () =>
		console.warn('setAllowedTypes was called without being provided by a context provider'),
	getTriggerValues: {} as UseFormGetValues<TriggerRequest>,
	setTriggerValue: {} as UseFormSetValue<TriggerRequest>,
	watchTrigger: {} as UseFormWatch<TriggerRequest>,
};

const ChainArchetypeEditorContext = createContext<ChainArchetypeEditorContextType>(defaultContext);

export const ChainArchetypeEditorProvider = ({
	children,
	chainId,
}: ChainArchetypeEditorContextProps) => {
	const {
		createTaskChainArchetype,
		editTaskChainArchetype,
		createTrigger,
		chainArchetypesList,
		triggerList,
	} = useTasks();
	const { t } = useTranslation();
	const { showSnackBar } = useFeedbacks();
	const [cachedId, setCachedId] = useState<number>(chainId);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [allowedFromTypes, setAllowedFromTypes] = useState<OrderDirectionType[]>([]);
	const [allowedToTypes, setAllowedToTypes] = useState<OrderDirectionType[]>([]);
	const [tasks, setTasks] = useState<number[]>([]);
	const [editorWidth, setEditorWidth] = useState<number>(34);

	const {
		control: chainControl,
		reset: chainReset,
		handleSubmit: handleChainSubmit,
	} = useForm<ChainArchetypeRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: '',
			estimateCompletionTime: null,
		},
	});

	const {
		control: triggerControl,
		reset: triggerReset,
		getValues: getTriggerValues,
		setValue: setTriggerValue,
		watch: watchTrigger,
		// handleSubmit: handleTriggerSubmit,
	} = useForm<TriggerRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			type: TriggerType.order,
			fromType: undefined,
			fromId: null,
			toType: undefined,
			toId: null,
		},
	});

	useEffect(() => {
		const chain = chainArchetypesList.find((chain) => chain.id === cachedId);
		if (!chain) {
			return;
		}

		chainReset({
			name: chain.name,
			estimateCompletionTime: chain.estimateCompletionTime ?? '',
			taskArchetypeIds: chain.taskArchetypeIds,
		});

		if (chain.taskArchetypeIds) {
			addTasks(chain.taskArchetypeIds);
		}

		const trigger = triggerList.find((trigger) => trigger.id === chain.triggerId);
		if (!trigger) {
			return;
		}
		triggerReset(trigger);
		setAllowedTypes(trigger.subtype as string);
	}, [chainArchetypesList, triggerList, cachedId]);

	const onSubmit = handleChainSubmit(async (data) => {
		const chainRequest = data;
		const triggerRequest = { ...getTriggerValues() };
		// triggerRequest.fromId = triggerRequest.fromId === -2 ? null : triggerRequest.fromId;
		// triggerRequest.toId = triggerRequest.toId === -2 ? null : triggerRequest.toId;

		triggerRequest.fromSubType =
			triggerRequest.fromSubType === -1 ? null : triggerRequest.fromSubType;
		triggerRequest.toSubType = triggerRequest.toSubType === -1 ? null : triggerRequest.toSubType;
		const match = triggerList.find(
			(trigger) =>
				trigger.subtype === triggerRequest.subtype &&
				trigger.fromId === triggerRequest.fromId &&
				trigger.toId === triggerRequest.toId &&
				trigger.fromType === triggerRequest.fromType &&
				trigger.toType === triggerRequest.toType,
		);

		if (!match) {
			console.log('no trigger match found: ', triggerRequest);
			const culo = await createTrigger(triggerRequest);
			if (culo.id) {
				// TODO: yeah check what we want to do with the ids here too
				chainRequest.triggerId = culo.id as number;
			}
		} else {
			console.log('matched with:', match);
			// TODO: duh
			chainRequest.triggerId = match.id as number;
		}

		chainRequest.taskArchetypeIds = tasks;

		// TODO: instert feedbacks please
		if (cachedId === -1) {
			const createResponse = await createTaskChainArchetype(chainRequest);
			if (createResponse.type === 'taskChainArchetypes/create/fulfilled') {
				const payload = createResponse.payload as ChainArchetypeResponse;
				console.log(payload.id);
				setCachedId(+payload.id);
				showSnackBar({
					message: t('operations.success', {
						item: t(`appFunctions.${AppFunction.TaskChainArchetype}`),
						action: t('operations.create'),
					}),
					severity: 'success',
				});
			} else {
				showSnackBar({
					message: t('operations.create.fail', {
						item: t(`appFunctions.${AppFunction.TaskChainArchetype}`),
						action: t('operations.create'),
					}),
					severity: 'error',
				});
			}
		} else {
			const chainEditRequest: ChainArchetypeResponse = { ...chainRequest, id: cachedId };
			const editResponse = await editTaskChainArchetype(chainEditRequest);
			if (editResponse.type === 'taskChainArchetypes/edit/fulfilled') {
				// const payload = editResponse.payload as ChainArchetypeResponse
				showSnackBar({
					message: t('operations.success', {
						item: t(`appFunctions.${AppFunction.TaskChainArchetype}`),
						action: t('operations.edit'),
					}),
					severity: 'success',
				});
			} else {
				showSnackBar({
					message: t('operations.edit.fail', {
						item: t(`appFunctions.${AppFunction.TaskChainArchetype}`),
						action: t('operations.edit'),
					}),
					severity: 'error',
				});
			}
		}
	});

	const setAllowedTypes = (subType: string, reset?: boolean) => {
		let fromType: OrderDirectionType[] = [];
		let toType: OrderDirectionType[] = [];
		// TODO: we are implying all the chains are orders, which is true RIGHT NOW
		// it won't be in the future but we'll deal with that when we get there
		switch (subType) {
			case OrderType.Inbound: {
				fromType = [OrderDirectionType.merchant];
				toType = [OrderDirectionType.warehouse];
				break;
			}
			case OrderType.Internal: {
				fromType = [OrderDirectionType.warehouse];
				toType = [OrderDirectionType.warehouse];
				break;
			}
			case OrderType.Outbound: {
				fromType = [OrderDirectionType.warehouse];
				toType = [OrderDirectionType.customer, OrderDirectionType.branch];
				break;
			}
			case OrderType.Processing: {
				fromType = [OrderDirectionType.warehouse];
				toType = [OrderDirectionType.warehouse];
				break;
			}
		}
		setAllowedFromTypes(fromType);
		setAllowedToTypes(toType);
		if (reset) {
			console.log('resetting');
			setTriggerValue('fromId', -1);
			setTriggerValue('toId', -1);
		}

		const cachedFromType = getTriggerValues().fromType;
		if (!cachedFromType) {
			setTriggerValue('fromType', fromType[0]);
		} else if (
			!fromType.includes(cachedFromType as OrderDirectionType) &&
			cachedFromType !== OrderDirectionType.entityGroup
		) {
			setTriggerValue('fromType', fromType[0]);
		} else {
			setTriggerValue('fromType', cachedFromType);
		}

		const cachedToType = getTriggerValues().toType;
		if (!cachedToType) {
			setTriggerValue('toType', toType[0]);
		} else if (
			!toType.includes(cachedToType as OrderDirectionType) &&
			cachedToType !== OrderDirectionType.entityGroup
		) {
			setTriggerValue('toType', toType[0]);
		} else {
			setTriggerValue('toType', cachedToType);
		}
	};

	const addTasks = (taskArchetypeIds: number[]) => setTasks(taskArchetypeIds);

	const addTaskAtIndex = (index: number, taskArchetypeId?: number) => {
		setTasks((tasksState) => {
			const newTasks = [...tasksState];
			newTasks.splice(index, 0, taskArchetypeId ?? -1);
			return newTasks;
		});
	};

	const modifyTaskAtIndex = (index: number, taskArchetype: TaskArchetypeResponse) => {
		setTasks((tasksState) => {
			const newTasks = [...tasksState];
			newTasks[index] = taskArchetype.id as number;
			return newTasks;
		});
	};

	const moveTaskAtIndex = (sourceIndex: number, destinationIndex: number) => {
		setTasks((tasksState) => {
			const newTasks = [...tasksState];
			const [removed] = newTasks.splice(sourceIndex, 1);
			newTasks.splice(destinationIndex, 0, removed);
			return newTasks;
		});
	};

	const deleteTaskAtIndex = (index: number) => {
		setTasks((tasksState) => {
			const newTasks = [...tasksState];
			newTasks.splice(index, 1);
			return newTasks;
		});
	};

	const increaseEditorWidth = (width: number) => setEditorWidth((prev) => prev + width);

	const contextValue = {
		isEditing,
		allowedFromTypes,
		allowedToTypes,
		tasks,
		setIsEditing,
		addTasks,
		addTaskAtIndex,
		modifyTaskAtIndex,
		moveTaskAtIndex,
		deleteTaskAtIndex,
		// NEW
		editorWidth,
		increaseEditorWidth,
		chainControl,
		triggerControl,
		setAllowedTypes,
		getTriggerValues,
		setTriggerValue,
		watchTrigger,
		onSubmit,
	};

	return (
		<ChainArchetypeEditorContext.Provider value={contextValue}>
			{children}
		</ChainArchetypeEditorContext.Provider>
	);
};

export const useChainArchetypeEditor = () => useContext(ChainArchetypeEditorContext);
