import useLocale from '@hooks/useLocale';
import useMerchants from '@hooks/useMerchants';

import { InputLabel, MenuItem, Select } from '@mui/material';

import { AppFunction, MerchantType, OrderDirectionType, TranslationTypes } from 'common';

import { EditShowGridField } from '@components/common/EditShowGridField';
import { useTranslation } from 'react-i18next';
import {
	DirectionType,
	createAdornment,
} from '@components/taskworkflow/chain/DestinationTypeSelector';
import { Controller } from 'react-hook-form';
import { useChainArchetypeEditor } from '@contexts/index';

interface Props {
	directionType: DirectionType;
}

export const MerchantSelector = ({ directionType }: Props) => {
	const { triggerControl, setTriggerValue, watchTrigger } = useChainArchetypeEditor();
	const { merchantList } = useMerchants();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const currentType = directionType === 'from' ? watchTrigger('fromType') : watchTrigger('toType');
	const currentId = directionType === 'from' ? watchTrigger('fromId') : watchTrigger('toId');

	if (currentType !== OrderDirectionType.merchant && currentId !== -1) {
		return null;
	}

	return (
		<Controller
			name={directionType === 'from' ? 'fromId' : 'toId'}
			control={triggerControl}
			render={({ field }) => (
				<EditShowGridField isEditing={true} width={12}>
					<InputLabel id='merchant-select-label'>
						{t(`appFunctions.${AppFunction.Merchant}`)}
					</InputLabel>
					<Select
						id='merchant-select'
						fullWidth
						value={
							merchantList.some(
								(m) =>
									m.id === field.value &&
									(m.type === MerchantType.seller || m.type === MerchantType.both),
							) || field.value === -2
								? field.value
								: -1
						}
						label={t(`appFunctions.${AppFunction.Merchant}`)}
						labelId='merchant-select-label'
						size='small'
						onChange={(e) => {
							const value = e.target.value as number;
							if (!value || isNaN(value)) {
								return;
							}
							if (value === -1) {
								setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							} else {
								setTriggerValue(
									directionType === 'from' ? 'fromType' : 'toType',
									OrderDirectionType.merchant,
								);
							}
							console.log(value);
							field.onChange(value);
						}}
						endAdornment={createAdornment(field.value ?? -1, () => {
							setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							field.onChange(-1);
						})}
					>
						<MenuItem
							value={-1}
							sx={{
								backgroundColor: 'rgba(255, 0, 0, 0.2)',
							}}
						>
							{t('common.none')}
						</MenuItem>
						<MenuItem
							value={-2}
							sx={{
								backgroundColor: 'rgba(0, 255, 0, 0.2)',
							}}
						>
							{t('common.all')}
						</MenuItem>
						{merchantList
							.filter((m) => m.type === MerchantType.seller || m.type === MerchantType.both)
							.map((merchant) => (
								<MenuItem key={`merchant_${merchant.id}`} value={merchant.id}>
									{getTranslatedString(AppFunction.Merchant, merchant.id, TranslationTypes.name)}
								</MenuItem>
							))}
					</Select>
				</EditShowGridField>
			)}
		/>
	);
};
