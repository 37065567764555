import { useState } from 'react';
import { IconButton, TextField, useTheme } from '@mui/material';

import { ColumnFilterElementTemplateOptions } from 'primereact/column';

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import styles from './CustomFilterTextInput.module.css';

export const CustomFilterInput = (options: ColumnFilterElementTemplateOptions) => {
	const [text, setText] = useState('');
	const theme = useTheme();

	return (
		<TextField
			id={`${options.field}-filter-input`}
			size='small'
			fullWidth
			value={text}
			onChange={(e) => {
				setText(e.target.value);
				options.filterApplyCallback(e.target.value);
			}}
			InputProps={{
				className: styles.baseline,
				endAdornment: (
					<div
						style={{
							overflow: 'hidden',
							display: 'flex',
							alignItems: 'center',
							width: '46px',
							padding: '3px',
						}}
					>
						<IconButton
							className={text !== '' ? styles.visibleButton : styles.hiddenButton}
							sx={{
								backgroundColor: 'transparent',
								color: theme.palette.error.main,
								transition: 'color 400ms',
								fontSize: '0.9rem',
								'&:hover': {
									backgroundColor: theme.palette.error.main,
									color: theme.palette.error.contrastText,
								},
							}}
							onClick={() => {
								setText('');
								options.filterApplyCallback(null);
							}}
						>
							<FilterAltOffIcon fontSize='inherit' />
						</IconButton>
					</div>
				),
			}}
		/>
	);
};
