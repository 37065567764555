import { RecipeChip } from '@components/common/chips/RecipeChip';
import { SkuChip } from '@components/common/chips/SkuChip';
import { EnhancedVariant } from '@contexts/orderEditorContext/types';
import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	Box,
	InputAdornment,
	MenuItem,
	Popper,
	TextField,
	styled,
	useTheme,
} from '@mui/material';
import { AppFunction, ProductType, TranslationTypes } from 'common';
import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	value: number | null;
	availableIds?: number[];
	onChange: (value: EnhancedVariant | null) => void;
	allowedProductTypes?: ProductType[];
}

const CustomPopper = styled(Popper)(({ theme }) => ({
	zIndex: '9999 !important',
	pointerEvents: 'auto',
}));

// TODO: add chips and stuff, maybe use props to define which info to show in the selection
const ProductAutocomplete = ({
	value,
	availableIds,
	onChange,
	allowedProductTypes,
}: Props): JSX.Element => {
	const { productList, variantList } = useProducts();
	const { getTranslatedString } = useLocale();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const theme = useTheme();

	const availableVariants = useMemo(() => {
		return productList
			.filter((p) =>
				allowedProductTypes
					? allowedProductTypes.includes(p.productType)
					: p.productType === ProductType.product,
			)
			.map((pr) => {
				if (pr.variants && pr.variants.length > 0) {
					return pr.variants.map((vr) => {
						if (
							vr !== undefined &&
							vr.id &&
							vr.id !== -1 &&
							(!availableIds || availableIds?.includes(vr.id))
						) {
							return vr;
						}
					});
				}
			})
			.flat()
			.filter((v) => v !== undefined) as EnhancedVariant[];
	}, [productList, variantList]);

	const getOptionLabel = (option: EnhancedVariant): string => {
		const productName = getTranslatedString(
			AppFunction.Product,
			option?.productId ?? -1,
			TranslationTypes.name,
		);
		const variantName = getTranslatedString(AppFunction.Variant, option.id, TranslationTypes.name);
		return `${productName} ${variantName}`;
	};

	const isOptionEqualToValue = (option: EnhancedVariant, value: EnhancedVariant): boolean => {
		return option.id === value.id;
	};

	const renderOption = (
		props: HTMLAttributes<HTMLLIElement>,
		option: EnhancedVariant,
		state: AutocompleteRenderOptionState,
	): ReactNode => {
		let variantName = 'none';
		let productName = 'none';
		if (option?.id !== -1) {
			productName = getTranslatedString(
				AppFunction.Product,
				option?.productId ?? -1,
				TranslationTypes.name,
			);
			variantName = getTranslatedString(
				AppFunction.Variant,
				option?.id ?? -1,
				TranslationTypes.name,
			);
		}
		const label = `${productName} ${variantName}`;
		return (
			<MenuItem
				{...props}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: theme.spacing(1),
				}}
			>
				<SkuChip {...option} />
				<div
					style={{
						marginLeft: '1rem',
						flexGrow: 1,
						flexBasis: 0,
						display: 'flex',
						justifyContent: 'flex-start',
						fontWeight: state.selected ? 'bold' : 'normal',
					}}
				>
					{label}
				</div>
				<RecipeChip {...option} />
			</MenuItem>
		);
	};

	/**
	 * Renders the input for the {@link Autocomplete}
	 * @param {AutocompleteRenderInputParams} params - the input parameters
	 * @returns {JSX.Element} - the rendered input
	 */
	const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
		const selectedValue = variantList.find((v) => v.id === value);
		return (
			<TextField
				{...params}
				value={`${params.inputProps.value}`}
				label={t(`appFunctions.${AppFunction.Variant}`)}
				variant='outlined'
				InputProps={{
					...params.InputProps,
					startAdornment: (
						<InputAdornment position='end' sx={{ display: 'flex', gap: 1 }}>
							{getIcon(AppFunction.Product, ProductType.product)}
							{selectedValue && <SkuChip {...selectedValue} />}
						</InputAdornment>
					),
					endAdornment: (
						<Box
							sx={{
								'& .MuiAutocomplete-endAdornment': {
									alignSelf: 'center',
									top: '50%',
									bottom: '50%',
								},
							}}
						>
							<InputAdornment position='end'>
								<RecipeChip {...selectedValue} />
							</InputAdornment>
							{params.InputProps.endAdornment}
						</Box>
					),
				}}
			/>
		);
	};

	/**
	 * Filters the options based on the input value
	 * @param {EnhancedVariant[]} options - the available options
	 * @param {object} param1 - the input value
	 * @returns {EnhancedVariant[]} - the filtered options
	 */
	const filterOptions = (
		options: EnhancedVariant[],
		{ inputValue }: { inputValue: string },
	): EnhancedVariant[] => {
		return options.filter((option) => {
			const productName = getTranslatedString(
				AppFunction.Product,
				option.productId ?? -1,
				TranslationTypes.name,
			).toLowerCase();
			const variantName = getTranslatedString(
				AppFunction.Variant,
				option.id,
				TranslationTypes.name,
			).toLowerCase();
			const sku = option.sku.toLowerCase();

			return (
				productName.includes(inputValue.toLowerCase()) ||
				variantName.includes(inputValue.toLowerCase()) ||
				sku.includes(inputValue.toLowerCase())
			);
		});
	};

	return (
		<Autocomplete
			id='simple-product-autocomplete'
			options={availableVariants}
			value={value === -1 ? null : variantList.find((v) => v.id === value) || null}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			renderOption={renderOption}
			renderInput={renderInput}
			filterOptions={filterOptions}
			onChange={(_, newValue) => {
				if (typeof newValue === 'object' && newValue !== null) {
					onChange(newValue);
				} else {
					onChange(null);
				}
			}}
			fullWidth
			size='small'
			PopperComponent={CustomPopper}
		/>
	);
};

ProductAutocomplete.displayName = 'ProductAutocomplete';
export default ProductAutocomplete;
