import { MiniTask } from '@components/taskworkflow/tasks/widgets/MiniTask';
import { Box, Grid, List, Stack, TextField, useTheme } from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { useAppSelector } from '@store/hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const HomeCalendar = () => {
	const theme = useTheme();
	const vTasks = useAppSelector((state) => state.tasks.virtualTasks);

	const [yourSister, setYourSister] = useState([moment().startOf('day').toISOString()]);
	const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));

	useEffect(() => {
		const allTasks: string[] = [];
		vTasks.forEach((task) => {
			if (task.status !== 'completed') {
				allTasks.push(moment(task.isoAssignableAt).startOf('day').toISOString());
			}
		});
		setYourSister(allTasks);
	}, [vTasks]);

	return (
		<Grid
			item
			xs='auto'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Stack direction='column' gap={2}>
				<Box
					sx={{
						borderRadius: '1rem',
						padding: '1rem',
						background: theme.palette.gradient.primaryAlpha,
					}}
				>
					<StaticDatePicker
						displayStaticWrapperAs='desktop'
						// disablePast
						value={selectedDate}
						renderInput={(params) => <TextField {...params} />}
						onChange={(day) => {
							if (!day) return;
							setSelectedDate(day);
						}}
						renderDay={(day, _, pickersDayProps) => {
							if (yourSister.includes(day.toISOString())) {
								return (
									<PickersDay
										{...pickersDayProps}
										sx={{
											position: 'relative',
											background: theme.palette.gradient.primaryAlpha,
											color: pickersDayProps.selected ? 'white' : 'black',
											border: pickersDayProps.selected ? '1px solid red' : '1px solid transparent',
											'&:after': {
												content: '""',
												width: '0.5rem',
												height: '0.5rem',
												background: 'red',
												position: 'absolute',
												top: -2,
												right: -2,
												zIndex: 1,
												borderRadius: '50%',
											},
										}}
									/>
								);
							} else {
								return <PickersDay {...pickersDayProps} />;
							}
						}}
					/>
				</Box>
				{!selectedDate.isSame(moment(), 'day') &&
					vTasks.some(
						(task) =>
							task.isoAssignableAt && moment(task.isoAssignableAt).isSame(selectedDate, 'day'),
					) && (
						<Box
							sx={{
								borderRadius: '1rem',
								padding: '1rem',
								background: theme.palette.gradient.primaryAlpha,
							}}
						>
							<List>
								{vTasks
									.filter(
										(task) =>
											task.isoAssignableAt &&
											moment(task.isoAssignableAt).isSame(selectedDate, 'day'),
									)
									.map((task, it) => (
										<MiniTask key={`${task.id}-${task.chainId}`} task={task} it={it} />
									))}
							</List>
						</Box>
					)}
			</Stack>
		</Grid>
	);
};
