import useAppFunctions from '@hooks/useAppFunctions';
import { Container, Grid, Typography } from '@mui/material';
import { AppFunction, OrderBaseResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	children: React.ReactNode;
	data: OrderBaseResponse;
	showTitle?: boolean;
}

export const EntryContainer = ({ children, data, showTitle }: Props) => {
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();

	const icon = getIcon(AppFunction.Order, data.orderType);

	return (
		<Container
			maxWidth={false}
			sx={{
				padding: '0 !important',
				height: '100%',
			}}
		>
			<Grid container p='1rem' borderRadius='1rem' bgcolor='white'>
				{showTitle && data.orderType && (
					<Grid
						xs={12}
						item
						display='flex'
						gap={1}
						justifyContent='flex-start'
						alignItems='center'
						marginBottom='1rem'
					>
						{icon}
						<Typography variant='h5'>
							{t(`${AppFunction.Order}.orderTypes.${data.orderType.toLocaleLowerCase()}`)}
						</Typography>
					</Grid>
				)}
				{children}
			</Grid>
		</Container>
	);
};
