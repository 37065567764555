import { useEffect } from 'react';
import useMerchants from '../../hooks/useMerchants';
import useLocale from '../../hooks/useLocale';

import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';

import '../../styles/MerchantsListStyle.css';
import { AppFunction, GenericIdRequest, TranslationTypes } from 'common';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Settings } from '@mui/icons-material';
import { TableContextProvider } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { DropDownActionType } from '@contexts/tableContext/types';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';

const appFunction = AppFunction.Merchant;

export const MerchantList = () => {
	const { t } = useTranslation();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { merchantList, deleteMerchant } = useMerchants();
	const { getI18NString, renderI18NField } = useLocale();
	const { pushDialog } = useFeedbacks();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	function callDeleteMerchantDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteMerchant(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={merchantList}
			pageIdentifier={AppFunction.Merchant}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: (data: GenericIdRequest) => callDeleteMerchantDialog(data),
				},
			]}
		>
			<Column
				field='name'
				header='name'
				body={(data) =>
					renderI18NField(getI18NString(AppFunction.Merchant, data.id, TranslationTypes.name))
				}
			/>
			<Column field='type' header='type' body={(data) => t(`merchant.types.${data.type}`)} />
			<Column
				field='description'
				header='description'
				body={(data) =>
					renderI18NField(
						getI18NString(AppFunction.Merchant, data.id, TranslationTypes.description),
					)
				}
			/>
		</TableContextProvider>
	);
};
