import useApp from '../../hooks/useApp';
import { useEffect, useMemo, useState } from 'react';

import { Alert, Slide, SlideProps, Snackbar } from '@mui/material';
import { SnackBarMessage } from '../../store/app';

// TODO: this shit doesn't work as it should
// somehow the example in mui page works but this doesn't
// exit animation is not triggered and the snackbar just disappears
export default function SnackbarHandler() {
	const { snackbarMessages, popSnackBar } = useApp();
	const [snackbarContent, setSnackBarContent] = useState<SnackBarMessage | null>(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (snackbarMessages.length > 0) {
			setSnackBarContent(snackbarMessages[0]);
			setOpen(true);
		} else {
			setOpen(false);
			setSnackBarContent(null);
		}
	}, [snackbarMessages]);

	type TransitionProps = Omit<SlideProps, 'direction'>;
	function TransitionUp(props: TransitionProps) {
		return <Slide {...props} direction='up' />;
	}

	const snackbar = useMemo(() => {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={open}
				autoHideDuration={2000}
				onClose={() => popSnackBar()}
				TransitionComponent={TransitionUp}
			>
				{snackbarContent !== null ? (
					<Alert
						severity={snackbarContent.severity ?? 'info'}
						variant='filled'
						sx={{ width: '100%' }}
					>
						{snackbarContent.message}
					</Alert>
				) : (
					<></>
				)}
			</Snackbar>
		);
	}, [snackbarContent, open]);

	return snackbar;
}
