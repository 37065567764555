import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	AffectedRowsResponse,
	CustomerBillingRequest,
	CustomerBillingResponse,
	CustomerBranchRequest,
	CustomerBranchResponse,
	CustomerInvoicingTimeRequest,
	CustomerInvoicingTimeResponse,
	CustomerRequest,
	CustomerResponse,
	CustomersResponse,
	CustomerTypeRequest,
	CustomerTypeResponse,
	CustomerTypesResponse,
	GenericIdRequest,
} from 'common';

// #region Interfaces
export interface CustomerState {
	customers: CustomersResponse;
	customerTypes: CustomerTypesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: CustomerState = {
	customers: [],
	customerTypes: [],
	op_status: 'idle',
};
// #endregion

// #region Customers
export const doListCustomers = api.apiList<CustomersResponse>('customers/list', 'customers');

export const doCreateCustomersBulk = api.apiBulkCreate<CustomerRequest[], CustomersResponse>(
	'customers/create/bulk',
	'customers/bulk',
);

export const doCreateCustomer = api.apiCreate<CustomerRequest, CustomerResponse>(
	'customers/create',
	'customers',
);

export const doEditCustomer = api.apiEdit<CustomerResponse, AffectedRowsResponse>(
	'customers/edit',
	'customers',
);

export const doDeleteCustomer = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'customers/delete',
	'customers',
);
// #endregion

// #region Customer Types
export const doListCustomerTypes = api.apiList<CustomerTypesResponse>(
	'customers/types/list',
	'customers/types',
);

export const doCreateCustomerTypes = api.apiCreate<CustomerTypeRequest, CustomerTypeResponse>(
	'customers/types/create',
	'customers/types',
);

export const doEditCustomerTypes = api.apiEdit<CustomerTypeResponse, AffectedRowsResponse>(
	'customers/types/edit',
	'customers/types',
);

export const doDeleteCustomerTypes = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'customers/types/delete',
	'customers/types',
);
// #endregion

// #region Customer Branches
export const doCreateCustomerBranch = api.apiCreate<CustomerBranchRequest, CustomerBranchResponse>(
	'customers/branches/create',
	'customers/:customerId/branch',
);

export const doEditCustomerBranch = api.apiEdit<CustomerBranchResponse, AffectedRowsResponse>(
	'customers/branches/edit',
	'customers/branch',
);

export const doDeleteCustomerBranch = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'customers/branches/delete',
	'customers/branch',
);
// #endregion

// #region Customer Billings
export const doCreateCustomerBilling = api.apiCreate<
	CustomerBillingRequest,
	CustomerBillingResponse
>('customers/billing/create', 'customers/:customerId/billing');

export const doEditCustomerBilling = api.apiEdit<CustomerBillingResponse, AffectedRowsResponse>(
	'customers/billing/edit',
	'customers/billing',
);

export const doDeleteCustomerBilling = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'customers/billing/delete',
	'customers/billing',
);
// #endregion

// #region Customer Invoicing Times
export const doCreateCustomerInvoicingTime = api.apiCreate<
	CustomerInvoicingTimeRequest,
	CustomerInvoicingTimeResponse
>('customers/invoicingtime/create', 'customers/invoicingtime');

export const doEditCustomerInvoicingTime = api.apiEdit<
	CustomerInvoicingTimeResponse,
	AffectedRowsResponse
>('customers/invoicingtime/edit', 'customers/invoicingtime');

export const doDeleteCustomerInvoicingTime = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'customers/invoicingtime/delete',
	'customers/invoicingtime',
);
// #endregion

export const CustomersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doListCustomerTypes.pending.type,
					doCreateCustomer.pending.type,
					doEditCustomer.pending.type,
					doDeleteCustomer.pending.type,
					doListCustomerTypes.pending.type,
					doCreateCustomerTypes.pending.type,
					doEditCustomerTypes.pending.type,
					doDeleteCustomerTypes.pending.type,
					doCreateCustomerBranch.pending.type,
					doEditCustomerBranch.pending.type,
					doDeleteCustomerBranch.pending.type,
					doCreateCustomerBilling.pending.type,
					doEditCustomerBilling.pending.type,
					doDeleteCustomerBilling.pending.type,
					doCreateCustomerInvoicingTime.pending.type,
					doEditCustomerInvoicingTime.pending.type,
					doDeleteCustomerInvoicingTime.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListCustomers.fulfilled.type,
					doCreateCustomer.fulfilled.type,
					doEditCustomer.fulfilled.type,
					doDeleteCustomer.fulfilled.type,
					doListCustomerTypes.fulfilled.type,
					doCreateCustomerTypes.fulfilled.type,
					doEditCustomerTypes.fulfilled.type,
					doDeleteCustomerTypes.fulfilled.type,
					doCreateCustomerBranch.fulfilled.type,
					doEditCustomerBranch.fulfilled.type,
					doDeleteCustomerBranch.fulfilled.type,
					doCreateCustomerBilling.fulfilled.type,
					doEditCustomerBilling.fulfilled.type,
					doDeleteCustomerBilling.fulfilled.type,
					doCreateCustomerInvoicingTime.fulfilled.type,
					doEditCustomerInvoicingTime.fulfilled.type,
					doDeleteCustomerInvoicingTime.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				switch (action.type) {
					case doListCustomers.fulfilled.type:
						state.customers = action.payload as CustomersResponse;
						break;
					case doListCustomerTypes.fulfilled.type:
						state.customerTypes = action.payload as CustomerTypesResponse;
						break;
				}

				if (action.type === doListCustomers.fulfilled.type) {
					state.customers = action.payload as CustomersResponse;
				}
				state.op_status = 'succeeded';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListCustomers.rejected.type,
					doCreateCustomer.rejected.type,
					doEditCustomer.rejected.type,
					doDeleteCustomer.rejected.type,
					doListCustomerTypes.rejected.type,
					doCreateCustomerTypes.rejected.type,
					doEditCustomerTypes.rejected.type,
					doDeleteCustomerTypes.rejected.type,
					doCreateCustomerBranch.rejected.type,
					doEditCustomerBranch.rejected.type,
					doDeleteCustomerBranch.rejected.type,
					doCreateCustomerBilling.rejected.type,
					doEditCustomerBilling.rejected.type,
					doDeleteCustomerBilling.rejected.type,
					doCreateCustomerInvoicingTime.rejected.type,
					doEditCustomerInvoicingTime.rejected.type,
					doDeleteCustomerInvoicingTime.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
		// #endregion
	},
});

export default CustomersSlice.reducer;
