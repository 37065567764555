import { List } from '@mui/material';
import { OrderBaseResponse, Recipe, RecipeType, VariantResponse } from 'common';
import { CloseOrderFieldAction, CloseOrderValueMap } from '../../../../config';
import { SimpleDivisionItem } from './SimpleDivisionItem';
import { ProcessingItem } from '../../ProcessingItem';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { CloseOrderFieldValues } from '@contexts/taskContext/config/closeOrderFieldConfig';
import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import useProducts from '@hooks/useProducts';
import _ from 'lodash';

interface Props {
	order: OrderBaseResponse;
	recipe: Recipe;
	taskFieldId: number;
}

export const SimpleDivisionContainer = ({ order, recipe, taskFieldId }: Props) => {
	const { targetId, setTaskFieldValues, getParentProduct } = useTask();
	const { orderList } = useOrders();
	const { variantList } = useProducts();
	const [totalWeight, setTotalWeight] = useState<number>(0);
	const [isWeighted, setIsWeighted] = useState<boolean>(false);
	const [values, setValues] = useState<CloseOrderValueMap>({});

	useEffect(() => {
		setValuesInContext(values);
	}, [values]);

	useEffect(() => {
		resetValues();
	}, [targetId, order]);

	const resetValues = () => {
		const targetProduct = variantList.find(
			(variant) => variant.id === order?.content?.[0].variantId,
		);
		if (!targetProduct) {
			return;
		}

		const newValues = getValues(order, targetProduct);
		let tempTotalWeight = 0;
		Object.values(newValues).forEach((value) => {
			tempTotalWeight += value.newValues.reduce((acc, v) => acc + v.arrivedQty, 0);
		});
		setTotalWeight(tempTotalWeight);
		setValues(newValues);
	};

	const getValues = (
		order: OrderBaseResponse,
		targetProduct: VariantResponse,
	): CloseOrderValueMap => {
		const newValues: CloseOrderValueMap = {};

		order.content
			?.filter((c) => c.variantId !== undefined && c.variantId !== null)
			.forEach((content) => {
				let oldExpiresAt: Moment | null = null;
				let oldOrderProductId: number | null = null;
				if (order.parentId !== null && order.parentId !== undefined) {
					const parentOrder = orderList.find((o) => o.id === order.parentId);

					if (parentOrder && order.orderType) {
						const parentProduct = getParentProduct?.(
							taskFieldId,
							order.orderType,
							parentOrder,
							content,
						);
						oldExpiresAt = parentProduct?.expiresAt
							? moment(parentProduct.expiresAt).startOf('day')
							: null;
						oldOrderProductId = parentProduct?.id ? +parentProduct.id : null;
					}
				}

				const baseSourceId = content.sourceId ? +content.sourceId : null;

				const value = {
					variantId: content.variantId ?? -1,
					expiresAt: content.expiresAt ? moment(content.expiresAt) : oldExpiresAt,
					arrivedQty: order.targetQuantity ?? 0,
					sourceId: oldOrderProductId ?? baseSourceId,
					weight: content.weight ?? 0,
					// the first value is never estimated since we need to weight the product
					// in order to perform the cut
					estimated: false,
				};

				const remainingValue = {
					variantId: content.variantId ?? -1,
					expiresAt: content.expiresAt ? moment(content.expiresAt) : oldExpiresAt,
					arrivedQty: (targetProduct.weight ?? 0) - (order.targetQuantity ?? 0),
					sourceId: oldOrderProductId ?? baseSourceId,
					weight: content.weight ?? 0,
					// this will most likely be estimated but
					// in case we've transfered an already weighted product it will be false (real weight)
					estimated: content.estimated ?? false,
				};

				newValues[content.variantId ?? -1] = {
					oldValues: value,
					newValues: [value, remainingValue],
				};
			});
		return newValues;
	};

	const toggleLeftovers = (idx?: number) => {
		const parsedIdx = Number(idx);
		setValues((prevValues) => {
			const newValues: CloseOrderValueMap = _.cloneDeep(prevValues);
			Object.entries(newValues).forEach(([key, value]) => {
				const parsedKey = parseInt(key);
				if (isNaN(parsedKey)) {
					return;
				}
				const newValue = { ...value };
				if (!isNaN(parsedIdx)) {
					if (
						newValue.newValues[parsedIdx + 2] &&
						newValue.newValues[parsedIdx + 2].sourceId === newValue.newValues[parsedIdx].sourceId
					) {
						newValue.newValues.splice(parsedIdx + 2, 1);
					} else {
						newValue.newValues.splice(parsedIdx + 2, 0, {
							...newValue.newValues[parsedIdx],
							estimated: false,
							arrivedQty: 0,
						});
					}
				} else {
					if (newValue.newValues[2]) {
						newValue.newValues.pop();
					} else {
						newValue.newValues.push({
							...newValue.oldValues,
							arrivedQty: 0,
						});
					}
				}
			});
			return newValues;
		});
	};

	/**
	 * Toggles the weighted flag for the parts.
	 * - Index 0 is the part we cut (always weighted by definition) so we don't change it.
	 * - Index 1 is the part we leave in cheese room (it can be weighted/estimated) so we change it.
	 * - Index 2 is the leftovers (weighted by definition) so we don't change it.
	 */
	const toggleIsWeighted = () => {
		const newValue = !isWeighted;
		setIsWeighted(newValue);
		setValues((prevValues) => {
			const newValues: CloseOrderValueMap = _.cloneDeep(prevValues);
			Object.values(newValues).forEach((value) => {
				const leftPart = value.newValues[1];
				if (leftPart) {
					leftPart.estimated = !newValue;
				}
			});
			return newValues;
		});
	};

	/**
	 * Converts values from local state to context state
	 * @param {CloseOrderValueMap} values
	 */
	const setValuesInContext = (values: CloseOrderValueMap) => {
		const newValues: CloseOrderFieldValues = Object.entries(values).map(([key, value]) => ({
			orderProductId: parseInt(key),
			variantId: value.oldValues.variantId,
			warehouseId: order?.toId ?? -1,
			oldValues: {
				...value.oldValues,
				expiresAt: value.oldValues.expiresAt ? value.oldValues.expiresAt.toDate() : null,
				actualExpiration: value.oldValues.actualExpiration
					? value.oldValues.actualExpiration.toDate()
					: null,
			},
			newValues: value.newValues.map((v) => ({
				...v,
				expiresAt: v.expiresAt ? v.expiresAt.toDate() : null,
				actualExpiration: v.actualExpiration ? v.actualExpiration.toDate() : null,
			})),
		}));
		setTaskFieldValues(taskFieldId, newValues);
	};

	/**
	 * Updates the local state and context state with the new values.
	 * - Performs an optimistic update to the local state.
	 * - The local state change triggers the update of the context state with the new values.
	 * @param {number} variantId
	 * @param {CloseOrderFieldAction} field
	 * @param {Moment | null | number} value
	 * @param {number} splitIndex
	 */
	const updateField = (
		variantId: number,
		field: CloseOrderFieldAction,
		value: Moment | null | number | boolean,
		splitIndex?: number,
	) => {
		let newValues: CloseOrderValueMap = {};
		setValues((prevValues) => {
			const key = variantId ?? -1;
			const currentValue = prevValues[key] || {
				oldValues: { expiresAt: null, arrivedQty: 0, weight: 0 },
				newValues: [],
			};
			switch (field) {
				case 'delete': {
					if (splitIndex === undefined) {
						throw new Error('splitIndex is required for delete field');
					}
					const updatedValue = {
						...currentValue,
						newValues: currentValue.newValues.filter((_, i) => i !== splitIndex),
					};
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'add': {
					const oldNewValues = currentValue.newValues;
					oldNewValues.splice((splitIndex ?? 0) + 1, 0, currentValue.oldValues);
					const updatedValue = { ...currentValue, newValues: oldNewValues };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'restore': {
					const updatedValue = { ...currentValue, newValues: [currentValue.oldValues] };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				default: {
					let updatedValue = currentValue;
					if (currentValue.newValues[splitIndex ?? 0]) {
						const modifiedInsertedValue = {
							...currentValue.newValues[splitIndex ?? 0],
							[field]: value,
						};
						currentValue.newValues[splitIndex ?? 0] = modifiedInsertedValue;
						updatedValue = currentValue;
					} else {
						const newInsertedValue = { ...currentValue.oldValues, [field]: value };
						updatedValue = {
							...currentValue,
							newValues: [...currentValue.newValues, newInsertedValue],
						};
					}
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
			}
		});
	};

	/**
	 * Updates the total weight of the order.
	 * - Updates the total weight in the local state and updates the weight of the left part (the part that stays in the cheese room).
	 * @param {number} newWeight
	 */
	const updateTotalWeight = (newWeight: number) => {
		setTotalWeight(newWeight);
		setValues((prevValues: CloseOrderValueMap) => {
			const newValues: CloseOrderValueMap = {};
			Object.entries(prevValues).forEach(([key, value]) => {
				const parsedKey = parseInt(key);
				if (isNaN(parsedKey)) {
					return;
				}
				const newValue = { ...value };
				const leftWeight = newValue.newValues[0].arrivedQty;
				newValue.newValues[1].arrivedQty = newWeight - leftWeight;
				newValues[parsedKey] = newValue;
			});
			return newValues;
		});
	};

	return (
		<List>
			{order?.content
				?.filter((c) => c.variantId !== undefined && c.variantId !== null)
				.map((content, it) => {
					if (values[content.variantId ?? -1]) {
						switch (recipe.recipeType) {
							case RecipeType.division:
								return (
									<SimpleDivisionItem
										key={it}
										content={content}
										value={values[content.variantId ?? -1]}
										totalWeight={totalWeight}
										isWeighted={isWeighted}
										iterator={it}
										updateField={updateField}
										updateTotalWeight={updateTotalWeight}
										toggleWeightedFlag={toggleIsWeighted}
										toggleLeftovers={toggleLeftovers}
									/>
								);
							case RecipeType.processing:
								return <ProcessingItem />;
						}
					}
				})}
		</List>
	);
};
