import { Box, Tooltip } from '@mui/material';
import { VariantResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface TableTotalWeightBodyProps {
	variant: VariantResponse;
	estimated?: boolean;
	remainingWeight?: number;
	weight: number;
	quantity: number;
}

export const TableTotalWeightBody = <T extends TableTotalWeightBodyProps>({
	variant,
	estimated,
	remainingWeight,
	weight,
	quantity,
}: T): JSX.Element => {
	const { t } = useTranslation();

	if (!variant) return <div>0</div>;
	// const arrivedQty = rowData.orderProduct?.arrivedQty ?? 1;
	const disableTooltip = !estimated;
	const displayWeight = variant.variable ? remainingWeight ?? weight : variant.weight * quantity;

	const formattedWeight =
		displayWeight >= 1000
			? `${(displayWeight / 1000).toLocaleString('ja-JP')}kg`
			: `${displayWeight.toLocaleString('ja-JP')}g`;

	return (
		<Tooltip
			title={t('inventory.estimatedWeight')}
			disableFocusListener={disableTooltip}
			disableHoverListener={disableTooltip}
			disableTouchListener={disableTooltip}
			placement='top'
		>
			<Box
				sx={{
					position: 'relative',
					cursor: estimated ? 'help' : 'default',
					'&:after': estimated
						? {
								content: '"*"',
								position: 'absolute',
								top: 0,
								right: '-0.5rem',
								display: 'flex',
						  }
						: {},
				}}
			>
				{formattedWeight}
			</Box>
		</Tooltip>
	);
};
