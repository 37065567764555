import { useTask } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useOrders from '@hooks/useOrders';
import useTasks from '@hooks/useTasks';
import useUsers from '@hooks/useUsers';
import { Autocomplete, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { AppFunction, CarrierType, ShippingType, TranslationTypes } from 'common';
import { useEffect, useState } from 'react';
import CarrierInputField from './CarrierInputField';
import { CarrierCodeField } from './CarrierCodeField';
import {
	SelectVesselFieldValue,
	SelectVesselFieldValues,
} from '@contexts/taskContext/config/selectvesselFieldConfig';

interface Props {
	taskFieldId: number;
	orderId: number;
}

export const SelectVesselField = ({ taskFieldId, orderId }: Props) => {
	const { orderList } = useOrders();
	const { taskFieldArchetypeList } = useTasks();
	const { getTranslatedString } = useLocale();
	const { cachedValues, setTaskFieldValues, getTaskFieldValues } = useTask();
	const { userList } = useUsers();

	const [init, setInit] = useState(false);
	const [values, setValues] = useState<SelectVesselFieldValues>([{ id: null, code: '' }]);

	const order = orderList.find((order) => order.id === orderId);
	const archetype = taskFieldArchetypeList.find((archetype) => archetype.id === taskFieldId);

	useEffect(() => {
		const stubData = getTaskFieldValues<SelectVesselFieldValue>(taskFieldId);
		if (stubData.length > 0 && !init) {
			setInit(true);
			setValues(stubData);
			setTaskFieldValues(taskFieldId, stubData);
		}
	}, [cachedValues]);

	useEffect(() => {
		if (order && !init && order.carrierId) {
			console.log(order);
			const values = [{ id: +order.carrierId, code: '' }];
			setValues(values);
			setTaskFieldValues(taskFieldId, values);
		}
	}, [order]);

	const inputField = () => {
		if (!order || !archetype) return <></>;
		switch (order.shippingType) {
			case ShippingType.externalCarrier:
				return (
					<>
						<CarrierInputField
							type={CarrierType.external}
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
						<CarrierCodeField
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
					</>
				);
			case ShippingType.internalCarrier:
				return (
					<>
						<CarrierInputField
							type={CarrierType.internal}
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
						<CarrierCodeField
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
					</>
				);
			case ShippingType.handDelivered:
				return (
					<>
						<Autocomplete
							id='hand-delivery-auto'
							size='small'
							options={userList}
							getOptionLabel={(option) => `${option.person?.name} ${option.person?.surname}`}
							renderOption={(props, option) => (
								<MenuItem {...props} key={option.id}>
									`${option.person?.name} ${option.person?.surname}`
								</MenuItem>
							)}
							renderInput={(params) => (
								<TextField {...params} label='Carrier' variant='outlined' fullWidth />
							)}
							onChange={(_, newValue) => {
								setValues([{ ...values[0], id: newValue?.id ?? null }]);
								setTaskFieldValues(taskFieldId, [{ ...values[0], id: newValue?.id }]);
							}}
							disablePortal
							sx={{ marginBottom: '1rem' }}
						/>
					</>
				);
			case ShippingType.air:
			case ShippingType.sea:
				return (
					<CarrierCodeField
						taskFieldId={taskFieldId}
						values={values[0]}
						setValues={(values) => setValues([values])}
					/>
				);
			default:
				return (
					<>
						<CarrierInputField
							type={[CarrierType.external, CarrierType.internal]}
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
						<CarrierCodeField
							taskFieldId={taskFieldId}
							values={values[0]}
							setValues={(values) => setValues([values])}
						/>
					</>
				);
		}
	};

	if (!order || !archetype) return null;

	return (
		<Grid container rowSpacing={2} p='1rem'>
			<Grid item xs={12}>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{inputField()}
			</Grid>
		</Grid>
	);
};
