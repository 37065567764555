import { GenericIdsRequest } from 'common';
import API from '../index';

export interface Response {
	message: string;
	id: number[] | number;
}

export const execute = async (params: GenericIdsRequest): Promise<Response> => {
	// Axios requires the data to be wrapped inside an object called data.
	// we use an object and not the id in the address because we need to perform a bulk delete.
	const response = await API.delete('/api/v1/inventory/variants/buyingprices', { data: params });
	if (response.status < 200 || response.status > 300) {
		throw new Error(response.data);
	}
	const data = (await response.data) as Response;
	return data;
};
