import { EditShowGridField } from '@components/common/EditShowGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useLocale from '@hooks/useLocale';
import useMerchants from '@hooks/useMerchants';
import {
	Autocomplete,
	FormControlLabel,
	FormGroup,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import {
	AppFunction,
	MerchantType,
	ProductResponse,
	ProductType,
	StorageConditionType,
	TaxBracket,
	TranslationTypes,
} from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control, formAction, register, subFunction } = useGenericForm<ProductResponse>();
	const { merchantList } = useMerchants();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const [merchants, setMerchants] = useState(
		merchantList.filter((m) => m.type !== MerchantType.seller),
	);

	useEffect(() => {
		const tempMerchants = merchantList.filter((m) => m.type !== MerchantType.seller);
		setMerchants((oldMerchants) => {
			if (!_.isEqual(oldMerchants, tempMerchants)) {
				return tempMerchants;
			}
			return oldMerchants;
		});
	}, [merchantList]);

	const editDisabled = formAction === 'view';

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant='overline' display='block' gutterBottom sx={{ fontSize: '0.9em' }}>
					{t('product.info')}
				</Typography>
			</Grid>
			{subFunction === ProductType.product && (
				<Controller
					name='isFresh'
					control={control}
					render={({ field }) => (
						<EditShowGridField width={6} isEditing={!editDisabled} label>
							<FormGroup sx={{ paddingLeft: 1 }}>
								<FormControlLabel
									control={
										<Switch
											{...field}
											checked={field.value ?? false}
											onChange={(ev) => field.onChange(ev.target.checked)}
										/>
									}
									label={t('product.isFresh')}
								/>
							</FormGroup>
						</EditShowGridField>
					)}
				/>
			)}
			{subFunction === ProductType.product && (
				<Controller
					name='shelfLife'
					control={control}
					render={({ field }) => (
						<InputGridField width={6} type={ComponentType.TextField}>
							<TextField
								variant='outlined'
								required
								type='number'
								size='small'
								inputProps={{ min: 0, max: 730 }}
								fullWidth
								disabled={editDisabled}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{t('units.day', { count: field.value })}
										</InputAdornment>
									),
								}}
								label={t('product.shelf_life')}
								id='shelf-life'
								value={field.value}
								onChange={(ev) => {
									const parsedValue = parseInt(ev.target.value);
									console.log(parsedValue);
									field.onChange(isNaN(parsedValue) ? 0 : parsedValue);
								}}
							/>
						</InputGridField>
					)}
				/>
			)}
			{subFunction === ProductType.product && (
				<Controller
					name='storageType'
					control={control}
					render={({ field }) => (
						<InputGridField
							width={6}
							muiLabel={{
								labelId: 'storage-type',
								label: t('product.storageType'),
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='storage-type'
								id='storage-type'
								fullWidth
								size='small'
								label={t('product.storageType')}
								value={field.value}
								disabled={editDisabled}
								onChange={(event) => field.onChange(event.target.value as StorageConditionType)}
							>
								{Object.values(StorageConditionType).map((value, it) => (
									<MenuItem key={it} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</InputGridField>
					)}
				/>
			)}
			<Controller
				name='merchantId'
				control={control}
				render={({ field }) => {
					// if (field.value === undefined) {
					//     return <></>;
					// }
					return (
						<InputGridField width={6} type={ComponentType.Autocomplete}>
							<Autocomplete
								id='product-merchant-id'
								options={merchants}
								value={merchants.find((m) => m.id === field.value) ?? null}
								size='small'
								disabled={editDisabled}
								getOptionLabel={(option) => {
									const merchantName = getTranslatedString(
										AppFunction.Merchant,
										option.id,
										TranslationTypes.name,
									);
									return option ? merchantName : 'none';
								}}
								isOptionEqualToValue={(option, value) => option?.id === value?.id}
								renderOption={(props, option, { selected }) => {
									let merchantName = 'none';
									if (option) {
										merchantName = getTranslatedString(
											AppFunction.Merchant,
											option.id,
											TranslationTypes.name,
										);
									}
									return (
										<MenuItem {...props} selected={selected}>
											{merchantName}
										</MenuItem>
									);
								}}
								renderInput={(params) => <TextField {...params} label={t('product.merchant')} />}
								onChange={(_, value) => {
									field.onChange(value ? +value.id : -1);
								}}
							/>
						</InputGridField>
					);
				}}
			/>
			<Controller
				name='taxBracket'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={6}
						type={ComponentType.Select}
						muiLabel={{
							labelId: 'sale-vat-label',
							label: t('product.saleVat'),
						}}
					>
						<Select
							labelId='sale-vat-label'
							id='sale-vat'
							size='small'
							fullWidth
							disabled={editDisabled}
							value={field.value ?? TaxBracket.eight}
							label={t('product.saleVat')}
							onChange={(event) => field.onChange(event.target.value as TaxBracket)}
						>
							{Object.keys(TaxBracket).map((tb, it) => {
								const enumValue = TaxBracket[tb as keyof typeof TaxBracket];
								return (
									<MenuItem value={enumValue} key={it}>
										{enumValue}
									</MenuItem>
								);
							})}
						</Select>
					</InputGridField>
				)}
			/>
			{subFunction === ProductType.product && (
				<InputGridField width={6} type={ComponentType.TextField}>
					<TextField
						variant='outlined'
						required
						size='small'
						fullWidth
						disabled={editDisabled}
						label={t('product.tax_code')}
						id='tax_code'
						{...register('taxCode')}
					/>
				</InputGridField>
			)}
		</Grid>
	);
};
