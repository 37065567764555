import { useTranslation } from 'react-i18next';
import { useOrderEditor } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import useMerchants from '@hooks/useMerchants';
import useWarehouses from '@hooks/useWarehouses';
import { Autocomplete, Chip, Grid, IconButton, TextField, useTheme } from '@mui/material';
import {
	AppFunction,
	CustomerBranchesResponse,
	CustomersResponse,
	MerchantsResponse,
	OrderDirectionType,
	ShippingType,
	StorageType,
	TranslationTypes,
	WarehousesResponse,
} from 'common';

import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';

import { Delete } from '@mui/icons-material';
import { InboundSubOrderTimeScheduler } from '@components/orders/header/timescheduler/InboundSubOrderTimeScheduler';

type OptionsType =
	| WarehousesResponse
	| MerchantsResponse
	| CustomersResponse
	| CustomerBranchesResponse;

export const InboundSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { orderType, subOrders, config, configValues, setSuborderProperties, removeSubOrder } =
		useOrderEditor();

	const { t } = useTranslation();
	const theme = useTheme();

	const { warehouseList } = useWarehouses();
	const { merchantList } = useMerchants();
	const { customerList } = useCustomers();
	const { getIcon } = useAppFunctions();
	const { getTranslatedString } = useLocale();

	if (!config) return null;

	const getChip = (id?: number) => {
		if (id === -1) {
			return <Chip size='small' label={t('common.none')} color='error' />;
		}

		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse: {
				const wh = warehouseList.find((w) => w.id === id);
				if (wh) {
					return (
						<Chip
							icon={getIcon(AppFunction.Location, wh.storageType) ?? <></>}
							label={getTranslatedString(AppFunction.Location, wh.id, TranslationTypes.name)}
							size='small'
						/>
					);
				}
				return '';
			}
			case OrderDirectionType.merchant: {
				const mr = merchantList.find((m) => m.id === id);
				if (mr) {
					return (
						<Chip
							icon={getIcon(AppFunction.Merchant) ?? <></>}
							label={getTranslatedString(AppFunction.Merchant, mr.id, TranslationTypes.name)}
							size='small'
						/>
					);
				}
				return '';
			}
			case OrderDirectionType.customer: {
				const cs = customerList.find((c) => c.id === id);
				if (cs && cs.id) {
					return (
						<Chip
							icon={getIcon(AppFunction.Customer) ?? <></>}
							label={getTranslatedString(AppFunction.Customer, +cs.id, TranslationTypes.name)}
							size='small'
						/>
					);
				}
				return '';
			}
			case OrderDirectionType.branch: {
				const br = customerList.find((c) => c.customerBranches?.some((cb) => cb.id === id));
				if (br && id && br.id) {
					const customerName = getTranslatedString(
						AppFunction.Customer,
						br.id,
						TranslationTypes.name,
					);
					const branchName = getTranslatedString(
						AppFunction.CustomerBranch,
						id,
						TranslationTypes.name,
					);
					return (
						<Chip
							icon={getIcon(AppFunction.CustomerBranch) ?? <></>}
							label={`[${customerName}] ${branchName}`}
							size='small'
						/>
					);
				}
				return '';
			}
			default:
				return '';
		}
	};

	/**
	 * Fetches the name of the direction based on the index
	 * @param {number} index - the index of the direction
	 * @returns {string} - the name of the direction
	 */
	const getName = (index: number): string => {
		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse:
				return getTranslatedString(AppFunction.Location, index, TranslationTypes.name);
			case OrderDirectionType.merchant:
				return getTranslatedString(AppFunction.Merchant, index, TranslationTypes.name);
			case OrderDirectionType.customer:
				return getTranslatedString(AppFunction.Customer, index, TranslationTypes.name);
			case OrderDirectionType.branch: {
				const customer = customerList.find((c) =>
					c.customerBranches?.some((cb) => cb.id === index),
				);
				const customerName = getTranslatedString(
					AppFunction.Customer,
					customer?.id ?? -1,
					TranslationTypes.name,
				);
				const branchName = getTranslatedString(
					AppFunction.CustomerBranch,
					index,
					TranslationTypes.name,
				);
				return `[${customerName}] ${branchName}`;
			}
			default:
				return '';
		}
	};

	/**
	 * Fetches the available options for the order direction
	 * based on the current suborder's toType
	 * Unavailable toIds are filtered out.
	 * @returns {OptionsType} Available dropdown options
	 */
	const getOptions = (): OptionsType => {
		/**
		 * Fetches the unavailable toIds for the current suborder
		 * from the suborders list. Already selected toIds are not available.
		 * @returns {number[]} the unavailable toIds
		 */
		const unavailableToIds = subOrders.reduce((acc: number[], soc) => {
			if (soc.toType === currentSubOrder?.toType && soc.toId !== currentSubOrder?.toId) {
				acc.push(Number(soc.toId));
			}
			return acc;
		}, []);
		switch (currentSubOrder?.toType) {
			case OrderDirectionType.warehouse:
				return warehouseList.filter(
					(w) =>
						w.storageType !== StorageType.port &&
						w.storageType !== StorageType.airport &&
						!unavailableToIds.includes(w.id),
				);
			case OrderDirectionType.merchant:
				return merchantList.filter((m) => !unavailableToIds.includes(m.id));
			case OrderDirectionType.customer:
				return customerList.filter((c) => c.id && !unavailableToIds.includes(c.id));
			case OrderDirectionType.branch:
				return customerList
					.map((c) => c.customerBranches ?? [])
					.flat()
					.filter((cb) => !unavailableToIds.includes(cb.id));
			default:
				return [];
		}
	};

	/**
	 * Fetches the available directions for this suborder from configValues
	 * @returns {OrderDirectionType[]} - the available directions for this suborder
	 */
	const availableDirections = (): OrderDirectionType[] => {
		if (configValues?.subOrderTypes?.isValueOfType<OrderDirectionType[]>()) {
			return configValues.subOrderTypes.value;
		}
		return [] as OrderDirectionType[];
	};

	/**
	 * Fetches the current suborder from the suborders list
	 */
	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	/**
	 * Suborder Header
	 * @returns {JSX.Element} - the header of the suborder
	 */
	const header = (): JSX.Element => {
		return (
			<Grid item container columnSpacing={2} rowGap={2} display='flex'>
				{availableDirections().length > 1 && (
					<Grid item flexGrow={1}>
						<Autocomplete
							options={Object.values(OrderDirectionType).filter((odt) =>
								availableDirections().includes(odt),
							)}
							value={currentSubOrder?.toType ?? availableDirections()[0]}
							size='small'
							onChange={(_, newValue) => {
								setSuborderProperties({
									subOrderCode: subOrderCode,
									toType: newValue ?? OrderDirectionType.warehouse,
									toId: -1,
								});
							}}
							renderInput={(params) => (
								<TextField {...params} label={t('order.toType')} variant='outlined' />
							)}
							getOptionLabel={(option) => t(`order.to.${option.toLowerCase()}`)}
						/>
					</Grid>
				)}
				<Grid item flexGrow={1}>
					<Autocomplete
						options={['-1', ...getOptions().map((o) => o.id?.toString())]}
						getOptionLabel={(option) =>
							option === '-1' || option === undefined ? 'None' : getName(parseInt(option))
						}
						value={currentSubOrder?.toId?.toString() ?? '-1'}
						size='small'
						onChange={(_, newValue) => {
							const newDirectionId = newValue ? parseInt(newValue) : -1;
							setSuborderProperties({
								subOrderCode: subOrderCode,
								toId: newDirectionId,
							});
						}}
						renderOption={(props, option) => (
							<li {...props}>{getChip(option ? parseInt(option) : -1)}</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`order.to.${currentSubOrder?.toType?.toLowerCase()}`)}
								variant='outlined'
							/>
						)}
					/>
				</Grid>
				<Grid item flexGrow={1}>
					<Autocomplete
						options={[
							ShippingType.notdefined,
							ShippingType.externalCarrier,
							ShippingType.internalCarrier,
							ShippingType.handDelivered,
						]}
						value={currentSubOrder?.shippingType ?? 'none'}
						size='small'
						onChange={(_, newValue) => {
							setSuborderProperties({
								subOrderCode: subOrderCode,
								shippingType: newValue !== 'none' ? (newValue as ShippingType) : undefined,
							});
						}}
						renderInput={(params) => (
							<TextField {...params} label={t('order.shippingType')} variant='outlined' />
						)}
						getOptionLabel={(option) =>
							option === 'none' || option === undefined
								? t('order.shippingTypes.undefined')
								: t(`order.shippingTypes.${option.toLowerCase()}`)
						}
						renderOption={(props, option) => (
							<li {...props}>
								{option === 'none' ? (
									t('order.shippingTypes.undefined')
								) : (
									<Chip
										label={t(`order.shippingTypes.${option.toLowerCase()}`)}
										size='small'
										icon={getIcon(AppFunction.Carrier, option as ShippingType) ?? <></>}
									/>
								)}
							</li>
						)}
					/>
				</Grid>
				<InboundSubOrderTimeScheduler currentSubOrder={currentSubOrder} />
			</Grid>
		);
	};

	return (
		<Grid
			item
			container
			sx={{
				margin: '1rem 0 1rem 0',
				padding: '1rem',
				borderBottom: subOrders.length > 1 ? `2px solid ${theme.palette.primary.main}` : '',
				height: 'fit-content',
				width: '100%',
			}}
			spacing={2}
			display='flex'
		>
			<Grid item container flexGrow={1} flexBasis={0} rowGap={2}>
				{header()}
				{currentSubOrder?.toId !== -1 && orderType !== null && config.subOrderProductsContainer && (
					<config.subOrderProductsContainer
						key={`${subOrderCode}-${orderType.toLowerCase()}`}
						subOrder={currentSubOrder}
					/>
				)}
			</Grid>
			<Grid
				item
				flexShrink={1}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<IconButton
					onClick={() => removeSubOrder(subOrderCode)}
					sx={{
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						transform: 'scale(1)',
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};
