import { EditShowGridField } from '@components/common/EditShowGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useAuth } from '@hooks/auth';
import { Grid, TextField } from '@mui/material';
import { UserEditRequest } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Roles } from './Roles';
import { Workplaces } from './Workplaces';
import { Person } from './Person';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const GeneralInfo = () => {
	const { entityId, control, errors, formAction } = useGenericForm<UserEditRequest>();
	const { t } = useTranslation();
	const auth = useAuth();

	const editDisabled = formAction === 'view';

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title={t('user.userInformation')} size='small' />
			</Grid>
			<Controller
				name='username'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='user-username'
							label={t('user.username')}
							size='small'
							fullWidth
							error={!!errors.username}
							disabled={editDisabled}
							// helperText={errors.name && errors.name.message}
							value={field.value ?? ''}
							onChange={(ev) => field.onChange(ev.target.value ?? '')}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='userNumber'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='user-userNumber'
							label={t('user.userNumber')}
							size='small'
							fullWidth
							disabled={editDisabled}
							// error={!!errors.name}
							// helperText={errors.name && errors.name.message}
							value={field.value ?? ''}
							onChange={(ev) => field.onChange(ev.target.value ?? '')}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='email'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='user-email'
							label={t('user.email')}
							size='small'
							fullWidth
							disabled={editDisabled}
							error={!!errors.email}
							helperText={errors.email && errors.email.message}
							value={field.value ?? ''}
							onChange={(ev) => field.onChange(ev.target.value ?? '')}
						/>
					</InputGridField>
				)}
			/>
			{(entityId === -1 || entityId === auth.user?.id) && !editDisabled && (
				<>
					<Controller
						name='password'
						control={control}
						render={({ field }) => (
							<InputGridField width={6} type={ComponentType.TextField}>
								<TextField
									variant='outlined'
									id='user-password'
									type='password'
									label={t('user.password')}
									fullWidth
									size='small'
									// error={!!errors.name}
									// helperText={errors.name && errors.name.message}
									value={field.value ?? ''}
									onChange={(ev) => field.onChange(ev.target.value ?? '')}
								/>
							</InputGridField>
						)}
					/>
					<Controller
						name='password_repeat'
						control={control}
						render={({ field }) => (
							<InputGridField width={6} type={ComponentType.TextField}>
								<TextField
									variant='outlined'
									id='user-password-repeat'
									type='password'
									label={t('user.confirmPassword')}
									fullWidth
									size='small'
									// error={!!errors.name}
									// helperText={errors.name && errors.name.message}
									value={field.value ?? ''}
									onChange={(ev) => field.onChange(ev.target.value ?? '')}
								/>
							</InputGridField>
						)}
					/>
					{entityId !== -1 && (
						<Controller
							name='newPassword'
							control={control}
							render={({ field }) => (
								<InputGridField width={12} type={ComponentType.TextField}>
									<TextField
										variant='outlined'
										id='user-new-password'
										type='password'
										label={t('user.newPassword')}
										fullWidth
										size='small'
										// error={!!errors.name}
										// helperText={errors.name && errors.name.message}
										value={field.value ?? ''}
										onChange={(ev) => field.onChange(ev.target.value ?? '')}
									/>
								</InputGridField>
							)}
						/>
					)}
				</>
			)}
			<Person />
			<Roles />
			<Workplaces />
		</Grid>
	);
};
