import { useGenericForm } from '@contexts/formContext/FormContext';
import useGeography from '@hooks/useGeography';
import useLocale from '@hooks/useLocale';
import { Cancel, Check, Delete } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import {
	AppFunction,
	CarrierResponse,
	CarrierType,
	RegionResponse,
	TranslationTypes,
} from 'common';
import { useState } from 'react';

interface Props {
	region?: RegionResponse;
	ngAreas: number[];
	onStopCreate: () => void;
	onAddRegion: (id: number) => void;
	onRemoveRegion: (id: number) => void;
}

export const DeliveryRegion = ({
	region,
	ngAreas,
	onStopCreate,
	onAddRegion,
	onRemoveRegion,
}: Props) => {
	const { getTranslatedString } = useLocale();
	const { getValues } = useGenericForm<CarrierResponse>();
	const { regionList } = useGeography();
	const [addingRegionId, setAddingRegionId] = useState<number | null>(null);

	if (region) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						paddingLeft: '0.5rem',
						flexGrow: 1,
						flexBasis: 0,
					}}
				>
					{getTranslatedString(AppFunction.Region, region.id, TranslationTypes.name)}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexShrink: 1,
					}}
				>
					<IconButton aria-label='delete' color='error' onClick={() => onRemoveRegion(region.id)}>
						<Delete />
					</IconButton>
				</div>
			</Box>
		);
	}

	const carrierType = getValues('carrierType');

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						flexGrow: 1,
						flexBasis: 0,
					}}
				>
					<Autocomplete
						options={regionList.filter((r) => !ngAreas?.includes(r.id))}
						size='small'
						value={regionList.find((r) => r.id === addingRegionId) ?? null}
						getOptionLabel={(option) =>
							getTranslatedString(AppFunction.Region, option.id, TranslationTypes.name)
						}
						renderInput={(params) => <TextField {...params} />}
						onChange={(_, value) => setAddingRegionId(value?.id ?? null)}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexShrink: 1,
					}}
				>
					<IconButton
						aria-label='delete'
						color='success'
						onClick={() => onAddRegion(addingRegionId ?? -1)}
					>
						<Check />
					</IconButton>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexShrink: 1,
					}}
				>
					<IconButton aria-label='delete' color='error' onClick={onStopCreate}>
						<Cancel />
					</IconButton>
				</div>
			</Box>
			{addingRegionId && carrierType === CarrierType.internal && (
				<Box
					style={{
						marginTop: '0.5rem',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Autocomplete
						fullWidth
						multiple
						options={
							[
								{
									id: -1,
									countryId: -1,
									regionTypeId: -1,
									parentId: addingRegionId,
								},
								...regionList.filter((r) => r.id === addingRegionId).flatMap((sr) => sr.subRegions),
							] ?? []
						}
						size='small'
						getOptionLabel={(option) => {
							if (option?.id && option?.id !== -1) {
								return getTranslatedString(AppFunction.Region, option.id, TranslationTypes.name);
							}
							return '全て';
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
				</Box>
			)}
		</Box>
	);
};
