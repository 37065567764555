import { useOrderEditor } from '@contexts/index';
import useProducts from '@hooks/useProducts';
import { Blender, Edit } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { AppFunction, OrderDirectionType, OrderType, RecipeType } from 'common';
import { useMemo, useState } from 'react';
import useInventory from '@hooks/useInventory';
import { useTranslation } from 'react-i18next';
import useCustomers from '@hooks/useCustomers';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { OrderProductRowWrapper } from '../../common/OrderProductRowWrapper';
import InventoryProductAutocomplete from '@components/common/inputs/products/InventoryProductAutocomplete';
import {
	EnhancedOrderProduct,
	EnhancedVariant,
	EnhancedVariants,
} from '@contexts/orderEditorContext/types';
import InventoryProductPrice from '@components/common/inputs/products/OrderProductPrice';
import OrderProductQuantity from '@components/common/inputs/products/OrderProductQuantity';
import { DatePicker } from '@mui/x-date-pickers';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import useOrders from '@hooks/useOrders';
import _ from 'lodash';
import moment from 'moment';

interface Props {
	index: number;
	data: EnhancedOrderProduct;
	subOrderCode: string;
	unavailableVariantIds: number[];
}

export const OutboundSuborderProduct = ({
	index,
	data,
	subOrderCode,
	unavailableVariantIds,
}: Props) => {
	const { getValues, changeOrderProduct } = useOrderEditor();

	const { customerList } = useCustomers();
	const { inventories, findInventoryEntryById } = useInventory();
	const { variantList } = useProducts();
	const { orderList } = useOrders();
	const { t } = useTranslation();

	const [useCustomPrice, setUseCustomPrice] = useState(false);
	// TODO: implement this (used to reset values when the variant changes)
	// const [cachedVariantId, setCachedVariantId] = useState<number | null>(null);

	const fromType = getValues('fromType');
	const fromId = getValues('fromId');

	/**
	 * Available variants for the current suborder
	 * If a warehouse as fromType is selected we show only the variants that are available in that warehouse
	 * If fromType is null we show all the products in all the inventories
	 */
	const availableItems = useMemo((): EnhancedVariants => {
		const tempItems: EnhancedVariants = [];
		if (!fromType || fromType !== OrderDirectionType.warehouse) return [];

		if (!fromId) {
			// All the available options variant id set
			const availableSet = new Set<number>();
			Object.values(inventories)
				.flat()
				.forEach((inventoryItem) => {
					availableSet.add(inventoryItem.variantId);
					const orderProductId = inventoryItem.orderProduct?.id
						? (inventoryItem.orderProduct?.id as number)
						: -1;
					const push =
						inventoryItem.quantity > 0 &&
						(!unavailableVariantIds.includes(inventoryItem.variantId) ||
							inventoryItem.variantId === data.variantId);
					if (push && inventoryItem.variant && orderProductId) {
						tempItems.push({
							...inventoryItem.variant,
							expiresAt: inventoryItem.expiresAt,
							orderProductId: orderProductId,
							maxAmount: inventoryItem.quantity,
							warehouseId: inventoryItem.warehouseId,
							sellUnitWeight: inventoryItem.unit === 'grams' ? 1 : 0,
							remainingWeight: inventoryItem.remainingWeight,
							weight: inventoryItem.weight,
							unit: inventoryItem.unit,
							stateId: inventoryItem.stateId,
						});
					}
				});

			const bookableOptions: EnhancedVariants = [];
			// All the unavailable options (will be deemed out of stock but bookable)
			const unavailableOptions: EnhancedVariants = [];
			variantList
				.filter((vr) => !availableSet.has(vr.id) && vr.currentlySold)
				.forEach((vr) => {
					const relatedImportOrders = orderList.filter((o) => {
						if (o.orderType === OrderType.Inbound) {
							return o.content?.some((c) => c.variantId === vr.id);
						}
					});
					relatedImportOrders.map((o) => {
						const orderProduct = o.content?.find((c) => c.variantId === vr.id);
						if (orderProduct) {
							const ead = o.ead ? new Date(o.ead) : null;
							bookableOptions.push({
								...vr,
								ead: ead,
								orderProductId: orderProduct.id ? +orderProduct.id : -1,
								maxAmount: orderProduct.orderQty,
								sellUnitWeight: vr.sellUnitWeight ?? 1,
							});
						}
					});
					unavailableOptions.push({
						...vr,
						maxAmount: 0,
						sellUnitWeight: vr.sellUnitWeight ?? 1,
					});
				});
			tempItems.push(...bookableOptions);
			tempItems.push(...unavailableOptions);
		} else {
			// TODO: scrap this, define the inventories from which to take the data in an if statement
			// and run the code above
			// also we should enforce the "include descendant warehouses" thingy (so that every descendant of a main storage - to be renamed)
			// is counted
			inventories[fromId]?.forEach((inVr) => {
				const orderProductId = inVr.orderProduct?.id ? (inVr.orderProduct?.id as number) : -1;
				const push =
					inVr.quantity > 0 &&
					(!unavailableVariantIds.includes(inVr.variantId) || inVr.variantId === data.variantId);
				if (push && inVr.variant && orderProductId) {
					tempItems.push({
						...inVr.variant,
						expiresAt: inVr.expiresAt,
						orderProductId: orderProductId,
						maxAmount: inVr.quantity,
						sellUnitWeight: inVr.unit === 'grams' ? 1 : 0,
					});
				}
			});
		}

		// setLoaded(true);
		return tempItems;
	}, [unavailableVariantIds, inventories, fromType, fromId, orderList]);

	/**
	 * The selected value of the product
	 * - If the fromType is null we return null
	 * - If the fromType is a warehouse we return the selected value
	 * - If the product is not in the inventory we return null
	 */
	const selectedValue = useMemo((): EnhancedVariant | null => {
		if (!fromType) return null;
		let foundItem: EnhancedVariant | null = null;
		if (fromType !== OrderDirectionType.warehouse) {
			return null;
		}
		if (data.variantId === -1) {
			return null;
		}

		if (!data.expiresAt) {
			if (data.ead) {
				const against = new Date(data.ead);
				foundItem =
					availableItems.find(
						(item) =>
							item.id &&
							data.variantId &&
							item.sellUnitWeight === (data.unit === 'grams' ? 1 : 0) &&
							_.isEqual(item.ead, against),
					) ?? null;
			} else {
				/** comparing items that are not in the inventory. for booked orders */
				foundItem =
					availableItems.find(
						(item) =>
							item.id === data.variantId &&
							item.sellUnitWeight === (data.unit === 'grams' ? 1 : 0) &&
							!item.ead,
					) ?? null;
			}
		} else {
			foundItem =
				availableItems.find(
					(item) =>
						item.orderProductId === data.sourceId && item.remainingWeight === data.remainingWeight,
				) ?? null;
		}
		return foundItem;
	}, [variantList, fromType, data, availableItems]);

	/**
	 * The price of the product
	 * - The shown price doesn't take account of the order quantity
	 * - If a customer price is found we show that price, otherwise we show the customer type price
	 * - If no customer price or customer type price is found we show the default price
	 */
	const getPriceValue = () => {
		if (!selectedValue) return 0;
		if (!selectedValue.sellUnitWeight) return 0;

		const customerId = getValues('toId');

		let price = selectedValue?.sellPrice ?? 0;
		const customerPrice = selectedValue?.sellingPriceCustomer?.find(
			(x) => x.customerId === customerId,
		);
		// TODO: come back and add the temporary price when implemented
		if (customerPrice) {
			price = customerPrice.price;
		} else {
			const sellingTypePrice = selectedValue?.sellingPriceType?.find(
				(x) => x.typeId === customerList.find((x) => x.id === customerId)?.typeId,
			);
			price = sellingTypePrice ? sellingTypePrice.price : price;
		}

		return price;
	};

	/**
	 * Switches between the custom price and the default price
	 * - If the product is processed or we're switching to a custom price
	 * we divide the price by the weight of the product and assign it to the actual price
	 * - If the product is not processed we assign the price to the actual price
	 */
	const switchCustomPrice = () => {
		setUseCustomPrice((oldValue) => {
			const newValue = !oldValue;
			changeOrderProduct(
				{
					actualPrice:
						newValue || data.process
							? getPriceValue() / (selectedValue?.weight ?? 0)
							: getPriceValue(),
				},
				subOrderCode,
				index,
			);

			return newValue;
		});
	};

	/**
	 * Switches between the processed and unprocessed state of the product
	 * - If the product is processed we divide the price by the weight of the product and assign it to the actual price
	 * - If the product is not processed we assign the price to the actual price
	 */
	const switchProcessing = () => {
		const newValue = !data.process;
		changeOrderProduct(
			{
				process: newValue,
				actualPrice: newValue ? getPriceValue() / (selectedValue?.weight ?? 1) : getPriceValue(),
			},
			subOrderCode,
			index,
		);
	};

	/**
	 * Changes the value of the selected order product
	 *
	 * @param {EnhancedVariant | null} newValue - The new value of the product
	 */
	const changeOrderProductValue = (newValue: EnhancedVariant | null) => {
		if (typeof newValue === 'object' && newValue !== null) {
			const expiresAt = newValue.expiresAt ?? null;
			changeOrderProduct(
				{
					stateId: newValue.stateId,
					variantId: newValue.id,
					expiresAt: expiresAt,
					unit: newValue.sellUnitWeight === 0 ? 'pieces' : 'grams',
					sourceId: newValue.orderProductId,
					weight: newValue.weight,
					pickedWeight: selectedValue?.remainingWeight
						? selectedValue.remainingWeight / (selectedValue.maxAmount ?? 1)
						: undefined,
					booked: !expiresAt,
					basePrice: getPriceValue(),
					remainingWeight: newValue.remainingWeight,
					ead: newValue.ead,
				},
				subOrderCode,
				index,
			);
		} else {
			changeOrderProduct({ variantId: -1 }, subOrderCode, index);
		}
	};

	/**
	 * Changes the quantity of the selected order product
	 * - If the product is processed we change the picked weight
	 * - If the product is not processed we change the order quantity
	 * @param {number | null} value - The new quantity of the product
	 */
	const changeOrderProductQuantity = (value: number | null) => {
		if (!selectedValue) return;
		if (data.process) {
			changeOrderProduct(
				{
					pickedWeight: value,
				},
				subOrderCode,
				index,
			);
		} else {
			const rawQuantity = value;
			let weight = selectedValue.weight;
			let pickedWeight = undefined;
			if (selectedValue.variable && data.sourceId) {
				const source = findInventoryEntryById(+data.sourceId);
				weight = source?.weight ?? selectedValue.weight;
				if (source?.remainingWeight) {
					pickedWeight = (rawQuantity ?? 1) * (source.remainingWeight / source.quantity);
				} else if (source?.weight) {
					pickedWeight = (rawQuantity ?? 1) * (source.weight / source.quantity);
				}
			}
			changeOrderProduct(
				{
					orderQty: rawQuantity ?? 1,
					weight: weight,
					pickedWeight: pickedWeight ? Math.round(pickedWeight) : undefined,
				},
				subOrderCode,
				index,
			);
		}
	};

	/**
	 * The content of the selected product row
	 * If the selected product doesn't have an expiry date it's considered out of stock.
	 * Out of stock products receive a different treatment from the ones in stock.
	 */
	const OrderProductContent = useMemo(() => {
		return (
			<OrderProductRowWrapper
				index={index}
				subOrderCode={subOrderCode}
				data={data}
				hasValue={!!selectedValue}
				isBooked={selectedValue !== null && selectedValue !== undefined && !selectedValue.expiresAt}
				isDivided={data?.process ?? false}
				hideNotes={selectedValue ? false : true}
			>
				<Grid item flexGrow={1} flexBasis={0}>
					<InventoryProductAutocomplete
						availableItems={availableItems}
						value={selectedValue}
						showUnavailable
						onChange={changeOrderProductValue}
					/>
				</Grid>
				{selectedValue && (
					<Grid item container gap={1} flexShrink={1} display='flex'>
						<Grid item xs='auto'>
							<IconButton
								disabled={selectedValue.recipe?.recipeType !== RecipeType.division}
								color={data.process ? 'success' : 'default'}
								onClick={switchProcessing}
								component='button'
								sx={{
									transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
									transform: 'scale(1)',
									'&:hover': {
										transform: 'scale(1.1)',
									},
								}}
							>
								<Blender />
							</IconButton>
						</Grid>
						{data.process && (
							<InputGridField width={1} type={ComponentType.TextField}>
								<TextField
									id={`order-quantity-idx-${index}`}
									fullWidth
									variant='outlined'
									size='small'
									label={t(`${AppFunction.Product}.quantity`)}
									type='number'
									value={data.orderQty ?? 1}
									InputProps={{
										inputProps: {
											min: 1,
										},
										endAdornment: (
											<InputAdornment position='end'>
												{t(`${AppFunction.Product}.units.pieces`, {
													count: data.orderQty,
												})}
											</InputAdornment>
										),
									}}
									onChange={(event) => {
										const parsedValue = parseInt(event.target.value);
										changeOrderProduct(
											{
												orderQty: parsedValue,
											},
											subOrderCode,
											index,
										);
									}}
								/>
							</InputGridField>
						)}
						<OrderProductQuantity
							data={data}
							value={selectedValue}
							onChange={changeOrderProductQuantity}
						/>
						<Grid item xs='auto'>
							<IconButton
								onClick={switchCustomPrice}
								color={useCustomPrice ? 'success' : 'default'}
								sx={{
									transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
									transform: 'scale(1)',
									'&:hover': {
										transform: 'scale(1.1)',
									},
								}}
							>
								<Edit />
							</IconButton>
						</Grid>
						{selectedValue.recipe?.recipeType === RecipeType.division && data.process ? (
							<InputGridField width='auto' type={ComponentType.TextField}>
								<TextField
									id={`order-price-idx-${index}`}
									fullWidth
									variant='outlined'
									size='small'
									label={t(`${AppFunction.Product}.sellPriceSingle`)}
									type='number'
									value={useCustomPrice ? data.actualPrice : getPriceValue() / selectedValue.weight}
									disabled={!useCustomPrice}
									InputProps={{
										startAdornment: <InputAdornment position='start'>¥</InputAdornment>,
									}}
									onChange={(event) =>
										changeOrderProduct(
											{
												actualPrice: useCustomPrice
													? parseInt(event.target.value)
													: // getPriceValue() / value.weight * parseInt(event.target.value),
													  getPriceValue() / selectedValue.weight,
											},
											subOrderCode,
											index,
										)
									}
								/>
							</InputGridField>
						) : (
							<InputGridField width='auto' type={ComponentType.TextField}>
								<TextField
									id={`order-price-idx-${index}`}
									fullWidth
									variant='outlined'
									size='small'
									label={t(`${AppFunction.Product}.sellPriceSingle`)}
									type='number'
									value={useCustomPrice ? data.actualPrice : getPriceValue()}
									disabled={!useCustomPrice}
									InputProps={{
										startAdornment: <InputAdornment position='start'>¥</InputAdornment>,
									}}
									onChange={(event) =>
										changeOrderProduct(
											{
												actualPrice: useCustomPrice
													? parseInt(event.target.value)
													: getPriceValue(),
											},
											subOrderCode,
											index,
										)
									}
								/>
							</InputGridField>
						)}
						<Grid
							item
							xs={1}
							display='flex'
							alignItems='center'
							justifyContent='flex-end'
							justifySelf='flex-end'
						>
							<InventoryProductPrice data={data} useCustomPrice={useCustomPrice} />
						</Grid>
						{data.booked && (
							<Grid item justifySelf='flex-end'>
								<DatePicker
									value={data.bookedDeliveryDate}
									onChange={(date) => {
										let jsDate: Date | null = null;
										if (date && moment.isMoment(date)) {
											jsDate = date.toDate();
										}
										return changeOrderProduct(
											{
												bookedDeliveryDate: jsDate,
											},
											subOrderCode,
											index,
										);
									}}
									renderInput={(params) => <TextField size='small' {...params} />}
									minDate={data.ead ? moment(data.ead) : moment()}
								/>
							</Grid>
						)}
					</Grid>
				)}
			</OrderProductRowWrapper>
		);
	}, [variantList, data, availableItems, fromId, data.process, useCustomPrice]);

	return <>{OrderProductContent}</>;
};
