import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

export const InternalSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { orderType, subOrders, config, configValues, setSuborderProperties, removeSubOrder } =
		useOrderEditor();

	if (!config) return null;

	/**
	 * Fetches the current suborder from the suborders list
	 */
	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	return (
		<Grid
			item
			xs={12}
			container
			sx={{
				margin: '1rem 0 1rem 0',
				padding: '1rem',
				borderBottom: subOrders.length > 1 ? '1px solid #e0e0e0' : '',
			}}
			spacing={2}
			display='flex'
		>
			<Grid item container flexGrow={1} flexBasis={0} rowSpacing={2}>
				{/* {header()} */}
				{currentSubOrder?.toId !== -1 && orderType !== null && config.subOrderProductsContainer && (
					<config.subOrderProductsContainer
						key={`${subOrderCode}-${orderType.toLowerCase()}`}
						subOrder={currentSubOrder}
					/>
				)}
			</Grid>
			<Grid
				item
				flexShrink={1}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<IconButton
					onClick={() => removeSubOrder(subOrderCode)}
					sx={{
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						transform: 'scale(1)',
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};
