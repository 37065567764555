import { GeneralInfo } from '@components/forms/sections/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import useUsers from '@hooks/useUsers';
import { Settings } from '@mui/icons-material';
import { AppFunction, SectionEditRequest, SectionEditSchema } from 'common';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

export const RoleForm = ({ forcedId, formAction }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { roleList, createSection, editSection } = useUsers();

	const [formValues, setFormValues] = useState<SectionEditRequest>({} as SectionEditRequest);
	const [id, setId] = useState<number>(-1);

	const setValues = (newId: number) => {
		if (newId === -1) {
			return;
		}
		const role = roleList.find((c) => c.id === newId);
		if (role) {
			const parsedRole = SectionEditSchema.safeParse(role);
			if (parsedRole.success) {
				setFormValues(parsedRole.data);
			} else {
				console.error('SectionEditSchema error', parsedRole.error);
			}
		}
	};

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (id !== routeId && routeId !== -1) {
			setValues(id);
		}
	}, [id]);

	useEffect(() => {
		if (forcedId) {
			setId(forcedId);
			setValues(forcedId);
		}
	}, [forcedId]);

	useEffect(() => {
		setValues(id);
	}, [roleList]);

	return (
		<FormContextProvider
			appFunction={AppFunction.Role}
			createFunction={createSection}
			editFunction={editSection}
			renderType={forcedId ? FormRenderType.popup : FormRenderType.standalone}
			entityId={forcedId ?? id}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			validationSchema={SectionEditSchema}
			defaultValues={{ id: -1 } as SectionEditRequest}
			hideMenu
			useFab
			customAfterCreateLink='role/:id/edit'
		>
			<TabbedElement title='role.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
		</FormContextProvider>
	);
};
