import { Grid, TextField, Typography } from '@mui/material';
import { useTask } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import { AppFunction, TaskStatus, TranslationTypes } from 'common';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DateFieldValue } from '@contexts/taskContext/config/dateFieldConfig';

interface Props {
	taskFieldId: number;
}

export const DateField = ({ taskFieldId }: Props) => {
	const { getTranslatedString } = useLocale();
	const { cachedValues, setTaskFieldValues, getTaskFieldValues, taskState } = useTask();

	const [init, setInit] = useState(false);
	const [value, setValue] = useState<Moment | null>(null);

	useEffect(() => {
		const stubData = getTaskFieldValues<DateFieldValue>(taskFieldId);
		if (stubData.length > 0 && !init) {
			setInit(true);
			setValue(moment(stubData[0].value));
			setTaskFieldValues(taskFieldId, [{ value: moment(stubData[0].value).toDate() }]);
		}
	}, [cachedValues]);

	return (
		<Grid container style={{ padding: '1rem' }} alignItems='center'>
			<Grid item flexGrow={1}>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item>
				<DatePicker
					value={value}
					disabled={taskState !== TaskStatus.inprogress && taskState !== TaskStatus.stub}
					onChange={(e) => {
						setTaskFieldValues(taskFieldId, [{ value: e?.startOf('day').toDate() }]);
						setValue(e);
					}}
					renderInput={(params) => <TextField size='small' {...params} />}
					PopperProps={{ style: { zIndex: 9999 } }}
				/>
			</Grid>
		</Grid>
	);
};
