import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/index';
import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { CustomerTypeResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control } = useGenericForm<CustomerTypeResponse>();
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='merchant.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='customerCodePrefix'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							size='small'
							fullWidth
							multiline
							id='codePrefix'
							label={t('customer.codePrefix')}
							{...field}
							value={field.value}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='canHaveParent'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<FormControlLabel
							control={
								<Switch
									{...field}
									checked={field.value}
									onChange={(ev) => field.onChange(ev.target.checked)}
								/>
							}
							label={t('customerType.canHaveParent')}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='canHaveChildren'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<FormControlLabel
							control={
								<Switch
									{...field}
									checked={field.value}
									onChange={(ev) => field.onChange(ev.target.checked)}
								/>
							}
							label={t('customerType.canHaveChildren')}
						/>
					</InputGridField>
				)}
			/>
		</Grid>
	);
};
