import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/tasks/taskFieldParameter/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormAction, FormType } from '@contexts/formContext/types';
import useTaskFieldParameters from '@hooks/useTaskFieldParameters';
import useTasks from '@hooks/useTasks';
import { Settings } from '@mui/icons-material';
import { AppFunction, TaskFieldParameterResponse, TaskFieldParameterSchema } from 'common';

interface Props {
	forcedId?: number;
	formAction: FormAction;
	formType?: FormType;
	onDataSaved?: (data: TaskFieldParameterResponse) => void;
	forcedValues?: TaskFieldParameterResponse;
}

const TaskFieldParameterFormContent = withFormLogic<TaskFieldParameterResponse>(() => <></>);

export const TaskFieldParameterForm = ({
	forcedId,
	formAction,
	formType,
	onDataSaved,
	forcedValues,
}: Props) => {
	const { taskFieldParametersList, createTaskFieldParameters, editTaskFieldParameters } =
		useTaskFieldParameters();

	return (
		<TaskFieldParameterFormContent
			forcedId={forcedId}
			formAction={formAction}
			formType={formType}
			list={taskFieldParametersList}
			validationSchema={TaskFieldParameterSchema}
			createFunction={createTaskFieldParameters}
			editFunction={editTaskFieldParameters}
			defaultValues={forcedValues ?? ({ id: -1 } as TaskFieldParameterResponse)}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			hideMenu
			customAfterCreateLink='settings/taskFieldParameter'
			appFunction={AppFunction.TaskFieldParameter}
			onDataSaved={onDataSaved}
		>
			{() => (
				<TabbedElement title='tasks.taskFieldParameter'>
					<GeneralInfo />
				</TabbedElement>
			)}
		</TaskFieldParameterFormContent>
	);
};
