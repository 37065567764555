import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';
import {
	AffectedRowsResponse,
	CarrierDeliveryAreaRequest,
	CarrierDeliveryAreaResponse,
	CarrierDeliveryAreasResponse,
	CarrierDeliveryTimeRequest,
	CarrierDeliveryTimeResponse,
	CarrierDeliveryTimesResponse,
	CarrierPlanRequest,
	CarrierPlanResponse,
	CarrierPlansResponse,
	CarrierPriceRequest,
	CarrierPriceResponse,
	CarrierPricesResponse,
	CarrierRequest,
	CarrierResponse,
	CarriersResponse,
	DeliveryAreaToRegionLinkRequest,
	DeliveryAreaToRegionsLinkRequest,
	GenericIdRequest,
} from 'common';

import * as api from './externalApi/common/apiCalls';

export interface CarriersState {
	carriers: CarriersResponse;
	deliveryTimes: CarrierDeliveryTimesResponse;
	deliveryAreas: CarrierDeliveryAreasResponse;
	deliveryPlans: CarrierPlansResponse;
	op_status: NetworkOperationStatus;
}

const initialState: CarriersState = {
	carriers: [],
	deliveryTimes: [],
	deliveryAreas: [],
	deliveryPlans: [],
	op_status: 'idle',
};

// #region Carriers
export const doListCarriers = api.apiList<CarriersResponse>('carriers/list', 'carriers');
export const doCreateCarriers = api.apiCreate<CarrierRequest, CarrierResponse>(
	'carriers/create',
	'carriers',
);
export const doEditCarriers = api.apiEdit<CarrierResponse, AffectedRowsResponse>(
	'carriers/edit',
	'carriers',
);
export const doDeleteCarriers = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'carriers/delete',
	'carriers',
);
// #endregion

// #region Delivery Times
export const doListDeliveryTimes = api.apiList<CarrierDeliveryTimesResponse>(
	'carriers/deliverytimes/list',
	'carriers/deliverytimes',
);
export const doCreateDeliveryTime = api.apiCreate<
	CarrierDeliveryTimeRequest,
	CarrierDeliveryTimeResponse
>('carriers/deliverytimes/create', 'carriers/deliverytime');
export const doEditDeliveryTime = api.apiEdit<CarrierDeliveryTimeResponse, AffectedRowsResponse>(
	'carriers/deliverytimes/edit',
	'carriers/deliverytime',
);
export const doDeleteDeliveryTime = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'carriers/deliverytimes/delete',
	'carriers/deliverytime',
);
// #endregion

// #region Delivery Plans
export const doListDeliveryPlans = api.apiList<CarrierPlansResponse>(
	'carriers/plans/list',
	'carriers/plans',
);
export const doCreateDeliveryPlan = api.apiCreate<CarrierPlanRequest, CarrierPlanResponse>(
	'carriers/plans/create',
	'carriers/plan',
);
export const doEditDeliveryPlan = api.apiEdit<CarrierPlanResponse, AffectedRowsResponse>(
	'carriers/plans/edit',
	'carriers/plan',
);
export const doDeleteDeliveryPlan = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'carriers/plans/delete',
	'carriers/plan',
);
// #endregion

// #region Delivery Areas
export const doListDeliveryAreas = api.apiList<CarrierDeliveryAreasResponse>(
	'carriers/areas/list',
	'carriers/deliveryareas',
);
export const doCreateDeliveryArea = api.apiCreate<
	CarrierDeliveryAreaRequest,
	CarrierDeliveryAreaResponse
>('carriers/areas/create', 'carriers/deliveryarea');
export const doEditDeliveryArea = api.apiEdit<CarrierDeliveryAreaResponse, AffectedRowsResponse>(
	'carriers/areas/edit',
	'carriers/area',
);
export const doDeleteDeliveryArea = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'carriers/areas/delete',
	'carriers/area',
);

export const doLinkDeliveryAreaToRegions = api.apiBulkEdit<
	DeliveryAreaToRegionsLinkRequest,
	AffectedRowsResponse
>('carriers/areas/link', 'carriers/linkregions');

export const doUnlinkDeliveryAreaFromRegion = api.apiCreate<
	DeliveryAreaToRegionLinkRequest,
	AffectedRowsResponse
>('carriers/areas/unlink', 'carriers/unlinkregion/:regionId/area/:areaId');
// #endregion

// #region Delivery Prices
export const doListDeliveryPrices = api.apiList<CarrierPricesResponse>(
	'carriers/prices/list',
	'carriers/prices',
);

export const doCreateDeliveryPrice = api.apiCreate<CarrierPriceRequest, CarrierPriceResponse>(
	'carriers/prices/create',
	'carriers/price',
);

export const doEditDeliveryPrice = api.apiEdit<CarrierPriceResponse, AffectedRowsResponse>(
	'carriers/prices/edit',
	'carriers/price',
);

export const doDeleteDeliveryPrice = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'carriers/prices/delete',
	'carriers/price',
);
// #endregion

export const CarriersSlice = createSlice({
	name: 'carriers',
	initialState,
	reducers: {
		// reducers
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doListCarriers.pending.type,
					doCreateCarriers.pending.type,
					doEditCarriers.pending.type,
					doDeleteCarriers.pending.type,
					doListDeliveryTimes.pending.type,
					doCreateDeliveryTime.pending.type,
					doEditDeliveryTime.pending.type,
					doDeleteDeliveryTime.pending.type,
					doListDeliveryAreas.pending.type,
					doCreateDeliveryArea.pending.type,
					doEditDeliveryArea.pending.type,
					doDeleteDeliveryArea.pending.type,
					doListDeliveryPlans.pending.type,
					doCreateDeliveryPlan.pending.type,
					doEditDeliveryPlan.pending.type,
					doDeleteDeliveryPlan.pending.type,
					doListDeliveryPrices.pending.type,
					doCreateDeliveryPrice.pending.type,
					doEditDeliveryPrice.pending.type,
					doDeleteDeliveryPrice.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListCarriers.fulfilled.type,
					doCreateCarriers.fulfilled.type,
					doEditCarriers.fulfilled.type,
					doDeleteCarriers.fulfilled.type,
					doListDeliveryTimes.fulfilled.type,
					doCreateDeliveryTime.fulfilled.type,
					doEditDeliveryTime.fulfilled.type,
					doDeleteDeliveryTime.fulfilled.type,
					doListDeliveryAreas.fulfilled.type,
					doCreateDeliveryArea.fulfilled.type,
					doEditDeliveryArea.fulfilled.type,
					doDeleteDeliveryArea.fulfilled.type,
					doListDeliveryPlans.fulfilled.type,
					doCreateDeliveryPlan.fulfilled.type,
					doEditDeliveryPlan.fulfilled.type,
					doDeleteDeliveryPlan.fulfilled.type,
					doListDeliveryPrices.fulfilled.type,
					doCreateDeliveryPrice.fulfilled.type,
					doEditDeliveryPrice.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				switch (action.type) {
					case doListCarriers.fulfilled.type:
						state.carriers = action.payload;
						break;
					case doListDeliveryTimes.fulfilled.type:
						state.deliveryTimes = action.payload;
						break;
					case doListDeliveryAreas.fulfilled.type:
						state.deliveryAreas = action.payload;
						break;
					case doListDeliveryPlans.fulfilled.type:
						state.deliveryPlans = action.payload;
						break;
				}
				state.op_status = 'succeeded';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListCarriers.rejected.type,
					doCreateCarriers.rejected.type,
					doEditCarriers.rejected.type,
					doDeleteCarriers.rejected.type,
					doListDeliveryTimes.rejected.type,
					doCreateDeliveryTime.rejected.type,
					doEditDeliveryTime.rejected.type,
					doDeleteDeliveryTime.rejected.type,
					doListDeliveryAreas.rejected.type,
					doCreateDeliveryArea.rejected.type,
					doEditDeliveryArea.rejected.type,
					doDeleteDeliveryArea.rejected.type,
					doListDeliveryPlans.rejected.type,
					doCreateDeliveryPlan.rejected.type,
					doEditDeliveryPlan.rejected.type,
					doDeleteDeliveryPlan.rejected.type,
					doListDeliveryPrices.rejected.type,
					doCreateDeliveryPrice.rejected.type,
					doEditDeliveryPrice.rejected.type,
					doDeleteDeliveryPrice.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
	},
});

export default CarriersSlice.reducer;
