import { EditShowGridField } from '@components/common/EditShowGridField';
import { Controller } from 'react-hook-form';
import { Referent } from './Referent';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { CustomerResponse, UserResponse } from 'common';
import { getI18n, useTranslation } from 'react-i18next';
import useUsers from '@hooks/useUsers';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const ReferentSelector = () => {
	const { control } = useGenericForm<CustomerResponse>();
	const { t } = useTranslation();
	const { userList } = useUsers();
	const canEdit = true;

	if (userList.length > 0) {
		return (
			<Controller
				name='referentUserId'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'internal-referent-label',
							label: t('customer.internalReferent'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='internal-referent-label'
							id='internal-referent-select'
							size='small'
							name='referent'
							fullWidth
							disabled={!canEdit}
							label={t('customer.internalReferent')}
							value={field.value ?? -1}
							inputProps={{
								'aria-labelledby': 'internal-referent-label',
								'aria-label': 'internal-referent-select',
							}}
							onChange={(ev) => field.onChange(ev.target.value !== -1 ? +ev.target.value : null)}
						>
							<MenuItem value={-1} key={'none'}>
								None
							</MenuItem>
							{userList?.map((u: UserResponse, index: number) => (
								<MenuItem value={u.id} key={index}>
									{getI18n().language === 'jp'
										? `${u.person?.surname ?? t('users.noSurname')} ${
												u.person?.name ?? t('users.noName')
										  }`
										: `${u.person?.name ?? t('users.noName')} ${
												u.person?.surname ?? t('users.noSurname')
										  }`}
								</MenuItem>
							))}
						</Select>
					</InputGridField>
				)}
			/>
		);
	}
	return null;
};
