import { PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { doCreateNote, doDeleteNote, doEditNote } from '@store/notes';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	NoteCreateSchema,
	NoteRequest,
	NoteResponse,
	NoteSchema,
	NotesResponse,
} from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';

function useNotes() {
	const dispatch = useAppDispatch();
	const notesSlice = useAppSelector((state) => state.notes.notes);

	const [noteList, setNoteList] = useState<NotesResponse>(notesSlice);

	useEffect(() => {
		setNoteList((currentList) => {
			if (!_.isEqual(currentList, notesSlice)) {
				return notesSlice;
			}
			return currentList;
		});
	}, [notesSlice]);

	const createNote = async (data: NoteRequest | NoteResponse): Promise<NotesResponse> => {
		const parsedData = NoteCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject(parsedData.error);
		} else {
			const response = (await dispatch(doCreateNote(data))) as PayloadAction<NotesResponse>;
			if (response.type === 'notes/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Error creating note');
		}
	};

	const editNote = async (data: NoteResponse): Promise<AffectedRowsResponse> => {
		const parsedData = NoteSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject(parsedData.error);
		} else {
			const response = (await dispatch(doEditNote(data))) as PayloadAction<AffectedRowsResponse>;
			if (response.type === 'notes/edit/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Error editing note');
		}
	};

	const deleteNote = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doDeleteNote(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'notes/delete/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error deleting note');
	};

	return {
		noteList,
		createNote,
		editNote,
		deleteNote,
	};
}

export default useNotes;
