import { Grid } from '@mui/material';
import useGeography from '../hooks/useGeography';
import { TranslatableAutocompleteWithCallback } from './TranslatableAutocompleteWithCallback';
import { AppFunction, TranslationTypes } from 'common';
import useLocale from '../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { NestedRegion } from '../store/geography';
import { EditShowGridField } from './common/EditShowGridField';
import { InputGridField } from './common/InputGridField/InputGridField';
import { ComponentType } from './common/InputGridField/config/Index';

interface Props {
	countryId: number;
	regionId?: number | null;
	isEditing?: boolean;
	onChange?: (id: number) => void;
}

// TODO: memoize the component or it will keep rerendering when something changes in the AddressComponent
export const RegionSelector = ({ countryId, regionId, isEditing, onChange }: Props) => {
	const { t } = useTranslation();
	const { getI18NString } = useLocale();
	const { regionTypeList, regionList, getRegionPath } = useGeography();

	const [cachedRegionId, setCachedRegionId] = useState<number | null | undefined>(null);
	const [cachedCountryId, setCachedCountryId] = useState(countryId);
	const [regions, setRegions] = useState<NestedRegion[]>([]);

	useEffect(() => {
		if (regionId !== cachedRegionId) {
			setCachedRegionId(regionId);
			const tempRegions = getRegionPath(regionId);
			setRegions(tempRegions);
		}
	}, [regionId]);

	useEffect(() => {
		if (countryId !== cachedCountryId) {
			setCachedCountryId(countryId);
			onChange?.(-1);
		}
	}, [countryId]);

	const getOptions = (iteration: number): NestedRegion[] => {
		if (regions[iteration - 1]) {
			return regions[iteration - 1].subRegions ?? [];
		}
		return regionList.filter((r) => r.countryId === cachedCountryId);
	};

	const getRegionTypeLabel = (iteration: number): string | null => {
		let typesId: number[] = [];
		if (regions[iteration - 1]) {
			const typeId = regions[iteration - 1].regionTypeId;
			typesId = regionTypeList.filter((rt) => rt.parentId === typeId).map((type) => type.id);
		} else {
			typesId = regionTypeList
				.filter((rt) => countryId === cachedCountryId && rt.parentId === null)
				.map((type) => type.id);
		}
		let string = '';

		typesId.map((tId, it) => {
			if (it === 0) {
				string = t(getI18NString(AppFunction.RegionType, tId, TranslationTypes.name), {
					ns: 'locale',
				});
			} else {
				string = string.concat(
					` ${t(getI18NString(AppFunction.RegionType, tId, TranslationTypes.name), {
						ns: 'locale',
					})}`,
				);
			}
		});

		return string !== '' ? string : null;
	};

	const onChangeValue = (value: number, iteration: number) => {
		if (value === -1 && regions[iteration - 1]) {
			onChange?.(regions[iteration - 1].id);
		} else {
			onChange?.(value);
		}
	};

	const RenderSelectors: () => JSX.Element = useCallback(() => {
		const getSelectorSize = (hasNewLabel: boolean): number => {
			if (hasNewLabel) {
				return 12 / (regions.length + 1) >= 4 ? 12 / (regions.length + 1) : 4;
			}
			return 12 / regions.length >= 4 ? 12 / regions.length : 4;
		};

		const selectors: JSX.Element[] = [];

		const label = getRegionTypeLabel(regions.length);

		regions.map((r, it) => {
			selectors.push(
				<InputGridField
					width={getSelectorSize(isEditing ? (label ? true : false) : false)}
					key={`recursion-${it}`}
					type={ComponentType.Autocomplete}
				>
					<TranslatableAutocompleteWithCallback
						data={getOptions(it)}
						value={r.id}
						translationSettings={{
							translatableType: AppFunction.Region,
							translationType: TranslationTypes.name,
							namespace: 'locale',
						}}
						id={`region-${it}`}
						label={t(`regionType-${r.regionTypeId}-name`, { ns: 'locale' })}
						onChange={(value) => onChangeValue(value, it)}
						onCreateNew={(newName) => console.log(`create new name: ${newName}`)}
					/>
				</InputGridField>,
			);
		});

		if (regions.length === 0) {
			if (label && isEditing) {
				selectors.push(
					<Grid item xs={getSelectorSize(label ? true : false)} key='recursion-0'>
						<TranslatableAutocompleteWithCallback
							data={getOptions(0)}
							value={cachedRegionId ?? -1}
							translationSettings={{
								translatableType: AppFunction.Region,
								translationType: TranslationTypes.name,
								namespace: 'locale',
							}}
							id={label}
							label={label}
							onChange={(value) => onChangeValue(value, 0)}
							onCreateNew={(newName) => console.log(`create new name: ${newName}`)}
						/>
					</Grid>,
				);
			}
		} else {
			if (label && isEditing) {
				selectors.push(
					<Grid item xs={getSelectorSize(label ? true : false)} key={`recursion-${regions.length}`}>
						<TranslatableAutocompleteWithCallback
							data={getOptions(regions.length)}
							value={-1}
							translationSettings={{
								translatableType: AppFunction.Region,
								translationType: TranslationTypes.name,
								namespace: 'locale',
							}}
							id={label}
							label={label}
							onChange={(value) => onChangeValue(value, regions.length)}
							onCreateNew={(newName) => console.log(`create new name: ${newName}`)}
						/>
					</Grid>,
				);
			}
		}

		return (
			<Grid item xs={12} container spacing={2}>
				{selectors}
			</Grid>
		);
	}, [regions]);

	return <RenderSelectors />;
};
