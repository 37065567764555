import {
	InternalOrderSubType,
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderProductRequest,
	OrderType,
	ShippingType,
	StorageType,
} from 'common';
import { OrderTypeConfig } from './orderTypeConfig';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { doCreateOrder } from '@store/order';
import { PayloadAction } from '@reduxjs/toolkit';
import { InboundSuborderRow } from '../suborder/rows/inbound/InboundSuborderRow';
import { InboundSuborderProductsContainer } from '../suborder/rows/inbound/InboundSuborderProductsContainer';
import { BookedOrders } from '../infobox/BookedOrders';
import { InboundOrderHeader } from '../header/inbound/InboundOrderHeader';
import { InboundModifySuborderContainer } from '../suborder/modifyRows/inbound/InboundModifySuborderContainer';

/**
 * This is the configuration for the inbound order type.
 */
export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(true),
		fromType: new OrderElementConfigItem([OrderDirectionType.merchant]),
		shippingType: new OrderElementConfigItem((directionType: OrderDirectionType) => {
			switch (directionType) {
				case OrderDirectionType.merchant:
					return [ShippingType.air, ShippingType.sea];
				default:
					return null;
			}
		}),
		toType: new OrderElementConfigItem((directionType: OrderDirectionType) => {
			// Since 'fromType' only allows for 'merchant', this will always return warehouse.
			// Even if suborders can have different OrderDirectionTypes (e.g. branch, customer),
			// the main order will always be warehouse for this OrderType (inboundOrder).
			switch (directionType) {
				case OrderDirectionType.merchant:
					return [OrderDirectionType.warehouse];
				default:
					return null;
			}
		}),
		toSubType: new OrderElementConfigItem(
			(directionType: OrderDirectionType, shippingType: ShippingType): StorageType[] | null => {
				switch (directionType) {
					case OrderDirectionType.merchant:
						switch (shippingType) {
							case ShippingType.air:
								return [StorageType.airport];
							case ShippingType.sea:
								return [StorageType.port];
							default:
								return null;
						}
					default:
						return null;
				}
			},
		),
		toWarehouseType: new OrderElementConfigItem(true),
		subOrderTypes: new OrderElementConfigItem(
			(directionType: OrderDirectionType, storageType: StorageType) => {
				switch (directionType) {
					case OrderDirectionType.warehouse:
						switch (storageType) {
							case StorageType.airport:
								return [
									OrderDirectionType.branch,
									OrderDirectionType.warehouse,
									OrderDirectionType.customer,
								];
							case StorageType.port:
								return [OrderDirectionType.warehouse];
							default:
								return null;
						}
					default:
						return null;
				}
			},
		),
	},
	headerElement: InboundOrderHeader,
	infoElements: [BookedOrders],
	maxSubOrders: 'unlimited',
	subOrderRow: InboundSuborderRow,
	subOrderProductsContainer: InboundSuborderProductsContainer,
	modifySubOrderProductsContainer: InboundModifySuborderContainer,
	isAddSuborderDisabled: (order, subOrders) => {
		if (!order.fromId || !order.toId) {
			return true;
		}
		if (subOrders.some((so) => !so.content || so.content.length <= 0)) {
			return true;
		}
		return subOrders.some((so) =>
			so.content?.some(
				(soco) =>
					soco.variantId === -1 ||
					soco.variantId === null ||
					soco.variantId === undefined ||
					soco.orderQty === undefined ||
					soco.orderQty <= 0,
			),
		);
	},
	isCreateOrderDisabled: (subOrders) => {
		if (subOrders.length <= 0) {
			return true;
		}
		if (subOrders.some((so) => !so.content || so.content.length <= 0)) {
			return true;
		}
		return subOrders.some((so) =>
			so.content?.some(
				(soco) =>
					soco.variantId === -1 ||
					soco.variantId === null ||
					soco.variantId === undefined ||
					soco.orderQty === undefined ||
					soco.orderQty <= 0,
			),
		);
	},
	executeOrderAction: async (data) => {
		const tempOrderProducts: OrderProductRequest[] = [];
		const request = { ...data.baseRequest };

		console.log(data.subOrders);

		if (data.subOrders) {
			data.subOrders.forEach((so) => {
				so.content?.forEach((op) => {
					if (op.orderQty === undefined || op.orderQty === null || op.orderQty === 0) {
						return;
					}
					tempOrderProducts.push({ ...op });
				});
			});
		}
		request.content = tempOrderProducts;

		// Remove suborders not going to be used in the order
		const newChildren: OrderBaseRequest[] = [];
		if (data.subOrders) {
			data.subOrders.forEach((so, it) => {
				if (so.toId) {
					newChildren.push({
						...so,
						code: `${request.code}-${it}`,
						orderType: OrderType.Internal,
						orderSubType: InternalOrderSubType.afterImport,
					});
				}
			});
		}

		request.children = newChildren;
		request.orderType = OrderType.Inbound;

		const validateData = OrderBaseCreateSchema.safeParse(request);
		if (!validateData.success) {
			return Promise.reject(validateData.error);
		}

		console.log(validateData.data);

		// return Promise.reject('testing');

		const inboundOrderResponse = (await data.dispatch(
			doCreateOrder(validateData.data),
		)) as PayloadAction<OrderBaseResponse>;

		if (inboundOrderResponse.type !== 'orders/create/fulfilled') {
			return Promise.reject('Inbound order creation failed');
		}
		return inboundOrderResponse.payload;
	},
};
