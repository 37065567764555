import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewInputGroup, Option } from '../NewInputGroup';
import { ChildSettings } from '../NewInputGroupItem';
import { ChildType } from '../NewInputGroupItemChildren';

import useProducts from '../../hooks/useProducts';
import usePrices from '../../hooks/usePrices';
import useFeedbacks from '../../hooks/useFeedbacks';

import { AppFunction, PriceTypesRequest, TranslationTypes, VariantsResponse } from 'common';

import commonStyles from '../../styles/Common.module.css';

interface Props {
	customerTypeId: number;
}

const childrenSettings: ChildSettings[] = [
	{
		childType: ChildType.NumberField,
		label: 'base price',
		size: 4,
		readonly: true,
	},
	{
		childType: ChildType.NumberField,
		label: 'type price',
		size: 8,
	},
];

export const InputCustomerTypePricesGroup = (props: Props) => {
	const { t } = useTranslation();
	const { productList } = useProducts();
	const { deleteCustomerTypePrices, editCustomerTypePrices } = usePrices();
	const { showSnackBar } = useFeedbacks();

	const [options, setOptions] = useState<Option[]>([]);
	const [editedOptions, setEditedOptions] = useState<Option[]>([]);

	const onRetrieveValues = (newOptions: Option[]) => {
		setEditedOptions(newOptions);
	};

	const onEdit = async () => {
		const originalEntries = options.filter((entry) => {
			if (entry.inputs) {
				const inputs = entry.inputs.filter((y) => y.defaultValue != undefined);
				if (inputs.length > 0) return inputs;
			}
		});

		const originalEntriesIndexes = originalEntries.map((x) => x.selectionId);
		const editedEntriesIndexes = editedOptions.map((x) => x.selectionId);
		const deleted = originalEntriesIndexes.filter((x) => !editedEntriesIndexes.includes(x));

		// delete
		const deleteRequestParams: number[] = [];
		const variantCollection: VariantsResponse = [];
		productList.map((pr) => {
			pr.variants?.map((vr) => variantCollection.push(vr));
		});
		const specialPrices = variantCollection.map((vr) => vr.sellingPriceType);
		specialPrices.map((spArray) => {
			if (spArray) {
				spArray.map((x) => {
					if (deleted.includes(x.variantId) && x.id) {
						deleteRequestParams.push(x.id);
					}
				});
			}
		});
		if (deleteRequestParams.length > 0) {
			await deleteCustomerTypePrices({ ids: deleteRequestParams });
			showSnackBar('Deleted stuff.');
		}

		// create
		const createRequestParams: PriceTypesRequest = [];
		editedOptions.map((op) => {
			if (op.inputs) {
				const price = op.inputs[1].value ? +op.inputs[1].value : 0;
				createRequestParams.push({
					variantId: op.selectionId,
					typeId: props.customerTypeId,
					price: price,
					currencyId: 1,
				});
			}
		});
		if (createRequestParams.length > 0) {
			await editCustomerTypePrices(createRequestParams);
			showSnackBar('Edited prices.');
		}
	};

	useEffect(() => {
		const variantCollection: VariantsResponse = [];
		productList.map((product) => {
			product.variants?.map((variant) => variantCollection.push(variant));
		});
		const newOptions: Option[] = [];
		variantCollection.map((variant) => {
			const sellingPrice = variant.sellingPriceType?.find((x) => x.typeId === props.customerTypeId);
			newOptions.push({
				selectionId: variant.id,
				selectionName: variant.id.toString(),
				inputs: [
					{ defaultValue: variant.sellPrice, value: variant.sellPrice },
					{ defaultValue: sellingPrice?.price, value: sellingPrice?.price },
				],
			});
		});

		setOptions(newOptions.sort());
	}, [productList, props.customerTypeId]);

	return (
		<>
			<Box className={`${commonStyles.itemBox} ${commonStyles.redBox}`}>
				<Typography
					variant='overline'
					display='block'
					gutterBottom
					sx={{ fontSize: '0.9rem' }}
					pb={1}
					pt={1}
				>
					{t('customers.discounted_price')}
				</Typography>
				<NewInputGroup
					options={options}
					optionsLabel={'prodName'}
					childrenSettings={childrenSettings}
					onRetrieveValues={onRetrieveValues}
					autocomplete
					translate={{ category: AppFunction.Variant, type: TranslationTypes.name }}
				/>
			</Box>
			<Button variant='outlined' sx={{ marginTop: '20px' }} onClick={onEdit} fullWidth>
				{t('operations.edit')}
			</Button>
		</>
	);
};
