import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import { useEffect, useMemo, useState } from 'react';
import { CloseOrderFieldAction, CloseOrderFieldItemValue, CloseOrderValueMap } from '../../config';
import moment, { Moment } from 'moment';
import { CloseOrderFieldValues } from '@contexts/taskContext/config/closeOrderFieldConfig';
import { List } from '@mui/material';
import { StandardItem } from './StandardItem';
import { InternalOrderSubType, OrderType } from 'common';
import { ImportChildItem } from './ImportChildItem/ImportChildItem';
import { ContainerComponentProps } from '@components/taskworkflow/tasks/config';
import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';
import { MoveFromToBanner } from '@components/common/banners/MoveFromToBanner';

export const InternalCloseOrderFieldContainer: React.FC<ContainerComponentProps> = ({
	taskFieldId,
}: ContainerComponentProps) => {
	const {
		targetId,
		additionalData,
		setTaskFieldValues,
		getParentProduct,
		orderChain,
		cachedValues,
	} = useTask();
	const { orderList } = useOrders();
	const order = orderList.find((order) => order.id === targetId);

	// useEffect(() => {
	// 	console.log('order chain changed: ', orderChain);
	// }, [orderChain]);

	/**
	 * Local state for the field values.
	 */
	const [values, setValues] = useState<CloseOrderValueMap>([]);

	/**
	 * Initializes the field values based on either the context or the current order.
	 * Initialization is performed when the component mounts or when the order changes.
	 *
	 * @hook useEffect
	 * @description This effect sets the initial state for close order field values.
	 * It calculates new values based on the current order and its contents,
	 * considering the expiration date and arrived quantity for each item.
	 * These values are then used to update both the local state and the context.
	 * @param {Order} order - The current order object used to initialize or update the field values.
	 * Includes the order content and parent order information for expiration date calculations.
	 * @sideEffects
	 * - Accesses and updates local component state.
	 * - Potentially updates context state with new field values.
	 * @cleanup No cleanup action is performed by this effect.
	 */
	useEffect(() => {
		if (!order) {
			return;
		}
		const newValues: CloseOrderValueMap = {};
		console.log(order);
		order.content
			?.filter((c) => c.variantId !== undefined && c.variantId !== null)
			.forEach((content) => {
				const parsedOrderProductId = getParsedId(content.id);
				if (parsedOrderProductId === null) {
					return;
				}
				let oldExpiresAt: Moment | null = null;
				let oldOrderProductId: number | null = null;
				if (order.parentId !== null && order.parentId !== undefined) {
					const parentOrder = orderList.find((o) => o.id === order.parentId);

					// If there is a parent order we look for the parent product.
					// The logic is inside the config for the current field type and might be
					// different depending on the order type (might be different for order subtype too in the future).
					if (parentOrder && order.orderType) {
						const parentProduct = getParentProduct?.(
							taskFieldId,
							order.orderType,
							parentOrder,
							content,
						);
						oldExpiresAt = parentProduct?.expiresAt
							? moment(parentProduct.expiresAt).startOf('day')
							: null;
						oldOrderProductId = parentProduct?.id ? +parentProduct.id : null;
					}
				}

				const value = {
					variantId: content.variantId ?? -1,
					expiresAt: content.expiresAt ? moment(content.expiresAt) : oldExpiresAt,
					orderQty: content.orderQty ?? 0,
					arrivedQty: content.arrivedQty ?? content.orderQty ?? 0,
					sourceId: oldOrderProductId,
					weight: content.weight ?? 0,
					estimated: content.estimated ?? false,
				};

				newValues[parsedOrderProductId] = {
					oldValues: value,
					newValues: [value],
				};
			});

		console.log('setting new values, ', newValues);
		setValues(newValues);
		setValuesInContext(newValues);
	}, [targetId, order]);

	useEffect(() => {
		setValuesInContext(values);
	}, [values]);

	/**
	 * Converts values from local state to context state
	 * @param {{[key: number]: CloseOrderValue}} values
	 */
	const setValuesInContext = (values: CloseOrderValueMap) => {
		const newValues: CloseOrderFieldValues = Object.entries(values).map(([key, value]) => ({
			orderProductId: parseInt(key),
			variantId: value.oldValues.variantId,
			warehouseId: order?.toId ?? -1,
			notArrived: value.notArrived,
			oldValues: {
				...value.oldValues,
				expiresAt: value.oldValues.expiresAt ? value.oldValues.expiresAt.toDate() : null,
				actualExpiration: value.oldValues.actualExpiration
					? value.oldValues.actualExpiration.toDate()
					: null,
			},
			newValues: value.newValues.map((v) => ({
				...v,
				expiresAt: v.expiresAt ? v.expiresAt.toDate() : null,
				actualExpiration: v.actualExpiration ? v.actualExpiration.toDate() : null,
			})),
			returnValue: value.returnValue
				? {
						...value.returnValue,
						estimated: true,
						expiresAt: value.returnValue.expiresAt ? value.returnValue.expiresAt.toDate() : null,
						actualExpiration: value.returnValue.actualExpiration
							? value.returnValue.actualExpiration.toDate()
							: null,
				  }
				: undefined,
			inspectionValue: value.inspectionValue
				? {
						...value.inspectionValue,
						estimated: true,
						expiresAt: value.inspectionValue.expiresAt
							? value.inspectionValue.expiresAt.toDate()
							: null,
						actualExpiration: value.inspectionValue.actualExpiration
							? value.inspectionValue.actualExpiration.toDate()
							: null,
				  }
				: undefined,
		}));
		setTaskFieldValues(taskFieldId, newValues);
	};

	/**
	 * Checks if the current order is a child of an import order
	 * if it is we need to render the import child item instead of the standard one
	 * // TODO: this is deprecated, we mark the import child items with a different orderSubType
	 */
	const isImportChild = useMemo(
		() =>
			orderChain.length > 1 &&
			orderChain[0].orderType === OrderType.Inbound &&
			orderChain.some((o, it) => o.orderType === OrderType.Internal && it > 0),
		[orderChain],
	);

	/**
	 * Updates the local state and context state with the new values
	 * Performs an optimistic update to the local state and updates the context state
	 * @param {number} orderProductId
	 * @param {CloseOrderFieldAction} field
	 * @param {Moment | null | number | boolean} value
	 * @param {number} splitIndex
	 */
	const updateField = (
		orderProductId: number,
		field: CloseOrderFieldAction,
		value: Moment | null | number | boolean,
		splitIndex?: number,
		variable?: boolean,
	) => {
		let newValues: CloseOrderValueMap = {};
		setValues((prevValues) => {
			const key = getParsedId(orderProductId);
			if (!key) {
				console.error('Invalid order product ID');
				return prevValues;
			}

			const currentValue = prevValues[key] || {
				oldValues: { expiresAt: null, arrivedQty: 0, weight: 0 },
				newValues: [],
			};

			switch (field) {
				case 'delete': {
					if (splitIndex === undefined) {
						throw new Error('splitIndex is required for delete field');
					}
					const updatedValue = {
						...currentValue,
						newValues: currentValue.newValues.filter((_, i) => i !== splitIndex),
					};
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'add': {
					console.log('adding split product, variable: ', variable);
					const oldNewValues = currentValue.newValues;
					const addedNewValue: CloseOrderFieldItemValue = {
						...currentValue.oldValues,
						arrivedQty: 0,
						weight: variable ? 0 : currentValue.oldValues.weight,
					};
					oldNewValues.splice((splitIndex ?? 0) + 1, 0, addedNewValue);
					const updatedValue = { ...currentValue, newValues: oldNewValues };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'restore': {
					const updatedValue = { ...currentValue, newValues: [currentValue.oldValues] };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'notArrived': {
					const updatedValue = { ...currentValue, notArrived: value as boolean };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'addReturn': {
					const updatedValue = { ...currentValue, returnValue: currentValue.oldValues };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'removeReturn': {
					const updatedValue = { ...currentValue, returnValue: undefined };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'addInspection': {
					const updatedValue = { ...currentValue, inspectionValue: currentValue.oldValues };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				case 'removeInspection': {
					const updatedValue = { ...currentValue, inspectionValue: undefined };
					newValues = { ...prevValues, [key]: updatedValue };
					return newValues;
				}
				default: {
					if (splitIndex === -1) {
						if (currentValue.returnValue) {
							const updatedValue = {
								...currentValue,
								returnValue: { ...currentValue.returnValue, [field]: value },
							};
							newValues = { ...prevValues, [key]: updatedValue };
						}
						return newValues;
					} else if (splitIndex === -2) {
						if (currentValue.inspectionValue) {
							const updatedValue = {
								...currentValue,
								inspectionValue: { ...currentValue.inspectionValue, [field]: value },
							};
							newValues = { ...prevValues, [key]: updatedValue };
						}
						return newValues;
					} else {
						let updatedValue = currentValue;
						if (currentValue.newValues[splitIndex ?? 0]) {
							const modifiedInsertedValue = {
								...currentValue.newValues[splitIndex ?? 0],
								[field]: value,
							};
							currentValue.newValues[splitIndex ?? 0] = modifiedInsertedValue;
							updatedValue = currentValue;
						} else {
							const newInsertedValue = { ...currentValue.oldValues, [field]: value };
							updatedValue = {
								...currentValue,
								newValues: [...currentValue.newValues, newInsertedValue],
							};
						}
						newValues = { ...prevValues, [key]: updatedValue };
						return newValues;
					}
				}
			}
		});
	};

	if (!order || !order.content?.[0] || !order.fromId || !order.toId) {
		return null;
	}

	const parsedSubType =
		(additionalData[taskFieldId]?.orderSubType as InternalOrderSubType) ??
		InternalOrderSubType.simple;

	return (
		<div style={{ padding: '1rem' }}>
			<MoveFromToBanner order={order} />
			<List>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content, it) => {
						const parsedOrderProductId = getParsedId(content.id);
						if (parsedOrderProductId === null) {
							return null;
						}
						if (values[parsedOrderProductId]) {
							if (isImportChild) {
								return (
									<ImportChildItem
										key={it}
										value={values[parsedOrderProductId]}
										content={content}
										iterator={it}
										updateField={updateField}
									/>
								);
							}
							return (
								<StandardItem
									key={it}
									value={values[parsedOrderProductId]}
									content={content}
									iterator={it}
									subType={parsedSubType}
								/>
							);
						}
					})}
			</List>
		</div>
	);
};
