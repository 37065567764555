export function warnNoProvider(functionName: string) {
	return () => console.warn(`TaskContext: no provider for ${functionName}`);
}

export function warnNoProviderAsync(functionName: string) {
	return async () => console.warn(`TaskContext: no provider for ${functionName}`);
}

export function warnNoProviderGeneric<T>(functionName: string) {
	return (fieldId: number): T[] => {
		console.warn(`TaskContext: no provider for ${functionName} with fieldId ${fieldId}`);
		return [] as T[];
	};
}
