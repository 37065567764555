import { useTranslation } from 'react-i18next';
import { Box, Typography, alpha, useTheme } from '@mui/material';

import styles from './TabbedSection.module.css';
import { AppFunction, ProductType } from 'common';
import useAppFunctions from '@hooks/useAppFunctions';

interface Props {
	appFunction?: AppFunction;
	subFunction?: string | ProductType;
	title?: string;
}

export const TabheaderBody = ({ appFunction, subFunction, title }: Props) => {
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const theme = useTheme();

	const icon = appFunction ? getIcon(appFunction, subFunction, true) : null;

	return (
		<Box
			className={styles.header}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
				margin: '1rem 0 1rem 0',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					backgroundColor: 'white',
					borderRadius: '2rem',
					boxShadow: `2px 2px 5px 0 ${alpha(theme.palette.primary.main, 0.2)} inset`,
					minWidth: '1%',
					// transition: 'min-width 0.5s',
					// '&:hover': {
					// 	minWidth: '100%',
					// },
				}}
			>
				{icon && (
					<div
						style={{
							fontSize: '3rem',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '1rem',
						}}
					>
						{icon}
					</div>
				)}
				<Typography
					variant='h5'
					className={styles.title}
					sx={{
						padding: '0 1rem 0 0',
					}}
				>
					{title ? t(`${title}`) : 'No Title'}
				</Typography>
			</Box>
		</Box>
	);
};
