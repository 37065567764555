import { AppFunction, TranslationTypes, WarehouseResponse } from 'common';

import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';

interface Props {
	data: WarehouseResponse;
	showFull?: boolean;
}

export const WarehouseChip = ({ data, showFull }: Props) => {
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const { getTranslatedString } = useLocale();

	const storageType = t(`${AppFunction.Location}.storageTypes.${data.storageType}`);
	const icon = getIcon(AppFunction.Location, data.storageType) || <></>;

	if (showFull) {
		return (
			<Chip
				icon={icon}
				size='small'
				label={
					<Chip
						label={getTranslatedString(AppFunction.Location, data.id, TranslationTypes.name)}
						size='small'
						sx={{
							paddingRight: 1,
						}}
					/>
				}
				sx={{
					margin: 0,
					padding: 0,
					'& .MuiChip-label': {
						paddingRight: 0,
					},
				}}
			/>
		);
	}
	return <Chip icon={icon} label={storageType} size='small' />;
};
