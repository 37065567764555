import { useLayout } from '@contexts/index';
import { SubMenuButton } from '@components/layout/submenu/button/SubMenuButton';
import { ArrowLeft } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { SubMenuWrapper } from './button/SubMenuWrapper';

export const SubMenuHandler = () => {
	const {
		selectedSubMenuArray,
		subMenuElements,
		subMenuFullScreen,
		setSubMenuPosition,
		selectSubMenuElement,
	} = useLayout();

	const theme = useTheme();

	// TODO: implement correctly the whole submenu
	// right now buttons are not correct (disappear etc..)

	return (
		<SubMenuWrapper>
			{selectedSubMenuArray && selectedSubMenuArray?.length > 0 && (
				<IconButton
					onClick={() => selectSubMenuElement(null)}
					// variant='contained'
					size='small'
					sx={{
						backgroundColor: theme.palette.primary.contrastText,
						color: theme.palette.primary.main,
						position: 'absolute',
						left: '0.5rem',
						maxHeight: '2rem',
						maxWidth: '2rem',
						'&:hover': {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
						},
					}}
				>
					<ArrowLeft />
				</IconButton>
			)}
			{subMenuElements?.map((_, index) => {
				return <SubMenuButton key={index} index={[index]} />;
			})}
		</SubMenuWrapper>
	);
};
