import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';
import {
	AffectedRowsResponse,
	AppFunction,
	GenericIdRequest,
	RolesCreateRequest,
	RolesResponse,
	SectionCreateRequest,
	SectionEditRequest,
	SectionResponse,
	SectionsResponse,
	UserWorkplacesResponse,
} from 'common';

import * as api from './externalApi/common/apiCalls';

export interface Permission {
	read: boolean;
	write: boolean;
	function: AppFunction;
}

export interface Role {
	name: string;
	active: boolean;
	permissions: Permission[];
}

const parseRole = (response: SectionResponse | undefined): Role => {
	if (response != undefined) {
		return {
			name: response.name,
			active: response.active ?? false,
			permissions: response.permissions.map((perm) => {
				return {
					function: perm.function as AppFunction,
					read: perm.read ?? false,
					write: perm.write ?? false,
				};
			}),
		};
	}

	return {
		name: 'none',
		active: false,
		permissions: [],
	};
};

export interface RoleState {
	roles: SectionsResponse;
	userRole: Role[] | null;
	op_status: NetworkOperationStatus;
}

const initialState: RoleState = {
	roles: [],
	userRole: null,
	op_status: 'idle',
};

export const doListSections = api.apiList<SectionsResponse>('sections/list', 'roles');

export const doCreateSection = api.apiCreate<SectionCreateRequest, SectionResponse>(
	'section/create',
	'roles',
);

export const doEditSection = api.apiEdit<SectionEditRequest, AffectedRowsResponse>(
	'section/edit',
	'roles',
);

export const doDeleteSection = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'section/delete',
	'roles',
);

export const doLinkUserToWorkplaces = api.apiBulkEdit<UserWorkplacesResponse, AffectedRowsResponse>(
	'user/workplaces',
	'users/:userId/workplace',
);

export const doLinkUserToRoles = api.apiBulkEdit<RolesCreateRequest, AffectedRowsResponse>(
	'user/roles',
	'users/:userId/role',
);

export const appRoles = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		setUserRoles(state, action: PayloadAction<RolesResponse>) {
			state.userRole = action.payload.map((role) => parseRole(role.section));
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doListSections.pending.type,
					doCreateSection.pending.type,
					doEditSection.pending.type,
					doDeleteSection.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListSections.fulfilled.type,
					doCreateSection.fulfilled.type,
					doEditSection.fulfilled.type,
					doDeleteSection.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				state.op_status = 'succeeded';
				switch (action.type) {
					case doListSections.fulfilled.type:
						state.roles = action.payload;
						break;
				}
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListSections.rejected.type,
					doCreateSection.rejected.type,
					doEditSection.rejected.type,
					doDeleteSection.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
	},
});

export const { setUserRoles } = appRoles.actions;
export default appRoles.reducer;
