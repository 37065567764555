import { Grid, Typography } from '@mui/material';
import { AppFunction } from 'common';
import { useTranslation } from 'react-i18next';

export const InboundItemsHeader = () => {
	const { t } = useTranslation();

	return (
		<Grid
			item
			xs={12}
			container
			columnSpacing={1}
			p={1}
			sx={{
				border: '1px solid #f0f0f0',
			}}
		>
			<Grid
				item
				xs={6}
				display='flex'
				alignItems='center'
				gap={1}
				sx={{
					borderRight: '1px solid #f0f0f0',
				}}
			>
				<Typography variant='body1'>{t(`${AppFunction.Product}.name`)}</Typography>
			</Grid>
			<Grid
				item
				xs={2}
				display='flex'
				alignItems='center'
				sx={{
					borderRight: '1px solid #f0f0f0',
				}}
			>
				<Typography variant='body1'>{t(`${AppFunction.Order}.orderedQuantity`)}</Typography>
			</Grid>
			<Grid
				item
				xs={2}
				display='flex'
				alignItems='center'
				sx={{
					borderRight: '1px solid #f0f0f0',
				}}
			>
				<Typography variant='body1'>{t(`${AppFunction.Order}.arrivedQuantity`)}</Typography>
			</Grid>
			<Grid item xs={2} display='flex' alignItems='center'>
				<Typography variant='body1'>{t(`${AppFunction.Inventory}.singleWeight`)}</Typography>
			</Grid>
		</Grid>
	);
};
