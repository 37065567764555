import { useState } from 'react';
import useGeography from '../hooks/useGeography';

import { Button, Grid, Typography } from '@mui/material';
import { Region } from './Region';

import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import { NestedRegion } from '../store/geography';
import { useGenericForm } from '@contexts/index';
import { AppFunction, CountryResponse, TranslationTypes } from 'common';
import useLocale from '@hooks/useLocale';
import { TabInnerTitle } from './tabs/TabInnerTitle';

export const Regions = () => {
	const { entityId, formAction } = useGenericForm<CountryResponse>();
	const { regionList, createEmptyRegion } = useGeography();
	const { getTranslatedString } = useLocale();
	const [isCreating, setCreating] = useState(false);

	const [canReorder, setCanReorder] = useState(false);

	const itemTemplate = (item: NestedRegion, options: VirtualScrollerTemplateOptions) => {
		return (
			<div
				style={{
					height: 'auto',
					display: 'flex',
					alignItems: 'center',
					padding: '2px',
					backgroundColor: options.even ? '#f9f9f9' : '#f0f0f0',
				}}
			>
				{getTranslatedString(AppFunction.Region, item.id, TranslationTypes.name)}
			</div>
		);
	};

	const virtualRegions = () => {
		const filteredRegions = regionList.filter((region) => region.countryId === entityId);
		if (filteredRegions.length <= 0) {
			return <></>;
		} else {
			return (
				<div style={{ width: '100%', height: '500px', display: 'flex', justifyContent: 'center' }}>
					<VirtualScroller
						items={filteredRegions}
						itemSize={20}
						itemTemplate={itemTemplate}
						style={{ width: '100%', height: '100%' }}
					/>
				</div>
			);
		}
	};

	const newRegion = () => (
		<Grid item xs={12}>
			<Region
				key='new-region'
				region={createEmptyRegion(entityId)}
				recursionIdx={0}
				forceCreate
				onCancelCreate={() => setCreating(false)}
			/>
		</Grid>
	);

	const addButton = () =>
		!canReorder && (
			<Grid item xs={12}>
				<Button fullWidth variant='outlined' onClick={() => setCreating(true)}>
					+
				</Button>
			</Grid>
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='country.regions' translate size='small' />
			</Grid>
			{/* <Grid item xs={10} sx={{ pointerEvents: isCreating ? 'none' : 'all' }}>
				<Typography variant='overline' display='block' gutterBottom sx={{ fontSize: '0.9em' }}>
					<span style={{ backgroundColor: 'white' }}>regions</span>
				</Typography>
			</Grid>
			{!isCreating && (
				<Grid item xs={2}>
					<Button fullWidth onClick={() => setCanReorder((value) => !value)}>
						{canReorder ? 'save' : 'reorder'}
					</Button>
				</Grid>
			)} */}
			<Grid
				item
				xs={12}
				container
				spacing={0}
				sx={{
					opacity: isCreating ? 0.3 : 1,
					transition: 'opacity 0.5s',
					pointerEvents: isCreating ? 'none' : 'all',
				}}
			>
				{virtualRegions()}
			</Grid>
			{/* {isCreating && newRegion()}
			{!isCreating && addButton()} */}
		</Grid>
	);
};
