import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomers from '../hooks/useCustomers';
import useLocale from '../hooks/useLocale';

import { Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { EditShowGridField } from './common/EditShowGridField';
import { AppFunction, CustomersResponse, TranslationTypes } from 'common';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

interface Props {
	childId: number;
}

export const CompanyChildSelector = ({ childId }: Props) => {
	const { customerList } = useCustomers();
	const { renderI18NField, getI18NString } = useLocale();
	const { t } = useTranslation();

	const [isEditing, setEditing] = useState(false);

	const getAvailableChildren = (): CustomersResponse => {
		return customerList;
	};

	return (
		<>
			<Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<SubdirectoryArrowRightIcon />
			</Grid>
			<EditShowGridField
				isEditing={isEditing}
				width={isEditing ? 9.5 : 11}
				valueTranslationRule={{
					translatableType: AppFunction.Customer,
					translationType: TranslationTypes.name,
					namespace: 'locale',
				}}
			>
				<InputLabel id='child-company'>{t('customer.childCompany')}</InputLabel>
				<Select
					labelId='parent-company'
					id='parentCompany'
					label={t('customer.parentCompany')}
					value={childId}
					onChange={(ev) => {
						console.log(ev.target.value);
					}}
				>
					<MenuItem value={-1} key={'none'}>
						None
					</MenuItem>
					{getAvailableChildren().map((u, index) => {
						if (u.id) {
							return (
								<MenuItem value={u.id} key={index}>
									{renderI18NField(
										getI18NString(AppFunction.Customer, u.id, TranslationTypes.name),
									)}
								</MenuItem>
							);
						}
					})}
				</Select>
			</EditShowGridField>
			{isEditing ? (
				<>
					<Grid
						item
						xs={1.5}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<IconButton onClick={() => console.log('save changes')}>
							<CheckIcon />
						</IconButton>
						<IconButton onClick={() => setEditing(false)}>
							<ClearIcon />
						</IconButton>
					</Grid>
				</>
			) : (
				// <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				//     <ContextualDropdownMenu
				//         onDelete={() => console.log('delete')}
				//         onEdit={() => setEditing(true)}
				//     />
				// </Grid>
				<></>
			)}
		</>
	);
};
