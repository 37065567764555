import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	AffectedRowsResponse,
	ChainArchetypeRequest,
	ChainArchetypeResponse,
	ChainArchetypesResponse,
	ChainsResponse,
	GenericIdRequest,
	TaskArchetypeRequest,
	TaskArchetypeResponse,
	TaskArchetypesResponse,
	TaskAssignRequest,
	TaskFieldArchetypeRequest,
	TaskFieldArchetypeResponse,
	TaskFieldArchetypesResponse,
	TaskFieldParameterRequest,
	TaskFieldParameterResponse,
	TaskFieldParametersResponse,
	TaskFieldSetArchetypeRequest,
	TaskFieldSetArchetypeResponse,
	TaskFieldSetArchetypesResponse,
	TaskFieldSetArchetypeUpdateRequest,
	TaskFieldType,
	TaskFieldValueResponse,
	TaskFieldValuesRequest,
	TaskResponse,
	TasksResponse,
	TaskStatus,
	TriggerRequest,
	TriggerResponse,
	TriggersResponse,
} from 'common';
import moment from 'moment';
import _ from 'lodash';

export interface EnhancedTaskResponse extends TaskResponse {
	virtual?: boolean;
	nextTaskId?: number;
	prevTaskId?: number;
	isoAssignableAt?: string;
}

export interface TasksState {
	taskArchetypes: TaskArchetypesResponse;
	taskFieldArchetypes: TaskFieldArchetypesResponse;
	chainArchetypes: ChainArchetypesResponse;
	taskFieldParameters: TaskFieldParametersResponse;
	sets: TaskFieldSetArchetypesResponse;
	triggers: TriggersResponse;
	tasks: TaskResponse[];
	virtualTasks: EnhancedTaskResponse[];
	activeChains: ChainsResponse;
	op_status: NetworkOperationStatus;
}

const initialState: TasksState = {
	taskArchetypes: [],
	taskFieldArchetypes: [],
	chainArchetypes: [],
	taskFieldParameters: [],
	sets: [],
	triggers: [],
	tasks: [],
	virtualTasks: [],
	activeChains: [],
	op_status: 'idle',
};

export const doFetchChains = api.apiList<ChainsResponse>('chains/list', 'tasks/chains');

// #region Tasks
/**
 * Fetches tasks
 * accepts data of type {@link TasksResponse}
 */
export const doFetchTasks = api.apiList<TasksResponse>('tasks/list', 'tasks');

/**
 * Assigns a task to a user
 * accepts data of type {@link TaskAssignRequest}
 */
export const doAssignTask = api.apiCreate<TaskAssignRequest, TaskResponse>(
	'tasks/assign',
	'tasks/:id/assign',
);

export const doUnassignTask = api.apiCreate<GenericIdRequest, TaskResponse>(
	'tasks/unassign',
	'tasks/:id/unassign',
);

export const doCloseTask = api.apiCreate<GenericIdRequest, TaskResponse>(
	'tasks/close',
	'tasks/:id/close',
);

export const doSetTaskValues = api.apiBulkCreate<
	TaskFieldValuesRequest,
	TaskFieldValuesRequest | TaskFieldValueResponse
>('tasks/setValues', 'tasks/:taskId/values');

export const doSetTaskToStub = api.apiCreate<GenericIdRequest, TaskResponse>(
	'tasks/stub',
	'tasks/:id/stub',
);

/**
 * Fetches task archetypes
 * returns data of type {@link TaskArchetypesResponse}
 */
export const doListTaskArchetypes = api.apiList<TaskArchetypesResponse>(
	'tasks/archetypes/list',
	'tasks/archetype',
);

export const doCreateTaskArchetype = api.apiCreate<TaskArchetypeRequest, TaskArchetypeResponse>(
	'tasks/create',
	'tasks/archetype',
);

export const doEditTaskArchetype = api.apiEdit<TaskArchetypeResponse, TaskArchetypeResponse>(
	'tasks/edit',
	'tasks/archetype',
);

export const doDeleteTaskArchetype = api.apiDelete<GenericIdRequest, TaskArchetypeResponse>(
	'tasks/delete',
	'tasks/archetype',
);
// #endregion

// #region Task Field Archetypes
export const doListTaskFieldArchetypes = api.apiList<TaskFieldArchetypesResponse>(
	'taskFieldArchetypes/list',
	'tasks/fields/archetype',
);

export const doCreateTaskFieldArchetype = api.apiCreate<
	TaskFieldArchetypeRequest,
	TaskFieldArchetypeResponse
>('taskFieldArchetypes/create', 'tasks/fields/archetype');

export const doEditTaskFieldArchetype = api.apiEdit<
	TaskFieldArchetypeResponse,
	TaskFieldArchetypeResponse
>('taskFieldArchetypes/edit', 'tasks/fields/archetype');

export const doDeleteTaskFieldArchetype = api.apiDelete<
	GenericIdRequest,
	TaskFieldArchetypeResponse
>('taskFieldArchetypes/delete', 'tasks/fields/archetype');
// #endregion

// #region Task Chain Archetypes
export const doListTaskChainArchetypes = api.apiList<ChainArchetypesResponse>(
	'taskChainArchetypes/list',
	'tasks/chain',
);

export const doCreateTaskChainArchetype = api.apiCreate<
	ChainArchetypeRequest,
	ChainArchetypesResponse
>('taskChainArchetypes/create', 'tasks/chain');

export const doEditTaskChainArchetype = api.apiEdit<ChainArchetypeResponse, AffectedRowsResponse>(
	'taskChainArchetypes/edit',
	'tasks/chain',
);

export const doDeleteTaskChainArchetype = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'taskChainArchetypes/delete',
	'tasks/chain',
);
// #endregion

// #region Task Field Parameters
export const doListTaskFieldParameters = api.apiList<TaskFieldParametersResponse>(
	'taskFieldParameters/list',
	'tasks/fields/parameters',
);

export const doCreateTaskFieldParameter = api.apiCreate<
	TaskFieldParameterRequest,
	TaskFieldParameterResponse
>('taskFieldParameters/create', 'tasks/fields/parameters');

export const doEditTaskFieldParameter = api.apiEdit<
	TaskFieldParameterResponse,
	AffectedRowsResponse
>('taskFieldParameters/edit', 'tasks/fields/parameters');

export const doDeleteTaskFieldParameter = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'taskFieldParameters/delete',
	'tasks/fields/parameters',
);
// #endregion

// #region Task Field Sets
export const doListTaskFieldSetsArchetypes = api.apiList<TaskFieldSetArchetypesResponse>(
	'taskFieldSetArchetypes/list',
	'tasks/fields/set',
);

export const doCreateTaskFieldSetArchetype = api.apiCreate<
	TaskFieldSetArchetypeRequest,
	TaskFieldSetArchetypeResponse
>('taskFieldSetArchetypes/create', 'tasks/fields/set');

export const doEditTaskFieldSetArchetype = api.apiEdit<
	TaskFieldSetArchetypeUpdateRequest,
	AffectedRowsResponse
>('taskFieldSetArchetypes/edit', 'tasks/fields/set');

export const doDeleteTaskFieldSetArchetype = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'taskFieldSetArchetypes/delete',
	'tasks/fields/set',
);

// #endregion

// #region Triggers
export const doListTriggers = api.apiList<TriggersResponse>('triggers/list', 'tasks/trigger');

export const doCreateTrigger = api.apiCreate<TriggerRequest, TriggerResponse>(
	'trigger/create',
	'tasks/trigger',
);

export const doFetchMostSpecificTrigger = api.apiCreate<TriggerResponse, TriggerResponse>(
	'trigger/mostSpecific',
	'tasks/trigger/specific',
);
// #endregion

/**
 * Parses an offset string and applies the offset to the startAt date (or now if startAt is null)
 * @param {string} offset
 * @param {moment.Moment} startAt
 * @returns {void}
 */
const parseOffsetString = (
	offset?: string | null,
	startAt: moment.Moment | null = null,
): moment.Moment => {
	const now = startAt ?? moment();
	if (!offset) return now;

	const parseDuration = (os: string) => {
		let duration = null;
		switch (os.at(-1)) {
			case 'M':
				duration = { months: parseInt(os) };
				break;
			case 'd':
				duration = { days: parseInt(os) };
				break;
			case 'h':
				duration = { hours: parseInt(os) };
				break;
			case 's':
				duration = { seconds: parseInt(os) };
				break;
			case 'm':
				duration = { minutes: parseInt(os) };
				break;
			case 'w':
				duration = { weeks: parseInt(os) };
				break;
			default:
				return null;
		}
		return duration;
	};

	if (offset.indexOf(',') === -1) {
		const duration = parseDuration(offset);
		if (duration !== null) {
			return now.add(duration);
		} else {
			return now;
		}
	} else {
		const [duration, day] = offset.split(',');
		const durationObj = parseDuration(duration);
		if (durationObj !== null) {
			const next = now.add(durationObj);
			const dayOfWeek = day.toLowerCase();

			switch (dayOfWeek) {
				case 'mo':
					return next.startOf('week').add({ days: 0 });
				case 'tu':
					return next.startOf('week').add({ days: 1 });
				case 'we':
					return next.startOf('week').add({ days: 2 });
				case 'th':
					return next.startOf('week').add({ days: 3 });
				case 'fr':
					return next.startOf('week').add({ days: 4 });
				case 'sa':
					return next.startOf('week').add({ days: 5 });
				case 'su':
					return next.startOf('week').add({ days: 6 });
				default:
					return now;
			}
		}
	}

	return now;
};

/**
 * Fetches all the tasks for the currently active chains, marking as virtual the tasks that are not instantiated
 * @param {ChainsResponse} chains - Currently active chains
 * @param {TaskState} state - The current state of the store (used for reference)
 * @returns {EnhancedTaskResponse[]} - List of tasks, with virtual tasks marked as such
 */
const fetchVirtualTasks = (state: TasksState): EnhancedTaskResponse[] => {
	const pendingTasks: EnhancedTaskResponse[] = [];
	const chains = state.activeChains;

	chains.forEach((chain) => {
		// check if there are tasks already instantiated for this chain
		// if the task is instantiated we cache the instantiated version
		// if the task is not instantiated we spoof it by caching data from the archetype
		// keep in mind that tasks have to be in the right order
		// so for that we use the taskArchetypIds from the chainArchetype
		// if the first task from the taskArchetyeIds is instantiated we have the "assignableAt"
		// from now on as soon as we get a non-instantiated task we have to calculate the "assignableAt"
		// using the previous taskArchetype "nextAssignableAt"
		const chainArchetype = state.chainArchetypes.find(
			(archetype) => archetype.id === chain.chainArchetypeId,
		);
		let lastTaskTime = moment();
		let lastTaskId: number | undefined = undefined;

		chainArchetype?.taskArchetypeIds?.forEach((taskId, it, archetypes) => {
			// Here we check if the task has been already instantiated or not
			const task = state.tasks.find(
				(task) => task.chainId === chain.id && task.archetypeId === taskId,
			);
			if (task) {
				// If the task is instantiated we cache the task and update the lastTaskTime
				lastTaskTime = moment(task.assignableAt);
				const vTask: EnhancedTaskResponse = {
					...task,
					virtual: false,
					isoAssignableAt: task.assignableAt?.toString(),
					prevTaskId: lastTaskId,
				};
				pendingTasks.push(vTask);
				lastTaskId = task.id;
			} else {
				// If the task is not instantiated we spoof the task and calculate the assignableAt
				// using the previous taskArchetype "nextAssignableAt".
				// If the task is a close order task we have to use the estimateCompletionDate
				// found in the chain. (this has been calculated at order creation time)
				const taskArchetype = state.taskArchetypes.find((archetype) => archetype.id === taskId);
				if (taskArchetype) {
					const fieldArchetypeIds = taskArchetype.taskFieldArchetypeId;
					let isCloseOrderTask = false;
					fieldArchetypeIds.forEach((fieldId) => {
						const fieldArchetype = state.taskFieldArchetypes.find(
							(archetype) => archetype.id === fieldId,
						);
						if (fieldArchetype && fieldArchetype.type === TaskFieldType.closeorder) {
							isCloseOrderTask = true;
						}
					});

					if (it - 1 >= 0) {
						const previousTaskArchetype = state.taskArchetypes.find(
							(archetype) => archetype.id === archetypes[it - 1],
						);
						if (previousTaskArchetype && !isCloseOrderTask) {
							lastTaskTime = parseOffsetString(taskArchetype.nextAssignableAt, lastTaskTime);
						}
					}

					if (isCloseOrderTask) {
						lastTaskTime = chain.estimateCompletionDate
							? moment(chain.estimateCompletionDate)
							: lastTaskTime;
					}

					const spoofedTask: EnhancedTaskResponse = {
						id: -it,
						archetypeId: +taskArchetype.id,
						archetype: taskArchetype,
						chainId: chain.id,
						title: taskArchetype.name,
						status: TaskStatus.blocked,
						isoAssignableAt: lastTaskTime.toString(),
						expiresAt: null,
						fieldValues: [],
						virtual: true,
						prevTaskId: lastTaskId,
					};
					pendingTasks.push(spoofedTask);
					lastTaskId = spoofedTask.id;
				}
			}
		});
	});

	return pendingTasks;
};

export const appTasks = createSlice({
	name: 'tasks',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doFetchTasks.pending.type,
					doListTaskFieldArchetypes.pending.type,
					doCreateTaskFieldArchetype.pending.type,
					doEditTaskFieldArchetype.pending.type,
					doDeleteTaskFieldArchetype.pending.type,
					doListTaskChainArchetypes.pending.type,
					doListTaskFieldParameters.pending.type,
					doListTriggers.pending.type,
					doListTaskFieldSetsArchetypes.pending.type,
					doCreateTaskFieldParameter.pending.type,
					doDeleteTaskFieldSetArchetype.pending.type,
					doEditTaskFieldSetArchetype.pending.type,
					doListTaskArchetypes.pending.type,
					doCreateTaskArchetype.pending.type,
					doEditTaskArchetype.pending.type,
					doDeleteTaskArchetype.pending.type,
					doCreateTrigger.pending.type,
					doCreateTaskChainArchetype.pending.type,
					doEditTaskChainArchetype.pending.type,
					doDeleteTaskChainArchetype.pending.type,
					doFetchChains.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doFetchTasks.fulfilled.type,
					doListTaskFieldArchetypes.fulfilled.type,
					doCreateTaskFieldArchetype.fulfilled.type,
					doEditTaskFieldArchetype.fulfilled.type,
					doDeleteTaskFieldArchetype.fulfilled.type,
					doListTaskChainArchetypes.fulfilled.type,
					doListTaskFieldParameters.fulfilled.type,
					doListTriggers.fulfilled.type,
					doListTaskFieldSetsArchetypes.fulfilled.type,
					doCreateTaskFieldParameter.fulfilled.type,
					doDeleteTaskFieldSetArchetype.fulfilled.type,
					doEditTaskFieldSetArchetype.fulfilled.type,
					doListTaskArchetypes.fulfilled.type,
					doCreateTaskArchetype.fulfilled.type,
					doEditTaskArchetype.fulfilled.type,
					doDeleteTaskArchetype.fulfilled.type,
					doCreateTrigger.fulfilled.type,
					doCreateTaskChainArchetype.fulfilled.type,
					doEditTaskChainArchetype.fulfilled.type,
					doDeleteTaskChainArchetype.fulfilled.type,
					doFetchChains.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				state.op_status = 'succeeded';
				switch (action.type) {
					case doFetchTasks.fulfilled.type:
						if (!_.isEqual(state.tasks, action.payload)) {
							state.tasks = action.payload;
						}
						break;
					case doListTaskFieldArchetypes.fulfilled.type:
						if (!_.isEqual(state.taskFieldArchetypes, action.payload)) {
							state.taskFieldArchetypes = action.payload;
						}
						break;
					case doListTaskChainArchetypes.fulfilled.type:
						if (!_.isEqual(state.chainArchetypes, action.payload)) {
							state.chainArchetypes = action.payload;
						}
						break;
					case doListTaskFieldParameters.fulfilled.type:
						if (!_.isEqual(state.taskFieldParameters, action.payload)) {
							state.taskFieldParameters = action.payload;
						}
						break;
					case doListTriggers.fulfilled.type:
						if (!_.isEqual(state.triggers, action.payload)) {
							state.triggers = action.payload;
						}
						break;
					case doListTaskFieldSetsArchetypes.fulfilled.type:
						if (!_.isEqual(state.sets, action.payload)) {
							state.sets = action.payload;
						}
						break;
					case doListTaskArchetypes.fulfilled.type:
						if (!_.isEqual(state.taskArchetypes, action.payload)) {
							state.taskArchetypes = action.payload;
						}
						break;
					case doFetchChains.fulfilled.type:
						if (!_.isEqual(state.activeChains, action.payload)) {
							state.activeChains = action.payload;
						}
						break;
				}
				const vTasks = fetchVirtualTasks(state);
				if (!_.isEqual(state.virtualTasks, vTasks)) {
					state.virtualTasks = vTasks;
				}
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doFetchTasks.rejected.type,
					doListTaskFieldArchetypes.rejected.type,
					doCreateTaskFieldArchetype.rejected.type,
					doEditTaskFieldArchetype.rejected.type,
					doDeleteTaskFieldArchetype.rejected.type,
					doListTaskChainArchetypes.rejected.type,
					doListTaskFieldParameters.rejected.type,
					doListTriggers.rejected.type,
					doListTaskFieldSetsArchetypes.rejected.type,
					doCreateTaskFieldParameter.rejected.type,
					doDeleteTaskFieldSetArchetype.rejected.type,
					doEditTaskFieldSetArchetype.rejected.type,
					doListTaskArchetypes.rejected.type,
					doCreateTaskArchetype.rejected.type,
					doEditTaskArchetype.rejected.type,
					doDeleteTaskArchetype.rejected.type,
					doCreateTrigger.rejected.type,
					doCreateTaskChainArchetype.rejected.type,
					doEditTaskChainArchetype.rejected.type,
					doDeleteTaskChainArchetype.rejected.type,
					doFetchChains.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
	},
});

// export const {
// } = appTasks.actions;
export default appTasks.reducer;
