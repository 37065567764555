import useAppFunctions from '@hooks/useAppFunctions';
import useTasks from '@hooks/useTasks';
import { Cancel, Check } from '@mui/icons-material';
import {
	Box,
	Chip,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	useTheme,
} from '@mui/material';
import { AppFunction, TasksResponse } from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderButton } from '../shared/OrderButton';
import { uniqueId } from 'lodash';
import { CompoundTaskView } from '../views/compoundTask/CompoundTaskView';
import { useLayout } from '@contexts/index';

interface Props {
	tasks: TasksResponse;
	it: number;
}

export const CompactMultipleTask = ({ tasks, it }: Props) => {
	const theme = useTheme();
	const {
		taskList,
		taskFieldArchetypeList,
		canAssignTaskTo,
		canUnassign,
		getOrderFromTask,
		assignTask,
		unassignTask,
	} = useTasks();
	const { pushPopup } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const [icons, setIcons] = useState<JSX.Element[]>([]);

	const isFutureTask = false;

	const canAssignTask = tasks.every((task) => canAssignTaskTo(task));
	const canUnassignTask = tasks.every((task) => canUnassign(task));

	useEffect(() => {
		const tempIcons: JSX.Element[] = [];
		tasks[0]?.archetype?.taskFieldArchetypeId.map((id) => {
			const field = taskFieldArchetypeList.find((field) => field.id === id);
			if (field) {
				const icon = getIcon(AppFunction.TaskFieldArchetype, field.type);
				if (icon) {
					tempIcons.push(
						<Tooltip key={id} title={t(`${AppFunction.TaskFieldArchetype}.types.${field.type}`)}>
							{icon}
						</Tooltip>,
					);
				}
			}
		});
		setIcons(tempIcons);
	}, [taskFieldArchetypeList, tasks]);

	const taskChip = (): JSX.Element => {
		if (tasks[0]) {
			return (
				<Chip
					label={t(`${AppFunction.Task}.statuses.${tasks[0].status}`)}
					color='lightWarning'
					size='small'
					icon={getIcon(AppFunction.Task, tasks[0].status ?? 'default') ?? <></>}
				/>
			);
		}
		return <></>;
	};

	return (
		<ListItem
			sx={{
				background: theme.palette.gradient.primaryAlpha,
				borderRadius: '1rem',
				marginTop: it === 0 ? 0 : 1,
				cursor: isFutureTask ? 'not-allowed' : 'pointer',
				opacity: isFutureTask ? 0.5 : 1,
				transform: isFutureTask ? 'scale(0.95)' : 'scale(1)',
				transition: 'all 0.4s',
				'&:hover': {
					boxShadow: isFutureTask ? 'none' : '0 0 0.5rem 1rem rgba(255, 255, 255, 0.2)',
				},
			}}
			onClick={() => {
				if (isFutureTask) return;
				const customId = uniqueId('popup_mtask_');
				pushPopup(
					<CompoundTaskView taskIds={tasks.map((t) => t.id)} popupId={customId} />,
					false,
					true,
					customId,
				);
			}}
		>
			<ListItemAvatar>{icons}</ListItemAvatar>
			<ListItemText sx={{ width: '100%' }} primary={tasks[0]?.title} />
			<Grid container columnSpacing={1} display='flex' flexDirection='row'>
				<Grid item flexGrow={1} flexBasis={0} container rowGap={1}>
					{tasks.map((task, it) => {
						const thisTask = taskList.find((t) => t.id === task.id);
						if (!thisTask) return null;
						const order = getOrderFromTask(thisTask);
						if (order) {
							return (
								<Grid item key={it} xs={12}>
									<OrderButton orderId={order.id} />
								</Grid>
							);
						}
					})}
				</Grid>
				<Grid
					item
					flexShrink={1}
					flexBasis={0}
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{taskChip()}
				</Grid>
				<Grid item display='flex' alignItems='center' justifyContent='center'>
					{canUnassignTask && (
						<IconButton
							size='small'
							onClick={(e) => {
								e.stopPropagation();
								tasks.forEach((task) => {
									unassignTask(task.id);
								});
							}}
						>
							<Cancel color='error' />
						</IconButton>
					)}
					{canAssignTask && (
						<IconButton
							size='small'
							// disabled={isInTheFuture(task.assignableAt)}
							onClick={(e) => {
								e.stopPropagation();
								tasks.forEach((task) => {
									assignTask(task.id);
								});
							}}
						>
							<Check color='success' />
						</IconButton>
					)}
				</Grid>
			</Grid>
		</ListItem>
	);
};
