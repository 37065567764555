import { OrderDetailsContainerProps } from '../config';
import { EntryContainer } from '../EntryContainer';
import { InboundItem } from './InboundItem';
import useOrders from '@hooks/useOrders';
import { InboundItemsHeader } from './InboundItemsHeader';

export const InboundContainer = ({ data, orderProduct }: OrderDetailsContainerProps) => {
	const { isSameOrRelated } = useOrders();

	return (
		<EntryContainer data={data} showTitle>
			<InboundItemsHeader />
			{data.content?.map((content, idx) => {
				return (
					<InboundItem
						key={idx}
						content={content}
						selected={
							orderProduct !== undefined &&
							isSameOrRelated(
								content.id ? +content.id : -1,
								orderProduct.id ? +orderProduct.id : -1,
							)
						}
					/>
				);
			})}
		</EntryContainer>
	);
};
