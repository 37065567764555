import { ReactNode, useEffect } from 'react';
import useApp from '../../hooks/useApp';

import { FocusedElementData } from '../../store/app';
import { TabInnerTitle } from '../tabs/TabInnerTitle';

import styles from './ScopedComponent.module.css';

interface Props {
	children: ReactNode;
	data: FocusedElementData;
	title?: string;
	translateTitle?: boolean;
	titleShadow?: boolean;
	active?: boolean;
}

export const ScopedComponent = ({
	data,
	children,
	title,
	translateTitle: translate,
	titleShadow,
	active,
}: Props) => {
	const { pushFocusedElement, removeFocusedElement } = useApp();

	useEffect(() => {
		if (active) {
			pushFocusedElement(data);
		} else {
			removeFocusedElement(data.id, data.type);
		}
	}, [active]);

	useEffect(() => {
		return () => {
			removeFocusedElement(data.id, data.type);
		};
	}, []);

	return (
		<div
			style={{
				zIndex: active ? data.zIndex : 1,
				position: 'relative',
				width: '100%',
			}}
		>
			{title && active && (
				<div className={styles.title}>
					<TabInnerTitle
						title={title}
						translate={translate}
						size='small'
						solid
						shadow={titleShadow}
					/>
				</div>
			)}
			{children}
		</div>
	);
};
