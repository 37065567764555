import { EditShowGridField } from '@components/common/EditShowGridField';
import { useOrderEditor } from '@contexts/index';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
	AppFunction,
	MerchantType,
	OrderDirectionType,
	StorageType,
	TranslationTypes,
} from 'common';
import useMerchants from '@hooks/useMerchants';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { useTranslation } from 'react-i18next';

export const OrderHeaderSourceSelect = () => {
	const { renderType, control, configValues } = useOrderEditor();
	const { merchantList } = useMerchants();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	if (!configValues?.fromType || !configValues?.fromType.isValueOfType<OrderDirectionType[]>()) {
		return null;
	}

	const fromType = configValues['fromType'].value as OrderDirectionType[];
	if (!fromType) return null;

	const FromList = (directionType: OrderDirectionType): JSX.Element[] => {
		switch (directionType) {
			case OrderDirectionType.merchant:
				return merchantList
					.filter((m) => m.type !== MerchantType.maker)
					.map((value, it) => (
						<MenuItem key={it} value={value.id}>
							{getTranslatedString(AppFunction.Merchant, value.id, TranslationTypes.name)}
						</MenuItem>
					));

			case OrderDirectionType.warehouse:
				return warehouseList
					.filter(
						(w) => w.storageType !== StorageType.airport && w.storageType !== StorageType.port,
					)
					.map((value, it) => (
						<MenuItem key={it} value={value.id}>
							{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
						</MenuItem>
					));
			default:
				return [];
		}
	};

	// TODO: support multiple fromTypes
	if (fromType.length > 1) {
		return <div>Array has more than one element.</div>;
	} else if (fromType.length === 1) {
		return (
			<Controller
				name='fromId'
				control={control}
				render={({ field }) => (
					<EditShowGridField isEditing={true} width={2}>
						<InputLabel id='from'>{t(`order.from.${fromType[0]}`)}</InputLabel>
						<Select
							labelId='from'
							id='from'
							size='small'
							label={t(`order.from.${fromType[0]}`)}
							value={field.value ?? -1}
							disabled={false}
							onChange={(event) => {
								const asNum = event.target.value as number;
								field.onChange(asNum === -1 ? null : asNum);
							}}
							MenuProps={
								renderType === 'popup'
									? {
											style: { zIndex: 9999 },
									  }
									: {}
							}
						>
							<MenuItem key={-1} value={-1}>
								{t('order.from.undefined')}
							</MenuItem>
							{FromList(fromType[0])}
						</Select>
					</EditShowGridField>
				)}
			/>
		);
	}
	return null;
};
