import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import {
	TaskEditorSidebar,
	TaskEditorSidebarTab,
} from '@components/taskworkflow/TaskEditorSidebar';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { TaskFieldType } from 'common';
import {
	Abc,
	AttachFile,
	CenterFocusStrong,
	CheckBox,
	DateRange,
	ListAlt,
	Pin,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';

export enum EditingMode {
	Chain = 'chain',
	Task = 'task',
	None = 'none',
}

export enum DragZoneName {
	taskArchetypeDroppableList = 'task-archetype-droppable-list',
	fieldArchetypeDroppableList = 'field-archetype-droppable-list',
	fieldSetArchetypeDroppableList = 'field-set-archetype-droppable-list',
}

export enum DropZoneName {
	taskArchetypeDropZone = 'task-archetype-drop-zone',
	taskFieldDropZone = 'task-field-drop-zone',
}

export interface DragDropState {
	state: 'none' | 'dragging' | 'draggingOver' | 'dropped';
	sourceType?: DragZoneName | string;
	sourceId?: number;
	destinationType?: DropZoneName | string;
	destinationId?: number;
	insertIndex?: number;
	draggedElement?: Element | null;
}

interface ChainEditorContextType {
	dragDropState: DragDropState;
	panDisabled: boolean;
	zoomPanState?: ReactZoomPanPinchRef | undefined;
	setDragDropState: (state: DragDropState) => void;
	setEditingMode: (mode: EditingMode) => void;
	setIsHoveringChain: (isHovering: boolean) => void;
	setIsMouseDownOnChain: (isMouseDown: boolean) => void;
	focusOnElement: (elementId: string) => void;
}

interface ChainEditorContextProps {
	children: React.ReactNode;
}

const defaultContext: ChainEditorContextType = {
	dragDropState: { state: 'none' },
	panDisabled: false,
	setDragDropState: () => {
		console.warn('setDragDropState was called without being provided by a context provider');
	},
	setEditingMode: () => {
		console.warn('setEditingMode was called without being provided by a context provider');
	},
	setIsHoveringChain: () => {
		console.warn('setIsHoveringChain was called without being provided by a context provider');
	},
	setIsMouseDownOnChain: (isMouseDown: boolean) => {
		console.warn(
			`setIsMouseDownOnChain to ${isMouseDown} was called without being provided by a context provider`,
		);
	},
	focusOnElement: () => {
		console.warn('focusOnElement was called without being provided by a context provider');
	},
};

const ChainEditorContext = createContext<ChainEditorContextType>(defaultContext);

export const getIcon = (type: TaskFieldType): ReactNode => {
	switch (type) {
		case TaskFieldType.string:
			return <Abc />;
		case TaskFieldType.number:
			return <Pin />;
		case TaskFieldType.checkbox:
			return <CheckBox />;
		case TaskFieldType.date:
			return <DateRange />;
		case TaskFieldType.dropdown:
			return <ListAlt />;
		case TaskFieldType.fileinput:
			return <AttachFile />;
		default:
			return <></>;
	}
};

export const ChainEditorProvider: React.FC<ChainEditorContextProps> = ({ children }) => {
	const [taskBarTabs, setTaskBarTabs] = useState<TaskEditorSidebarTab[]>([
		TaskEditorSidebarTab.TaskArchetype,
	]);
	const [panDisabled, setPanDisabled] = useState<boolean>(false);

	const [zoomPanState, setZoomPanState] = useState<ReactZoomPanPinchRef | undefined>(undefined);
	const [dragDropState, setDragDropState] = useState<DragDropState>({ state: 'none' });
	const [editingMode, setEditingMode] = useState<EditingMode>(EditingMode.Chain);
	const [isHoveringChain, setIsHoveringChain] = useState<boolean>(false);
	const [isMouseDownOnChain, setIsMouseDownOnChain] = useState<boolean>(false);

	const [currentlyEditing, setCurrentlyEditing] = useState<string | undefined>(undefined);

	const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);

	const focusOnElement = (elementId: string) => {
		const element = document.getElementById(elementId);
		const wrapper = document.getElementById('chain-editor-wrapper');
		const sidebar = document.getElementById('chain-editor-sidebar');
		if (element && wrapper) {
			const rect = element.getBoundingClientRect();

			// Calculate center and dimensions
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			const elementWidth = rect.width;
			const elementHeight = rect.height;

			const wrapperWidth = wrapper.getBoundingClientRect().width || 0;
			const wrapperHeight = wrapper.getBoundingClientRect().height || 0;
			const wrapperCenterX = wrapperWidth / 2;
			const wrapperCenterY = wrapperHeight / 2;

			const sidebarWidth = sidebar?.getBoundingClientRect().width || 0;

			// console.log(sidebarWidth);

			// console.log(centerX, centerY);

			// const newScale = calculateNewScale(elementWidth, elementHeight);
			const newScale = 1;
			const newPositionX =
				-wrapperCenterX * newScale +
				wrapperWidth / 2 +
				window.innerWidth / 2 -
				elementWidth / 2 -
				sidebarWidth;
			const newPositionY = -wrapperCenterY * newScale + wrapperHeight / 2 + 100;

			// Apply the new transformation
			transformWrapperRef.current?.setTransform(
				newPositionX,
				newPositionY,
				newScale,
				800,
				'easeInOutQuad',
			);
		}
	};

	useEffect(() => {
		switch (editingMode) {
			case EditingMode.Chain:
				setTaskBarTabs([TaskEditorSidebarTab.TaskArchetype]);
				break;
			case EditingMode.Task:
				setTaskBarTabs([TaskEditorSidebarTab.FieldArchetype, TaskEditorSidebarTab.FieldSet]);
				break;
			case EditingMode.None:
				setTaskBarTabs([]);
				break;
		}
	}, [editingMode]);

	useEffect(() => {
		if (
			isHoveringChain ||
			['dragging', 'draggingOver', 'dropped'].includes(dragDropState.state) ||
			isMouseDownOnChain
		) {
			setPanDisabled(true);
		} else {
			setPanDisabled(false);
		}
	}, [isHoveringChain, dragDropState, isMouseDownOnChain]);

	const onDragStart = (start: DragStart) => {
		const domQuery = `[data-rbd-drag-handle-draggable-id='${start.draggableId}']`;
		const draggedDOM = document.querySelector(domQuery);
		const source = start.source?.droppableId;
		const sourceArray = start.source?.droppableId?.split('_');
		let sourceId = start.draggableId;
		if (sourceArray.length > 1) {
			sourceId = start.source.index.toString();
		}
		setDragDropState({
			state: 'dragging',
			sourceType: source as DragZoneName,
			sourceId: sourceId ? parseInt(sourceId) : undefined,
			draggedElement: draggedDOM,
		});
	};

	const onDragUpdate = (update: DragUpdate) => {
		const destinationArray = update.destination?.droppableId?.split('_');
		const destination = destinationArray?.[0];
		const destinationId = destinationArray?.[1];
		setDragDropState((currentState) => ({
			...currentState,
			state: destination ? 'draggingOver' : 'dragging',
			destinationType: destination as DropZoneName,
			destinationId: destinationId ? parseInt(destinationId) : undefined,
			insertIndex: update.destination?.index,
		}));
	};

	const onDragEnd = (result: DropResult) => {
		const destinationId = result.destination?.droppableId;
		const destination = destinationId?.split('_');
		const sourceId = result.source?.droppableId;
		if (destination && sourceId) {
			setDragDropState((currentState) => ({
				...currentState,
				state: 'dropped',
				insertIndex: result.destination?.index,
			}));
		} else {
			setDragDropState({
				state: 'none',
			});
		}
	};

	const contextValue = {
		dragDropState,
		panDisabled,
		zoomPanState,
		setDragDropState,
		setEditingMode,
		setIsHoveringChain,
		setIsMouseDownOnChain,
		focusOnElement,
	};

	return (
		<ChainEditorContext.Provider value={contextValue}>
			<DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
				<TaskEditorSidebar tabs={taskBarTabs} />
				<div
					onMouseUp={() => setIsMouseDownOnChain(false)}
					style={{ height: '100%', width: '100%', position: 'absolute', top: 0 }}
					id='chain-editor-wrapper'
				>
					<TransformWrapper
						ref={transformWrapperRef}
						initialScale={1}
						minScale={0.1}
						maxPositionX={1000}
						maxPositionY={1000}
						limitToBounds={false}
						doubleClick={{
							disabled: panDisabled,
							mode: 'zoomIn',
							excluded: ['dcExcluded'],
						}}
						centerOnInit
						panning={{
							disabled: panDisabled,
							excluded: ['panExcluded'],
						}}
						onZoomStop={(ref) => setZoomPanState(ref)}
						onPanningStop={(ref) => setZoomPanState(ref)}
					>
						<div
							style={{
								position: 'absolute',
								height: '3rem',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								padding: '0 0.2rem 0 0.2rem',
								top: '4rem',
								zIndex: 1000,
							}}
						>
							<IconButton
								onClick={() => {
									// if (currentlyEditing) {
									//     focusOnElement(currentlyEditing);
									// } else {
									//     console.log('not editing anything');
									// }
									focusOnElement('testChainId');
								}}
							>
								<CenterFocusStrong />
							</IconButton>
						</div>
						<TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
							{children}
						</TransformComponent>
					</TransformWrapper>
				</div>
			</DragDropContext>
		</ChainEditorContext.Provider>
	);
};

export const useChainEditor = () => useContext(ChainEditorContext);
