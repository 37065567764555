import { OrderDetailsContainerProps } from '../config';
import { EntryContainer } from '../EntryContainer';
import useOrders from '@hooks/useOrders';

export const OutboundContainer = ({ data, orderProduct }: OrderDetailsContainerProps) => {
	const { isSameOrRelated } = useOrders();

	return (
		<EntryContainer data={data} showTitle>
			{data.content?.map((content, idx) => {
				const related = isSameOrRelated(
					content.id ? +content.id : -1,
					orderProduct?.id ? +orderProduct.id : -1,
				);
				return (
					<div key={idx} style={{ color: related ? 'red' : 'black' }}>
						{content.id}
					</div>
				);
			})}
		</EntryContainer>
	);
};
