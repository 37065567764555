import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app';
import userReducer from './user';
import taskReducer from './tasks';
import productsReducer from './products';
import storagesReducer from './warehouses';
import merchantsReducer from './merchants';
import orderReducer from './order';
import clientsReducer from './customers';
import rolesReducer from './role';
import carriersReducer from './carriers';
import addressesReducer from './addresses';
import geographyReducer from './geography';
import peopleReducer from './people';
import imagesReducer from './images';
import groupsReducer from './groups';
import inventoryStateReducer from './inventory';
import noteStateReducer from './notes';

export type NetworkOperationStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

const store = configureStore({
	reducer: {
		app: appReducer,
		customers: clientsReducer,
		orders: orderReducer,
		products: productsReducer,
		roles: rolesReducer,
		storages: storagesReducer,
		merchants: merchantsReducer,
		carriers: carriersReducer,
		tasks: taskReducer,
		user: userReducer,
		addresses: addressesReducer,
		geography: geographyReducer,
		people: peopleReducer,
		images: imagesReducer,
		groups: groupsReducer,
		inventoryState: inventoryStateReducer,
		notes: noteStateReducer,
	},
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
