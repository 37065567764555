import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	EntityImageResponse,
	EntityImagesRequest,
	GenericIdsRequest,
	ImageResponse,
	ImagesResponse,
} from 'common';
import _ from 'lodash';
import {
	doCreateEntityImageLinks,
	doDeleteEntityImageLinks,
	doDeleteImages,
	doEditEntityImageLink,
	doEditImage,
} from '../store/images';

function useImages() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.images.opStatus);
	const imagesSlice = useAppSelector((state) => state.images.images);

	const [imageList, setImageList] = useState<ImagesResponse>(imagesSlice);

	useEffect(() => {
		setImageList((currentList) => {
			if (!_.isEqual(currentList, imagesSlice)) {
				return imagesSlice;
			}
			return currentList;
		});
	}, [imagesSlice]);

	const editImage = async (data: ImageResponse) => dispatch(doEditImage(data));

	const deleteImages = async (data: GenericIdsRequest) => dispatch(doDeleteImages(data));

	const createEntityImageLinks = async (data: EntityImagesRequest) =>
		dispatch(doCreateEntityImageLinks(data));

	const editEntityImageLink = async (data: EntityImageResponse) =>
		dispatch(doEditEntityImageLink(data));

	const deleteEntityImageLinks = async (data: EntityImagesRequest) =>
		dispatch(doDeleteEntityImageLinks(data));

	return {
		opStatus,
		imageList,
		editImage,
		deleteImages,
		createEntityImageLinks,
		editEntityImageLink,
		deleteEntityImageLinks,
	};
}

export default useImages;
