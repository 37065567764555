import { SxProps, Theme } from '@mui/material';

export const config: SxProps<Theme> = {
	'&& .MuiInputLabel-root': {
		pointerEvents: 'none',
	},
	'&& .MuiInputBase-root': {
		color: 'black',
		pointerEvents: 'none',
		borderColor: 'transparent',
		opacity: 1,
		backgroundColor: 'background.paper',
	},
	'&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent !important',
	},
	'&& .MuiInput-underline:before': {
		borderBottom: 'none !important',
	},
	'&& .Mui-disabled': {
		color: 'black !important',
		WebkitTextFillColor: 'black !important',
	},
};
