import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	AddressesResponse,
	AddressRequest,
	AddressResponse,
	AffectedRowsResponse,
	GenericIdRequest,
} from 'common';

export interface AddressesState {
	addresses: AddressesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: AddressesState = {
	addresses: [],
	op_status: 'idle',
};

export const doListAddresses = api.apiList<AddressesResponse>('addresses/list', 'addresses');

export const doCreateAddress = api.apiCreate<AddressRequest, AddressResponse>(
	'addresses/create',
	'addresses',
);

export const doEditAddress = api.apiEdit<AddressResponse, AffectedRowsResponse>(
	'addresses/edit',
	'addresses',
);

export const doDeleteAddress = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'addresses/delete',
	'addresses',
);

export const appStorages = createSlice({
	name: 'addresses',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(doListAddresses.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListAddresses.fulfilled, (state, action) => {
			state.addresses = action.payload;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListAddresses.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateAddress.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateAddress.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateAddress.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditAddress.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditAddress.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditAddress.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeleteAddress.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteAddress.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteAddress.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export default appStorages.reducer;
