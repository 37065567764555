import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomers from '../hooks/useCustomers';

import { CompanyChildSelector } from './CompanyChildSelector';

import { Button, Grid, Typography } from '@mui/material';

interface Props {
	clientId: number;
}

export const CompanyChildrenSelector = ({ clientId }: Props) => {
	const { customerList } = useCustomers();
	const { t } = useTranslation();

	const [isCreating, setCreating] = useState(false);

	return (
		<Grid
			container
			spacing={0}
			item
			xs={12}
			sx={{
				border: '1px solid rgba(0, 0, 0, 0.23)',
				padding: '4px',
				borderRadius: '4px',
				position: 'relative',
			}}
		>
			<Typography
				component={'span'}
				variant='caption'
				gutterBottom
				sx={{
					paddingLeft: '6px',
					paddingRight: '6px',
					fontSize: '0.72rem',
					transform: 'translate(4px, -4px)',
					color: 'rgba(0, 0, 0, 0.6)',
					position: 'absolute',
					top: '-7px',
					backgroundColor: 'white',
					textAlign: 'left',
				}}
			>
				{t('customer.childCompany')}
			</Typography>
			{customerList
				.filter((cu) => cu.parentId === clientId)
				.map((ch, it) => {
					if (ch.id) {
						return <CompanyChildSelector key={it} childId={ch.id} />;
					}
				})}
			{isCreating ? (
				<>
					<Grid item xs={6} sx={{ paddingRight: '5px' }}>
						<Button variant='contained' fullWidth>
							save
						</Button>
					</Grid>
					<Grid item xs={6} sx={{ paddingLeft: '5px' }}>
						<Button variant='outlined' fullWidth onClick={() => setCreating(false)}>
							cancel
						</Button>
					</Grid>
				</>
			) : (
				// <Grid item xs={12}>
				//     <Button
				//         variant='outlined'
				//         fullWidth
				//         onClick={() => setCreating(true)}
				//     >
				//         +
				//     </Button>
				// </Grid>
				<></>
			)}
		</Grid>
	);
};
