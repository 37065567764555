export const getParsedId = (id?: string | number | null): number | null => {
	if (!id) {
		console.error('Content ID is missing');
		return null;
	}
	const parsedId = Number(id);
	if (isNaN(parsedId)) {
		console.error('Content ID is not a number');
		return null;
	}
	return parsedId;
};
