import { useLayout } from '@contexts/index';
import { Keyboard } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useAppDispatch } from '@store/hooks';
import { doOrderChuckNorris, doTasksChuckNorris } from '@store/order';
import { useEffect } from 'react';

export const ControlRoom = () => {
	const { setBreadCrumbs } = useLayout();
	const dispatch = useAppDispatch();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'control room',
				icon: <Keyboard />,
			},
		]);
	}, []);

	return (
		<Box
			sx={{
				padding: 2,
			}}
		>
			<Stack direction='column' gap={2}>
				<Button onClick={() => dispatch(doOrderChuckNorris())} variant='contained'>
					Orders Chuck Norris
				</Button>
				<Button onClick={() => dispatch(doTasksChuckNorris())} variant='contained'>
					Tasks Chuck Norris
				</Button>
			</Stack>
		</Box>
	);
};
