import { useEffect, useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { checkReadPermission, checkWritePermission } from '../permissions';
import { AppFunction } from 'common';
import { useTranslation } from 'react-i18next';

export interface DropdownEntry {
	entryName: string;
	entryIcon: JSX.Element;
	entryAction?: () => void;
	entryLink?: string;
	permission?: AppFunction;
	permissionType?: 'read' | 'write';
	disabled?: boolean;
}

interface Props {
	onEdit?: () => void;
	onDelete?: () => void;
	entries?: DropdownEntry[];
	disabled?: boolean;
	forcedAnchor?: HTMLElement;
}

export const ContextualDropdownMenu = (props: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();

	const navigate = useNavigate();
	const userRole = useAppSelector((state) => state.roles.userRole);

	useEffect(() => {
		if (props.forcedAnchor) {
			console.log(props.forcedAnchor.getBoundingClientRect());
			setAnchorEl(props.forcedAnchor);
		}
	}, [props.forcedAnchor]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const renderOption = (entry: DropdownEntry, key: string): JSX.Element => {
		return (
			<MenuItem
				key={key}
				disabled={entry.disabled}
				onClick={() => {
					if (entry.disabled) return;
					handleClose();
					if (entry.entryLink) {
						navigate(entry.entryLink);
					} else {
						entry.entryAction?.();
					}
				}}
			>
				<ListItemIcon>{entry.entryIcon}</ListItemIcon>
				<Typography variant='inherit' sx={{ textTransform: 'uppercase' }}>
					{entry.entryName}
				</Typography>
			</MenuItem>
		);
	};

	const renderOptions = () => {
		const options: JSX.Element[] = [];

		if (props.onEdit != undefined) {
			options.push(
				renderOption(
					{
						entryAction: () => {
							props.onEdit?.();
							handleClose();
						},
						entryIcon: <EditIcon />,
						entryName: t('operations.edit'),
					},
					'edit',
				),
			);
		}
		if (props.onDelete != undefined) {
			options.push(
				renderOption(
					{
						entryAction: () => {
							props.onDelete?.();
							handleClose();
						},
						entryIcon: <DeleteForeverIcon />,
						entryName: t('operations.delete'),
					},
					'delete',
				),
			);
		}

		if (props.entries) {
			props.entries.map((entry, it) => {
				let render = true;
				if (entry.permission && userRole) {
					switch (entry.permissionType) {
						case 'read':
							render = checkReadPermission(userRole, entry.permission) !== false;
							break;
						case 'write':
							render = checkWritePermission(userRole, entry.permission) !== false;
							break;
					}
				}

				if (render) {
					options.push(renderOption(entry, `entry_${it}`));
				}
			});
		}

		return options;
	};

	return (
		<>
			<div>
				<IconButton
					aria-label='more'
					aria-controls='dropdown-menu'
					aria-haspopup='true'
					onClick={handleClick}
					sx={{ padding: '0px' }}
					disabled={props.disabled}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu id='dropdown-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
					{renderOptions()}
				</Menu>
			</div>
		</>
	);
};
