import useOrders from '@hooks/useOrders';

import { config } from './config';

interface Props {
	orderId: number;
	taskFieldId: number;
}

export const CloseOrderField = ({ orderId, taskFieldId }: Props) => {
	const { orderList } = useOrders();

	const order = orderList.find((order) => order.id === orderId);

	if (!order || !order.orderType) {
		return null;
	}

	const fieldConfig = config[order?.orderType];

	if (!fieldConfig) {
		return null;
	}

	return (
		<div style={{ padding: '1rem' }}>
			<fieldConfig.containerComponent taskFieldId={taskFieldId} />
		</div>
	);
};
