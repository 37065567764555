import useLocale from '@hooks/useLocale';
import { FiberNew } from '@mui/icons-material';
import { Box, Button, Chip, MenuItem, Select, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown, { ExtraProps } from 'react-markdown';
import { remark } from 'remark';
import remarkParse from 'remark-parse';

const dateRegex = /^\[.*\] - \d{4}-\d{2}-\d{2}$/;
// const versionRegex = /^## \[(\d+\.\d+\.\d+[a-zA-Z]?)\] - \d{4}-\d{2}-\d{2}$/;
const versionRegex = /^## \[(\d+\.\d+\.\d+(-[A-Za-z0-9]+)?)\]/;

// TODO: works. Now add ol, ul, li and then style it

const IgnoreRenderer = ({ node }: ExtraProps): JSX.Element | null => {
	return null;
};

export const ChangelogView = () => {
	const theme = useTheme();
	const { currentLanguage } = useLocale();
	const [changelog, setChangelog] = useState<string>('');
	const [rawChangelog, setRawChangelog] = useState<string>('');
	const [versions, setVersions] = useState<string[]>([]);
	const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchChangelog = async () => {
			const token = process.env.REACT_APP_GITHUB_TOKEN;

			let prefix: string | undefined;
			switch (currentLanguage) {
				case 'jp':
					prefix = 'ja';
					break;
				case 'it':
					prefix = 'it';
					break;
			}

			let suffix: string | undefined;
			switch (process.env.REACT_APP_NODE_ENV) {
				case 'development':
					suffix = 'develop';
					break;
			}

			const response = await fetch(
				`https://api.github.com/repos/Yugenstudio/fji_front/contents/CHANGELOG${
					prefix !== undefined ? `.${prefix}` : ''
				}.md${suffix !== undefined ? `?ref=${suffix}` : ''}`,
				{
					headers: {
						Authorization: `token ${token}`,
						Accept: 'application/vnd.github.v3.raw',
					},
				},
			);

			if (response.ok) {
				const text = await response.text();
				parseChangelog(text);
			} else {
				console.error('Error fetching changelog:', response.statusText);
				setChangelog('Error fetching changelog');
			}
		};

		fetchChangelog();
	}, [currentLanguage]);

	const parseChangelog = async (content: string) => {
		setRawChangelog(content);
		const file = await remark().use(remarkParse).process(content);
		setChangelog(String(file));

		const lines = content.split('\n');
		const extractedVersions: string[] = [];
		lines.forEach((line) => {
			const match = line.match(versionRegex);
			if (match) {
				extractedVersions.push(match[1]);
			}
		});
		setVersions(extractedVersions);
		const currentVersion = process.env.REACT_APP_VERSION?.replace(/^V/, ''); // Remove 'V' if it exists
		setSelectedVersion(currentVersion);
	};

	const CustomVersionRenderer = ({ node }: ExtraProps): JSX.Element | null => {
		if (node) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const text = node.children[0].value;
			if (typeof text === 'string') {
				if (!dateRegex.test(text)) {
					return null;
				}
				if (text.includes(`[${selectedVersion}]`) || selectedVersion === 'all') {
					return (
						<Box
							sx={{
								// backgroundColor: 'red',
								border: '1px solid black',
								borderRadius: '0.4rem',
								padding: '0.4rem',
								marginBottom: '1rem',
							}}
						>
							{text}
							{EncapsulatedContent(text)}
						</Box>
					);
				}
			}
		}
		return null;
	};

	const CustomHeadingRenderer = ({ node }: ExtraProps): JSX.Element | null => {
		if (node) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const text = node.children[0].value;
			if (typeof text === 'string') {
				const getColor = (text: string): 'lightWarning' | 'primary' | 'info' | 'error' | null => {
					if (text.includes('Fixed')) return 'lightWarning';
					if (text.includes('Added')) return 'primary';
					if (text.includes('Changed')) return 'info';
					if (text.includes('Removed')) return 'error';
					return null;
				};

				const color = getColor(text);
				if (color) {
					return (
						<Box
							sx={{
								marginTop: '1rem',
							}}
						>
							<Chip label={text} color={color} size='small' />
						</Box>
					);
				}
			}
			return React.createElement(`${node.tagName}`, {}, text);
		}
		return null;
	};

	const EncapsulatedContent = (startVersion: string) => {
		const lines = rawChangelog.split('\n');
		const startIndex = lines.findIndex((line) => line.includes(startVersion));
		let endIndex = lines.slice(startIndex + 1).findIndex((line) => versionRegex.test(line));
		if (endIndex !== -1) {
			endIndex += startIndex + 1;
		} else {
			endIndex = lines.length;
		}

		const content = lines.slice(startIndex, endIndex).join('\n');

		return (
			<ReactMarkdown
				components={{
					h1: IgnoreRenderer,
					h2: IgnoreRenderer,
					h3: CustomHeadingRenderer,
					ul: (props) => <ul {...props} />,
					ol: (props) => <ol {...props} />,
					li: (props) => <li {...props} />,
				}}
			>
				{content}
			</ReactMarkdown>
		);
	};

	return (
		<Box
			sx={{
				position: 'relative',
				background: theme.palette.gradient.secondary,
				padding: '3.3rem 0 3.3rem 0',
				height: '100%',
			}}
		>
			<Box
				sx={{
					backgroundColor: 'white',
					borderRadius: '2rem',
					minHeight: '100%',
					padding: '2rem',
				}}
			>
				<Box display='flex' justifyContent='center' alignItems='center' columnGap={1}>
					<Button
						size='small'
						variant='contained'
						onClick={() => setSelectedVersion(process.env.REACT_APP_VERSION?.replace(/^V/, ''))}
					>
						<FiberNew sx={{ marginRight: '0.3rem' }} />
						{process.env.REACT_APP_VERSION}
					</Button>
					<Select
						size='small'
						value={selectedVersion ?? 'all'}
						onChange={(e) => setSelectedVersion(e.target.value as string)}
						sx={{
							flexGrow: 1,
							flexBasis: 0,
						}}
						MenuProps={{
							style: { zIndex: 9999 },
						}}
					>
						<MenuItem value='all'>All</MenuItem>
						{versions.map((version) => (
							<MenuItem key={version} value={version}>
								{version}
							</MenuItem>
						))}
					</Select>
				</Box>
				<ReactMarkdown
					components={{
						h1: CustomHeadingRenderer,
						h2: CustomVersionRenderer,
						h3: () => null,
						ul: () => null,
						ol: () => null,
						li: () => null,
					}}
				>
					{changelog}
				</ReactMarkdown>
			</Box>
		</Box>
	);
};
