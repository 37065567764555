import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useCurrentBreakpoint = () => {
	const theme = useTheme();
	const keys = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output, key) => {
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, '') || 'xs'
	);
};

export default useCurrentBreakpoint;
