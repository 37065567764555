import { GeneralInfo } from '@components/forms/products/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import { ProductFormContextProvider } from '@contexts/formContext/subContexts/ProductFormContext';
import useProducts from '@hooks/useProducts';
import {
	AppFunction,
	ProductResponse,
	ProductSchema,
	ProductType,
	StorageConditionType,
	TaxBracket,
} from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
	productType: ProductType;
	formAction: FormAction;
	renderType?: FormRenderType;
	forceId?: number;
}

export const ProductForm = ({ productType, formAction, renderType, forceId }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { productList, createProduct, editProduct } = useProducts();

	const [formValues, setFormValues] = useState({} as ProductResponse);
	const [id, setId] = useState<number>(-1);

	const [next, setNext] = useState<number | null>(null);
	const [prev, setPrev] = useState<number | null>(null);

	const setValues = (newId: number) => {
		const product = productList.find((p) => p.id === newId);
		if (product) {
			setFormValues(product);
		}
	};

	useEffect(() => {
		const newValues = productList.find((p) => p.id === routeId || p.id === forceId);
		if (newValues && !_.isEqual(newValues, formValues)) {
			setFormValues(newValues);
		}
	}, [productList]);

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (routeId !== -1) {
			setValues(id);
		}
		setNext(getNext());
		setPrev(getPrev());
	}, [id]);

	function getNext(): number | null {
		const filteredList = productList
			.filter((product) => product.productType === productType)
			.flatMap((product) => product.id);

		const higherIds = filteredList.filter((entityId) => entityId > id);

		if (higherIds.length === 0) {
			return null;
		}

		return Math.min(...higherIds);
	}

	function getPrev(): number | null {
		const filteredList = productList
			.filter((product) => product.productType === productType)
			.flatMap((product) => product.id);

		const lowerIds = filteredList.filter((entityId) => entityId < id);

		if (lowerIds.length === 0) {
			return null;
		}

		return Math.max(...lowerIds);
	}

	function goToNext() {
		if (next) {
			setId(next);
		}
	}

	function goToPrev() {
		if (prev) {
			setId(prev);
		}
	}

	return (
		<FormContextProvider
			appFunction={AppFunction.Product}
			createFunction={createProduct}
			subFunction={productType}
			editFunction={editProduct}
			renderType={renderType ?? FormRenderType.standalone}
			entityId={forceId ?? id}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			validationSchema={ProductSchema}
			formComponent={ProductFormContextProvider}
			hideMenu
			useFab
			sideFabs={[FormSidefabType.Info, FormSidefabType.Notes]}
			customAfterCreateLink='product/:id/edit'
			defaultValues={
				{
					id: routeId,
					productType: productType,
					isFresh: false,
					shelfLife: 0,
					taxBracket: TaxBracket.eight,
					storageType: StorageConditionType.ambient,
					strings: undefined,
				} as ProductResponse
			}
			getNext={next ? () => goToNext() : undefined}
			getPrev={prev ? () => goToPrev() : undefined}
		>
			<TabbedElement title='product.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
		</FormContextProvider>
	);
};
