import { Add, ArrowDownward } from '@mui/icons-material';
import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { EditShowGridField } from '../../common/EditShowGridField';
import { DestinationTypeSelector } from './DestinationTypeSelector';
import { useChainArchetypeEditor } from '../../../contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { TabInnerTitle } from '../../tabs/TabInnerTitle';
import { AppFunction, OrderType, TriggerType } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	viewMode?: boolean;
	addTaskDisabled?: boolean;
}

export const ChainArchetypeHead = ({ viewMode, addTaskDisabled }: Props) => {
	const { t } = useTranslation();
	const [wrapperHover, setWrapperHover] = useState<boolean>(false);

	const {
		allowedFromTypes,
		addTaskAtIndex,
		// NEW
		editorWidth,
		chainControl,
		triggerControl,
		setAllowedTypes,
		watchTrigger,
	} = useChainArchetypeEditor();

	useEffect(() => {
		if (addTaskDisabled) {
			setWrapperHover(false);
		}
	}, [addTaskDisabled]);

	const chainType = watchTrigger('type');

	return (
		<Box
			sx={{
				position: 'relative',
				width: `${editorWidth}rem`,
				transition: 'width 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
			}}
			onMouseEnter={() => setWrapperHover(addTaskDisabled ? false : true)}
			onMouseLeave={() => setWrapperHover(false)}
		>
			<Grid
				container
				sx={{
					backgroundColor: 'white',
					borderRadius: '15px',
					padding: '1rem',
					border: '1px solid black',
				}}
			>
				<Grid item xs={12}>
					<TabInnerTitle title={t(`${AppFunction.TaskChainArchetype}.name`)} size='small' />
				</Grid>
				<Controller
					name='name'
					control={chainControl}
					render={({ field }) => (
						<EditShowGridField isEditing={!viewMode} label width={12}>
							<TextField
								{...field}
								fullWidth
								label={t(`${AppFunction.TaskChainArchetype}.name`)}
								size='small'
								InputProps={{
									inputProps: {
										className: 'panExcluded',
									},
								}}
								sx={{ minHeight: '72px !important' }}
							/>
						</EditShowGridField>
					)}
				/>
				<Grid item xs={12}>
					<TabInnerTitle title={t(`${AppFunction.TaskChainArchetype}.triggerType`)} size='small' />
				</Grid>
				<Controller
					name='subtype'
					control={triggerControl}
					render={({ field }) => (
						<EditShowGridField isEditing={!viewMode} label width={12}>
							<InputLabel id='trigger-select-label'>
								{t(`${AppFunction.TaskChainArchetype}.triggerType`)}
							</InputLabel>
							<Select
								className='dcExcluded panExcluded'
								fullWidth
								label={t(`${AppFunction.TaskChainArchetype}.triggerType`)}
								labelId='trigger-select-label'
								value={field.value ?? -1}
								size='small'
								onChange={(e) => {
									switch (chainType) {
										case TriggerType.order:
											field.onChange(e.target.value as OrderType);
											setAllowedTypes(e.target.value as OrderType, true);
											break;
									}
								}}
							>
								{(field.value === null || field.value === undefined) && (
									<MenuItem value={-1}>
										<em>None</em>
									</MenuItem>
								)}
								{Object.values(OrderType).map((subType, it) => (
									<MenuItem key={it} value={subType}>
										{t(`${AppFunction.TaskChainArchetype}.triggerTypes.${subType}`)}
									</MenuItem>
								))}
							</Select>
						</EditShowGridField>
					)}
				/>
				{allowedFromTypes.length > 0 && (
					<>
						<Grid item xs={12}>
							<TabInnerTitle title={t('taskChainArchetype.startType')} size='small' />
						</Grid>
						<DestinationTypeSelector directionType='from' />
					</>
				)}
			</Grid>
			<Button
				fullWidth
				onClick={() => addTaskAtIndex(0)}
				className='panExcluded dcExcluded'
				size='small'
				sx={{
					position: 'absolute',
					bottom: '0px',
					height: wrapperHover ? '3rem' : '0rem',
					backgroundColor: 'transparent',
					borderRadius: wrapperHover ? '15px' : '0px',
					border: wrapperHover ? '1px dashed blue' : 'none',
					margin: wrapperHover ? '0.5rem 0 0.5rem 0' : '0px !important',
					transform: `scale(${wrapperHover ? 1 : 0})`,
					transition:
						'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1), height 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
			>
				<Add />
			</Button>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '3rem',
					margin: '0.5rem 0 0.5rem 0',
					transform: `scale(${!wrapperHover ? 1 : 0})`,
					transition:
						'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1), height 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
			>
				<ArrowDownward sx={{ transform: 'scale(2)' }} />
			</div>
		</Box>
	);
};
