import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DragUpdate } from 'react-beautiful-dnd';
import { Box, Container, Grid, IconButton, TextField } from '@mui/material';
import useTasks from '../../hooks/useTasks';
import { Delete, DragIndicator } from '@mui/icons-material';
import {
	TaskFieldArchetypesResponse,
	TaskFieldSetArchetypeResponse,
	TaskFieldSetArchetypeUpdateRequest,
} from 'common';
import { FadeWrapper } from '../../components/common/FadeWrapper';
import { RouterBreadcrumbs } from '../../components/RouterBreadcrumbs';
import { TabbedSection } from '../../components/tabs/TabbedSection';
import { TabFooterBody } from '../../components/tabs/TabFooterBody';
import { TabbedElement } from '../../components/tabs/TabbedElement';
import { Controller, useForm } from 'react-hook-form';
import { EditShowGridField } from '../../components/common/EditShowGridField';
import { useParams } from 'react-router-dom';
import {
	TaskEditorSidebar,
	TaskEditorSidebarTab,
} from '../../components/taskworkflow/TaskEditorSidebar';

interface Props {
	viewMode?: boolean;
}

const dropZoneName = 'drop-zone';

export const TaskFieldSetCreate = ({ viewMode }: Props) => {
	const { id: routeId } = useParams();
	const {
		taskFieldSetsList,
		taskFieldArchetypeList,
		createTaskFieldSetArchetype,
		editTaskFieldSetArchetype,
	} = useTasks();
	const [droppedItems, setDroppedItems] = useState<TaskFieldArchetypesResponse>([]);
	const [targetName, setTargetName] = useState<string | null>(null);

	const [cachedData, setCachedData] = useState<TaskFieldSetArchetypeResponse>();
	const [id, setId] = useState(routeId ? parseInt(routeId) : -1);

	const { control, reset, handleSubmit } = useForm<TaskFieldSetArchetypeUpdateRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: '',
			types: [],
		},
	});

	const onDeleteTaskField = (index: number) => {
		setDroppedItems((old) => old.filter((_, i) => i !== index));
	};

	const onSubmit = (data: TaskFieldSetArchetypeUpdateRequest) => {
		if (id === -1) {
			const request = { name: data.name, types: droppedItems.map((item) => item.id as number) };
			createTaskFieldSetArchetype(request)
				.then((res) => {
					const response = res.payload as TaskFieldSetArchetypeResponse;
					console.log(response);
					setId(response.id as number);
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			const request = { ...data, types: droppedItems.map((item) => item.id as number) };
			editTaskFieldSetArchetype(request)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	useEffect(() => {
		if (id !== -1) {
			const newData = taskFieldSetsList.find((item) => item.id === id);
			if (newData) {
				setCachedData(newData);
				reset(newData);
				const newTypes: TaskFieldArchetypesResponse = newData.types
					.filter((item) => item !== undefined)
					.map((item) =>
						taskFieldArchetypeList.find((archetype) => archetype.id === item),
					) as TaskFieldArchetypesResponse;
				setDroppedItems(newTypes);
			}
		}
	}, [id]);

	const onDragEnd = (result: DropResult) => {
		setTargetName(null);
		if (result.source && result.source.droppableId === dropZoneName) {
			if (!result.destination) return;
			const newItems = Array.from(droppedItems);
			const [removed] = newItems.splice(result.source.index, 1);
			newItems.splice(result.destination.index, 0, removed);
			setDroppedItems(newItems);
			return;
		}

		if (!result.destination) return;
		if (result.destination.droppableId === dropZoneName) {
			const itemToAdd = taskFieldArchetypeList.find(
				(item) => item.id.toString() === result.draggableId,
			);
			if (itemToAdd) {
				setDroppedItems((old) => {
					const newItems = Array.from(old);
					if (result.destination) {
						newItems.splice(result.destination.index, 0, itemToAdd);
					}
					return newItems;
				});
			}
		}
	};

	const onDragUpdate = (update: DragUpdate) => {
		if (update.destination) {
			setTargetName(update.destination.droppableId);
		} else {
			setTargetName(null);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
			<TaskEditorSidebar tabs={[TaskEditorSidebarTab.FieldArchetype]} />
			<FadeWrapper fadeTime={1000}>
				<Container maxWidth='md' sx={{ marginTop: '20px', backgroundColor: '000000' }}>
					<RouterBreadcrumbs />
					<TabbedSection
						title='tasks.taskFieldSetCreate'
						hideMenu
						footerBody={
							<TabFooterBody
								canEdit={true}
								showEditButton
								disabled={false}
								onSubmit={handleSubmit(onSubmit)}
							/>
						}
					>
						<TabbedElement title='tasks.taskFieldSetCreate'>
							<Grid container spacing={2} sx={{ marginTop: '1rem' }}>
								<Controller
									name='name'
									control={control}
									render={({ field }) => (
										<EditShowGridField width={12} isEditing={true} label>
											<TextField {...field} label='tasks.taskFieldSetName' fullWidth required />
										</EditShowGridField>
									)}
								/>
							</Grid>
							<Droppable droppableId={dropZoneName}>
								{(provided) => (
									<Box
										ref={provided.innerRef}
										{...provided.droppableProps}
										sx={{
											minHeight: '5.4rem',
											border:
												targetName === dropZoneName || droppedItems.length === 0
													? '1px dashed black'
													: '1px solid transparent',
											marginTop: '20px',
										}}
									>
										{droppedItems.map((item, index) => (
											<Draggable
												key={`listItem_${index}`}
												draggableId={`listItem_${index}`}
												index={index}
											>
												{(provided) => (
													<div
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														ref={provided.innerRef}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																paddingTop: '0.2rem',
																paddingBottom: '0.2rem',
															}}
														>
															<DragIndicator />
															<Grid
																container
																key={index}
																sx={{
																	backgroundColor: 'white',
																	borderRadius: '5px',
																	border: '1px solid black',
																	marginBottom: '5px',
																	width: '100%',
																	padding: '1rem',
																}}
															>
																<Grid item xs={11} sx={{ display: 'flex', alignItems: 'center' }}>
																	{item.name} {item.type}
																</Grid>
																<Grid
																	item
																	xs={1}
																	sx={{ display: 'flex', justifyContent: 'flex-end' }}
																>
																	<IconButton onClick={() => onDeleteTaskField(index)}>
																		<Delete />
																	</IconButton>
																</Grid>
															</Grid>
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
										{droppedItems.length === 0 && targetName !== dropZoneName && (
											<Box
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'flex-end',
													minHeight: '100%',
												}}
											>
												<p>Drag and drop fields here</p>
											</Box>
										)}
									</Box>
								)}
							</Droppable>
						</TabbedElement>
					</TabbedSection>
				</Container>
			</FadeWrapper>
		</DragDropContext>
	);
};
