import { DialogComponentProps, DialogResult } from '@contexts/feedbacksContext/types';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import {
	Autocomplete,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Popper,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { AppFunction, ProductResponse, ProductType, TranslationTypes } from 'common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface AddProductDialogResult extends DialogResult {
	type: string;
	parentId: number;
}

export interface AddProductDialogData {
	productType: ProductType;
	isGrouped: boolean;
}

export const AddProductDialog: React.FC<
	DialogComponentProps<AddProductDialogResult, AddProductDialogData>
> = ({ close, data }) => {
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { productList } = useProducts();
	const [type, setType] = useState<'variant' | 'product'>(data?.isGrouped ? 'product' : 'variant');
	const [parent, setParent] = useState<ProductResponse | null>(null);

	const handleSave = () => {
		close({
			type,
			parentId: parent?.id ?? -1,
		});
	};

	return (
		<Stack direction='column' spacing={2} sx={{ minWidth: '20rem' }}>
			<FormControl>
				<InputLabel id='language-select'>{t(`${AppFunction.Product}.productType`)}</InputLabel>
				<Select
					label={t(`${AppFunction.Product}.productType`)}
					labelId='type-select'
					name='add-product-type'
					value={type}
					onChange={(e) => setType(e.target.value as 'variant' | 'product')}
					fullWidth
					MenuProps={{
						style: { zIndex: 9999 },
					}}
				>
					<MenuItem value='variant'>{t(`appFunctions.${AppFunction.Variant}`)}</MenuItem>
					<MenuItem value='product'>{t(`appFunctions.${AppFunction.Product}`)}</MenuItem>
				</Select>
			</FormControl>
			{type === 'variant' && (
				<FormControl>
					<Autocomplete
						id='select-parent-autocomplete'
						size='small'
						options={
							data?.productType
								? productList.filter((v) => v.productType === data.productType)
								: productList
						}
						value={parent?.id === -1 ? null : productList.find((v) => v.id === parent?.id) || null}
						getOptionLabel={(option) => {
							const productName = getTranslatedString(
								AppFunction.Product,
								option.id,
								TranslationTypes.name,
							);
							return `${productName}`;
						}}
						isOptionEqualToValue={(option, value) => {
							return option?.id === value?.id;
						}}
						renderOption={(props, option) => {
							let productName = 'none';
							if (option?.id !== -1) {
								productName = getTranslatedString(
									AppFunction.Product,
									option?.id ?? -1,
									TranslationTypes.name,
								);
							}
							const label = `${productName}`;
							return <MenuItem {...props}>{label}</MenuItem>;
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`${AppFunction.Product}.parent`)}
								variant='outlined'
								inputProps={{
									...params.inputProps,
								}}
							/>
						)}
						onChange={(_, newValue) => {
							if (typeof newValue === 'object' && newValue !== null) {
								setParent(newValue);
							} else {
								setParent(null);
							}
						}}
						PopperComponent={(props) => <Popper {...props} style={{ zIndex: 9999 }} />}
					/>
				</FormControl>
			)}
			<Button
				onClick={handleSave}
				variant='contained'
				sx={{
					borderRadius: '2rem',
				}}
			>
				{t('operations.create')}
			</Button>
		</Stack>
	);
};
