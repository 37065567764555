import { createContext, useContext, useEffect, useState } from 'react';
import { PopupData, PopupsHandler } from '../../components/layout/PopupsHandler';
import _, { uniqueId } from 'lodash';
import { LoadingHandler } from '@components/layout/loading/LoadingHandler';
import { FeedbacksProvider } from '@contexts/feedbacksContext/FeedbacksContext';
import {
	BreadCrumb,
	DeviceType,
	ElementPosition,
	FabData,
	LayoutContextProps,
	LayoutContextType,
	LoadingData,
	PaddingPosition,
	PaddingSettings,
	SubMenuElement,
} from './types';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { doEditDrawerStateSettings, doEditTextSizeSettings } from '@store/app';
import { useAuth } from '@hooks/auth';

const defaultContext: LayoutContextType = {
	visiblePadding: { top: 0, bottom: 0, left: 0, right: 0 },
	drawerOpen: true,
	showHelp: false,
	breadCrumbs: [],
	sideMenuContent: null,
	popupContent: [],
	popupStates: {},
	loadingData: null,
	fabData: [],
	deviceType: DeviceType.desktop,
	selectedSubMenuArray: null,
	subMenuElements: null,
	subMenuFullScreen: false,
	subMenuPosition: null,
	setDrawerOpen: () => console.warn('no feedbacks provider'),
	setFontSize: () => console.warn('no feedbacks provider'),
	setBreadCrumbs: () => console.warn('no feedbacks provider'),
	setSideMenu: () => console.warn('no feedbacks provider'),
	pushPopup: () => console.warn('no feedbacks provider'),
	popPopup: () => console.warn('no feedbacks provider'),
	closeAllPopups: () => console.warn('no feedbacks provider'),
	setHasClickedInPopup: () => console.warn('no feedbacks provider'),
	pushLoadingData: () => console.warn('no feedbacks provider'),
	popLoadingData: () => console.warn('no feedbacks provider'),
	setFabs: () => console.warn('no feedbacks provider'),
	registerElement: () => console.warn('no feedbacks provider'),
	unregisterElement: () => console.warn('no feedbacks provider'),
	setSubMenuFullScreen: () => console.warn('no feedbacks provider'),
	selectSubMenuElement: () => console.warn('no feedbacks provider'),
	pushSubMenuElements: () => console.warn('no feedbacks provider'),
	setSubMenuPosition: () => console.warn('no feedbacks provider'),
	pixelsToRem: () => 0,
	remToPixels: () => 0,
};

const LayoutContext = createContext<LayoutContextType>(defaultContext);

export const LayoutProvider = ({ children }: LayoutContextProps) => {
	const dispatch = useAppDispatch();
	const auth = useAuth();
	const settingsSlice = useAppSelector((state) => state.app.settings);

	const [layoutElements, setLayoutElements] = useState<Record<string, ElementPosition>>({});
	const [fontSize, setFontSize] = useState<number>(settingsSlice?.textSize ?? 12);
	const [visiblePadding, setVisiblePadding] = useState<PaddingSettings>({
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	});
	const [drawerOpen, setDrawerOpen] = useState(settingsSlice?.showDrawer ?? false);
	const [showHelp, setShowHelp] = useState(false);

	const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);

	const [sideMenuContent, setSideMenuContent] = useState<React.ReactNode | null>(null);

	const [popupContent, setPopupContent] = useState<PopupData[]>([]);
	const [popupStates, setPopupStates] = useState<Record<string, boolean>>({});
	const [hasClickedInPopup, setHasClickedInPopup] = useState(false);

	/**
	 * Loading state
	 */
	const [loadingData, setLoadingData] = useState<LoadingData | null>(null);

	/**
	 * FABs
	 */
	const [fabData, setFabData] = useState<FabData[]>([]);

	/**
	 * Device type
	 */
	const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.desktop);

	const [selectedSubMenuArray, setSelectedSubMenuArray] = useState<number[] | null>(null);
	const [subMenuElements, setSubMenuElements] = useState<SubMenuElement[] | null>(null);
	const [subMenuFullScreen, setSubMenuFullScreen] = useState(false);
	const [subMenuPosition, setSubMenuPosition] = useState<DOMRect | null>(null);

	/**
	 * Detects the device type on mount.
	 */
	useEffect(() => {
		const userAgent = navigator.userAgent;
		if (/mobile/i.test(userAgent)) {
			setDeviceType(DeviceType.mobile);
		} else if (/tablet/i.test(userAgent)) {
			setDeviceType(DeviceType.mobile);
		} else {
			setDeviceType(DeviceType.desktop);
		}
	}, []);

	useEffect(() => {
		document.documentElement.style.fontSize = `${fontSize}px`;
		setFabData((oldFabs) => {
			const newSize: 'small' | 'medium' | 'large' =
				fontSize <= 12 ? 'small' : fontSize <= 16 ? 'medium' : 'large';

			let push = false;
			const newFabs = oldFabs.map((fab) => {
				if (fab.size !== newSize) {
					push = true;
				}
				return { ...fab, size: newSize };
			});
			if (push) {
				return newFabs;
			}
			return oldFabs;
		});
	}, [fontSize]);

	useEffect(() => {
		const padding: PaddingSettings = {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		};
		Object.keys(layoutElements).forEach((key) => {
			const element = layoutElements[key];
			padding[element.position] += element.padding;
		});
		setVisiblePadding((oldPadding) => {
			if (_.isEqual(oldPadding, padding)) return oldPadding;
			return padding;
		});
	}, [layoutElements]);

	/**
	 * Sets the drawer state and updates the settings in indexedDB.
	 * @param {boolean} open
	 */
	const setDrawerState = (open?: boolean) => {
		if (!auth.user) {
			return;
		}
		if (open !== undefined) {
			dispatch(doEditDrawerStateSettings({ userId: auth.user.id, showDrawer: open }));
			setDrawerOpen(open);
		} else {
			dispatch(doEditDrawerStateSettings({ userId: auth.user.id, showDrawer: !drawerOpen }));
			setDrawerOpen((oldState) => !oldState);
		}
	};

	const registerElement = (id: string, position: PaddingPosition, padding: number) => {
		setLayoutElements((prevElements) => {
			const newElements = { ...prevElements };
			newElements[id] = { position, padding };
			return newElements;
		});
	};

	const unregisterElement = (id: string) => {
		setLayoutElements((prevElements) => {
			const newElements = { ...prevElements };
			delete newElements[id];
			return newElements;
		});
	};

	// test
	// useEffect(() => {
	// 	console.log(visiblePadding);
	// }, [visiblePadding]);

	const setSideMenu = (content: React.ReactNode | null) => {
		setSideMenuContent(content);
	};

	/**
	 * Pushes a new popup to the stack.
	 * @param content A {@link React.ReactNode} to be rendered in the popup.
	 * @param movable Whether the popup can be moved around.
	 * @param expandable Whether the popup can be expanded.
	 * @param customId Custom id for the popup. It can be used to identify the popup.
	 * If not provided, a unique id will be generated.
	 */
	const pushPopup = (
		content: React.ReactNode,
		movable?: boolean,
		expandable?: boolean,
		customId?: string,
	) => {
		const newId = customId ?? uniqueId('popup_');
		setPopupStates((prevStates) => {
			const newStates = { ...prevStates };
			newStates[newId] = true;
			return newStates;
		});
		setPopupContent((prevContent) => [...prevContent, { content, movable, expandable, id: newId }]);
	};

	/**
	 * Pops a popup from the stack.
	 * @param {string} id
	 */
	const popPopup = (id: string) => {
		setPopupContent((prevContent) => prevContent.filter((popup) => popup?.id !== id));
		setPopupStates((prevStates) => {
			const newStates = { ...prevStates };
			delete newStates[id];
			return newStates;
		});
	};

	/**
	 * Closes all popups.
	 */
	const closeAllPopups = () => {
		if (hasClickedInPopup) {
			setHasClickedInPopup(false);
			return;
		}
		setPopupStates((oldStates) => {
			const newStates = { ...oldStates };
			Object.keys(oldStates).forEach((key) => (newStates[key] = false));
			return newStates;
		});
	};

	/**
	 * Sets the loading state to true and pushes the {@link LoadingData} to the context.
	 * @param loadingData
	 */
	const pushLoadingData = (loadingData: LoadingData) => {
		setLoadingData(loadingData);
	};

	/**
	 * Pops the loading state.
	 */
	const popLoadingData = () => {
		setLoadingData(null);
	};

	/**
	 * Sets the fab data.
	 * @param {Partial<FabData> | Partial<FabData>[]} fabData
	 */
	const setFabs = (fabData: Partial<FabData> | Partial<FabData>[]) => {
		const fabs: FabData[] = [];

		const pushFab = (fab: Partial<FabData>) => {
			const id = uniqueId('fab_');
			const placement = fab.placement ?? 'bottomRight';
			fabs.push({ ...fab, id, placement });
		};

		if (Array.isArray(fabData)) {
			fabData.forEach((fab) => {
				pushFab(fab);
			});
		} else {
			pushFab(fabData);
		}
		setFabData(fabs);
	};

	/**
	 * Modifies the font size and saves its state to indexedDB.
	 * @param {number} size
	 */
	const modifyFontSize = (size: number) => {
		if (!auth.user) return;
		dispatch(
			doEditTextSizeSettings({
				userId: auth.user.id,
				textSize: fontSize + size,
			}),
		);
		setFontSize((oldSize) => {
			if (oldSize + size < 5 || oldSize + size > 30) return oldSize;
			return oldSize + size;
		});
	};

	const selectSubMenuElement = (index: number | null | undefined, recursionIndex = 0) => {
		if (index === null || index === undefined) {
			setSelectedSubMenuArray(null);
			return;
		}

		setSelectedSubMenuArray((oldSelection) => {
			const tempSelection = oldSelection?.slice(0, recursionIndex) ?? [];
			if (tempSelection?.[recursionIndex]) {
				tempSelection[recursionIndex] = index;
			} else {
				tempSelection?.push(index);
			}
			return tempSelection;
		});
	};

	const pushSubMenuElements = (elements: SubMenuElement[] | null) => {
		setSubMenuElements(elements);
		if (elements === null) {
			setSelectedSubMenuArray(null);
		}
	};

	const pixelsToRem = (pixels: number) => pixels / fontSize;

	const remToPixels = (rem: number) => rem * fontSize;

	const contextValue = {
		visiblePadding,
		drawerOpen,
		showHelp,
		breadCrumbs,
		sideMenuContent,
		popupContent,
		popupStates,
		loadingData,
		fabData,
		deviceType,
		selectedSubMenuArray,
		subMenuElements,
		subMenuFullScreen,
		subMenuPosition,
		setDrawerOpen: setDrawerState,
		setFontSize: modifyFontSize,
		registerElement,
		unregisterElement,
		setBreadCrumbs,
		setSideMenu,
		pushPopup,
		popPopup,
		closeAllPopups,
		setHasClickedInPopup,
		pushLoadingData,
		popLoadingData,
		setFabs,
		setSubMenuFullScreen,
		selectSubMenuElement,
		pushSubMenuElements,
		setSubMenuPosition,
		pixelsToRem,
		remToPixels,
	};

	return (
		<LayoutContext.Provider value={contextValue}>
			<FeedbacksProvider>
				<LoadingHandler />
				<PopupsHandler />
				{children}
			</FeedbacksProvider>
		</LayoutContext.Provider>
	);
};

export const useLayout = () => useContext(LayoutContext);
