import { OrderType } from 'common';
import { InboundModifyOrderFieldContainer } from '../modules/inbound/InboundModifyOrderFieldContainer';
import { OrderTypeConfigMap } from '@components/taskworkflow/tasks/config';

export const config: OrderTypeConfigMap = {
	[OrderType.Modify]: {
		containerComponent: () => null,
	},
	[OrderType.Inbound]: {
		containerComponent: InboundModifyOrderFieldContainer,
	},
};
