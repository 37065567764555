import { AffectedRowsResponse } from 'common';
import {
	DeletionDialogSettings,
	SnackBarMessage,
	clearNextDeletionDialogId,
	popDeletionDialog,
	pushDeletionDialog,
	pushSnackbar,
} from '../store/app';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';

interface ActionWithId {
	action: () => Promise<AffectedRowsResponse>;
	id: string;
}

function useFeedbacks() {
	const dispatch = useAppDispatch();
	const deletionDialog = useAppSelector((state) => state.app.deletionDialog);
	const nextDeleteDialogId = useAppSelector((state) => state.app.nextDeleteDialogId);
	const [storedAction, setStoredAction] = useState<ActionWithId | null>(null);

	useEffect(() => {
		if (deletionDialog === null && nextDeleteDialogId === storedAction?.id) {
			storedAction.action();
			setStoredAction(null);
			dispatch(clearNextDeletionDialogId());
		}
	}, [deletionDialog]);

	const showSnackBar = (message: string | SnackBarMessage) => {
		dispatch(pushSnackbar(message));
	};

	const pushDialog = (
		dialog: DeletionDialogSettings,
		action: () => Promise<AffectedRowsResponse>,
	) => {
		const uid = uniqueId('delete-action-');
		setStoredAction({ action: action, id: uid });
		dispatch(pushDeletionDialog({ ...dialog, dialogId: uid }));
	};
	const removeDialog = (dispatchAction: boolean) => dispatch(popDeletionDialog(dispatchAction));

	return {
		deletionDialog,
		showSnackBar,
		pushDeletionDialog: pushDialog,
		removeDeletionDialog: removeDialog,
	};
}

export default useFeedbacks;
