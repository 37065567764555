import { LocaleEntries } from 'common';

export type FormAction = 'create' | 'edit' | 'view';

export enum FormRenderType {
	standalone = 'standalone',
	popup = 'popup',
}

export enum FormType {
	full = 'full',
	quick = 'quick',
}

export enum FormProperty {
	strings = 'strings',
}
export interface FormValuesWithStrings {
	strings?: { [key: string]: LocaleEntries };
}
