import useOrders from '@hooks/useOrders';
import usePrices from '@hooks/usePrices';
import { Tooltip } from '@mui/material';
import { OrderProductResponse, VariantResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface TablePriceBodyProps {
	orderProduct: OrderProductResponse;
	variant: VariantResponse;
	sourceId: number;
}

export const TablePriceBody = <T extends TablePriceBodyProps>({
	orderProduct,
	variant,
	sourceId,
}: T) => {
	const { findContentById } = useOrders();
	const { getBatchSinglePrice } = usePrices();
	const { i18n, t } = useTranslation();

	if (!orderProduct || !orderProduct?.arrivedQty || !variant?.unitsPerBox) {
		return <div>0</div>;
	}

	if (!orderProduct.actualPrice) {
		const tempPrice = getBatchSinglePrice(variant.id, orderProduct.weight);

		const langString = (() => {
			switch (i18n.language) {
				case 'en':
					return 'en-US';
				case 'ja':
					return 'ja-JP';
				case 'it':
					return 'it-IT';
				default:
					return 'ja-JP';
			}
		})();

		const formattedPrice = (tempPrice.price ?? 0).toLocaleString(langString, {
			style: 'currency',
			currency: tempPrice.currency !== '' ? tempPrice.currency : 'JPY',
		});
		return (
			<Tooltip title={t('inventory.estimatedSinglePrice')} placement='top' disableInteractive>
				<div style={{ userSelect: 'none', cursor: 'help' }}>{formattedPrice}*</div>
			</Tooltip>
		);
	}

	let singlePrice = Math.ceil(
		(orderProduct.actualPrice +
			(orderProduct.importTaxes ?? 0) +
			(orderProduct.transportFees ?? 0)) /
			orderProduct?.arrivedQty,
	);

	if (sourceId) {
		const source = findContentById(sourceId);
		if (!source) {
			singlePrice = 0;
		} else if (source.actualPrice && source.arrivedQty) {
			singlePrice = Math.ceil(
				(source.actualPrice + (source.importTaxes ?? 0) + (source.transportFees ?? 0)) /
					source.arrivedQty,
			);
		}
	}

	return <div>{singlePrice}</div>;
};
