import { useEffect } from 'react';

/**
 * Custom hook to warn the user before leaving the page if there are unsaved changes.
 * @param {boolean} shouldWarn - A boolean indicating whether the user should be warned before leaving.
 */
function useWarnBeforeLeaving(shouldWarn: boolean) {
	useEffect(() => {
		const handleBeforeUnload = (e: { returnValue: string }) => {
			if (!shouldWarn) return;

			const message = 'You have unsaved changes. Are you sure you want to leave?';
			e.returnValue = message;
			return message;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [shouldWarn]);
}

export default useWarnBeforeLeaving;
