import { useOrderEditor } from '@contexts/index';
import { Controller } from 'react-hook-form';
import { EditShowGridField } from '@components/common/EditShowGridField';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { ShippingType } from 'common';
import { useTranslation } from 'react-i18next';

export const OrderHeaderShippingSelect = () => {
	const { renderType, control, config, configValues } = useOrderEditor();
	const { t } = useTranslation();

	if (!config) {
		return null;
	}

	const element = config.headerElements['shippingType'];
	const sourceType = config.headerElements['fromType'];
	if (element.isShippingTypeFunction() && sourceType.isArrayofOrderDirectionType()) {
		if (!sourceType) return null;
		// TODO: support multiple fromTypes

		let shippingValues: ShippingType[] | null = null;
		if (configValues?.shippingType) {
			shippingValues = configValues.shippingType.value as ShippingType[];
		}
		if (!shippingValues) return null;
		return (
			<Controller
				name='shippingType'
				control={control}
				render={({ field }) => (
					<EditShowGridField isEditing={true} width={2}>
						<InputLabel id='ship-by'>{t('order.shipBy')}</InputLabel>
						<Select
							labelId='ship-by'
							id='ship-by'
							size='small'
							label={t('order.shipBy')}
							value={field.value === 'notdefined' ? '-1' : field.value}
							disabled={false}
							onChange={(event) => {
								field.onChange(
									event.target.value === '-1'
										? ShippingType.notdefined
										: (event.target.value as ShippingType),
								);
							}}
							MenuProps={
								renderType === 'popup'
									? {
											style: { zIndex: 9999 },
									  }
									: {}
							}
						>
							<MenuItem key={-1} value={-1}>
								{t('order.shippingTypes.undefined')}
							</MenuItem>
							{shippingValues?.map((value, it) => (
								<MenuItem key={it} value={value}>
									{t(`order.shippingTypes.${value.toLowerCase()}`)}
								</MenuItem>
							))}
						</Select>
					</EditShowGridField>
				)}
			/>
		);
	}
	return null;
};
