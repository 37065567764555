import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useProducts from '@hooks/useProducts';
import useImages from '@hooks/useImages';
import useLocale from '@hooks/useLocale';

import { AppFunction, InternalOrderSubType, OrderProductResponse, TranslationTypes } from 'common';
import {
	Avatar,
	Box,
	Chip,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	Typography,
} from '@mui/material';
import { SkuChip } from '@components/common/chips/SkuChip';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';

import { Edit, Note, Save } from '@mui/icons-material';
import { ModifyOrderFieldValue } from '@contexts/taskContext/config/modifyOrderFieldConfig';
import { QuantityChip } from '@components/common/chips/QuantityChip';
import { WeightChip } from '@components/common/chips/WeightChip';
import { useTask } from '@contexts/index';

/**
 * Props for the CloseOrderFieldListItem component.
 * @interface Props
 * @property {OrderProductResponse} content - The order product content.
 * @property {ModifyOrderFieldValue} values - The close order value.
 * @property {number} iterator - The iterator number.
 */
interface ModifyOrderFieldListItemProps {
	content: OrderProductResponse;
	children: ReactNode;
	values: ModifyOrderFieldValue;
	iterator: number;
	subType?: InternalOrderSubType | null;
	forceEdit?: boolean;
}

/**
 * Close Order Field List Item component.
 * Used as base component for all close order field list items.
 * @param {ModifyOrderFieldListItemProps} props - CloseOrderFieldListItem component props
 * @property {OrderProductResponse} content - The order product content.
 * @property {ModifyOrderFieldValue} values - The close order value.
 * @property {number} iterator - The iterator number.
 * @returns {JSX.Element} CloseOrderFieldListItem component
 */
export const ModifyOrderFieldListItem = ({
	content,
	children,
	values,
	iterator,
	subType,
	forceEdit,
}: ModifyOrderFieldListItemProps): JSX.Element | null => {
	const { taskState } = useTask();
	const { variantList, productList } = useProducts();
	const { imageList } = useImages();
	const { getTranslatedString } = useLocale();

	const [isEditing, setIsEditing] = useState(forceEdit ?? false);

	const variant = variantList.find((v) => v.id === content.variantId);

	useEffect(() => {
		setIsEditing(forceEdit ?? false);
	}, [forceEdit]);

	if (!variant) {
		return null;
	}

	const imageUrl = useMemo(() => {
		let image = imageList.find((image) => image.id === variant?.images?.[0]?.id ?? -1);
		if (!image) {
			image = imageList.find(
				(image) =>
					image.id === productList.find((p) => p.id === variant.productId)?.images?.[0]?.id ?? -1,
			);
		}

		return image?.url ?? '';
	}, [content.variantId, variantList, productList, imageList]);

	return (
		<ListItem
			sx={{
				margin: '0.5rem 0 0.5rem 0',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderRadius: '1rem',
				transition: 'all 0.2s ease-in-out',
				boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
				padding: '0.3rem',
				'&:hover': {
					boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
				},
				'&:before':
					!subType ||
					subType === InternalOrderSubType.simple ||
					subType === InternalOrderSubType.afterImport
						? {
								content: `"${(iterator ?? 0) + 1}"`,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '0.8rem',
								position: 'absolute',
								top: 'calc(50% - 0.6rem)',
								backgroundColor: 'black',
								color: 'white',
								left: '-1.6rem',
								width: '1.2rem',
								height: '1.2rem',
								zIndex: 0,
								borderRadius: '1rem',
								opacity: 0.4,
						  }
						: {},
			}}
		>
			<ListItemAvatar>
				<Avatar
					src={imageUrl}
					imgProps={{
						style: {
							objectFit: 'cover',
						},
					}}
				/>
			</ListItemAvatar>
			<Grid container display='flex' rowGap={2} p={0.3}>
				<Grid
					item
					display='flex'
					gap={1}
					alignItems='center'
					justifyContent='flex-start'
					flexGrow={1}
					flexBasis={0}
					container
				>
					<Grid item>
						<SkuChip
							{...variant}
							orderProductId={content.sourceId ? +content.sourceId : undefined}
						/>
					</Grid>
					<Grid item flexGrow={1} flexBasis={0} display='flex' gap={1}>
						<Typography>
							{getTranslatedString(
								AppFunction.Product,
								variant.productId ?? -1,
								TranslationTypes.name,
							)}
						</Typography>
						<Typography>
							{getTranslatedString(
								AppFunction.Variant,
								content.variantId ?? -1,
								TranslationTypes.name,
							)}
						</Typography>
					</Grid>
					{isEditing ? (
						<Grid item flexGrow={1} />
					) : (
						<Grid item flexGrow={1.5} flexBasis={0} container columnSpacing={1}>
							<Grid item xs={4}>
								<QuantityChip quantity={values?.quantity} sx={{ width: '100%' }} />
							</Grid>
							<Grid item xs={4}>
								<WeightChip weight={values?.totalWeight} sx={{ width: '100%' }} />
							</Grid>
							<Grid item xs={4}>
								{values?.expirationDate ? (
									<ExpiresAtChip expiresAt={values?.expirationDate} sx={{ width: '100%' }} />
								) : (
									<Chip label='N/A' size='small' sx={{ width: '100%' }} color='error' />
								)}
							</Grid>
						</Grid>
					)}
					{taskState === 'in_progress' && (
						<Grid item>
							<IconButton onClick={() => setIsEditing(!isEditing)} sx={{ fontSize: '1.2rem' }}>
								{isEditing ? (
									<Save fontSize='inherit' color='success' />
								) : (
									<Edit fontSize='inherit' color='info' />
								)}
							</IconButton>
						</Grid>
					)}
				</Grid>
				{isEditing && (
					<Grid item xs={12} container display='flex' gap={1} alignItems='center'>
						{children}
					</Grid>
				)}
				{content.notes && (
					<Grid item xs={12}>
						<Box
							sx={{
								padding: '1rem',
								backgroundColor: 'rgba(0, 0, 0, 0.05)',
								borderRadius: '1rem',
								transition: 'all 0.2s ease-in-out',
								boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
								'&:hover': {
									boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
								},
							}}
							display='flex'
							alignItems='center'
							justifyContent='flex-start'
							columnGap={1}
						>
							<Note color='success' />
							<Box>{content.notes}</Box>
						</Box>
					</Grid>
				)}
			</Grid>
		</ListItem>
	);
};
