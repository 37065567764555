import { Box, Button, Grid, IconButton, List, TextField } from '@mui/material';
import React, { JSXElementConstructor, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Add, ArrowDownward, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { EditShowGridField } from '../common/EditShowGridField';
import { Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { DragZoneName, DropZoneName } from '../../pages/Tasks/TaskChainArchetypeCreate';
import { calculateAdjustedPosition } from './utils/utils';
import { getIcon, useChainEditor } from '../../contexts/chainEditorContext/ChainEditorContext';
import useTasks from '../../hooks/useTasks';
import { Controller, useForm } from 'react-hook-form';
import {
	FieldSetList,
	TaskArchetypeRequest,
	TaskArchetypeResponse,
	TranslationTypes,
} from 'common';
import { AssignableToInput } from './AssignableToInput';
import { NextAssignableAtInput } from './NextAssignableAtInput';
import useLocale from '../../hooks/useLocale';
import { useChainArchetypeEditor } from '../../contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	taskIndex: number;
	taskArchetypeId?: number;
	addTaskDisabled?: boolean;
	onStartEdit?: (index: number) => void;
	onStopEdit?: (index: number) => void;
}

interface ItemType {
	id: number;
	type: 'field' | 'fieldSet';
}

export const TaskArchetypeEditor = ({
	taskIndex,
	taskArchetypeId,
	addTaskDisabled,
	onStartEdit,
	onStopEdit,
}: Props) => {
	const {
		taskArchetypesList,
		createTaskArchetype,
		editTaskArchetype,
		taskFieldArchetypeList,
		taskFieldSetsList,
	} = useTasks();

	const { editorWidth, addTaskAtIndex, modifyTaskAtIndex, deleteTaskAtIndex } =
		useChainArchetypeEditor();

	const { getCurrentLanguage } = useLocale();

	const [id, setId] = useState<number>(taskArchetypeId ?? -1);
	const [editing, setEditing] = useState<boolean>(taskArchetypeId === -1);
	const [hover, setHover] = useState<boolean>(false);
	const [wrapperHover, setWrapperHover] = useState<boolean>(false);
	const [items, setItems] = useState<ItemType[]>([]);

	const { dragDropState, zoomPanState, setDragDropState } = useChainEditor();
	const { control, reset, handleSubmit } = useForm<TaskArchetypeRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: '',
			assignableTo: { users: [], roleWorkplaces: [] },
			nextAssignableAt: null,
		},
	});

	const onSubmit = (data: TaskArchetypeRequest) => {
		const newRoleWorkplaces = data.assignableTo.roleWorkplaces.filter(
			(roleWorkplace) => roleWorkplace.warehouseId !== -1 && roleWorkplace.sectionId !== -1,
		);
		data.assignableTo.roleWorkplaces = newRoleWorkplaces;

		const fieldSets: FieldSetList = [];
		let fieldArchetypeIds: number[] = [];
		// let's convert the items to fieldSets
		for (let i = 0; i < items.length; i++) {
			if (items[i].type === 'field') {
				fieldArchetypeIds.push(items[i].id);
			} else {
				const fieldSet = taskFieldSetsList.find((fieldSet) => fieldSet.id === items[i].id);
				if (fieldSet) {
					// TODO: Really BAD cast. Fix this when we decide if we want to keep number | string or only number;
					const tempIndexes = [];
					for (
						let i = fieldArchetypeIds.length;
						i < fieldArchetypeIds.length + fieldSet.types.length;
						i++
					) {
						tempIndexes.push(i);
					}
					fieldArchetypeIds = [...fieldArchetypeIds, ...fieldSet.types];
					const tempFieldSet = {
						id: fieldSet.id as number,
						taskFieldArchetypeIndexes: tempIndexes,
					};
					fieldSets.push(tempFieldSet);
				}
			}
		}
		data.taskFieldArchetypeId = fieldArchetypeIds;
		data.fieldSets = fieldSets;
		console.log(data);
		if (id === -1) {
			createTaskArchetype(data)
				.then((response) => {
					// TODO: solve bad cast
					setId(response.id as number);
					modifyTaskAtIndex(taskIndex, response);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setEditing(false);
					onStopEdit?.(taskIndex);
				});
		} else {
			const request: TaskArchetypeResponse = { ...data, id: id };
			editTaskArchetype(request)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setEditing(false);
					onStopEdit?.(taskIndex);
				});
		}
	};

	useEffect(() => {
		setId(taskArchetypeId ?? -1);

		if (taskArchetypeId === -1) {
			reset({ name: 'New Task', assignableTo: { users: [], roleWorkplaces: [] } });
			return;
		}

		const taskArchetype = taskArchetypesList.find((archetype) => archetype.id === taskArchetypeId);
		if (taskArchetype) {
			resetToArchetype(taskArchetype);
		}
	}, [taskArchetypeId]);

	useEffect(() => {
		if (editing) {
			onStartEdit?.(taskIndex);
		}
	}, [editing]);

	useEffect(() => {
		if (dragDropState.state === 'none') return;
		if (
			dragDropState.state === 'dropped' &&
			dragDropState.destinationType === DropZoneName.taskFieldDropZone
		) {
			if (dragDropState.sourceType === `${DropZoneName.taskFieldDropZone}_${taskIndex}`) {
				console.log(dragDropState.sourceId, dragDropState.insertIndex);
				moveItemAtIndex(dragDropState.sourceId ?? 0, dragDropState.insertIndex ?? 0);
			} else if (dragDropState.destinationId === taskIndex) {
				const newItem: ItemType = {
					id: dragDropState.sourceId ?? -1,
					type:
						dragDropState.sourceType === DragZoneName.fieldArchetypeDroppableList
							? 'field'
							: 'fieldSet',
				};
				console.log(newItem);
				insertItemTypeAtIndex(dragDropState.insertIndex ?? 0, newItem);
			}
			setDragDropState({ state: 'none' });
		}
	}, [dragDropState]);

	useEffect(() => {
		if (addTaskDisabled) {
			setWrapperHover(false);
		}
	}, [addTaskDisabled]);

	const resetToArchetype = (taskArchetype: TaskArchetypeResponse) => {
		reset(taskArchetype);

		const fieldSetStartIndexes = new Map();
		taskArchetype.fieldSets?.forEach((fieldSet) => {
			const startIndex = fieldSet.taskFieldArchetypeIndexes[0];
			fieldSetStartIndexes.set(startIndex, fieldSet.id);
		});

		const itemTypes: ItemType[] = [];
		let currentIndex = 0;
		while (currentIndex < taskArchetype.taskFieldArchetypeId.length) {
			const fieldId = taskArchetype.taskFieldArchetypeId[currentIndex];
			if (fieldSetStartIndexes.has(currentIndex)) {
				// Add fieldSet
				const fieldSetId = fieldSetStartIndexes.get(currentIndex);
				itemTypes.push({ id: fieldSetId, type: 'fieldSet' });

				// Skip over the indices covered by this field set
				const fieldSet = taskArchetype.fieldSets?.find((fs) => fs.id === fieldSetId);
				const fieldSetSize = fieldSet ? fieldSet.taskFieldArchetypeIndexes.length : 0;
				currentIndex += fieldSetSize;
			} else {
				// Add field
				itemTypes.push({ id: fieldId, type: 'field' });
				currentIndex++;
			}
		}

		setItems(itemTypes);
	};

	const insertItemTypeAtIndex = (index: number, item: ItemType) => {
		setItems((currentItems) => {
			const newItems = [...currentItems];
			newItems.splice(index, 0, item);
			return newItems;
		});
	};

	const moveItemAtIndex = (fromIndex: number, toIndex: number) => {
		setItems((currentItems) => {
			const newItems = [...currentItems];
			const item = newItems.splice(fromIndex, 1)[0];
			newItems.splice(toIndex, 0, item);
			return newItems;
		});
	};

	const deleteItemAtIndex = (index: number) => {
		setItems((currentItems) => {
			const newItems = [...currentItems];
			newItems.splice(index, 1);
			return newItems;
		});
	};

	const getTransform = (): string => {
		return `scale(${hover || editing ? 1 : 0}) translate(${editing ? '1.7rem, 0px' : '0px, 0px'})`;
	};

	const buttons = (): ReactNode => (
		<>
			<div
				style={{
					maxWidth: '1.5rem',
					maxHeight: '1.5rem',
					position: 'absolute',
					top: '0.3rem',
					right: '2rem',
					cursor: 'pointer',
					borderRadius: '50%',
					color: 'white',
					backgroundColor: editing ? 'red' : 'green',
					transform: getTransform(),
					transition:
						'backgroundColor 0.5s ease-in-out, transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
				onClick={() => {
					if (!editing) {
						setEditing(true);
						return;
					}
					onStopEdit?.(taskIndex);
					if (id === -1) {
						deleteTaskAtIndex(taskIndex);
					} else {
						const archetype = taskArchetypesList.find((archetype) => archetype.id === id);
						if (archetype) {
							resetToArchetype(archetype);
						}
						setEditing((editingState) => !editingState);
					}
				}}
			>
				{editing ? <Cancel sx={{ padding: '0.2rem' }} /> : <Edit sx={{ padding: '0.2rem' }} />}
			</div>
			<Delete
				className='dcExcluded'
				sx={{
					maxWidth: '1.5rem',
					maxHeight: '1.5rem',
					position: 'absolute',
					top: '0.3rem',
					right: '0.3rem',
					cursor: 'pointer',
					borderRadius: '50%',
					padding: '0.2rem',
					color: 'white',
					backgroundColor: 'red',
					transform: `scale(${hover && !editing ? 1 : 0})`,
					transition: 'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
				onClick={() => {
					onStopEdit?.(taskIndex);
					deleteTaskAtIndex(taskIndex);
				}}
			/>
			<Save
				className='dcExcluded'
				sx={{
					maxWidth: '1.5rem',
					maxHeight: '1.5rem',
					position: 'absolute',
					bottom: '0.3rem',
					right: '0.3rem',
					cursor: 'pointer',
					borderRadius: '50%',
					padding: '0.2rem',
					color: 'white',
					backgroundColor: 'green',
					transform: `scale(${editing ? 1 : 0})`,
					transition: 'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
				onClick={handleSubmit(onSubmit)}
			/>
		</>
	);

	const renderFieldSet = (
		fieldSetId: number,
		index: number,
		provided: DraggableProvided,
		style: React.CSSProperties,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): ReactElement<HTMLElement, string | JSXElementConstructor<any>> => {
		const fieldSet = taskFieldSetsList.find((fieldSet) => fieldSet.id === fieldSetId);
		if (!fieldSet) return <></>;
		const currentLanguage = getCurrentLanguage();
		return (
			<div
				key={index}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={style}
			>
				<Grid
					container
					style={{
						display: 'flex',
						alignItems: 'center',
						border: '1px solid black',
						borderRadius: '5px',
						padding: '1rem',
						margin: '0.5rem 0 0.5rem 0',
						minHeight: '3rem',
						backgroundColor: 'white',
					}}
				>
					<Grid item xs={12}>
						[{taskFieldSetsList.find((fieldSet) => fieldSet.id === fieldSetId)?.name}]
					</Grid>
					<Grid item xs={12}>
						insert translation here
					</Grid>
					<Grid item xs={12}>
						{fieldSet.types.map((fieldId, index) => {
							const archetype = taskFieldArchetypeList.find((field) => field.id === fieldId);
							let icon: ReactNode = <></>;
							let name = '';
							if (archetype) {
								icon = getIcon(archetype.type);
								name =
									archetype.strings?.find(
										(string) =>
											string.langCode === currentLanguage && string.type === TranslationTypes.name,
									)?.value ?? '';
							}
							return (
								<div
									key={index}
									style={{
										display: 'flex',
										alignItems: 'center',
										border: '1px solid black',
										borderRadius: '5px',
										padding: '0 1rem 0 1rem',
										margin: '0.5rem 0 0.5rem 0',
									}}
								>
									{icon} [{taskFieldArchetypeList.find((field) => field.id === fieldId)?.name}]
									{name}
								</div>
							);
						})}
					</Grid>
				</Grid>
			</div>
		);
	};

	const renderField = (
		fieldArchetype: number,
		index: number,
		provided: DraggableProvided,
		style: React.CSSProperties,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): ReactElement<HTMLElement, string | JSXElementConstructor<any>> => {
		const archetype = taskFieldArchetypeList.find((archetype) => archetype.id === fieldArchetype);
		// TODO: decide how to handle deleted fields
		if (!archetype)
			return (
				<div
					key={index}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={style}
				>
					deleted field
				</div>
			);
		// bring back when using translations
		// const currentLanguage = getCurrentLanguage();
		// const name = archetype.strings?.find(string => string.langCode === currentLanguage && string.type === TranslationTypes.name)?.value ?? '';
		return (
			<div
				key={index}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={style}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							border: '1px solid black',
							borderRadius: '5px',
							padding: '1rem',
							margin: '0.5rem 0 0.5rem 0',
							minHeight: '3rem',
							backgroundColor: 'white',
							flexGrow: 1,
						}}
					>
						[{taskFieldArchetypeList.find((field) => field.id === fieldArchetype)?.name}]
					</div>
					{editing && (
						<IconButton onClick={() => deleteItemAtIndex(index)}>
							<Delete color='error' />
						</IconButton>
					)}
				</div>
			</div>
		);
	};

	return (
		<Box
			sx={{
				position: 'relative',
				width: `${editorWidth}rem`,
				transition: 'width 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
			}}
			onMouseEnter={() => {
				if (!addTaskDisabled) setWrapperHover(true);
			}}
			onMouseLeave={() => {
				if (!addTaskDisabled) setWrapperHover(false);
			}}
			className='panExcluded'
		>
			<Box
				sx={{
					position: 'relative',
					backgroundColor: 'white',
					borderRadius: '15px',
					padding: '1rem',
					border: `1px ${editing ? 'dashed' : 'solid'} black`,
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => {
					if (!editing) setHover(false);
				}}
				className='panExcluded'
			>
				{buttons()}
				<Grid container spacing={0} className='panExcluded' sx={{ marginTop: '1rem' }}>
					<Controller
						name='name'
						control={control}
						render={({ field }) => (
							<InputGridField width={12} type={ComponentType.TextField}>
								<TextField
									{...field}
									fullWidth
									label='Name'
									disabled={!editing}
									size='small'
									InputProps={{
										inputProps: {
											className: 'panExcluded',
										},
									}}
								/>
							</InputGridField>
						)}
					/>
					<Controller
						name='assignableTo'
						control={control}
						render={({ field }) => (
							<AssignableToInput
								value={field.value}
								isEditing={editing}
								onChange={field.onChange}
							/>
						)}
					/>
					<Controller
						name='nextAssignableAt'
						control={control}
						render={({ field }) => (
							<NextAssignableAtInput
								value={field.value}
								isEditing={editing}
								onChange={field.onChange}
							/>
						)}
					/>
					<Grid item xs={12} className='panExcluded'>
						<Droppable
							droppableId={`${DropZoneName.taskFieldDropZone}_${taskIndex}`}
							isDropDisabled={!editing}
						>
							{(provided) => (
								<List
									ref={provided.innerRef}
									{...provided.droppableProps}
									sx={{ position: 'relative' }}
								>
									{items.map((item, index) => (
										<Draggable
											draggableId={`testculcaz_${taskIndex}_${index}`}
											index={index}
											key={index}
											isDragDisabled={!editing}
										>
											{(provided, snapshot) => {
												const style = calculateAdjustedPosition(provided, snapshot, zoomPanState);
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												let element: ReactElement<
													HTMLElement,
													string | JSXElementConstructor<any>
												> = <></>;
												switch (item.type) {
													case 'field':
														element = renderField(item.id, index, provided, style);
														break;
													case 'fieldSet':
														element = renderFieldSet(item.id, index, provided, style);
														break;
												}
												return element;
											}}
										</Draggable>
									))}
									{provided.placeholder}
								</List>
							)}
						</Droppable>
					</Grid>
				</Grid>
			</Box>
			<Button
				fullWidth
				onClick={() => addTaskAtIndex(taskIndex + 1)}
				className='panExcluded dcExcluded'
				size='small'
				sx={{
					position: 'absolute',
					bottom: '0px',
					height: wrapperHover && !editing ? '3rem' : '0rem',
					backgroundColor: 'transparent',
					borderRadius: wrapperHover && !editing ? '15px' : '0px',
					border: wrapperHover && !editing ? '1px dashed blue' : 'none',
					margin: wrapperHover && !editing ? '0.5rem 0 0 0' : '0px !important',
					transform: `scale(${wrapperHover && !editing ? 1 : 0})`,
					transition:
						'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1), height 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
			>
				<Add />
			</Button>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '3rem',
					margin: '0.5rem 0 0.5rem 0',
					transform: `scale(${!wrapperHover || editing ? 1 : 0})`,
					transition:
						'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1), height 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
				}}
			>
				<ArrowDownward sx={{ transform: 'scale(2)' }} />
			</div>
		</Box>
	);
};
