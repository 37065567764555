import React from 'react';
import { Slider, Typography, Grid, TextField } from '@mui/material';

interface Props {
	weights: { size: number; weight: number; cost: number };
	setWeights: (values: { size: number; weight: number; cost: number }) => void;
}

export const WeightSliders = ({ weights, setWeights }: Props) => {
	const handleSliderChange =
		(weightType: 'size' | 'weight' | 'cost') => (event: Event, newValue: number | number[]) => {
			updateWeights(weightType, newValue as number);
		};

	const handleInputChange =
		(weightType: 'size' | 'weight' | 'cost') => (event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = parseFloat(event.target.value);
			updateWeights(weightType, isNaN(newValue) ? 0 : newValue);
		};

	const updateWeights = (weightType: 'size' | 'weight' | 'cost', newValue: number) => {
		const newWeights = { ...weights, [weightType]: Math.max(0, Math.min(newValue, 1)) }; // Ensure new value is between 0 and 1
		const total = Object.values(newWeights).reduce((acc, value) => acc + value, 0);

		if (total > 1) {
			const difference = total - 1;
			const otherTypes = Object.keys(weights).filter((key) => key !== weightType) as Array<
				'size' | 'weight' | 'cost'
			>;

			// Attempt to distribute the difference evenly across the other weights
			let distributedDifference = difference;
			for (const type of otherTypes) {
				if (distributedDifference > 0) {
					const currentWeight = newWeights[type];
					const adjustedWeight = Math.max(0, currentWeight - distributedDifference / 2);
					distributedDifference -= currentWeight - adjustedWeight;
					newWeights[type] = adjustedWeight;
				}
			}
		}

		setWeights(newWeights);
	};

	return (
		<>
			{(['size', 'weight', 'cost'] as const).map((type) => (
				<Grid item xs={4} key={type}>
					<Typography>{`${type.charAt(0).toUpperCase() + type.slice(1)} Weight: ${weights[
						type
					].toFixed(2)}`}</Typography>
					<Slider
						value={weights[type]}
						onChange={handleSliderChange(type)}
						min={0}
						max={1}
						step={0.01}
					/>
					<TextField
						label='Value'
						type='number'
						value={weights[type]}
						onChange={handleInputChange(type)}
						inputProps={{ min: 0, max: 1, step: 0.01 }}
						variant='outlined'
						size='small'
						fullWidth
					/>
				</Grid>
			))}
		</>
	);
};
