import { useTranslation } from 'react-i18next';

import { CustomerResponse, PersonType } from 'common';

import { Box, Grid } from '@mui/material';

import { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { PersonComponent } from '@components/PersonComponent';
import { FocusedElementType } from 'store/app';
import { ScopedLayer } from '@components/scopedlayer/ScopedLayer';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { useGenericForm } from '@contexts/formContext/FormContext';

interface Props {
	entityType: PersonType;
	hideBox?: boolean;
}

export const PeopleList = ({ entityType, hideBox }: Props) => {
	// TODO: this should be agnostic to the customer form
	// we can do it by creating a new interface called "withpeople" so we can assure
	// that we are receiving people data and we can use it with every form that has people
	const { entityId, formValues, formAction } = useGenericForm<CustomerResponse>();
	const { t } = useTranslation();
	const [isCreating, setCreating] = useState(false);
	const [expandedId, setExpandedId] = useState<number | null>(null);

	const editDisabled = formAction === 'view';

	const People = useMemo(() => {
		if (formValues.people && formValues.people.length <= 0) {
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>{t('person.noPeople')}</div>
			);
		} else if (formValues.people) {
			const list: JSX.Element[] = [];
			formValues.people.map((p, it) =>
				list.push(
					<PersonComponent
						key={it}
						person={p}
						expandedId={expandedId}
						onExpand={(id: number | null) => {
							setExpandedId(id);
							setCreating(false);
						}}
						view={editDisabled}
					/>,
				),
			);
			return <>{list}</>;
		}
	}, [formValues.people, expandedId, formAction]);

	return (
		<Box sx={{ position: 'relative' }}>
			<ScopedLayer scopes={[FocusedElementType.person]} />
			<TabInnerTitle title='customer.contacts' translate />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{People}
				</Grid>
				{isCreating ? (
					<Grid item xs={12}>
						<PersonComponent
							key='newperson'
							person={{
								id: -1,
								name: t('person.addNew'),
								surname: '',
								type: entityType,
								populatableId: entityId,
							}}
							onStopCreating={() => setCreating(false)}
							expandedId={expandedId}
							onExpand={(id: number | null) => setExpandedId(id)}
							view={editDisabled}
						/>
					</Grid>
				) : !editDisabled ? (
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<AnimatedIconButton
							text='operations.create'
							translate
							icon={<AddIcon />}
							onClick={() => {
								setExpandedId(-1);
								setCreating(true);
							}}
						/>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Box>
	);
};
