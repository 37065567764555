import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import AdvancedAutoComplete from '@components/common/inputs/common/AdvancedAutoComplete';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { AssignableToInput } from '@components/taskworkflow/AssignableToInput';
import { NextAssignableAtInput } from '@components/taskworkflow/NextAssignableAtInput';
import { FormRenderType } from '@contexts/formContext/types';
import { useFeedbacks, useGenericForm, useLayout } from '@contexts/index';
import {
	DragDropContext,
	Draggable,
	DraggableStyle,
	DraggingStyle,
	Droppable,
	DropResult,
} from '@hello-pangea/dnd';
import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useTasks from '@hooks/useTasks';
import { Delete } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	SxProps,
	TextField,
	Theme,
} from '@mui/material';
import { AppFunction, TaskArchetypeResponse, TranslationTypes } from 'common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function isDraggingStyle(style: DraggableStyle | undefined): style is DraggingStyle {
	if (!style) {
		return false;
	}
	return style && typeof style.transform === 'string';
}

export const GeneralInfo = () => {
	const { control, formAction, appFunction, renderType } = useGenericForm<TaskArchetypeResponse>();
	const { taskFieldArchetypeList } = useTasks();
	const { getTranslatedString } = useLocale();
	const { getIcon } = useAppFunctions();
	const { visiblePadding } = useLayout();
	const { activeDialogBoundingRect } = useFeedbacks();
	const { t } = useTranslation();

	const [fieldArchetypeToAdd, setFieldArchetypeToAdd] = useState<number | null>(null);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title={`${appFunction}.generalInfo`} translate size='small' />
			</Grid>
			<Controller
				name='name'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							{...field}
							label={t(`${AppFunction.TaskArchetype}.name`)}
							variant='outlined'
							fullWidth
							disabled={formAction === 'view'}
							size='small'
							required
						/>
					</InputGridField>
				)}
			/>
			<Grid item xs={12}>
				<TabInnerTitle title={`${appFunction}.users`} translate size='small' />
			</Grid>
			<Controller
				name='assignableTo'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<AssignableToInput
							value={field.value}
							isEditing={formAction !== 'view'}
							onChange={field.onChange}
							dontFold
						/>
					</InputGridField>
				)}
			/>
			<Grid item xs={12}>
				<TabInnerTitle title={`${appFunction}.offset`} translate size='small' />
			</Grid>
			<Controller
				name='nextAssignableAt'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<NextAssignableAtInput
							value={field.value}
							isEditing={formAction !== 'view'}
							onChange={field.onChange}
							dontFold
						/>
					</InputGridField>
				)}
			/>
			<Grid item xs={12}>
				<TabInnerTitle title={`${appFunction}.fields`} translate size='small' />
			</Grid>
			<Grid item xs={12}>
				<Controller
					name='taskFieldArchetypeId'
					control={control}
					render={({ field }) => {
						const params = Array.from(field.value ?? []);

						const onDragEnd = (result: DropResult) => {
							if (!result.destination) {
								return;
							}

							const items = Array.from(field.value) ?? [];
							const [reorderedItem] = items.splice(result.source.index, 1);
							items.splice(result.destination.index, 0, reorderedItem);

							field.onChange(items);
						};

						return (
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId='droppable'>
									{(provided, snapshot) => {
										return (
											<Box
												{...provided.droppableProps}
												ref={provided.innerRef}
												width='100%'
												sx={{
													border: snapshot.isDraggingOver
														? '1px dashed #ccc'
														: '1px solid transparent',
													borderRadius: '0.5rem',
												}}
											>
												<List>
													{params.map((fieldId, index) => (
														<Draggable
															key={index}
															draggableId={`draggable-option-${index}`}
															index={index}
														>
															{(provided, snapshot) => {
																const fieldArchetype = taskFieldArchetypeList.find(
																	(tfa) => tfa.id === fieldId,
																);
																const style = provided.draggableProps.style;
																let customSx: SxProps<Theme> = {};
																// Narrow down the style type with a type assertion
																const draggableStyle = style as DraggingStyle & {
																	top?: number;
																	left?: number;
																};

																if (isDraggingStyle(draggableStyle) || snapshot.isDropAnimating) {
																	if (renderType === FormRenderType.popup) {
																		customSx = {
																			top: `${
																				draggableStyle.top -
																				(activeDialogBoundingRect?.y ?? 0) -
																				(draggableStyle.height ? draggableStyle.height / 2 : 0)
																			}px !important`,
																			left: '1.5rem !important',
																		};
																	} else {
																		customSx = {
																			top: `${
																				draggableStyle.top - visiblePadding.top
																			}px !important`,
																			left: '1.5rem !important',
																		};
																	}
																}

																if (!fieldArchetype) {
																	return null;
																}

																return (
																	<ListItem
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		sx={{
																			...((snapshot.isDragging || snapshot.isDropAnimating) &&
																				customSx),
																		}}
																	>
																		<ListItemAvatar>
																			<Avatar>
																				{getIcon(
																					AppFunction.TaskFieldArchetype,
																					fieldArchetype.type,
																				)}
																			</Avatar>
																		</ListItemAvatar>
																		<Grid container gap={1} display='flex'>
																			<Grid
																				item
																				flexGrow={1}
																				display='flex'
																				alignItems='center'
																				justifyContent='flex-start'
																			>
																				<span
																					style={{
																						flexGrow: 1,
																					}}
																				>
																					{`[${fieldArchetype.name}]`}
																				</span>
																				<span>
																					{getTranslatedString(
																						AppFunction.TaskFieldArchetype,
																						fieldId,
																						TranslationTypes.name,
																					)}
																				</span>
																			</Grid>
																			<Grid item display='flex' alignItems='center'>
																				<IconButton
																					onClick={() => {
																						params.splice(index, 1);
																						field.onChange(params);
																					}}
																					size='small'
																					color='error'
																				>
																					<Delete />
																				</IconButton>
																			</Grid>
																		</Grid>
																	</ListItem>
																);
															}}
														</Draggable>
													))}
													{provided.placeholder}
												</List>
											</Box>
										);
									}}
								</Droppable>
								{fieldArchetypeToAdd ? (
									<Grid container gap={1} display='flex' sx={{ marginTop: '1rem' }}>
										<Grid item flexGrow={1}>
											<AdvancedAutoComplete
												options={taskFieldArchetypeList}
												appFunction={AppFunction.TaskFieldArchetype}
												value={fieldArchetypeToAdd ?? -1}
												onChange={(value) => {
													if (typeof value === 'number') {
														setFieldArchetypeToAdd(value);
													}
												}}
												onCustomOptionLabel={(option) => option.name}
											/>
										</Grid>
										<Grid item>
											<Button
												variant='outlined'
												fullWidth
												onClick={() => {
													if (fieldArchetypeToAdd) {
														const items = Array.from(field.value);
														field.onChange([...items, fieldArchetypeToAdd]);
														setFieldArchetypeToAdd(null);
													}
												}}
												sx={{
													height: '100%',
												}}
												disabled={fieldArchetypeToAdd === -1}
											>
												{t('operations.add')}
											</Button>
										</Grid>
										<Grid item>
											<IconButton
												onClick={() => setFieldArchetypeToAdd(null)}
												color='error'
												sx={{
													height: '100%',
												}}
											>
												<Delete />
											</IconButton>
										</Grid>
									</Grid>
								) : (
									<Button
										variant='outlined'
										fullWidth
										onClick={() => setFieldArchetypeToAdd(-1)}
										sx={{ marginTop: '1rem' }}
									>
										{t(`${AppFunction.TaskArchetype}.addField`)}
									</Button>
								)}
							</DragDropContext>
						);
					}}
				/>
			</Grid>
		</Grid>
	);
};
