import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Delete } from '@mui/icons-material';
import { Grid, IconButton, useTheme } from '@mui/material';

export const ProcessingSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { subOrders, config, removeSubOrder } = useOrderEditor();
	const theme = useTheme();

	const currentSubOrder = subOrders.find((s) => s.code === subOrderCode);

	if (!config || !config.subOrderProductsContainer) {
		return null;
	}

	return (
		<Grid
			item
			container
			sx={{
				margin: '1rem 0',
				padding: '1rem 1rem 1rem 2.2rem',
				borderBottom: subOrders.length > 1 ? `2px solid ${theme.palette.primary.main}` : '',
				height: 'fit-content',
				width: '100%',
			}}
			gap={1}
			display='flex'
		>
			<Grid item container flexGrow={1} flexBasis={0} rowGap={2}>
				<config.subOrderProductsContainer subOrder={currentSubOrder} />
			</Grid>
			<Grid
				item
				flexShrink={1}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0.5rem',
				}}
			>
				<IconButton
					onClick={() => removeSubOrder(subOrderCode)}
					sx={{
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						transform: 'scale(1)',
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};
