import { useOrderEditor } from '@contexts/index';
import { Controller } from 'react-hook-form';
import { EditShowGridField } from '@components/common/EditShowGridField';
import { InputLabel, MenuItem, Select } from '@mui/material';
import useWarehouses from '@hooks/useWarehouses';
import useLocale from '@hooks/useLocale';
import { AppFunction, OrderType, StorageType, TranslationTypes } from 'common';
import useCustomers from '@hooks/useCustomers';
import { useTranslation } from 'react-i18next';

export const OrderHeaderTargetSelect = () => {
	const { renderType, orderType, control, configValues } = useOrderEditor();
	const { warehouseList } = useWarehouses();
	const { customerList } = useCustomers();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	if (orderType === OrderType.Internal || orderType === OrderType.Inbound) {
		if (!configValues?.toSubType || !configValues?.toSubType.isValueOfType<StorageType[]>()) {
			return null;
		}

		// TODO: we are assuming here that the value will be StorageType[]
		//      but later on the value might be something different depending on the
		//      order direction type (fromType).
		//      Come back and handle the case when it will be needed.
		// Add toType here and handle the case when it will be needed.
		const toSubType = configValues['toSubType'].value as StorageType[];
		if (!toSubType) return null;

		if (toSubType.length > 0) {
			return (
				<Controller
					name='toId'
					control={control}
					render={({ field }) => {
						const validated = warehouseList.find(
							(w) => toSubType.includes(w.storageType) && w.id === field.value,
						);
						return (
							<EditShowGridField isEditing={true} width={2}>
								<InputLabel id='through-label'>{t('order.target.warehouse')}</InputLabel>
								<Select
									labelId='through-label'
									size='small'
									id='through'
									label={t('order.target.warehouse')}
									value={validated ? field.value : -1}
									disabled={false}
									onChange={(event) => {
										const asNum = event.target.value as number;
										field.onChange(asNum === -1 ? null : asNum);
									}}
									MenuProps={
										renderType === 'popup'
											? {
													style: { zIndex: 9999 },
											  }
											: {}
									}
								>
									<MenuItem key={-1} value={-1}>
										{t('order.target.undefined')}
									</MenuItem>
									{warehouseList
										.filter((w) => toSubType.includes(w.storageType))
										.map((value, it) => (
											<MenuItem key={it} value={value.id}>
												{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
											</MenuItem>
										))}
								</Select>
							</EditShowGridField>
						);
					}}
				/>
			);
		}
	} else if (orderType === OrderType.Outbound) {
		return (
			<Controller
				name='toId'
				control={control}
				render={({ field }) => {
					// const validated = customerList.find((c) => c.id === field.value);
					return (
						<EditShowGridField isEditing={true} width={2}>
							<InputLabel id='through-label'>{t('order.target.customer')}</InputLabel>
							<Select
								size='small'
								labelId='through-label'
								id='through'
								label={t('order.target.customer')}
								value={field.value ?? -1}
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? null : asNum);
								}}
							>
								<MenuItem key={-1} value={-1}>
									none
								</MenuItem>
								{customerList.map((value, it) => (
									<MenuItem key={it} value={value.id ?? -1}>
										{getTranslatedString(
											AppFunction.Customer,
											value.id ?? -1,
											TranslationTypes.name,
										)}
									</MenuItem>
								))}
							</Select>
						</EditShowGridField>
					);
				}}
			/>
		);
	}

	return null;
};
