import useAppFunctions from '@hooks/useAppFunctions';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import { Chip, SxProps, Theme } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';

interface Props {
	customerId: number;
	sx?: SxProps<Theme>;
}

export const CustomerChip = ({ customerId, sx }: Props): JSX.Element | null => {
	const { customerList } = useCustomers();
	const { getIcon } = useAppFunctions();
	const { getTranslatedString } = useLocale();

	// TODO: we are retrieving the customer here,
	// we might use the info later on to display more details about the customer.
	const customer = customerList.find((customer) => customer.id === customerId);

	if (!customer) {
		return null;
	}

	return (
		<Chip
			size='small'
			label={getTranslatedString(AppFunction.Customer, customer.id ?? -1, TranslationTypes.name)}
			icon={getIcon(AppFunction.Customer) ?? <></>}
			sx={{
				...sx,
			}}
		/>
	);
};
