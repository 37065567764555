import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { InputGroupItem, InputItem } from './InputGroupItem';

export interface InputItemData {
	id: number;
	selectedItem: InputItem;
	value: string;
}

interface InputGroupProps {
	id: number;
	selectableItems: InputItem[];
	items: InputItemData[];
	onChange: (data: InputItemData) => void;
	onRemove: (removedElement: InputItemData) => void;
	disableEdit?: boolean;
}

export const InputGroup = (props: InputGroupProps) => {
	const [id, setId] = useState(props.id);
	const [items, setItems] = useState(props.items);
	useEffect(() => {
		setItems(props.items);
		setId(props.id);
	}, [props.items]);

	useEffect(() => {
		setSelectableItems(props.selectableItems);
	}, [props.selectableItems]);

	const [selectableItems, setSelectableItems] = useState(props.selectableItems);

	const isEditable = () => {
		return props.disableEdit ? !props.disableEdit : true;
	};

	return (
		<>
			{items.map((item) => {
				// append currently selected input group to selectable groups
				const selectedItemIndex = selectableItems.findIndex((m) => m.id === item.selectedItem.id);
				const itemSelectableItems = selectableItems.concat([item.selectedItem]).reverse();
				// remove currently selected if duplicated (eg. it was already there when concating)
				if (selectedItemIndex > -1) {
					itemSelectableItems.splice(selectedItemIndex, 1);
				}

				return (
					<InputGroupItem
						key={item.id}
						id={item.id}
						selectableItems={itemSelectableItems}
						selectedItem={item.selectedItem}
						value={item.value}
						disableEdit={props.disableEdit}
						onChange={(data: string) => {
							const index = items.findIndex((m) => m.id === item.id);
							items[index].value = data;
							setItems([...items]);
							props.onChange(items[index]);
						}}
						onGroupChange={(id: number, newItem: InputItem) => {
							// remove new item from selectableItems
							// add back old item into selectableItems
							const index = items.findIndex((m) => m.id === id);
							const oldItem = items[index].selectedItem;
							items[index].selectedItem = newItem;
							setItems([...items]);
							const newSelectableIndex = selectableItems.findIndex((m) => m.id === newItem.id);
							if (newSelectableIndex > -1) {
								selectableItems.splice(newSelectableIndex, 1);
							}
							setSelectableItems([...selectableItems, oldItem]);
						}}
						onRemove={(id: number) => {
							const index = items.findIndex((m) => m.id === id);
							const oldItem = items.splice(index, 1)[0];
							setItems([...items]);
							// insert inputitem back into selectableItems
							setSelectableItems([...selectableItems, oldItem.selectedItem]);
							props.onRemove(oldItem);
						}}
						selectable={itemSelectableItems.length > 1}
					/>
				);
			})}
			{/* THIS IS A FUCKING HACK 
				(LEL ADDED MORE HACK FOR THE LULZ) */}
			{selectableItems.length > 0 && isEditable() && (
				<Button
					sx={{ width: '100%', marginTop: '10px' }}
					variant='outlined'
					onClick={() => {
						// TODO: fix new item's id
						const newItem = {
							id: Math.random(),
							selectedItem: {
								id: selectableItems[0].id,
								label: selectableItems[0].label,
							},
							value: '',
						} as InputItemData;
						setId(id + 1);
						setItems([...items, newItem]);
						// remove used selectableItems
						selectableItems.splice(0, 1);
						setSelectableItems(selectableItems);
						props.onChange(newItem);
					}}
				>
					+
				</Button>
			)}
		</>
	);
};
