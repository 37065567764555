import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { FormRenderType } from '@contexts/formContext/types';
import { useUserForm } from '@contexts/formContext/subContexts/UserFormContext';
import useUsers from '@hooks/useUsers';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { UserEditRequest } from 'common';
import { useTranslation } from 'react-i18next';

export const Roles = () => {
	const { renderType, formAction } = useGenericForm<UserEditRequest>();
	const { roles, setRoles } = useUserForm();
	const { t } = useTranslation();
	const { roleList } = useUsers();

	const editDisabled = formAction === 'view';

	return (
		<InputGridField width={12} type={ComponentType.AutocompleteWithRenderTags}>
			<Autocomplete
				multiple
				size='small'
				disabled={editDisabled}
				disablePortal={renderType === FormRenderType.popup ? true : false}
				options={roleList}
				getOptionLabel={(option) => option.name}
				value={roleList.filter((role) => roles.includes(role.id))}
				onChange={(_, newValue) => {
					setRoles(newValue.map((item) => item.id));
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							// icon={<Add />}
							variant='outlined'
							size='small'
							label={option.name}
							{...getTagProps({ index })}
							key={option.id}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant='outlined'
						label={t('appFunctions.section')}
						placeholder={t('select.sections')}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
			/>
		</InputGridField>
	);
};
