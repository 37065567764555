import { ReactNode, useEffect, useState } from 'react';
import { FocusedElementType } from '../../store/app';
import useApp from '../../hooks/useApp';

interface Props {
	elementType: FocusedElementType;
	elementId: number;
	children: ReactNode;
	disabled?: boolean;
}

export const ScopedShadowComponent = ({ elementType, elementId, children, disabled }: Props) => {
	const { editingElements } = useApp();
	const [hasShadow, setShadow] = useState(false);
	const isDisabled = disabled || false;

	useEffect(() => {
		if (isDisabled) {
			return;
		}
		let hasShadow = false;
		editingElements.map((ee) => {
			if (ee.type === elementType && ee.id === elementId) {
				hasShadow = true;
			}
		});
		setShadow(hasShadow);
	}, [editingElements]);

	return !isDisabled ? (
		<div
			style={{
				boxShadow: hasShadow ? '1px 1px 14px -2px rgba(0,0,0,0.45)' : 'none',
				width: '100%',
				transition: 'box-shadow 0.3s ease-in-out',
			}}
		>
			{children}
		</div>
	) : (
		<>{children}</>
	);
};
