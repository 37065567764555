import {
	Button,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { EditShowGridField } from './common/EditShowGridField';

export interface InputItem {
	id: string;
	label: string;
}

interface InputGroupItemProps {
	id: number;
	selectableItems: { id: string; label: string }[];
	selectedItem: InputItem;
	selectable: boolean;
	value: string;
	onGroupChange: (id: number, data: InputItem) => void;
	onChange: (data: string) => void;
	onRemove: (id: number) => void;
	disableEdit?: boolean;
}

export const InputGroupItem = (props: InputGroupItemProps) => {
	const {
		id,
		selectableItems,
		selectedItem,
		onChange,
		onGroupChange,
		onRemove,
		selectable,
		value,
	} = props;
	const { t, i18n } = useTranslation();

	const theme = useTheme();

	return (
		<Grid container spacing={2} sx={{ marginBottom: '6px', display: 'flex', alignItems: 'center' }}>
			{props.disableEdit ? (
				<></>
			) : (
				<EditShowGridField
					width={4}
					isEditing={props.disableEdit ? !props.disableEdit : true}
					label
				>
					<InputLabel id='lang-select-label'>{t('general.language')}</InputLabel>
					<Select
						labelId='lang-select-label'
						id='langs'
						name='langs'
						label={t('general.language')}
						disabled={!selectable}
						onChange={(event) => {
							const index = selectableItems.findIndex((m) => m.id === event.target.value);
							onGroupChange(id, selectableItems[index]);
						}}
						value={selectedItem.id}
					>
						{selectableItems.map((item) => (
							<MenuItem key={item.id} value={item.id}>
								{t(item.label)}
							</MenuItem>
						))}
					</Select>
				</EditShowGridField>
			)}
			<EditShowGridField
				width={props.disableEdit ? 8 : 7}
				isEditing={props.disableEdit ? !props.disableEdit : true}
				label
			>
				<TextField
					required
					label={t('product.name')}
					variant='outlined'
					id='value-input'
					onChange={(event) => {
						onChange(event.target.value);
					}}
					onBlur={(event) => {
						onChange(event.target.value);
					}}
					value={value}
				/>
			</EditShowGridField>
			{props.disableEdit ? (
				<></>
			) : (
				<Grid item xs={1} alignContent='center' justifyItems='center'>
					<IconButton
						onClick={() => onRemove(id)}
						sx={{
							transition: '200ms all cubic-bezier(.58,.16,.45,1.51)',
							backgroundColor: theme.palette.error.main,
							color: theme.palette.error.contrastText,
							'&:hover': {
								backgroundColor: theme.palette.error.contrastText,
								color: theme.palette.error.main,
							},
						}}
					>
						<DeleteIcon />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};
