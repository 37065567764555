import { AccordionDetails, Box, Button, Grid, IconButton, Stack } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import {
	AppFunction,
	CarrierDeliveryAreaResponse,
	CarrierDeliveryAreaSchema,
	LocaleEntries,
	TranslationTypes,
} from 'common';
import useLocale from '../../hooks/useLocale';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Cancel, Save } from '@mui/icons-material';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTranslation } from 'react-i18next';
import useCarriers from '@hooks/useCarriers';
import { DeliveryRegion } from './DeliveryRegion';
import { styled } from '@mui/material/styles';
import { PresetInputLangGroup } from '@components/PresetInputLangGroup';

interface Props {
	ngAreas: number[];
	area: CarrierDeliveryAreaResponse;
	isCreating?: boolean;
	onCancelCreate?: () => void;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion elevation={1} {...props} />)(
	() => ({
		border: '1px solid rgba(0, 0, 0, .050)',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&::before': {
			display: 'none',
		},
	}),
);

interface ExtendedAccordionSumamryProps extends AccordionSummaryProps {
	canExpand?: boolean;
}

const AccordionSummary = styled((props: ExtendedAccordionSumamryProps) => {
	const { canExpand, ...otherProps } = props;
	return (
		<MuiAccordionSummary
			expandIcon={canExpand && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...otherProps}
		/>
	);
})(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
	'&.Mui-focusVisible': {
		backgroundColor:
			theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	},
}));

export const DeliveryArea = ({
	ngAreas,
	area,
	isCreating: forceCreate,
	onCancelCreate: onStopCreating,
}: Props) => {
	const { createCarrierDeliveryArea, linkDeliveryAreaToRegions, unlinkDeliveryAreaFromRegion } =
		useCarriers();
	const { t } = useTranslation();
	const { getI18NString, renderI18NField } = useLocale();

	const [isExpanded, setExpanded] = useState(false);
	const [isEditing, setEditing] = useState(false);
	const [isAddingRegion, setAddingRegion] = useState(false);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CarrierDeliveryAreaResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: { ...area },
		resolver: zodResolver(CarrierDeliveryAreaSchema),
	});

	const onSubmit = async (data: CarrierDeliveryAreaResponse) => {
		const result = await createCarrierDeliveryArea(data);
		if (result) {
			onCancel();
		}
	};

	const onDelete = async () => {
		console.log('id', area.id);
	};

	const onCancel = () => {
		if (forceCreate) {
			onStopCreating?.();
		} else {
			setEditing(false);
			reset(area);
		}
	};

	const onAddRegion = async (regionId: number) => {
		if (!regionId) {
			return;
		}
		const result = await linkDeliveryAreaToRegions([{ areaId: area.id, regionId: regionId }]);
		if (result) {
			setAddingRegion(false);
		}
	};

	const onRemoveRegion = async (regionId?: number | null) => {
		if (!regionId) {
			return;
		}
		const result = await unlinkDeliveryAreaFromRegion({ areaId: area.id, regionId });
		console.log(result);
	};

	return (
		<Accordion
			expanded={isExpanded && area.id !== -1}
			onChange={() => {
				if (area.id !== -1) {
					setExpanded((oldValue) => !oldValue);
				}
			}}
		>
			<AccordionSummary canExpand={area.id !== -1}>
				{area.id === -1 ? (
					<Grid container rowGap={1}>
						<Controller
							name='strings'
							control={control}
							render={({ field }) => (
								<PresetInputLangGroup
									useReactHookForm
									showOnlyCurrent
									type={TranslationTypes.name}
									strings={field.value ?? []}
									onChange={(data: LocaleEntries) => {
										field.onChange(data);
									}}
								/>
							)}
						/>
						<Grid item xs={12}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<IconButton aria-label='submit' color='success' onClick={handleSubmit(onSubmit)}>
									<Save />
								</IconButton>
								<IconButton aria-label='delete' color='error' onClick={onCancel}>
									<Cancel />
								</IconButton>
							</Box>
						</Grid>
					</Grid>
				) : (
					<Box
						sx={{
							width: 'calc(100% - 1rem)',
							marginLeft: '1rem',
							display: 'flex',
							justifyContent: 'center',
							backgroundColor: '#e0e0e0',
							padding: '0.5rem',
							borderRadius: '0.5rem',
						}}
					>
						{renderI18NField(
							getI18NString(AppFunction.CarrierDeliveryArea, area.id, TranslationTypes.name),
						)}
					</Box>
				)}
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					gap={0.5}
					sx={{
						width: '100%',
					}}
				>
					{area.regions?.map((r, it) => (
						<DeliveryRegion
							key={`${it}-region`}
							region={r}
							onStopCreate={() => setAddingRegion(false)}
							onAddRegion={onAddRegion}
							onRemoveRegion={onRemoveRegion}
							ngAreas={ngAreas}
						/>
					))}
					{!isAddingRegion ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<AnimatedIconButton
								text={t('operations.createItem', { item: t(`appFunctions.${AppFunction.Region}`) })}
								icon={<Add />}
								onClick={() => setAddingRegion(true)}
								colorType='info'
							/>
						</Box>
					) : (
						<DeliveryRegion
							onStopCreate={() => setAddingRegion(false)}
							onAddRegion={onAddRegion}
							onRemoveRegion={onRemoveRegion}
							ngAreas={ngAreas}
						/>
					)}
					<Box>
						{isEditing ? (
							<Button onClick={handleSubmit(onSubmit)}>Save</Button>
						) : (
							<Button onClick={() => setEditing(true)}>Edit</Button>
						)}
						<Button onClick={onDelete}>Delete</Button>
						<Button onClick={onCancel}>Cancel</Button>
					</Box>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
