import { useTabs } from '@contexts/tabContext/TabContext';
import { Close } from '@mui/icons-material';
import { Fab, Tooltip, keyframes } from '@mui/material';
import { useState } from 'react';
import { FormSidefabType, tabFabConfig } from './config';
import { useTranslation } from 'react-i18next';
import { useGenericForm } from '@contexts/formContext/FormContext';

interface Props {
	fabType: FormSidefabType;
}

const fabAnim = keyframes`
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.12);
    }
`;

const fabAppear = keyframes`
    from {
        z-index: -1200;
        opacity: 0;
        transform: scale(0.4) translateX(-6rem);
    }
    to {
        z-index: 1;
        opacity: 1;
        transform: scale(1) translateX(0);
    }
`;

export const TabFab = ({ fabType }: Props) => {
	const { entityId } = useGenericForm();
	const { openFab, setOpenFab } = useTabs();
	const [hover, setHover] = useState(false);

	const { t } = useTranslation();

	const config = tabFabConfig[fabType];
	const disabled = (openFab !== null && openFab !== fabType) || entityId === -1;

	return (
		<Tooltip
			key={fabType}
			title={t(config.tooltip)}
			placement='right'
			arrow
			onMouseEnter={() => !disabled && setHover(true)}
			onMouseLeave={() => !disabled && setHover(false)}
			open={(hover && openFab !== fabType) || false}
		>
			<div>
				<Fab
					color={openFab === fabType ? 'error' : 'info'}
					size='small'
					disabled={disabled}
					sx={{
						animation:
							openFab === fabType ? `${fabAnim} 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)` : '',
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						'&:hover': {
							transform: 'scale(1.12)',
						},
					}}
					onClick={() => {
						setHover(false);
						setOpenFab?.(openFab === fabType ? null : fabType);
					}}
				>
					{openFab === fabType ? <Close /> : config.icon}
				</Fab>
			</div>
		</Tooltip>
	);
};
