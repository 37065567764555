import API from '../index';
import { AffectedRowsResponse, PriceCustomersRequest } from 'common';

export const execute = async (params: PriceCustomersRequest): Promise<AffectedRowsResponse> => {
	const response = await API.put('/api/v1/inventory/variants/customersprices', params);
	if (response.status < 200 && response.status > 300) {
		throw new Error(response.data);
	}
	const data = (await response.data.payload) as AffectedRowsResponse;
	return data;
};
