import { Button } from '@mui/material';
import { useTask, useLayout } from '@contexts/index';
import { AppFunction, TaskStatus } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	popupId?: string;
}

export const CloseTaskButton = ({ popupId }: Props) => {
	const { taskState, canClose, closeTask, acceptTask } = useTask();
	const { popPopup } = useLayout();
	const { t } = useTranslation();

	return (
		<div
			style={{
				padding: '1rem',
			}}
		>
			{(taskState === TaskStatus.inprogress || taskState === TaskStatus.stub) && (
				<Button
					fullWidth
					onClick={async () => {
						try {
							await closeTask();
							popupId && popPopup(popupId);
						} catch (error) {
							console.error('Failed to close task', error);
						}
					}}
					variant='contained'
					disabled={!canClose}
				>
					{t(`${AppFunction.Task}.closeTask`)}
				</Button>
			)}
			{taskState === 'pending' && (
				<Button fullWidth onClick={acceptTask} variant='contained'>
					{t(`${AppFunction.Task}.acceptTask`)}
				</Button>
			)}
		</div>
	);
};
