import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCustomerTypes from '@hooks/useCustomerTypes';
import { Grid, TextField } from '@mui/material';
import { CustomerResponse } from 'common';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CustomerCode = () => {
	const { control, watch, getValues, setValue, errors, formAction } =
		useGenericForm<CustomerResponse>();

	const { t } = useTranslation();
	const { customerTypesList, applyCodePrefix } = useCustomerTypes();

	const typeId = watch('typeId');
	const canEdit = formAction !== 'view';

	useEffect(() => {
		const oldCode = getValues('customerCode');
		const newCode = applyCodePrefix(typeId, oldCode);
		if (newCode !== oldCode && newCode !== '') {
			setValue('customerCode', newCode);
		}
	}, [typeId]);

	const calculateFieldValue = (beforeParse: string): string => {
		if (beforeParse != undefined && beforeParse !== '') {
			const values = beforeParse.split('-');
			if (values.length === 2) {
				return values[1];
			}
			return values[0];
		}
		return '';
	};

	let prefix = '';
	if (typeId) {
		const customerType = customerTypesList.find((ct) => ct.id === typeId);
		prefix = customerType?.customerCodePrefix ?? '';
	}

	return (
		<>
			{prefix !== '' && (
				<Grid
					item
					xs={0.6}
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				>
					{prefix}
				</Grid>
			)}
			<Controller
				name='customerCode'
				control={control}
				render={({ field }) => (
					<InputGridField width={prefix !== '' ? 5.4 : 6} type={ComponentType.TextField}>
						<TextField
							variant={'outlined'}
							required
							size='small'
							id='customer-code'
							fullWidth
							disabled={!canEdit}
							label={t('customer.customerCode')}
							error={!!errors.customerCode}
							helperText={errors.customerCode && errors.customerCode.message}
							value={calculateFieldValue(field.value)}
							onChange={(ev) =>
								field.onChange(
									prefix !== '' ? `${prefix}-${ev.target.value}` : `${ev.target.value}`,
								)
							}
						/>
					</InputGridField>
				)}
			/>
		</>
	);
};
