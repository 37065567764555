import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { Grid, MenuItem, Select } from '@mui/material';
import { AppFunction, MerchantResponse, MerchantType } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control } = useGenericForm<MerchantResponse>();
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='merchant.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='type'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'merchant-type',
							label: t('merchant.type'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='merchant-type'
							id='merchant'
							value={field.value}
							label={t('merchant.type')}
							disabled={false}
							size='small'
							fullWidth
							onChange={(event) => field.onChange(event.target.value as MerchantType)}
						>
							{(Object.keys(MerchantType) as (keyof typeof MerchantType)[]).map((key, index) => {
								return (
									<MenuItem value={MerchantType[key]} key={index}>
										{t(`${AppFunction.Merchant}.types.${MerchantType[key]}`)}
									</MenuItem>
								);
							})}
						</Select>
					</InputGridField>
				)}
			/>
			<Grid item xs={12} sx={{ minHeight: '100px' }} />
		</Grid>
	);
};
