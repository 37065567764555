import { fetchMap } from '@store/fetchMap';
import { useAppDispatch } from '@store/hooks';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const HEARTBEAT_INTERVAL = 60000;

type WebsocketMessageType = {
	data: 'string';
};

interface WebsocketContextType {
	ws: WebSocket | null;
	setWs: (ws: WebSocket) => void;
}

const defaultContext: WebsocketContextType = {
	ws: null,
	setWs: () => console.warn('no websocket provider'),
};

const WebsocketContext = createContext<WebsocketContextType>(defaultContext);

export const WebsocketProvider = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useAppDispatch();
	const heartbeatRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

	const [ws, setWs] = useState<WebSocket | null>(null);
	const { lastMessage, readyState, sendMessage } = useWebSocket(
		process.env.REACT_APP_WS_URL ?? '',
		{
			shouldReconnect: () => true,
			reconnectInterval: 5000,
			onOpen: () => console.log('WebSocket connected'),
			onClose: (event) => console.error('WebSocket closed:', event),
			onError: (event) => console.error('WebSocket error:', event),
		},
	);

	useEffect(() => {
		if (readyState === ReadyState.OPEN) {
			heartbeatRef.current = setInterval(() => {
				sendMessage(JSON.stringify({ type: 'heartbeat' }));
			}, HEARTBEAT_INTERVAL);
		} else if (readyState === ReadyState.CLOSED || readyState === ReadyState.CLOSING) {
			if (heartbeatRef.current !== undefined) {
				clearInterval(heartbeatRef.current);
				heartbeatRef.current = undefined;
			}
		}
		return () => {
			if (heartbeatRef.current !== undefined) {
				clearInterval(heartbeatRef.current);
				heartbeatRef.current = undefined;
			}
		};
	}, [readyState, sendMessage]);

	useEffect(() => {
		if (readyState === ReadyState.CLOSED) {
			console.log('WebSocket closed. Attempting to reconnect...');
		}
		if (readyState === ReadyState.OPEN) {
			console.log('WebSocket connected');
		}
		if (readyState === ReadyState.CONNECTING) {
			console.log('WebSocket connecting...');
		}
		if (readyState === ReadyState.CLOSING) {
			console.log('WebSocket closing...');
		}
	}, [readyState]);

	useEffect(() => {
		if (!lastMessage) return;
		const message = lastMessage as WebsocketMessageType;
		console.log(message.data);
		const dispatcher = fetchMap[message.data];
		if (dispatcher) {
			dispatch(dispatcher());
		}
	}, [lastMessage]);

	return <WebsocketContext.Provider value={{ ws, setWs }}>{children}</WebsocketContext.Provider>;
};

export const useWebsocket = () => useContext(WebsocketContext);
