import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, List, useTheme } from '@mui/material';
import { calculateAdjustedPosition } from '../utils/utils';
import { DragZoneName, DropZoneName } from '@pages/Tasks/TaskChainArchetypeCreate';
import { EditingMode, useChainEditor } from '@contexts/chainEditorContext/ChainEditorContext';
import { useChainArchetypeEditor } from '@contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { ChainArchetypeHead } from './ChainArchetypeHead';
import { ChainArchetypeTail } from './ChainArchetypeTail';
import { TaskArchetypeEditor } from '../TaskArchetypeEditor';
import { Save } from '@mui/icons-material';
import { ChainButtons } from './ChainButtons';

interface Props {
	chainIndex: number;
	viewMode?: boolean;
}

export const ChainArchetypeEditor = ({ chainIndex, viewMode }: Props) => {
	const theme = useTheme();

	const { tasks, addTaskAtIndex, moveTaskAtIndex, onSubmit } = useChainArchetypeEditor();

	const {
		dragDropState,
		zoomPanState,
		setDragDropState,
		setEditingMode,
		setIsHoveringChain,
		setIsMouseDownOnChain,
	} = useChainEditor();

	const [isDropDisabled, setIsDropDisabled] = useState<boolean>(false);
	const [isAddDisabled, setIsAddDisabled] = useState<boolean>(false);
	const [editingTask, setEditingTask] = useState<number | null>(null);

	const [placeholderProps, setPlaceholderProps] = useState({
		clientHeight: 0,
		clientWidth: 0,
		clientY: 0,
		clientX: 0,
	});

	useEffect(() => {
		setIsAddDisabled(dragDropState.state !== 'none');
		const dropEnabled =
			dragDropState.sourceType === DragZoneName.taskArchetypeDroppableList ||
			dragDropState.sourceType === `${DropZoneName.taskArchetypeDropZone}_${chainIndex}`;
		setIsDropDisabled(!dropEnabled);
		if (!dropEnabled) {
			return;
		}
		switch (dragDropState.state) {
			case 'dragging':
				// const domQuery = `[data-rbd-placeholder-context-id='${0}']`;
				// const draggedDOM = document.querySelector(domQuery) as HTMLElement;
				break;
			case 'draggingOver':
				// console.log('draggingOver');
				if (dragDropState.sourceType === `${DropZoneName.taskArchetypeDropZone}_${chainIndex}`) {
					console.log(`draggingOver id ${dragDropState.sourceId} at ${dragDropState.insertIndex}`);
					calculateClientY(
						dragDropState.draggedElement as Element,
						dragDropState.insertIndex ?? 0,
						dragDropState.sourceId ?? 0,
					);
				}
				break;
			case 'dropped':
				if (dragDropState.sourceType === DragZoneName.taskArchetypeDroppableList) {
					console.log(`dropped id ${dragDropState.sourceId} at ${dragDropState.insertIndex}`);
					if (dragDropState.sourceId === undefined || dragDropState.insertIndex === undefined)
						return;
					addTaskAtIndex(dragDropState.insertIndex, dragDropState.sourceId);
					setDragDropState({ state: 'none' });
				} else if (
					dragDropState.sourceType === `${DropZoneName.taskArchetypeDropZone}_${chainIndex}`
				) {
					console.log(`dropped index ${dragDropState.sourceId} at ${dragDropState.insertIndex}`);
					moveTaskAtIndex(dragDropState.sourceId ?? 0, dragDropState.insertIndex ?? 0);
				}
				setDragDropState({ state: 'none' });
				break;
			case 'none':
				return;
		}
	}, [dragDropState]);

	const onStartEditTaskInner = (taskIndex: number) => {
		setEditingMode(EditingMode.Task);
		setEditingTask(taskIndex);
	};

	const onStopEditTask = () => {
		setEditingMode(EditingMode.Chain);
		setEditingTask(null);
	};

	const calculateClientY = (draggedDOM: Element, destinationIndex: number, sourceIndex: number) => {
		if (!draggedDOM.parentNode) return;
		const { clientHeight, clientWidth } = draggedDOM;

		const childrenArray = Array.from(draggedDOM.parentNode.children);
		const movedItem = childrenArray[sourceIndex];
		childrenArray.splice(sourceIndex, 1);

		const updatedArray = [
			...childrenArray.slice(0, destinationIndex),
			movedItem,
			...childrenArray.slice(destinationIndex + 1),
		];

		let clientY =
			parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
			updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
				const style = getComputedStyle(curr as HTMLElement);
				const marginBottom = parseFloat(style.marginBottom);
				return total + curr.clientHeight + marginBottom;
			}, 0);

		clientY = destinationIndex === 0 ? clientY : clientY + 8;

		setPlaceholderProps({
			clientHeight,
			clientWidth,
			clientY,
			clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
		});
	};

	const buttons = (): ReactNode => (
		<Save
			className='dcExcluded'
			sx={{
				maxWidth: '1.5rem',
				maxHeight: '1.5rem',
				position: 'absolute',
				bottom: '0.3rem',
				right: '0.3rem',
				cursor: 'pointer',
				borderRadius: '50%',
				padding: '0.2rem',
				color: 'white',
				backgroundColor: 'green',
				// transform: `scale(${editing ? 1 : 0})`,
				transition: 'transform 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
			}}
			onClick={onSubmit}
		/>
	);

	const memoizedTasks = useMemo(
		() =>
			tasks.map((task, it) => (
				<Draggable
					key={`${task}_${it}`}
					draggableId={`${DragZoneName.taskArchetypeDroppableList}_${it}`}
					index={it}
					isDragDisabled={editingTask !== null}
				>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							style={calculateAdjustedPosition(provided, snapshot, zoomPanState)}
						>
							<TaskArchetypeEditor
								addTaskDisabled={isAddDisabled || editingTask !== null}
								key={it}
								taskArchetypeId={task}
								taskIndex={it}
								onStartEdit={onStartEditTaskInner}
								onStopEdit={onStopEditTask}
							/>
						</div>
					)}
				</Draggable>
			)),
		[tasks, editingTask, zoomPanState],
	);

	const dragHereArea = tasks.length === 0 &&
		dragDropState.state !== 'draggingOver' &&
		dragDropState.state !== 'dropped' &&
		dragDropState.state === 'dragging' &&
		dragDropState.sourceType === DragZoneName.taskArchetypeDroppableList && (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '36px',
					width: '100%',
					border: '1px dashed blue',
					borderRadius: '15px',
				}}
			>
				drag here
			</div>
		);

	const customPlaceholder = (isDraggingOver: boolean) =>
		dragDropState.draggedElement &&
		isDraggingOver &&
		!isDropDisabled && (
			<div
				style={{
					height: dragDropState.draggedElement.clientHeight,
					width: dragDropState.draggedElement.clientWidth,
					paddingTop: '8px',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: placeholderProps.clientY,
						left: placeholderProps.clientX,
						height: placeholderProps.clientHeight,
						width: placeholderProps.clientWidth,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						border: '1px dashed black',
						backgroundColor: 'teal',
						opacity: 0.2,
						borderRadius: '15px',
					}}
				>
					dragging
				</div>
			</div>
		);

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
				padding: '1rem 1rem 2rem 1rem',
				border: '1px solid black',
				borderRadius: '15px',
				background: theme.palette.gradient.secondaryAlpha,
				transition: 'height 1.4s ease-in-out',
				height: 'auto',
			}}
			onMouseEnter={() => setIsHoveringChain(true)}
			onMouseLeave={() => setIsHoveringChain(false)}
			onMouseDown={() => setIsMouseDownOnChain(true)}
			id='testChainId'
		>
			<ChainButtons />
			<ChainArchetypeHead addTaskDisabled={isAddDisabled || editingTask !== null} />
			<Droppable
				droppableId={`${DropZoneName.taskArchetypeDropZone}_${chainIndex}`}
				isDropDisabled={isDropDisabled}
			>
				{(provided, snapshot) => (
					<List
						ref={provided.innerRef}
						{...provided.droppableProps}
						sx={{ minHeight: '2rem', width: '100%' }}
					>
						{memoizedTasks}
						{dragHereArea}
						{customPlaceholder(snapshot.isDraggingOver)}
						<div style={{ maxWidth: '0px', maxHeight: '0px', marginTop: '8px' }}>
							{provided.placeholder}
						</div>
					</List>
				)}
			</Droppable>
			<ChainArchetypeTail viewMode={viewMode} />
			{buttons()}
		</Box>
	);
};
