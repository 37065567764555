import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { CustomersGroupSelector } from '@components/groups/CustomersGroupSelector';
import { PeopleGroupSelector } from '@components/groups/PeopleGroupSelector';
import { WarehouseGroupSelector } from '@components/groups/WarehouseGroupSelector';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/index';
import useGroups from '@hooks/useGroups';
import { Grid, MenuItem, Select } from '@mui/material';
import {
	EntitiesEntityGroupRequest,
	EntityGroupRequest,
	EntityGroupResponse,
	GroupableType,
} from 'common';
import _, { set } from 'lodash';
import { createElement, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { t } = useTranslation();
	const {
		control,
		formAction,
		entityId,
		getValues,
		setAfterCreate,
		setAfterEdit,
		setForcedDirtyState,
	} = useGenericForm<EntityGroupResponse>();
	const { linksList, getLinkedEntities, linkEntitiesToGroup, unlinkEntityFromGroup } = useGroups();

	const [cachedMembers, setCachedMembers] = useState<number[]>([]);
	const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
	const [membersSelector, setMembersSelector] = useState<JSX.Element>(
		<CustomersGroupSelector members={[]} onValuesChanged={(m) => setSelectedMembers(m)} />,
	);

	const cascadeRequestsRef = useRef<(data: EntityGroupResponse) => Promise<boolean>>(() =>
		Promise.reject(),
	);

	useEffect(() => {
		const entities = getLinkedEntities(entityId)?.map((e) => e.entityId);
		setSelectedMembers(entities ?? []);
		setCachedMembers(entities ?? []);
		onBeforeSetType(getValues('type'), entities);
		setForcedDirtyState(!_.isEqual(_.sortBy(entities), _.sortBy(selectedMembers)));
	}, [entityId, getValues('type'), linksList]);

	// TODO: can this be nested in a useEffect? so we do not recalculate it every time?
	// lol don't think so
	// TODO: add checks (rawdogging the data now)
	cascadeRequestsRef.current = async (data: EntityGroupResponse): Promise<boolean> => {
		try {
			const difference = cachedMembers.filter((item) => !selectedMembers.includes(item));
			const newMembers: EntitiesEntityGroupRequest = selectedMembers.map((memberId) => ({
				entityId: memberId,
				entityType: data.type,
				entityGroupId: data.id,
			}));
			await linkEntitiesToGroup(newMembers);
			const membersToUnlink: EntitiesEntityGroupRequest = difference.map((memberId) => ({
				entityId: memberId,
				entityType: data.type,
				entityGroupId: data.id,
			}));
			await unlinkEntityFromGroup(membersToUnlink);
			setForcedDirtyState(false);
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	useEffect(() => {
		const entities = getLinkedEntities(entityId)?.map((e) => e.entityId);
		setForcedDirtyState(!_.isEqual(_.sortBy(entities), _.sortBy(selectedMembers)));
	}, [selectedMembers]);

	useEffect(() => {
		setAfterCreate((data: EntityGroupResponse): Promise<boolean> => {
			cascadeRequestsRef.current(data).catch((error) => {
				return Promise.reject(error);
			});
			return Promise.resolve(true);
		});
	}, [setAfterCreate]);

	useEffect(() => {
		setAfterEdit((data: EntityGroupResponse): Promise<boolean> => {
			cascadeRequestsRef.current(data).catch((error) => {
				return Promise.reject(error);
			});
			return Promise.resolve(true);
		});
	}, [setAfterEdit]);

	const onBeforeSetType = (type: GroupableType, entities?: number[]) => {
		const selectorProps = {
			members: entities ?? [],
			viewMode: formAction === 'view',
			onValuesChanged: setSelectedMembers,
		};
		let selector = null;
		switch (type) {
			case GroupableType.customer:
				selector = CustomersGroupSelector;
				break;
			case GroupableType.person:
				selector = PeopleGroupSelector;
				break;
			case GroupableType.warehouse:
				selector = WarehouseGroupSelector;
				break;
		}
		if (selector !== null) {
			setMembersSelector(createElement(selector, selectorProps));
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='entityGroup.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='type'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'entity-group-type',
							label: t('entityGroup.type'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='group-type-label'
							id='group-type'
							label={t('entityGroup.type')}
							size='small'
							fullWidth
							value={field.value}
							onChange={(event) => {
								onBeforeSetType(event.target.value as GroupableType, []);
								field.onChange(event.target.value as GroupableType);
							}}
						>
							{Object.values(GroupableType).map((value, it) => (
								<MenuItem key={it} value={value}>
									{value}
								</MenuItem>
							))}
						</Select>
					</InputGridField>
				)}
			/>
			<Grid item xs={12}>
				{membersSelector}
			</Grid>
		</Grid>
	);
};
