import { SxProps, Theme } from '@mui/material';

// export const config: SxProps<Theme> = {
// 	'&& .MuiInputLabel-root': {
// 		pointerEvents: 'none',
// 	},
// 	'&& .MuiInputBase-root': {
// 		color: 'black',
// 		pointerEvents: 'none',
// 		userSelect: 'none !important',
// 		borderColor: 'transparent',
// 		opacity: 1,
// 		backgroundColor: 'background.paper',
// 	},
// 	'&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
// 		borderColor: 'transparent !important',
// 	},
// 	'&& .MuiInput-underline:before': {
// 		borderBottom: 'none !important',
// 	},
// 	'&& .MuiAutocomplete-endAdornment': {
// 		display: 'none',
// 	},
// 	'&& .MuiSvgIcon-root': {
// 		display: 'none',
// 	},
// 	// Hide number input spinner in WebKit browser
// 	'&& input::-webkit-outer-spin-button, && input::-webkit-inner-spin-button':
// 	{
// 		WebkitAppearance: 'none',
// 		margin: 0,
// 	},
// 	// Hide number input spinner in Firefox
// 	'&& input': {
// 		MozAppearance: 'textfield',
// 	},
// };

export const config: SxProps<Theme> = {
	'&& .MuiFormControl-root': {
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiInputBase-root': {
		color: 'black',
		pointerEvents: 'none !important',
		borderColor: 'transparent',
		opacity: 1,
		backgroundColor: 'background.paper',
		userSelect: 'none !important',
	},
	'&& .MuiInputBase-input': {
		userSelect: 'none !important',
	},
	'&& .MuiFormLabel-root': {
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent !important',
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiButtonBase-root': {
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiAutocomplete-root': {
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiAutocomplete-inputRoot': {
		pointerEvents: 'none !important',
		userSelect: 'none !important',
	},
	'&& .MuiAutocomplete-input': {
		WebkitAppearance: 'none !important',
		MozAppearance: 'textfield !important',
		appearance: 'none !important',
		color: 'black !important',
		backgroundColor: 'background.paper',
		pointerEvents: 'none !important',
		userSelect: 'none !important',
		outline: 'none !important',
		caretColor: 'transparent',
	},
	'&& .MuiAutocomplete-popupIndicator': {
		display: 'none !important',
		pointerEvents: 'none !important',
	},
	'&& .MuiAutocomplete-clearIndicator': {
		display: 'none !important',
		pointerEvents: 'none',
	},
	'&& .MuiAutocomplete-listbox': {
		display: 'none !important',
		pointerEvents: 'none',
	},
	// Hide number input spinner in WebKit browser
	'&& input[type="text"]::-webkit-outer-spin-button, && input[type="text"]::-webkit-inner-spin-button':
		{
			WebkitAppearance: 'none !important',
			userSelect: 'none !important',
			margin: 0,
		},
	'&& input[type="text"]': {
		userSelect: 'none !important',
		pointerEvents: 'none !important',
		keyboard: 'none !important',
		MozAppearance: 'text !important',
		WebkitAppearance: 'none !important',
		color: 'black !important',
		// backgroundColor: 'blue'
	},
	'&& input': {
		userSelect: 'none !important',
		pointerEvents: 'none !important',
		keyboard: 'none !important',
		MozAppearance: 'text !important',
		WebkitAppearance: 'none !important',
		color: 'black !important',
	},
	'&& input:-webkit-autofill': {
		WebkitBoxShadow: '0 0 0 100px #303030 inset',
		WebkitTextFillColor: 'red',
	},
	'&& fieldset': {
		display: 'none !important',
	},
};
