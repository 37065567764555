import { orderDetailsEntryConfig } from '@components/orders/details/entries/config';
import { OrderHeader } from '@components/orders/details/header/OrderHeader';
import useOrders from '@hooks/useOrders';
import { Container, useTheme } from '@mui/material';
import { OrderBaseResponse, OrderProductResponse } from 'common';
import { useEffect, useState } from 'react';

interface Props {
	id: number;
	orderProduct?: OrderProductResponse;
}

export interface OrderTreeNode extends OrderBaseResponse {
	children: OrderTreeNode[];
}

export const OrderDetails = ({ id, orderProduct }: Props) => {
	const { orderList, findHighestParent } = useOrders();
	const theme = useTheme();
	const [orderData, setOrderData] = useState<OrderBaseResponse | null>(null);
	const [tree, setTree] = useState<OrderTreeNode>({} as OrderTreeNode);
	const [afterTree, setAfterTree] = useState<OrderTreeNode[]>([]);

	const [viewingOrderData, setViewingOrderData] = useState<OrderBaseResponse | null>(null);

	useEffect(() => {
		const buildTree = (orderId: number): OrderTreeNode | null => {
			const order = orderList.find((o) => o.id === orderId);
			if (!order) return null;

			// Recursively find and attach children, typed as OrderTreeNode[]
			const children: OrderTreeNode[] = orderList
				.filter((o) => o.parentId === orderId)
				.map((childOrder) => buildTree(childOrder.id) as OrderTreeNode)
				.filter((child): child is OrderTreeNode => child !== null);

			// Return the current order with its children, typed as OrderTreeNode
			return { ...order, children };
		};

		if (orderList) {
			const order = orderList.find((order) => order.id === id);
			if (order) {
				const highestParent = findHighestParent(order.id);

				if (highestParent) {
					const found = buildTree(highestParent.id);
					if (found) {
						setTree(found);
					}
				}

				setOrderData(order);
				setViewingOrderData(order);
			}
		}
		if (orderProduct) {
			const afterOrder = orderList?.filter((order) =>
				order.content?.some((content) => content.sourceId === orderProduct.id),
			);
			if (!afterOrder) return;

			const afterTrees: OrderTreeNode[] = [];
			afterOrder.map((ao) => {
				const findHighestParent = (orderId: number): OrderBaseResponse | undefined => {
					const parentOrder = orderList.find((o) => o.id === orderId);
					if (!parentOrder) return undefined;
					if (parentOrder.parentId) {
						const nextParentOrder = orderList.find((o) => o.id === parentOrder.parentId);
						if (!nextParentOrder) {
							return parentOrder;
						}
						return findHighestParent(parentOrder.parentId);
					} else {
						return parentOrder;
					}
				};

				const highestParent = findHighestParent(ao.id);

				if (highestParent) {
					const found = buildTree(highestParent.id);
					if (found) {
						afterTrees.push(found);
					}
				}
			});
			setAfterTree(afterTrees);
		}
	}, [orderList, id]);

	const selectSuborder = (subOrderId: number) => {
		if (!orderData) return;
		if (subOrderId !== orderData.id) {
			const subOrder = orderList?.find((order) => order.id === subOrderId);
			if (subOrder) {
				setViewingOrderData({ ...subOrder, id: subOrderId });
			}
		} else {
			setViewingOrderData(orderData);
		}
	};

	if (orderData && viewingOrderData) {
		const EntryComponent = viewingOrderData?.orderType
			? orderDetailsEntryConfig[viewingOrderData.orderType]
			: null;

		return (
			<Container
				maxWidth={false}
				sx={{
					padding: '2rem !important',
					background: theme.palette.gradient.primary,
					borderRadius: '1rem',
					margin: 0,
					minHeight: '100%',
				}}
			>
				<OrderHeader
					data={orderData}
					orderTree={tree}
					afterTree={afterTree}
					selectSuborder={selectSuborder}
					selectedSubOrder={viewingOrderData?.id}
					orderProduct={orderProduct}
				/>
				{EntryComponent && viewingOrderData && (
					<EntryComponent data={viewingOrderData} orderProduct={orderProduct} />
				)}
			</Container>
		);
	}
	return null;
};
