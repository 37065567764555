import { ReactNode } from 'react';
import { FocusedElementType } from '../../store/app';

export interface TabbedElementProps {
	title: string;
	children?: ReactNode;
	onBeforeShowTab?: () => boolean;
	hideEditButton?: boolean;
	scopes?: FocusedElementType[];
}

export const TabbedElement = (props: TabbedElementProps) => {
	return <>{props.title}</>;
};
