import { OrderDirectionType, ShippingType, StorageType } from 'common';

/**
 * This function takes the main order direction type and returns the possible shipping types.
 * @param {OrderDirectionType} directionType The main order direction type
 * @param {StorageType} storageType The main order end point storage type (optional)
 * @returns {ShippingType[] | null} The possible shipping types
 */
export interface ShippingTypeFunction {
	(directionType: OrderDirectionType, storageType?: StorageType): ShippingType[] | null;
}

/**
 * This function takes the main order direction type (fromType)
 * and returns the possible main order direction types (toType).
 * @param {OrderDirectionType} directionType The main order direction type (fromType)
 * @returns {OrderDirectionType[] | null} The possible main order direction types (toType).
 */
export interface ToTypeFunction {
	(directionType: OrderDirectionType): OrderDirectionType[] | null;
}

/**
 * This function takes the main order direction type and the main order shipping type and
 * returns the possible main order end point storage types.
 * This will be expanded to support types of clients and stuff like that.
 * @param {OrderDirectionType} directionType The main order direction type
 * @param {ShippingType} shippingType The main order shipping type
 * @returns {StorageType[] | null} The possible main order end point storages
 */
export interface ToSubTypeFunction {
	(directionType: OrderDirectionType, shippingType: ShippingType): StorageType[] | null;
}

/**
 * This function takes the main order direction type and the main order end point storage and
 * returns the possible sub order types.
 * @param {OrderDirectionType} directionType The main order direction type (toType)
 * @param {StorageType} storageType The main order end point storage type
 * @returns {OrderDirectionType[] | null} The possible sub order types.
 */
export interface SubOrderTypeFunction {
	(directionType: OrderDirectionType, sourceStorageType: StorageType): OrderDirectionType[] | null;
}

/**
 * This type is used to store the possible values of an order element.
 */
export type OrderElementValueOutput =
	| string
	| boolean
	| OrderDirectionType[]
	| ShippingTypeFunction
	| ToSubTypeFunction
	| SubOrderTypeFunction;

/**
 * This class is used to store the configuration of an order element.
 * This configuration refers to the OrderType of the main order.
 */
export class OrderElementConfigItem {
	private _value: OrderElementValueOutput;
	public get value(): OrderElementValueOutput {
		return this._value;
	}

	constructor(value: OrderElementValueOutput) {
		this._value = value;
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is a boolean
	 */
	isBoolean(): this is { value: boolean } {
		return typeof this.value === 'boolean';
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is an array of {@link OrderDirectionType}
	 */
	isArrayofOrderDirectionType(): this is { value: OrderDirectionType[] } {
		// There are functions that have a return type of OrderDirectionType[]
		// but here we are checking for a simple array, not a function that returns an array.
		if (typeof this.value === 'function') return false;

		if (Array.isArray(this.value)) {
			return this.value.every((val) => Object.values(OrderDirectionType).includes(val));
		}
		return false;
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is a function of type {@link ShippingTypeFunction}
	 */
	isShippingTypeFunction(): this is { value: ShippingTypeFunction | null } {
		return typeof this.value === 'function';
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is a function of type {@link ToTypeFunction}
	 */
	isToTypeFunction(): this is { value: ToTypeFunction | null } {
		return typeof this.value === 'function';
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is a function of type {@link ToSubTypeFunction}
	 */
	isToSubTypeFunction(): this is { value: ToSubTypeFunction | null } {
		return typeof this.value === 'function';
	}

	/**
	 * Type guard for the value of the {@link OrderElementConfigItem}
	 * @returns {boolean} True if the value is a function of type {@link SubOrderTypeFunction}
	 */
	isSubOrderTypeFunction(): this is { value: SubOrderTypeFunction | null } {
		return typeof this.value === 'function';
	}
}
