import { z } from 'zod';
import {
	TaskApiActionData,
	TaskFieldConfig,
	parseObjectToValues,
	parseValuesToString,
} from '../taskFieldConfig';
import { AffectedRowsResponse, OrderProductsResponse, OrderProductsUpdateRequest } from 'common';
import { doSetOrderProductsData } from 'store/order';
import { doSetTaskValues } from 'store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';

const ConfirmPricesFieldItemSchema = z.object({
	variantId: z.number(),
	basePrice: z.number(),
	duties: z.number(),
	freight: z.number(),
	finalPrice: z.number(),
});

const ConfirmPricesFieldDutySchema = z.object({
	value: z.number(),
	currency: z.number(),
});

const ConfirmPricesFieldOthersSchema = z.object({
	name: z.string(),
	value: z.number(),
	currency: z.number(),
});

const ConfirmPricesFieldHeaderSchema = z.object({
	duties: ConfirmPricesFieldDutySchema,
	others: z.array(ConfirmPricesFieldOthersSchema),
	finalPriceCurrency: z.number(),
	exchangeRate: z.number(),
});

const ConfirmPricesFieldValueSchema = ConfirmPricesFieldHeaderSchema.extend({
	values: z.array(ConfirmPricesFieldItemSchema),
});

const ConfirmPricesFieldValuesSchema = z.array(ConfirmPricesFieldValueSchema);

export type ConfirmPricesFieldHeader = z.infer<typeof ConfirmPricesFieldHeaderSchema>;
export type ConfirmPricesFieldDuty = z.infer<typeof ConfirmPricesFieldDutySchema>;
export type ConfirmPricesFieldOthers = z.infer<typeof ConfirmPricesFieldOthersSchema>;
export type ConfirmPricesFieldItem = z.infer<typeof ConfirmPricesFieldItemSchema>;
export type ConfirmPricesFieldValue = z.infer<typeof ConfirmPricesFieldValueSchema>;

async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(ConfirmPricesFieldValuesSchema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	const setValuesResult = (await data.dispatch(
		doSetTaskValues(setValuesRequest),
	)) as PayloadAction<AffectedRowsResponse>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
}

export const confirmpricesFieldConfig: TaskFieldConfig = {
	stringToValues: (value: string) => [
		parseObjectToValues<ConfirmPricesFieldValue>(ConfirmPricesFieldValueSchema, value),
	],
	valuesToString: (values: unknown[]) => parseValuesToString(ConfirmPricesFieldValueSchema, values),
	validateStructure: (values: unknown[]): boolean => {
		// TODO: Validate the structure of the values
		// console.log(values);
		return true;
	},
	executeApiAction: async (data: TaskApiActionData) => {
		const values = ConfirmPricesFieldValuesSchema.safeParse(data.values);
		if (!values.success) return Promise.reject(new Error('Invalid values'));

		// Let's set the order products data
		// We need to use only the first value of the array because it's the only one that gets updated
		const entriesRequest: OrderProductsResponse =
			data.orderProducts?.map((product) => {
				const newProductData = values.data[0].values.find(
					(value) => value.variantId === product.variantId,
				);
				return {
					...product,
					importTaxes: newProductData?.duties ?? -1,
					transportFees: newProductData?.freight ?? -1,
					actualPrice: newProductData?.finalPrice ?? -1,
					actualCurrency: values.data[0].finalPriceCurrency,
				};
			}) ?? [];

		const setDataRequest: OrderProductsUpdateRequest = {
			entries: entriesRequest,
			customUpdateFields: ['actualCurrency', 'actualPrice', 'importTaxes', 'transportFees'],
		};

		const setProductPricesResult = (await data.dispatch(
			doSetOrderProductsData(setDataRequest),
		)) as PayloadAction<AffectedRowsResponse>;
		if (setProductPricesResult.type !== 'orders/products/set/fulfilled') {
			return Promise.reject(new Error('Error setting product data'));
		}

		await saveTaskValues(data);

		return Promise.resolve();
	},
	saveStub: async (data: TaskApiActionData): Promise<void> => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
};
