import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	GenericIdRequest,
	CountryRequest,
	CountriesResponse,
	RegionTypesResponse,
	RegionsResponse,
	RegionResponse,
	CountryResponse,
	AffectedRowsResponse,
	RegionRequest,
	RegionTypeRequest,
	RegionTypeResponse,
	AddressesByZipResponse,
} from 'common';

export interface NestedRegion extends RegionResponse {
	subRegions?: NestedRegion[];
}

export interface GeographyState {
	countries: CountriesResponse;
	regions: NestedRegion[];
	regionTypes: RegionTypesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: GeographyState = {
	countries: [],
	regions: [],
	regionTypes: [],
	op_status: 'idle',
};

// #region Countries
export const doListCountries = api.apiList<CountriesResponse>(
	'country/list',
	'geography/countries',
);
export const doCreateCountry = api.apiCreate<CountryRequest, CountryResponse>(
	'country/create',
	'geography/country',
);
export const doEditCountry = api.apiEdit<CountryResponse, AffectedRowsResponse>(
	'country/edit',
	'geography/country',
);
export const doDeleteCountry = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'country/delete',
	'geography/country',
);
// #endregion

// #region Regions
export const doListRegions = api.apiList<RegionsResponse>('region/list', 'geography/regions');
export const doCreateRegion = api.apiCreate<RegionRequest, RegionResponse>(
	'region/create',
	'geography/region',
);
export const doEditRegion = api.apiEdit<RegionResponse, AffectedRowsResponse>(
	'region/edit',
	'geography/region',
);
export const doDeleteRegion = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'region/edit',
	'geography/region',
);
// #endregion

// #region Region Types
export const doListRegionTypes = api.apiList<RegionTypesResponse>(
	'regiontype/list',
	'geography/regiontypes',
);
export const doCreateRegionType = api.apiCreate<RegionTypeRequest, RegionTypeResponse>(
	'regiontype/create',
	'geography/regiontype',
);
export const doEditRegionType = api.apiEdit<RegionTypeResponse, AffectedRowsResponse>(
	'regiontype/edit',
	'geography/regiontype',
);
export const doDeleteRegionType = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'regiontype/edit',
	'geography/regiontype',
);
// #endregion

// #region Search Address by Zip Code
export const doSearchByZipCode = api.apiListById<GenericIdRequest, AddressesByZipResponse>(
	'zipcode/list',
	'geography/zipcode/:id',
);
// #endregion

export const GeographySlice = createSlice({
	name: 'geography',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(doListCountries.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListCountries.fulfilled, (state, action) => {
			state.countries = action.payload as CountriesResponse;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListCountries.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateCountry.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateCountry.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateCountry.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditCountry.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditCountry.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditCountry.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeleteCountry.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteCountry.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteCountry.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doListRegions.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListRegions.fulfilled, (state, action) => {
			function buildNestedRegions(
				allRegions: RegionsResponse,
				parentId?: number | null,
			): NestedRegion[] {
				return allRegions
					.filter((region) => region.parentId === parentId)
					.map((region) => ({
						...region,
						subRegions: buildNestedRegions(allRegions, region.id),
					}));
			}

			const newRegions = buildNestedRegions(action.payload, null);
			state.regions = newRegions;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListRegions.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doListRegionTypes.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListRegionTypes.fulfilled, (state, action) => {
			state.regionTypes = action.payload as RegionTypesResponse;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListRegionTypes.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doSearchByZipCode.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doSearchByZipCode.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doSearchByZipCode.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export default GeographySlice.reducer;
