import { WarehouseChip } from '@components/WarehouseChip';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { ArrowRight } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';

interface Props {
	locationId: number | null | undefined;
}

export const LocationRow = ({ locationId }: Props) => {
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();

	const warehouse = warehouseList.find((w) => w.id === locationId);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
			}}
		>
			{warehouse && <WarehouseChip data={warehouse} />}
			<ArrowRight />
			<Chip
				label={getTranslatedString(AppFunction.Location, locationId ?? -1, TranslationTypes.name)}
				size='small'
			/>
		</div>
	);
};
