import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { CloseOrderValue } from '../../config';
import { Box, List } from '@mui/material';
import useTasks from '@hooks/useTasks';
import { TaskFieldType } from 'common';
import { CloseOrderFieldValues } from '@contexts/taskContext/config/closeOrderFieldConfig';
import { OutboundCloseOrderFieldItem } from './OutboundCloseOrderFieldItem';
import useProducts from '@hooks/useProducts';
import { PickingFieldValue } from '@contexts/taskContext/config/pickingFieldConfig';
import { ContainerComponentProps } from '@components/taskworkflow/tasks/config';
import { MoveFromToBanner } from '@components/common/banners/MoveFromToBanner';

export const OutboundCloseOrderFieldContainer: React.FC<ContainerComponentProps> = ({
	taskFieldId,
}: ContainerComponentProps) => {
	const { targetId, setTaskFieldValues, getParentProduct } = useTask();
	const { orderList } = useOrders();
	const { variantList } = useProducts();
	const { getTaskInChainByOrderIdFieldType } = useTasks();
	const [values, setValues] = useState<{ [key: number]: CloseOrderValue }>([]);
	const order = orderList.find((order) => order.id === targetId);

	useEffect(() => {
		if (!order) {
			return;
		}
		const culValues = getTaskInChainByOrderIdFieldType(targetId, TaskFieldType.picking);
		// console.log(culValues);
		const newValues: { [key: number]: CloseOrderValue } = {};
		order.content
			?.filter((c) => c.variantId !== undefined && c.variantId !== null)
			.forEach((content, it) => {
				const variant = variantList.find((v) => v.id === content.variantId);
				const tempValues: PickingFieldValue = culValues?.fieldValues?.[0]?.value?.[it];

				if (!variant) {
					throw new Error('variant not found');
				}

				const isVariable = variant.variable;

				let oldExpiresAt: Moment | null = null;
				let oldOrderProductId: number | null = null;
				if (order.parentId !== null && order.parentId !== undefined) {
					const parentOrder = orderList.find((o) => o.id === order.parentId);

					if (parentOrder && order.orderType) {
						const parentProduct = getParentProduct?.(
							taskFieldId,
							order.orderType,
							parentOrder,
							content,
						);
						oldExpiresAt = parentProduct?.expiresAt
							? moment(parentProduct.expiresAt).startOf('day')
							: null;
						oldOrderProductId = parentProduct?.id ? +parentProduct.id : null;
					}
				}

				const weight = isVariable
					? tempValues?.pickedWeight ?? content.pickedWeight ?? 0
					: content.weight ?? 0;
				const arrivedQty = isVariable
					? tempValues?.pickedQty ?? content.arrivedQty ?? 0
					: content.arrivedQty ?? content.orderQty ?? 0;
				// console.log(arrivedQty);
				const value = {
					expiresAt: content.expiresAt ? moment(content.expiresAt) : oldExpiresAt,
					arrivedQty,
					sourceId: oldOrderProductId,
					variantId: content.variantId ?? -1,
					weight,
					estimated: content.estimated ?? false,
				};
				newValues[content.variantId ?? -1] = {
					oldValues: value,
					newValues: [value],
				};
			});
		setValues(newValues);
		setValuesInContext(newValues);
	}, [targetId, order]);

	/**
	 * Converts values from local state to context state
	 * @param {{[key: number]: CloseOrderValue}} values
	 */
	const setValuesInContext = (values: { [key: number]: CloseOrderValue }) => {
		const newValues: CloseOrderFieldValues = Object.entries(values).map(([key, value]) => ({
			orderProductId: parseInt(key),
			variantId: value.oldValues.variantId,
			warehouseId: order?.toId ?? -1,
			oldValues: {
				...value.oldValues,
				expiresAt: value.oldValues.expiresAt ? value.oldValues.expiresAt.toDate() : null,
				actualExpiration: value.oldValues.actualExpiration
					? value.oldValues.actualExpiration.toDate()
					: null,
			},
			newValues: value.newValues.map((v) => ({
				...v,
				expiresAt: v.expiresAt ? v.expiresAt.toDate() : null,
				actualExpiration: v.actualExpiration ? v.actualExpiration.toDate() : null,
			})),
		}));
		setTaskFieldValues(taskFieldId, newValues);
	};

	return (
		<div style={{ padding: '1rem' }}>
			{order && (
				<Box>
					<MoveFromToBanner order={order} />
				</Box>
			)}
			<List>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content, it) => {
						if (values[content.variantId ?? -1]) {
							return (
								<OutboundCloseOrderFieldItem
									key={it}
									content={content}
									value={values[content.variantId ?? -1]}
									iterator={it}
								/>
							);
						}
					})}
			</List>
		</div>
	);
};
