import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography } from '@mui/material';
import { CustomerInvoicingTimesResponse } from 'common';
import { InvoicingTime } from './InvoicingTime';
import { AnimatedIconButton } from '../common/AnimatedIconButton';

import { Add } from '@mui/icons-material';

interface Props {
	times: CustomerInvoicingTimesResponse;
	customerId: number;
	view?: boolean;
}

export const InvoicingTimes = ({ times, customerId, view }: Props) => {
	const { t } = useTranslation();

	const canEdit = view ? !view : true;
	const [isCreating, setCreating] = useState(false);
	const [invoicingTimes, setInvoicingTimes] = useState(times);

	useEffect(() => {
		setInvoicingTimes((oldTimes) => {
			if (!_.isEqual(oldTimes, times)) {
				return times;
			}
			return oldTimes;
		});
	}, [times]);

	return (
		<Grid container spacing={2} item xs={12}>
			{invoicingTimes.map((t, it) => (
				<Grid item xs={12} key={`t${it}`}>
					<InvoicingTime time={t} view={view} />
				</Grid>
			))}
			{isCreating ? (
				<InvoicingTime
					time={{ id: -1, customerId: customerId, closeDate: 31, monthDelay: 1, invoicingDate: 31 }}
					onCloseCreate={() => setCreating(false)}
				/>
			) : canEdit ? (
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
					<AnimatedIconButton
						text='operations.create'
						translate
						colorType='info'
						icon={<Add />}
						onClick={() => setCreating(true)}
					/>
				</Grid>
			) : (
				<></>
			)}
		</Grid>
	);
};
