import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	translate?: boolean;
	size?: 'small' | 'medium' | 'large';
	solid?: boolean;
	shadow?: boolean;
}

export const TabInnerTitle = ({ title, translate, size, solid, shadow }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	let textSize = '1rem';
	switch (size) {
		case 'small':
			textSize = '0.7rem';
			break;
		case 'medium':
			textSize = '1rem';
			break;
		case 'large':
			textSize = '1.5rem';
			break;
	}

	return (
		<Typography
			variant='overline'
			display='block'
			gutterBottom
			sx={{
				background: solid
					? theme.palette.gradient.secondary
					: theme.palette.gradient.secondaryAlpha,
				display: 'flex',
				justifyContent: 'center',
				fontSize: textSize,
				marginTop: '1rem',
				marginBottom: '1rem',
				borderRadius: '2rem',
				boxShadow: shadow ? '1px 1px 14px -2px rgba(0,0,0,0.45)' : 'null',
				userSelect: 'none',
			}}
		>
			<span>{translate ? t(title) : title}</span>
		</Typography>
	);
};
