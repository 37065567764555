import useCustomers from '@hooks/useCustomers';
import useGeography from '@hooks/useGeography';
import { TableView } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
	AppFunction,
	CustomerRequest,
	CustomersCreateSchema,
	InvoicingMethod,
	LocaleEntryAction,
	TranslationTypes,
} from 'common';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

interface Props {
	appFunction: AppFunction;
}

export const CsvImporter = ({ appFunction }: Props) => {
	const { t } = useTranslation();
	const { parseAddress } = useGeography();
	const { bulkCreateCustomers } = useCustomers();

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files[0]) {
			Papa.parse(files[0], {
				header: true,
				skipEmptyLines: true,
				encoding: 'Shift_JIS',
				complete: async (result) => {
					const customers: CustomerRequest[] = [];
					for (const entry of result.data) {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						const anyfied = entry as any;
						const address = await parseAddress(anyfied['取引先住所１']);
						const noChildrenRegion = address.regions?.find(
							(region) => region.region.subRegions?.length === 0,
						);
						const newCustomer: CustomerRequest = {
							customerCode: anyfied.得意先ｺｰﾄﾞ,
							companyCode: anyfied.法人番号,
							qbbrNumber: anyfied.適格請求書発行事業者の登録番号,
							prebanking: true,
							invoicingMethod: InvoicingMethod.none,
							separateData: true,
							strings: [
								{
									type: TranslationTypes.name,
									value: anyfied.得意先名,
									langCode: 'jp',
									action: LocaleEntryAction.CREATE,
								},
							],
							addresses: [],
						};

						if (address) {
							newCustomer.addresses?.push({
								// TODO: I had to put -1 here, check if it gets translated correctly or not
								id: -1,
								addressableId: -1,
								type: AppFunction.Customer,
								shortCut: 'main',
								countryId: 1,
								regionId: noChildrenRegion?.region.id,
								zip: anyfied.取引先郵便番号,
								address: address.address,
								number: anyfied.取引先住所２,
								building: anyfied.取引先住所３,
							});
						}

						customers.push(newCustomer);
					}
					// console.log(customers as CustomerRequest[]);
					const parsedCustomers = CustomersCreateSchema.safeParse(customers);
					if (parsedCustomers.success) {
						console.log(parsedCustomers.data);
						await bulkCreateCustomers(parsedCustomers.data);
					} else {
						console.log(parsedCustomers.error);
					}
				},
			});
		}
	};

	const handleButtonClick = () => {
		document.getElementById('hiddenFileInput')?.click();
	};

	return (
		<Tooltip
			title={t('operations.importItemType', {
				item: t(`appBar.${appFunction}`),
				type: 'csv',
			})}
			placement='top'
			arrow
		>
			<div>
				<input
					type='file'
					id='hiddenFileInput'
					style={{ display: 'none' }}
					onChange={handleFileChange}
					accept='.csv'
				/>
				<IconButton
					color='info'
					onClick={() => handleButtonClick()}
					sx={{
						backgroundColor: 'white',
						transform: 'scale(1)',
						transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
						'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
					}}
				>
					<TableView />
				</IconButton>
			</div>
		</Tooltip>
	);
};
