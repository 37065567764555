import useCarriers from '../../hooks/useCarriers';
import useLocale from '../../hooks/useLocale';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { AppFunction, GenericIdRequest, TranslationTypes } from 'common';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Settings } from '@mui/icons-material';
import { TableContextProvider } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { DropDownActionType } from '@contexts/tableContext/types';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { useTranslation } from 'react-i18next';

export const CarrierList = () => {
	const { carrierList, deleteCarrier } = useCarriers();
	const { renderI18NField, getI18NString } = useLocale();
	const { getIcon } = useAppFunctions();
	const { setBreadCrumbs } = useLayout();
	const { pushDialog } = useFeedbacks();
	const { t } = useTranslation();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.carriers',
				icon: getIcon(AppFunction.Carrier) ?? <></>,
			},
		]);
	}, []);

	function callDeleteCarrierDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteCarrier(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			pageIdentifier={AppFunction.Carrier}
			values={carrierList}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: callDeleteCarrierDialog,
				},
			]}
		>
			<Column
				field='name'
				header='name'
				body={(data) =>
					renderI18NField(getI18NString(AppFunction.Carrier, data.id, TranslationTypes.name))
				}
			/>
		</TableContextProvider>
	);
};
