import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';
import {
	AffectedRowsResponse,
	ChainArchetypeRequest,
	ChainArchetypeResponse,
	GenericIdRequest,
	OrderBaseResponse,
	TaskArchetypeRequest,
	TaskArchetypeResponse,
	TaskFieldArchetypeCreateSchema,
	TaskFieldArchetypeRequest,
	TaskFieldArchetypeResponse,
	TaskFieldArchetypeSchema,
	TaskFieldParameterCreateSchema,
	TaskFieldParameterRequest,
	TaskFieldParameterResponse,
	TaskFieldParameterSchema,
	TaskFieldSetArchetypeRequest,
	TaskFieldSetArchetypeUpdateRequest,
	TaskFieldType,
	TaskResponse,
	TaskStatus,
	TriggerRequest,
	TriggerResponse,
} from 'common';
import {
	doAssignTask,
	doCreateTaskArchetype,
	doCreateTaskChainArchetype,
	doCreateTaskFieldArchetype,
	doCreateTaskFieldParameter,
	doCreateTaskFieldSetArchetype,
	doCreateTrigger,
	doDeleteTaskArchetype,
	doDeleteTaskChainArchetype,
	doDeleteTaskFieldArchetype,
	doDeleteTaskFieldParameter,
	doDeleteTaskFieldSetArchetype,
	doEditTaskArchetype,
	doEditTaskChainArchetype,
	doEditTaskFieldArchetype,
	doEditTaskFieldParameter,
	doEditTaskFieldSetArchetype,
	doFetchMostSpecificTrigger,
	doUnassignTask,
} from '../store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import { useAuth } from './auth';

function useTasks() {
	const dispatch = useAppDispatch();
	const auth = useAuth();
	const triggerSlice = useAppSelector((state) => state.tasks.triggers);
	const taskArchetypesSlice = useAppSelector((state) => state.tasks.taskArchetypes);
	const taskFieldArchetypesSlice = useAppSelector((state) => state.tasks.taskFieldArchetypes);
	const taskChainArchetypesSlice = useAppSelector((state) => state.tasks.chainArchetypes);
	const taskFieldSetsSlice = useAppSelector((state) => state.tasks.sets);
	const tasksSlice = useAppSelector((state) => state.tasks.tasks);
	const chainsSlice = useAppSelector((state) => state.tasks.activeChains);
	const ordersSlice = useAppSelector((state) => state.orders.orders);

	const [triggerList, setTriggerList] = useState(triggerSlice);
	const [taskArchetypesList, setTaskArchetypesList] = useState(taskArchetypesSlice);
	const [taskFieldArchetypeList, setTaskFieldArchetypeList] = useState(taskFieldArchetypesSlice);
	const [chainArchetypesList, setChainArchetypesList] = useState(taskChainArchetypesSlice);
	const [taskFieldSetsList, setTaskFieldSetsList] = useState(taskFieldSetsSlice);
	const [taskList, setTaskList] = useState(tasksSlice);

	useEffect(() => {
		setTriggerList((currentList) => {
			if (!_.isEqual(currentList, triggerSlice)) {
				return triggerSlice;
			}
			return currentList;
		});
	}, [triggerSlice]);

	useEffect(() => {
		setTaskArchetypesList((currentList) => {
			if (!_.isEqual(currentList, taskArchetypesSlice)) {
				return taskArchetypesSlice;
			}
			return currentList;
		});
	}, [taskArchetypesSlice]);

	useEffect(() => {
		setTaskFieldArchetypeList((currentList) => {
			if (!_.isEqual(currentList, taskFieldArchetypesSlice)) {
				return taskFieldArchetypesSlice;
			}
			return currentList;
		});
	}, [taskFieldArchetypesSlice]);

	useEffect(() => {
		setChainArchetypesList((currentList) => {
			if (!_.isEqual(currentList, taskChainArchetypesSlice)) {
				return taskChainArchetypesSlice;
			}
			return currentList;
		});
	}, [taskChainArchetypesSlice]);

	useEffect(() => {
		setTaskFieldSetsList((currentList) => {
			if (!_.isEqual(currentList, taskFieldSetsSlice)) {
				return taskFieldSetsSlice;
			}
			return currentList;
		});
	}, [taskFieldSetsSlice]);

	useEffect(() => {
		setTaskList((currentList) => {
			if (!_.isEqual(currentList, tasksSlice)) {
				return tasksSlice;
			}
			return currentList;
		});
	}, [tasksSlice]);

	const createTrigger = async (data: TriggerRequest): Promise<TriggerResponse> => {
		const response = (await dispatch(doCreateTrigger(data))) as PayloadAction<TriggerResponse>;
		return response.payload;
	};

	const getMostSpecificTrigger = async (data: TriggerResponse): Promise<TriggerResponse | null> => {
		const response = (await dispatch(
			doFetchMostSpecificTrigger(data),
		)) as PayloadAction<TriggerResponse>;
		if (response.type === 'trigger/mostSpecific/fulfilled') {
			return response.payload;
		}
		return null;
	};

	const createTaskChainArchetype = async (data: ChainArchetypeRequest) => {
		return await dispatch(doCreateTaskChainArchetype(data));
	};

	const editTaskChainArchetype = async (data: ChainArchetypeResponse) => {
		return await dispatch(doEditTaskChainArchetype(data));
	};

	const deleteTaskChainArchetype = async (id: number): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doDeleteTaskChainArchetype({ id: id }),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const createTaskArchetype = async (
		data: TaskArchetypeRequest,
	): Promise<TaskArchetypeResponse> => {
		const response = (await dispatch(
			doCreateTaskArchetype(data),
		)) as PayloadAction<TaskArchetypeResponse>;
		return response.payload;
	};

	const editTaskArchetype = async (data: TaskArchetypeResponse): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doEditTaskArchetype(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const deleteTaskArchetype = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doDeleteTaskArchetype(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const createTaskFieldArchetype = async (
		data: TaskFieldArchetypeResponse | TaskFieldArchetypeRequest,
	): Promise<TaskFieldArchetypeResponse> => {
		const parsedData = TaskFieldArchetypeCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid task field archetype data');
		} else {
			const response = (await dispatch(
				doCreateTaskFieldArchetype(parsedData.data),
			)) as PayloadAction<TaskFieldArchetypeResponse>;
			if (response.type === 'taskFieldArchetypes/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('tasks.createFieldArchetype.rejected');
		}
	};

	const editTaskFieldArchetype = async (
		data: TaskFieldArchetypeResponse,
	): Promise<AffectedRowsResponse> => {
		const parsedData = TaskFieldArchetypeSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid task field archetype data');
		}
		const response = (await dispatch(
			doEditTaskFieldArchetype(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'taskFieldArchetypes/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('tasks.editFieldArchetype.rejected');
	};

	const deleteTaskFieldArchetype = async (
		data: GenericIdRequest,
	): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doDeleteTaskFieldArchetype(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const createTaskFieldSetArchetype = async (data: TaskFieldSetArchetypeRequest) => {
		return await dispatch(doCreateTaskFieldSetArchetype(data));
	};

	const editTaskFieldSetArchetype = async (data: TaskFieldSetArchetypeUpdateRequest) => {
		return await dispatch(doEditTaskFieldSetArchetype(data));
	};

	const deleteTaskFieldSetArchetype = async (id: number): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doDeleteTaskFieldSetArchetype({ id: id }),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const assignTask = async (taskId: number, userId?: number): Promise<TaskResponse> => {
		if (!auth.user?.id || !auth.user.roles) return Promise.reject('tasks.assign.rejected');
		if (!userId) userId = auth.user.id;

		const response = (await dispatch(
			doAssignTask({
				id: taskId,
				assignedTo: userId,
				assignedToType: 'user',
			}),
		)) as PayloadAction<TaskResponse>;
		if (response.type === 'tasks/assign/fulfilled') {
			return response.payload;
		}
		return Promise.reject('tasks.assign.rejected');
	};

	const unassignTask = async (taskId: number): Promise<TaskResponse> => {
		const response = (await dispatch(
			doUnassignTask({
				id: taskId,
			}),
		)) as PayloadAction<TaskResponse>;
		if (response.type === 'tasks/unassign/fulfilled') {
			return response.payload;
		}
		return Promise.reject('tasks.unassign.rejected');
	};

	const canAssignTaskTo = (task: TaskResponse, userId?: number): boolean => {
		if (!auth.user?.id || !auth.user.roles) return false;
		if (!userId) userId = auth.user.id;

		return (
			task.status === TaskStatus.pending &&
			(auth.user.id === userId ||
				auth.user.roles.includes('admin') ||
				auth.user.roles.includes('superadmin'))
		);
	};

	const canUnassign = (task: TaskResponse): boolean => {
		if (!auth.user?.id || !auth.user.roles) return false;
		return (
			(task.status === TaskStatus.inprogress || task.status === TaskStatus.stub) &&
			(auth.user.id === task.assignedTo ||
				auth.user.roles.includes('admin') ||
				auth.user.roles.includes('superadmin'))
		);
	};

	const isInTheFuture = (date: Date | undefined | null) => {
		if (!date) return false;
		return new Date(date).getTime() > new Date().getTime();
	};

	const getOrderFromTask = (task: TaskResponse): OrderBaseResponse | null => {
		const chain = chainsSlice.find((chain) => chain.id === task.chainId);
		if (!chain) return null;
		const order = ordersSlice.find((order) => order.id === chain.targetId);
		if (!order) return null;
		return order;
	};

	const getTaskInChainByOrderIdFieldType = (
		orderId: number,
		fieldType: TaskFieldType,
		ignoreBlocked?: boolean,
	): TaskResponse | null => {
		const chain = chainsSlice.find((chain) => chain.targetId === orderId);
		if (!chain) return null;
		let task: TaskResponse | null = null;
		taskList.some((t) => {
			if (ignoreBlocked && t.status === TaskStatus.blocked) return false;
			if (t.chainId === chain.id) {
				// TODO: there should be a better way to get the archetype (it is inside the task)
				const archetype = taskArchetypesList.find((a) => a.id === t.archetypeId);
				// console.log(t);
				if (archetype) {
					// console.log(t.archetypeId);
					// console.log(archetype.taskFieldArchetypeId);
					const field = taskFieldArchetypeList.find(
						(f) => archetype.taskFieldArchetypeId.includes(+f.id) && f.type === fieldType,
					);
					if (field) {
						task = t;
						return true;
					}
				}
			}
			return false;
		});

		return task;
	};

	return {
		triggerList,
		createTrigger,
		getMostSpecificTrigger,
		createTaskChainArchetype,
		editTaskChainArchetype,
		deleteTaskChainArchetype,
		taskArchetypesList,
		createTaskArchetype,
		editTaskArchetype,
		deleteTaskArchetype,
		taskFieldArchetypeList,
		createTaskFieldArchetype,
		editTaskFieldArchetype,
		deleteTaskFieldArchetype,
		chainArchetypesList,
		taskFieldSetsList,
		createTaskFieldSetArchetype,
		editTaskFieldSetArchetype,
		deleteTaskFieldSetArchetype,
		taskList,
		assignTask,
		unassignTask,
		canAssignTaskTo,
		canUnassign,
		isInTheFuture,
		getOrderFromTask,
		getTaskInChainByOrderIdFieldType,
	};
}

export default useTasks;
