import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { Box, Grid } from '@mui/material';
import { CarrierResponse, StorageConditionType } from 'common';
import { DeliveryPlan } from './DeliveryPlan';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { Add } from '@mui/icons-material';
import { useDeliveryPlans } from '@contexts/deliveryPlansContext/DeliveryPlansContext';
import { useTranslation } from 'react-i18next';

export const DeliveryPlans = () => {
	const { entityId } = useGenericForm<CarrierResponse>();
	const { t } = useTranslation();
	const { creating, editing, plans, setCreating } = useDeliveryPlans();

	return (
		<Grid container rowGap={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='carrier.plans' translate size='small' />
			</Grid>
			<Grid item xs={12}>
				{plans?.map((plan, it) => (
					<DeliveryPlan key={it} plan={plan} />
				))}
				{creating ? (
					<DeliveryPlan
						plan={{ id: -1, carrierId: entityId, planStorageType: StorageConditionType.ambient }}
					/>
				) : (
					<Box
						sx={{
							marginTop: '1rem',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<AnimatedIconButton
							text={t('operations.create')}
							icon={<Add />}
							onClick={() => setCreating(true)}
							disabled={!!editing}
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};
