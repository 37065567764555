import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import { Delete } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import { AppFunction, IngredientVariant, TranslationTypes } from 'common';
import { RecipeUnitWeight } from './RecipeUnitWeight';
import { useTranslation } from 'react-i18next';

interface Props {
	values: IngredientVariant[] | null | undefined;
	index: number;
	isDivisionIngredient?: boolean;
	onChange: (values: IngredientVariant[]) => void;
}

export const RecipeIngredient = ({ values, index, isDivisionIngredient, onChange }: Props) => {
	const { variantList } = useProducts();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	if (!values) return null;

	const value = variantList.find((variant) => variant.id === values[index].variantId);

	return (
		<Grid
			item
			xs={12}
			container
			sx={{
				border: '1px solid',
				borderColor: 'grey.300',
				borderRadius: 1,
				padding: 1,
			}}
		>
			<Grid item xs={11} container rowSpacing={2}>
				<Grid item xs={12}>
					<Autocomplete
						id='test-autocomplete'
						size='small'
						options={variantList}
						value={value}
						disabled={isDivisionIngredient}
						getOptionLabel={(option) => {
							if (typeof option === 'object' && option !== null) {
								const productName = getTranslatedString(
									AppFunction.Variant,
									option.id,
									TranslationTypes.name,
								);
								return `[${option.sku}] - ${productName}`;
							} else {
								return option;
							}
						}}
						isOptionEqualToValue={(option, value) => {
							return option?.id === value?.id;
						}}
						renderOption={(props, option, { selected }) => {
							let productName = 'none';
							if (option.id !== -1) {
								productName = getTranslatedString(
									AppFunction.Variant,
									option.id,
									TranslationTypes.name,
								);
							}
							const label = `${selected ? '> ' : ''}[${option.sku}] - ${productName}`;
							return (
								<MenuItem {...props} key={option.id}>
									{label}
								</MenuItem>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`${AppFunction.Product}.variant`)}
								variant='outlined'
								inputProps={{
									...params.inputProps,
								}}
							/>
						)}
						onChange={(_, newValue) => {
							const newValues = [...values];
							newValues[index].variantId = newValue?.id || -1;
							onChange(newValues);
						}}
					/>
				</Grid>
				{!isDivisionIngredient && (
					<>
						<RecipeUnitWeight
							value={values[index].unitWeight}
							prefix='unit'
							simpleChoice
							onChange={(value) => {
								const newValues = [...values];
								newValues[index].unitWeight = value ?? 0;
								onChange(newValues);
							}}
						/>
						<RecipeUnitWeight
							value={values[index].usedUnit}
							freeWeight
							optionLabels={['useAll', 'usePart']}
							onChange={(value) => {
								const newValues = [...values];
								newValues[index].usedUnit = value ?? 0;
								onChange(newValues);
							}}
						/>
					</>
				)}
			</Grid>
			<Grid
				item
				xs={1}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<IconButton
					color='error'
					onClick={() => {
						const newValue = values.filter((_, it) => it !== index);
						onChange(newValue);
					}}
				>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};
