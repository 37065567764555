import { SxProps, Theme } from '@mui/material';

export const config: SxProps<Theme> = {
	'&& .MuiFormControl-root': {
		pointerEvents: 'none !important',
	},
	'&& .MuiInputLabel-root .MuiFormLabel-root .Mui-focused': {
		pointerEvents: 'none !important',
	},
	'&& .MuiInputBase-root': {
		color: 'black',
		pointerEvents: 'none !important',
		userSelect: 'none',
		borderColor: 'transparent',
		// opacity: 0.5,
		backgroundColor: 'background.paper',
	},
	'&& .MuiInputAdornment-root': {
		color: 'red !important',
	},
	'&& .MuiSelect-select': {
		pointerEvents: 'none',
	},
	'&& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent !important',
		pointerEvents: 'none',
	},
	'&& .MuiInput-underline:before': {
		borderBottom: 'none !important',
	},
};
