import { useLayout } from '@contexts/layoutContext/LayoutContext';
import { AccessAlarm } from '@mui/icons-material';
import { useEffect } from 'react';

export const ScheduledEventsList = () => {
	const { setBreadCrumbs } = useLayout();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.scheduledEvent',
				icon: <AccessAlarm />,
			},
		]);
	}, []);

	return <>yep</>;
};
