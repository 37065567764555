import { PresetInputLangGroup } from '@components/PresetInputLangGroup';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { Grid } from '@mui/material';
import { TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';

export const InfoTab = () => {
	const { control } = useGenericForm();

	return (
		<Grid
			container
			sx={{
				margin: '2rem 0 2rem 0',
				padding: '0 2rem 2rem 2rem',
				borderRadius: '2rem',
				backgroundColor: 'white',
			}}
		>
			<Grid item xs={12}>
				<TabInnerTitle title='product.description' translate size='small' />
				<Controller
					name='strings'
					control={control}
					render={({ field }) => (
						<PresetInputLangGroup
							useReactHookForm
							type={TranslationTypes.description}
							strings={field.value ?? []}
							onChange={field.onChange}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
};
