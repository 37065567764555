import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';

import processLocaleEntries from '../../helpers/processLocaleEntries';

import {
	CustomerBillingCreateSchema,
	CustomerBillingResponse,
	CustomerBillingSchema,
	LocaleEntries,
	TranslationTypes,
} from 'common';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	TextField,
	Typography,
} from '@mui/material';

import { EditShowGridField } from '../common/EditShowGridField';
import { PresetInputLangGroup } from '../PresetInputLangGroup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useCustomers from '../../hooks/useCustomers';
import useFeedbacks from '../../hooks/useFeedbacks';
import { ContextualDropdownMenu } from '../ContextualDropdownMenu';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { Cancel, Check } from '@mui/icons-material';
import { DeleteItemDialog } from '../DeleteItemDialog';

export interface CustomerBillingProps {
	isEditing: boolean;
	index: number;
	data: CustomerBillingResponse;
	onStartEdit: (id: number) => void;
	expandedId?: number | null;
	onExpand?: (id: number | null) => void;
	view?: boolean;
}

export const CustomerBilling = (props: CustomerBillingProps) => {
	const { t } = useTranslation();
	const { createCustomerBilling, editCustomerBilling, deleteCustomerBilling } = useCustomers();
	const { showSnackBar } = useFeedbacks();
	const canEdit = props.view ? !props.view : true;

	const [isEditing, setEditing] = useState((canEdit && props.data.id === -1) ?? false);
	const [expanded, setExpanded] = useState((canEdit && props.data.id === -1) ?? false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [cachedData, setCachedData] = useState(props.data);

	const { control, handleSubmit, reset } = useForm<CustomerBillingResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: props.data,
		resolver: zodResolver(CustomerBillingSchema),
	});

	useEffect(() => {
		if (!_.isEqual(props.data, cachedData)) {
			setCachedData(props.data);
			reset(props.data);
		}
	}, [props.data]);

	useEffect(() => {
		if (props.expandedId !== props.data.id) {
			setExpanded(false);
		}
	}, [props.expandedId]);

	const onStartEdit = () => {
		setEditing(true);
		setExpanded(true);
		if (cachedData.id) {
			props.onExpand?.(cachedData.id);
		}
		// pushFocusedElement({ type: FocusedElementType.person, id: person.id, zIndex: 200 });
	};

	const onCloseEdit = () => {
		setEditing(false);
		reset(cachedData);
	};

	const onChangeExpanded = (e: SyntheticEvent, expanded: boolean) => {
		if (props.data.id !== -1) {
			setExpanded(expanded);
			if (!expanded) {
				setEditing(false);
				props.onExpand?.(null);
				// removeFocusedElement(person.id, FocusedElementType.person);
			} else {
				props.onExpand?.(props.data.id ?? null);
			}
		}
		if (!expanded && isEditing) {
			props.onStartEdit(-1);
		}
		setDeleteDialogOpen(false);
	};

	const onSubmit = useCallback(
		async (data: CustomerBillingResponse) => {
			if (props.data.id === -1) {
				const createData = CustomerBillingCreateSchema.safeParse(data);
				console.log(createData);
				if (createData.success) {
					await createCustomerBilling(createData.data);
					showSnackBar('Customer account created.');
				}
			} else if (cachedData) {
				const newStrings = processLocaleEntries(data.strings ?? [], cachedData.strings ?? []);
				const editRequest: CustomerBillingResponse = { ...data, strings: newStrings };
				await editCustomerBilling(editRequest);
				setEditing(false);
				showSnackBar('Customer Account edited.');
			}
		},
		[props.data.id],
	);

	const renderDialog = () => {
		// leaving show extended dialog here
		// we use it when you are about to delete a person which is in use somewhere
		const onDeletePerson = async (showExtended: boolean) => {
			await deleteCustomerBilling({ id: cachedData.id ?? -1 });
			showSnackBar('deleted');
		};

		// Translate
		const text = 'delete billing';

		return (
			<DeleteItemDialog
				title='delete billing'
				text={text}
				dialogOpen={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
				onAccept={() => onDeletePerson(false)}
			/>
		);
	};

	return (
		<div style={{ position: 'relative', padding: '0px' }}>
			{renderDialog()}
			<Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
				<Grid item xs={!canEdit ? 12 : isEditing ? 12 : 11}>
					<Accordion key={props.index} onChange={onChangeExpanded} expanded={expanded}>
						<AccordionSummary
							expandIcon={cachedData.id !== -1 ? <ExpandMoreIcon /> : <></>}
							aria-controls={`panel${props.index}-content`}
							id={`panel${props.index}-header`}
						>
							{canEdit && isEditing ? (
								<>{t('operations.editBilling')}</>
							) : (
								<>{cachedData.accountName}</>
							)}
						</AccordionSummary>
						<AccordionDetails sx={{ borderRadius: '15px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography
										variant='overline'
										display='block'
										gutterBottom
										sx={{ fontSize: '0.9em' }}
									>
										<span style={{ backgroundColor: 'white' }}>{t('customer.billing')}</span>
									</Typography>
								</Grid>
								<Controller
									name='accountName'
									control={control}
									render={({ field }) => (
										<EditShowGridField
											width={12}
											isEditing={canEdit && isEditing}
											noTranslation
											label
										>
											<TextField
												variant='outlined'
												multiline
												id='accountName'
												label={t('customer.accountName')}
												{...field}
											/>
										</EditShowGridField>
									)}
								/>
								{/* <Controller
									name='bankName'
									control={control}
									render={({ field }) => (
										<EditShowGridField width={6} isEditing={isEditing} noTranslation label>
											<TextField
												variant='outlined'
												multiline
												id='bankName'
												label={t('customer.bankName')}
												{...field}
											/>
										</EditShowGridField>
									)}
								/> */}
								<Grid item xs={12}>
									<Controller
										name='strings'
										control={control}
										render={({ field }) => (
											<PresetInputLangGroup
												useReactHookForm
												showOnlyCurrent
												type={TranslationTypes.description}
												strings={field.value ?? []}
												onChange={(data: LocaleEntries) => {
													field.onChange(data);
												}}
												disableEdit={!canEdit || !isEditing}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{canEdit && isEditing && (
					<>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.save'
								translate
								colorType='success'
								icon={<Check />}
								onClick={handleSubmit(onSubmit)}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								translate
								colorType='error'
								icon={<Cancel />}
								onClick={onCloseEdit}
							/>
						</Grid>
					</>
				)}
				{canEdit && !isEditing && (
					<Grid
						item
						xs={1}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<ContextualDropdownMenu
							onEdit={onStartEdit}
							onDelete={() => setDeleteDialogOpen(true)}
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
};
