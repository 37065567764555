import { FadeWrapper } from '@components/common/FadeWrapper';
import { useOrderModify } from '@contexts/orderModifyContext/OrderModifyContext';
import useLocale from '@hooks/useLocale';
import useOrders from '@hooks/useOrders';
import useProducts from '@hooks/useProducts';
import { Box, Button, Container, Stack, TextField, useTheme } from '@mui/material';
import {
	AppFunction,
	OrderBaseChildRequest,
	OrderBaseResponse,
	OrderProductsUpdateRequest,
	TranslationTypes,
} from 'common';
import { Controller } from 'react-hook-form';
import { orderConfigMap } from './config/orderTypeConfig';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useFeedbacks from '@hooks/useFeedbacks';
import { useNavigate } from 'react-router-dom';

export const OrderModifier = () => {
	const theme = useTheme();
	const { cachedData, control, formAction, handleSubmit } = useOrderModify();
	const navigate = useNavigate();
	const { modifyOrderContent } = useOrders();
	const { showSnackBar } = useFeedbacks();
	const { t } = useTranslation();

	if (!cachedData || !cachedData.orderType) {
		return null;
	}

	const config = orderConfigMap[cachedData.orderType];

	if (!config || config.modifySubOrderProductsContainer === undefined) {
		return null;
	}

	const editOrder = async (data: OrderBaseResponse) => {
		const requestData: OrderProductsUpdateRequest = {
			entries: data.children?.map((child) => child.content ?? []).flat() ?? [],
		};
		modifyOrderContent(requestData)
			.then(() => {
				showSnackBar({
					message: t('operations.success', {
						item: t(`appFunctions.${AppFunction.Order}`),
						action: t('operations.create'),
					}),
					severity: 'success',
				});
				navigate(`/orders/${cachedData.id}`);
			})
			.catch((error) => {
				console.log('Error editing order', error);
				showSnackBar({
					message: t('operations.failure', {
						item: t(`appFunctions.${AppFunction.Order}`),
						action: t('operations.create'),
					}),
					severity: 'error',
				});
			});
	};

	const orderContent = (
		<Container
			maxWidth={false}
			sx={{
				// position: 'relative',
				// maxWidth: renderType === 'standalone' ? 'calc(100% - 2rem)' : '100%',
				// margin: renderType === 'standalone' ? '0 1rem 1rem 1rem !important' : '',
				// padding: renderType === 'standalone' ? '0 !important' : '2rem 0 0 0 !important',
				// borderRadius: renderType === 'standalone' ? '0.5rem' : '',
				// minHeight: renderType === 'popup' ? '100%' : 'auto',
				// background: renderType === 'standalone' ? theme.palette.gradient.primary : 'none',
				position: 'relative',
				maxWidth: 'calc(100% - 2rem)',
				margin: '0 1rem 1rem 1rem !important',
				padding: '0 !important',
				borderRadius: '0.5rem',
				minHeight: 'auto',
				background: theme.palette.gradient.primary,
			}}
		>
			<Box
				sx={{
					height: 'auto',
					padding: '1rem 1rem 0.5rem 1rem',
				}}
			>
				header
			</Box>
			<Box
				sx={{
					padding: '0.5rem 1rem 0.5rem 1rem',
				}}
			>
				<Controller
					control={control}
					name='children'
					render={({ field }) => (
						<Stack
							direction='column'
							gap={1}
							sx={{
								backgroundColor: theme.palette.background.paper,
								borderRadius: '0.5rem',
								padding: '1rem',
							}}
						>
							{field.value?.map((child: OrderBaseChildRequest, childIndex) => {
								if (config.modifySubOrderProductsContainer === undefined) {
									return null;
								}
								return (
									<config.modifySubOrderProductsContainer
										subOrder={child}
										key={childIndex}
										field={field}
									/>
								);
							})}
						</Stack>
					)}
				/>
			</Box>
			{formAction === 'edit' && (
				<Box
					sx={{
						padding: '0.5rem 1rem 1rem 1rem',
					}}
				>
					<Button variant='contained' onClick={handleSubmit(editOrder)} fullWidth>
						{t('operations.editItem', { item: t(`appFunctions.${AppFunction.Order}`) })}
					</Button>
				</Box>
			)}
		</Container>
	);

	return (
		<FadeWrapper fadeTime={500}>
			{/* {renderType === 'popup' ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
						background: theme.palette.gradient.primary,
					}}
				>
					<Stack
						direction='row'
						sx={{
							height: '100%',
							width: '100%',
						}}
					>
						{orderContent}
					</Stack>
				</Box>
			) : ( */}
			<Stack direction='row'>{orderContent}</Stack>
			{/* )} */}
		</FadeWrapper>
	);
};
