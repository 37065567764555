import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging';
import { createContext, useEffect, useState } from 'react';
import { appendNotification } from './app';
import { useAppDispatch, useAppSelector } from './hooks';
import { setUserMeta } from './user';

interface IFirebaseData {
	firebaseApp: FirebaseApp | null;
	firebaseMessaging: Messaging | null;
	token: string | null;
}

export const defaultFirebaseState: IFirebaseData = {
	firebaseApp: null,
	firebaseMessaging: null,
	token: null,
};

export const initFirebase = async (onMessageCallback: (payload: string) => void) => {
	// eslint-disable-next-line no-debugger
	// const firebaseConfig = {
	// 	apiKey: 'AIzaSyDZ6NgO8sUfl5Xzg1bN1fzI4UPkpAnUFD8',
	// 	authDomain: 'fiordimaso-mess.firebaseapp.com',
	// 	projectId: 'fiordimaso-mess',
	// 	storageBucket: 'fiordimaso-mess.appspot.com',
	// 	messagingSenderId: '1045805004024',
	// 	appId: '1:1045805004024:web:529daa75c94e0378a1f70d',
	// 	measurementId: 'G-J3XXVTTYT0',
	// };
	const firebaseConfig = {
		apiKey: 'AIzaSyDb8b89b5XQ0q5P9kyrQGxwVETmnbQ9tg8',
		authDomain: 'fji-test.firebaseapp.com',
		projectId: 'fji-test',
		storageBucket: 'fji-test.appspot.com',
		messagingSenderId: '728750998048',
		appId: '1:728750998048:web:1dcb6796a3ae0ce0c5b245',
	};

	if ('serviceWorker' in navigator && 'addEventListener' in navigator.serviceWorker) {
		// Initialize Firebase
		// eslint-disable-next-line no-debugger
		const firebaseApp = initializeApp(firebaseConfig);
		const firebaseMessaging = getMessaging(firebaseApp);

		const requestForToken = () => {
			return getToken(firebaseMessaging, {
				vapidKey:
					// 'BFvH35CoPg3gjGMaXQkR9PXhPDfuwkjRFAEL7vNTY5cdJ8pF91AJCbEBkEwrZ64jfpJuUZk_hpNTkdV29TAuiSs',
					'BHlhal1z5ifzYGDMs8nloiZlNbdiMYhnz6XyPKHZl4febuEHkKOPX03BeAQgd3Ha9HJPEiw17mCSjFElXw4Io3w',
			})
				.then((currentToken) => {
					if (currentToken) {
						return currentToken;
					} else {
						// Show permission request UI
						console.log('No registration token available. Request permission to generate one.');
					}
				})
				.catch((err) => {
					console.log('An error occurred while retrieving token. ', err);
				});
		};

		const token = await requestForToken();

		onMessage(firebaseMessaging, (payload) => {
			console.log('Message received. ', payload);
			// ...
			onMessageCallback(JSON.stringify(payload));
		});
		return { firebaseApp, firebaseMessaging, token };
	}
	return { firebaseApp: null, firebaseMessaging: null, token: null };
};

export const useFirebase = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);
	const [contextFirebaseApp, setContextFirebaseApp] = useState(defaultFirebaseState.firebaseApp);
	const [contextFirebaseMessaging, setContextFirebaseMessaging] = useState(
		defaultFirebaseState.firebaseMessaging,
	);
	const [contextToken, setContextToken] = useState(defaultFirebaseState.token);

	useEffect(() => {
		if (!user) return;
		async function doit() {
			const { firebaseApp, firebaseMessaging, token } = await initFirebase((payload: string) => {
				dispatch(appendNotification(payload));
			});

			setContextFirebaseApp(firebaseApp);
			setContextFirebaseMessaging(firebaseMessaging);
			setContextToken(token as string);
			if (user && user.userData) {
				dispatch(
					setUserMeta({
						user_id: user.userData?.id?.toString(),
						payload: { fcm_token: token as string },
					}),
				);
			}
			console.log('registered on firebase with token: ' + token);
		}

		doit();
	}, []);
};

const FirebaseContext = createContext(defaultFirebaseState);
export default FirebaseContext;
