import {
	OrderBaseResponse,
	OrderProductResponse,
	OrderStatus,
	OrderType,
	RecipeType,
	TaskFieldType,
	TaskResponse,
	TaskStatus,
} from 'common';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useTasks from '../../hooks/useTasks';
import { getTaskFieldConfig } from './taskFieldConfig';
import { useAppDispatch } from '../../store/hooks';
import useOrders from '../../hooks/useOrders';
import { doAssignTask, doCloseTask, doSetTaskToStub } from '../../store/tasks';
import { useAuth } from '../../hooks/auth';
import { warnNoProvider, warnNoProviderAsync, warnNoProviderGeneric } from '@contexts/shared';
import _ from 'lodash';
import useProducts from '@hooks/useProducts';

interface TaskContextType {
	targetId: number;
	task: TaskResponse | null;
	cachedValues: { [key: number]: unknown[] } | null;
	fieldValues: { [key: number]: unknown[] };
	taskState: TaskStatus;
	canClose: boolean;
	orderChain: OrderChainElement[];
	additionalData: { [key: number]: { [key: string]: unknown } };
	getParentProduct?: (
		fieldId: number,
		orderType: OrderType,
		parentOrder: OrderBaseResponse,
		content: OrderProductResponse,
	) => OrderProductResponse | undefined;
	setTaskFieldValues: (fieldId: number, values: unknown[]) => void;
	getTaskFieldValues: <T>(fieldId: number) => T[];
	closeTask: () => Promise<void>;
	acceptTask: () => void;
	saveTaskStub: () => void;
}

const defaultContext: TaskContextType = {
	targetId: -1,
	task: null,
	cachedValues: null,
	fieldValues: {},
	taskState: TaskStatus.pending,
	canClose: false,
	orderChain: [],
	additionalData: {},
	setTaskFieldValues: warnNoProvider('setTaskFieldValues'),
	getTaskFieldValues: warnNoProviderGeneric('getTaskFieldValues'),
	closeTask: warnNoProviderAsync('closeTask'),
	acceptTask: warnNoProvider('acceptTask'),
	saveTaskStub: warnNoProvider('saveTaskStub'),
};

interface TaskContextProps {
	task: TaskResponse | null;
	children: React.ReactNode;
	targetId?: number;
}

type FieldStatus = {
	[key: number]: { valid: boolean; optional: boolean };
};

interface OrderChainElement {
	orderId: number;
	orderType: OrderType | undefined;
}

function getOrderChain(
	orderId: number | undefined,
	orderList: OrderBaseResponse[],
): OrderChainElement[] {
	const order = orderList.find((order) => order.id === orderId);
	if (!order) return [];
	const chain = [{ orderId: order.id, orderType: order.orderType }];
	let currentOrder = order;
	while (currentOrder.parentId) {
		const parent = orderList.find((order) => order.id === currentOrder.parentId);
		if (!parent) break;
		chain.unshift({ orderId: parent.id, orderType: parent.orderType });
		currentOrder = parent;
	}
	return chain;
}

const TaskContext = createContext<TaskContextType>(defaultContext);

export const TaskProvider: React.FC<TaskContextProps> = ({
	children,
	task,
	targetId,
}: TaskContextProps) => {
	const dispatch = useAppDispatch();
	const auth = useAuth();
	const { taskFieldArchetypeList } = useTasks();
	const { variantList } = useProducts();
	const { orderList } = useOrders();

	/**
	 * The cachedValues state is used to store the original values of the fields
	 * They can be used to compare the current values with the original values
	 * or to reset the values to the original ones.
	 */
	const [cachedValues, setCachedValues] = useState<{ [key: number]: unknown[] }>({});
	/**
	 * The fieldValues state is used to store the current values of the fields
	 * They are used to update the task field values in this context.
	 */
	const [fieldValues, setFieldValues] = useState<{ [key: number]: unknown[] }>({});
	/**
	 * The additionalData state is used to store additional data for the fields that need it.
	 * It is used in the performApiAction function in the taskFieldConfig.
	 * Most task fields won't need this, but some might. (e.g. CloseOrderField)
	 */
	const [additionalData, setAdditionalData] = useState<{
		[key: number]: { [key: string]: unknown };
	}>({});

	/**
	 * Status of the fields. We are using this to determine if the user can close the task.
	 * If all the non optional fields are valid, the user can close the task.
	 */
	const [fieldsStatus, setFieldsStatus] = useState<FieldStatus>(() => {
		const initialFieldsStatus =
			task?.archetype?.taskFieldArchetypeId.reduce((acc, id) => {
				const optional =
					taskFieldArchetypeList.find((archetype) => archetype.id === id)?.optional ?? false;
				return {
					...acc,
					[id]: { valid: false, optional: optional },
				};
			}, {}) ?? {};
		return initialFieldsStatus;
	});
	const [canClose, setCanClose] = useState(false);
	const taskState = task?.status ?? TaskStatus.pending;
	const orderChain = useMemo(() => getOrderChain(targetId, orderList), [targetId, orderList]);

	// // TODO: test! remove after testing
	// useEffect(() => {
	// 	console.log(fieldValues);
	// }, [fieldValues]);

	/**
	 * Updates the canClose state based on the fieldsStatus and the task
	 * if all the non optional field statuses are valid, canClose will be true
	 * //TODO: Could this be eliminated? Maybe we can do it with useMemo
	 */
	useEffect(() => {
		if (!task) return;
		const valid =
			task?.archetype?.taskFieldArchetypeId.reduce((acc, id) => {
				const fieldStatus = fieldsStatus[id];
				const fieldIsValid = fieldStatus.optional || fieldStatus.valid;
				return acc && fieldIsValid;
			}, true) ?? false;

		let canProceed = true;
		const order = orderList.find((order) => order.id === targetId);
		if (order) {
			const subOrders = orderList.filter((order) => order.groupId === targetId);
			canProceed = subOrders?.every((order) => order.status === OrderStatus.Done);
		}
		// we need the values to be valid AND possible suborders to be completed in order to proceed
		// and let the user close the task
		setCanClose(valid && canProceed);
	}, [fieldsStatus, task, orderList]);

	/**
	 * Initializes the field values based on the task we got from the props
	 * - We are using the fieldValues from the task to set the initial state
	 * @param {TaskResponse} task
	 * @returns {void}
	 * @sideEffects Sets the fieldValues state
	 * @sideEffects Sets the cachedValues state
	 */
	useEffect(() => {
		if (!task) return;
		const initialFieldValues: Record<number, unknown[]> = {};
		task.fieldValues?.map((field) => {
			initialFieldValues[field.taskFieldArchetypeId] = field.value;
		});
		if (!_.isEqual(initialFieldValues, {})) {
			setFieldValues(initialFieldValues);
			setCachedValues(initialFieldValues);
		}
	}, [task]);

	// TODO: temp additional data stuff. document.
	useEffect(() => {
		if (!task) return;
		const order = orderList.find((order) => order.id === targetId);
		if (!order) return;
		const tempAdditionalData: { [key: string]: unknown } = {};
		task.archetype?.taskFieldArchetypeId.forEach((id) => {
			const taskFieldArchetpe = taskFieldArchetypeList.find((archetype) => archetype.id === id);
			if (!taskFieldArchetpe) return;
			// TODO: document! we are using the order type to determine the recipe type only when closing a processing order
			// additional data might be needed in other cases too
			switch (taskFieldArchetpe.type) {
				case TaskFieldType.closeorder: {
					if (order.orderType === OrderType.Processing) {
						const variantId = order.content?.[0].variantId;
						if (!variantId) return;
						const variant = variantList.find((variant) => variant.id === variantId);
						if (!variant) return;
						tempAdditionalData[id] = {
							recipeType: variant.recipe?.recipeType,
							orderId: order.id,
							orderSubType: order.orderSubType,
							warehouseId: order.fromId,
							sourceOrderProductId: order.content?.[0].id ? +order.content?.[0].id : null,
						};
					}
					if (order.orderType === OrderType.Internal) {
						tempAdditionalData[id] = {
							orderId: order.id,
							orderSubType: order.orderSubType,
							warehouseId: order.fromId,
						};
					}
					break;
				}
				default:
					// do nothing
					break;
			}
		});
		setAdditionalData((oldData) => ({ ...oldData, ...tempAdditionalData }));
	}, [task, orderList, variantList, taskFieldArchetypeList]);

	/**
	 * Validates the values we got from the field input and sets the state accordingly
	 * - We are using the validateStructure function from
	 * the config that we got through {@link getTaskFieldConfig}
	 * @param {number} fieldId
	 * @param {unknown[]} values
	 * @returns {void}
	 */
	const setTaskFieldValues = (fieldId: number, values: unknown[]): void => {
		if (!task) return;
		const config = getTaskFieldConfig(taskFieldArchetypeList, fieldId);
		if (!config) {
			console.log('no config found for field', fieldId);
			return;
		}
		const order = orderList.find((order) => order.id === targetId);
		const valid = config.validateStructure?.(values, order?.orderType) ?? false;
		setFieldValues((oldValues) => ({ ...oldValues, [fieldId]: values }));
		setFieldsStatus((oldStatus) => ({
			...oldStatus,
			[fieldId]: {
				...oldStatus[fieldId],
				valid: valid,
			},
		}));
	};

	function getTaskFieldValues<T>(fieldId: number): T[] {
		if (!fieldValues[fieldId]) return [];
		const result = fieldValues[fieldId] as T[];
		if (!result) {
			console.error('No values found for field', fieldId);
			return [];
		}
		return result;
	}

	/**
	 * Fetches the task field config for the current field and calls the getParentProduct function
	 * - We are using the getParentProduct function from the config that we got through {@link getTaskFieldConfig}
	 * - Depending on the order type, the parent order and the content we're trying to find the parent of could be different
	 * that's why we are using this solution to find the correct parent product.
	 * @param {number} fieldId - The field id of the field we're currently working with
	 * @param {OrderType} orderType - The order type of the current order
	 * @param {OrderBaseResponse} parentOrder - The immediate parent order of the current order
	 * @param {OrderProductResponse} content - The order product content we're trying to find the parent of
	 * @returns {OrderProductResponse | undefined} - The parent order product if found, otherwise undefined
	 */
	function getParentProduct(
		fieldId: number,
		orderType: OrderType,
		parentOrder: OrderBaseResponse,
		content: OrderProductResponse,
	): OrderProductResponse | undefined {
		if (!task) return;
		const config = getTaskFieldConfig(taskFieldArchetypeList, fieldId);
		if (!config) {
			return undefined;
		}
		return config.getParentProduct?.(orderType, parentOrder, content);
	}

	const saveTaskStub = async () => {
		if (!task) return;
		try {
			const apiCallPromises = Object.entries(fieldValues).map(async ([key, value]) => {
				const config = getTaskFieldConfig(taskFieldArchetypeList, Number(key));
				const order = orderList.find((order) => order.id === targetId);

				if (!order || !order.orderType) return Promise.reject(new Error('No order found'));

				if (config && config.saveStub) {
					await config.saveStub({
						taskId: task.id,
						orderType: order.orderType,
						fieldId: Number(key),
						values: value,
						dispatch: dispatch,
					});
				} else {
					console.log('no, I dont want no stubs');
				}
			});
			await Promise.all(apiCallPromises);
			dispatch(doSetTaskToStub({ id: task.id }));
		} catch (error) {
			console.error('An API call failed:', error);
		}
	};

	const closeTask = async (): Promise<void> => {
		if (!task) return;
		try {
			const apiCallPromises = Object.entries(fieldValues).map(async ([key, value]) => {
				const config = getTaskFieldConfig(taskFieldArchetypeList, Number(key));
				const order = orderList.find((order) => order.id === targetId);

				if (!order || !order.orderType) return Promise.reject(new Error('No order found'));

				if (config && config.executeApiAction) {
					// console.log(
					// 	'Performing API action for field',
					// 	key,
					// 	'with value',
					// 	value,
					// 	'for task',
					// 	task.id,
					// );
					await config.executeApiAction({
						taskId: task.id,
						orderType: order.orderType,
						fieldId: Number(key),
						values: value,
						dispatch: dispatch,
						orderProducts: order.content,
						additionalData: additionalData[Number(key)],
					});
				}
			});

			await Promise.all(apiCallPromises);
			console.log('All API calls succeeded. Closing task', task.id);
			await dispatch(doCloseTask({ id: task.id }));
			return Promise.resolve();
		} catch (error) {
			console.error('An API call failed:', error);
			return Promise.reject(error);
		}
	};

	const acceptTask = () => {
		if (!task) return;
		console.log('Accepting task', task.id);
		if (!auth.user) {
			console.error('No user found');
			return;
		}
		// TODO: can we get rid of the type here?
		dispatch(doAssignTask({ id: task.id, assignedTo: auth.user.id, assignedToType: 'user' }));
	};

	const contextValue = {
		targetId: targetId ?? -1,
		task,
		cachedValues,
		fieldValues,
		taskState,
		canClose,
		orderChain,
		additionalData,
		getParentProduct,
		setTaskFieldValues,
		getTaskFieldValues,
		closeTask,
		acceptTask,
		saveTaskStub,
	};

	return <TaskContext.Provider value={contextValue}>{children}</TaskContext.Provider>;
};

export const useTask = () => useContext(TaskContext);
