import { useState } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';

interface AdvancedDatePickerProps
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extends Omit<DatePickerProps<any, any>, 'open' | 'onOpen' | 'onClose'> {
	isPopup?: boolean;
	initialOpen?: boolean;
}

const AdvancedDatePicker = ({
	isPopup,
	initialOpen = false,
	...originalProps
}: AdvancedDatePickerProps): JSX.Element => {
	const [dateOpen, setDateOpen] = useState(initialOpen);

	return (
		<>
			{dateOpen && isPopup && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						zIndex: 9998,
						backgroundColor: 'transparent',
					}}
					onClick={() => setDateOpen(false)}
				/>
			)}
			<DatePicker
				{...originalProps}
				open={dateOpen}
				onOpen={() => setDateOpen(true)}
				onClose={() => setDateOpen(false)}
				PopperProps={{
					sx: { zIndex: 9999 },
				}}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							size='small'
							fullWidth
							{...originalProps.renderInput?.(params).props}
							sx={{
								backgroundColor: 'white',
								...originalProps.renderInput?.(params).props.sx,
							}}
						/>
					);
				}}
			/>
		</>
	);
};

AdvancedDatePicker.displayName = 'AdvancedDatePicker';
export default AdvancedDatePicker;
