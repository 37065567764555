import { useOrderEditor } from '@contexts/index';
import { OrderTimeScheduler } from '../common/OrderTimeScheduler';
import useCustomers from '@hooks/useCustomers';
import useGeography from '@hooks/useGeography';
import useCarriers from '@hooks/useCarriers';
import {
	AppFunction,
	CarrierDeliveryAreasResponse,
	CarrierResponse,
	CarriersResponse,
	InvoicingMethod,
	OrderBaseResponse,
	RegionResponse,
	TranslationTypes,
} from 'common';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import useLocale from '@hooks/useLocale';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAddresses from '@hooks/useAddresses';

export const OutboundOrderTimeScheduler = () => {
	const { loading, currentChainArchetype, control, getValues } = useOrderEditor();
	const { customerList } = useCustomers();
	const { getRegionPath } = useGeography();
	const { renderAddress } = useAddresses();
	const { deliveryAreaList, deliveryTimeList } = useCarriers();
	const { carrierList } = useCarriers();
	const { t } = useTranslation();

	const theme = useTheme();
	const { getTranslatedString } = useLocale();
	const [cachedCustomerId, setCachedCustomerId] = useState<number | null>(null);
	const [customerRegion, setCustomerRegion] = useState<RegionResponse | null>(null);
	const [availableCarriers, setAvailableCarriers] = useState<CarriersResponse | null>(null);

	const [deliveryAddressId, setDeliveryAddressId] = useState<number | null>(null);

	useEffect(() => {
		const newId = getValues('toId');
		if (!newId || newId === cachedCustomerId) {
			return;
		}
		setCachedCustomerId(newId);
		setCustomerRegion(null);
		setAvailableCarriers(null);

		const customer = customerList.find((c) => c.id === newId);
		if (!customer) {
			return;
		}

		const deliveryAddress = customer.addresses?.find((a) => a.id === customer.deliveryAddressId);
		if (deliveryAddress) {
			setDeliveryAddressId(deliveryAddress.id);
		}
		const areas: CarrierDeliveryAreasResponse = [];
		if (!deliveryAddress) {
			return;
		}
		const path = getRegionPath(deliveryAddress.regionId);
		if (path.length > 0) {
			setCustomerRegion(path[0]);
			const tempAreas = deliveryAreaList.filter((a) => a.regions?.find((r) => r.id === path[0].id));
			areas.push(...tempAreas);
		}

		const carriers = new Set<CarrierResponse>();
		areas.forEach((a) => {
			const carrier = carrierList.find((c) => c.id === a.carrierId);
			if (carrier) {
				carriers.add(carrier);
			}
		});
		setAvailableCarriers(Array.from(carriers));
	}, [getValues('toId')]);

	const injection = useMemo(() => {
		if (!availableCarriers || availableCarriers.length === 0) {
			return;
		}

		const carrierId = getValues('carrierId');
		const carrier = availableCarriers.find((c) => c.id === carrierId);
		const plans = carrier?.carrierPlans ?? [];

		const deliveryTimes = deliveryTimeList.filter((dt) => dt.carrierId === carrierId);
		return (
			<>
				<Grid item flexGrow={1} flexBasis={0}>
					<Controller
						name='carrierId'
						control={control}
						render={({ field }) => (
							<FormControl fullWidth>
								<InputLabel id='ship-by'>test</InputLabel>
								<Select
									labelId='ship-by'
									id='ship-by'
									size='small'
									label='test'
									value={field.value ?? -1}
									disabled={false}
									onChange={(event) => {
										field.onChange(event.target.value);
									}}
									sx={{
										backgroundColor: 'white',
									}}
								>
									<MenuItem key='na' value={-1}>
										N/A
									</MenuItem>
									{availableCarriers?.map((value, it) => (
										<MenuItem key={it} value={value.id}>
											{getTranslatedString(AppFunction.Carrier, value.id, TranslationTypes.name)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
				</Grid>
				<Grid item flexGrow={1} flexBasis={0}>
					{/* <Controller
						name='carrierPlanId'
						control={control}
						render={({ field }) => (

						)}
					/> */}
					<FormControl fullWidth>
						<InputLabel id='ship-by'>test</InputLabel>
						<Select
							labelId='ship-by'
							id='ship-by'
							size='small'
							label='test'
							value={-1}
							disabled={false}
							onChange={(event) => {
								console.log(event.target.value);
							}}
							sx={{
								backgroundColor: 'white',
							}}
						>
							<MenuItem key='na' value={-1}>
								N/A
							</MenuItem>
							{plans?.map((value, it) => (
								<MenuItem key={it} value={value.id}>
									{getTranslatedString(AppFunction.CarrierPlan, value.id, TranslationTypes.name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item flexGrow={1} flexBasis={0}>
					<Controller
						name='ead'
						control={control}
						render={({ field }) => (
							<DatePicker
								label={t('order.estimatedTime')}
								value={field.value ? moment(field.value) : null}
								disablePast
								onChange={(newValue) => newValue && field.onChange(newValue.toDate())}
								renderInput={(params) => (
									<TextField
										{...params}
										size='small'
										variant='outlined'
										fullWidth
										sx={{ backgroundColor: 'white' }}
									/>
								)}
								PopperProps={{ style: { zIndex: 9999 } }}
							/>
						)}
					/>
				</Grid>
				{deliveryTimes && (
					<Grid item flexGrow={1} flexBasis={0}>
						<Controller
							name='deliveryTimeId'
							control={control}
							render={({ field }) => (
								<FormControl fullWidth>
									<InputLabel id='time-label'>test</InputLabel>
									<Select
										labelId='time-label'
										id='shipping-time'
										size='small'
										label='test'
										value={field.value ?? -1}
										disabled={false}
										onChange={(event) => {
											field.onChange(+event.target.value);
										}}
										sx={{
											backgroundColor: 'white',
										}}
									>
										<MenuItem key='na' value={-1}>
											N/A
										</MenuItem>
										{deliveryTimes
											?.sort((a, b) => a.startTime.localeCompare(b.startTime))
											.map((value, it) => (
												<MenuItem key={it} value={value.id}>
													{value.startTime} - {value.endTime}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
				)}
			</>
		);
	}, [availableCarriers, getValues('carrierId')]);

	return (
		<>
			{customerRegion && (
				<Grid
					container
					columnSpacing={2}
					sx={{
						width: '100%',
						background: theme.palette.gradient.primaryAlpha,
						border: '1px solid ' + theme.palette.primary.main,
						borderRadius: '1rem',
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						padding: '1rem',
						marginLeft: '1rem',
					}}
				>
					<Grid item>{deliveryAddressId && renderAddress(deliveryAddressId, false)}</Grid>
				</Grid>
			)}
			<OrderTimeScheduler
				loading={loading}
				currentChainArchetype={currentChainArchetype}
				injection={injection}
				control={control}
				hideEad
			/>
		</>
	);
};
