import { TabbedElement } from '@components/tabs/TabbedElement';
import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { AppFunction, PersonJobTitleResponse, PersonJobTitleSchema } from 'common';
import { Settings } from '@mui/icons-material';
import { FormAction } from '@contexts/formContext/types';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import usePeople from '@hooks/usePeople';
import { GeneralInfo } from '@components/forms/people/jobtitles/GeneralInfo';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

const JobTitleFormContent = withFormLogic<PersonJobTitleResponse>(() => <></>);

const JobTitleForm = ({ forcedId, formAction }: Props) => {
	const { jobTitlesList, createJobTitle, editJobTitle } = usePeople();

	return (
		<JobTitleFormContent
			forcedId={forcedId}
			formAction={formAction}
			list={jobTitlesList}
			validationSchema={PersonJobTitleSchema}
			createFunction={createJobTitle}
			editFunction={editJobTitle}
			defaultValues={{ id: -1 }}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			hideMenu
			sideFabs={[FormSidefabType.Info, FormSidefabType.Notes]}
			appFunction={AppFunction.JobTitle}
			customAfterCreateLink='jobtitle/:id/edit'
		>
			{() => (
				<TabbedElement title='merchant.generalInfo'>
					<GeneralInfo />
				</TabbedElement>
			)}
		</JobTitleFormContent>
	);
};

export default JobTitleForm;
