import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Chip, ChipProps, SxProps, Theme, Tooltip } from '@mui/material';
import { CalendarMonth, Edit, ExitToApp } from '@mui/icons-material';

import { CombinedIcon } from '../CombinedIcon';
import { useTranslation } from 'react-i18next';
import { AppFunction } from 'common';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';

/**
 * @description The properties of the expiration date chip
 * @property {Date | null | undefined} [expiresAt] - The expiration date of the product
 * @property {Date | null | undefined} [actualExpiration] - The actual expiration date of the product (if it was modified)
 */
interface ExpirationProps {
	ead?: string | Date | null;
	sx?: SxProps<Theme>;
}

/**
 * @description A chip component that displays the expiration date and changes color based on it
 *
 * The color changes based on the expiration date:
 * - **Red**: If the expiration date is in the past
 * - **Orange**: If the expiration date is in the next 2 days
 * - **Blue**: If the expiration date is in the next 7 days
 * - **Green**: If the expiration date is in more than 7 days
 *
 * @param {ExpirationProps} props - Accepts {@link ExpirationProps} or classes that extend it
 * @returns {JSX.Element | null} - Returns a Chip component with the expiration date
 */
const ArrivesAtChip = <T extends ExpirationProps>({ ead, sx }: T): JSX.Element | null => {
	const { timeZone, dateFormat } = useLocationTimeZone();

	if (!ead) return null;

	return (
		<Chip
			label={moment(ead).tz(timeZone).format(dateFormat)}
			color='primary'
			icon={<CombinedIcon main={<CalendarMonth />} sub={<ExitToApp />} />}
			variant='outlined'
			size='small'
			sx={sx}
		/>
	);
};

ArrivesAtChip.displayName = 'ArrivesAtChip';
export default ArrivesAtChip;
