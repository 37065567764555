import { ReactNode } from 'react';
import { SliderMenu } from '../slidermenu/SliderMenu';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';

interface Props {
	children: ReactNode;
}

export const SliderMenuHandler = ({ children }: Props) => {
	const { sideMenuContent } = useLayout();

	return <SliderMenu menuContent={sideMenuContent}>{children}</SliderMenu>;
};
