import { useTask } from '@contexts/index';
import { TaskSignal, useMultipleTask } from '@contexts/multipleTaskContext/MultipleTaskContext';
import { useAuth } from '@hooks/auth';
import { useEffect, useMemo } from 'react';

export const TaskConnector = () => {
	const auth = useAuth();
	const { task, acceptTask, closeTask, canClose } = useTask();
	const { handleTaskStatus, subscribe, unsubscribe } = useMultipleTask();

	const listener = useMemo(
		() => async (signal: TaskSignal) => {
			console.log(signal);
			switch (signal) {
				case TaskSignal.CLOSE_ALL_TASKS: {
					console.log('close all tasks');
					if (!task) {
						return false;
					}
					if (canClose && task) {
						console.log('closing task for', task.id);
						// TODO: we should await
						closeTask();
						return true;
					} else {
						return false;
					}
				}
				case TaskSignal.ACCEPT_ALL_TASKS: {
					if (!task) {
						return false;
					}
					// TODO: this should be async!!!
					acceptTask();
					return true;
				}
			}
			// return false;
		},
		[auth, task, canClose, closeTask],
	);

	useEffect(() => {
		if (!task) {
			return;
		}
		handleTaskStatus(task?.id ?? 0, canClose);
	}, [task, canClose]);

	useEffect(() => {
		if (!task || !auth.user) {
			return;
		}
		subscribe(task, listener);
		return () => {
			unsubscribe(task.id);
		};
	}, [task, auth.user, canClose, closeTask]);

	return <></>;
};
