import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useFeedbacks, useGenericForm, useLayout } from '@contexts/index';
import { Add, Delete } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	SxProps,
	TextField,
	Theme,
} from '@mui/material';
import { TaskFieldParameterResponse } from 'common';
import { Controller } from 'react-hook-form';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
	DraggableStyle,
	DraggingStyle,
} from '@hello-pangea/dnd';
import { FormRenderType } from '@contexts/formContext/types';

function isDraggingStyle(style: DraggableStyle | undefined): style is DraggingStyle {
	if (!style) {
		return false;
	}
	return style && typeof style.transform === 'string';
}

export const GeneralInfo = () => {
	const { control, renderType } = useGenericForm<TaskFieldParameterResponse>();
	const { visiblePadding } = useLayout();
	const { activeDialogBoundingRect } = useFeedbacks();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='taskFieldParameter.generalInfo' translate size='small' />
			</Grid>
			<Grid item xs={12}>
				<Controller
					name='params'
					control={control}
					render={({ field }) => {
						const params = field.value as string[];

						const onDragEnd = (result: DropResult) => {
							if (!result.destination) {
								return;
							}

							const items = Array.from(params);
							const [reorderedItem] = items.splice(result.source.index, 1);
							items.splice(result.destination.index, 0, reorderedItem);

							field.onChange(items);
						};

						if (params && params.length > 0) {
							// TODO: the whole droppable list could be extracted to a separate component
							return (
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId='droppable'>
										{(provided, snapshot) => {
											return (
												<Grid
													item
													xs={12}
													{...provided.droppableProps}
													ref={provided.innerRef}
													sx={{
														border: snapshot.isDraggingOver
															? '1px dashed #ccc'
															: '1px solid transparent',
														borderRadius: '0.5rem',
														marginBottom: '1rem',
													}}
												>
													<List>
														{params.map((param, index) => (
															<Draggable
																key={index}
																draggableId={`draggable-option-${index}`}
																index={index}
															>
																{(provided, snapshot) => {
																	const style = provided.draggableProps.style;
																	let customSx: SxProps<Theme> = {};
																	// Narrow down the style type with a type assertion
																	const draggableStyle = style as DraggingStyle & {
																		top?: number;
																		left?: number;
																	};

																	if (isDraggingStyle(draggableStyle) || snapshot.isDropAnimating) {
																		if (renderType === FormRenderType.popup) {
																			customSx = {
																				top: `${
																					draggableStyle.top -
																					(activeDialogBoundingRect?.y ?? 0) -
																					(draggableStyle.height ? draggableStyle.height / 2 : 0)
																				}px !important`,
																				left: '1.8rem !important',
																			};
																		} else {
																			customSx = {
																				top: `${
																					draggableStyle.top - visiblePadding.top
																				}px !important`,
																				left: '1.8rem !important',
																			};
																		}
																	}

																	return (
																		<ListItem
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			sx={{
																				...((snapshot.isDragging || snapshot.isDropAnimating) &&
																					customSx),
																			}}
																		>
																			<ListItemAvatar>
																				<Avatar
																					sx={{
																						width: '1.2rem',
																						height: '1.2rem',
																						fontSize: '1rem',
																					}}
																				>
																					{index + 1}
																				</Avatar>
																			</ListItemAvatar>
																			<Grid container gap={1} display='flex'>
																				<Grid item flexGrow={1}>
																					<TextField
																						fullWidth
																						value={param}
																						size='small'
																						onChange={(e) => {
																							params[index] = e.target.value;
																							field.onChange(params);
																						}}
																					/>
																				</Grid>
																				<Grid item display='flex' alignItems='center'>
																					<IconButton
																						onClick={() => {
																							params.splice(index, 1);
																							field.onChange(params);
																						}}
																						size='small'
																						color='error'
																					>
																						<Delete />
																					</IconButton>
																				</Grid>
																			</Grid>
																		</ListItem>
																	);
																}}
															</Draggable>
														))}
														{provided.placeholder}
													</List>
												</Grid>
											);
										}}
									</Droppable>
									<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
										<AnimatedIconButton
											text='operations.create'
											translate
											colorType='primary'
											icon={<Add />}
											onClick={() => {
												field.onChange([...params, '']);
											}}
										/>
									</Box>
								</DragDropContext>
							);
						}
						return (
							<Box display='flex' justifyContent='center' width='100%'>
								<AnimatedIconButton
									text='operations.create'
									translate
									colorType='primary'
									icon={<Add />}
									onClick={() => {
										const existingParams = (field.value as string[]) ?? [];
										field.onChange([...existingParams, '']);
									}}
								/>
							</Box>
						);
					}}
				/>
			</Grid>
		</Grid>
	);
};
