import { AppFunction, OrderProductResponse, OrderStatus } from 'common';
import { CloseOrderFieldAction, CloseOrderValue } from '../../../../config';
import {
	Box,
	Button,
	Chip,
	Grid,
	IconButton,
	TextField,
	ToggleButton,
	useTheme,
} from '@mui/material';
import useImages from '@hooks/useImages';
import useProducts from '@hooks/useProducts';
import { useTranslation } from 'react-i18next';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { Delete, Scale } from '@mui/icons-material';
import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import { useMemo } from 'react';
import { CloseOrderFieldListItem } from '../../../../shared/CloseOrderFieldListItem';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	content: OrderProductResponse;
	value: CloseOrderValue;
	totalWeight: number;
	isWeighted: boolean;
	iterator: number;
	updateField: (
		variantId: number,
		field: CloseOrderFieldAction,
		value: number | null | Moment,
		splitIndex?: number,
	) => void;
	updateTotalWeight: (newQty: number) => void;
	toggleWeightedFlag: () => void;
	toggleLeftovers: () => void;
}

interface SubItemProps
	extends Omit<
		Props,
		'totalWeight' | 'updateTotalWeight' | 'isWeighted' | 'toggleWeightedFlag' | 'toggleLeftovers'
	> {
	handleWeightChange: (newWeight: number, idx: number) => void;
	editDisabled?: boolean;
}

const CutValue = ({
	content,
	value,
	iterator,
	updateField,
	handleWeightChange,
	editDisabled,
}: SubItemProps) => {
	const { taskState } = useTask();
	const { t } = useTranslation();

	const thisValue = value.newValues[iterator];
	const expiration = thisValue.actualExpiration ?? thisValue.expiresAt ?? null;
	const actualQuantity = value.newValues[iterator].arrivedQty ?? content.orderQty;

	return (
		<Box
			sx={{
				border: '1px solid rgba(0, 0, 0, 0.1)',
				borderRadius: '1rem',
				marginBottom: '0.5rem',
				padding: '0.5rem',
			}}
		>
			<Box
				sx={{
					padding: '0.5rem',
				}}
			>
				<Chip
					label={
						thisValue.estimated
							? t(`${AppFunction.Order}.processing.notWeighted`)
							: t(`${AppFunction.Order}.processing.weighted`)
					}
					color={thisValue.estimated ? 'error' : 'success'}
					icon={<Scale />}
					variant='filled'
					size='small'
					sx={{ padding: '0 0.3rem 0 0.3rem' }}
				/>
			</Box>
			<Grid container columnSpacing={2} sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
				<Grid item flexGrow={2} flexBasis={0} />
				<InputGridField flexGrow={1} type={ComponentType.TextField}>
					<TextField
						label={t('product.arrivedQuantity')}
						size='small'
						type='number'
						disabled={taskState !== 'in_progress' || editDisabled}
						InputProps={{
							// inputProps: { min: 0, max: locked ? content.orderQty : undefined },
							inputProps: { min: 0 },
						}}
						fullWidth
						value={actualQuantity}
						onChange={(e) => handleWeightChange(parseInt(e.target.value), iterator)}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
				<InputGridField
					flexGrow={1}
					sx={{
						justifyContent: 'right',
					}}
					type={ComponentType.DatePicker}
				>
					<DatePicker
						value={expiration}
						onChange={(newValue) =>
							updateField(
								content.variantId ?? -1,
								'actualExpiration',
								newValue ? newValue.startOf('day') : moment().startOf('day'),
								iterator,
							)
						}
						disablePast
						disabled={taskState !== 'in_progress' || editDisabled}
						renderInput={(params) => (
							<TextField size='small' fullWidth {...params} sx={{ backgroundColor: 'white' }} />
						)}
						// TODO: we'll have to work with zIndexes later on
						PopperProps={{
							style: { zIndex: 9999 },
						}}
					/>
				</InputGridField>
				<Grid item>
					<Box sx={{ width: '2.6rem', padding: '8px' }} />
				</Grid>
			</Grid>
		</Box>
	);
};

const LeftValue = ({
	content,
	value,
	iterator,
	updateField,
	handleWeightChange,
	editDisabled,
}: SubItemProps) => {
	const { taskState } = useTask();
	const { t } = useTranslation();

	const thisValue = value.newValues[iterator];
	const expiration = thisValue.actualExpiration ?? thisValue.expiresAt ?? null;
	const actualQuantity = value.newValues[iterator].arrivedQty ?? content.orderQty;

	return (
		<Box
			sx={{
				border: '1px solid rgba(0, 0, 0, 0.1)',
				borderRadius: '1rem',
				padding: '0.5rem',
				marginBottom: iterator === 1 ? '0.5rem' : '0',
			}}
		>
			<Box
				sx={{
					padding: '0.5rem',
				}}
			>
				<Chip
					label={
						thisValue.estimated
							? t(`${AppFunction.Order}.processing.notWeighted`)
							: t(`${AppFunction.Order}.processing.weighted`)
					}
					color={thisValue.estimated ? 'error' : 'success'}
					icon={<Scale />}
					variant='filled'
					size='small'
					sx={{ padding: '0 0.3rem 0 0.3rem' }}
				/>
			</Box>
			<Grid container columnSpacing={2} sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
				<Grid item flexGrow={2} flexBasis={0} />
				<InputGridField flexGrow={1} type={ComponentType.TextField}>
					<TextField
						label={t('product.arrivedQuantity')}
						size='small'
						type='number'
						disabled={taskState !== 'in_progress' || editDisabled}
						InputProps={{
							// inputProps: { min: 0, max: locked ? content.orderQty : undefined },
							inputProps: { min: 0 },
						}}
						fullWidth
						value={actualQuantity}
						onChange={(e) => handleWeightChange(parseInt(e.target.value), iterator)}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
				<InputGridField
					flexGrow={1}
					sx={{
						justifyContent: 'right',
					}}
					type={ComponentType.DatePicker}
				>
					<DatePicker
						value={expiration}
						onChange={(newValue) =>
							updateField(
								content.variantId ?? -1,
								'actualExpiration',
								newValue ? newValue.startOf('day') : moment().startOf('day'),
								iterator,
							)
						}
						disablePast
						disabled={taskState !== 'in_progress' || editDisabled || iterator >= 2}
						renderInput={(params) => (
							<TextField size='small' {...params} fullWidth sx={{ backgroundColor: 'white' }} />
						)}
						// TODO: we'll have to work with zIndexes later on
						PopperProps={{
							style: { zIndex: 9999 },
						}}
					/>
				</InputGridField>
				<Grid item>
					{iterator === 2 ? (
						<IconButton
							disabled={taskState !== 'in_progress' || editDisabled}
							onClick={() => updateField(content.variantId ?? -1, 'delete', null, iterator)}
						>
							<Delete />
						</IconButton>
					) : (
						<Box sx={{ width: '2.6rem', padding: '8px' }} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

/**
 * Processing Close Order Field Division Item
 *
 * @param {Props} props
 * @returns {JSX.Element | null}
 */
export const SimpleDivisionItem = ({
	content,
	value,
	totalWeight,
	isWeighted,
	iterator,
	updateField,
	updateTotalWeight,
	toggleWeightedFlag,
	toggleLeftovers,
}: Props): JSX.Element | null => {
	const theme = useTheme();
	const { taskState } = useTask();
	const { variantList } = useProducts();
	const { t } = useTranslation();
	const { orderList } = useOrders();

	/**
	 * Gets the variant based on the content variantId
	 * @returns {Variant | undefined} - The variant
	 */
	const variant = useMemo(() => {
		return variantList.find((v) => v.id === content.variantId);
	}, [content.variantId, variantList]);

	if (!variant || !value.oldValues.expiresAt) {
		return null;
	}

	/**
	 * Checks if the item can be edited.
	 * - If the item is not part of a suborder, it can be edited.
	 * - If the item is part of a suborder and the suborder is done, it can be edited.
	 * @returns {boolean} - The result
	 */
	const canEdit = useMemo(() => {
		const subOrder = orderList.find(
			(order) => order.content?.find((c) => c.id === content.sourceId) !== undefined,
		);
		return !subOrder || subOrder.status === OrderStatus.Done;
	}, [orderList, content.sourceId]);

	/**
	 * Updates the values of the arrived quantity based on the new quantity
	 * cascading through the other values.
	 * @param {number} newQty - The new quantity
	 * @param {number} idx - The index of the value to update
	 */
	const handleWeightChange = (newQty: number, idx: number) => {
		const safeQty = isNaN(newQty) ? 0 : newQty;
		let remainingQty = totalWeight - safeQty;
		const newValues: number[] = [];

		const afterAddition = value.newValues.reduce((acc, val, i) => {
			if (i === idx) {
				return acc + newQty;
			}
			return acc + (val.arrivedQty ?? 0);
		}, 0);

		if (afterAddition > totalWeight && safeQty > totalWeight) {
			return;
		}

		value.newValues.forEach((val, i) => {
			if (i === idx) {
				newValues[i] = safeQty;
				updateField(content.variantId ?? -1, 'arrivedQty', safeQty, i);
			} else {
				const adjustedQty = remainingQty > 0 ? Math.min(val.arrivedQty ?? 0, remainingQty) : 0;
				remainingQty -= adjustedQty;
				newValues[i] = adjustedQty;
				updateField(content.variantId ?? -1, 'arrivedQty', adjustedQty, i);
			}
		});

		if (remainingQty <= 0) {
			return;
		}

		newValues.forEach((val, i) => {
			if (i === idx) {
				return;
			}

			if (newValues.length > 2) {
				switch (i) {
					case 0:
						if (idx === 1) {
							updateField(content.variantId ?? -1, 'arrivedQty', val + remainingQty, i);
						}
						break;
					case 1:
						if ((idx === 0 && newValues[2] === 0) || idx === 2) {
							updateField(content.variantId ?? -1, 'arrivedQty', val + remainingQty, i);
						}
						break;
					case 2:
						if (idx === 0 && newValues[2] !== 0) {
							updateField(content.variantId ?? -1, 'arrivedQty', val + remainingQty, i);
						}
						break;
				}
			} else {
				updateField(content.variantId ?? -1, 'arrivedQty', val + remainingQty, i);
			}
		});
	};

	/**
	 * Updates the total weight of the order
	 * @param {number} newQty
	 */
	const handleUpdateTotalWeight = (newQty: string) => {
		const parsedQty = parseInt(newQty);
		if (isNaN(parsedQty)) {
			return;
		}
		updateTotalWeight(parsedQty);
	};

	return (
		<CloseOrderFieldListItem content={content} iterator={iterator} value={value}>
			<Grid item flexGrow={1} flexBasis={0} container columnGap={1} alignContent='center'>
				<Grid
					item
					xs={12}
					sx={{
						background: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						borderRadius: '0.5rem',
						marginBottom: '0.8rem',
						padding: '0.5rem 1rem 0.5rem 1rem',
						display: 'flex',
						gap: '0.5rem',
						alignItems: 'center',
					}}
				>
					{t(`${AppFunction.Order}.processing.isWeighted`)}
					<ToggleButton
						value='check'
						size='small'
						selected={isWeighted}
						onChange={toggleWeightedFlag}
						sx={{
							padding: '0 0.5rem 0 0.5rem !important',
							backgroundColor: `${
								isWeighted ? theme.palette.success.main : theme.palette.error.main
							} !important`,
							color: `${
								isWeighted ? theme.palette.success.contrastText : theme.palette.error.contrastText
							} !important`,
						}}
					>
						{isWeighted
							? t(`${AppFunction.Order}.processing.weighted`)
							: t(`${AppFunction.Order}.processing.notWeighted`)}
					</ToggleButton>
				</Grid>
				<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
					{/* orderQty is wrong when working with 'pieces', it will always display 1 */}
					{content.orderQty}
					{t(`${AppFunction.Product}.units.pieces`, { count: content.orderQty })}
				</Grid>
				<InputGridField
					flexGrow={1}
					sx={{
						paddingLeft: '2rem',
					}}
					type={ComponentType.TextField}
				>
					<TextField
						label={t(`${AppFunction.Order}.processing.totalWeight`)}
						size='small'
						type='number'
						disabled={taskState !== 'in_progress' || !canEdit}
						InputProps={{
							// inputProps: { min: 0, max: locked ? content.orderQty : undefined },
							inputProps: { min: 0 },
						}}
						fullWidth
						value={totalWeight}
						onChange={(e) => handleUpdateTotalWeight(e.target.value)}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
			</Grid>
			<Grid item>
				{value.newValues &&
					value.newValues.length > 0 &&
					value.newValues.map((_, subIt) => {
						if (subIt === 0) {
							return (
								<CutValue
									key={subIt}
									content={content}
									value={value}
									iterator={subIt}
									updateField={updateField}
									handleWeightChange={handleWeightChange}
									editDisabled={!canEdit}
								/>
							);
						}
						return (
							<LeftValue
								key={subIt}
								content={content}
								value={value}
								iterator={subIt}
								updateField={updateField}
								handleWeightChange={handleWeightChange}
								editDisabled={!canEdit}
							/>
						);
					})}
				{value.newValues.length < 3 && (
					<Box
						sx={{
							marginTop: '0.5rem',
						}}
					>
						<Button variant='contained' onClick={toggleLeftovers} fullWidth>
							{t(`${AppFunction.Order}.processing.addLeftovers`)}
						</Button>
					</Box>
				)}
			</Grid>
		</CloseOrderFieldListItem>
	);
};
