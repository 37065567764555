import { useNavigate } from 'react-router-dom';
import _, { uniqueId } from 'lodash';

import { useLayout } from '@contexts/index';
import { FabData, FabPlacement } from '@contexts/layoutContext/types';
import { Fab, SpeedDial, SpeedDialAction, SpeedDialIcon, keyframes } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useAppSelector } from '@store/hooks';
import { checkWritePermission } from 'permissions';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLayoutObserver from '@hooks/useLayoutObserver';

const popUp = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const shrinkDown = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

interface AnimatedFabData extends FabData {
	animation?: typeof popUp | typeof bounce | typeof shrinkDown;
	index?: number;
	init?: boolean;
}

const animationDelay = 0.1;

export const FabsHandler = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const userRole = useAppSelector((state) => state.roles.userRole);
	const { fabData, registerElement, unregisterElement } = useLayout();
	const [cachedFabs, setCachedFabs] = useState<AnimatedFabData[]>([]);
	const [exitingFabs, setExitingFabs] = useState<AnimatedFabData[]>([]);

	const observe = (_: string, contentRect: DOMRectReadOnly) => {
		registerElement('fab-wrapper', 'bottom', contentRect.height + 32);
	};

	const fabRef = useLayoutObserver<HTMLButtonElement>('fab-wrapper', 'size', observe);

	useEffect(() => {
		if (_.isEqual(fabData, cachedFabs)) return;
		// Calculate indexes for placement
		const placementIndexCounts = { topRight: 0, bottomRight: 0, bottomLeft: 0, topLeft: 0 };

		const updatedFabs = fabData.map((fab) => {
			const animation = cachedFabs.some((cachedFab) => cachedFab.placement === fab.placement)
				? bounce
				: popUp;
			// Assign index based on placement
			const index = placementIndexCounts[fab.placement]++;
			return { ...fab, animation, index };
		});

		const newExitingFabs = cachedFabs
			.filter((cachedFab) => !fabData.some((fab) => fab.placement === cachedFab.placement))
			.map((fab) => ({
				...fab,
				animation: shrinkDown,
				disabled: fab.disabled,
			}));

		setCachedFabs(updatedFabs);
		if (!updatedFabs || updatedFabs.length <= 0) {
			unregisterElement('fab-wrapper');
			fabRef.current = null;
		}
		setExitingFabs((exitingFabs) => [...exitingFabs, ...newExitingFabs]);
	}, [fabData]);

	const handleAnimationEnd = (fabId: string) => {
		// For exiting FABs, remove them once their animation ends.
		setExitingFabs((exitingFabs) => exitingFabs.filter((fab) => fab.id !== fabId));
	};

	const setFabInit = (fabId: string) => {
		setCachedFabs((cachedFabs) =>
			cachedFabs.map((fab) => {
				if (fab.id === fabId) {
					return { ...fab, init: true };
				}
				return fab;
			}),
		);
	};

	const getFabStyle = (placement: FabPlacement, currentIndex: number): React.CSSProperties => {
		const baseStyle: React.CSSProperties = { position: 'fixed' };
		const offset = 16 + 60 * currentIndex;
		switch (placement) {
			case 'topRight':
				return { ...baseStyle, top: 16, right: offset };
			case 'bottomRight':
				return { ...baseStyle, bottom: 16, right: offset };
			case 'bottomLeft':
				return { ...baseStyle, bottom: 16, left: offset };
			case 'topLeft':
				return { ...baseStyle, top: 16, left: offset };
			default:
				return baseStyle;
		}
	};

	return (
		<div>
			{cachedFabs.map((fab) => {
				if (fab.subActions && fab.subActions?.length > 0) {
					const render = fab.subActions.some(
						(subFab) =>
							subFab.permission === undefined || checkWritePermission(userRole, subFab.permission),
					);
					if (!render) return null;
					return (
						<SpeedDial
							key={fab.id}
							color={fab.color ?? 'primary'}
							ariaLabel={fab.id}
							icon={
								fab.icon ?? (
									<SpeedDialIcon
										sx={{
											height: 'auto', // Adjust the height to be auto
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									/>
								)
							}
							direction='up'
							FabProps={{
								size: fab.size ?? 'medium',
								sx: {
									backgroundColor: fab.color ?? '',
									transform: fab.animation === bounce || fab.init ? 'scale(1)' : 'scale(0)',
									animation: `${fab.animation} 0.3s cubic-bezier(0.34, 2, 0.84, 1) ${
										fab.init ? '' : 'forwards'
									}`,
									animationDelay: `${(fab.index || 0) * animationDelay}s`,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
							}}
							sx={{
								...getFabStyle(fab.placement, fab.index || 0),
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{fab.subActions.map((subFab) => (
								<SpeedDialAction
									key={subFab.tooltip ?? uniqueId('fab-sub-action-')}
									tooltipTitle={t(subFab.tooltip ?? '')}
									onClick={() => {
										if (subFab.customAction) {
											subFab.customAction();
										} else if (subFab.targetUrl) {
											navigate(subFab.targetUrl);
										}
									}}
									icon={subFab.icon}
								/>
							))}
						</SpeedDial>
					);
				} else {
					if (fab.permission && !checkWritePermission(userRole, fab.permission)) return null;
					return (
						<Fab
							size={fab.size ?? 'medium'}
							ref={fabRef}
							key={fab.id}
							aria-label={fab.tooltip}
							color={fab.color ? 'inherit' : 'primary'}
							disabled={fab.disabled}
							sx={{
								...getFabStyle(fab.placement, fab.index || 0),
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transform: fab.animation === bounce || fab.init ? 'scale(1)' : 'scale(0)',
								animation: `${fab.animation} 0.3s cubic-bezier(0.34, 2, 0.84, 1) ${
									fab.init ? '' : 'forwards'
								}`,
								animationDelay: `${(fab.index || 0) * animationDelay}s`,
								backgroundColor: fab.color ?? '',
								transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
								'&:hover': { transform: 'scale(1.1) !important' },
							}}
							onAnimationEnd={() => setFabInit(fab.id)}
							onClick={() => {
								if (fab.customAction) {
									fab.customAction();
								} else if (fab.targetUrl) {
									navigate(fab.targetUrl);
								}
							}}
						>
							{fab.icon ?? <Add />}
						</Fab>
					);
				}
			})}
			{exitingFabs.map((fab) => {
				const highestIndex = exitingFabs.filter((fab) => fab.placement === fab.placement).length;
				return (
					<Fab
						key={fab.id}
						ref={fabRef}
						size={fab.size ?? 'medium'}
						color={fab.color ? 'inherit' : 'primary'}
						sx={{
							...getFabStyle(fab.placement, fab.index || 0),
							animation: `${fab.animation} 0.6s cubic-bezier(0.34, 2, 0.84, 1) forwards`,
							animationDelay: `${(highestIndex - 1 - (fab.index || 0)) * animationDelay}s`,
							backgroundColor: fab.color ?? '',
						}}
						onAnimationEnd={() => handleAnimationEnd(fab.id)}
						disabled={fab.disabled}
					>
						{fab.icon || <Add />}
					</Fab>
				);
			})}
		</div>
	);
};
