import { useEffect, useState } from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import _ from 'lodash';

import { AvailableLangs, AvailableLangsArray } from '../store/app';
import { InputGroup, InputItemData } from './InputGroup';
import { InputItem } from './InputGroupItem';
import { TextField } from '@mui/material';
import { EditShowGridField } from './common/EditShowGridField';
import { LocaleEntries, LocaleEntry, TranslationTypes } from 'common';

interface PresetInputLangGroupProps {
	type: TranslationTypes;
	strings: LocaleEntries;
	onChange: (data: LocaleEntries) => void;
	disableEdit?: boolean;
	showOnlyCurrent?: boolean;
	useReactHookForm?: boolean;
}

export interface PresetInputLangState {
	id: number;
	availableLanguages: AvailableLangs[];
	selectedLanguage: AvailableLangs | null;
	value: LocaleEntry | null;
}

export const convertStrings = (
	tempStrings: LocaleEntries,
	oldStrings: LocaleEntries,
	type: TranslationTypes,
	onSetStrings: (data: LocaleEntries) => void,
) => {
	let newStrings: LocaleEntries = [...oldStrings];
	// foreach changed string data
	for (let i = 0; i < newStrings.length; i++) {
		// is the string we are currently checking of the right type?
		if (newStrings[i].type !== type) continue;

		const string = newStrings[i];
		const langCode = string.langCode;
		// is that particular old string present inside the new array?
		const index = tempStrings.findIndex((entry) => {
			return entry.langCode === langCode;
		});
		// if not present we should remove it from the list of current strings
		if (index === -1) {
			newStrings.splice(i, 1);
		}
	}

	for (let i = 0; i < tempStrings.length; i++) {
		const langCode = tempStrings[i].langCode;
		let index = -1;

		if (newStrings.length > 0) {
			index = newStrings.findIndex((entry) => {
				return entry.type === type && entry.langCode === langCode;
			});
		}

		const value = tempStrings[i]?.value;

		if (value !== null) {
			const entry = { type: type, langCode: langCode, value: value };
			if (index !== -1) {
				newStrings[index] = entry;
			} else {
				if (newStrings.length > 0) {
					newStrings?.push(entry);
				} else {
					newStrings = [entry];
				}
			}
		}
	}
	onSetStrings(newStrings);
};

const sortOrder: { [index: string]: number } = { jp: 1, it: 2, en: 3 };

export const PresetInputLangGroup = (props: PresetInputLangGroupProps) => {
	const { t } = useTranslation();

	const [items, setItems] = useState<InputItemData[] | null>(null);
	const [unusedItems, setUnusedItems] = useState<LocaleEntries>([]);
	const [enabledLanguages, setEnabledLanguages] = useState<InputItem[]>([]);

	const extractInputDataFromStrings = (data?: LocaleEntries): InputItemData[] => {
		// should we parse props.strings or data?
		// when using this component as normal input we can parse props.strings,
		// since it's likely been set at mount time
		// when using react-hook-form the data will be injected afeter mount
		// so we need to parse the property "data"
		let dataToParse: LocaleEntries = props.strings;
		if (props.useReactHookForm && data) {
			dataToParse = data;
		}
		// get the default languages
		const availableLangs = [...AvailableLangsArray];
		// cycle through the data to parse and splice from availableLanguages
		// the languages already used in the inputs and to
		// convert the data to the format used in InputGroup
		const extractedData = dataToParse.map((m, it) => {
			// TODO: I am casting to AvailableLangs but we should add the enum to common and use it in the schemas
			availableLangs.splice(availableLangs.indexOf(m.langCode as AvailableLangs), 1);
			return {
				id: it,
				value: m.value,
				selectedItem: {
					id: m.langCode,
					label: t(`langs.${m.langCode}`),
				},
			};
		});

		// convert the available languages to InputItem,
		// the format used in InputGroup to determine if an option
		// is already in use or not
		const langsToEnable = availableLangs.map((m) => {
			return {
				id: m,
				label: `langs.${m}`,
			} as InputItem;
		});

		// set the enabled languages only if the content of the array
		// is not equal to the existing array
		// console.log('oldLangs', enabledLanguages, 'newLangs', langsToEnable);
		setEnabledLanguages((oldLangs) => {
			if (!_.isEqual(oldLangs, langsToEnable)) {
				return langsToEnable;
			} else {
				return oldLangs;
			}
		});

		// return the extracted data
		return extractedData;
	};

	// Since sometimes we receive changed inputs from Control
	// we're using useEffect but check if the values we get
	// are the same as the ones we already have
	useEffect(() => {
		let newItems: InputItemData[] = [];
		if (props.useReactHookForm) {
			// first divide the items we are going to use
			// from the items we're not going to use right away
			// (we will merge the items later on if we're using react-hook-form)
			let itemsToParse = props.strings.filter((string) => string.type === props.type);
			let itemsToCache = props.strings.filter((string) => string.type !== props.type);

			itemsToParse = _.sortBy(itemsToParse, (o) => {
				return sortOrder[o.langCode];
			});

			itemsToCache = _.sortBy(itemsToCache, (o) => {
				return sortOrder[o.langCode];
			});

			setUnusedItems(itemsToCache);
			newItems = extractInputDataFromStrings(itemsToParse);
		} else {
			newItems = extractInputDataFromStrings();
		}

		// console.log('old', items, 'new', newItems);
		setItems((oldItems) => {
			if (!_.isEqual(oldItems, newItems)) {
				return newItems;
			} else {
				return oldItems ?? [];
			}
		});
	}, [props.strings]);

	const inputDataToLocaleEntry = (data: InputItemData) => {
		return {
			type: props.type,
			langCode: data.selectedItem.id,
			value: data.value,
		} as LocaleEntry;
	};

	const finalizeAndPushItems = (items: InputItemData[]) => {
		let itemsToPush = items.map((m) => inputDataToLocaleEntry(m));

		itemsToPush = _.sortBy(itemsToPush, (o) => {
			return sortOrder[o.langCode];
		});

		// if we are using react-hook-form we need to combine
		// the modified items with the unused items (the items which type is
		// different from the one we are translating in this component)
		if (props.useReactHookForm) {
			itemsToPush = [...itemsToPush, ...unusedItems];
		}
		props.onChange(itemsToPush);
	};

	if (props.showOnlyCurrent && props.disableEdit) {
		const currentTranslation = props.strings.find((x) => x.langCode === getI18n().language);
		if (currentTranslation) {
			return (
				<EditShowGridField width={12} isEditing={false} label>
					<TextField
						required
						label={t('product.name')}
						variant='outlined'
						id='value-input'
						value={currentTranslation.value}
					/>
				</EditShowGridField>
			);
		} else {
			return (
				<EditShowGridField width={12} isEditing={false} label>
					<TextField
						required
						label={t('product.name')}
						variant='outlined'
						id='value-input'
						value='no translation for current language'
					/>
				</EditShowGridField>
			);
		}
	} else if (items) {
		return (
			<InputGroup
				id={0}
				selectableItems={enabledLanguages}
				items={items}
				disableEdit={props.disableEdit}
				onChange={(data: InputItemData) => {
					const itemIndex = items.findIndex((m) => m.id === data.id);
					if (itemIndex > -1) {
						items[itemIndex] = data;
					} else {
						items.push(data);
					}
					setItems([...items]);
					finalizeAndPushItems(items);
				}}
				onRemove={(oldItem: InputItemData) => {
					const itemIndex = items.findIndex((m) => m.id === oldItem.id);
					if (itemIndex > -1) {
						items.splice(itemIndex, 1);
					}
					setItems([...items]);
					finalizeAndPushItems(items);
				}}
			/>
		);
	} else {
		return <></>;
	}
};
