import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { doLogin } from '../store/user';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { setUserRoles } from '../store/role';
import { UserLoginResponse } from 'common';

const theme = createTheme();

export default function SignIn() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const loggedIn = useAppSelector((state) => state.user.loggedIn);
	const operationStatus = useAppSelector((state) => state.user.op_status);
	const [cookies, setCookies] = useCookies(['access_token', 'refresh_token', 'user']);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const email = data.get('email')?.valueOf();
		const password = data.get('password')?.valueOf();

		if (email != null || password != null) {
			const user = await dispatch(
				doLogin({
					userormail: email as string,
					password: password as string,
				}),
			);
			const data = user.payload as UserLoginResponse;

			console.log(data);

			if (data.accessToken != null) {
				const { accessToken, refreshToken, ...user } = data;
				setCookies('access_token', accessToken, { path: '/' });
				setCookies('refresh_token', refreshToken, { path: '/' });
				setCookies('user', JSON.stringify(user));
				console.log('signin');
				if (data.roles.length > 0) {
					// dispatch roles correctly (if needed)
					// dispatch(setUserRoles(data.roles));
				}
			}
			navigate('/');
		} else {
			throw new Error('email or password were null');
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					<Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin='normal'
							required
							fullWidth
							id='email'
							label='Email Address'
							name='email'
							autoComplete='email'
							autoFocus
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							name='password'
							label='Password'
							type='password'
							id='password'
							autoComplete='current-password'
						/>
						<FormControlLabel
							control={<Checkbox value='remember' color='primary' />}
							label='Remember me'
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
							disabled={operationStatus === 'pending'}
						>
							Sign In
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href='#' variant='body2'>
									Forgot password?
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
}
