import { Blender, ContentCut } from '@mui/icons-material';
import { Chip, SxProps, Theme } from '@mui/material';
import { AppFunction, Recipe, RecipeType } from 'common';
import { useTranslation } from 'react-i18next';

interface RecipeChipProps {
	recipe?: Recipe | null;
	sx?: SxProps<Theme>;
}

export const RecipeChip = <T extends RecipeChipProps>({ recipe, sx }: T): JSX.Element | null => {
	const { t } = useTranslation();

	if (!recipe) {
		return null;
	}

	return (
		<Chip
			size='small'
			label={t(`${AppFunction.Product}.recipeTypes.${recipe.recipeType}`)}
			icon={recipe.recipeType === RecipeType.division ? <ContentCut /> : <Blender />}
			color='info'
			sx={sx}
		/>
	);
};
