import { useOrderEditor } from '@contexts/index';
import useInventory from '@hooks/useInventory';
import useProducts from '@hooks/useProducts';
import { Grid, ToggleButton } from '@mui/material';
import { AppFunction, OrderProductResponse } from 'common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import InventoryProductAutocomplete from '@components/common/inputs/products/InventoryProductAutocomplete';
import { ProductQuantitySelector } from '@components/common/inputs/products/ProductQuantitySelector';
import { OrderProductRowWrapper } from '../../common/OrderProductRowWrapper';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { EnhancedVariants } from '@contexts/orderEditorContext/types';

interface Props {
	index: number;
	data: OrderProductResponse;
	subOrderCode: string;
	unavailableVariantIds: number[];
}

export const InternalSuborderProduct = ({
	index,
	data,
	subOrderCode,
	unavailableVariantIds,
}: Props) => {
	const { variantList } = useProducts();
	const { inventories } = useInventory();
	const { t } = useTranslation();

	const { configValues, getValues, changeOrderProduct: changeVariant } = useOrderEditor();

	const fromId = getValues('fromId');

	const availableItems = useMemo((): EnhancedVariants => {
		const tempItems: EnhancedVariants = [];

		if (!fromId || fromId === -1) {
			return tempItems;
		}

		inventories[fromId]?.forEach((inventoryItem) => {
			const push =
				inventoryItem.quantity > 0 &&
				(!unavailableVariantIds.includes(inventoryItem.variantId) ||
					inventoryItem.variantId === data.variantId);
			const orderProductId = inventoryItem.orderProduct?.id
				? (inventoryItem.orderProduct?.id as number)
				: -1;
			if (push && inventoryItem.variant && orderProductId) {
				const orderId: number | string | undefined = inventoryItem.orderProduct?.orderId;
				tempItems.push({
					...inventoryItem.variant,
					orderProductId: orderProductId,
					expiresAt: inventoryItem.expiresAt,
					actualExpiration: inventoryItem.actualExpiration,
					maxAmount: inventoryItem.quantity,
					weight: inventoryItem.weight,
					unit: inventoryItem.unit,
					remainingWeight: inventoryItem.remainingWeight,
					estimated: inventoryItem.estimated,
					orderId,
				});
			}
		});

		return tempItems;
	}, [fromId, inventories]);

	const value = useMemo(() => {
		if (data.variantId !== -1) {
			const variant = availableItems.find(
				(vr) => vr.orderProductId === data.prevOrderId && vr.expiresAt === data.expiresAt,
			);
			if (variant) {
				return variant;
			}
		}
		return null;
	}, [variantList, data, unavailableVariantIds, configValues]);

	const ProductContent = useMemo(() => {
		return (
			<OrderProductRowWrapper index={index} data={data} subOrderCode={subOrderCode}>
				<Grid
					item
					flexGrow={{
						xs: 1,
						md: 1,
					}}
					xs={12}
					lg='auto'
					flexBasis={0}
				>
					<InventoryProductAutocomplete
						availableItems={availableItems}
						value={value?.orderProductId ?? null}
						onChange={(newValue) => {
							if (typeof newValue === 'object' && newValue !== null) {
								const expiresAt = newValue.expiresAt ?? null;
								let pickedWeight = undefined;
								if (newValue.variable) {
									pickedWeight = newValue.weight * (data.orderQty ?? 1);
								}
								changeVariant(
									{
										variantId: newValue.id,
										expiresAt: expiresAt,
										unit: newValue.unit,
										actualExpiration: newValue.actualExpiration,
										prevOrderId: newValue.orderProductId,
										weight: newValue.maxAmount ?? 1,
										pickedWeight,
										estimated: newValue.variable ? true : false,
										orderId: newValue.orderId,
										sourceId: newValue.orderProductId,
									},
									subOrderCode,
									index,
								);
							} else {
								changeVariant({ variantId: -1 }, subOrderCode, index);
							}
						}}
					/>
				</Grid>
				{value && (
					<>
						{value.variable && (
							<>
								<InputGridField width={1} type={ComponentType.ToggleButton}>
									<ToggleButton
										value='check'
										size='small'
										selected={data.estimated ?? false}
										fullWidth
										onChange={() =>
											changeVariant(
												{
													estimated: !data.estimated,
												},
												subOrderCode,
												index,
											)
										}
										sx={{
											borderColor: data.estimated ? 'error.main' : 'success.main',
										}}
										color='error'
									>
										{data.estimated
											? t(`${AppFunction.Order}.processing.notWeighted`)
											: t(`${AppFunction.Order}.processing.weighted`)}
									</ToggleButton>
								</InputGridField>
							</>
						)}
						<ProductQuantitySelector
							width='auto'
							quantity={data.orderQty ?? 1}
							value={value}
							onChange={(newValue) => {
								let rawQuantity = newValue;
								if (rawQuantity < 1 || isNaN(rawQuantity)) {
									rawQuantity = 1;
								}
								const pickedWeight = (): number | null => {
									if (value.variable && value.weight && value.maxAmount) {
										let realWeight = value.weight;
										if (value.remainingWeight) {
											realWeight = value.remainingWeight;
										}
										return (realWeight / value.maxAmount) * rawQuantity;
									}
									return null;
								};
								changeVariant(
									{
										orderQty: rawQuantity,
										weight: value.weight, // this identifies the warehouse product
										pickedWeight: pickedWeight(),
									},
									subOrderCode,
									index,
								);
							}}
						/>
					</>
				)}
			</OrderProductRowWrapper>
		);
	}, [availableItems, value, configValues, data]);

	return <>{ProductContent}</>;
};
