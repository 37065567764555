import { Column } from 'primereact/column';
import { TableContextProvider, useFeedbacks, useLayout } from '@contexts/index';
import useGroups from '@hooks/useGroups';
import useLocale from '@hooks/useLocale';
import { AppFunction, EntityGroupResponse, GenericIdRequest, TranslationTypes } from 'common';
import { Settings } from '@mui/icons-material';
import useAppFunctions from '@hooks/useAppFunctions';
import { useEffect } from 'react';
import { DropDownActionType } from '@contexts/tableContext/types';
import { useTranslation } from 'react-i18next';

const appFunction = AppFunction.EntityGroup;

export const GroupsList = () => {
	const { groupList, getLinkedEntities, deleteGroup } = useGroups();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const renderName = (rowData: EntityGroupResponse) => {
		const string = getTranslatedString(AppFunction.EntityGroup, rowData.id, TranslationTypes.name);
		return <span>{string}</span>;
	};

	const renderGroupMembers = (rowData: EntityGroupResponse) => {
		const culo = getLinkedEntities(rowData.id);
		return <span>{culo?.length}</span>;
	};

	function callDeleteGroupDialog(data: GenericIdRequest) {
		const jobTitleName = getTranslatedString(
			AppFunction.EntityGroup,
			data.id,
			TranslationTypes.name,
		);
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('dialog.confirmDelete', { deleting: jobTitleName }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteGroup(data).then((response) => {
							if (response) {
								console.log('deleted');
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={groupList}
			pageIdentifier={appFunction}
			actions={[
				{
					type: DropDownActionType.edit,
					customLink: '/entityGroup/:id/edit',
				},
				{
					type: DropDownActionType.details,
					customLink: '/entityGroup/:id',
				},
				{
					type: DropDownActionType.delete,
					action: callDeleteGroupDialog,
				},
			]}
		>
			<Column field='strings' header='name' body={renderName} sortable />
			<Column field='type' header='type' sortable />
			<Column field='members' header='members' body={renderGroupMembers} sortable />
		</TableContextProvider>
	);
};
