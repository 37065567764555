import { Chip, SxProps, Theme } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface ChainCompletionTimeChipProps {
	timeString?: string | null;
	sx?: SxProps<Theme>;
}

function convertToHumanReadable(input: string, t: TFunction): string {
	// Define the mapping of units to their full names
	const units: { [key: string]: string } = {
		M: 'time.month',
		w: 'time.week',
		d: 'time.day',
		h: 'time.hour',
		m: 'time.minute',
		s: 'time.second',
	};

	// Define the mapping for days of the week
	const daysOfWeek: { [key: string]: string } = {
		mo: t('time.monday'),
		tu: t('time.tuesday'),
		we: t('time.wednesday'),
		th: t('time.thursday'),
		fr: t('time.friday'),
		sa: t('time.saturday'),
		su: t('time.sunday'),
	};

	// Separate the time and day parts
	const [timePart, dayPart] = input.split(',');

	let humanReadable = '';

	// Extract the time components
	if (timePart) {
		const timePattern = /(\d+)([Mwdhms])/g;
		let match: RegExpExecArray | null;
		while ((match = timePattern.exec(timePart)) !== null) {
			const value = match[1];
			const unit = match[2];
			const unitName = t(units[unit], { count: parseInt(value) });
			humanReadable += `${value} ${unitName}, `;
		}
	}

	// Extract the day component
	if (dayPart) {
		const dayNames = dayPart
			.split('')
			.map((day) => daysOfWeek[day.toLowerCase()])
			.filter(Boolean);
		if (dayNames.length > 0) {
			humanReadable += dayNames.join(', ');
		}
	}

	// Remove trailing comma and space, if any
	humanReadable = humanReadable.trim().replace(/,$/, '');

	return humanReadable;
}

export const ChainCompletionTimeChip = ({
	timeString,
	sx,
}: ChainCompletionTimeChipProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (!timeString) {
		return null;
	}

	return <Chip label={convertToHumanReadable(timeString, t)} size='small' sx={sx} />;
};
