import { CombinedIcon } from '@components/common/CombinedIcon';
import {
	AccountCircle,
	Attribution,
	Badge,
	CardGiftcard,
	GroupWork,
	Inventory,
	Input,
	Link,
	LocalShipping,
	Photo,
	Place,
	PointOfSale,
	PowerInput,
	Public,
	SouthAmerica,
	SupervisedUserCircle,
	Sell,
	SubdirectoryArrowRight,
	Groups,
	AttachMoney,
	AccessTime,
	OfflineBolt,
	Translate,
	Blender,
	Delete,
	ExitToApp,
	MoveUp,
	PanTool,
	Build,
	Logout,
	CallSplit,
	Warehouse,
	Home,
	Storefront,
	Flight,
	Waves,
	Business,
	Notes,
	QuestionMark,
	Check,
	ListAlt,
	Grading,
	CurrencyYen,
	CalendarMonth,
	FormatListBulleted,
	AttachFile,
	EditAttributes,
	Numbers,
	FactCheck,
	TextFields,
	AcUnit,
	WbSunny,
	Kitchen,
	ContentCut,
	Block,
	Clear,
	PlaylistAddCheck,
	Loop,
	Pending,
	NoteAlt,
	Assignment,
	Folder,
} from '@mui/icons-material';
import { SxProps } from '@mui/material';
import {
	AppFunction,
	OrderType,
	ProductType,
	RecipeType,
	ShippingType,
	StorageConditionType,
	StorageType,
	TaskFieldType,
	TaskStatus,
} from 'common';
import { ReactElement } from 'react';

type WithSx = { sx: SxProps };

type ConfigElement = ReactElement<WithSx>;

type AppFunctionIconConfig = {
	[key in AppFunction]: { [key: string | 'default']: ConfigElement | null };
};

const mainSx: SxProps = {
	paddingLeft: '0.4em',
};

const subSx: SxProps = {
	bottom: '-10%',
	right: -2,
};

export const config: AppFunctionIconConfig = {
	[AppFunction.Address]: {
		['default']: <Place />,
	},
	[AppFunction.Carrier]: {
		['default']: <LocalShipping />,
		[ShippingType.air]: <Flight />,
		[ShippingType.sea]: <Waves />,
		[ShippingType.externalCarrier]: <LocalShipping />,
		[ShippingType.internalCarrier]: <LocalShipping />,
		[ShippingType.handDelivered]: <PanTool />,
		[ShippingType.notdefined]: <QuestionMark />,
	},
	[AppFunction.Currency]: {
		['default']: <AttachMoney />,
	},
	[AppFunction.Customer]: {
		['default']: <SupervisedUserCircle />,
	},
	[AppFunction.CustomerType]: {
		['default']: <Attribution />,
	},
	[AppFunction.CarrierPrice]: {
		['default']: (
			<CombinedIcon main={<LocalShipping />} sub={<AttachMoney />} sx={mainSx} subSx={subSx} />
		),
	},
	[AppFunction.Country]: {
		['default']: <Public />,
	},
	[AppFunction.CustomerBranch]: {
		['default']: (
			<CombinedIcon
				main={<SupervisedUserCircle />}
				sub={<Storefront />}
				sx={mainSx}
				subSx={subSx}
			/>
		),
	},
	[AppFunction.DeliveryArea]: {
		['default']: (
			<CombinedIcon main={<LocalShipping />} sub={<SouthAmerica />} sx={mainSx} subSx={subSx} />
		),
	},
	[AppFunction.DeliveryTime]: {
		['default']: (
			<CombinedIcon main={<LocalShipping />} sub={<AccessTime />} sx={mainSx} subSx={subSx} />
		),
	},
	[AppFunction.EntityEntityGroup]: {
		['default']: <CombinedIcon main={<Groups />} sub={<Link />} sx={mainSx} subSx={subSx} />,
	},
	[AppFunction.EntityGroup]: {
		['default']: <Groups />,
	},
	[AppFunction.Group]: {
		['default']: <GroupWork />,
	},
	[AppFunction.Image]: {
		['default']: <Photo />,
	},
	[AppFunction.Inventory]: {
		['default']: <Warehouse />,
	},
	[AppFunction.JobTitle]: {
		['default']: <Badge />,
	},
	[AppFunction.Location]: {
		['default']: <Place />,
		[StorageType.external]: <Warehouse />,
		[StorageType.main]: <Home />,
		[StorageType.processing]: <Blender />,
		[StorageType.shipping]: <LocalShipping />,
		[StorageType.store]: <Storefront />,
		[StorageType.subStorage]: <SubdirectoryArrowRight />,
		[StorageType.trash]: <Delete />,
		[StorageType.airport]: <Flight />,
		[StorageType.port]: <Waves />,
		[StorageType.office]: <Business />,
		[StorageType.folder]: <Folder />,
	},
	[AppFunction.Merchant]: {
		['default']: <PointOfSale />,
	},
	[AppFunction.Order]: {
		['default']: <Sell />,
		[OrderType.Processing]: <Blender />,
		[OrderType.Inbound]: <ExitToApp />,
		[OrderType.Internal]: <MoveUp />,
		[OrderType.Manual]: <PanTool />,
		[OrderType.Modify]: <Build />,
		[OrderType.Outbound]: <PointOfSale />,
		[OrderType.Split]: <CallSplit />,
		[OrderType.Delete]: <Delete />,
	},
	[AppFunction.Person]: {
		['default']: null,
	},
	[AppFunction.Product]: {
		[ProductType.product]: <Inventory />,
		[ProductType.packaging]: <CardGiftcard />,
		[RecipeType.division]: <ContentCut />,
		[RecipeType.processing]: <Blender />,
	},
	[AppFunction.Region]: {
		['default']: <SouthAmerica />,
	},
	[AppFunction.RegionType]: {
		['default']: <SouthAmerica />,
	},
	[AppFunction.Role]: {
		['default']: <Badge />,
	},
	[AppFunction.String]: {
		['default']: <Translate />,
	},
	[AppFunction.TaskArchetype]: {
		['default']: <Assignment />,
	},
	[AppFunction.TaskChainArchetype]: {
		['default']: <Link />,
	},
	[AppFunction.TaskFieldArchetype]: {
		['default']: <Input />,
		[TaskFieldType.checkbox]: <Check />,
		[TaskFieldType.closeorder]: <ListAlt />,
		[TaskFieldType.confirmorder]: <Grading />,
		[TaskFieldType.confirmprices]: <CurrencyYen />,
		[TaskFieldType.date]: <CalendarMonth />,
		[TaskFieldType.dropdown]: <FormatListBulleted />,
		[TaskFieldType.fileinput]: <AttachFile />,
		[TaskFieldType.modifyorder]: <EditAttributes />,
		[TaskFieldType.number]: <Numbers />,
		[TaskFieldType.picking]: <FactCheck />,
		[TaskFieldType.selectvessel]: <LocalShipping />,
		[TaskFieldType.string]: <TextFields />,
	},
	[AppFunction.TaskFieldParameter]: {
		['default']: <FormatListBulleted />,
	},
	[AppFunction.TaskFieldSetArchetype]: {
		['default']: <PowerInput />,
	},
	[AppFunction.Template]: {
		['default']: null,
	},
	[AppFunction.Trigger]: {
		['default']: <OfflineBolt />,
	},
	[AppFunction.User]: {
		['default']: <AccountCircle />,
	},
	[AppFunction.Variant]: {
		['default']: <SubdirectoryArrowRight />,
		[ProductType.product]: <Inventory />,
		[ProductType.packaging]: <CardGiftcard />,
	},
	[AppFunction.Task]: {
		['default']: null,
		[TaskStatus.blocked]: <Block />,
		[TaskStatus.cancelled]: <Clear />,
		[TaskStatus.completed]: <PlaylistAddCheck />,
		[TaskStatus.expired]: <ExitToApp />,
		[TaskStatus.failed]: (
			<CombinedIcon main={<AccessTime />} sub={<Clear />} sx={mainSx} subSx={subSx} />
		),
		[TaskStatus.inprogress]: <Loop />,
		[TaskStatus.pending]: <Pending />,
		[TaskStatus.stub]: <NoteAlt />,
	},
	[AppFunction.Tag]: {
		['default']: <Badge />,
	},
	[AppFunction.Chain]: {
		['default']: <Link />,
	},
	[AppFunction.CarrierPlan]: {
		['default']: null,
		[StorageConditionType.ambient]: <WbSunny />,
		[StorageConditionType.chilled]: <Kitchen />,
		[StorageConditionType.frozen]: <AcUnit />,
	},
	[AppFunction.CarrierDeliveryArea]: {
		['default']: null,
	},
	[AppFunction.CustomerBilling]: {
		['default']: null,
	},
	[AppFunction.Note]: {
		['default']: <Notes />,
	},
};
