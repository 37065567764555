import { TaskResponse } from 'common';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

export enum TaskSignal {
	CLOSE_ALL_TASKS = 'CLOSE_ALL_TASKS',
	ACCEPT_ALL_TASKS = 'ACCEPT_ALL_TASKS',
}

interface MultipleTaskContextType {
	canCloseAll: boolean;
	tasks: Record<number, TaskResponse>;
	handleTaskStatus: (taskId: number, status: boolean) => void;
	subscribe: (task: TaskResponse, listener: (signal: TaskSignal) => Promise<boolean>) => void;
	unsubscribe: (taskId: number) => void;
	sendSignal: (signal: TaskSignal) => Promise<boolean>;
}

const defaultContext: MultipleTaskContextType = {
	canCloseAll: false,
	tasks: {},
	handleTaskStatus: () => {
		throw new Error('handleTaskStatus not implemented');
	},
	subscribe: () => {
		throw new Error('subscribe not implemented');
	},
	unsubscribe: () => {
		throw new Error('unsubscribe not implemented');
	},
	sendSignal: () => {
		throw new Error('sendSignal not implemented');
	},
};

interface MultipleTaskContextProps {
	children: React.ReactNode;
}

export const MultipleTaskContext = createContext<MultipleTaskContextType>(defaultContext);

export const MultipleTaskProvider = ({ children }: MultipleTaskContextProps): JSX.Element => {
	const [canCloseAll, setCanCloseAll] = useState<boolean>(false);
	const [tasks, setTasks] = useState<Record<number, TaskResponse>>({});
	const [tasksStatus, setTasksStatus] = useState<Record<number, boolean>>({});
	const listeners = useRef<Record<number, (signal: TaskSignal) => Promise<boolean>>>({});

	useEffect(() => {
		const allTasksClosed = Object.values(tasksStatus).every((status) => status);
		setCanCloseAll(allTasksClosed);
	}, [tasksStatus]);

	const subscribe = (task: TaskResponse, listener: (signal: TaskSignal) => Promise<boolean>) => {
		setTasks((oldTasks) => ({ ...oldTasks, [task.id]: task }));
		listeners.current[task.id] = listener;
	};

	const unsubscribe = (taskId: number) => {
		setTasks((oldTasks) => {
			const newTasks = { ...oldTasks };
			delete newTasks[taskId];
			return newTasks;
		});
		delete listeners.current[taskId];
	};

	const sendSignal = async (signal: TaskSignal): Promise<boolean> => {
		let result = true;
		for (const listener of Object.values(listeners.current)) {
			result = result && (await listener(signal));
		}
		return result;
	};

	const handleTaskStatus = (taskId: number, status: boolean) => {
		setTasksStatus((oldTasksStatus) => ({ ...oldTasksStatus, [taskId]: status }));
	};

	const contextValue = {
		canCloseAll,
		tasks,
		handleTaskStatus,
		subscribe,
		unsubscribe,
		sendSignal,
	};

	return (
		<MultipleTaskContext.Provider value={contextValue}>{children}</MultipleTaskContext.Provider>
	);
};

export const useMultipleTask = () => useContext(MultipleTaskContext);
