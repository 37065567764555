import { AppFunction, OrderProductResponse, VariantResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface TableQuantityBodyProps {
	orderProduct: OrderProductResponse;
	variant: VariantResponse;
	unit?: 'pieces' | 'grams';
	quantity: number;
}

export const TableQuantityBody = <T extends TableQuantityBodyProps>({
	orderProduct,
	variant,
	unit,
	quantity,
}: T) => {
	const { t } = useTranslation();

	if (!orderProduct) return <div>0</div>;
	const thisUnit = unit
		? t(`${AppFunction.Product}.units.${variant?.variable ? 'pieces' : unit}`, {
				count: orderProduct.arrivedQty ?? 2,
		  })
		: 'N/A';
	return (
		<div>
			{quantity}
			{thisUnit}
		</div>
	);
};
