import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	GenericIdRequest,
	WarehouseRequest,
	WarehouseResponse,
	WarehousesResponse,
	AffectedRowsResponse,
} from 'common';

export interface StorageState {
	warehouses: WarehousesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: StorageState = {
	warehouses: [],
	op_status: 'idle',
};

export const doListWarehouses = api.apiList<WarehousesResponse>('warehouse/list', 'warehouses');

export const doCreateWarehouse = api.apiCreate<WarehouseRequest, AffectedRowsResponse>(
	'warehouse/create',
	'warehouses',
);

export const doEditWarehouse = api.apiEdit<WarehouseResponse, AffectedRowsResponse>(
	'warehouse/edit',
	'warehouses',
);

export const doDeleteWarehouse = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'warehouse/delete',
	'warehouses',
);

export const appStorages = createSlice({
	name: 'storages',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(doListWarehouses.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListWarehouses.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.warehouses = action.payload;
		});
		builder.addCase(doListWarehouses.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateWarehouse.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateWarehouse.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateWarehouse.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditWarehouse.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditWarehouse.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditWarehouse.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeleteWarehouse.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteWarehouse.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteWarehouse.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export default appStorages.reducer;
