import { useGenericForm } from '@contexts/formContext/FormContext';
import { hasStringsProperty } from '@contexts/formContext/utils';
import { useLayout } from '@contexts/index';
import useWarnBeforeLeaving from '@hooks/useWarnBeforeLeaving';
import { Edit, Save } from '@mui/icons-material';
import { IconButton, alpha, useTheme } from '@mui/material';

import commonStyles from '@styles/Common.module.css';
import { LangsSchema } from 'common/interfaces/locale';
import _, { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	useFab?: boolean;
}

export const TabFooter = ({ useFab }: Props) => {
	const {
		showEditButton,
		formAction,
		formValues,
		appFunction,
		entityId,
		forcedDirty,
		watch,
		onSubmit,
	} = useGenericForm();
	const { setFabs } = useLayout();
	const theme = useTheme();
	const navigate = useNavigate();

	const [dirty, setDirty] = useState(false);

	const newValues = watch();

	const checkForEquality = useCallback(
		debounce(() => {
			let newData = newValues;
			let oldData = formValues;
			if (
				hasStringsProperty(formValues) &&
				formValues.strings &&
				hasStringsProperty(newValues) &&
				newValues.strings
			) {
				const newStrings = LangsSchema.safeParse(newValues.strings);
				const oldStrings = LangsSchema.safeParse(formValues.strings);

				if (newStrings.success === false || oldStrings.success === false) {
					return;
				}

				const sortOrder: { [index: string]: number } = { jp: 1, it: 2, en: 3 };
				newData = {
					...newValues,
					strings: _.sortBy(newStrings.data, (o) => {
						return sortOrder[o.langCode];
					}),
				};
				oldData = {
					...formValues,
					strings: _.sortBy(oldStrings.data, (o) => {
						return sortOrder[o.langCode];
					}),
				};
			}
			setDirty(!_.isEqual(newData, oldData));
		}, 1000),
		[newValues],
	);

	const editDisabled = formAction === 'view';

	useWarnBeforeLeaving(dirty);

	useEffect(() => {
		checkForEquality();

		return () => {
			checkForEquality.cancel();
		};
	}, [checkForEquality]);

	useEffect(() => {
		checkForEquality();
	}, [formValues, newValues]);

	useEffect(() => {
		if (!useFab || editDisabled) return;

		if (showEditButton) {
			setFabs([
				{
					icon: <Save />,
					permission: appFunction,
					customAction: onSubmit,
					disabled: !dirty && !forcedDirty && entityId !== -1,
				},
			]);
		} else {
			setFabs([]);
		}
	}, [dirty, forcedDirty, formAction, showEditButton]);

	if (!editDisabled && !useFab) {
		let scale = 1;
		if (!showEditButton) {
			scale = 0;
		} else if (dirty) {
			scale = 1.1;
		}
		return (
			<div className={commonStyles.tabFooterButtonContainer}>
				<IconButton
					size='medium'
					type='submit'
					sx={{
						transform: `scale(${scale})`,
						transition: '300ms all cubic-bezier(0.34, 2.1, 0.84, 1)',
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						'&:hover': {
							backgroundColor: alpha(theme.palette.primary.main, 0.8),
							transform: 'scale(1.2)',
						},
					}}
					// disabled={formState === 'pending' || !dirty}
					disabled={!dirty}
					onClick={onSubmit}
				>
					<Save />
				</IconButton>
			</div>
		);
	}
	if (!useFab && !editDisabled && !_.isEqual(formValues, {})) {
		return (
			<div className={commonStyles.tabFooterButtonContainer}>
				<IconButton
					size='medium'
					type='submit'
					sx={{
						// transform: `scale(${formState === 'pending' || !dirty ? '0.9, 0.9' : '1.1, 1.1'})`,
						transform: `scale(${!dirty ? '0.9, 0.9' : '1.1, 1.1'})`,
						transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						'&:hover': {
							backgroundColor: alpha(theme.palette.primary.main, 0.5),
						},
					}}
					onClick={() => navigate(`../${appFunction}/${entityId}/edit`)}
				>
					<Edit />
				</IconButton>
			</div>
		);
	}
	return <></>;
};
