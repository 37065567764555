import { Box } from '@mui/material';
import { TaskResponse } from 'common';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { useAppSelector } from '@store/hooks';
import { TaskFieldView } from '../singleTask/TaskFieldView';
import { TaskProvider } from '@contexts/index';
import useTasks from '@hooks/useTasks';
import { OrderButton } from '../../shared/OrderButton';
import { TaskConnector } from '../../shared/TaskConnector';

interface Props {
	taskId: number;
}

export const TaskListView = ({ taskId }: Props) => {
	const { getOrderFromTask } = useTasks();

	const chains = useAppSelector((state) => state.tasks.activeChains);
	const tasks = useAppSelector((state) => state.tasks.tasks);
	const task = tasks.find((task) => task.id === taskId) as TaskResponse;

	// TODO: we are assuming that the targetType of the chain is an order
	// this will be true for most of the cases, and surely during MVP phase.
	// However, we should handle this properly in the future.
	const orderId = chains.find((chain) => chain.id === task.chainId)?.targetId ?? -1;
	const order = getOrderFromTask(task);

	return (
		<FadeWrapper fadeTime={500}>
			<Box
				sx={{
					backgroundColor: 'transparent',
					position: 'relative',
				}}
			>
				<TaskProvider task={task} targetId={orderId}>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
						<Box
							sx={{
								padding: 1,
							}}
						>
							{order && <OrderButton orderId={order.id} isPopup />}
						</Box>
						<Box
							sx={{
								backgroundColor: 'white',
								borderRadius: '20px',
								overflow: 'auto',
								flexGrow: 1,
								maxHeight: '84vh',
							}}
						>
							{/* TODO: we need to take account of fieldsets when showing the fields
									it can be done later since it doesn't create functionality problems */}
							{task.archetype?.taskFieldArchetypeId.map((field, it) => (
								// TODO: we are under a TaskProvider, so we can get all the data we need from the context
								// make this (including the map) a single component that gets the data from the context
								<TaskFieldView
									key={`taskfield-${it}-${field}`}
									archetypeId={field}
									targetId={orderId}
								/>
							))}
						</Box>
					</div>
					<TaskConnector />
				</TaskProvider>
			</Box>
		</FadeWrapper>
	);
};
