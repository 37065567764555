import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, InputLabel, MenuItem, Select } from '@mui/material';
import {
	CustomerInvoicingTimeCreateSchema,
	CustomerInvoicingTimeResponse,
	CustomerInvoicingTimeSchema,
} from 'common';
import { Controller, useForm } from 'react-hook-form';
import { EditShowGridField } from '../common/EditShowGridField';
import { useTranslation } from 'react-i18next';
import { ContextualDropdownMenu } from '../ContextualDropdownMenu';
import { useCallback, useEffect, useState } from 'react';
import useCustomers from '../../hooks/useCustomers';
import useFeedbacks from '../../hooks/useFeedbacks';
import useLocale, { FixedTranlsationType } from '../../hooks/useLocale';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { Cancel, Check } from '@mui/icons-material';
import { ScopedComponent } from '../scopedlayer/ScopedComponent';
import { FocusedElementType } from '../../store/app';

interface Props {
	time: CustomerInvoicingTimeResponse;
	view?: boolean;
	onCloseCreate?: () => void;
}

export const InvoicingTime = ({ time, view, onCloseCreate }: Props) => {
	const canEdit = view ? !view : true;
	const { t } = useTranslation();
	const { getFixedTranslation } = useLocale();
	const { createInvoicingTime, editInvoicingTime, deleteInvoicingTime } = useCustomers();
	const { showSnackBar } = useFeedbacks();

	const [isEditing, setEditing] = useState(time.id === -1);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CustomerInvoicingTimeResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: { ...time },
		resolver: zodResolver(CustomerInvoicingTimeSchema),
	});

	useEffect(() => {
		reset(time);
	}, [time]);

	const onSubmit = useCallback(
		async (data: CustomerInvoicingTimeResponse) => {
			if (time.id === -1) {
				const createData = CustomerInvoicingTimeCreateSchema.safeParse(data);
				if (createData.success) {
					await createInvoicingTime(createData.data);
					showSnackBar({ message: 'invoicing time created', severity: 'success' });
					onCloseCreate?.();
				}
			} else {
				await editInvoicingTime(data);
				showSnackBar({ message: 'invoicing time edited', severity: 'success' });
				setEditing(false);
			}
		},
		[time],
	);

	const onDelete = async () => {
		await deleteInvoicingTime({ id: time.id || -1 });
		showSnackBar('invoicing time deleted');
	};

	const onCancel = () => {
		if (time.id === -1) {
			onCloseCreate?.();
		} else {
			setEditing(false);
		}
	};

	const CloseDateMenuItems = () => {
		const items: JSX.Element[] = [];

		for (let i = 1; i < 32; i++) {
			items.push(
				<MenuItem value={i} key={i}>
					{getFixedTranslation(`closeDate.${i}`, FixedTranlsationType.dayOfMonth)}
				</MenuItem>,
			);
		}

		return items;
	};

	const MonthDelayMenuItems = () => {
		const items: JSX.Element[] = [];

		for (let i = 0; i < 7; i++) {
			items.push(
				<MenuItem value={i} key={i}>
					{getFixedTranslation(`monthDelay.${i}`, FixedTranlsationType.monthDelay)}
				</MenuItem>,
			);
		}

		return items;
	};

	const InvoicingDateMenuItems = () => {
		const items: JSX.Element[] = [];

		for (let i = 1; i < 32; i++) {
			items.push(
				<MenuItem value={i} key={i}>
					{getFixedTranslation(`invoicingDate.${i}`, FixedTranlsationType.dayOfMonth)}
				</MenuItem>,
			);
		}

		return items;
	};

	return (
		<ScopedComponent
			data={{ id: time.id, type: FocusedElementType.invoicingtime, zIndex: 200 }}
			active={isEditing}
			title={time.id !== -1 ? 'operations.edit' : 'operations.create'}
			translateTitle
		>
			<Grid container spacing={2}>
				<Grid container spacing={2} item xs={!canEdit ? 12 : isEditing ? 12 : 11}>
					<Controller
						name='closeDate'
						control={control}
						defaultValue={31}
						render={({ field }) => (
							<EditShowGridField
								isEditing={isEditing}
								width={4}
								fixedTranslationType={FixedTranlsationType.dayOfMonth}
								forceLabel={t('dates.closeDateTitle')}
								label
							>
								<InputLabel id='close_date'>{t('dates.closeDateTitle')}</InputLabel>
								<Select
									labelId='close_date'
									id='closeDate'
									label={t('dates.closeDateTitle')}
									{...field}
									value={field.value ?? -1}
									onChange={(ev) => field.onChange(+ev.target.value)}
								>
									{CloseDateMenuItems()}
								</Select>
							</EditShowGridField>
						)}
					/>
					<Controller
						name='monthDelay'
						control={control}
						defaultValue={1}
						render={({ field }) => (
							<EditShowGridField
								isEditing={isEditing}
								width={4}
								fixedTranslationType={FixedTranlsationType.monthDelay}
								forceLabel={t('dates.monthDelayTitle')}
								label
							>
								<InputLabel id='month_delay'>{t('dates.monthDelayTitle')}</InputLabel>
								<Select
									labelId='month_delay'
									id='monthDelay'
									label={t('dates.monthDelayTitle')}
									{...field}
									value={field.value ?? -1}
									onChange={(ev) => field.onChange(+ev.target.value)}
								>
									{MonthDelayMenuItems()}
								</Select>
							</EditShowGridField>
						)}
					/>
					<Controller
						name='invoicingDate'
						control={control}
						defaultValue={10}
						render={({ field }) => (
							<EditShowGridField
								isEditing={isEditing}
								width={4}
								fixedTranslationType={FixedTranlsationType.dayOfMonth}
								forceLabel={t('dates.invoicingDateTitle')}
								label
							>
								<InputLabel id='invoicing_date'>{t('dates.invoicingDateTitle')}</InputLabel>
								<Select
									labelId='invoicing_date'
									id='invoicingDate'
									label={t('dates.invoicingDateTitle')}
									{...field}
									value={field.value ?? -1}
									onChange={(ev) => field.onChange(+ev.target.value)}
								>
									{InvoicingDateMenuItems()}
								</Select>
							</EditShowGridField>
						)}
					/>
				</Grid>
				{canEdit && isEditing ? (
					<>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.save'
								translate
								colorType='success'
								icon={<Check />}
								onClick={handleSubmit(onSubmit)}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								translate
								colorType='error'
								icon={<Cancel />}
								onClick={onCancel}
							/>
						</Grid>
					</>
				) : canEdit ? (
					<Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
						<ContextualDropdownMenu onEdit={() => setEditing(true)} onDelete={onDelete} />
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</ScopedComponent>
	);
};
