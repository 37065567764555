import { AppFunction, TranslatableAppFunction, TranslationTypes } from 'common';
import { Translation, useTranslation } from 'react-i18next';

import styles from './useLocale.module.css';
import usePeople from './usePeople';
import useUsers from './useUsers';

export interface Data {
	[key: string]: unknown;
}

export enum FixedTranlsationType {
	dayOfMonth = 'dayOfMonth',
	monthDelay = 'monthDelay',
}

function useLocale() {
	const { i18n, t } = useTranslation();
	const { peopleList } = usePeople();
	const { userList } = useUsers();

	const currentLanguage = i18n.language;

	function renderI18NField(data: string): JSX.Element;
	function renderI18NField(data: string[]): JSX.Element;
	function renderI18NField(data: Data, field: string): JSX.Element;
	function renderI18NField(data: Data | string | string[], field?: string): JSX.Element {
		if (field && typeof data !== 'string' && !Array.isArray(data)) {
			return (
				<div className={styles['translation-wrapper']}>
					<Translation>
						{(t) => {
							if (typeof data[field] === 'string') {
								const value = t(String(data[field]), { ns: 'locale', default: '' });
								if (data[field] !== value) {
									return <p>{value}</p>;
								} else return <p></p>;
							} else return <p></p>;
						}}
					</Translation>
				</div>
			);
		} else if (Array.isArray(data) && data.every((d) => typeof d === 'string')) {
			return (
				<div className={styles['translation-wrapper']}>
					{data.map((string, it) => {
						const affix =
							it !== data.length - 1 ? (
								<div
									style={{ display: 'inline-block', paddingLeft: '0.5em', paddingRight: '0.5em' }}
								>
									{'>'}
								</div>
							) : (
								<></>
							);
						const translation = (
							<div style={{ display: 'inline-block' }}>
								<Translation>
									{(t) => {
										return <p>{t(string, { ns: 'locale', default: '' })}</p>;
									}}
								</Translation>
							</div>
						);
						return (
							<div key={it} className={styles['multiple']}>
								{translation}
								{affix}
							</div>
						);
					})}
				</div>
			);
		} else if (typeof data === 'string') {
			return (
				<div className={styles['translation-wrapper']}>
					<Translation>
						{(t) => {
							const value = t(data, { ns: 'locale', default: '' });
							if (data !== value) {
								return <p>{value}</p>;
							} else {
								return <p></p>;
							}
						}}
					</Translation>
				</div>
			);
		}
		return <></>;
	}

	const getUserTranslatedString = (id: number | string): string => {
		const user = userList.find((user) => user.id === id);
		if (user) {
			const person = peopleList.find((person) => person.populatableId === user.id);
			if (person) {
				return getPersonTranslatedString(person.id);
			}
		}
		return 'no name';
	};

	const getPersonTranslatedString = (id: number | string): string => {
		const person = peopleList.find((person) => person.id === id);
		if (person) {
			return currentLanguage === 'jp'
				? `${person.surname} ${person.name}`
				: `${person.name} ${person.surname}`;
		}
		return '';
	};

	const getTranslatedString = (
		translatableType: TranslatableAppFunction,
		id: number | string,
		translationType: TranslationTypes,
	): string => {
		let value = '';
		const string = `${translatableType}-${id}-${translationType}`;
		switch (translatableType) {
			case AppFunction.Person:
				value = getPersonTranslatedString(id);
				break;
			case AppFunction.User:
				value = getUserTranslatedString(id);
				break;
			default:
				value = t(string, { ns: 'locale' });
		}
		return value !== string ? value : '';
	};

	const getI18NString = (
		translatableType: TranslatableAppFunction,
		id: number | string,
		translationType: TranslationTypes,
	): string => {
		return `${translatableType}-${id}-${translationType}`;
	};

	const getI18NStrings = (
		translatableType: TranslatableAppFunction,
		ids: number[],
		translationType: TranslationTypes,
	): string[] => {
		return ids.map((id) => `${translatableType}-${id}-${translationType}`);
	};

	// yeah maybe move all this shit
	const getFixedTranslation = (value: string, type: FixedTranlsationType): string => {
		let newValue = '';
		switch (type) {
			case FixedTranlsationType.dayOfMonth: {
				const values = value.split('.');
				if (values.length === 2) {
					if (+values[1] === 31) {
						newValue = t('dates.endOfMonth');
					} else {
						newValue = t(`dates.${values[0]}`, { day: values[1] });
					}
				}
				break;
			}
			case FixedTranlsationType.monthDelay: {
				const values = value.split('.');
				if (values.length === 2) {
					if (+values[1] === 1) {
						newValue = t('dates.monthDelay');
					} else if (+values[1] === 0) {
						newValue = t('dates.sameMonth');
					} else {
						newValue = t(`dates.${values[0]}_other`, { month: values[1] });
					}
				}
				break;
			}
		}

		return newValue;
	};

	// TODO: duplicate
	const getCurrentLanguage = (): string => i18n.language;

	return {
		currentLanguage,
		renderI18NField,
		getTranslatedString,
		getI18NString,
		getI18NStrings,
		getFixedTranslation,
		getCurrentLanguage,
	};
}

export default useLocale;
