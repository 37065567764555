import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/index';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { PersonJobTitleResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control } = useGenericForm<PersonJobTitleResponse>();
	const { t } = useTranslation();

	return (
		<Grid
			container
			spacing={2}
			minHeight='14rem'
			display='flex'
			flexDirection='column'
			justifyContent='flex-start'
		>
			<Grid item xs={12} flexShrink={1}>
				<TabInnerTitle title='merchant.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='mandatory'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<FormControlLabel
							control={
								<Switch
									{...field}
									value={field.value ?? false}
									checked={field.value ?? false}
									onChange={(ev) => field.onChange(ev.target.checked)}
								/>
							}
							label={t('jobTitle.mandatory')}
						/>
					</InputGridField>
				)}
			/>
		</Grid>
	);
};
