import { useOrderEditor } from '@contexts/index';
import useInventory from '@hooks/useInventory';
import useLocale from '@hooks/useLocale';
import { Box } from '@mui/material';
import { AppFunction, IngredientVariant, TranslationTypes } from 'common';
import { useEffect } from 'react';

interface Props {
	subOrderCode: string;
	automatic: boolean;
	ingredient: IngredientVariant;
}

export const ProcessingIngredientRow = ({ subOrderCode, automatic, ingredient }: Props) => {
	const { changeOrderProduct, getValues } = useOrderEditor();
	const { getTranslatedString } = useLocale();
	const { findAllInventoryEntriesByVariantId } = useInventory();

	useEffect(() => {
		console.log('ProcessingIngredientRow', ingredient);
	}, [ingredient, automatic]);

	return (
		<Box p='0.5rem'>
			{automatic && 'auto'}
			{getTranslatedString(AppFunction.Variant, ingredient.variantId, TranslationTypes.name)}
			{findAllInventoryEntriesByVariantId(ingredient.variantId).map((inv, invIdx) => (
				<Box key={invIdx} p='0.5rem'>
					{getTranslatedString(AppFunction.Location, inv.warehouseId, TranslationTypes.name)}{' '}
					{inv.quantity} {inv.unit}
				</Box>
			))}
		</Box>
	);
};
