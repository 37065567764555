import { EditShowGridField } from '@components/common/EditShowGridField';
import {
	DirectionType,
	createAdornment,
} from '@components/taskworkflow/chain/DestinationTypeSelector';
import { useChainArchetypeEditor } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, OrderDirectionType, StorageType, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
	directionType: DirectionType;
}

export const WarehouseSelector = ({ directionType }: Props) => {
	const { triggerControl, setTriggerValue, watchTrigger } = useChainArchetypeEditor();
	const { warehouseList } = useWarehouses();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const currentType = directionType === 'from' ? watchTrigger('fromType') : watchTrigger('toType');
	const currentSubType =
		directionType === 'from' ? watchTrigger('fromSubType') : watchTrigger('toSubType');
	const currentId = directionType === 'from' ? watchTrigger('fromId') : watchTrigger('toId');

	if (currentType !== OrderDirectionType.warehouse && currentId !== -1) {
		return null;
	}

	return (
		<>
			{(currentSubType === null || currentSubType === -1) && (
				<Controller
					name={directionType === 'from' ? 'fromId' : 'toId'}
					control={triggerControl}
					render={({ field }) => (
						<EditShowGridField isEditing={true} width={12}>
							<InputLabel id='warehouse-select-label'>
								{t(`appFunctions.${AppFunction.Location}`)}
							</InputLabel>
							<Select
								id='warehouse-select'
								fullWidth
								value={field.value ?? -1}
								label={t(`appFunctions.${AppFunction.Location}`)}
								labelId='warehouse-select-label'
								size='small'
								onChange={(e) => {
									const value = e.target.value as number;
									if (!value || isNaN(value)) {
										field.onChange(null);
										return;
									}
									if (value === -1) {
										setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
									} else {
										console.log('changing');
										setTriggerValue(
											directionType === 'from' ? 'fromType' : 'toType',
											OrderDirectionType.warehouse,
										);
									}
									field.onChange(value);
								}}
								endAdornment={createAdornment(field.value ?? -1, () => {
									setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
									field.onChange(-1);
								})}
							>
								<MenuItem
									value={-1}
									sx={{
										backgroundColor: 'rgba(255, 0, 0, 0.2)',
									}}
								>
									{t('common.none')}
								</MenuItem>
								<MenuItem
									value={-2}
									sx={{
										backgroundColor: 'rgba(0, 255, 0, 0.2)',
									}}
								>
									{t('common.all')}
								</MenuItem>
								{warehouseList.map((warehouse) => (
									<MenuItem key={`warehouse_${warehouse.id}`} value={warehouse.id}>
										{getTranslatedString(AppFunction.Location, warehouse.id, TranslationTypes.name)}
									</MenuItem>
								))}
							</Select>
						</EditShowGridField>
					)}
				/>
			)}
			{currentId === -1 && (
				<Controller
					name={directionType === 'from' ? 'fromSubType' : 'toSubType'}
					control={triggerControl}
					render={({ field }) => (
						<EditShowGridField isEditing={true} width={12}>
							<InputLabel id='warehouse-type-select-label'>{t('location.storageType')}</InputLabel>
							<Select
								id='warehouse-type-select'
								fullWidth
								value={field.value ?? -1}
								label={t('location.storageType')}
								labelId='warehouse-type-select-label'
								size='small'
								onChange={(e) => {
									const value = e.target.value as OrderDirectionType;
									setTriggerValue(
										directionType === 'from' ? 'fromType' : 'toType',
										OrderDirectionType.warehouse,
									);
									field.onChange(value);
								}}
								endAdornment={createAdornment((field.value as string) ?? -1, () => {
									setTriggerValue(
										directionType === 'from' ? 'fromType' : 'toType',
										OrderDirectionType.warehouse,
									);
									field.onChange(-1);
								})}
							>
								<MenuItem value={-1}>{t('common.none')}</MenuItem>
								{Object.keys(StorageType).map((key) => (
									<MenuItem key={key} value={key}>
										{t(`${AppFunction.Location}.storageTypes.${key}`)}
									</MenuItem>
								))}
							</Select>
						</EditShowGridField>
					)}
				/>
			)}
		</>
	);
};
