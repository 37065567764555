import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

interface LocationTimeZoneContextProps {
	children: ReactNode;
}

interface LocationTimeZoneContextType {
	timeZone: string;
	dateFormat: string;
	setTimeZone: (timeZone: string) => void;
	latitude: number | null;
	longitude: number | null;
	error: string | null;
}

const defaultContext = {
	timeZone: 'UTC',
	dateFormat: 'YYYY/MM/DD',
	setTimeZone: () => console.warn('no location timezone provider'),
	latitude: null,
	longitude: null,
	error: null,
};

type GeolocationPosition = globalThis.GeolocationPosition;

const LocationTimeZoneContext = createContext<LocationTimeZoneContextType>(defaultContext);

export const LocationTimeZoneProvider = ({ children }: LocationTimeZoneContextProps) => {
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);
	const [timeZone, setTimeZone] = useState<string>('UTC');
	const [error, setError] = useState<string | null>(null);
	const [dateFormat, setDateFormat] = useState<string>('YYYY/DD/MM');
	const { i18n } = useTranslation();

	useEffect(() => {
		if (!navigator.geolocation) {
			setError('Geolocation is not supported by your browser');
			return;
		}

		const success = (position: GeolocationPosition) => {
			const lat = position.coords.latitude;
			const lon = position.coords.longitude;
			setLatitude(lat);
			setLongitude(lon);

			// Determine the time zone using the latitude and longitude
			const timeZone = moment.tz.guess();
			console.log(timeZone);
			setTimeZone(timeZone);
		};

		const error = () => {
			setError('Unable to retrieve your location');
		};

		navigator.geolocation.getCurrentPosition(success, error);
	}, []);

	useEffect(() => {
		switch (i18n.language) {
			case 'en':
				setDateFormat('MM/DD/YYYY');
				break;
			case 'it':
				setDateFormat('DD/MM/YYYY');
				break;
			case 'jp':
				setDateFormat('YYYY/MM/DD');
				break;
			default:
				setDateFormat('DD/MM/YYYY');
				break;
		}
	}, [i18n.language]);

	const contextValue = {
		timeZone,
		dateFormat,
		setTimeZone,
		latitude,
		longitude,
		error,
	};

	return (
		<LocationTimeZoneContext.Provider value={contextValue}>
			{children}
		</LocationTimeZoneContext.Provider>
	);
};

export const useLocationTimeZone = () => useContext(LocationTimeZoneContext);
