import useAppFunctions from '@hooks/useAppFunctions';
import useTasks from '@hooks/useTasks';
import { Box, Button, Grid, Stack, TextField, Tooltip, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AppFunction, ChainArchetypeResponse, OrderBaseRequest } from 'common';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ChainCompletionTimeChip } from '@components/common/chips/ChainCompletionTimeChip';

interface Props {
	loading: boolean;
	currentChainArchetype?: ChainArchetypeResponse | null;
	control?: Control<OrderBaseRequest>;
	changeEad?: (date: Date) => void;
	minDate?: moment.Moment | null;
	value?: moment.Moment | null;
	hideEad?: boolean;
	injection?: JSX.Element;
}

export const OrderTimeScheduler = ({
	loading,
	currentChainArchetype,
	control,
	changeEad,
	minDate,
	value,
	hideEad,
	injection,
}: Props) => {
	const { taskArchetypesList, taskFieldArchetypeList } = useTasks();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const theme = useTheme();

	const getIconsForTaskArchetype = (taskArchetypeIds: number[]): JSX.Element[] => {
		const tooltips: JSX.Element[] = [];
		taskArchetypeIds.map((taskArchetypeId, it) => {
			const task = taskArchetypesList.find((task) => task.id === taskArchetypeId);
			if (task) {
				task.taskFieldArchetypeId?.map((fieldId, fieldIt) => {
					const field = taskFieldArchetypeList.find((field) => field.id === fieldId);
					if (field) {
						const icon = getIcon(AppFunction.TaskFieldArchetype, field.type);
						if (icon) {
							tooltips.push(
								<Tooltip
									key={`${it}-${fieldIt}`}
									title={t(`${AppFunction.TaskFieldArchetype}.types.${field.type}`)}
								>
									{icon}
								</Tooltip>,
							);
						}
					}
				});
			}
		});
		return tooltips;
	};

	return (
		<Grid
			item
			xs={12}
			container
			sx={{
				background: theme.palette.gradient.primaryAlpha,
				border: '1px solid ' + theme.palette.primary.main,
				borderRadius: '1rem',
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				padding: '1rem',
				marginLeft: '1rem',
			}}
		>
			{loading && (
				<Box
					sx={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						backdropFilter: 'blur(5px)',
						zIndex: 1,
						borderRadius: '1rem',
					}}
				/>
			)}
			{currentChainArchetype ? (
				<>
					<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
						<Button
							size='small'
							variant='contained'
							sx={{
								fontWeight: 'bold',
								textTransform: 'none',
								borderRadius: '1rem',
							}}
						>
							{currentChainArchetype.name}
						</Button>
						<Stack direction='row' spacing={1}>
							{getIconsForTaskArchetype(currentChainArchetype.taskArchetypeIds ?? [])}
						</Stack>
					</Grid>
					<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
						<ChainCompletionTimeChip timeString={currentChainArchetype.estimateCompletionTime} />
					</Grid>
					{injection && (
						<Grid container columnSpacing={1} display='flex' item flexGrow={2} flexBasis={0}>
							{injection}
						</Grid>
					)}
					{control && !hideEad ? (
						<Grid
							item
							flexGrow={1}
							flexBasis={0}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								paddingLeft: '1rem',
							}}
						>
							<Controller
								name='ead'
								control={control}
								render={({ field }) => (
									<DatePicker
										label={t('order.estimatedTime')}
										value={field.value ? moment(field.value) : null}
										disablePast
										onChange={(newValue) => newValue && field.onChange(newValue.toDate())}
										renderInput={(params) => (
											<TextField
												{...params}
												size='small'
												variant='outlined'
												fullWidth
												sx={{ backgroundColor: 'white' }}
											/>
										)}
										PopperProps={{ style: { zIndex: 9999 } }}
									/>
								)}
							/>
						</Grid>
					) : !control && changeEad && value && !hideEad ? (
						<Grid
							item
							flexGrow={1}
							flexBasis={0}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								paddingLeft: '1rem',
							}}
						>
							<DatePicker
								label={t('order.estimatedTime')}
								value={value}
								disablePast
								onChange={(newValue) => newValue && changeEad(newValue.toDate())}
								minDate={minDate}
								renderInput={(params) => (
									<TextField
										{...params}
										size='small'
										variant='outlined'
										sx={{ backgroundColor: 'white' }}
									/>
								)}
								PopperProps={{ style: { zIndex: 9999 } }}
							/>
						</Grid>
					) : null}
				</>
			) : (
				<>関連づけているチェーンが見つかりません。オーダーを作成してもタスクは発生しない。</>
			)}
		</Grid>
	);
};
