import { AddressComponent } from '@components/addresses/AddressComponent';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useAddresses from '@hooks/useAddresses';
import { Add } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AddressTab = () => {
	const { entityId, appFunction, formAction } = useGenericForm();
	const { t } = useTranslation();
	const { addressList, createEmptyAddress } = useAddresses();

	const [isCreating, setCreating] = useState(false);
	const [expandedId, setExpandedId] = useState<number | null>(null);
	const [full, setFull] = useState(false);

	const editDisabled = formAction === 'view';

	const Addresses = useMemo(() => {
		if (addressList.length <= 0) {
			return <>No Addresses</>;
		} else {
			const list: JSX.Element[] = [];
			addressList
				.filter((x) => x.addressableId === entityId && x.type === appFunction)
				.map((x, it) =>
					list.push(
						<AddressComponent
							key={it}
							address={x}
							// chips={chips}
							// onAfterDelete={onAfterDeleteUsedAddress}
							canEdit={!editDisabled}
							expandedId={expandedId}
							onExpand={(id: number | null) => {
								setExpandedId(id);
								setCreating(false);
							}}
						/>,
					),
				);
			// if (maxAddresses && list.length >= maxAddresses) {
			// 	setFull(true);
			// } else {
			// 	setFull(false);
			// }
			if (list.length > 0) {
				return <Stack spacing={2}>{list}</Stack>;
			}
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>{t('address.noAddresses')}</div>
			);
		}
	}, [addressList, formAction]);

	if (!appFunction) return null;

	return (
		<Grid
			container
			sx={{
				margin: '2rem 0 2rem 0',
				padding: '0 2rem 2rem 2rem',
				borderRadius: '2rem',
				backgroundColor: 'white',
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TabInnerTitle title='address.address_other' translate size='small' />
					{Addresses}
				</Grid>
				{isCreating ? (
					<Grid item xs={12} sx={{ position: 'relative' }}>
						<AddressComponent
							address={createEmptyAddress(appFunction, entityId)}
							canEdit={!editDisabled}
							onStopCreating={() => setCreating(false)}
							expandedId={expandedId ?? -1}
							onExpand={(id: number | null) => setExpandedId(id)}
						/>
					</Grid>
				) : !full && !editDisabled ? (
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<AnimatedIconButton
							text='operations.create'
							colorType='info'
							translate
							icon={<Add />}
							onClick={() => setCreating(true)}
						/>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	);
};
