import API from '../index';
import { AxiosResponse } from 'axios';
import { IApiBulkRequestWithOptions, IApiResponse } from './types';

const prefix = '/api/v1/';

/**
 * Execute API call (bulk create with options)
 * @param {Req} params
 * @param {string} url
 * @returns
 */
export const execute = async <Req extends IApiBulkRequestWithOptions, Res>(
	params: Req,
	url: string,
): Promise<Res> => {
	const response: AxiosResponse<IApiResponse<Res>> = await API.post(`${prefix}${url}`, params);

	if (response.status < 200 || response.status >= 300) {
		throw new Error(response.data as unknown as string);
	}

	if ('payload' in response.data) {
		return response.data.payload;
	} else {
		throw new Error('Payload does not exist in the response');
	}
};
