import { doListCustomers, doListCustomerTypes } from './customers';
import { doListWarehouses } from './warehouses';
import { doFetchLocale } from './app';
import { doListMerchants } from './merchants';
import { doListCurrencies, doListProducts } from './products';
import {
	doListCarriers,
	doListDeliveryAreas,
	doListDeliveryPlans,
	doListDeliveryPrices,
	doListDeliveryTimes,
} from './carriers';
import { doListUsers } from './user';
import { doListOrders, doListTemplates } from './order';
import { AsyncThunk } from '@reduxjs/toolkit';
import { doListAddresses } from './addresses';
import { doListCountries, doListRegions, doListRegionTypes } from './geography';
import { AppFunction } from 'common';
import { doListJobTitles, doListPeople } from './people';
import { doListImages } from './images';
import {
	doFetchChains,
	doFetchTasks,
	doListTaskArchetypes,
	doListTaskChainArchetypes,
	doListTaskFieldArchetypes,
	doListTaskFieldParameters,
	doListTaskFieldSetsArchetypes,
	doListTriggers,
} from './tasks';
import { doListEntitiesEntityGroup, doListGroups } from './groups';
import { doListSections } from './role';
import { doFetchAllInventoryStates } from './inventory';
import { doFetchNotes } from './notes';

type FetchMap = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key in AppFunction]?: AsyncThunk<any, void, any>;
};

export const fetchMap: FetchMap = {
	[AppFunction.String]: doFetchLocale,
	[AppFunction.User]: doListUsers,
	[AppFunction.Customer]: doListCustomers,
	[AppFunction.CustomerType]: doListCustomerTypes,
	[AppFunction.Location]: doListWarehouses,
	[AppFunction.Merchant]: doListMerchants,
	[AppFunction.Carrier]: doListCarriers,
	[AppFunction.DeliveryTime]: doListDeliveryTimes,
	[AppFunction.CarrierPlan]: doListDeliveryPlans,
	[AppFunction.CarrierPrice]: doListDeliveryPrices,
	[AppFunction.DeliveryArea]: doListDeliveryAreas,
	[AppFunction.CarrierDeliveryArea]: doListDeliveryAreas,
	[AppFunction.Product]: doListProducts,
	[AppFunction.Currency]: doListCurrencies,
	[AppFunction.Address]: doListAddresses,
	[AppFunction.Country]: doListCountries,
	[AppFunction.Region]: doListRegions,
	[AppFunction.RegionType]: doListRegionTypes,
	[AppFunction.Template]: doListTemplates,
	[AppFunction.JobTitle]: doListJobTitles,
	[AppFunction.Person]: doListPeople,
	[AppFunction.Image]: doListImages,
	[AppFunction.TaskFieldArchetype]: doListTaskFieldArchetypes,
	[AppFunction.TaskChainArchetype]: doListTaskChainArchetypes,
	[AppFunction.TaskFieldParameter]: doListTaskFieldParameters,
	[AppFunction.Trigger]: doListTriggers,
	[AppFunction.EntityGroup]: doListGroups,
	[AppFunction.EntityEntityGroup]: doListEntitiesEntityGroup,
	[AppFunction.TaskFieldSetArchetype]: doListTaskFieldSetsArchetypes,
	[AppFunction.TaskArchetype]: doListTaskArchetypes,
	[AppFunction.Role]: doListSections,
	[AppFunction.Task]: doFetchTasks,
	[AppFunction.Chain]: doFetchChains,
	[AppFunction.Order]: doListOrders,
	[AppFunction.Inventory]: doFetchAllInventoryStates,
	[AppFunction.Note]: doFetchNotes,
};
