import { Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import { Calendar } from './pages/Calendar';
import { ProductsList } from './pages/Products/ProductsList';
import { Inventory } from './pages/Inventory/InventoryList';
import { OrderCreate } from './pages/Orders/OrderCreate';
import { CustomersList } from './pages/Customers/CustomersList';
import { OrderList } from './pages/Orders/OrderList';
import { Settings } from './pages/Settings';
import { OrderType, ProductType } from 'common';
import { TaskFieldArchetypesList } from './pages/Tasks/TaskFieldArchetypeList';
import { ChainArchetypesList } from './pages/Tasks/ChainArchetypesList';
import { GroupsList } from './pages/Groups/GroupsList';
import { TaskFieldSetList } from './pages/Tasks/TaskFieldSetList';
import { TaskFieldSetCreate } from './pages/Tasks/TaskFieldSetCreate';
import { TaskChainArchetypeCreate } from './pages/Tasks/TaskChainArchetypeCreate';
import { SectionList } from './pages/Users/SectionList';
import { CustomerForm } from '@pages/Customers/CustomerForm';
import { UserForm } from '@pages/Users/UserForm';
import { ProductForm } from '@pages/Products/ProductForm';
import { VariantForm } from '@pages/Products/VariantForm';
import { RoleForm } from '@pages/Users/RoleForm';
import { CarrierForm } from '@pages/Carriers/CarrierForm';
import { ScheduledEventsList } from '@pages/Schedules/ScheduledEventsList';
import { ControlRoom } from '@pages/SuperAdmin/ControlRoom';
import { MerchantForm } from '@pages/Merchants/MerchantForm';
import { TaskFieldArchetypeForm } from '@pages/Tasks/TaskFieldArchetypeForm';
import { LocationForm } from '@pages/Warehouses/LocationForm';
import { CustomerTypeForm } from '@pages/Customers/CustomerTypeForm';
import JobTitleForm from '@pages/People/JobTitleForm';
import GroupForm from '@pages/Groups/GroupForm';
import CountryForm from '@pages/Countries/CountryForm';
import { OrderModify } from '@pages/Orders/OrderModify';
import { TaskArchetypeList } from '@pages/Tasks/TaskArchetypeList';
import { TaskFieldParameterForm } from '@pages/Tasks/TaskFieldParameterForm';
import { TaskArchetypeForm } from '@pages/Tasks/TaskArchetypeForm';

const routes = [
	{
		href: '/',
		name: 'Home',
		element: <Home />,
	},
	{
		href: '/calendar',
		name: 'Calendar',
		element: <Calendar />,
	},
	{
		href: '/product',
		name: 'ProductsList',
		element: <ProductsList productType={ProductType.product} />,
	},
	{
		href: '/variant',
		name: 'VariantList',
		element: <ProductsList productType={ProductType.product} />,
	},
	{
		href: '/packaging',
		name: 'PackagingList',
		element: <ProductsList productType={ProductType.packaging} />,
	},
	{
		href: '/customer',
		name: 'CustomersList',
		element: <CustomersList />,
	},
	{
		href: '/customer/create',
		name: 'CustomerCreate',
		element: <CustomerForm formAction='create' />,
	},
	{
		href: '/customer/:id/edit',
		name: 'CustomerEdit',
		element: <CustomerForm formAction='edit' />,
	},
	{
		href: '/customer/:id',
		name: 'CustomerView',
		element: <CustomerForm formAction='view' />,
	},
	{
		href: '/product/create',
		name: 'ProductCreate',
		element: <ProductForm productType={ProductType.product} formAction='create' />,
	},
	{
		href: '/product/:id/edit',
		name: 'ProductEdit',
		element: <ProductForm productType={ProductType.product} formAction='edit' />,
	},
	{
		href: '/product/:id',
		name: 'ProductView',
		element: <ProductForm productType={ProductType.product} formAction='view' />,
	},
	{
		href: '/product/:productId/create',
		name: 'ProductVariantCreate',
		element: <VariantForm productType={ProductType.product} formAction='create' />,
	},
	{
		href: '/product/:productId/edit/:variantId',
		name: 'ProductVariantEdit',
		element: <VariantForm productType={ProductType.product} formAction='edit' />,
	},
	{
		href: '/product/:productId/:variantId',
		name: 'ProductVariantShow',
		element: <VariantForm productType={ProductType.product} formAction='view' />,
	},
	{
		href: '/packaging/create',
		name: 'PackagingCreate',
		element: <ProductForm productType={ProductType.packaging} formAction='create' />,
	},
	{
		href: '/packaging/:id/edit',
		name: 'PackagingEdit',
		element: <ProductForm productType={ProductType.packaging} formAction='edit' />,
	},
	{
		href: '/packaging/:id',
		name: 'PackagingView',
		element: <ProductForm productType={ProductType.packaging} formAction='view' />,
	},
	{
		href: '/packaging/:productId/create',
		name: 'PackagingVariantCreate',
		element: <VariantForm productType={ProductType.packaging} formAction='create' />,
	},
	{
		href: '/packaging/:productId/edit/:variantId',
		name: 'PackagingVariantEdit',
		element: <VariantForm productType={ProductType.packaging} formAction='edit' />,
	},
	{
		href: '/packaging/:productId/:variantId',
		name: 'PackagingVariantShow',
		element: <VariantForm productType={ProductType.packaging} formAction='view' />,
	},
	{
		href: '/orders',
		name: 'OrderList',
		element: <OrderList />,
	},
	{
		href: '/orders/:id',
		name: 'OrderList',
		element: <OrderList />,
	},
	{
		href: '/orders/modify/:id',
		name: 'OrderEdit',
		element: <OrderModify formAction='edit' />,
	},
	{
		href: '/orders/view/:id',
		name: 'OrderView',
		element: <OrderModify formAction='view' />,
	},
	{
		href: '/orders/create/import',
		name: 'OrderCreate',
		element: <OrderCreate orderType={OrderType.Inbound} />,
	},
	{
		href: '/orders/create/sales',
		name: 'OrderCreate',
		element: <OrderCreate orderType={OrderType.Outbound} />,
	},
	{
		href: '/inventory/move',
		name: 'OrderCreate',
		element: <OrderCreate orderType={OrderType.Internal} />,
	},
	{
		href: '/inventory/process',
		name: 'OrderCreate',
		element: <OrderCreate orderType={OrderType.Processing} />,
	},
	{
		href: '/inventory',
		name: 'Inventory',
		element: <Inventory />,
	},
	{
		href: '/settings/*',
		name: 'Settings',
		element: <Settings />,
	},
	{
		href: '/scheduledEvent/',
		name: 'Scheduled Event',
		element: <ScheduledEventsList />,
	},
	// #region Carriers
	{
		href: '/carrier/create',
		name: 'Create Carrier',
		element: <CarrierForm formAction='create' />,
	},
	{
		href: '/carrier/:id/edit',
		name: 'Edit Carrier',
		element: <CarrierForm formAction='edit' />,
	},
	{
		href: '/carrier/:id/details',
		name: 'View Carrier',
		element: <CarrierForm formAction='view' />,
	},
	// #endregion
	// #region Warehouses
	// create
	{
		href: '/location/create',
		name: 'Create Warehouse',
		element: <LocationForm formAction='create' />,
	},
	// create sub warehouse
	{
		href: '/location/create/:parentId',
		name: 'Create Warehouse',
		element: <LocationForm formAction='create' />,
	},
	// edit
	{
		href: '/location/:id/edit',
		name: 'Edit Warehouse',
		element: <LocationForm formAction='edit' />,
	},
	// view
	{
		href: '/location/:id/details',
		name: 'Warehouse details',
		element: <LocationForm formAction='view' />,
	},
	// #endregion
	// #region Customer Types
	{
		href: '/customertype/:id/edit',
		name: 'Edit Customer Type',
		element: <CustomerTypeForm formAction='edit' />,
	},
	{
		href: '/customertype/create',
		name: 'Create Customer Type',
		element: <CustomerTypeForm formAction='create' />,
	},
	// #endregion
	// #region Merchants
	{
		href: '/merchant/create',
		name: 'Create Merchant',
		element: <MerchantForm formAction='create' />,
	},
	{
		href: '/merchant/:id/edit',
		name: 'Edit Merchant',
		element: <MerchantForm formAction='edit' />,
	},
	{
		href: '/merchant/:id/details',
		name: 'View Merchant',
		element: <MerchantForm formAction='view' />,
	},
	// #endregion
	// #region Geography
	{
		href: '/country/create',
		name: 'Create Country',
		element: <CountryForm formAction='create' />,
	},
	{
		href: '/country/:id/edit',
		name: 'Edit Country',
		element: <CountryForm formAction='edit' />,
	},
	{
		href: '/country/:id/show',
		name: 'Create Country',
		element: <CountryForm formAction='view' />,
	},
	// #endregion
	// #region Job Titles
	{
		href: '/jobtitle/create',
		name: 'Create Job Title',
		element: <JobTitleForm formAction='create' />,
	},
	{
		href: '/jobtitle/:id/edit',
		name: 'Edit Job Title',
		element: <JobTitleForm formAction='edit' />,
	},
	// #endregion
	// #region Users
	{
		href: '/user/create',
		name: 'Create user',
		element: <UserForm formAction='create' />,
	},
	{
		href: '/user/:id/details',
		name: 'Show user',
		element: <UserForm formAction='view' />,
	},
	{
		href: '/user/:id/edit',
		name: 'Edit User',
		element: <UserForm formAction='edit' />,
	},
	// #endregion
	// #region Sections
	{
		href: '/role',
		name: 'Sections List',
		element: <SectionList />,
	},
	{
		href: '/role/create',
		name: 'Create Section',
		element: <RoleForm formAction='create' />,
	},
	{
		href: '/role/:id/details',
		name: 'Show Section',
		element: <RoleForm formAction='view' />,
	},
	{
		href: '/role/:id/edit',
		name: 'Edit Section',
		element: <RoleForm formAction='edit' />,
	},
	// #endregion
	// #region Tasks
	{
		href: '/taskarchetype',
		name: 'Task Archetypes List',
		element: <TaskArchetypeList />,
	},
	{
		href: '/tasks/field/archetypes',
		name: 'Task Field Archetypes List',
		element: <TaskFieldArchetypesList />,
	},
	{
		href: '/taskfieldarchetype/create',
		name: 'Create Task Field Archetype',
		element: <TaskFieldArchetypeForm formAction='create' />,
	},
	{
		href: '/taskfieldarchetype/:id/edit',
		name: 'Edit Task Field Parameters',
		element: <TaskFieldArchetypeForm formAction='edit' />,
	},
	{
		href: '/taskfieldarchetype/:id/details',
		name: 'Edit Task Field Parameters',
		element: <TaskFieldArchetypeForm formAction='view' />,
	},
	{
		href: '/tasks/chain/archetypes',
		name: 'Chain Archetypes List',
		element: <ChainArchetypesList />,
	},
	{
		href: '/taskchainarchetype/create',
		name: 'Create Chain Archetype',
		element: <TaskChainArchetypeCreate />,
	},
	{
		href: '/taskchainarchetype/:id/edit',
		name: 'Edit Chain Archetype',
		element: <TaskChainArchetypeCreate />,
	},
	{
		href: '/taskarchetype/create',
		name: 'Create Task Archetype',
		element: <TaskArchetypeForm formAction='create' />,
	},
	{
		href: '/taskarchetype/:id/edit',
		name: 'Edit Task Archetype',
		element: <TaskArchetypeForm formAction='edit' />,
	},
	{
		href: '/taskarchetype/:id/details',
		name: 'Task Archetype Details',
		element: <TaskArchetypeForm formAction='view' />,
	},
	{
		href: '/taskfieldparameter/create',
		name: 'Create Task Field Parameter',
		element: <TaskFieldParameterForm formAction='create' />,
	},
	{
		href: '/taskfieldparameter/:id/details',
		name: 'Task Field Parameters List',
		element: <TaskFieldParameterForm formAction='view' />,
	},
	{
		href: '/taskfieldparameter/:id/edit',
		name: 'Edit Task Field Parameter',
		element: <TaskFieldParameterForm formAction='edit' />,
	},
	{
		href: '/tasks/field/sets',
		name: 'Task Field Sets List',
		element: <TaskFieldSetList />,
	},
	{
		href: '/taskfieldsetarchetype/create',
		name: 'Create Task Field Set',
		element: <TaskFieldSetCreate />,
	},
	{
		href: '/taskfieldsetarchetype/:id/edit',
		name: 'Edit Task Field Set',
		element: <TaskFieldSetCreate />,
	},
	// #endregion
	// #region Groups
	{
		href: '/entityGroup',
		name: 'Groups List',
		element: <GroupsList />,
	},
	{
		href: '/entityGroup/create',
		name: 'Create Group',
		element: <GroupForm formAction='create' />,
	},
	{
		href: '/entityGroup/:id/edit',
		name: 'Edit Group',
		element: <GroupForm formAction='edit' />,
	},
	{
		href: '/entityGroup/:id',
		name: 'Show Group',
		element: <GroupForm formAction='view' />,
	},
	// #endregion
	{
		href: '/controlRoom',
		name: 'Control Room',
		element: <ControlRoom />,
	},
];

export const AppRoutes = () => (
	<>
		{routes.map((route) => (
			<Route path={route.href} key={route.name} element={route.element} />
		))}
	</>
);

export const RouteObjects = () =>
	routes.map((route) => {
		return {
			path: route.href,
		};
	});
