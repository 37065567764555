import { useMemo, useState } from 'react';
import useLocale from '@hooks/useLocale';
import { Add, ArrowRight, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import {
	AccordionDetails,
	Autocomplete,
	Box,
	Grid,
	IconButton,
	Stack,
	TextField,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
	AppFunction,
	CarrierPriceResponse,
	CarrierPriceSchema,
	CarrierPricesResponse,
	CarrierResponse,
	TranslationTypes,
} from 'common';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useTranslation } from 'react-i18next';
import useCarriers from '@hooks/useCarriers';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useDeliveryPlans } from '@contexts/deliveryPlansContext/DeliveryPlansContext';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import AdvancedAutoComplete from '@components/common/inputs/common/AdvancedAutoComplete';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	price: CarrierPriceResponse;
	subPrices?: CarrierPricesResponse;
	parentPriceId?: number;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion elevation={1} {...props} />)(
	() => ({
		border: '1px solid rgba(0, 0, 0, .050)',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&::before': {
			display: 'none',
		},
	}),
);

interface ExtendedAccordionSumamryProps extends AccordionSummaryProps {
	canExpand?: boolean;
}

const AccordionSummary = styled((props: ExtendedAccordionSumamryProps) => {
	const { canExpand, ...otherProps } = props;
	return (
		<MuiAccordionSummary
			expandIcon={canExpand && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...otherProps}
		/>
	);
})(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
	'&.Mui-focusVisible': {
		backgroundColor:
			theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	},
}));

export const DeliveryPrice = ({ price, subPrices, parentPriceId }: Props) => {
	const { entityId } = useGenericForm<CarrierResponse>();
	const {
		editingPrice,
		creatingPrice,
		callDeleteCarrierPriceDialog,
		setCreatingPrice,
		setEditingPrice,
	} = useDeliveryPlans();
	const { deliveryAreaList, deliveryTimeList, createCarrierPrice, editCarrierPrice } =
		useCarriers();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const [isExpanded, setIsExpanded] = useState(false);

	const isCreatingSubPrice = useMemo(() => {
		if (parentPriceId) {
			return false;
		}
		if (creatingPrice) {
			return (
				creatingPrice.startsWith(price.planId.toString()) &&
				creatingPrice !== price.planId.toString()
			);
		}
		return false;
	}, [price.planId, creatingPrice, parentPriceId]);

	const disabled =
		(!editingPrice ||
			(parentPriceId
				? editingPrice !== `${parentPriceId}-${price.id}`
				: editingPrice?.split('-')[0] !== price.id.toString())) &&
		price.id !== -1;

	const { control, handleSubmit, reset } = useForm<CarrierPriceResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: { ...price },
		resolver: zodResolver(CarrierPriceSchema),
	});

	const onSubmit = async (data: CarrierPriceResponse) => {
		console.log(data);
		if (data.id === -1) {
			await createCarrierPrice(data);
			setCreatingPrice(null);
		} else {
			await editCarrierPrice(data);
			if (parentPriceId) {
				setEditingPrice(parentPriceId.toString());
			} else {
				setEditingPrice(null);
			}
		}
	};

	const expanded: boolean = useMemo(() => {
		return (
			isExpanded ||
			price.id === -1 ||
			editingPrice?.split('-')[0] === price.id.toString() ||
			creatingPrice === `${price.planId}-${parentPriceId ?? price.id}` ||
			false
		);
	}, [isExpanded, price.id, editingPrice, creatingPrice, parentPriceId]);

	if (!parentPriceId) {
		return (
			<Accordion expanded={expanded} onChange={() => setIsExpanded(!isExpanded)}>
				<AccordionSummary canExpand>
					<Box
						sx={{
							width: 'calc(100% - 1rem)',
							marginLeft: '1rem',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
						onClick={(e) => e.stopPropagation()}
					>
						<Stack
							rowGap={2}
							sx={{
								position: 'relative',
								flexGrow: 1,
								flexBasis: 0,
							}}
						>
							<Grid container display='flex' flexDirection='row'>
								<Controller
									name='originAreaId'
									control={control}
									render={({ field }) => (
										<InputGridField
											width='auto'
											flexGrow={1}
											flexBasis={0}
											type={ComponentType.Autocomplete}
										>
											<AdvancedAutoComplete
												options={deliveryAreaList.filter((area) => area.carrierId === entityId)}
												disabled={disabled}
												appFunction={AppFunction.CarrierDeliveryArea}
												value={field.value}
												onChange={field.onChange}
											/>
										</InputGridField>
									)}
								/>
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<ArrowRight />
								</Grid>
								<Controller
									name='destinationAreaId'
									control={control}
									render={({ field }) => (
										<InputGridField
											width='auto'
											flexGrow={1}
											flexBasis={0}
											type={ComponentType.TextField}
										>
											<AdvancedAutoComplete
												options={deliveryAreaList.filter((area) => area.carrierId === entityId)}
												disabled={disabled}
												appFunction={AppFunction.CarrierDeliveryArea}
												value={field.value}
												onChange={field.onChange}
											/>
										</InputGridField>
									)}
								/>
							</Grid>
							<Grid container display='flex' flexDirection='row' columnGap={2.5}>
								<Controller
									name='deliveryOffset'
									control={control}
									render={({ field }) => (
										<InputGridField
											width='auto'
											flexGrow={1}
											flexBasis={0}
											type={ComponentType.TextField}
										>
											<TextField
												value={field.value}
												variant='outlined'
												label={t('carrier.price.deliveryOffset')}
												type='number'
												fullWidth
												size='small'
												disabled={disabled}
												onChange={(e) => field.onChange(+e.target.value)}
												InputProps={{
													inputProps: {
														min: 0,
														...(disabled && { tabIndex: -1 }),
													},
												}}
											/>
										</InputGridField>
									)}
								/>
								<Controller
									name='deliveryTimeId'
									control={control}
									render={({ field }) => (
										<InputGridField
											width='auto'
											flexGrow={1}
											flexBasis={0}
											type={ComponentType.Autocomplete}
										>
											<AdvancedAutoComplete
												options={deliveryTimeList}
												disabled={disabled}
												appFunction={AppFunction.Carrier}
												value={field.value}
												onChange={field.onChange}
												onCustomOptionLabel={(option) => option.startTime + ' - ' + option.endTime}
											/>
										</InputGridField>
									)}
								/>
							</Grid>
						</Stack>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexShrink: 1,
								paddingLeft: '0.5rem',
							}}
						>
							{disabled ? (
								<>
									<IconButton
										color='primary'
										onClick={(e) => {
											setEditingPrice(price.id.toString());
											e.stopPropagation();
										}}
									>
										<Edit />
									</IconButton>
									<IconButton
										color='error'
										onClick={(e) => {
											callDeleteCarrierPriceDialog({ id: price.id });
											e.stopPropagation();
										}}
									>
										<Delete />
									</IconButton>
								</>
							) : (
								<>
									<IconButton
										color='success'
										onClick={(e) => {
											handleSubmit(onSubmit)();
											e.stopPropagation();
										}}
									>
										<Save />
									</IconButton>
									<IconButton
										color='error'
										onClick={(e) => {
											reset(price);
											if (price.id !== -1) {
												setEditingPrice(null);
											}
											setCreatingPrice(null);
											e.stopPropagation();
										}}
									>
										<Cancel />
									</IconButton>
								</>
							)}
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Stack rowGap={2}>
						<Grid
							container
							display='flex'
							flexDirection='row'
							gap={1}
							sx={{
								backgroundColor: '#f0f0f0',
								padding: '1rem',
								borderRadius: '0.5rem',
							}}
						>
							<Controller
								name='weight'
								control={control}
								render={({ field }) => (
									<InputGridField
										width='auto'
										flexGrow={1}
										flexBasis={0}
										type={ComponentType.TextField}
									>
										<TextField
											value={field.value}
											variant='outlined'
											label={t('carrier.price.weight')}
											size='small'
											type='number'
											disabled={disabled}
											InputProps={{
												inputProps: {
													min: 0,
													...(disabled && { tabIndex: -1 }),
												},
											}}
											onChange={(e) => field.onChange(+e.target.value)}
											sx={{
												backgroundColor: 'white',
											}}
										/>
									</InputGridField>
								)}
							/>
							<Controller
								name='size'
								control={control}
								render={({ field }) => (
									<InputGridField
										width='auto'
										flexGrow={1}
										flexBasis={0}
										type={ComponentType.TextField}
									>
										<TextField
											value={field.value}
											variant='outlined'
											label={t('carrier.price.size')}
											size='small'
											type='number'
											disabled={disabled}
											InputProps={{
												inputProps: {
													min: 0,
													...(disabled && { tabIndex: -1 }),
												},
											}}
											onChange={(e) => field.onChange(e.target.value)}
											sx={{
												backgroundColor: 'white',
											}}
										/>
									</InputGridField>
								)}
							/>
							<Controller
								name='price'
								control={control}
								render={({ field }) => (
									<InputGridField
										width='auto'
										flexGrow={1}
										flexBasis={0}
										type={ComponentType.TextField}
									>
										<TextField
											value={field.value}
											variant='outlined'
											label={t('carrier.price.price')}
											size='small'
											type='number'
											disabled={disabled}
											InputProps={{
												inputProps: {
													min: 0,
													...(disabled && { tabIndex: -1 }),
												},
											}}
											onChange={(e) => field.onChange(+e.target.value)}
											sx={{
												backgroundColor: 'white',
											}}
										/>
									</InputGridField>
								)}
							/>
						</Grid>
						{subPrices &&
							subPrices.map((subPrice) => (
								<DeliveryPrice key={subPrice.id} price={subPrice} parentPriceId={price.id} />
							))}
						{isCreatingSubPrice && (
							<DeliveryPrice
								price={{
									id: -1,
									planId: price.planId,
									originAreaId: price.originAreaId,
									destinationAreaId: price.destinationAreaId,
									price: 0,
									deliveryOffset: 1,
									deliveryTimeId: 1,
									size: '',
									weight: 0,
								}}
								parentPriceId={price.id}
							/>
						)}
						{editingPrice?.startsWith(price.id.toString()) && !isCreatingSubPrice && (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<AnimatedIconButton
									text={t('operations.create')}
									icon={<Add />}
									colorType='info'
									onClick={() => setCreatingPrice(`${price.planId}-${price.id}`)}
								/>
							</Box>
						)}
					</Stack>
				</AccordionDetails>
			</Accordion>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
			}}
		>
			<Grid
				container
				display='flex'
				flexDirection='row'
				gap={1}
				sx={{
					backgroundColor: '#f0f0f0',
					padding: '1rem',
					borderRadius: '0.5rem',
					flexGrow: 1,
					flexBasis: 0,
				}}
			>
				<Controller
					name='weight'
					control={control}
					render={({ field }) => (
						<InputGridField width='auto' flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
							<TextField
								value={field.value}
								variant='outlined'
								label={t('carrier.price.weight')}
								size='small'
								type='number'
								disabled={disabled}
								InputProps={{
									inputProps: {
										min: 0,
										...(disabled && { tabIndex: -1 }),
									},
								}}
								onChange={(e) => field.onChange(+e.target.value)}
								sx={{
									backgroundColor: 'white',
								}}
							/>
						</InputGridField>
					)}
				/>
				<Controller
					name='size'
					control={control}
					render={({ field }) => (
						<InputGridField width='auto' flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
							<TextField
								value={field.value}
								variant='outlined'
								label={t('carrier.price.size')}
								size='small'
								type='number'
								disabled={disabled}
								InputProps={{
									inputProps: {
										min: 0,
										...(disabled && { tabIndex: -1 }),
									},
								}}
								onChange={(e) => field.onChange(e.target.value)}
								sx={{
									backgroundColor: 'white',
								}}
							/>
						</InputGridField>
					)}
				/>
				<Controller
					name='price'
					control={control}
					render={({ field }) => (
						<InputGridField width='auto' flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
							<TextField
								value={field.value}
								variant='outlined'
								label={t('carrier.price.price')}
								size='small'
								type='number'
								disabled={disabled}
								InputProps={{
									inputProps: {
										min: 0,
										...(disabled && { tabIndex: -1 }),
									},
								}}
								onChange={(e) => field.onChange(+e.target.value)}
								sx={{
									backgroundColor: 'white',
								}}
							/>
						</InputGridField>
					)}
				/>
			</Grid>
			{editingPrice?.split('-')[0] === parentPriceId.toString() && (
				<Box
					sx={{
						paddingLeft: '1rem',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexShrink: 1,
					}}
				>
					{editingPrice === `${parentPriceId}-${price.id}` || price.id === -1 ? (
						<>
							<IconButton size='small' onClick={handleSubmit(onSubmit)}>
								<Save fontSize='small' />
							</IconButton>
							<IconButton
								size='small'
								onClick={() => {
									reset(price);
									if (creatingPrice === `${price.planId}-${parentPriceId}`) {
										setCreatingPrice(null);
									} else {
										setEditingPrice(parentPriceId.toString());
									}
								}}
							>
								<Cancel fontSize='small' />
							</IconButton>
						</>
					) : (
						<>
							<IconButton
								size='small'
								onClick={() => setEditingPrice(`${parentPriceId}-${price.id}`)}
							>
								<Edit fontSize='small' />
							</IconButton>
							<IconButton
								size='small'
								onClick={() => {
									callDeleteCarrierPriceDialog({ id: price.id });
								}}
							>
								<Delete fontSize='small' />
							</IconButton>
						</>
					)}
				</Box>
			)}
		</Box>
	);
};
