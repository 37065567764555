import { useEffect } from 'react';
import { t } from 'i18next';

import { TableContextProvider, useLayout, useFeedbacks } from '@contexts/index';
import usePeople from '@hooks/usePeople';
import useLocale from '@hooks/useLocale';

import { Badge, Settings } from '@mui/icons-material';
import { Column } from 'primereact/column';

import { AppFunction, GenericIdRequest, TranslationTypes } from 'common';
import { TableBoolBody } from '@components/common/tablecells/TableBoolBody';
import { DropDownActionType } from '@contexts/tableContext/types';

export const JobTitleList = () => {
	const { jobTitlesList, deleteJobTitle } = usePeople();
	const { pushDialog } = useFeedbacks();
	const { renderI18NField, getI18NString, getTranslatedString } = useLocale();
	const { setBreadCrumbs } = useLayout();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.jobTitles',
				icon: <Badge />,
			},
		]);
	}, []);

	function callDeleteJobTitleDialog(data: GenericIdRequest) {
		const jobTitleName = getTranslatedString(AppFunction.JobTitle, data.id, TranslationTypes.name);
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('dialog.confirmDelete', { deleting: jobTitleName }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteJobTitle(data).then((response) => {
							if (response) {
								console.log('deleted');
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			pageIdentifier={AppFunction.JobTitle}
			values={jobTitlesList}
			actions={[
				{
					type: DropDownActionType.edit,
					customLink: '/jobtitle/:id/edit',
				},
				{
					type: DropDownActionType.details,
					customLink: '/jobtitle/:id',
				},
				{
					type: DropDownActionType.delete,
					action: callDeleteJobTitleDialog,
				},
			]}
		>
			<Column
				field='name'
				header='name'
				body={(data) =>
					renderI18NField(getI18NString(AppFunction.JobTitle, data.id, TranslationTypes.name))
				}
			/>
			<Column
				field='mandatory'
				header='mandatory'
				body={(data) => <TableBoolBody value={data.mandatory} />}
			/>
		</TableContextProvider>
	);
};
