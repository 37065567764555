import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	PeopleResponse,
	PersonJobTitleRequest,
	PersonJobTitleResponse,
	PersonJobTitlesResponse,
	PersonRequest,
	PersonResponse,
	PersonToJobTitleLinkRequest,
} from 'common';

export interface PeopleState {
	people: PeopleResponse;
	jobTitles: PersonJobTitlesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: PeopleState = {
	people: [],
	jobTitles: [],
	op_status: 'idle',
};

export const doListPeople = api.apiList<PeopleResponse>('people/list', 'people');
export const doCreatePerson = api.apiCreate<PersonRequest, PersonResponse>(
	'person/create',
	'people',
);
export const doEditPerson = api.apiEdit<PersonResponse, AffectedRowsResponse>(
	'person/edit',
	'people',
);
export const doDeletePerson = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'person/delete',
	'people',
);

export const doListJobTitles = api.apiList<PersonJobTitlesResponse>(
	'people/jobtitles/list',
	'people/jobtitles',
);
export const doCreateJobTitle = api.apiCreate<PersonJobTitleRequest, PersonJobTitleResponse>(
	'people/jobtitles/create',
	'people/jobtitle',
);
export const doEditJobTitle = api.apiEdit<PersonJobTitleResponse, AffectedRowsResponse>(
	'people/jobtitles/edit',
	'people/jobtitle',
);
export const doDeleteJobTitle = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'people/jobtitles/delete',
	'people/jobtitle',
);

export const doLinkPersonToJobTitle = api.apiCreate<
	PersonToJobTitleLinkRequest,
	AffectedRowsResponse
>('people/jobtitles/link', 'people/:personId/link/:jobTitleId');

export const doUnlinkPersonFromJobTitle = api.apiCreate<
	PersonToJobTitleLinkRequest,
	AffectedRowsResponse
>('people/jobtitles/unlink', 'people/:personId/unlink/:jobTitleId');

export const PeopleSlice = createSlice({
	name: 'people',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(doListPeople.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListPeople.fulfilled, (state, action) => {
			state.people = action.payload as PeopleResponse;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListPeople.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreatePerson.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreatePerson.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreatePerson.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditPerson.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditPerson.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditPerson.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeletePerson.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeletePerson.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeletePerson.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doListJobTitles.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListJobTitles.fulfilled, (state, action) => {
			state.jobTitles = action.payload as PersonJobTitlesResponse;
			state.op_status = 'succeeded';
		});
		builder.addCase(doListJobTitles.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateJobTitle.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateJobTitle.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateJobTitle.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditJobTitle.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditJobTitle.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditJobTitle.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeleteJobTitle.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteJobTitle.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteJobTitle.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doLinkPersonToJobTitle.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doLinkPersonToJobTitle.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doLinkPersonToJobTitle.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doUnlinkPersonFromJobTitle.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doUnlinkPersonFromJobTitle.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doUnlinkPersonFromJobTitle.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export default PeopleSlice.reducer;
