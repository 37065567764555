import { LocaleEntries, LocaleEntryAction, TranslationTypes } from 'common';

// ADD ignoreTypes: TranslationTypes[] to prevent that translation types modified elsewhere (if present)
// are not touched (if not present in newStrings but present in oldStrings are not flagged as DELETE)
function processLocaleEntries(
	newStrings: LocaleEntries,
	oldStrings: LocaleEntries,
	ignoreTypes?: TranslationTypes[],
): LocaleEntries {
	const ignore: TranslationTypes[] = ignoreTypes ?? [];
	return newStrings
		.map((newEntry) => {
			// console.log(newStrings, oldStrings);
			const matchingEntry = oldStrings.find(
				(oldEntry) => oldEntry.langCode === newEntry.langCode && oldEntry.type === newEntry.type,
			);

			if (newEntry.value === '') {
				return { ...newEntry, action: LocaleEntryAction.NONE };
			}

			if (!matchingEntry) {
				return { ...newEntry, action: LocaleEntryAction.CREATE };
			} else if (matchingEntry.value !== newEntry.value) {
				return { ...newEntry, action: LocaleEntryAction.MODIFY };
			}

			return { ...newEntry, action: LocaleEntryAction.NONE };
		})
		.concat(
			oldStrings
				.filter(
					(oldEntry) =>
						!newStrings.some(
							(newEntry) =>
								newEntry.langCode === oldEntry.langCode && oldEntry.type === newEntry.type,
						),
				)
				.map((oldEntry) => ({
					...oldEntry,
					value: '',
					action: ignore.includes(oldEntry.type)
						? LocaleEntryAction.NONE
						: LocaleEntryAction.DELETE,
				})),
		);
}

export default processLocaleEntries;
