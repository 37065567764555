import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useUserForm } from '@contexts/formContext/subContexts/UserFormContext';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Person = () => {
	const { control, editDisabled } = useUserForm();
	const { t } = useTranslation();

	return (
		<>
			<Controller
				name='name'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='user-person-name'
							label={t('person.name')}
							size='small'
							disabled={editDisabled}
							fullWidth
							// error={!!errors.name}
							// helperText={errors.name && errors.name.message}
							value={field.value ?? ''}
							onChange={(ev) => field.onChange(ev.target.value ?? '')}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='surname'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='user-person-surname'
							label={t('person.surname')}
							size='small'
							disabled={editDisabled}
							fullWidth
							// error={!!errors.name}
							// helperText={errors.name && errors.name.message}
							value={field.value ?? ''}
							onChange={(ev) => field.onChange(ev.target.value ?? '')}
						/>
					</InputGridField>
				)}
			/>
		</>
	);
};
