import { useOrderEditor } from '@contexts/index';
import { OrderTimeScheduler } from '../common/OrderTimeScheduler';

export const InboundOrderTimeScheduler = () => {
	const { loading, currentChainArchetype, control } = useOrderEditor();

	return (
		<OrderTimeScheduler
			loading={loading}
			currentChainArchetype={currentChainArchetype}
			control={control}
		/>
	);
};
