import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { PopupWrapper } from './PopupWrapper';

export interface PopupData {
	content: ReactNode;
	movable?: boolean;
	expandable?: boolean;
	id?: string;
}

export interface CachedPopupData {
	id: string;
	popup: ReactNode;
}

export const PopupsHandler = () => {
	const { popupContent, closeAllPopups } = useLayout();
	const [cachedPopups, setCachedPopups] = useState<CachedPopupData[]>([]);

	useEffect(() => {
		const newPopups: CachedPopupData[] = [];
		let newPopup: ReactNode;
		let found = false;
		for (let i = 0; i < popupContent.length; i++) {
			found = false;
			for (let j = 0; j < cachedPopups.length; j++) {
				if (popupContent[i]?.id === cachedPopups[j]?.id) {
					newPopups.push({ id: cachedPopups[j].id, popup: cachedPopups[j].popup });
					found = true;
					break;
				}
			}
			if (!found) {
				newPopup = (
					<PopupWrapper
						content={popupContent[i].content}
						zIndex={9000 + i}
						movable={popupContent[i].movable}
						expandable={popupContent[i].expandable}
						id={popupContent[i].id ?? 'untracked'}
					/>
				);
				newPopups.push({ id: popupContent[i].id ?? '', popup: newPopup });
			}
		}
		setCachedPopups(newPopups);
	}, [popupContent]);

	return (
		<>
			{popupContent.length > 0 && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						background: 'rgba(0, 0, 0, 0.3)',
						zIndex: 9000,
						backdropFilter: 'blur(1px)',
					}}
					onClick={() => closeAllPopups()}
				>
					{cachedPopups.map((popup) => (
						<Fragment key={popup.id}>{popup.popup}</Fragment>
					))}
				</div>
			)}
		</>
	);
};
