import React from 'react';
import { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from 'react-beautiful-dnd';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

export const calculateAdjustedPosition = (
	draggableProvided: DraggableProvided,
	snapshot: DraggableStateSnapshot,
	zoomPanState?: ReactZoomPanPinchRef,
): React.CSSProperties => {
	if (!snapshot.isDragging)
		return calculateAdjustedTranslation(
			draggableProvided,
			snapshot,
			zoomPanState,
		) as React.CSSProperties;
	if (!zoomPanState) return draggableProvided.draggableProps.style as React.CSSProperties;

	const {
		draggableProps: { style },
	} = draggableProvided;

	const castedStyle = style as DraggingStyle;
	const scale = zoomPanState?.state.scale ?? 1;
	const panX = zoomPanState?.state.positionX ?? 0;
	const panY = zoomPanState?.state.positionY ?? 0;

	// TODO: Find a way to get these values dynamically
	const offsetX = 58; // Fixed offset for the left bar
	const offsetY = 64; // Fixed offset for the top bar

	const adjustedX = (castedStyle.left - offsetX - panX) / scale;
	const adjustedY = (castedStyle.top - offsetY - panY) / scale;

	const adjustedWidth = castedStyle.width / scale;
	const adjustedHeight = castedStyle.height / scale;

	let newTransform = castedStyle.transform;
	if (newTransform) {
		const match = /translate\(([^,]+), ([^)]+)\)/.exec(newTransform);
		if (match) {
			const x = parseFloat(match[1]);
			const y = parseFloat(match[2]);

			const adjustedX = x * (1 / scale);
			const adjustedY = y * (1 / scale);

			newTransform = `translate(${adjustedX}px, ${adjustedY}px)`;
		}
	}

	return {
		...style,
		left: adjustedX,
		top: adjustedY,
		transform: newTransform,
		width: adjustedWidth,
		height: adjustedHeight,
	};
};

const calculateAdjustedTranslation = (
	draggableProvided: DraggableProvided,
	snapshot: DraggableStateSnapshot,
	zoomPanState?: ReactZoomPanPinchRef,
) => {
	if (snapshot.isDragging) return {};
	if (!zoomPanState) return draggableProvided.draggableProps.style;

	const {
		draggableProps: { style },
	} = draggableProvided;
	const castedStyle = style as DraggingStyle;

	const scale = zoomPanState?.state.scale ?? 1;
	let newTransform = castedStyle.transform;
	if (newTransform) {
		const match = /translate\(([^,]+), ([^)]+)\)/.exec(newTransform);
		if (match) {
			const x = parseFloat(match[1]);
			const y = parseFloat(match[2]);

			const adjustedX = x * (1 / scale);
			const adjustedY = 8 + y * (1 / scale);

			newTransform = `translate(${adjustedX}px, ${adjustedY}px)`;
		}
	}

	return {
		...style,
		transform: newTransform,
	};
};
