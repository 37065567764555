import { SxProps, Theme } from '@mui/material';

export const config: SxProps<Theme> = {
	'&& .MuiInputLabel-root': {
		pointerEvents: 'none',
	},
	'&& .MuiInputBase-root': {
		pointerEvents: 'none',
		borderColor: 'transparent',
		opacity: 1,
		backgroundColor: 'background.paper',
	},
	'&& .MuiInputBase-input.Mui-disabled': {
		color: 'black !important',
		WebkitTextFillColor: 'black !important',
	},
	'&& .MuiInputLabel-root.Mui-disabled': {
		color: 'black !important',
		WebkitTextFillColor: 'black !important',
	},
	'&& .MuiChip-root': {
		opacity: 1,
	},
	'&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent !important',
	},
	'&& .MuiInput-underline:before': {
		borderBottom: 'none !important',
	},
	'&& .MuiAutocomplete-endAdornment': {
		display: 'none',
	},
	'&& .MuiAutocomplete-input': {
		display: 'none',
	},
	'&& .MuiSvgIcon-root': {
		display: 'none',
	},
};
