import {
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderProductRequest,
	OrderStatus,
	OrderType,
	ShippingType,
	StorageType,
} from 'common';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { OrderTypeConfig } from './orderTypeConfig';
import { doCreateOrder } from '@store/order';
import { PayloadAction } from '@reduxjs/toolkit';
import { OutboundSuborderProductsContainer } from '../suborder/rows/outbound/OutboundSuborderProductsContainer';
import { OutboundSuborderRow } from '../suborder/rows/outbound/OutboundSuborderRow';
import { OutboundOrderHeader } from '../header/outbound/OutboundOrderHeader';
import { cloneDeep } from 'lodash';
import { EnhancedOrderProduct } from '@contexts/orderEditorContext/types';
import moment from 'moment';

/**
 * This is the configuration for the outbound order type.
 */
export const config: OrderTypeConfig = {
	headerElement: OutboundOrderHeader,
	headerElements: {
		code: new OrderElementConfigItem(true),
		fromType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		shippingType: new OrderElementConfigItem((directionType: OrderDirectionType) => {
			switch (directionType) {
				case OrderDirectionType.warehouse:
					return [ShippingType.externalCarrier, ShippingType.internalCarrier];
				default:
					return null;
			}
		}),
		toType: new OrderElementConfigItem([OrderDirectionType.customer]),
		toSubType: new OrderElementConfigItem(true),
		toWarehouseType: new OrderElementConfigItem(true),
		subOrderTypes: new OrderElementConfigItem(() => {
			return [
				StorageType.office,
				StorageType.subStorage,
				StorageType.trash,
				StorageType.processing,
			];
		}),
	},
	maxSubOrders: 1,
	subOrderRow: OutboundSuborderRow,
	subOrderProductsContainer: OutboundSuborderProductsContainer,
	isAddSuborderDisabled: (order, subOrders) => {
		if (!order.toId || !order.pickingSite) {
			return true;
		}
		return subOrders.some((so) => !so.content || so.content?.length <= 0);
	},
	isCreateOrderDisabled: () => false,
	executeOrderAction: async (data) => {
		const tempOrderProducts: OrderProductRequest[] = [];
		const tempBookedOrders: OrderBaseRequest[] = [];
		const request = cloneDeep(data.baseRequest);

		if (data.subOrders) {
			data.subOrders.map((so) => {
				so.content?.map((op) => {
					const enhanced = op as EnhancedOrderProduct;
					if (op.orderQty === undefined || op.orderQty === null || op.orderQty === 0) {
						console.log('Invalid order qty', op);
						return;
					}
					if (enhanced.booked) {
						if (enhanced.bookedDeliveryDate !== undefined) {
							const ead = moment(enhanced.bookedDeliveryDate).startOf('day').toDate();
							const existingOrder = tempBookedOrders.find((bo) => bo.ead === ead);
							if (existingOrder) {
								existingOrder.content?.push(cloneDeep(op));
							} else {
								// We have to understand if the order is simply outbound or if it is a cut order
								// I'll check that later
								tempBookedOrders.push({
									code: `${request.code}-booked-${tempBookedOrders.length}`,
									userId: request.userId,
									fromType: OrderDirectionType.warehouse,
									fromId: request.fromId,
									toType: OrderDirectionType.customer,
									toId: request.toId,
									shippingType: request.shippingType,
									orderType: OrderType.Outbound,
									ead,
									status: OrderStatus.Booked,
									content: [cloneDeep(op)],
								});
							}
						}
					} else {
						tempOrderProducts.push(cloneDeep(op));
					}
				});
			});
		}
		request.content = tempOrderProducts;
		console.log(request);

		// We remove the suborders that are not going to be used
		// in the order, so we don't send them to the backend. This happens for example
		// when we are moving things internally or when a suborder is used as proxy
		const newChildren: OrderBaseRequest[] = [];
		if (data.subOrders) {
			data.subOrders.forEach((so, it) => {
				if (so.toId) {
					newChildren.push({ ...so, code: `${request.code}-${it}`, orderType: OrderType.Outbound });
				}
			});
		}

		request.children = [...newChildren, ...tempBookedOrders];
		request.orderType = OrderType.Outbound;

		const validateData = OrderBaseCreateSchema.safeParse(request);
		if (!validateData.success) {
			return Promise.reject(validateData.error);
		}

		const outboundOrderResponse = (await data.dispatch(
			doCreateOrder(validateData.data),
		)) as PayloadAction<OrderBaseResponse>;

		if (outboundOrderResponse.type !== 'orders/create/fulfilled') {
			return Promise.reject('Outbound order creation failed');
		}
		console.log('Outbound order created', outboundOrderResponse.payload);
		return Promise.resolve(outboundOrderResponse.payload);
	},
};
