import { NestedSlidingMenu, NestedSlidingMenuItem } from '@components/menus/NestedSlidingMenu';
import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { Folder } from '@mui/icons-material';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AppFunction, ProductType, StorageType, TranslationTypes, WarehouseResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	currentWarehouseId: number | 'all';
	onChangeWarehouse: (warehouseId: number | 'all', subIds?: number[]) => void;
	currentProductTypes: ProductType[];
	onChangeProductTypes: (productTypes: ProductType[]) => void;
}

export const InventoryListHeader = ({
	currentWarehouseId,
	onChangeWarehouse,
	currentProductTypes,
	onChangeProductTypes,
}: Props) => {
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { getIcon } = useAppFunctions();
	const { warehouseList } = useWarehouses();

	const items = (): NestedSlidingMenuItem[] => {
		const itemArray: NestedSlidingMenuItem[] = [];
		const recursiveAdditems = (warehouse: WarehouseResponse): NestedSlidingMenuItem => {
			const children = warehouseList
				.filter((w) => w.parentId === warehouse.id)
				.map((child) => recursiveAdditems(child));
			return {
				id: warehouse.id,
				name: getTranslatedString(AppFunction.Location, warehouse.id, TranslationTypes.name) ?? '',
				icon: getIcon(AppFunction.Location, warehouse.storageType, false, {
					fontSize: '1.2rem',
				}) ?? <></>,
				children,
				suffix: children.length !== 0 ? <>{` (${children.length})`}</> : undefined,
			};
		};
		warehouseList.forEach((warehouse) => {
			if (warehouse.parentId === null) {
				itemArray.push(recursiveAdditems(warehouse));
			}
		});

		return itemArray;
	};

	const handleSelectedTypesChange = (
		_: React.MouseEvent<HTMLElement>,
		newSelectedTypes: ProductType[],
	) => {
		onChangeProductTypes(newSelectedTypes);
	};

	const selecteWarehouse = (id: number) => {
		const warehouse = warehouseList.find((w) => w.id === id);
		if (warehouse?.storageType === StorageType.folder) {
			const ids: number[] =
				items()
					.find((item) => item.id === id)
					?.children?.map((child) => child.id) ?? [];
			onChangeWarehouse(id, ids);
		} else {
			onChangeWarehouse(id);
		}
	};

	return (
		<Stack direction='column' justifyContent='space-between' alignItems='flex-start' gap={1}>
			<ToggleButtonGroup
				sx={{
					height: '2rem',
				}}
				value={currentProductTypes}
				onChange={handleSelectedTypesChange}
			>
				<ToggleButton
					value={ProductType.product}
					aria-label={ProductType.product}
					size='small'
					sx={{
						display: 'flex',
						gap: '0.5rem',
					}}
					color='success'
				>
					{getIcon(AppFunction.Product, ProductType.product, false, { fontSize: '1.2rem' })}
					{t(`${AppFunction.Product}.productTypes.${ProductType.product}`)}
				</ToggleButton>
				<ToggleButton
					value={ProductType.packaging}
					aria-label={ProductType.packaging}
					size='small'
					sx={{
						display: 'flex',
						gap: '0.5rem',
					}}
					color='success'
				>
					{getIcon(AppFunction.Product, ProductType.packaging, false, { fontSize: '1.2rem' })}
					{t(`${AppFunction.Product}.productTypes.${ProductType.packaging}`)}
				</ToggleButton>
			</ToggleButtonGroup>
			<NestedSlidingMenu
				root={{
					name: 'root',
					icon: <Folder sx={{ fontSize: '1.2rem' }} />,
				}}
				items={items()}
				currentSelection={currentWarehouseId === 'all' ? 'root' : currentWarehouseId}
				onSelectionChange={(id) => {
					if (id !== 'root') {
						selecteWarehouse(id);
					}
				}}
				onRootClick={() => {
					onChangeWarehouse('all');
				}}
			/>
		</Stack>
	);
};
