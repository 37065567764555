import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	AffectedRowsResponse,
	GenericIdRequest,
	MerchantRequest,
	MerchantResponse,
	MerchantsResponse,
	MerchantType,
} from 'common';

export const createEmptyMerchant = () => {
	return {
		id: -1,
		type: MerchantType.maker,
		strings: [],
	} as MerchantResponse;
};

export interface MerchantState {
	merchants: MerchantsResponse;
	op_status: NetworkOperationStatus;
}

const initialState: MerchantState = {
	merchants: [],
	op_status: 'idle',
};

export const doListMerchants = api.apiList<MerchantsResponse>('merchants/list', 'merchants');

export const doCreateMerchant = api.apiCreate<MerchantRequest, MerchantResponse>(
	'merchant/create',
	'merchants',
);

export const doEditMerchant = api.apiEdit<MerchantResponse, AffectedRowsResponse>(
	'merchant/edit',
	'merchants',
);

export const doDeleteMerchant = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'merchant/delete',
	'merchants',
);

export const merchantsSlice = createSlice({
	name: 'merchants',
	initialState,
	reducers: {
		addMerchant: (state, action: PayloadAction<MerchantResponse>) => {
			state.merchants = [...state.merchants, action.payload];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(doListMerchants.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListMerchants.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.merchants = action.payload;
		});
		builder.addCase(doListMerchants.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doCreateMerchant.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateMerchant.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateMerchant.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doEditMerchant.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditMerchant.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditMerchant.rejected, (state) => {
			state.op_status = 'failed';
		});
		builder.addCase(doDeleteMerchant.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteMerchant.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteMerchant.rejected, (state) => {
			state.op_status = 'failed';
		});
	},
});

export const { addMerchant } = merchantsSlice.actions;
export default merchantsSlice.reducer;
