import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface Props {
	main: JSX.Element;
	sub: JSX.Element;
	fontSize?: string;
	sx?: SxProps<Theme>;
	subSx?: SxProps<Theme>;
}

export const CombinedIcon = ({ main, sub, fontSize, sx, subSx }: Props) => {
	return (
		<Box
			sx={{
				display: 'inline-flex',
				position: 'relative',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: fontSize,
				paddingLeft: '0.2em',
				opacity: 0.7,
				marginRight: '-0.4em',
				...sx,
			}}
		>
			{React.cloneElement(main, {
				style: {
					...(fontSize && { fontSize: fontSize }),
				},
			})}
			<Box
				sx={{
					position: 'absolute',
					bottom: '-20%',
					right: 0,
					zIndex: 1,
					...subSx,
				}}
			>
				{React.cloneElement(sub, {
					style: {
						color: 'white',
						fontSize: '0.8em',
						backgroundColor: 'green',
						borderRadius: '50%',
						padding: '0.1em',
					},
				})}
			</Box>
		</Box>
	);
};
