import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Chip,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	useTheme,
} from '@mui/material';
import useGroups from '../../hooks/useGroups';
import useLocale from '../../hooks/useLocale';
import usePeople from '../../hooks/usePeople';
import { AppFunction, GroupableType, TranslationTypes } from 'common';
import { Add, Cancel, CopyAll } from '@mui/icons-material';

interface Props {
	members: number[];
	viewMode?: boolean;
	onValuesChanged: (values: number[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

enum SelectionMode {
	FREE = 'free',
	FROMTYPE = 'fromtype',
	FROMGROUP = 'fromgroup',
}

type AddMode = 'copy' | 'add';

export const PeopleGroupSelector = (props: Props) => {
	const { peopleList, jobTitlesList } = usePeople();
	const { getLinkedEntities, groupList } = useGroups();
	const { getTranslatedString } = useLocale();
	const theme = useTheme();

	const [selectedPeople, setSelectedPeople] = useState<number[]>([]);
	const [selectionMode, setSelectionMode] = useState(SelectionMode.FREE);
	const [addMode, setAddMode] = useState<AddMode>('copy');

	const handleChange = (event: SelectChangeEvent<typeof selectedPeople>) => {
		const {
			target: { value },
		} = event;
		props.onValuesChanged(value as number[]);
		setSelectedPeople(value as number[]);
	};

	const handleDelete = (id: number) => {
		const newList = selectedPeople.filter((p) => p !== id);
		props.onValuesChanged(newList);
		setSelectedPeople(newList);
	};

	useEffect(() => {
		setSelectedPeople(props.members);
	}, [props.members]);

	const selectCustomers = (list: number[] | null) => {
		if (list === null) {
			props.onValuesChanged([]);
			setSelectedPeople([]);
			return;
		}

		if (addMode === 'add') {
			const newList = [...selectedPeople];
			list.forEach((v) => {
				if (!newList.includes(v)) {
					newList.push(v);
				}
			});
			props.onValuesChanged(newList);
			setSelectedPeople(newList);
		} else {
			props.onValuesChanged(list);
			setSelectedPeople(list);
		}
	};

	const getPersonName = (id: number) => {
		const person = peopleList.find((p) => p.id === id);
		if (person !== undefined) {
			return `${person.name} ${person.surname}`;
		}
		return '';
	};

	return (
		<Grid container>
			{props.viewMode ? (
				<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
					{selectedPeople.map((value) => (
						<Chip sx={{ zIndex: 200 }} key={value} label={getPersonName(value)} />
					))}
				</Box>
			) : (
				<>
					<Grid item xs={6} sx={{ marginBottom: '1rem', paddingRight: '0.3rem' }} container>
						{selectionMode === SelectionMode.FROMGROUP ? (
							<Grid item xs={12} sx={{ maxHeight: '1rem', marginTop: '1.5rem' }} container>
								<Grid item xs={10}>
									<FormControl fullWidth>
										<InputLabel id='customer-groups-label'>Customer Groups</InputLabel>
										<Select
											labelId='customer-groups-label'
											id='customer-groups'
											value={-1}
											size='small'
											onChange={(event) => {
												const selection = event.target.value as number;
												const entitiesList = getLinkedEntities(selection)?.map((e) =>
													e.entityId !== undefined && e.entityId !== null ? e.entityId : -1,
												);
												selectCustomers(entitiesList ?? []);
												setSelectionMode(SelectionMode.FREE);
											}}
											input={<OutlinedInput id='select-multiple-chip' label='Customer Groups' />}
										>
											<MenuItem key={-1} value={-1}>
												<em>None</em>
											</MenuItem>
											{groupList
												.filter((g) => g.type === GroupableType.person)
												.map((g) => {
													if (g.id !== undefined && g.id !== null) {
														return (
															<MenuItem key={g.id} value={g.id}>
																{getTranslatedString(
																	AppFunction.EntityGroup,
																	g.id,
																	TranslationTypes.name,
																)}
															</MenuItem>
														);
													}
												})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
									<IconButton
										sx={{
											transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
											backgroundColor: theme.palette.error.main,
											color: theme.palette.primary.contrastText,
											'&:hover': {
												color: theme.palette.error.main,
												backgroundColor: theme.palette.primary.contrastText,
											},
										}}
										onClick={() => {
											setSelectionMode(SelectionMode.FREE);
										}}
									>
										<Cancel />
									</IconButton>
								</Grid>
							</Grid>
						) : (
							<>
								<Button
									fullWidth
									variant='contained'
									onClick={() => {
										setSelectionMode(SelectionMode.FROMGROUP);
										setAddMode('copy');
									}}
									sx={{ marginBottom: '1rem' }}
								>
									<CopyAll sx={{ marginRight: '1rem' }} />
									Copy from People Groups
								</Button>
								<Button
									fullWidth
									variant='contained'
									onClick={() => {
										setSelectionMode(SelectionMode.FROMGROUP);
										setAddMode('add');
									}}
								>
									<Add sx={{ marginRight: '1rem' }} />
									Add from People Groups
								</Button>
							</>
						)}
					</Grid>
					<Grid item xs={6} sx={{ marginBottom: '1rem', paddingLeft: '0.3rem' }} container>
						{selectionMode === SelectionMode.FROMTYPE ? (
							<Grid item xs={12} sx={{ maxHeight: '1rem', marginTop: '1.5rem' }} container>
								<Grid item xs={10}>
									<FormControl fullWidth>
										<InputLabel id='customer-types-label'>Customer Types</InputLabel>
										<Select
											labelId='customer-types-label'
											id='customer-types'
											value={-1}
											size='small'
											onChange={(event) => {
												const list = peopleList
													.filter(
														(p) =>
															p.jobTitles?.find((jt) => jt.id === event.target.value) !== undefined,
													)
													.map((p) => (p.id !== undefined && p.id !== null ? p.id : -1));
												selectCustomers(list);
												setSelectionMode(SelectionMode.FREE);
											}}
											input={<OutlinedInput id='select-multiple-chip' label='Customer Types' />}
										>
											<MenuItem key={-1} value={-1}>
												<em>None</em>
											</MenuItem>
											{jobTitlesList.map((jt) => {
												if (jt.id !== undefined && jt.id !== null) {
													return (
														<MenuItem key={jt.id} value={jt.id}>
															{getTranslatedString(
																AppFunction.JobTitle,
																jt.id,
																TranslationTypes.name,
															)}
														</MenuItem>
													);
												}
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
									<IconButton
										sx={{
											transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
											backgroundColor: theme.palette.error.main,
											color: theme.palette.primary.contrastText,
											'&:hover': {
												color: theme.palette.error.main,
												backgroundColor: theme.palette.primary.contrastText,
											},
										}}
										onClick={() => {
											setSelectionMode(SelectionMode.FREE);
										}}
									>
										<Cancel />
									</IconButton>
								</Grid>
							</Grid>
						) : (
							<>
								<Button
									fullWidth
									variant='contained'
									onClick={() => {
										setSelectionMode(SelectionMode.FROMTYPE);
										setAddMode('copy');
									}}
									sx={{ marginBottom: '1rem' }}
								>
									<CopyAll sx={{ marginRight: '1rem' }} />
									Copy from Job Titles
								</Button>
								<Button
									fullWidth
									variant='contained'
									onClick={() => {
										setSelectionMode(SelectionMode.FROMTYPE);
										setAddMode('add');
									}}
								>
									<Add sx={{ marginRight: '1rem' }} />
									Add from Job Titles
								</Button>
							</>
						)}
					</Grid>
					<Grid item xs={selectedPeople.length > 0 ? 11 : 12}>
						<FormControl fullWidth>
							<InputLabel id='demo-multiple-chip-label'>Members</InputLabel>
							<Select
								labelId='demo-multiple-chip-label'
								id='demo-multiple-chip'
								multiple
								value={selectedPeople}
								onChange={handleChange}
								input={<OutlinedInput id='select-multiple-chip' label='Members' />}
								renderValue={(selected) => {
									return (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((value) => (
												<Chip
													sx={{ zIndex: 200 }}
													key={value}
													label={getPersonName(value)}
													onMouseDown={(e) => e.stopPropagation()}
													deleteIcon={<Cancel />}
													onDelete={() => handleDelete(value)}
												/>
											))}
										</Box>
									);
								}}
								MenuProps={MenuProps}
							>
								{peopleList.map((p) => {
									if (p.id !== undefined && p.id !== null) {
										return (
											<MenuItem
												key={p.id}
												value={p.id}
												sx={{ display: 'flex', justifyContent: 'space-between' }}
											>
												<span style={{ flexGrow: 5 }}>{getPersonName(p.id)}</span>
											</MenuItem>
										);
									}
								})}
							</Select>
						</FormControl>
					</Grid>
					{selectedPeople.length > 0 && (
						<Grid
							item
							xs={1}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<IconButton
								sx={{
									transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
									backgroundColor: theme.palette.error.main,
									color: theme.palette.primary.contrastText,
									'&:hover': {
										color: theme.palette.error.main,
										backgroundColor: theme.palette.primary.contrastText,
									},
								}}
								onClick={() => selectCustomers(null)}
							>
								<Cancel />
							</IconButton>
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
};
