import { Delete, KeyboardReturn } from '@mui/icons-material';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { SubItemProps } from './ImportChildItem';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useTask } from '@contexts/index';
import { useTranslation } from 'react-i18next';
import { AppFunction, TaskStatus } from 'common';
import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface ImportChildReturnItemProps extends SubItemProps {
	locked: boolean;
}

export const ReturnChildItem = ({
	content,
	value,
	iterator,
	locked,
	variable,
	updateField,
	handleQuantityChange,
}: ImportChildReturnItemProps) => {
	const { taskState } = useTask();
	const { t } = useTranslation();

	const canEdit = taskState === TaskStatus.inprogress || taskState === TaskStatus.stub;

	const orderProductId = getParsedId(content.id);
	if (!orderProductId) {
		console.error('Invalid order product ID');
		return null;
	}

	return (
		<Grid container columnSpacing={1} item sx={{ marginTop: '1rem' }}>
			<Grid item flexShrink={1} display='flex' alignItems='center' justifyContent='center' gap={1}>
				<Typography>{t(`${AppFunction.Order}.return`)}</Typography>
				<KeyboardReturn />
			</Grid>
			<Grid item flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
				<InputGridField type={ComponentType.TextField}>
					<TextField
						label={t('product.arrivedQuantity')}
						size='small'
						type='number'
						disabled={!canEdit || value.notArrived}
						InputProps={{
							inputProps: { min: 0, max: locked ? content.orderQty : undefined },
						}}
						fullWidth
						value={value.returnValue?.arrivedQty ?? content.orderQty}
						onChange={(e) => handleQuantityChange(parseInt(e.target.value), iterator)}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
			</Grid>
			{variable && (
				<InputGridField flexGrow={1} type={ComponentType.TextField}>
					<TextField
						label={t('product.weight')}
						size='small'
						type='number'
						disabled={!canEdit || value.notArrived}
						InputProps={{
							inputProps: { min: 0 },
						}}
						fullWidth
						value={value.returnValue?.weight ?? content.weight}
						onChange={(e) =>
							updateField(orderProductId, 'weight', parseInt(e.target.value), iterator)
						}
						sx={{ backgroundColor: 'white' }}
					/>
				</InputGridField>
			)}
			<Grid item flexShrink={1} display='flex' alignItems='center' justifyContent='center' gap={1}>
				<IconButton onClick={() => updateField(orderProductId, 'removeReturn', true)}>
					<Delete color='error' />
				</IconButton>
			</Grid>
		</Grid>
	);
};
