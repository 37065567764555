import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/tasks/taskfieldarchetype/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormAction, FormType } from '@contexts/formContext/types';
import useTasks from '@hooks/useTasks';
import { Settings } from '@mui/icons-material';
import { AppFunction, TaskFieldArchetypeResponse, TaskFieldArchetypeSchema } from 'common';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

const TaskFieldArchetypeFormContent = withFormLogic<TaskFieldArchetypeResponse>(() => <></>);

export const TaskFieldArchetypeForm = ({ forcedId, formAction }: Props) => {
	const { taskFieldArchetypeList, createTaskFieldArchetype, editTaskFieldArchetype } = useTasks();

	console.log('rerender');

	return (
		<TaskFieldArchetypeFormContent
			forcedId={forcedId}
			formType={FormType.full}
			formAction={formAction}
			list={taskFieldArchetypeList}
			hideMenu
			validationSchema={TaskFieldArchetypeSchema}
			createFunction={createTaskFieldArchetype}
			editFunction={editTaskFieldArchetype}
			defaultValues={{ id: -1, optional: false } as TaskFieldArchetypeResponse}
			pagePrefix='settings'
			customAfterCreateLink='settings/taskFieldArchetype'
			prefixIcon={<Settings />}
			sideFabs={[FormSidefabType.Info]}
			appFunction={AppFunction.TaskFieldArchetype}
		>
			{() => (
				<TabbedElement title='tasks.taskFieldArchetype'>
					<GeneralInfo />
				</TabbedElement>
			)}
		</TaskFieldArchetypeFormContent>
	);
};
