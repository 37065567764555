import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useProducts from '@hooks/useProducts';
import useImages from '@hooks/useImages';
import useLocale from '@hooks/useLocale';

import { CloseOrderValue } from '../config';

import {
	AppFunction,
	InternalOrderSubType,
	OrderProductResponse,
	ProcessingOrderSubType,
	TranslationTypes,
} from 'common';
import { Avatar, Box, Grid, IconButton, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { SkuChip } from '@components/common/chips/SkuChip';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';

import { Edit, Note, Save } from '@mui/icons-material';
import {
	CloseOrderFieldListItemAction,
	CloseOrderFieldListItemActions,
} from './CloseOrderFieldListItemActions';
import { QuantityChip } from '@components/common/chips/QuantityChip';
import { WeightChip } from '@components/common/chips/WeightChip';

/**
 * Props for the CloseOrderFieldListItem component.
 * @interface Props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 */
interface CloseOrderFieldListItemProps {
	content: OrderProductResponse;
	children: ReactNode;
	value: CloseOrderValue;
	iterator: number;
	subType?: InternalOrderSubType | ProcessingOrderSubType | null;
	hideNumber?: boolean;
	actions?: CloseOrderFieldListItemAction[];
	canEdit?: boolean;
}

/**
 * Close Order Field List Item component.
 * Used as base component for all close order field list items.
 * @param {CloseOrderFieldListItemProps} props - CloseOrderFieldListItem component props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 * @returns {JSX.Element} CloseOrderFieldListItem component
 */
export const CloseOrderFieldListItem = ({
	content,
	children,
	value,
	iterator,
	subType,
	hideNumber,
	actions,
	canEdit,
}: CloseOrderFieldListItemProps): JSX.Element | null => {
	const { variantList, productList } = useProducts();
	const { imageList } = useImages();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const [isEditing, setIsEditing] = useState(false);

	const variant = variantList.find((v) => v.id === content.variantId);

	if (!variant || !value.oldValues.expiresAt) {
		return null;
	}

	const imageUrl = useMemo(() => {
		let image = imageList.find((image) => image.id === variant?.images?.[0]?.id ?? -1);
		if (!image) {
			image = imageList.find(
				(image) =>
					image.id === productList.find((p) => p.id === variant.productId)?.images?.[0]?.id ?? -1,
			);
		}

		return image?.url ?? '';
	}, [content.variantId, variantList, productList, imageList]);

	return (
		<ListItem
			sx={{
				margin: '1rem 0 1rem 0',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderRadius: '1rem',
				transition: 'all 0.2s ease-in-out',
				padding: '0.3rem',
				boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
				'&:hover': {
					boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
				},
				'&:before':
					!hideNumber ||
					!subType ||
					subType === InternalOrderSubType.simple ||
					subType === InternalOrderSubType.afterImport
						? {
								content: `"${(iterator ?? 0) + 1}"`,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '0.8rem',
								position: 'absolute',
								top: 'calc(50% - 0.6rem)',
								backgroundColor: 'black',
								color: 'white',
								left: '-1.6rem',
								width: '1.2rem',
								height: '1.2rem',
								zIndex: 0,
								borderRadius: '1rem',
								opacity: 0.4,
						  }
						: {},
			}}
		>
			<ListItemAvatar
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Avatar
					src={imageUrl}
					imgProps={{
						style: {
							objectFit: 'cover',
						},
					}}
					sx={{
						maxWidth: isEditing ? 'auto' : '2rem',
						maxHeight: isEditing ? 'auto' : '2rem',
					}}
				/>
			</ListItemAvatar>
			<Grid container display='flex' rowGap={1}>
				<Grid
					item
					flexGrow={1}
					flexBasis={0}
					container
					display='flex'
					rowGap={2}
					sx={{
						padding: '0.3rem 1rem 0.3rem 0.3rem',
					}}
				>
					<Grid
						item
						display='flex'
						gap={1}
						alignItems='center'
						justifyContent='flex-start'
						flexGrow={1}
						flexBasis={0}
						container
					>
						<Grid item>
							<SkuChip
								{...variant}
								orderProductId={content.sourceId ? +content.sourceId : undefined}
							/>
						</Grid>
						<Grid item flexGrow={1} flexBasis={0} display='flex' gap={1}>
							<Typography>
								{getTranslatedString(
									AppFunction.Product,
									variant.productId ?? -1,
									TranslationTypes.name,
								)}
							</Typography>
							<Typography>
								{getTranslatedString(
									AppFunction.Variant,
									content.variantId ?? -1,
									TranslationTypes.name,
								)}
							</Typography>
						</Grid>
						{isEditing ? (
							<Grid item flexGrow={1} />
						) : (
							<Grid item flexGrow={1.5} flexBasis={0} container columnSpacing={1}>
								<Grid item xs={4}>
									{value.oldValues.arrivedQty && (
										<QuantityChip quantity={value.oldValues.arrivedQty} sx={{ width: '100%' }} />
									)}
								</Grid>
								<Grid item xs={4}>
									{value.oldValues.weight && (
										<WeightChip weight={value.oldValues.weight} sx={{ width: '100%' }} />
									)}
								</Grid>
								<Grid item xs={4} display='flex' justifyContent='center' alignItems='center'>
									<ExpiresAtChip {...content} sx={{ width: '100%' }} />
								</Grid>
							</Grid>
						)}
						{canEdit && (
							<IconButton onClick={() => setIsEditing(!isEditing)} sx={{ fontSize: '1.2rem' }}>
								{isEditing ? <Save fontSize='inherit' /> : <Edit fontSize='inherit' />}
							</IconButton>
						)}
					</Grid>
					{isEditing && (
						<Grid
							item
							xs={12}
							container
							display='flex'
							p={1}
							gap={1}
							bgcolor={'rgba(255, 255, 255, 1)'}
							borderRadius={'1rem'}
							alignItems='center'
						>
							{children}
						</Grid>
					)}
					{content.notes && (
						<Grid item xs={12}>
							<Box
								sx={{
									padding: '1rem',
									backgroundColor: 'rgba(0, 0, 0, 0.05)',
									borderRadius: '1rem',
									transition: 'all 0.2s ease-in-out',
									boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
									'&:hover': {
										boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
									},
								}}
								display='flex'
								alignItems='center'
								justifyContent='flex-start'
								columnGap={1}
							>
								<Note color='success' />
								<Box>{content.notes}</Box>
							</Box>
						</Grid>
					)}
				</Grid>
				{isEditing && <CloseOrderFieldListItemActions actions={actions} />}
			</Grid>
		</ListItem>
	);
};
