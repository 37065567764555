import { Fragment, useEffect, useRef, useState } from 'react';
import useProducts from '../../hooks/useProducts';
import useLocale from '../../hooks/useLocale';

import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnSortOrderType, ColumnSortParams } from 'primereact/column';
import { ButtonType, GuardedCreateButton } from '../../components/GuardedCreateButton';
import {
	AppFunction,
	GenericIdRequest,
	ProductResponse,
	ProductType,
	TranslationTypes,
	VariantResponse,
	VariantsResponse,
} from 'common';

import styles from './ProductsList.module.css';
import { CustomFilterInput } from '../../components/filterInputs/CustomFilterTextInput';
import { CustomFilter } from '../../hooks/useInitializeFilters';
import _ from 'lodash';

import { Check, Clear } from '@mui/icons-material';
import commonStyles from '../../styles/Common.module.css';
import { TableContextProvider, useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { DropDownActionType } from '@contexts/tableContext/types';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { AvatarRowBodyTemplate } from '@components/tables/fields/AvatarRowBodyTemplate';
import {
	AddProductDialog,
	AddProductDialogData,
	AddProductDialogResult,
} from '@components/dialogs/products/AddProductDialog';
import { useNavigate } from 'react-router-dom';

interface Props {
	productType: ProductType;
}

type ProductOrVariant = ProductResponse | VariantResponse;

export const ProductsList = ({ productType }: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { productList, deleteProduct, deleteVariant } = useProducts();
	const { pushDialog, pushComponentDialog } = useFeedbacks();
	const { getI18NString, renderI18NField, getCurrentLanguage, getTranslatedString } = useLocale();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: `breadCrumbs.productTypes.${productType}`,
				icon: getIcon(AppFunction.Product, productType) ?? <></>,
			},
		]);
	}, [productType]);

	// #region States
	const [showGrouped, setShowGrouped] = useState(false);
	// #endregion

	const isGrouped = useRef<boolean>(false);

	// #region DataTable Elements
	const nameBodyTemplate = (rowData: ProductOrVariant) => {
		if (Object.prototype.hasOwnProperty.call(rowData, 'productId')) {
			const data = rowData as VariantResponse;
			return (
				<Fragment key={`${data.id}-${data.productId ?? -1}`}>
					{`${getTranslatedString(
						AppFunction.Product,
						data.productId ? +data.productId : -1,
						TranslationTypes.name,
					)} ${getTranslatedString(AppFunction.Variant, data.id ?? '', TranslationTypes.name)}`}
				</Fragment>
			);
		} else if (Object.prototype.hasOwnProperty.call(rowData, 'id')) {
			const data = rowData as ProductResponse;
			return (
				<Fragment key={data.id}>
					{renderI18NField(getI18NString(AppFunction.Product, rowData.id, TranslationTypes.name))}
				</Fragment>
			);
		}
	};

	// entryLink: `/product/${rowData.productId}/edit/${rowData.id}`,

	const rowExpansionTemplate = (data: ProductResponse) => {
		return (
			<Grid container spacing={0} sx={{ p: 0 }}>
				<Grid item xs={12}>
					<DataTable
						value={data.variants ?? []}
						className='p-datatable-products'
						dataKey='id'
						onContextMenu={(event) => console.log(event.data.strings)}
						responsiveLayout='scroll'
						size='small'
						emptyMessage='No products found.'
						currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
						scrollable
					>
						<Column
							field='image'
							body={(data) => <AvatarRowBodyTemplate images={data.images} />}
							// style={{ maxWidth: '4.5rem' }}
							// headerStyle={{ maxWidth: '4.5rem' }}
						/>
						<Column field='sku' header='sku' />
						<Column
							field='name'
							header={t('product.name')}
							body={(data) =>
								renderI18NField(getI18NString(AppFunction.Variant, data.id, TranslationTypes.name))
							}
							style={{ maxWidth: '20rem' }}
							headerStyle={{ maxWidth: '20rem' }}
						/>
						{/* <Column
							body={actionsVariantBodyTemplate}
							bodyStyle={{ overflow: 'visible', justifyContent: 'right' }}
						/> */}
					</DataTable>
				</Grid>
				<Grid item xs={12}>
					<GuardedCreateButton
						targetUrl={`/product/${data.id}/create/`}
						permission={AppFunction.Product}
						type={ButtonType.FULLWIDTH}
					/>
				</Grid>
			</Grid>
		);
	};

	const renderBoolean = (data: boolean) => {
		if (data) {
			return <Check />;
		} else {
			return <Clear />;
		}
	};

	const renderVariable = (data: VariantResponse) => {
		return renderBoolean(data.variable);
	};

	const matchModes = [{ label: 'My Filter', value: 'filterStrings' }];

	const sortByName = (data: ColumnSortParams): VariantsResponse => {
		function sortByName(
			objectArray: VariantsResponse,
			currentLanguage: string,
			sortOrder: ColumnSortOrderType,
		) {
			const fallbackOrder: Record<string, string[]> = {
				jp: ['it', 'en'],
				it: ['jp', 'en'],
				en: ['jp', 'it'],
			};
			return _.orderBy(
				objectArray,
				(obj) => {
					return _.get(
						_.find(obj.strings, (s) => s.langCode === currentLanguage) ||
							_.find(obj.strings, (s) => _.includes(fallbackOrder[currentLanguage], s.langCode)),
						'value',
						'',
					);
				},
				sortOrder === 1 ? 'asc' : 'desc',
			);
		}

		if (data.order === 1 || data.order === -1) {
			const sortedArray = sortByName(data.rowData, getCurrentLanguage(), data.order);
			return sortedArray;
		}
		return data.rowData;
	};

	function renderGroupedColumns() {
		return (
			<>
				<Column
					expander
					frozen
					style={{
						maxWidth: '4rem',
						display: 'flex',
						justifyContent: 'center',
					}}
				/>
				<Column
					field='image'
					header=''
					body={(data) => <AvatarRowBodyTemplate images={data.images} />}
					style={{ maxWidth: '4rem' }}
				/>
				<Column
					field='strings'
					header='name'
					body={nameBodyTemplate}
					filter={true}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterStrings}
					filterMatchModeOptions={matchModes}
					showFilterMenu={false}
					style={{ maxWidth: '5rem', backgroundColor: 'red !important' }}
				/>
				<Column
					field='storageType'
					header='storageType'
					bodyClassName={styles.productsStorageTypeBody}
					headerClassName={styles.productsStorageTypeHead}
					body={(data) => <>{t(`product.storageTypes.${data.storageType.toLowerCase()}`)}</>}
					style={{ maxWidth: '9rem' }}
				/>
				<Column
					field='strings'
					header='description'
					body={(data) =>
						renderI18NField(
							getI18NString(AppFunction.Product, data.id, TranslationTypes.description),
						)
					}
					style={{ width: '18em' }}
					headerClassName={styles.productsNameHead}
					filter={true}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterStrings}
					filterMatchModeOptions={matchModes}
					showFilterMenu={false}
				/>
				<Column
					field='isFresh'
					header='isFresh'
					body={(data) => renderBoolean(data.isFresh)}
					bodyStyle={{ justifyContent: 'center' }}
					style={{ maxWidth: '4.6rem ' }}
				/>
				<Column
					field='merchantId'
					header='merchant'
					style={{ minWidth: '10em' }}
					body={(data) =>
						renderI18NField(
							getI18NString(AppFunction.Merchant, data.merchantId, TranslationTypes.name),
						)
					}
				/>
				<Column field='taxBracket' header='tax_bracket' style={{ minWidth: '10em' }} />
				<Column field='taxCode' header='tax_code' style={{ minWidth: '10em' }} />
			</>
		);
	}

	function renderUngroupedColumns() {
		return (
			<>
				<Column
					field='image'
					header='image'
					body={(data) => <AvatarRowBodyTemplate images={data.images} />}
					style={{ minWidth: '53px', maxWidth: '53px' }}
				/>
				<Column field='sku' header='sku' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
				<Column
					field='strings'
					header='name'
					body={nameBodyTemplate}
					style={{ minWidth: '26rem', maxWidth: '26rem' }}
					sortable
					sortFunction={sortByName}
					filter={true}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterStrings}
					filterMatchModeOptions={matchModes}
					showFilterMenu={false}
				/>
				<Column
					field='strings'
					header='description'
					style={{ minWidth: '18rem', maxWidth: '18rem' }}
					body={(data) =>
						renderI18NField(
							getI18NString(AppFunction.Variant, data.id, TranslationTypes.description),
						)
					}
					headerClassName={styles.productsNameHead}
					filter={true}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterStrings}
					filterMatchModeOptions={matchModes}
					showFilterMenu={false}
				/>
				<Column field='janCode' header='janCode' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
				<Column
					field='sizePerPiece'
					header='size_per_piece'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column
					field='sizePerBox'
					header='size_per_box'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column field='status' header='status' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
				<Column
					field='variable'
					header='varWeight'
					body={renderVariable}
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column field='weight' header='weight' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
				<Column
					field='shelfLife'
					header='shelf_life'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column
					field='purchase_price'
					header='price'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column
					field='sellPrice'
					header='basePrice'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
				/>
				<Column
					field='shipBy'
					header='shipBy'
					style={{ minWidth: '12rem', maxWidth: '12rem' }}
					body={(data) => {
						return <>{t(`product.shippingTypes.${data.shipBy.toLowerCase()}`)}</>;
					}}
				/>
			</>
		);
	}

	function callDeleteProductDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => {
						console.log('');
					},
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteProduct(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	function renderGroupedDatatable() {
		return (
			<TableContextProvider
				pageIdentifier={AppFunction.Product}
				values={productList.filter((x) => x.productType == productType)}
				customHeader={
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									onChange={(_, v) => {
										setShowGrouped(v);
										isGrouped.current = v;
									}}
								/>
							}
							label={t('product.showGrouped')}
							sx={{ marginLeft: '15px' }}
						/>
					</FormGroup>
				}
				subIdentifier={productType}
				tableVariant='grouped'
				rowExpansionTemplate={rowExpansionTemplate}
				actions={[
					{
						type: DropDownActionType.edit,
						customLink: '/product/:id/edit',
					},
					{
						type: DropDownActionType.details,
						customLink: '/product/:id',
					},
					{
						type: DropDownActionType.delete,
						action: callDeleteProductDialog,
					},
				]}
			>
				{renderGroupedColumns()}
			</TableContextProvider>
		);
	}

	function callDeleteVariantDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteVariant(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	function showProductCreateDialog() {
		pushComponentDialog<AddProductDialogResult, AddProductDialogData>({
			title: t('operations.createItem', {
				item: t(`${AppFunction.Product}.productTypes.${productType.toLowerCase()}`),
			}),
			component: AddProductDialog,
			fullScreen: false,
			type: 'component',
			data: { productType: productType, isGrouped: isGrouped.current },
		})
			.then((result) => {
				if (result) {
					navigate(
						`/${productType}/${result.parentId !== -1 ? result.parentId : 'create'}${
							result.parentId !== -1 ? '/create' : ''
						}`,
					);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function renderUngroupedDatatable() {
		return (
			<TableContextProvider
				pageIdentifier={AppFunction.Product}
				values={productList
					.filter((pr) => pr.productType === productType && pr.variants)
					.flatMap((product) => product.variants ?? [])
					.filter(
						(variant): variant is VariantResponse => variant !== null && variant !== undefined,
					)}
				tableVariant='ungrouped'
				customHeader={
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									onChange={(_, v) => {
										setShowGrouped(v);
										isGrouped.current = v;
									}}
								/>
							}
							label={t('product.showGrouped')}
							sx={{ marginLeft: '15px' }}
						/>
					</FormGroup>
				}
				subIdentifier={productType}
				actions={[
					{
						type: DropDownActionType.edit,
						customLink: `/${productType}/:productId/edit/:id`,
					},
					{
						type: DropDownActionType.details,
						customLink: `/${productType}/:productId/:id`,
					},
					{
						type: DropDownActionType.delete,
						action: callDeleteVariantDialog,
					},
				]}
				customCreateAction={showProductCreateDialog}
			>
				{renderUngroupedColumns()}
			</TableContextProvider>
		);
	}
	// #endregion

	// #region React Dom Render
	return <>{showGrouped ? renderGroupedDatatable() : renderUngroupedDatatable()}</>;
	// #endregion
};
