import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AffectedRowsResponse,
	GenericIdRequest,
	RolesCreateRequest,
	SectionCreateRequest,
	SectionCreateSchema,
	SectionEditRequest,
	SectionPermissionsResponse,
	SectionResponse,
	UserCreateRequest,
	UserCreateRequestSchema,
	UserEditRequest,
	UserEditRequestSchema,
	UserResponse,
	UserWorkplacesResponse,
	UsersResponse,
} from 'common';
import { doCreateUser, doDeleteUser, doEditUser } from '../store/user';
import {
	doCreateSection,
	doDeleteSection,
	doEditSection,
	doLinkUserToRoles,
	doLinkUserToWorkplaces,
} from '../store/role';
import { PayloadAction } from '@reduxjs/toolkit';

function useUsers() {
	const dispatch = useAppDispatch();
	const usersSlice = useAppSelector((state) => state.user.userList);
	const rolesSlice = useAppSelector((state) => state.roles.roles);

	const [filter, setFilter] = useState('');
	const [userList, setUserList] = useState<UsersResponse>(usersSlice);
	const [currentUserList, setCurrentUserList] = useState<UsersResponse>(usersSlice);
	const [roleList, setRoleList] = useState(rolesSlice);

	useEffect(() => {
		setUserList((currentList) => {
			if (!_.isEqual(currentList, usersSlice)) {
				return usersSlice;
			}
			return currentList;
		});
	}, [usersSlice]);

	useEffect(() => {
		filterUsers();
	}, [userList, filter]);

	useEffect(() => {
		setRoleList((currentList) => {
			if (!_.isEqual(currentList, rolesSlice)) {
				return rolesSlice;
			}
			return currentList;
		});
	}, [rolesSlice]);

	const setFilterParams = (keyword: string) => setFilter(keyword);

	const filterUsers = () => {
		let newList: UsersResponse = [];
		if (filter !== '') {
			// newList = userList.filter(
			// 	(user) => user.name.includes(filter) || user.email.includes(filter),
			// );
			console.log('fix');
		} else {
			newList = userList;
		}

		setCurrentUserList((currentList) => {
			if (!_.isEqual(currentList, newList)) {
				return newList;
			}
			return currentList;
		});
	};

	const deleteUser = async (data: GenericIdRequest): Promise<AffectedRowsResponse | null> => {
		const response = (await dispatch(doDeleteUser(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'user/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	const editUser = async (data: UserEditRequest): Promise<AffectedRowsResponse> => {
		const parsedData = UserEditRequestSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid user data');
		}
		const response = (await dispatch(
			doEditUser(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'users/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('user.edit.rejected');
	};

	const createUser = async (
		data: UserCreateRequest | UserEditRequest | UserResponse,
	): Promise<UserEditRequest> => {
		const parsedData = UserCreateRequestSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid user data');
		} else {
			const response = (await dispatch(
				doCreateUser(parsedData.data),
			)) as PayloadAction<UserEditRequest>;
			if (response.type === 'users/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('user.create.rejected');
		}
	};

	const createSection = async (
		data: SectionCreateRequest | SectionEditRequest | SectionResponse,
	): Promise<SectionResponse> => {
		const parsedData = SectionCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid section data');
		} else {
			const response = (await dispatch(
				doCreateSection(parsedData.data),
			)) as PayloadAction<SectionResponse>;
			if (response.type === 'section/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('section.create.rejected');
		}
	};

	const editSection = async (data: SectionEditRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doEditSection(data))) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const deleteSection = async (data: GenericIdRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doDeleteSection(data))) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const linkUserToWorkplaces = async (
		data: UserWorkplacesResponse,
	): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doLinkUserToWorkplaces(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	const linkUserToRoles = async (data: RolesCreateRequest): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doLinkUserToRoles(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	return {
		roleList,
		userList: currentUserList,
		filterUsers: setFilterParams,
		deleteUser,
		createUser,
		editUser,
		createSection,
		editSection,
		deleteSection,
		linkUserToWorkplaces,
		linkUserToRoles,
	};
}

export default useUsers;
