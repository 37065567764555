import React, { useState } from 'react';
import { TextField, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TemplateNameDialogProps {
	close: (result?: Record<string, string>) => void;
}

export const TemplateNameDialog: React.FC<TemplateNameDialogProps> = ({ close }) => {
	const { t } = useTranslation();
	const [templateName, setTemplateName] = useState('');

	const handleSave = () => {
		close({ name: templateName });
	};

	return (
		<Stack direction='column' spacing={2}>
			<TextField
				label={t('order.templateName')}
				name='name'
				value={templateName}
				onChange={(e) => setTemplateName(e.target.value)}
				sx={{ minWidth: '20rem' }}
			/>
			<Button
				onClick={handleSave}
				variant='contained'
				disabled={!templateName || templateName.length < 3}
				sx={{
					borderRadius: '2rem',
				}}
			>
				{t('operations.save')}
			</Button>
		</Stack>
	);
};
