import { CustomerChip } from '@components/common/chips/CustomerChip';
import { MerchantChip } from '@components/common/chips/MerchantChip';
import { WarehouseChip } from '@components/WarehouseChip';
import { useOrderModify } from '@contexts/orderModifyContext/OrderModifyContext';
import useWarehouses from '@hooks/useWarehouses';
import { ArrowRight } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { AppFunction, OrderBaseChildRequest, OrderDirectionType } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	subOrder: OrderBaseChildRequest;
}

export const InboundModifySuborderContainerHeader = ({ subOrder }: Props) => {
	const { cachedData, formAction } = useOrderModify();
	const { warehouseList } = useWarehouses();
	const { t } = useTranslation();

	const getDirectionChip = (direction: OrderDirectionType, id: number) => {
		switch (direction) {
			case OrderDirectionType.merchant:
				return <MerchantChip merchantId={id} />;
			case OrderDirectionType.warehouse: {
				const warehouse = warehouseList.find((w) => w.id === id);
				if (warehouse) {
					return <WarehouseChip data={warehouse} showFull />;
				}
				break;
			}
			case OrderDirectionType.customer:
				return <CustomerChip customerId={id} />;
			default:
				break;
		}

		return null;
	};

	return (
		<Stack>
			<Grid
				container
				display='flex'
				alignItems='center'
				sx={{
					padding: '1rem 0 1rem 0',
				}}
			>
				{cachedData?.fromType &&
					cachedData?.fromId &&
					getDirectionChip(cachedData.fromType, cachedData.fromId)}
				<ArrowRight />
				{subOrder.fromType &&
					subOrder.fromId &&
					getDirectionChip(subOrder.fromType, subOrder.fromId)}
				<ArrowRight />
				{subOrder.toType && subOrder.toId && getDirectionChip(subOrder.toType, subOrder.toId)}
			</Grid>
			<Grid
				container
				sx={{
					marginBottom: '0.3rem',
				}}
			>
				<Grid
					item
					xs={1}
					sx={{
						borderWidth: '1px 0px 1px 1px',
						borderStyle: 'solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
					display='flex'
					alignItems='center'
				>
					{t(`${AppFunction.Product}.sku`)}
				</Grid>
				<Grid
					item
					xs={formAction === 'edit' ? 3 : undefined}
					flexGrow={formAction === 'edit' ? undefined : 1}
					sx={{
						borderWidth: '1px 0px 1px 1px',
						borderStyle: 'solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
				>
					{t(`${AppFunction.Product}.name`)}
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						borderWidth: '1px 0px 1px 1px',
						borderStyle: 'solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
				>
					{t(`${AppFunction.Product}.orderedQuantity`)}
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						borderWidth: '1px 0px 1px 1px',
						borderStyle: 'solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
				>
					{t(`${AppFunction.Product}.weight`)}
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						borderWidth: '1px 0px 1px 1px',
						borderStyle: 'solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
				>
					{t(`${AppFunction.Product}.totalWeight`)}
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						border: '1px solid',
						padding: '0.3rem',
						fontSize: '0.8rem',
					}}
				>
					{t(`${AppFunction.Inventory}.expiration`)}
				</Grid>
			</Grid>
		</Stack>
	);
};
