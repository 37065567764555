import { config as TextFieldConfig } from './TextFieldConfig';
import { config as AutocompleteFieldConfig } from './AutocompleteFieldConfig';
import { config as AutocompleteWithRenderTagsFieldConfig } from './AutocompleteWithRenderTagsFieldConfig';
import { config as SelectFieldConfig } from './SelectFieldConfig';
import { config as ToggleButtonFieldConfig } from './ToggleButtonFieldConfig';
import { config as DatePickerFieldConfig } from './DatePickerFieldConfig';
import { config as VectorTwoFieldConfig } from './VectorTwoFieldConfig';
import { SxProps, Theme } from '@mui/material';

export enum ComponentType {
	TextField = 'TextField',
	Autocomplete = 'Autocomplete',
	AutocompleteWithRenderTags = 'AutocompleteWithRenderTags',
	Select = 'Select',
	ToggleButton = 'ToggleButton',
	DatePicker = 'DatePicker',
	VectorTwoInput = 'VectorTwoInput',
	AdvancedAutocomplete = 'AdvancedAutocomplete',
	ProductAutocomplete = 'ProductAutocomplete',
	GroupedWarehouseAutocomplete = 'GroupedWarehouseAutocomplete',
	AdvancedDatePicker = 'AdvancedDatePicker',
	Switch = 'Switch',
}

export const noSxComponents: ComponentType[] = [
	ComponentType.DatePicker,
	ComponentType.ProductAutocomplete,
	ComponentType.GroupedWarehouseAutocomplete,
	ComponentType.AdvancedDatePicker,
];

type InputGridFieldConfig = {
	[key in ComponentType]?: SxProps<Theme>;
};

export const inputGridFieldConfigMap: InputGridFieldConfig = {
	TextField: TextFieldConfig,
	Autocomplete: AutocompleteFieldConfig,
	AutocompleteWithRenderTags: AutocompleteWithRenderTagsFieldConfig,
	Select: SelectFieldConfig,
	ToggleButton: ToggleButtonFieldConfig,
	DatePicker: DatePickerFieldConfig,
	VectorTwoInput: VectorTwoFieldConfig,
	AdvancedAutocomplete: AutocompleteFieldConfig,
};
