import { EditShowGridField } from '@components/common/EditShowGridField';
import { ConfirmPricesFieldItem } from '@contexts/taskContext/config/confirmpricesFieldConfig';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import { ChevronRight, Close, Edit } from '@mui/icons-material';
import { Grid, IconButton, ListItem, TextField, Typography } from '@mui/material';
import { AppFunction, OrderProductResponse, TranslationTypes, VariantResponse } from 'common';
import { useEffect, useState } from 'react';

interface Props {
	variant: VariantResponse;
	content: OrderProductResponse;
	value: ConfirmPricesFieldItem;
	merchantId: number;
	exchangeRate: number;
	currency: number;
	updateField: (variantId: number, value: ConfirmPricesFieldItem) => void;
}

export const ConfirmPricesItem = ({
	variant,
	content,
	value,
	merchantId,
	exchangeRate,
	currency,
	updateField,
}: Props) => {
	const { getTranslatedString } = useLocale();
	const { currencies } = useProducts();

	const [editingBasePrice, setEditingBasePrice] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const buyPrice = variant.merchantBuyingPrices?.find(
		(p) => p.variantId === variant.id && p.merchantId === merchantId,
	);

	if (!buyPrice || !content.arrivedQty) {
		return <ListItem>no buying prices set. this should not happen.</ListItem>;
	}

	const calculateMinPrice = (): number => {
		if (!content.arrivedQty) return 0;
		const basePrice =
			value.basePrice === -1 ? buyPrice.price * content.arrivedQty : value.basePrice;
		const convertedPrice = currency !== buyPrice.currencyId ? exchangeRate * basePrice : basePrice;
		return Math.ceil(convertedPrice + value.duties + value.freight);
	};

	const minPrice = calculateMinPrice();
	useEffect(() => {
		// Check if the current finalPrice is below the minimum price and update it if necessary
		if (value.finalPrice < minPrice) {
			updateField(variant.id, {
				...value,
				finalPrice: minPrice,
			});
		}
	}, [minPrice, value.finalPrice, variant.id, updateField]);

	return (
		<ListItem
			sx={{
				border: '1px solid #e0e0e0',
				borderRadius: '5px',
				padding: '1rem 0.5rem 0rem 0.5rem',
				'&:hover': {
					backgroundColor: '#f5f5f5',
				},
				height: expanded ? '15rem' : '5rem',
				transition: 'all 0.2s',
				marginBlock: '0.5rem',
			}}
		>
			<IconButton
				sx={{ marginRight: '0.5rem' }}
				onClick={() => setExpanded((oldToggle) => !oldToggle)}
			>
				<ChevronRight
					sx={{
						transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
						transition: 'transform 0.2s',
					}}
				/>
			</IconButton>
			<Grid container spacing={2}>
				<Grid item container xs={2}>
					<Grid item xs={12}>
						<Typography
							variant='body1'
							sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}
						>
							[{variant.sku}]
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start' }}>
						<Typography variant='body1'>
							{getTranslatedString(AppFunction.Variant, variant.id, TranslationTypes.name)}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={10} container spacing={2}>
					<Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
						{content.arrivedQty}
					</Grid>
					<Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
						<EditShowGridField isEditing={editingBasePrice} label>
							<TextField
								id={`buy-price-${variant.id}`}
								fullWidth
								size='small'
								// TODO: Add currency symbol/name
								label={`Buy Price (${
									currencies.find((c) => c.id === buyPrice.currencyId)?.alphabeticCode ?? 'currency'
								})`}
								type='number'
								value={
									value.basePrice === -1 ? buyPrice.price * content.arrivedQty : value.basePrice
								}
								onChange={(e) =>
									updateField(variant.id, {
										...value,
										basePrice: parseFloat(e.target.value ?? 0),
									})
								}
								InputProps={editingBasePrice ? { inputProps: { min: 0 } } : { readOnly: true }}
							/>
						</EditShowGridField>
						<IconButton
							sx={{ marginLeft: '0.5rem' }}
							onClick={() => {
								if (editingBasePrice) {
									updateField(variant.id, {
										...value,
										basePrice: -1,
									});
								} else {
									updateField(variant.id, {
										...value,
										basePrice: buyPrice.price * (content?.arrivedQty ?? 0),
									});
								}
								setEditingBasePrice((oldToggle) => !oldToggle);
							}}
						>
							{editingBasePrice ? <Close /> : <Edit />}
						</IconButton>
					</Grid>
					<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							id={`duties-${variant.id}`}
							fullWidth
							size='small'
							label='Duties'
							type='number'
							InputProps={{ inputProps: { min: 0 } }}
							value={value.duties}
							onChange={(e) =>
								updateField(variant.id, {
									...value,
									duties: parseFloat(e.target.value),
								})
							}
						/>
					</Grid>
					<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							id={`freight-${variant.id}`}
							fullWidth
							size='small'
							label='Freight/Others'
							type='number'
							InputProps={{ inputProps: { min: 0 } }}
							value={value.freight}
							onChange={(e) =>
								updateField(variant.id, {
									...value,
									freight: parseFloat(e.target.value),
								})
							}
						/>
					</Grid>
					<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							id={`final-price-${variant.id}`}
							fullWidth
							size='small'
							label='Final Price'
							type='number'
							InputProps={{
								inputProps: {
									min: minPrice,
								},
							}}
							value={value.finalPrice}
							onChange={(e) =>
								updateField(variant.id, {
									...value,
									finalPrice: parseFloat(e.target.value),
								})
							}
						/>
					</Grid>
					{expanded && (
						<>
							<Grid
								item
								xs={1}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								box price
							</Grid>
							<Grid item xs={2}>
								<EditShowGridField isEditing={false} label>
									<TextField
										id={`buy-price-per-box-${variant.id}`}
										fullWidth
										size='small'
										label='Buy Price per box'
										value={value.basePrice === -1 ? buyPrice.price : value.basePrice}
										InputProps={{ readOnly: true }}
									/>
								</EditShowGridField>
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`duties-per-box-${variant.id}`}
											fullWidth
											size='small'
											label='Duties per piece'
											value={value.duties / content.arrivedQty}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`freight-per-box-${variant.id}`}
											fullWidth
											size='small'
											label='Freight/Others per piece'
											value={value.freight / content.arrivedQty}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`final-price-per-box-${variant.id}`}
											fullWidth
											size='small'
											label='Final Price per box'
											value={value.finalPrice / content.arrivedQty}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
							<Grid
								item
								xs={1}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								unit price
							</Grid>
							<Grid item xs={2}>
								<EditShowGridField isEditing={false} label>
									<TextField
										id={`buy-price-per-piece-${variant.id}`}
										fullWidth
										size='small'
										label='Buy Price per piece'
										value={
											(value.basePrice === -1 ? buyPrice.price : value.basePrice) /
											variant.unitsPerBox
										}
										InputProps={{ readOnly: true }}
									/>
								</EditShowGridField>
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`duties-per-piece-${variant.id}`}
											fullWidth
											size='small'
											label='Duties per piece'
											value={value.duties / content.arrivedQty / variant.unitsPerBox}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`freight-per-piece-${variant.id}`}
											fullWidth
											size='small'
											label='Freight/Others per piece'
											value={value.freight / content.arrivedQty / variant.unitsPerBox}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
							<Grid item xs={3}>
								{content.arrivedQty && (
									<EditShowGridField isEditing={false} label>
										<TextField
											id={`final-price-per-piece-${variant.id}`}
											fullWidth
											size='small'
											label='Final Price per piece'
											value={value.finalPrice / content.arrivedQty / variant.unitsPerBox}
											InputProps={{ readOnly: true }}
										/>
									</EditShowGridField>
								)}
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</ListItem>
	);
};
