import { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';

import { Grid, IconButton, InputAdornment } from '@mui/material';
import { OrderDirectionType } from 'common';
import { Clear } from '@mui/icons-material';
import { useChainArchetypeEditor } from '@contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { MerchantSelector } from './destinationselectors/MerchantSelector';
import { WarehouseSelector } from './destinationselectors/WarehouseSelector';
import { BranchSelector } from './destinationselectors/BranchSelector';
import { CustomerSelector } from './destinationselectors/CustomerSelector';
import { EntityGroupSelector } from './destinationselectors/EntityGroupSelector';

interface Props {
	directionType: DirectionType;
}

export type DirectionType = 'from' | 'to';

export const createAdornment = (currentValue: number | string, onClick: () => void): ReactNode =>
	currentValue === -1 ? undefined : (
		<InputAdornment
			position='end'
			sx={{
				marginRight: '1.8rem',
			}}
		>
			<IconButton
				onClick={onClick}
				edge='end'
				sx={{
					transform: 'scale(0.8)',
				}}
			>
				<Clear />
			</IconButton>
		</InputAdornment>
	);

export const DestinationTypeSelector = ({ directionType }: Props) => {
	const { allowedFromTypes, allowedToTypes } = useChainArchetypeEditor();

	const [allowedDestinationTypes, setAllowedDestinationTypes] = useState<OrderDirectionType[]>([]);

	useEffect(() => {
		const tempAllowed = directionType === 'from' ? allowedFromTypes : allowedToTypes;
		setAllowedDestinationTypes(tempAllowed);
	}, [directionType, allowedFromTypes, allowedToTypes]);

	const getSelector = (direction: OrderDirectionType, index: number): ReactNode => {
		switch (direction) {
			case OrderDirectionType.merchant:
				return <MerchantSelector key={index} directionType={directionType} />;
			case OrderDirectionType.warehouse:
				return <WarehouseSelector key={index} directionType={directionType} />;
			case OrderDirectionType.branch:
				return <BranchSelector key={index} directionType={directionType} />;
			case OrderDirectionType.customer:
				return <CustomerSelector key={index} directionType={directionType} />;
		}
	};

	return (
		<Grid item xs={12} container spacing={2}>
			{allowedDestinationTypes.length > 1 ? (
				<>
					{allowedDestinationTypes.map((allowedDestinationType, index) =>
						getSelector(allowedDestinationType, index),
					)}
				</>
			) : (
				getSelector(allowedDestinationTypes[0], 0)
			)}
			<EntityGroupSelector directionType={directionType} />
		</Grid>
	);
};
