import { EditShowGridField } from '@components/common/EditShowGridField';
import { VectorThreeInput } from '@components/VectorThreeInput';
import { UnitSelector } from '@components/products/UnitSelector';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useProducts from '@hooks/useProducts';
import { Straighten } from '@mui/icons-material';
import {
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Select,
	Switch,
	TextField,
	ToggleButton,
} from '@mui/material';
import { ProductType, RecipeType, ShipBy, VariantResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const GeneralInfo = () => {
	const { t } = useTranslation();
	const { control, formAction, subFunction, getValues } = useGenericForm<VariantResponse>();
	const { currencies } = useProducts();

	const editDisabled = formAction === 'view';
	// const recipe = getValues('recipe');

	// const isProcessingProduct = (recipe && recipe.recipeType === RecipeType.processing) || false;
	// const isDivisible = (recipe && recipe.recipeType === RecipeType.division) || false;

	return (
		<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
			<Controller
				name='currentlySold'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.ToggleButton}>
						<ToggleButton
							value='check'
							color='success'
							size='small'
							disabled={editDisabled}
							selected={field.value}
							onChange={() => field.onChange(!field.value)}
							fullWidth
						>
							{field.value ? t('product.currentlySold') : t('product.notCurrentlySold')}
						</ToggleButton>
					</InputGridField>
				)}
			/>
			<Controller
				name='sku'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<TextField
							required
							variant='outlined'
							id='sku'
							size='small'
							fullWidth
							label={t('product.sku')}
							disabled={editDisabled}
							value={field.value}
							onChange={field.onChange}
						/>
					</InputGridField>
				)}
			/>
			<Controller
				name='janCode'
				control={control}
				render={({ field }) => (
					<InputGridField width={6} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='janCode'
							size='small'
							fullWidth
							disabled={editDisabled}
							label={t('product.janCode')}
							value={field.value}
							onChange={field.onChange}
						/>
					</InputGridField>
				)}
			/>
			{subFunction === ProductType.product && (
				<Controller
					name='shipBy'
					control={control}
					render={({ field }) => (
						<InputGridField
							width={12}
							muiLabel={{ labelId: 'ship-by', label: t('product.shipBy') }}
							type={ComponentType.Select}
						>
							<Select
								labelId='ship-by'
								id='ship-by'
								label={t('product.shipBy')}
								size='small'
								fullWidth
								value={field.value}
								disabled={editDisabled}
								onChange={(event) => field.onChange(event.target.value as ShipBy)}
							>
								{Object.values(ShipBy).map((value, it) => (
									<MenuItem key={it} value={value}>
										{t(`product.shippingTypes.${value}`)}
									</MenuItem>
								))}
							</Select>
						</InputGridField>
					)}
				/>
			)}
			{subFunction === ProductType.product && (
				<>
					<Grid item xs={12}>
						<TabInnerTitle title='product.sellInfo' translate size='small' />
					</Grid>
					<Controller
						name='sellUnitWeight'
						control={control}
						render={({ field }) => (
							<UnitSelector
								value={field.value}
								view={editDisabled}
								prefix='sell'
								onChange={(value) => field.onChange(value)}
							/>
						)}
					/>
					<Controller
						name='sellPrice'
						control={control}
						render={({ field }) => (
							<EditShowGridField width={8} isEditing={!editDisabled} label>
								<TextField
									variant='outlined'
									required
									type='number'
									size='small'
									fullWidth
									inputProps={{ min: 0 }}
									label={t('product.sellPrice')}
									id='product_sell_price'
									value={field.value}
									onChange={(event) => {
										field.onChange(+event.target.value);
									}}
								/>
							</EditShowGridField>
						)}
					/>
					<Controller
						name='sellCurrency'
						control={control}
						render={({ field }) => (
							<InputGridField
								width={4}
								muiLabel={{ labelId: 'sell-currency', label: t('product.sellCurrency') }}
								type={ComponentType.Select}
							>
								<Select
									labelId='sell-currency'
									id='sell-currency'
									size='small'
									fullWidth
									label={t('product.sellCurrency')}
									value={field.value}
									disabled={editDisabled}
									onChange={(event) => field.onChange(+event.target.value)}
								>
									{currencies.length > 0 &&
										currencies.map((currency) => (
											<MenuItem key={currency.id} value={currency.id}>
												{t(`currency.${currency.alphabeticCode}`)}
											</MenuItem>
										))}
								</Select>
							</InputGridField>
						)}
					/>
					<Grid item xs={12}>
						<TabInnerTitle title='product.buyInfo' translate size='small' />
					</Grid>
					<Controller
						name='purchaseUnitWeight'
						control={control}
						render={({ field }) => (
							<UnitSelector
								value={field.value}
								view={editDisabled}
								prefix='buy'
								onChange={(value) => field.onChange(value)}
							/>
						)}
					/>
				</>
			)}
			{subFunction === ProductType.product && (
				<Grid item xs={12} container spacing={2}>
					<Grid item xs={12}>
						<TabInnerTitle title='product.size' translate size='small' />
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<Controller
							name='variable'
							control={control}
							render={({ field }) => (
								<InputGridField width={4} type={ComponentType.Switch}>
									<FormGroup sx={{ paddingLeft: 1 }}>
										<FormControlLabel
											control={
												<Switch
													{...field}
													checked={field.value}
													disabled={editDisabled}
													onChange={(ev) => field.onChange(ev.target.checked)}
												/>
											}
											label={t('product.varWeight')}
										/>
									</FormGroup>
								</InputGridField>
							)}
						/>
						<Controller
							name='weight'
							control={control}
							render={({ field }) => (
								<InputGridField width={4} type={ComponentType.TextField}>
									<TextField
										variant='outlined'
										required
										type='number'
										size='small'
										fullWidth
										disabled={editDisabled}
										label={t(
											getValues('variable') ? 'product.approximateWeight' : 'product.weight',
										)}
										id='weight'
										value={+field.value}
										onChange={(event) => field.onChange(+event.target.value)}
									/>
								</InputGridField>
							)}
						/>
						<Controller
							name='shelfLife'
							control={control}
							render={({ field }) => (
								<InputGridField width={4} type={ComponentType.TextField}>
									<TextField
										variant='outlined'
										required
										label={t('product.shelfLife')}
										id='weight_unit'
										size='small'
										fullWidth
										disabled={editDisabled}
										value={+field.value}
										onChange={(event) => {
											field.onChange(+event.target.value);
										}}
									/>
								</InputGridField>
							)}
						/>
						<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
							<Straighten sx={{ marginLeft: '8px', marginRight: '8px' }} />
							{t('product.size_per_box')}
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
							<Straighten sx={{ marginLeft: '8px', marginRight: '8px' }} />
							{t('product.size_per_piece')}
						</Grid>
						<Controller
							name='sizePerBox'
							control={control}
							render={({ field }) => (
								<EditShowGridField isEditing={true} width={6}>
									<VectorThreeInput value={field.value ?? '{0,0,0}'} onChange={field.onChange} />
								</EditShowGridField>
							)}
						/>
						<Controller
							name='sizePerPiece'
							control={control}
							render={({ field }) => (
								<EditShowGridField isEditing={true} width={6}>
									<VectorThreeInput value={field.value ?? '{0,0,0}'} onChange={field.onChange} />
								</EditShowGridField>
							)}
						/>
					</Grid>
					<Controller
						name='unitsPerBox'
						control={control}
						render={({ field }) => (
							<InputGridField width={12} type={ComponentType.TextField}>
								<TextField
									variant='outlined'
									required
									type='number'
									label={t('product.unitsPerBox')}
									id='units_per_box'
									size='small'
									fullWidth
									disabled={editDisabled}
									value={+field.value}
									onChange={(event) => field.onChange(+event.target.value)}
								/>
							</InputGridField>
						)}
					/>
				</Grid>
			)}
		</Grid>
	);
};
