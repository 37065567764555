import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './langs/en.json';
import ja from './langs/ja.json';
import it from './langs/it.json';

const resources = {
	en: {
		translation: en,
	},
	jp: {
		translation: ja,
	},
	it: {
		translation: it,
	},
};

i18n.use(initReactI18next).init({
	resources,
	debug: false,
	lng: 'ja',
	interpolation: {
		escapeValue: false,
	},
	pluralSeparator: '_',
	fallbackLng: ['ja', 'it', 'en'],
});

export default i18n;
