export default function CheckTextWidth(
	text: string,
	textSize?: number,
	fontFamily?: string,
): number {
	const tempElement = document.createElement('div');
	tempElement.style.fontSize = textSize ? `${textSize}rem` : '1.5rem';
	tempElement.style.fontFamily = fontFamily ?? 'Roboto';
	tempElement.style.visibility = 'hidden';
	tempElement.style.display = 'inline-block';
	tempElement.style.whiteSpace = 'nowrap';
	tempElement.textContent = text;
	document.body.appendChild(tempElement);
	const textWidthInPixels = tempElement.offsetWidth;
	const remSizeInPixels = parseFloat(getComputedStyle(tempElement).fontSize);
	const textWidthInRems = textWidthInPixels / remSizeInPixels;
	document.body.removeChild(tempElement);
	// console.log('Element should be removed now');
	return textWidthInRems;
}
