import { Grid } from '@mui/material';
import { OrderBaseResponse, OrderDirectionType } from 'common';
import { WarehouseChip } from '../chips/WarehouseChip';
import { ArrowRight } from '@mui/icons-material';
import { useMemo } from 'react';
import { MerchantChip } from '../chips/MerchantChip';
import { CustomerChip } from '../chips/CustomerChip';

interface Props {
	order: OrderBaseResponse;
}

export const MoveFromToBanner = ({ order }: Props): JSX.Element | null => {
	const fromChip = useMemo(() => {
		if (!order.fromId || !order.toId || !order.fromType || !order.toType) {
			return null;
		}

		switch (order.fromType) {
			case OrderDirectionType.warehouse:
				return <WarehouseChip warehouseId={order.fromId} sx={{ width: '100%' }} />;
			case OrderDirectionType.merchant:
				return <MerchantChip merchantId={order.fromId} sx={{ width: '100%' }} />;
			default:
				return null;
		}
	}, [order.fromId, order.fromType]);

	const toChip = useMemo(() => {
		if (!order.toId || !order.toType) {
			return null;
		}

		switch (order.toType) {
			case OrderDirectionType.warehouse:
				return <WarehouseChip warehouseId={order.toId} sx={{ width: '100%' }} />;
			case OrderDirectionType.customer:
				return <CustomerChip customerId={order.toId} sx={{ width: '100%' }} />;
			default:
				return null;
		}
	}, [order.toId, order.toType]);

	return (
		<Grid item container bgcolor='grey.200' borderRadius='1rem' columnGap={1} p={1}>
			{/* <Grid 
                item 
                xs={12}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                test
            </Grid> */}
			<Grid
				item
				display='flex'
				alignItems='center'
				justifyContent='center'
				gap={1}
				flexGrow={1}
				flexBasis={0}
				p={1}
				sx={{
					borderRadius: '1rem',
				}}
			>
				{fromChip}
			</Grid>
			<Grid item display='flex' alignItems='center' justifyContent='center'>
				<ArrowRight />
			</Grid>
			<Grid
				item
				display='flex'
				alignItems='center'
				justifyContent='center'
				gap={1}
				flexGrow={1}
				flexBasis={0}
				p={1}
				sx={{
					// backgroundColor: 'grey[200]',
					borderRadius: '1rem',
				}}
			>
				{toChip}
			</Grid>
		</Grid>
	);
};
