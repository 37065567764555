import { Theme } from '@emotion/react';
import { Chip, SxProps } from '@mui/material';

interface QuantityChipProps {
	quantity?: number | null;
	maxAmount?: number | null;
	unit?: string | 'pieces' | 'grams';
	sx?: SxProps<Theme>;
}

export const QuantityChip = ({
	quantity,
	maxAmount,
	unit,
	sx,
}: QuantityChipProps): JSX.Element | null => {
	if (!quantity && !maxAmount) {
		return null;
	}

	return <Chip size='small' label={`${(maxAmount || quantity)?.toLocaleString()}個`} sx={sx} />;
};
