import { ArrowRight } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { OrderTreeNode } from '@pages/Orders/OrderDetails';
import { OrderDirection } from './OrderDirection';
import { AppFunction, OrderType } from 'common';
import useAppFunctions from '@hooks/useAppFunctions';
import useOrders from '@hooks/useOrders';

interface Props {
	orderChildren: OrderTreeNode[];
	selectedSubOrder?: number;
	selectSuborder?: (subOrderId: number) => void;
	level?: number;
	afterTree?: OrderTreeNode[];
	orderProductId?: number;
	onlyRelated?: boolean;
}

export const OrderTree = ({
	orderChildren,
	selectedSubOrder,
	selectSuborder,
	level = 0,
	afterTree,
	orderProductId,
	onlyRelated,
}: Props) => {
	const { getIcon } = useAppFunctions();
	const { isSameOrRelated } = useOrders();

	return (
		<Grid
			item
			container
			flexGrow={1}
			flexBasis={0}
			display='flex'
			direction={orderChildren.length <= 1 ? 'row' : 'column'}
			rowSpacing={orderChildren.length <= 1 ? 0 : 2}
		>
			{orderChildren.map((child, idx) => {
				if (
					onlyRelated &&
					orderProductId &&
					!child.content?.some((cc) => isSameOrRelated(cc.id ? +cc.id : -1, orderProductId))
				) {
					return null;
				}
				return (
					<Grid key={`${child.id}-${idx}`} item container direction='row'>
						<Grid
							item
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
							flexShrink={1}
							flexBasis={0}
						>
							<div
								style={{
									display: 'flex',
								}}
							>
								<ArrowRight />
							</div>
							<OrderDirection
								type={child.toType}
								id={child.orderType === OrderType.Processing ? child.fromId : child.toId}
								status={child.status}
								action={() => selectSuborder?.(child.id)}
								isSelected={selectedSubOrder === child.id}
								icon={getIcon(AppFunction.Order, child.orderType ?? OrderType.Manual) ?? <></>}
							/>
						</Grid>
						{child.children && child.children.length > 0 ? (
							<OrderTree
								orderChildren={child.children}
								level={level + 1}
								selectSuborder={selectSuborder}
								selectedSubOrder={selectedSubOrder}
								afterTree={afterTree}
								orderProductId={orderProductId}
								onlyRelated={onlyRelated}
							/>
						) : (
							<Grid item>
								{orderProductId &&
									child.content?.some((c) => c.id === orderProductId) &&
									afterTree &&
									afterTree.length > 0 && (
										<Grid container display='flex' flexDirection='column' spacing={2}>
											{afterTree.map((child, idx) => (
												<OrderTree
													key={`after-${child.id}-${idx}`}
													orderChildren={[child]}
													selectSuborder={selectSuborder}
													selectedSubOrder={selectedSubOrder}
												/>
											))}
										</Grid>
									)}
							</Grid>
						)}
					</Grid>
				);
			})}
		</Grid>
	);
};
