import { Box, Grid, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import { AppFunction, CustomerResponse } from 'common';
import { AddressChip, AddressComponent } from '@components/addresses/AddressComponent';
import useAddresses from '@hooks/useAddresses';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { ScopedLayer } from '@components/scopedlayer/ScopedLayer';
import { FocusedElementType } from 'store/app';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';

interface Props {
	chips?: AddressChip[];
	hideBox?: boolean;
	maxAddresses?: number;
}

// TODO: destructure props plz
export const AddressList = ({ chips, hideBox, maxAddresses }: Props) => {
	const { entityId, appFunction, formAction } = useGenericForm<CustomerResponse>();
	const { onAfterDeleteUsedAddress } = useCustomerForm();
	const { t } = useTranslation();
	const { addressList, createEmptyAddress } = useAddresses();

	const [isCreating, setCreating] = useState(false);
	const [expandedId, setExpandedId] = useState<number | null>(null);
	const [full, setFull] = useState(false);

	const editDisabled = formAction === 'view';

	const Addresses = useMemo(() => {
		if (addressList.length <= 0) {
			return <>No Addresses</>;
		} else {
			const list: JSX.Element[] = [];
			addressList
				.filter((x) => x.addressableId === entityId && x.type === appFunction)
				.map((x, it) =>
					list.push(
						<AddressComponent
							key={it}
							address={x}
							chips={chips}
							onAfterDelete={onAfterDeleteUsedAddress}
							canEdit={!editDisabled}
							expandedId={expandedId}
							onExpand={(id: number | null) => {
								setExpandedId(id);
								setCreating(false);
							}}
						/>,
					),
				);
			if (maxAddresses && list.length >= maxAddresses) {
				setFull(true);
			} else {
				setFull(false);
			}
			if (list.length > 0) {
				return <Stack spacing={2}>{list}</Stack>;
			}
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>{t('address.noAddresses')}</div>
			);
		}
	}, [addressList, formAction]);

	return (
		<Box>
			<ScopedLayer scopes={[FocusedElementType.address]} />
			<TabInnerTitle title='address.address_other' translate size='medium' />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{Addresses}
				</Grid>
				{isCreating ? (
					<Grid item xs={12} sx={{ position: 'relative' }}>
						<AddressComponent
							address={createEmptyAddress(appFunction ?? AppFunction.Customer, entityId)}
							canEdit={!editDisabled}
							onStopCreating={() => setCreating(false)}
							expandedId={expandedId ?? -1}
							onExpand={(id: number | null) => setExpandedId(id)}
						/>
					</Grid>
				) : !full && !editDisabled ? (
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<AnimatedIconButton
							text='operations.create'
							colorType='info'
							translate
							icon={<AddIcon />}
							onClick={() => setCreating(true)}
						/>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Box>
	);
};
