import useAppFunctions from '@hooks/useAppFunctions';
import { Box, alpha, keyframes, useTheme } from '@mui/material';
import { AppFunction } from 'common';

interface Props {
	appFunction: AppFunction;
}

const loading = keyframes`
    0% {
        transform: rotateY(0deg) scale(1);
    }
    50% {
        transform: rotateY(180deg) scale(1.1);
    }
    100% {
        transform: rotateY(360deg) scale(1);
    }
`;

export const LoadingBox = ({ appFunction }: Props) => {
	const { getIcon } = useAppFunctions();
	const theme = useTheme();
	const icon = getIcon(appFunction);

	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '1rem',
				borderRadius: '1rem',
				backgroundColor: 'white',
				boxShadow: `0 0 1.5rem 0.1rem ${alpha(theme.palette.primary.main, 0.2)}`,
			}}
		>
			<Box
				sx={{
					animation: `${loading} 3s linear infinite`,
					fontSize: '3rem',
				}}
			>
				{icon}
			</Box>
			<Box
				sx={{
					fontWeight: 'bold',
					// fontFamily: 'monospace',
					fontSize: '1rem',
					lineHeight: '1.2em',
					display: 'inline-grid',
					'&::before, &::after': {
						content: `'Loading...${appFunction}'`,
						gridArea: '1/1',
						WebkitMask: 'linear-gradient(90deg,#000 50%,#0000 0) 0 50%/2ch 100%',
						color: '#0000',
						textShadow: '0 0 0 #000,0 calc(var(--s,1)*1.2em) 0 #000',
						animation: 'textLoading 1.5s ease-in-out infinite',
					},
					'&::after': {
						WebkitMaskPosition: '1ch 50%',
						'--s': -1,
					},
					'@keyframes textLoading': {
						'80%,100%': {
							textShadow: '0 calc(var(--s,1)*-1.2em)  0 #000,0 0 0 #000',
						},
					},
				}}
			/>
		</Box>
	);
};
