import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AddressRequest,
	AddressResponse,
	AddressableAppFunction,
	AddressesResponse,
	GenericIdRequest,
	InvoicingMethod,
} from 'common';
import { doCreateAddress, doDeleteAddress, doEditAddress } from '../store/addresses';
import { NetworkOperationStatus } from '../store';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGeography from './useGeography';

function useAddresses() {
	const dispatch = useAppDispatch();
	const addressSlice = useAppSelector((state) => state.addresses.addresses);
	const state = useAppSelector((state) => state.addresses.op_status);
	const { t } = useTranslation();
	const { getRegionPath } = useGeography();

	const [formState, setFormState] = useState<NetworkOperationStatus>(state);
	const [addressList, setAddressList] = useState<AddressesResponse>(addressSlice);

	useEffect(() => {
		setAddressList((currentList) => {
			if (!_.isEqual(currentList, addressSlice)) {
				return addressSlice;
			}
			return currentList;
		});
	}, [addressSlice]);

	useEffect(() => {
		setFormState(state);
	}, [state]);

	const getAddressesByTypeId = (type: AddressableAppFunction, id: number): AddressesResponse => {
		return addressList.filter((address) => address.type === type && address.addressableId === id);
	};

	const createAddress = async (data: AddressRequest) => {
		return await dispatch(doCreateAddress(data));
	};

	const editAddress = async (data: AddressResponse) => {
		return await dispatch(doEditAddress(data));
	};

	const deleteAddress = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteAddress(data));
	};

	const createEmptyAddress = (
		entityType: AddressableAppFunction,
		entityId: number,
	): AddressResponse => {
		return {
			id: -1,
			countryId: 1,
			regionId: null,
			type: entityType,
			addressableId: entityId,
		};
	};

	const renderAddress = (
		addressId: number | null,
		isParentCompany: boolean,
		type?: InvoicingMethod | null,
		chips?: JSX.Element[],
	): JSX.Element | null => {
		const address = addressList.find((a) => a.id === addressId);
		if (!address) {
			return null;
		}
		const regionPath = getRegionPath(address.regionId);
		if (address.id) {
			switch (type) {
				case InvoicingMethod.email:
					return (
						<div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
							<div style={{ flexGrow: 1 }}>
								{isParentCompany && (
									<Chip
										size='small'
										label={t('customer.parentCompany')}
										sx={{ marginRight: '1rem' }}
									/>
								)}
								{address.email}
							</div>
							{address.shortCut && (
								<div style={{ fontSize: '0.5rem', fontWeight: 'bold' }}>
									{`[${address.shortCut}]`}
								</div>
							)}
							{chips ? <div>{chips}</div> : <></>}
						</div>
					);
				case InvoicingMethod.fax:
					return <>fax</>;
				case InvoicingMethod.mail:
				default:
					return (
						<div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '1rem' }}>
							<div style={{ flexGrow: 1 }}>
								{isParentCompany && (
									<Chip
										size='small'
										label={t('customer.parentCompany')}
										sx={{ marginRight: '1rem' }}
									/>
								)}
								{address.zip ? (
									<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
										<div>{`〒${address.zip}`}</div>
										<div>
											{regionPath.map((r) => t(`region-${r.id}-name`, { ns: 'locale' }))}
											{address.address}
										</div>
										<div>
											{address.number}
											{address.building}
										</div>
									</div>
								) : (
									<>{address.email}</>
								)}
							</div>
							{address.shortCut && (
								<div style={{ fontSize: '0.5rem', fontWeight: 'bold' }}>
									{`[${address.shortCut}]`}
								</div>
							)}
							{chips ? <div style={{ display: 'inline-flex' }}>{chips}</div> : <></>}
						</div>
					);
			}
		}
		return null;
	};

	return {
		formState,
		addressList,
		getAddressesByTypeId,
		createAddress,
		editAddress,
		deleteAddress,
		createEmptyAddress,
		renderAddress,
	};
}

export default useAddresses;
