import { QuickPersonCreate } from '@components/customers/QuickPersonCreate';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';
import usePeople from '@hooks/usePeople';
import { CustomerResponse } from 'common';
import _ from 'lodash';
import { useCallback } from 'react';

export const PeopleData = () => {
	const { entityId } = useGenericForm<CustomerResponse>();
	const { updatePersonData } = useCustomerForm();
	const { jobTitlesList } = usePeople();

	const debouncedUpdatePersonData = useCallback(
		_.debounce((data, titleId) => {
			if (updatePersonData) {
				updatePersonData(data, titleId);
			}
		}, 500),
		[updatePersonData],
	);

	if (entityId === -1) {
		const mandatoryTitles = jobTitlesList.filter((jt) => jt.mandatory === true);
		const elements: JSX.Element[] = [];

		mandatoryTitles.map((mt, it) =>
			elements.push(
				<QuickPersonCreate
					titleId={mt.id}
					onChangeValue={(data, titleId) => debouncedUpdatePersonData(data, titleId)}
					key={it}
				/>,
			),
		);

		return <>{elements}</>;
	}
	return <></>;
};
