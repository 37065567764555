import { useTask } from '@contexts/index';
import { SelectVesselFieldValue } from '@contexts/taskContext/config/selectvesselFieldConfig';
import useCarriers from '@hooks/useCarriers';
import useLocale from '@hooks/useLocale';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { AppFunction, CarrierType, TranslationTypes } from 'common';
import { memo } from 'react';

interface Props {
	taskFieldId: number;
	type: CarrierType | CarrierType[];
	values: SelectVesselFieldValue;
	setValues: (values: SelectVesselFieldValue) => void;
}

const CarrierInputField = ({ taskFieldId, type, values, setValues }: Props) => {
	const { setTaskFieldValues } = useTask();
	const { carrierList } = useCarriers();
	const { getTranslatedString } = useLocale();

	const carriers = Array.isArray(type)
		? carrierList.filter((carrier) => type.includes(carrier.carrierType))
		: carrierList.filter((carrier) => carrier.carrierType === type);

	return (
		<Autocomplete
			id='carrier-auto-complete'
			size='small'
			options={carriers}
			value={values.id ? carriers.find((carrier) => carrier.id === values.id) : null}
			getOptionLabel={(option) =>
				getTranslatedString(AppFunction.Carrier, option.id, TranslationTypes.name)
			}
			renderOption={(props, option) => (
				<MenuItem {...props} key={option.id}>
					{getTranslatedString(AppFunction.Carrier, option.id, TranslationTypes.name)}
				</MenuItem>
			)}
			renderInput={(params) => (
				<TextField {...params} label='Carrier' variant='outlined' fullWidth />
			)}
			onChange={(_, newValue) => {
				setTaskFieldValues(taskFieldId, [{ ...values, id: newValue?.id }]);
				setValues({ ...values, id: newValue?.id ?? null });
			}}
			disablePortal
			sx={{ marginBottom: '1rem' }}
		/>
	);
};

export default memo(CarrierInputField);
