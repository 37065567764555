import {
	AppFunction,
	OrderProductResponse,
	ProcessingOrderSubType,
	TaskStatus,
	WarehouseProductCutItem,
} from 'common';
import { CloseOrderFieldListItem } from '../../../../shared/CloseOrderFieldListItem';
import moment from 'moment';
import { CloseOrderValue } from '../../../../config';
import { useMemo } from 'react';
import { Box, Button, Grid, IconButton, TextField, ToggleButton, Typography } from '@mui/material';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useTask } from '@contexts/index';
import { ArrowRight, Delete, Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FadeWrapper } from '@components/common/FadeWrapper';
import AdvancedDatePicker from '@components/common/inputs/common/AdvancedDatePicker';
import { WeightChip } from '@components/common/chips/WeightChip';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	content: OrderProductResponse;
	value: WarehouseProductCutItem;
	iterator: number;
	sourceOrderProduct?: OrderProductResponse;
	compound?: boolean;
	updateValue: (partialValue: Partial<WarehouseProductCutItem>, idx: number, key: number) => void;
}

export const DivisionItem = ({
	content,
	value,
	iterator,
	sourceOrderProduct,
	compound,
	updateValue,
}: Props): JSX.Element | null => {
	const { taskState } = useTask();
	const { t } = useTranslation();

	/**
	 * {@link CloseOrderFieldListItem} takes {@link CloseOrderValue} as value.
	 * We need to convert the value to {@link CloseOrderValue} to use it in the component.
	 */
	const convertedValue: CloseOrderValue = useMemo(() => {
		return {
			oldValues: {
				variantId: content.variantId ?? -1,
				arrivedQty: 0,
				weight: 0,
				sourceId: content.sourceId ? +content.sourceId : null,
				estimated: false,
				expiresAt: moment(value.cutItems[0].cutExpiresAt),
			},
			newValues: [],
		};
	}, [content, value]);

	/** Original weight of the variable item moved to the Processing Room */
	const originalWeight: number = useMemo(() => {
		if (compound) {
			return sourceOrderProduct?.pickedWeight ?? 0;
		}
		return (sourceOrderProduct?.pickedWeight ?? 0) / (sourceOrderProduct?.orderQty ?? 0);
	}, [sourceOrderProduct, compound]);

	const isMoreThanOriginal: boolean = useMemo(() => {
		return (
			value.cutItems.reduce((acc, cutItem) => acc + cutItem.cutWeight, 0) +
				value.leftWeight +
				(value.discardedWeight ?? 0) >
			originalWeight
		);
	}, [value, originalWeight]);

	const weighted: boolean = useMemo(() => {
		return isMoreThanOriginal || !value.estimated;
	}, [value, originalWeight, isMoreThanOriginal]);

	if (!content.id) {
		return null;
	}

	const editDisabled = false;
	const canEdit =
		(taskState === TaskStatus.inprogress || taskState === TaskStatus.stub) && !editDisabled;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const updateCutItemValue = (cutItemIdx: number, key: string) => (newValue: any) => {
		const newCutItems = [...value.cutItems];
		newCutItems[cutItemIdx] = {
			...newCutItems[cutItemIdx],
			[key]: newValue,
		};
		const valuesToUpdate: Partial<WarehouseProductCutItem> = {
			cutItems: newCutItems,
		};
		if (value.estimated) {
			const leftWeight =
				originalWeight - newCutItems.reduce((acc, cutItem) => acc + cutItem.cutWeight, 0);
			valuesToUpdate.leftWeight = Math.max(0, leftWeight);
		}
		updateValue(valuesToUpdate, iterator, content.id ? +content.id : -1);
	};

	return (
		<CloseOrderFieldListItem
			content={content}
			value={convertedValue}
			iterator={iterator}
			subType={ProcessingOrderSubType.multiple}
			hideNumber
		>
			<Grid item xs={12} gap={1} container display='flex' alignItems='center'>
				<Grid item flexGrow={1}>
					<WeightChip weight={originalWeight} estimated />
				</Grid>
				{weighted && (
					<>
						<Grid item flexShrink={1} display='flex' alignItems='center'>
							<ArrowRight />
						</Grid>
						<Grid item flexGrow={1}>
							<WeightChip
								weight={
									value.cutItems.reduce((acc, cutItem) => acc + cutItem.cutWeight, 0) +
									value.leftWeight +
									(value.discardedWeight ?? 0)
								}
							/>
						</Grid>
					</>
				)}
				<Grid item xs={2}>
					<ToggleButton
						value='check'
						// size='small'
						selected={!weighted}
						fullWidth
						disabled={isMoreThanOriginal && value.estimated}
						onChange={() => {
							const estimated = !value.estimated;
							updateValue(
								{ estimated, discardedWeight: 0 },
								iterator,
								content.id ? +content.id : -1,
							);
						}}
						sx={{
							maxHeight: '1.5rem',
							borderColor: !weighted ? 'error.main' : 'success.main',
							backgroundColor: !weighted ? 'error.ultraLight' : 'success.light !important',
							display: 'flex',
							gap: 1,
							alignItems: 'center',
						}}
					>
						{isMoreThanOriginal && value.estimated && (
							<Lock
								fontSize='inherit'
								sx={{
									color: !weighted ? 'error.main' : 'success.main',
								}}
							/>
						)}
						<Typography variant='body2' color={!weighted ? 'error.main' : 'success.main'}>
							{t(`${AppFunction.Order}.processing.${!weighted ? 'notWeighted' : 'weighted'}`)}
						</Typography>
					</ToggleButton>
				</Grid>
			</Grid>
			<Grid
				item
				flexShrink={1}
				borderRadius={2}
				border={1}
				height='calc(100% - 3rem)'
				display='flex'
				alignItems='center'
				sx={{
					backgroundColor: value.estimated ? 'primary.main' : 'transparent',
					opacity: value.estimated ? 1 : 0.5,
					transition: 'opacity 0.2s ease-in-out',
				}}
			>
				<Lock
					sx={{
						margin: '0.5rem',
						color: value.estimated ? 'primary.contrastText' : 'primary.main',
					}}
				/>
			</Grid>
			<Grid item flexGrow={1} flexBasis={0} container gap={1}>
				{value.cutItems.map((cutItem, idx) => (
					<Grid item key={idx} xs={12} container gap={1}>
						<Grid item display='flex' alignItems='center' justifyContent='center' flexShrink={1}>
							<Box
								borderRadius='50%'
								bgcolor='primary.main'
								height='1.5rem'
								width='1.5rem'
								p='0.2rem'
								color='primary.contrastText'
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								{idx + 1}
							</Box>
						</Grid>
						<Grid item xs={4} display='flex' alignItems='center'>
							test
						</Grid>
						<InputGridField flexGrow={2} type={ComponentType.TextField}>
							<TextField
								// label={t('product.arrivedQuantity')}
								label='test'
								size='small'
								type='number'
								disabled={!canEdit}
								InputProps={{
									// inputProps: { min: 0, max: locked ? content.orderQty : undefined },
									inputProps: { min: 0 },
								}}
								fullWidth
								value={cutItem.cutWeight}
								onChange={(e) => {
									const newValue = parseInt(e.target.value);
									updateCutItemValue(idx, 'cutWeight')(newValue);
								}}
								sx={{ backgroundColor: 'white' }}
							/>
						</InputGridField>
						<InputGridField
							flexGrow={1}
							sx={{
								justifyContent: 'right',
							}}
							type={ComponentType.DatePicker}
							tools={[
								{
									title: 'oneMonth',
									onClick: () =>
										updateCutItemValue(
											idx,
											'cutExpiresAt',
										)(moment().add(1, 'month').startOf('day').toDate()),
								},
								{
									title: 'twoMonths',
									onClick: () =>
										updateCutItemValue(
											idx,
											'cutExpiresAt',
										)(moment().add(2, 'month').startOf('day').toDate()),
								},
							]}
						>
							<AdvancedDatePicker
								isPopup
								value={cutItem.cutExpiresAt}
								onChange={(newValue) =>
									updateCutItemValue(
										idx,
										'cutExpiresAt',
									)(moment(newValue).startOf('day') ?? new Date())
								}
								disablePast
								disabled={!canEdit}
								renderInput={(params) => (
									<TextField
										size='small'
										fullWidth
										{...params}
										sx={{ backgroundColor: 'white' }}
										disabled={!canEdit}
									/>
								)}
								// TODO: we'll have to work with zIndexes later on
								PopperProps={{
									style: { zIndex: 9999 },
								}}
							/>
						</InputGridField>
					</Grid>
				))}
				<Grid item xs={12} container gap={1}>
					<Grid item flexShrink={1}>
						<Box height='1.5rem' width='1.5rem' p='0.2rem' />
					</Grid>
					<Grid item xs={4} display='flex' alignItems='center' gap={3}>
						<Typography
							sx={{
								...(!weighted && {
									position: 'relative',
									'&::after': {
										position: 'absolute',
										content: '"*"',
										top: '-0.2rem',
									},
								}),
							}}
						>
							leftover
						</Typography>
					</Grid>
					<InputGridField
						flexGrow={2}
						type={ComponentType.TextField}
						// tooltipWhenDisabled='test'
					>
						<TextField
							// label={t('product.arrivedQuantity')}
							label='test'
							size='small'
							type='number'
							disabled={!canEdit || value.estimated}
							InputProps={{
								inputProps: { min: 0 },
							}}
							fullWidth
							value={value.leftWeight}
							onChange={(e) => {
								const newValue = parseInt(e.target.value);
								updateValue({ leftWeight: newValue }, iterator, content.id ? +content.id : -1);
							}}
							sx={{ backgroundColor: 'white' }}
						/>
					</InputGridField>
					<InputGridField
						flexGrow={1}
						sx={{
							justifyContent: 'right',
						}}
						type={ComponentType.TextField}
						tools={[
							{
								title: 'oneMonth',
								onClick: () =>
									updateValue(
										{ leftExpiresAt: moment().add(1, 'month').startOf('day').toDate() },
										iterator,
										content.id ? +content.id : -1,
									),
							},
							{
								title: 'twoMonths',
								onClick: () =>
									updateValue(
										{ leftExpiresAt: moment().add(2, 'month').startOf('day').toDate() },
										iterator,
										content.id ? +content.id : -1,
									),
							},
						]}
					>
						<AdvancedDatePicker
							isPopup
							value={value.leftExpiresAt}
							onChange={(newValue) =>
								updateValue(
									{
										leftExpiresAt: moment(newValue).startOf('day').toDate() ?? new Date(),
									},
									iterator,
									content.id ? +content.id : -1,
								)
							}
							disablePast
							disabled={!canEdit}
							renderInput={(params) => (
								<TextField
									size='small'
									fullWidth
									{...params}
									sx={{ backgroundColor: 'white' }}
									disabled={!canEdit}
								/>
							)}
							// TODO: we'll have to work with zIndexes later on
							PopperProps={{
								style: { zIndex: 9999 },
							}}
						/>
					</InputGridField>
				</Grid>
				{!value.estimated && (
					<FadeWrapper>
						{value.discardedWeight === null ? (
							<Grid item xs={12}>
								<Button
									fullWidth
									size='small'
									variant='outlined'
									onClick={() =>
										updateValue({ discardedWeight: 0 }, iterator, content.id ? +content.id : -1)
									}
									disabled={!canEdit}
								>
									add discard
								</Button>
							</Grid>
						) : (
							<Grid item xs={12} container gap={1}>
								<Grid item flexShrink={1}>
									<Box height='1.5rem' width='1.5rem' p='0.2rem' />
								</Grid>
								<Grid item xs={4} display='flex' alignItems='center'>
									discarded
								</Grid>
								<Grid item flexGrow={1} flexBasis={0} container>
									<InputGridField flexGrow={1} type={ComponentType.TextField}>
										<TextField
											// label={t('product.arrivedQuantity')}
											label='test'
											size='small'
											type='number'
											disabled={
												(taskState !== 'in_progress' && taskState !== 'stub') || editDisabled
											}
											InputProps={{
												// inputProps: { min: 0, max: locked ? content.orderQty : undefined },
												inputProps: { min: 0 },
											}}
											fullWidth
											value={value.discardedWeight}
											onChange={(e) => {
												const newValue = parseInt(e.target.value);
												updateValue(
													{ discardedWeight: newValue },
													iterator,
													content.id ? +content.id : -1,
												);
											}}
											sx={{ backgroundColor: 'white' }}
										/>
									</InputGridField>
									<Grid item flexShrink={1}>
										<IconButton
											size='small'
											onClick={() =>
												updateValue(
													{ discardedWeight: null },
													iterator,
													content.id ? +content.id : -1,
												)
											}
											disabled={taskState !== 'in_progress' || editDisabled}
											sx={{ padding: 1, marginLeft: 0.5 }}
											color='error'
										>
											<Delete />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						)}
					</FadeWrapper>
				)}
			</Grid>
		</CloseOrderFieldListItem>
	);
};
