import { OrderBaseResponse, OrderDirectionType, WarehouseProductSplitRequest } from 'common';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import {
	EnhancedOrderBaseChildRequest,
	OrderApiActionData,
	OrderTypeConfig,
} from './orderTypeConfig';
import { doSplitInventoryProduct } from '@store/inventory';
import { SplitOrderProductRow } from '../suborder/rows/split/SplitSuborderProductRow';
import { SplitSuborderRow } from '../suborder/rows/split/SplitSuborderRow';

export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(false),
		fromType: new OrderElementConfigItem(false),
		shippingType: new OrderElementConfigItem(false),
		toType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		toSubType: new OrderElementConfigItem(false),
		toWarehouseType: new OrderElementConfigItem(false),
		subOrderTypes: new OrderElementConfigItem(false),
	},
	maxSubOrders: 1,
	subOrderElements: {
		borderBottom: new OrderElementConfigItem(false),
		maxWidth: new OrderElementConfigItem('100%'),
	},
	subOrderRow: SplitSuborderRow,
	subOrderProductsContainer: SplitOrderProductRow,
	isAddSuborderDisabled: (order, subOrders) => {
		if (!order.fromId || !order.toId) {
			return true;
		}
		return subOrders.some((so) => !so.content || so.content?.length <= 0);
	},
	isCreateOrderDisabled: () => false,
	executeOrderAction: async (data: OrderApiActionData) => {
		console.log(data);
		if (data.subOrders?.length !== 2) {
			return Promise.reject('Invalid suborders');
		}

		if (
			data.subOrders[1].content?.length !== 1 ||
			!data.subOrders[0].content ||
			data.subOrders[0].content.length < 2
		) {
			return Promise.reject('Invalid suborders content');
		}

		const splitOrderRequest: WarehouseProductSplitRequest = {
			oldProduct: {
				warehouseId: data.subOrders[1].fromId ? +data.subOrders[1].fromId : -1,
				orderId: data.subOrders[1].content[0].orderId ? +data.subOrders[1].content[0].orderId : -1,
				quantity: data.subOrders[1].content[0].arrivedQty ?? 0,
				expiresAt: data.subOrders[1].content[0].expiresAt
					? new Date(data.subOrders[1].content[0].expiresAt)
					: new Date(),
				weight: data.subOrders[1].content[0].weight ?? 0,
				variantId: data.subOrders[1].content[0].variantId ?? -1,
				unit: data.subOrders[1].content[0].unit ?? 'pieces',
				estimated: data.subOrders[1].content[0].estimated ?? false,
				actualExpiration: data.subOrders[1].content[0].actualExpiration
					? new Date(data.subOrders[1].content[0].actualExpiration)
					: new Date(),
			},
			newProducts: data.subOrders[0].content.map((product) => {
				const pickedWeight =
					product.weight !== data.subOrders?.[1].content?.[0].weight ? product.weight : undefined;
				return {
					warehouseId: data.subOrders?.[0].fromId ? +data.subOrders[0].fromId : -1,
					orderId: -1,
					quantity: product.arrivedQty ?? 0,
					expiresAt: data.subOrders?.[1].content?.[0]?.expiresAt
						? new Date(data.subOrders[1].content[0].expiresAt)
						: new Date(),
					pickedWeight,
					actualExpiration: product.expiresAt ? new Date(product.expiresAt) : new Date(),
					variantId: data.subOrders?.[0].content?.[0].variantId ?? -1,
					weight: product.weight ?? 0,
					unit: data.subOrders?.[1].content?.[0].unit ?? 'pieces',
					estimated: false,
				};
			}),
			sourceOrderPrductId: data.sourceOrderProduct?.id ? +data.sourceOrderProduct.id : -1,
		};

		console.log(splitOrderRequest);

		try {
			const result = await data.dispatch(doSplitInventoryProduct(splitOrderRequest));
			if (result.type !== 'inventoryState/split/fulfilled') {
				return Promise.reject('Split failed');
			}
			return Promise.resolve(result.payload as OrderBaseResponse);
		} catch (error) {
			return Promise.reject('Split failed');
		}
		// return Promise.reject('Testing');
	},
};
