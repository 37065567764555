import API from '../index';

export interface UserMetaParams {
	user_id: string;
	payload: any;
}

export const execute = async (data: UserMetaParams) => {
	const response = await API.post(`/api/v1/users/${data.user_id}/meta`, {
		...data.payload,
	});

	if (response.status !== 200 && response.status !== 201) {
		throw new Error('Invalid username/password');
	}
	return response.data;
};
