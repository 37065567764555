import {
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	alpha,
	useTheme,
} from '@mui/material';
import { AppFunction, PersonJobTitleResponse, PersonResponse, TranslationTypes } from 'common';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePeople from '../hooks/usePeople';
import useFeedbacks from '../hooks/useFeedbacks';

import { TabInnerTitle } from './tabs/TabInnerTitle';
import { AnimatedIconButton } from './common/AnimatedIconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { EditShowGridField } from './common/EditShowGridField';
import { TranslatableAutocompleteWithCallback } from './TranslatableAutocompleteWithCallback';
import { JobTitleCreate } from '../pages/People/JobTitleCreate';

interface Props {
	person: PersonResponse;
	isEditing?: boolean;
}

// TODO: add modal for creation of new Job Title
export const JobTitlesSelector = ({ person, isEditing }: Props) => {
	const { t } = useTranslation();
	const { jobTitlesList, peopleList, linkPersonToJobTitle, unlinkPersonFromJobTitle } = usePeople();
	const { showSnackBar } = useFeedbacks();
	const theme = useTheme();

	const [cachedData, setCachedData] = useState(person);
	const [addJobTitle, setAddJobTitle] = useState(false);
	const [jobTitleToAdd, setJobTitleToAdd] = useState(-1);
	const [highlighted, setHighlighted] = useState(-1);

	// #region Job Title Modal
	const [jobTitleModalOpen, setJobTitleModalOpen] = useState(false);
	const [newCustomerTypeName, setNewJobTitleName] = useState('');

	const onNewJobTitleInput = (newName: string) => {
		if (!jobTitleModalOpen) {
			setNewJobTitleName(newName);
			setJobTitleModalOpen(true);
		}
	};

	const closeJobTitleModal = (newJobTitle?: number) => {
		setJobTitleModalOpen(false);
		setJobTitleToAdd(newJobTitle ?? -1);
		setNewJobTitleName('');
	};

	const JobTitleModal = useMemo(() => {
		const ModalComponent = forwardRef((_, ref) => (
			<JobTitleCreate
				isModal
				innerRef={ref}
				newName={newCustomerTypeName}
				closeModal={(newCustomerType) => {
					closeJobTitleModal(newCustomerType);
				}}
			/>
		));
		ModalComponent.displayName = 'Create Job Title';

		return ModalComponent;
	}, [newCustomerTypeName]);
	// #endregion

	const onAddJobTitle = async () => {
		if (jobTitleToAdd !== -1) {
			await linkPersonToJobTitle({ personId: person.id, jobTitleId: jobTitleToAdd });
			showSnackBar({ message: 'Job Title linked', severity: 'success' });
			setJobTitleToAdd(-1);
			setAddJobTitle(false);
		}
	};

	const onUnlinkJobTitle = async (jobTitleId: number) => {
		if (jobTitleId !== -1) {
			await unlinkPersonFromJobTitle({ personId: person.id, jobTitleId: jobTitleId });
			showSnackBar({ message: 'Job Title unlinked', severity: 'success' });
		}
	};

	useEffect(() => {
		if (person) {
			const newPerson = peopleList.find((p) => p.id === person.id);
			if (newPerson) {
				setCachedData(newPerson);
			}
		}
	}, [peopleList]);

	const jobTitleOptions = useMemo(() => {
		const options: PersonJobTitleResponse[] = [];
		jobTitlesList.map((jt) => {
			let push = true;
			cachedData.jobTitles?.map((pjt) => {
				if (pjt.id === jt.id) {
					push = false;
				}
			});
			if (push) {
				options.push(jt);
			}
		});
		return options;
	}, [cachedData.jobTitles]);

	return (
		<>
			<Modal
				open={jobTitleModalOpen}
				onClose={() => setJobTitleModalOpen(false)}
				componentsProps={{
					backdrop: {
						style: {
							backdropFilter: 'blur(1px)',
						},
					},
				}}
			>
				<JobTitleModal />
			</Modal>
			<TabInnerTitle title='person.jobTitles' translate size='small' />
			<Grid container spacing={2}>
				{(!cachedData.jobTitles || cachedData.jobTitles.length <= 0) && !addJobTitle && (
					<Grid item xs={12} key='no-job-titles'>
						{t('person.noJobTitles')}
					</Grid>
				)}
				{cachedData.jobTitles?.map((jt, it) => (
					<Grid item xs={12} key={it} container spacing={0}>
						<Grid
							item
							xs={isEditing ? 11 : 12}
							sx={{
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
								background:
									highlighted === jt.id
										? `linear-gradient(${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 100%)`
										: `linear-gradient(${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 100%)`,
								color: highlighted == jt.id ? theme.palette.primary.contrastText : 'black',
								backgroundSize: highlighted === jt.id ? '100% 100%' : '100% 0%',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								transition: 'background-size 0.2s',
								borderRadius: '2rem 0 0 2rem',
								paddingLeft: '1rem',
							}}
						>
							{t(`jobTitle-${jt.id}-name`, { ns: 'locale' })}
						</Grid>
						{isEditing && (
							<Grid
								item
								xs={1}
								sx={{
									display: 'flex',
									flexDirection: 'row-reverse',
									position: 'relative',
									background:
										highlighted === jt.id
											? `linear-gradient(${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 100%)`
											: `linear-gradient(${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 100%)`,
									backgroundSize: highlighted === jt.id ? '100% 100%' : '100% 0%',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
									transition: 'background-size 0.2s',
									borderRadius: '0 2rem 2rem 0',
								}}
							>
								<IconButton
									size='small'
									type='submit'
									sx={{
										transition: '300ms all cubic-bezier(.58,.16,.45,1.51)',
										backgroundColor: theme.palette.error.main,
										color: theme.palette.primary.contrastText,
										'&:hover': {
											color: theme.palette.error.main,
											backgroundColor: theme.palette.primary.contrastText,
										},
									}}
									onClick={() => onUnlinkJobTitle(jt.id)}
									onMouseEnter={() => setHighlighted(jt.id)}
									onMouseLeave={() => setHighlighted(-1)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						)}
					</Grid>
				))}
				{addJobTitle && (
					<>
						<Grid item xs={10.4}>
							<TranslatableAutocompleteWithCallback
								data={jobTitleOptions}
								value={jobTitleToAdd}
								translationSettings={{
									translatableType: AppFunction.JobTitle,
									translationType: TranslationTypes.name,
									namespace: 'locale',
								}}
								id='jobTitle'
								label={t('person.jobTitle')}
								onChange={(value) => setJobTitleToAdd(value)}
								onCreateNew={onNewJobTitleInput}
							/>
						</Grid>
						<Grid
							item
							xs={1.6}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<IconButton
								size='small'
								type='submit'
								sx={{
									transition: '300ms all ease-in-out',
									backgroundColor: theme.palette.success.main,
									color: theme.palette.primary.contrastText,
									marginRight: 'auto',
									'&:hover': {
										backgroundColor: alpha(theme.palette.success.main, 0.5),
									},
								}}
								onClick={() => onAddJobTitle()}
							>
								<CheckIcon />
							</IconButton>
							<IconButton
								size='small'
								type='submit'
								sx={{
									transition: '300ms all ease-in-out',
									backgroundColor: theme.palette.error.main,
									color: theme.palette.primary.contrastText,
									'&:hover': {
										backgroundColor: alpha(theme.palette.error.main, 0.5),
									},
								}}
								onClick={() => setAddJobTitle(false)}
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</>
				)}
				{isEditing && !addJobTitle ? (
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<AnimatedIconButton
							text='operations.create'
							colorType='info'
							translate
							icon={<AddIcon />}
							onClick={() => setAddJobTitle(true)}
						/>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</>
	);
};
