import { Settings } from '../app';

export const defaultSettings = (userId: number): Settings => {
	return {
		userId: userId,
		listSettings: {},
		textSize: 12,
		showDrawer: false,
		language: 'jp',
	};
};
