import { Fragment, useEffect, useState } from 'react';
import useTasks from '../../../hooks/useTasks';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldSetItem } from '../FieldSetItem';
import { DragZoneName } from '../../../pages/Tasks/TaskChainArchetypeCreate';

interface Props {
	searchWord?: string;
}

export const FieldSetDrawer = ({ searchWord }: Props) => {
	const { taskFieldSetsList } = useTasks();
	const [open, setOpen] = useState(true);
	const [filtered, setFiltered] = useState(taskFieldSetsList);

	useEffect(() => {
		if (searchWord && searchWord !== '') {
			const filtered = taskFieldSetsList.filter((archetype) => {
				return archetype.name.toLowerCase().includes(searchWord.toLowerCase());
			});
			setFiltered(filtered);
		} else {
			setFiltered(taskFieldSetsList);
		}
	}, [searchWord, taskFieldSetsList]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					cursor: 'pointer',
					userSelect: 'none',
					padding: '0 1rem 0 1rem',
				}}
				onClick={() => setOpen((openState) => !openState)}
			>
				<KeyboardArrowRight sx={{ transform: `rotate(${open ? 90 : 0}deg)` }} />
				<div>Task Field Sets</div>
			</div>
			<Collapse in={open}>
				<Droppable droppableId={DragZoneName.fieldSetArchetypeDroppableList} isDropDisabled={true}>
					{(provided) => (
						<List ref={provided.innerRef} {...provided.droppableProps} sx={{ padding: '1rem' }}>
							{filtered.map((archetype, index) => (
								<Fragment key={archetype.id}>
									<Draggable draggableId={archetype.id.toString()} index={index}>
										{(provided, snapshot) => {
											return (
												<Fragment>
													<FieldSetItem
														provided={provided}
														archetype={archetype}
														isDragging={snapshot.isDragging}
													/>
													{snapshot.isDragging && (
														<FieldSetItem archetype={archetype} isDragging={false} />
													)}
												</Fragment>
											);
										}}
									</Draggable>
								</Fragment>
							))}
							<div style={{ height: '0px' }}>{provided.placeholder}</div>
						</List>
					)}
				</Droppable>
			</Collapse>
		</>
	);
};
