import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { EnhancedVariant } from '@contexts/orderEditorContext/types';
import { Numbers } from '@mui/icons-material';
import { GridSize, InputAdornment, TextField } from '@mui/material';
import { AppFunction } from 'common';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

interface Props {
	width?: GridSize;
	quantity: number;
	value: EnhancedVariant;
	onChange: (value: number) => void;
}

export const ProductQuantitySelector = ({
	width,
	quantity,
	value,
	onChange,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const index = uniqueId();

	return (
		<InputGridField
			width={width}
			flexGrow={0.1}
			tools={[
				{
					title: 'setMax',
					text: 'godmode',
					onClick: () => onChange(value.maxAmount ?? 1),
				},
			]}
			type={ComponentType.TextField}
		>
			<TextField
				id={`order-quantity-idx-${index}`}
				fullWidth
				variant='outlined'
				size='small'
				label={t(`${AppFunction.Product}.quantity`)}
				type='number'
				value={quantity}
				InputProps={{
					inputProps: {
						min: 1,
						max: value.maxAmount ?? 9999,
					},
					startAdornment: (
						<InputAdornment position='start'>
							<Numbers />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position='end'>
							{t(`${AppFunction.Product}.units.pieces`, { count: quantity })}
						</InputAdornment>
					),
				}}
				onChange={(event) => onChange(+event.target.value)}
			/>
		</InputGridField>
	);
};
