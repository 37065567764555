import { useEffect, useState } from 'react';
import { Grid, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface Props {
	value: Vector2;
	label?: string;
	adornments?: typeof InputAdornment[];
	disabled?: boolean;
	sx?: SxProps<Theme>;
	onChange: (value: Vector2) => void;
}

export type Vector2 = {
	x: number;
	y: number;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		hover: {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.primary.main,
			},
		},
		focus: {
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: '2px',
			},
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderWidth: '2px',
			},
		},
		leftTextField: {
			'& .MuiOutlinedInput-root': {
				borderRadius: theme.spacing(1, 0, 0, 1),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderRight: 'none',
			},
		},
		rightTextField: {
			'& .MuiOutlinedInput-root': {
				borderRadius: theme.spacing(0, 1, 1, 0),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderLeft: 'none',
			},
		},
	}),
);

const VectorTwoInput = ({ value, label, disabled, sx, onChange }: Props) => {
	const classes = useStyles();
	const [values, setValues] = useState<Vector2>(value);
	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		setValues((oldValues) => {
			if (value.x !== oldValues.x || value.y !== oldValues.y) {
				return value;
			}
			return oldValues;
		});
	}, [value]);

	const onValueChange = (index: number, value: string) => {
		onChange({ x: index === 0 ? +value : values.x, y: index === 1 ? +value : values.y });
	};

	return (
		<Grid
			container
			spacing={0}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className={isFocused ? classes.focus : ''}
		>
			<Grid item xs={6}>
				<TextField
					fullWidth
					variant='outlined'
					type='number'
					label={label ?? ''}
					inputProps={{ min: 0, max: 23 }}
					size='small'
					disabled={disabled}
					// InputProps={{
					// 	startAdornment: <InputAdornment position='start'>W:</InputAdornment>,
					// }}
					value={values.x}
					onChange={(ev) => onValueChange(0, ev.target.value)}
					className={`${classes.leftTextField} ${isHovered || isFocused ? classes.hover : ''} ${
						isFocused ? classes.focus : ''
					}`}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					sx={sx}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					variant='outlined'
					type='number'
					size='small'
					disabled={disabled}
					inputProps={{ min: 0, max: 59 }}
					// InputProps={{
					// 	startAdornment: <InputAdornment position='start'>D:</InputAdornment>,
					// }}
					value={values.y}
					onChange={(ev) => onValueChange(1, ev.target.value)}
					className={`${classes.rightTextField} ${isHovered || isFocused ? classes.hover : ''} ${
						isFocused ? classes.focus : ''
					}`}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					sx={sx}
				/>
			</Grid>
		</Grid>
	);
};

VectorTwoInput.displayName = 'VectorTwoInput';
export default VectorTwoInput;
