import { EditShowGridField } from '@components/common/EditShowGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';
import useAddresses from '@hooks/useAddresses';
import useCarriers from '@hooks/useCarriers';
import useLocale from '@hooks/useLocale';
import { Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, CustomerResponse, InvoicingMethod, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarrierDeliveryTime } from './CarrierDeliveryTime';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const DeliveryInfo = () => {
	const { control, getValues, formAction, formValues } = useGenericForm<CustomerResponse>();
	const { addressMenuItems } = useCustomerForm();
	const { renderAddress } = useAddresses();
	const { carrierList } = useCarriers();
	const { t } = useTranslation();
	const { renderI18NField, getI18NString } = useLocale();

	const editDisabled = formAction === 'view';

	return (
		<>
			<TabInnerTitle title='customer.deliveryInfo' translate />
			<Grid container spacing={2}>
				<Controller
					name='deliveryAddressId'
					control={control}
					defaultValue={-1}
					render={({ field }) => (
						<InputGridField
							width={12}
							muiLabel={{
								label: t('customer.deliveryAddress'),
								labelId: 'delivery_address',
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='delivery_address'
								id='prefecture'
								fullWidth
								size='small'
								label={t('customer.deliveryAddress')}
								{...field}
								value={field.value ?? -1}
								onChange={(ev) => field.onChange(+ev.target.value)}
							>
								{addressMenuItems?.(true)}
							</Select>
						</InputGridField>
					)}
				/>
				{getValues('deliveryAddressId') && (
					<Controller
						name='carrierId'
						control={control}
						render={({ field }) => (
							<InputGridField
								width={12}
								muiLabel={{
									label: t('customer.preferredCarrier'),
									labelId: 'preferred_carrier',
								}}
								type={ComponentType.Select}
							>
								<Select
									labelId='preferred_carrier'
									id='preferredCarrier'
									label={t('customer.preferredCarrier')}
									fullWidth
									size='small'
									value={field.value ?? -1}
									onChange={(ev) => {
										field.onChange(+ev.target.value !== -1 ? +ev.target.value : null);
									}}
								>
									<MenuItem value={-1} key={'none'}>
										None
									</MenuItem>
									{carrierList.map((u, index) => {
										if (u.id) {
											return (
												<MenuItem value={u.id} key={index}>
													{renderI18NField(
														getI18NString(AppFunction.Carrier, u.id, TranslationTypes.name),
													)}
												</MenuItem>
											);
										}
									})}
								</Select>
							</InputGridField>
						)}
					/>
				)}
				<CarrierDeliveryTime />
			</Grid>
		</>
	);
};
