import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from '@hooks/auth';
import { Cancel, Save } from '@mui/icons-material';
import { Grid, MenuItem, Select, TextField, ToggleButton } from '@mui/material';
import { AppFunction, NoteResponse, NoteSchema, NoteType } from 'common';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import useNotes from '@hooks/useNotes';
import { ContextualDropdownMenu } from '@components/ContextualDropdownMenu';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	entityId: number;
	entityType: AppFunction;
	noteData?: NoteResponse;
	editingNote: number;
	onClose: () => void;
	onEdit: () => void;
}

function preprocessNoteData(data: NoteResponse): NoteResponse {
	return {
		...data,
		createdAt: data.createdAt ? new Date(data.createdAt) : null,
		updatedAt: data.updatedAt ? new Date(data.updatedAt) : null,
	};
}

export const NoteComponent = ({
	entityId,
	entityType,
	noteData,
	editingNote,
	onClose,
	onEdit,
}: Props) => {
	const auth = useAuth();
	const { createNote, editNote, deleteNote } = useNotes();
	const { t } = useTranslation();

	const editing = editingNote === (noteData?.id ?? -1);

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<NoteResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: noteData
			? preprocessNoteData(noteData)
			: {
					id: -1,
					entityId,
					entityType,
					noteType: NoteType.postit,
					color: '#ffffff',
					private: false,
					noteContent: '',
			  },
		resolver: zodResolver(NoteSchema),
	});

	useEffect(() => {
		setValue('userId', auth?.user?.id ?? -1);
	}, [auth]);

	const onSubmit = async (data: NoteResponse) => {
		if (data.id === -1) {
			const result = await createNote(data);
			if (result) {
				console.log('note created');
			} else {
				console.log('error');
			}
		} else {
			const result = await editNote(data);
			if (result) {
				console.log('note edited');
			} else {
				console.log('error');
			}
		}
		onClose();
	};

	const disabled = editingNote !== 0 && editingNote !== (noteData?.id ?? -1);

	return (
		<div
			style={{
				border: '1px solid #ccc',
				borderRadius: '1rem',
				padding: '1rem',
				opacity: disabled ? 0.5 : 1,
				transition: 'opacity 0.4s ease-in-out',
			}}
		>
			<Grid container display='flex' spacing={1}>
				<Grid item flexGrow={1} flexBasis={0} container spacing={2}>
					<Controller
						name='noteType'
						control={control}
						render={({ field }) => (
							<InputGridField
								width={10}
								muiLabel={{
									label: 'Note Type',
									labelId: 'note-type-label',
								}}
								type={ComponentType.Select}
							>
								<Select
									label='Note Type'
									variant='outlined'
									size='small'
									fullWidth
									disabled={!editing}
									value={field.value}
									onChange={(e) => field.onChange(e.target.value as NoteType)}
								>
									{Object.values(NoteType).map((type) => (
										<MenuItem key={type} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</InputGridField>
						)}
					/>
					<Controller
						name='private'
						control={control}
						render={({ field }) => (
							<InputGridField width={2} type={ComponentType.ToggleButton}>
								<ToggleButton
									value='check'
									size='small'
									disabled={!editing}
									selected={field.value}
									onChange={() => field.onChange(!field.value)}
									sx={{
										borderColor: field.value ? 'error.main' : 'success.main',
									}}
									color='error'
								>
									{field.value ? t(`${AppFunction.Note}.private`) : t(`${AppFunction.Note}.public`)}
								</ToggleButton>
							</InputGridField>
						)}
					/>
					<Controller
						name='noteContent'
						control={control}
						render={({ field }) => (
							<InputGridField width={12} type={ComponentType.TextField}>
								<TextField
									{...field}
									label='Note'
									multiline
									fullWidth
									disabled={!editing}
									variant='outlined'
								/>
							</InputGridField>
						)}
					/>
				</Grid>
				<Grid
					item
					flexShrink={1}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ContextualDropdownMenu
						onEdit={() => onEdit()}
						onDelete={() => deleteNote({ id: noteData?.id ?? -1 })}
						disabled={disabled}
					/>
				</Grid>
				{editing && (
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
						}}
					>
						<AnimatedIconButton
							text={t('operations.save')}
							colorType='success'
							icon={<Save />}
							onClick={handleSubmit(onSubmit)}
						/>
						<AnimatedIconButton
							text={t('operations.cancel')}
							colorType='error'
							icon={<Cancel />}
							onClick={() => onClose()}
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
};
