import { Box, Grid, Stack } from '@mui/material';
import useAddresses from '../../hooks/useAddresses';

import { AddressChip, AddressComponent } from './AddressComponent';
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TabInnerTitle } from '../tabs/TabInnerTitle';

import AddIcon from '@mui/icons-material/Add';

import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { AddressableAppFunction } from 'common';

interface Props {
	entityId: number;
	entityType: AddressableAppFunction;
	isEditing?: boolean;
	chips?: AddressChip[];
	onAfterDelete?: (parentId: number) => void;
	hideBox?: boolean;
	maxAddresses?: number;
}

// TODO: destructure props plz
export const AddressList = (props: Props) => {
	const { t } = useTranslation();
	const { addressList, createEmptyAddress } = useAddresses();

	const [isCreating, setCreating] = useState(false);
	const [expandedId, setExpandedId] = useState<number | null>(null);
	const [full, setFull] = useState(false);

	const Addresses = useMemo(() => {
		if (addressList.length <= 0) {
			return <>No Addresses</>;
		} else {
			const list: JSX.Element[] = [];
			addressList
				.filter((x) => x.addressableId === props.entityId && x.type === props.entityType)
				.map((x, it) =>
					list.push(
						<AddressComponent
							key={it}
							address={x}
							chips={props.chips}
							onAfterDelete={props.onAfterDelete}
							canEdit={props.isEditing}
							expandedId={expandedId}
							onExpand={(id: number | null) => {
								setExpandedId(id);
								setCreating(false);
							}}
						/>,
					),
				);
			if (props.maxAddresses && list.length >= props.maxAddresses) {
				setFull(true);
			} else {
				setFull(false);
			}
			if (list.length > 0) {
				return <Stack spacing={2}>{list}</Stack>;
			}
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>{t('address.noAddresses')}</div>
			);
		}
	}, [addressList, props.isEditing]);

	return (
		<Box>
			<TabInnerTitle title='appFunctions.address' translate size='small' />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{Addresses}
				</Grid>
				{isCreating ? (
					<Grid item xs={12} sx={{ position: 'relative' }}>
						<AddressComponent
							address={createEmptyAddress(props.entityType, props.entityId)}
							canEdit={props.isEditing}
							onStopCreating={() => setCreating(false)}
							expandedId={expandedId ?? -1}
							onExpand={(id: number | null) => setExpandedId(id)}
						/>
					</Grid>
				) : !full && props.isEditing ? (
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<AnimatedIconButton
							text='operations.create'
							colorType='info'
							translate
							icon={<AddIcon />}
							onClick={() => setCreating(true)}
						/>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Box>
	);
};
