import Dexie from 'dexie';
import { Settings } from '../../app';

class SettingsDatabase extends Dexie {
	public settings!: Dexie.Table<Settings, number>;
	public constructor() {
		super('SettingsDatabase');
		this.version(1).stores({
			settings: 'userId',
		});
	}
}

export default SettingsDatabase;
