import { Chip } from '@mui/material';
import { AppFunction, OrderBaseResponse, OrderStatus } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	data: OrderBaseResponse;
}

export const OrderStatusChip = ({ data }: Props) => {
	const { t } = useTranslation();

	if (!data.status) {
		return <>N/A</>;
	}
	let color: 'default' | 'error' | 'warning' | 'info' | 'success' = 'default';
	switch (data.status) {
		case OrderStatus.Done:
			color = 'success';
			break;
		case OrderStatus.Pending:
			color = 'warning';
			break;
	}
	return (
		<Chip
			size='small'
			color={color}
			label={t(`${AppFunction.Order}.statuses.${data.status?.toLowerCase()}`)}
			sx={{ width: '100%' }}
		/>
	);
};
