import { EditShowGridField } from '@components/common/EditShowGridField';
import {
	DirectionType,
	createAdornment,
} from '@components/taskworkflow/chain/DestinationTypeSelector';
import { useChainArchetypeEditor } from '@contexts/index';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, CustomerResponse, OrderDirectionType, TranslationTypes } from 'common';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
	directionType: DirectionType;
}

export const BranchSelector = ({ directionType }: Props) => {
	const { triggerControl, setTriggerValue, watchTrigger } = useChainArchetypeEditor();
	const { customerList } = useCustomers();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const currentType = directionType === 'from' ? watchTrigger('fromType') : watchTrigger('toType');
	const entityId = directionType === 'from' ? watchTrigger('fromId') : watchTrigger('toId');
	const entity = useMemo((): CustomerResponse | null | undefined => {
		if (currentType === OrderDirectionType.customer) {
			return customerList.find((c) => c.id === entityId);
		} else if (currentType === OrderDirectionType.branch) {
			return customerList.find((c) => c.customerBranches?.some((b) => b.id === entityId));
		}
		return null;
	}, [entityId, currentType, customerList]);

	if (!entity || !entity.customerBranches || entity.customerBranches?.length === 0) {
		return null;
	}

	return (
		<Controller
			name={directionType === 'from' ? 'fromId' : 'toId'}
			control={triggerControl}
			render={({ field }) => (
				<EditShowGridField isEditing={true} width={12}>
					<InputLabel id='branch-select-label'>
						{t(`appFunctions.${AppFunction.CustomerBranch}`)}
					</InputLabel>
					<Select
						id='branch-select'
						fullWidth
						label={t(`appFunctions.${AppFunction.CustomerBranch}`)}
						labelId='branch-select-label'
						value={
							entity.customerBranches?.some((b) => b.id === field.value) || field.value === -2
								? field.value
								: -1
						}
						size='small'
						onChange={(e) => {
							const value = e.target.value as number;
							if (!value || isNaN(value)) {
								return;
							}
							if (value === -1) {
								setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
								field.onChange(-1);
							} else {
								setTriggerValue(
									directionType === 'from' ? 'fromType' : 'toType',
									OrderDirectionType.branch,
								);
							}
							field.onChange(value);
						}}
						endAdornment={createAdornment(field.value ?? -1, () => {
							setTriggerValue(
								directionType === 'from' ? 'fromType' : 'toType',
								OrderDirectionType.customer,
							);
							field.onChange(entity.id ?? -1);
						})}
					>
						<MenuItem
							value={-1}
							sx={{
								backgroundColor: 'rgba(255, 0, 0, 0.2)',
							}}
						>
							{t('common.none')}
						</MenuItem>
						<MenuItem
							value={-2}
							sx={{
								backgroundColor: 'rgba(0, 255, 0, 0.2)',
							}}
						>
							{t('common.all')}
						</MenuItem>
						{entity.customerBranches?.map((branch) => (
							<MenuItem key={`branch_${branch.id}`} value={branch.id}>
								{getTranslatedString(AppFunction.CustomerBranch, branch.id, TranslationTypes.name)}
							</MenuItem>
						))}
					</Select>
				</EditShowGridField>
			)}
		/>
	);
};
