import { PopupData } from '@components/layout/PopupsHandler';
import { AppFunction } from 'common';

export interface LoadingData {
	contentType: AppFunction | string;
	icon?: JSX.Element;
}

export type PaddingPosition = 'top' | 'right' | 'bottom' | 'left';

export type ElementPosition = { position: PaddingPosition; padding: number };

export type PaddingSettings = { [key in PaddingPosition]: number };

export interface BreadCrumb {
	label: string;
	icon?: JSX.Element;
	route?: string;
	callback?: () => void;
}

export type FabPlacement = 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft';

export interface FabData {
	id: string;
	icon?: JSX.Element;
	placement: FabPlacement;
	tooltip?: string;
	targetUrl?: string;
	customAction?: () => void;
	subActions?: FabData[];
	permission?: AppFunction;
	color?: string;
	disabled?: boolean;
	size?: 'small' | 'medium' | 'large';
}

export enum DeviceType {
	desktop = 'desktop',
	tablet = 'tablet',
	mobile = 'mobile',
}

export interface SubMenuElement {
	label: string;
	icon: JSX.Element;
	onClick?: () => void;
	translate?: boolean;
	disabled?: boolean;
	hidden?: boolean;
	nestedElements?: SubMenuElement[];
}

export interface LayoutContextType {
	visiblePadding: PaddingSettings;
	drawerOpen: boolean;
	showHelp: boolean;
	breadCrumbs: BreadCrumb[];
	sideMenuContent: React.ReactNode | null;
	popupContent: PopupData[];
	popupStates: Record<string, boolean>;
	loadingData: LoadingData | null;
	fabData: FabData[];
	deviceType: DeviceType;
	selectedSubMenuArray: number[] | null;
	subMenuElements: SubMenuElement[] | null;
	subMenuFullScreen: boolean;
	subMenuPosition: DOMRect | null;
	setDrawerOpen: (open?: boolean) => void;
	setFontSize: (size: number) => void;
	registerElement: (id: string, position: PaddingPosition, padding: number) => void;
	unregisterElement: (id: string) => void;
	setBreadCrumbs: (breadCrumbs: BreadCrumb[]) => void;
	setSideMenu: (content: React.ReactNode | null) => void;
	popPopup: (id: string) => void;
	pushPopup: (
		content: React.ReactNode,
		movable?: boolean,
		expandable?: boolean,
		customId?: string,
	) => void;
	closeAllPopups: () => void;
	setHasClickedInPopup: (hasClicked: boolean) => void;
	pushLoadingData: (loadingData: LoadingData) => void;
	popLoadingData: () => void;
	setFabs: (fabData: Partial<FabData> | Partial<FabData>[]) => void;
	setSubMenuFullScreen: (value: boolean) => void;
	selectSubMenuElement: (index: number | null, recursionIndex?: number) => void;
	pushSubMenuElements: (elements: SubMenuElement[] | null) => void;
	setSubMenuPosition: (position: DOMRect | null) => void;
	pixelsToRem: (pixels: number) => number;
	remToPixels: (rem: number) => number;
}

export interface LayoutContextProps {
	children: React.ReactNode;
}
