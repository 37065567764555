import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { InvoicingTimes } from '@components/invoicing/InvoicingTimes';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';
import useAddresses from '@hooks/useAddresses';
import { Chip, Grid, MenuItem, Select } from '@mui/material';
import {
	CustomerResponse,
	InvoicingMethod,
	AppFunction,
	AddressResponse,
	CustomerInvoicingTimesResponse,
} from 'common';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const InvoicingInfo = () => {
	const { entityId, formValues, control, getValues, watch, setValue, formAction } =
		useGenericForm<CustomerResponse>();
	const { cachedParent, addressMenuItems } = useCustomerForm();
	const { addressList, renderAddress } = useAddresses();
	const { t } = useTranslation();

	const separateData = watch('separateData');
	const invoicingMethod = watch('invoicingMethod');

	const addressItems = addressMenuItems?.(true) ?? [];

	const editDisabled = formAction === 'view';

	const renderInvoicingTimes = () => {
		let times: CustomerInvoicingTimesResponse = [];
		if (cachedParent && !separateData) {
			times = cachedParent.invoicingTimes ?? [];
		} else {
			times = formValues?.invoicingTimes ?? [];
		}
		// const times =
		// 	formValues && !separateData ? cachedParent?.invoicingTimes : formValues?.invoicingTimes;
		return (
			<InvoicingTimes
				times={times ?? []}
				customerId={entityId}
				view={cachedParent && !separateData ? true : editDisabled}
			/>
		);
	};

	const EmailMenuItems = useMemo(() => {
		const items: JSX.Element[] = [];

		items.push(
			<MenuItem value={-1} key={'none'}>
				None
			</MenuItem>,
		);

		cachedParent?.addresses?.map((address, index) => {
			if (address.email && address.email !== '') {
				const parsedAddress = (
					<MenuItem value={address.id ?? -1} key={index}>
						{renderAddress(address.id ?? null, true, InvoicingMethod.email)}
					</MenuItem>
				);
				if (parsedAddress) {
					items.push(parsedAddress);
				}
			}
		});

		formValues?.addresses?.map((address, index) => {
			if (address.email && address.email !== '') {
				const parsedAddress = (
					<MenuItem value={address.id ?? -1} key={(cachedParent?.addresses?.length ?? 0) + index}>
						{renderAddress(address.id ?? null, false, InvoicingMethod.email)}
					</MenuItem>
				);
				if (parsedAddress) {
					items.push(parsedAddress);
				}
			}
		});

		return items;
	}, [cachedParent, formValues, addressList]);

	// TODO: move createItems
	const FaxMenuItems = useMemo(() => {
		const items: JSX.Element[] = [];

		items.push(
			<MenuItem value={-1} key={'none'}>
				None
			</MenuItem>,
		);
		const parentId = getValues('parentId');

		const createAddress = (
			address: AddressResponse,
			index: number,
			isCompany: boolean,
		): JSX.Element | null => {
			if (address.id && address.fax && address.fax !== '') {
				return (
					<MenuItem value={address.id} key={index}>
						<div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
							<div style={{ flexGrow: 1 }}>
								{isCompany && (
									<Chip size='small' label='Parent Company' sx={{ marginRight: '1rem' }} />
								)}
								{address.fax}
							</div>
							{address.shortCut && (
								<div style={{ fontSize: '0.5rem', fontWeight: 'bold' }}>
									{`[${address.shortCut}]`}
								</div>
							)}
						</div>
					</MenuItem>
				);
			}
			return null;
		};

		if (parentId != undefined && parentId !== -1) {
			addressList
				.filter(
					(address) =>
						address.addressableId === parentId &&
						address.type === AppFunction.Customer &&
						address.fax &&
						address.fax !== '',
				)
				.map((address, index) => {
					const parsedAddress = createAddress(address, index, true);
					if (parsedAddress) {
						items.push(parsedAddress);
					}
				});
		}

		addressList
			.filter(
				(address) =>
					address.addressableId === entityId &&
					address.type === AppFunction.Customer &&
					address.fax &&
					address.fax !== '',
			)
			.map((address, index) => {
				const parsedAddress = createAddress(address, index, false);
				if (parsedAddress) {
					items.push(parsedAddress);
				}
			});

		return items;
	}, [cachedParent, formValues, addressList]);

	const InvoicingMethods = useMemo(() => {
		let element = <></>;
		const renderItems = (): JSX.Element[] => {
			switch (invoicingMethod) {
				case InvoicingMethod.email:
					return EmailMenuItems;
				case InvoicingMethod.fax:
					return FaxMenuItems;
				case InvoicingMethod.mail:
					return addressItems ?? [];
			}
			return [];
		};

		if (invoicingMethod !== InvoicingMethod.none && renderItems().length > 1) {
			element = (
				<Controller
					name='physicalAddressId'
					control={control}
					defaultValue={-1}
					render={({ field }) => {
						function Body(): JSX.Element {
							return (
								renderAddress(
									field.value ?? null,
									cachedParent !== null && !separateData,
									invoicingMethod,
								) ?? <></>
							);
						}
						return (
							<InputGridField
								width={12}
								muiLabel={{
									label: t('customer.physicalAddress'),
									labelId: 'physical_address',
								}}
								type={ComponentType.Select}
							>
								<Select
									labelId='physical_address'
									id='physical'
									label={t('customer.physicalAddress')}
									fullWidth
									size='small'
									disabled={cachedParent && !separateData ? false : editDisabled}
									{...field}
									value={field.value ?? -1}
									onChange={(ev) => field.onChange(+ev.target.value)}
								>
									{renderItems()}
								</Select>
							</InputGridField>
						);
					}}
				/>
			);
		}
		return element;
	}, [
		cachedParent,
		formValues,
		addressList,
		editDisabled,
		invoicingMethod,
		separateData,
		addressItems,
	]);

	const DetailsSelector = useMemo(() => {
		if (cachedParent?.sendDetails === true) {
			return (
				<Controller
					name='detailsAddressId'
					control={control}
					defaultValue={-1}
					render={({ field }) => (
						<InputGridField
							width={12}
							muiLabel={{
								label: t('customer.detailsAddress'),
								labelId: 'details_address',
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='details_address'
								id='details-address'
								fullWidth
								disabled={editDisabled}
								size='small'
								label={t('customer.detailsAddress')}
								{...field}
								value={field.value ?? -1}
								onChange={(ev) => field.onChange(+ev.target.value)}
							>
								{addressItems}
							</Select>
						</InputGridField>
					)}
				/>
			);
		}
		return <></>;
	}, [cachedParent, formValues, addressList, editDisabled, addressItems]);

	return (
		<>
			<TabInnerTitle title='customer.invoicingInfo' translate />
			<Grid container spacing={2}>
				{DetailsSelector}
				<Controller
					name='fiscalAddressId'
					control={control}
					defaultValue={-1}
					render={({ field }) => (
						<InputGridField
							width={12}
							muiLabel={{
								label: t('customer.fiscalAddress'),
								labelId: 'fiscal_address',
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='fiscal_address'
								id='prefecture'
								label={t('customer.fiscalAddress')}
								fullWidth
								size='small'
								disabled={cachedParent !== null && !separateData}
								{...field}
								value={field.value ?? -1}
								onChange={(ev) => field.onChange(+ev.target.value)}
							>
								{addressMenuItems?.(true)}
							</Select>
						</InputGridField>
					)}
				/>
				<Grid item xs={12}>
					<TabInnerTitle title='customer.invoicingTime' translate />
				</Grid>
				{renderInvoicingTimes()}
				<Grid item xs={12}>
					<TabInnerTitle title='customer.invoicingMethod.method' translate />
				</Grid>
				<Controller
					name='invoicingMethod'
					control={control}
					defaultValue={InvoicingMethod.mail}
					render={({ field }) => {
						const method = field.value ?? InvoicingMethod.none;
						return (
							<InputGridField
								width={12}
								muiLabel={{
									label: t('customer.invoicingMethod.method'),
									labelId: 'invoicingMethod',
								}}
								type={ComponentType.Select}
							>
								<Select
									labelId='invoicingMethod'
									id='invoicing-method-type'
									label={t('customer.invoicingMethod.method')}
									fullWidth
									size='small'
									disabled={cachedParent !== null && !separateData}
									{...field}
									onChange={(ev) => {
										if (ev.target.value !== method) {
											setValue('physicalAddressId', null);
										}
										field.onChange(ev.target.value as InvoicingMethod);
									}}
								>
									{(Object.keys(InvoicingMethod) as (keyof typeof InvoicingMethod)[]).map(
										(key, index) => (
											<MenuItem value={InvoicingMethod[key]} key={index}>
												{t(`customer.invoicingMethod.${InvoicingMethod[key]}`)}
											</MenuItem>
										),
									)}
								</Select>
							</InputGridField>
						);
					}}
				/>
				{InvoicingMethods}
			</Grid>
		</>
	);
};
