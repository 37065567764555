import { Box, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { NextAssignableAtInput } from '../NextAssignableAtInput';
import { useChainArchetypeEditor } from '@contexts/chainArchetypeEditorContext/ChainArchetypeEditorContext';
import { DestinationTypeSelector } from './DestinationTypeSelector';
import { TabInnerTitle } from '../../tabs/TabInnerTitle';
import { useTranslation } from 'react-i18next';

interface Props {
	viewMode?: boolean;
}

export const ChainArchetypeTail = ({ viewMode }: Props) => {
	const { editorWidth, chainControl, allowedToTypes } = useChainArchetypeEditor();
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				position: 'relative',
				width: `${editorWidth}rem`,
				backgroundColor: 'white',
				borderRadius: '15px',
				padding: '1rem',
				border: '1px solid black',
				transition: 'width 0.2s cubic-bezier(0.34, 1.56, 0.84, 1)',
			}}
		>
			<Grid container spacing={2}>
				{allowedToTypes.length > 0 && (
					<>
						<Grid item xs={12}>
							<TabInnerTitle title={t('taskChainArchetype.endType')} size='small' />
						</Grid>
						<DestinationTypeSelector directionType='to' />
					</>
				)}
				<Grid item xs={12}>
					<TabInnerTitle title='Chain Approximate Completion Time' size='small' />
				</Grid>
				<Grid item xs={12}>
					<Controller
						name='estimateCompletionTime'
						control={chainControl}
						render={({ field }) => (
							<NextAssignableAtInput
								value={field.value}
								onChange={field.onChange}
								isEditing={!viewMode}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
