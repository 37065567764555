import { Box, useTheme } from '@mui/material';
import { MultipleTaskProvider } from '@contexts/multipleTaskContext/MultipleTaskContext';
import { CloseAllButton } from '../../shared/CloseAllButton';
import { TaskListView } from './TaskListView';

interface Props {
	taskIds: number[];
	popupId?: string;
}

export const CompoundTaskView = ({ taskIds, popupId }: Props) => {
	const theme = useTheme();

	const taskViews = taskIds.map((taskId, it) => {
		return <TaskListView taskId={taskId} key={`${taskId}-${it}`} />;
	});

	return (
		<MultipleTaskProvider>
			<Box
				sx={{
					height: '100%',
					background: theme.palette.gradient.secondary,
				}}
			>
				{taskViews}
				<CloseAllButton popupId={popupId} />
			</Box>
		</MultipleTaskProvider>
	);
};
