import { Grid, Typography } from '@mui/material';
import useLocale from '@hooks/useLocale';
import { AppFunction, TranslationTypes } from 'common';
import { FileDropZone } from '@components/common/FileDropzone';
import { useTask } from '@contexts/index';

interface Props {
	taskFieldId: number;
}

export const FileInputField = ({ taskFieldId }: Props) => {
	const { getTranslatedString } = useLocale();
	const { setTaskFieldValues, taskState } = useTask();

	const onFileDrop = (file: File) => {
		const values = [{ fileName: file.name, uri: 'testUri' }];
		setTaskFieldValues(taskFieldId, values);
	};

	const onFileDeleted = () => {
		setTaskFieldValues(taskFieldId, []);
	};

	return (
		<Grid container style={{ padding: '1rem' }}>
			<Grid item xs={12}>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<FileDropZone
					onFileDrop={onFileDrop}
					onFileDeleted={onFileDeleted}
					disabled={taskState !== 'in_progress'}
				/>
			</Grid>
		</Grid>
	);
};
