import { FormControlLabel, Grid, Switch } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewInputGroup, Option } from './NewInputGroup';
import { ChildSettings } from './NewInputGroupItem';
import { ChildType } from './NewInputGroupItemChildren';

import useProducts from '../hooks/useProducts';
import useCustomerTypes from '../hooks/useCustomerTypes';
import usePrices from '../hooks/usePrices';
import { AppFunction, PriceTypesRequest, TranslationTypes, VariantsResponse } from 'common';

import { AnimatedIconButton } from './common/AnimatedIconButton';
import { Check } from '@mui/icons-material';
import { TabInnerTitle } from './tabs/TabInnerTitle';
import useFeedbacks from '../hooks/useFeedbacks';

interface Props {
	variantId: number;
}

const childrenSettings: ChildSettings[] = [
	{
		childType: ChildType.NumberField,
		label: 'product.basePrice',
		size: 4,
		readonly: true,
	},
	{
		childType: ChildType.NumberField,
		label: 'product.typePrice',
		size: 8,
	},
];

// TODO: FEEDBACKS PLEASE

export const InputVariantPricesByCustomerTypeGroup = (props: Props) => {
	const { t } = useTranslation();
	const { editCustomerTypePrices, deleteCustomerTypePrices } = usePrices();
	const { productList } = useProducts();
	const { customerTypesList } = useCustomerTypes();
	const { showSnackBar } = useFeedbacks();

	const [options, setOptions] = useState<Option[]>([]);
	const [editedOptions, setEditedOptions] = useState<Option[]>([]);
	const [multiLine, setMultiline] = useState<boolean>(false);

	const onRetrieveValues = (newOptions: Option[]) => {
		setEditedOptions(newOptions);
	};

	const onEdit = async () => {
		const originalEntries = options.filter((entry) => {
			if (entry.inputs) {
				const inputs = entry.inputs.filter((y) => y.defaultValue != undefined);
				if (inputs.length > 0) return inputs;
			}
		});

		const originalEntriesIndexes = originalEntries.map((x) => x.selectionId);
		const editedEntriesIndexes = editedOptions.map((x) => x.selectionId);
		const deleted = originalEntriesIndexes.filter((x) => !editedEntriesIndexes.includes(x));

		// delete
		const deleteRequestParams: number[] = [];
		const variantCollection: VariantsResponse = [];
		productList.map((pr) => {
			pr.variants?.map((vr) => variantCollection.push(vr));
		});
		const specialPrices = variantCollection.map((vr) => vr.sellingPriceType);
		specialPrices.map((spArray) => {
			if (spArray) {
				spArray.map((x) => {
					if (deleted.includes(x.typeId) && x.id) {
						deleteRequestParams.push(x.id);
					}
				});
			}
		});
		if (deleteRequestParams.length > 0) {
			await deleteCustomerTypePrices({ ids: deleteRequestParams });
		}

		// create
		const createRequestParams: PriceTypesRequest = [];
		editedOptions.map((op) => {
			if (op.inputs) {
				const price = op.inputs[1].value ? +op.inputs[1].value : 0;
				createRequestParams.push({
					variantId: props.variantId,
					typeId: op.selectionId,
					price: price,
					currencyId: 1,
				});
			}
		});
		if (createRequestParams.length > 0) {
			await editCustomerTypePrices(createRequestParams);
			showSnackBar({ message: '値段は正しく保存されました', severity: 'success' });
		}
	};

	const onExpand = (_: SyntheticEvent<Element, Event>, checked: boolean) => {
		setMultiline(checked);
	};

	useEffect(() => {
		const product = productList.find((x) => x.variants?.find((y) => y.id === props.variantId));
		const variant = product?.variants?.find((x) => x.id === props.variantId);
		const newOptions: Option[] = [];
		customerTypesList.map((customerType) => {
			const sellingPrice = variant?.sellingPriceType?.find((x) => x.typeId === customerType.id);
			newOptions.push({
				selectionId: customerType.id,
				selectionName: customerType.id.toString(),
				inputs: [
					{ defaultValue: variant?.sellPrice, value: variant?.sellPrice },
					{ defaultValue: sellingPrice?.price, value: sellingPrice?.price },
				],
			});
		});
		setOptions(newOptions.sort());
	}, [productList, customerTypesList, props.variantId]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='customers.discounted_price' translate size='small' />
				<Grid container spacing={2} sx={{ position: 'absolute', right: '1rem', top: '0.5rem' }}>
					<Grid item xs={10}></Grid>
					<Grid item xs={2}>
						<FormControlLabel
							value={multiLine}
							onChange={onExpand}
							control={<Switch />}
							label={t('operations.expand')}
						/>
					</Grid>
				</Grid>
				<NewInputGroup
					options={options}
					optionsLabel={'general.customer_type_one'}
					childrenSettings={childrenSettings}
					onRetrieveValues={onRetrieveValues}
					multiLine={multiLine}
					autocomplete
					translate={{ category: AppFunction.CustomerType, type: TranslationTypes.name }}
				/>
			</Grid>
			<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
				<AnimatedIconButton
					text='operations.save'
					translate
					// active={dirty}
					icon={<Check />}
					onClick={onEdit}
				/>
			</Grid>
		</Grid>
	);
};
