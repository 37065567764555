import { SxProps, Theme } from '@mui/material';

export const config: SxProps<Theme> = {
	'&& .MuiAutocomplete-root': {
		backgroundColor: 'red',
	},
	'&& .MuiFormControl-root': {
		pointerEvents: 'none !important',
	},
	'&& .MuiInputLabel-root .MuiFormLabel-root .Mui-focused': {
		pointerEvents: 'none !important',
		color: 'black !important',
		WebkitTextFillColor: 'black !important',
	},
	'&& .MuiInputBase-root': {
		color: 'black',
		pointerEvents: 'none',
		borderColor: 'transparent',
		opacity: 1,
		backgroundColor: 'background.paper',
	},
	'&& .Mui-disabled': {
		color: 'black !important',
		WebkitTextFillColor: 'black !important',
	},
	'&& .MuiSelect-select': {
		pointerEvents: 'none',
	},
	'&& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent !important',
		pointerEvents: 'none',
	},
	'&& .MuiInput-underline:before': {
		borderBottom: 'none !important',
	},
	'&& .MuiSvgIcon-root': {
		display: 'none',
	},
	// Hide number input spinner in WebKit browser
	'&& input::-webkit-outer-spin-button, && input::-webkit-inner-spin-button': {
		WebkitAppearance: 'none',
		userSelect: 'none',
		margin: 0,
	},
	// Hide number input spinner in Firefox
	'&& input': {
		userSelect: 'none',
		MozAppearance: 'textfield',
	},
};
