import {
	AnchorHTMLAttributes,
	Children,
	DetailedHTMLProps,
	ElementType,
	FocusEventHandler,
	forwardRef,
	isValidElement,
	JSXElementConstructor,
	ReactElement,
	ReactNode,
	Ref,
	RefObject,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
	Box,
	ButtonBaseActions,
	ButtonBaseClasses,
	Stack,
	SxProps,
	Tab,
	TabClasses,
	Tabs,
	Theme,
	Tooltip,
	useTheme,
} from '@mui/material';
import { TabbedElement, TabbedElementProps } from './TabbedElement';
import { TouchRippleProps, TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { CommonProps } from '@mui/material/OverridableComponent';

import { FocusedElementType } from '../../store/app';
import { ScopedLayer } from '../scopedlayer/ScopedLayer';
import { TabheaderBody } from './TabHeaderBody';

import styles from './TabbedSection.module.css';
import { AppFunction, ProductType } from 'common';
import { FormRenderType } from '@contexts/formContext/types';
import CheckTextWidth from 'helpers/checkTextWidth';

export interface TabbedSectionProps {
	title?: string;
	appFunction?: AppFunction;
	subFunction?: string | ProductType;
	renderMode?: FormRenderType;
	children?: ReactNode;
	naTooltipText?: string;
	hideMenu?: boolean;
	onShowTab?: (hasEditButton: boolean) => void;
	footerBody?: JSX.Element;
	overflow?: 'hidden' | 'visible' | 'scroll' | 'auto' | 'initial' | 'inherit' | 'unset';
}

interface TabPanelProps {
	children?: ReactNode;
	index: number;
	value: number;
	scopes?: FocusedElementType[];
}

interface TabElement {
	tabHeader: ReactNode;
	tabContent: ReactNode;
	scopes?: FocusedElementType[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CloneProps(props: { [x: string]: any; children: any }) {
	const { children, ...other } = props;
	return children(other);
}

const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
	(props: TabPanelProps, ref: Ref<HTMLDivElement>) => {
		const { children, value, index, scopes, ...other } = props;

		return (
			<div
				ref={ref}
				role='tabpanel'
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				key={props.index}
				{...other}
				style={{
					position: 'relative',
					width: index === value ? '100%' : '0%',
					backgroundColor: 'white',
				}}
				className={`${value === index ? styles.itemBox : ''}`}
			>
				<div
					style={{
						display: 'grid',
						width: '100%',
						opacity: value === index ? 1 : 0,
						gridTemplateRows: value === index ? '1fr' : '0fr',
						position: value === index ? 'relative' : 'absolute',
						transition: 'grid-template-rows 300ms ease-in-out, opacity 300ms ease-in-out',
						zIndex: 3,
					}}
				>
					{value === index && (
						<Box minWidth='md' sx={{ position: 'relative' }} className={styles.selectedTab}>
							{scopes && scopes.length > 0 && <ScopedLayer scopes={scopes} />}
							{children}
						</Box>
					)}
				</div>
			</div>
		);
	},
);
TabPanel.displayName = 'tabPanel';

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const TabbedSection = (props: TabbedSectionProps) => {
	const { t } = useTranslation();

	const [tabValue, setTabValue] = useState(0);
	const theme = useTheme();

	let editButtonArray: number[] = [];

	const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
		const hasEditButton = editButtonArray.includes(newValue);
		if (props.onShowTab) {
			props.onShowTab(hasEditButton);
		}
		setTabValue(newValue);
	};

	function findTabbedElements(children: ReactNode): ReactElement[] {
		let foundElements: React.ReactElement[] = [];

		Children.forEach(children, (child) => {
			if (isValidElement(child)) {
				if (child.type === TabbedElement) {
					foundElements.push(child);
				} else if (child.props && child.props.children) {
					foundElements = foundElements.concat(findTabbedElements(child.props.children));
				}
			}
		});

		return foundElements;
	}

	const tabs = () => {
		if (!props.children) return <></>;

		editButtonArray = [];
		const tabs: TabElement[] = [];
		const haveEditArray: number[] = [];

		const tabbedElements = findTabbedElements(props.children);

		let tabsWidth = 0;

		Children.forEach(tabbedElements, (child) => {
			if (!isValidElement<TabbedElementProps>(child)) {
				return;
			}

			const textLengthInRem = CheckTextWidth(t(child.props.title));
			tabsWidth = Math.max(tabsWidth, textLengthInRem);
		});

		Children.map(tabbedElements, (child, i) => {
			if (!isValidElement<TabbedElementProps>(child)) {
				return;
			}

			const elementProps = child.props as TabbedElementProps;

			let show = true;
			if (elementProps.onBeforeShowTab) {
				show = elementProps.onBeforeShowTab();
			}

			let header: ReactNode = [];
			if (show) {
				header = (
					<Tab
						label={t(elementProps.title)}
						{...a11yProps(i)}
						key={i}
						sx={{
							alignItems: 'flex-end',
							pointerEvents: tabValue === i ? 'none' : '',
							backgroundColor: tabValue === i ? 'white' : 'transparent',
							width: `${tabsWidth + 2}rem`,
							textAlign: 'right',
							position: 'relative',
							overflow: 'visible',
							flexWrap: 'nowrap',
							'&:after': {
								position: 'absolute',
								right: '-20px',
								content: '""',
								backgroundColor: 'white',
								width: '20px',
								opacity: tabValue === i ? '1' : '0',
								height: '100%',
								zIndex: 2,
								transition: 'opacity 200ms',
							},
						}}
					/>
				);
			} else {
				header = (
					<CloneProps key={i} style={{ alignItems: 'flex-end' }}>
						{
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(
								tabProps: JSX.IntrinsicAttributes & { href: string } & {
									children?: null | undefined;
									classes?: Partial<TabClasses> | undefined;
									disabled?: boolean | undefined;
									disableFocusRipple?: boolean | undefined;
									icon?:
										| string
										| ReactElement<any, string | JSXElementConstructor<any>>
										| undefined;
									iconPosition?: 'end' | 'start' | 'top' | 'bottom' | undefined;
									label?: ReactNode;
									sx?: SxProps<Theme> | undefined;
									value?: any;
									wrapped?: boolean | undefined;
								} & Omit<
										{
											action?: Ref<ButtonBaseActions> | undefined;
											centerRipple?: boolean | undefined;
											children?: ReactNode;
											classes?: Partial<ButtonBaseClasses> | undefined;
											disabled?: boolean | undefined;
											disableRipple?: boolean | undefined;
											disableTouchRipple?: boolean | undefined;
											focusRipple?: boolean | undefined;
											focusVisibleClassName?: string | undefined;
											LinkComponent?: ElementType<any> | undefined;
											onFocusVisible?: FocusEventHandler<any> | undefined;
											sx?: SxProps<Theme> | undefined;
											tabIndex?: number | undefined;
											TouchRippleProps?: Partial<TouchRippleProps> | undefined;
											touchRippleRef?: Ref<TouchRippleActions> | undefined;
										},
										'classes'
									> &
									CommonProps &
									Omit<
										Pick<
											DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
											'key' | keyof AnchorHTMLAttributes<HTMLAnchorElement>
										> & {
											ref?:
												| ((instance: HTMLAnchorElement | null) => void)
												| RefObject<HTMLAnchorElement>
												| null
												| undefined;
										},
										| 'label'
										| 'children'
										| keyof CommonProps
										| 'sx'
										| 'tabIndex'
										| 'disabled'
										| 'value'
										| 'action'
										| 'centerRipple'
										| 'disableRipple'
										| 'disableTouchRipple'
										| 'focusRipple'
										| 'focusVisibleClassName'
										| 'LinkComponent'
										| 'onFocusVisible'
										| 'TouchRippleProps'
										| 'touchRippleRef'
										| 'disableFocusRipple'
										| 'icon'
										| 'iconPosition'
										| 'wrapped'
									>,
							) => (
								<Tooltip
									title={t(props.naTooltipText ?? 'general.not_available')}
									arrow
									placement='right'
									key={i}
								>
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<Tab {...tabProps} disabled label={t(elementProps.title)} />
									</div>
								</Tooltip>
							)
						}
					</CloneProps>
				);
			}

			const element: TabElement = {
				tabHeader: header,
				tabContent: elementProps.children,
				scopes: elementProps.scopes,
			};

			if (!elementProps.hideEditButton) {
				haveEditArray.push(i);
			}

			tabs.push(element);
		});
		editButtonArray = haveEditArray;

		return (
			<div
				className={styles.wrapper}
				style={{
					background: theme.palette.gradient.secondary,
					marginBottom: props.renderMode === FormRenderType.popup ? '0px' : '2.5rem',
					height: props.renderMode === FormRenderType.popup ? '100%' : 'auto',
				}}
			>
				<TabheaderBody
					appFunction={props.appFunction}
					subFunction={props.subFunction}
					title={props.title}
				/>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='stretch'
					spacing={0}
					className={styles.container}
					sx={{ overflow: props.overflow ?? 'hidden' }}
				>
					{!props.hideMenu && (
						<Box className={styles.menuContainer}>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								aria-label='tabbed section'
								orientation='vertical'
								TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
								sx={{ overflow: 'visible' }}
							>
								{tabs.map((x) => x.tabHeader)}
							</Tabs>
						</Box>
					)}
					{tabs.map((x, i) => {
						return (
							<TabPanel value={tabValue} index={i} key={i} scopes={x.scopes}>
								{x.tabContent}
							</TabPanel>
						);
					})}
				</Stack>
				<Box className={styles.footer}>{props.footerBody}</Box>
			</div>
		);
	};
	const memoizedTabs = useMemo(() => tabs(), [props, tabValue]);

	return memoizedTabs;
};
