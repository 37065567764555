import { Grid, TextField, Typography } from '@mui/material';
import useLocale from '@hooks/useLocale';
import { AppFunction, TranslationTypes } from 'common';
import { useTask } from '@contexts/index';
import { StringFieldValue } from '@contexts/taskContext/config/stringFieldConfig';
import { useEffect } from 'react';

interface Props {
	taskFieldId: number;
}

export const StringField = ({ taskFieldId }: Props) => {
	const { getTranslatedString } = useLocale();
	const { cachedValues, setTaskFieldValues, getTaskFieldValues } = useTask();

	const fieldData = getTaskFieldValues<StringFieldValue>(taskFieldId);

	useEffect(() => {
		const stubData = getTaskFieldValues<StringFieldValue>(taskFieldId);
		if (stubData.length > 0) {
			setTaskFieldValues(taskFieldId, [{ value: stubData[0].value }]);
		}
	}, [cachedValues]);

	return (
		<Grid container style={{ padding: '1rem' }}>
			<Grid item xs={12}>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					value={fieldData.length > 0 ? fieldData[0].value : ''}
					onChange={(e) => setTaskFieldValues(taskFieldId, [{ value: e.target.value }])}
				/>
			</Grid>
		</Grid>
	);
};
