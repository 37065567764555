import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { FormRenderType } from '@contexts/formContext/types';
import { useUserForm } from '@contexts/formContext/subContexts/UserFormContext';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { AppFunction, TranslationTypes, UserEditRequest } from 'common';
import { useTranslation } from 'react-i18next';

export const Workplaces = () => {
	const { renderType, formAction } = useGenericForm<UserEditRequest>();
	const { workplaces, setWorkplaces } = useUserForm();
	const { warehouseList } = useWarehouses();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const editDisabled = formAction === 'view';

	return (
		<InputGridField width={12} type={ComponentType.AutocompleteWithRenderTags}>
			<Autocomplete
				multiple
				size='small'
				disabled={editDisabled}
				disablePortal={renderType === FormRenderType.popup ? true : false}
				options={warehouseList}
				getOptionLabel={(option) =>
					getTranslatedString(AppFunction.Location, option.id, TranslationTypes.name)
				}
				value={warehouseList.filter((warehouse) => workplaces?.includes(warehouse.id))}
				onChange={(_, newValue) => {
					setWorkplaces(newValue.map((item) => item.id));
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							size='small'
							variant='outlined'
							label={getTranslatedString(AppFunction.Location, option.id, TranslationTypes.name)}
							{...getTagProps({ index })}
							key={option.id}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant='outlined'
						label={t('user.workplaces')}
						placeholder={t('select.workplaces')}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
			/>
		</InputGridField>
	);
};
