import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import useLocale from '@hooks/useLocale';
import useCustomers from '@hooks/useCustomers';
import useUsers from '@hooks/useUsers';
import useCustomerTypes from '@hooks/useCustomerTypes';
import { CustomFilter } from '@hooks/useInitializeFilters';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Modal,
	Typography,
} from '@mui/material';

import { Column, ColumnSortOrderType, ColumnSortParams } from 'primereact/column';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { doDeleteCustomer } from '../../store/customers';
import { getI18n, useTranslation } from 'react-i18next';
import { AppFunction, CustomerResponse, CustomersResponse, TranslationTypes } from 'common';
import { CustomFilterInput } from '@components/filterInputs/CustomFilterTextInput';

import commonStyles from '@styles/Common.module.css';
import _ from 'lodash';

import { TableContextProvider, useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { ContextualDropdownMenu } from '@components/ContextualDropdownMenu';
import { Delete, Edit } from '@mui/icons-material';
import { UserForm } from '@pages/Users/UserForm';

const appFunction = AppFunction.Customer;

export const CustomersList = () => {
	const dispatch = useAppDispatch();

	const { getNotesByCustomer, customerList } = useCustomers();
	const { customerTypesList } = useCustomerTypes();
	const { getI18NString, renderI18NField, getCurrentLanguage } = useLocale();
	const { t } = useTranslation();
	const { userList } = useUsers();
	const { setBreadCrumbs, pushPopup } = useLayout();
	const { getIcon } = useAppFunctions();

	// #region States
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deletingCustomer, setDeletingCustomer] = useState(-1);
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(-1);

	useEffect(() => {
		setBreadCrumbs([
			{
				label: `appFunctions.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const handleModal = (id: number) => {
		setSelectedId(id);
		setModalOpen(!isModalOpen);
	};

	// #region DataTable Elements
	const customerCodeBodyTemplate = (data: CustomerResponse) => {
		const values = data.customerCode.split('-');
		return values.length > 1 ? (
			<>
				<span style={{ color: 'red' }}>{values[0]}</span>
				{values.slice(1).join('')}
			</>
		) : (
			<>{values.join('')}</>
		);
	};

	const sortByName = (data: ColumnSortParams): CustomersResponse => {
		function sortByName(
			objectArray: CustomersResponse,
			currentLanguage: string,
			sortOrder: ColumnSortOrderType,
		) {
			const fallbackOrder: Record<string, string[]> = {
				jp: ['it', 'en'],
				it: ['jp', 'en'],
				en: ['jp', 'it'],
			};
			return _.orderBy(
				objectArray,
				(obj) => {
					return _.get(
						_.find(obj.strings, (s) => s.langCode === currentLanguage) ||
							_.find(obj.strings, (s) => _.includes(fallbackOrder[currentLanguage], s.langCode)),
						'value',
						'',
					);
				},
				sortOrder === 1 ? 'asc' : 'desc',
			);
		}

		if (data.order === 1 || data.order === -1) {
			const sortedArray = sortByName(data.rowData, getCurrentLanguage(), data.order);
			return sortedArray;
		}
		return data.rowData;
	};

	const customerTypeBodyTemplate = (rowData: CustomerResponse) => {
		const type = customerTypesList.find((ct) => ct.id === rowData.typeId);
		if (type) {
			return (
				<>
					{renderI18NField(
						getI18NString(AppFunction.CustomerType, type?.id, TranslationTypes.name),
					)}
				</>
			);
		}
		return <></>;
	};

	const internalReferentBodyTemplate = (data: CustomerResponse) => {
		const referentUser = userList.find((x) => x.id === data.referentUserId);
		if (referentUser != undefined) {
			const name = referentUser.person?.name ?? 'name';
			const surname = referentUser.person?.surname ?? 'surname';
			return (
				<Button
					onClick={() => {
						pushPopup(<UserForm forcedId={referentUser.id} formAction='view' />, false, true);
					}}
				>
					{renderConditionalName(name, surname)}
				</Button>
			);
		}
		return '';
	};

	const renderConditionalName = (name?: string, surname?: string) => {
		if (name != undefined && surname != undefined) {
			return getI18n().language === 'jp' ? `${surname} ${name}` : `${name} ${surname}`;
		} else if (name != undefined && surname == undefined) {
			return name;
		} else if (name == undefined && surname != undefined) {
			return surname;
		} else {
			return '';
		}
	};

	const deleteCustomer = () => {
		dispatch(doDeleteCustomer({ id: deletingCustomer }));
	};

	const actionsBodyTemplate = (rowData: CustomerResponse) => {
		return (
			<ContextualDropdownMenu
				entries={[
					{
						entryLink: `/customer/${rowData.id}/edit`,
						entryIcon: <Edit />,
						entryName: 'Edit',
					},
					{
						entryAction: () => {
							setDeletingCustomer(rowData.id ?? -1);
							setDialogOpen(true);
						},
						entryIcon: <Delete />,
						entryName: 'Delete',
					},
				]}
			/>
		);
	};
	// #endregion

	const matchModes = [{ label: 'My Filter', value: 'filterStrings' }];

	// #region React Dom Render
	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{t('dialog.areYouSure')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'></DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setDialogOpen(false);
							deleteCustomer();
						}}
					>
						{t('operations.deleteItem', { item: t('appBar.customers') })}
					</Button>
					<Button
						onClick={() => {
							setDialogOpen(false);
						}}
						autoFocus
					>
						{t('dialog.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
			<TableContextProvider pageIdentifier={appFunction} values={customerList}>
				<Column
					field='customerCode'
					header='customerCode'
					body={customerCodeBodyTemplate}
					style={{ maxWidth: '10rem' }}
					sortable
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterCustomerCode}
					showFilterMenu={false}
				/>
				<Column
					field='strings'
					header='name'
					body={(data) =>
						renderI18NField(
							getI18NString(AppFunction.Customer, data.id ?? '', TranslationTypes.name),
						)
					}
					// style={{ maxWidth: '14rem' }}
					sortable
					sortFunction={sortByName}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterStrings}
					filterMatchModeOptions={matchModes}
					showFilterMenu={false}
				/>
				<Column
					field='companyCode'
					header='companyCode'
					sortable
					style={{ maxWidth: '12rem' }}
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					filterMatchMode='contains'
					showFilterMenu={false}
				/>
				<Column
					field='typeId'
					header='customerType'
					style={{ maxWidth: '10rem' }}
					body={customerTypeBodyTemplate}
					sortable
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterCustomerType}
					showFilterMenu={false}
				/>
				<Column
					field='referentUserId'
					header='internalReferent'
					body={internalReferentBodyTemplate}
					sortable
					filterElement={CustomFilterInput}
					filterHeaderClassName={commonStyles.noFilterButton}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					filterMatchMode={CustomFilter.filterInternalReferent}
					showFilterMenu={false}
				/>
				<Column
					field='creditLineLimit'
					header='creditLineLimit'
					style={{
						maxWidth: '12rem',
					}}
				/>
				<Column
					field='actions'
					style={{ maxWidth: '3rem' }}
					bodyStyle={{
						display: 'flex',
						justifyContent: 'center',
					}}
					body={actionsBodyTemplate}
				/>
			</TableContextProvider>
		</>
	);
	// #endregion
};
