import { EditShowGridField } from '@components/common/EditShowGridField';
import { PresetInputLangGroup } from '@components/PresetInputLangGroup';
import { VectorThreeInput } from '@components/VectorThreeInput';
import { UnitSelector } from '@components/products/UnitSelector';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { TabbedSection } from '@components/tabs/TabbedSection';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useProductForm } from '@contexts/formContext/subContexts/ProductFormContext';
import useProducts from '@hooks/useProducts';
import { Straighten } from '@mui/icons-material';
import {
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material';
import { AppFunction, LocaleEntries, ProductType, ShipBy, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DefaultVariant = () => {
	const { t } = useTranslation();
	const { subFunction } = useGenericForm();
	const { control, formAction } = useProductForm();
	const { currencies } = useProducts();

	const editDisabled = formAction === 'view';

	return (
		<TabbedSection
			hideMenu
			appFunction={AppFunction.Variant}
			title={t('operations.createItem', { item: t(`appFunctions.${AppFunction.Variant}`) })}
			// renderMode={}
		>
			<TabbedElement title='test'>
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Controller
						name='sku'
						control={control}
						render={({ field }) => (
							<EditShowGridField width={6} isEditing={!editDisabled} label>
								<TextField
									required
									variant='outlined'
									id='sku'
									label={t('product.sku')}
									value={field.value}
									onChange={field.onChange}
									// TODO: I'll leave it here,
									// when working on the graphics we need to make sure the label doesn't
									// animate whenever coming back from another tab
									// InputLabelProps={{
									// 	style: {
									// 	  transform: 'none', // Disables the label moving animation
									// 	  marginTop: '-8.5px', // Adjust the label's position as needed
									// 	  marginLeft: '14px',
									// 	  fontSize: '0.7rem'
									// 	},
									// }}
								/>
							</EditShowGridField>
						)}
					/>
					<Controller
						name='janCode'
						control={control}
						render={({ field }) => (
							<EditShowGridField width={6} isEditing={!editDisabled} label>
								<TextField
									variant='outlined'
									id='janCode'
									label={t('product.janCode')}
									value={field.value}
									onChange={field.onChange}
								/>
							</EditShowGridField>
						)}
					/>
					{subFunction === ProductType.product && (
						<Controller
							name='shipBy'
							control={control}
							render={({ field }) => (
								<EditShowGridField width={12} isEditing={!editDisabled} label>
									<InputLabel id='ship-by'>{t('product.shipBy')}</InputLabel>
									<Select
										labelId='ship-by'
										id='ship-by'
										label={t('product.shipBy')}
										value={field.value}
										disabled={editDisabled}
										onChange={(event) => field.onChange(event.target.value as ShipBy)}
									>
										{Object.values(ShipBy).map((value, it) => (
											<MenuItem key={it} value={value}>
												{value}
											</MenuItem>
										))}
									</Select>
								</EditShowGridField>
							)}
						/>
					)}
					{subFunction === ProductType.product && (
						<Controller
							name='sellPrice'
							control={control}
							render={({ field }) => (
								<EditShowGridField width={8} isEditing={!editDisabled} label>
									<TextField
										variant='outlined'
										required
										type='number'
										inputProps={{ min: 0 }}
										label={t('product.sellPrice')}
										id='product_sell_price'
										value={field.value}
										onChange={(e) => field.onChange(+e.target.value)}
									/>
								</EditShowGridField>
							)}
						/>
					)}
					{subFunction === ProductType.product && (
						<Controller
							name='sellCurrency'
							control={control}
							render={({ field }) => (
								<EditShowGridField width={4} isEditing={!editDisabled} label>
									<InputLabel id='sell-currency'>{t('product.sellCurrency')}</InputLabel>
									<Select
										labelId='sell-currency'
										id='sell-currency'
										label={t('product.sellCurrency')}
										value={field.value}
										disabled={editDisabled}
										onChange={(event) => field.onChange(+event.target.value)}
									>
										{currencies.length > 0 &&
											currencies.map((currency) => (
												<MenuItem key={currency.id} value={currency.id}>
													{t(`currency.${currency.alphabeticCode}`)}
												</MenuItem>
											))}
									</Select>
								</EditShowGridField>
							)}
						/>
					)}
					{/* {!transformProduct && (
                        <Controller
                            name='purchaseUnitWeight'
                            control={control}
                            render={({ field }) => (
                                <PurchaseUnitSelector
                                    value={field.value}
                                    view={viewMode}
                                    prefix='buy'
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                        />
                    )} */}
					{subFunction === ProductType.product && (
						<Controller
							name='sellUnitWeight'
							control={control}
							render={({ field }) => (
								<UnitSelector
									value={field.value}
									view={editDisabled}
									prefix='sell'
									onChange={(value) => field.onChange(value)}
								/>
							)}
						/>
					)}
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={12}>
							<TabInnerTitle title='product.name' translate size='small' />
							<Controller
								name='strings'
								control={control}
								render={({ field }) => (
									<PresetInputLangGroup
										useReactHookForm
										type={TranslationTypes.name}
										strings={field.value ?? []}
										onChange={(data: LocaleEntries) => {
											field.onChange(data);
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TabInnerTitle title='product.description' translate size='small' />
							<Controller
								name='strings'
								control={control}
								render={({ field }) => (
									<PresetInputLangGroup
										useReactHookForm
										type={TranslationTypes.description}
										strings={field.value ?? []}
										onChange={field.onChange}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={12}>
							<TabInnerTitle title='product.size' translate size='small' />
						</Grid>
						{subFunction === ProductType.product && (
							<Grid item xs={12} container spacing={2}>
								<Controller
									name='variable'
									control={control}
									render={({ field }) => (
										<EditShowGridField width={4} isEditing={!editDisabled} label>
											<FormGroup sx={{ paddingLeft: 1 }}>
												<FormControlLabel
													control={
														<Switch
															{...field}
															checked={field.value}
															onChange={(ev) => field.onChange(ev.target.checked)}
														/>
													}
													label={t('product.varWeight')}
												/>
											</FormGroup>
										</EditShowGridField>
									)}
								/>
								<Controller
									name='weight'
									control={control}
									render={({ field }) => (
										<EditShowGridField width={4} isEditing={!editDisabled} label>
											<TextField
												variant='outlined'
												required
												type='number'
												label={t('product.weight')}
												id='weight'
												value={field.value}
												onChange={(e) => field.onChange(+e.target.value)}
											/>
										</EditShowGridField>
									)}
								/>
								<Controller
									name='shelfLife'
									control={control}
									render={({ field }) => (
										<EditShowGridField width={4} isEditing={!editDisabled} label>
											<TextField
												variant='outlined'
												required
												label={t('product.weightUnit')}
												id='weight_unit'
												value={+field.value}
												onChange={(e) => field.onChange(+e.target.value)}
											/>
										</EditShowGridField>
									)}
								/>
								<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
									<Straighten sx={{ marginLeft: '8px', marginRight: '8px' }} />
									{t('product.size_per_box')}
								</Grid>
								<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
									<Straighten sx={{ marginLeft: '8px', marginRight: '8px' }} />
									{t('product.size_per_piece')}
								</Grid>
								<Controller
									name='sizePerBox'
									control={control}
									render={({ field }) => (
										<EditShowGridField isEditing={true} width={6}>
											<VectorThreeInput
												value={field.value ?? '{0,0,0}'}
												onChange={field.onChange}
											/>
										</EditShowGridField>
									)}
								/>
								<Controller
									name='sizePerPiece'
									control={control}
									render={({ field }) => (
										<EditShowGridField isEditing={true} width={6}>
											<VectorThreeInput
												value={field.value ?? '{0,0,0}'}
												onChange={field.onChange}
											/>
										</EditShowGridField>
									)}
								/>
							</Grid>
						)}
						<Controller
							name='unitsPerBox'
							control={control}
							render={({ field }) => (
								<EditShowGridField width={12} isEditing={!editDisabled} label>
									<TextField
										variant='outlined'
										required
										type='number'
										label={t('product.unitsPerBox')}
										id='units_per_box'
										value={field.value}
										onChange={(e) => field.onChange(+e.target.value)}
									/>
								</EditShowGridField>
							)}
						/>
					</Grid>
				</Grid>
			</TabbedElement>
		</TabbedSection>
	);
};
