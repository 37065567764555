import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditShowGridField } from './common/EditShowGridField';
import { Option } from './NewInputGroup';
import { ChildSettings } from './NewInputGroupItem';

interface Props {
	selectedOption: Option;
	childrenSettings: ChildSettings[];
	view?: boolean;
	onReset: (index: number) => void;
	onStoreValue: (inputValue: string, index: number) => void;
}

export enum ChildType {
	TextField = 'textfield',
	NumberField = 'numberfield',
	SelectField = 'selectfield',
}

export const NewInputGroupItemChildren = (props: Props) => {
	const { t } = useTranslation();
	const canEdit = props.view ? !props.view : true;

	const createChild = (index: number, childSettings: ChildSettings): JSX.Element => {
		switch (childSettings.childType) {
			case ChildType.TextField:
				return (
					<EditShowGridField
						key={index}
						isEditing={canEdit && !childSettings.readonly}
						width={childSettings.size}
						label={t(childSettings.label)}
					>
						<TextField
							fullWidth
							label={t(childSettings.label)}
							value={
								props.selectedOption.inputs ? props.selectedOption.inputs[index].value ?? '' : ''
							}
							disabled={props.selectedOption.selectionId == -1}
							onChange={(ev) => {
								ev.preventDefault();
								props.onStoreValue(ev.target.value, index);
							}}
							InputProps={{
								endAdornment: <Button onClick={() => props.onReset(index)}>reset</Button>,
							}}
						/>
					</EditShowGridField>
				);
			case ChildType.NumberField:
				return (
					<EditShowGridField
						key={index}
						isEditing={canEdit && !childSettings.readonly}
						width={childSettings.size}
						label={t(childSettings.label)}
					>
						<TextField
							fullWidth
							label={t(childSettings.label)}
							value={
								props.selectedOption.inputs ? props.selectedOption.inputs[index].value ?? '' : ''
							}
							disabled={props.selectedOption.selectionId == -1}
							onChange={(ev) => {
								ev.preventDefault();
								const value = ev.target.value;
								props.onStoreValue(value, index);
							}}
							onBlur={(ev) => {
								const value = ev.target.value;
								const numericValue = parseFloat(value);
								if (!isNaN(numericValue)) {
									const formattedValue = numericValue.toFixed(2);
									props.onStoreValue(formattedValue, index);
								} else {
									props.onStoreValue('', index);
								}
							}}
							type='text'
							InputProps={{
								endAdornment: <Button onClick={() => props.onReset(index)}>reset</Button>,
							}}
						/>
					</EditShowGridField>
				);
			case ChildType.SelectField:
				if (childSettings.selectionItems && childSettings.selectionItems?.length > 0) {
					return (
						<EditShowGridField
							key={index}
							isEditing={canEdit && !childSettings.readonly}
							width={childSettings.size}
							label={t(childSettings.label)}
						>
							<InputLabel id={`${childSettings.label}-label`}>{t(childSettings.label)}</InputLabel>
							<Select
								labelId={`${childSettings.label}-label`}
								id={t(childSettings.label)}
								defaultValue={() => {
									if (props.selectedOption.inputs && props.selectedOption.inputs.length > index) {
										return props.selectedOption.inputs
											? props.selectedOption.inputs[index].value ?? ''
											: '';
									} else {
										if (childSettings.selectionItems) {
											return childSettings.selectionItems[0];
										} else {
											return '';
										}
									}
								}}
								label={t(childSettings.label)}
								fullWidth
								// value={props.selectedOption?.inputs[index].value ?? ''}
								onChange={(e) => {
									e.preventDefault();
									props.onStoreValue(e.target.value as string, index);
								}}
							>
								{childSettings.selectionItems.map((key, index) => {
									return (
										<MenuItem value={key} key={index}>
											{key}
										</MenuItem>
									);
								})}
							</Select>
						</EditShowGridField>
					);
				} else {
					return <></>;
				}
			default:
				return <></>;
		}
	};

	return (
		<>
			{props.childrenSettings.map((childSetting, it) => {
				return createChild(it, childSetting);
			})}
		</>
	);
};
