import { Box } from '@mui/material';
import { FocusedElementType } from '@store/app';
import { ReactNode } from 'react';

import styles from '@contexts/tabContext/TabContext.module.css';
import { ScopedLayer } from '@components/scopedlayer/ScopedLayer';

interface Props {
	tabValue: number;
	index: number;
	scopes: FocusedElementType[];
	children?: ReactNode;
	fullScreen?: boolean;
}

export const TabPanel = ({ tabValue, children, index, scopes, fullScreen }: Props) => {
	return (
		<div
			role='tabpanel'
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			key={index}
			style={{
				...(fullScreen && { height: '100%' }),
				position: 'relative',
				width: index === tabValue ? '100%' : '0%',
				backgroundColor: 'white',
			}}
			className={`${tabValue === index ? styles.itemBox : ''}`}
		>
			<div
				style={{
					display: 'grid',
					width: '100%',
					opacity: tabValue === index ? 1 : 0,
					gridTemplateRows: tabValue === index ? '1fr' : '0fr',
					position: tabValue === index ? 'relative' : 'absolute',
					transition: 'grid-template-rows 300ms ease-in-out, opacity 300ms ease-in-out',
					zIndex: 3,
				}}
			>
				{tabValue === index && (
					<Box minWidth='md' sx={{ position: 'relative' }} className={styles.selectedTab}>
						{scopes && scopes.length > 0 && <ScopedLayer scopes={scopes} />}
						{children}
					</Box>
				)}
			</div>
		</div>
	);
};
