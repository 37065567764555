import { Box, Button, useTheme } from '@mui/material';
import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { UserList } from './Users/UserList';
import { WarehouseList } from './Warehouses/WarehouseList';
import { MerchantList } from './Merchants/MerchantList';
import { CarrierList } from './Carriers/CarrierList';
import { CustomerTypesList } from './Customers/CustomerTypesList';
import { useTranslation } from 'react-i18next';
import { CountryList } from './Countries/CountryList';
import { RegionTypeList } from './Regions/RegionTypeList';
import { JobTitleList } from './People/JobTitleList';
import { ImagesList } from './Images/ImagesList';
import { TaskFieldArchetypesList } from './Tasks/TaskFieldArchetypeList';
import { ChainArchetypesList } from './Tasks/ChainArchetypesList';
import { GroupsList } from './Groups/GroupsList';
import { TaskFieldSetList } from './Tasks/TaskFieldSetList';
import { SectionList } from './Users/SectionList';
import { useEffect, useState } from 'react';
import { useLayout } from '../contexts/layoutContext/LayoutContext';
import { Settings as SettingsIcon } from '@mui/icons-material';
import useAppFunctions from '@hooks/useAppFunctions';
import { AppFunction } from 'common';
import { FadeWrapper } from '@components/common/FadeWrapper';
import useLayoutObserver from '@hooks/useLayoutObserver';
import { TaskArchetypeList } from './Tasks/TaskArchetypeList';
import { TaskFieldParameterList } from './Tasks/TaskFieldParameterList';

const fullIconsPerRow = 8;
const smallIconsPerRow = 8;
const iconsGap = 2;
const containerPadding = 1;
const elementName = 'settingsButtonContainer';

export const Settings = () => {
	const { t } = useTranslation();
	const {
		setBreadCrumbs,
		remToPixels,
		pixelsToRem,
		visiblePadding,
		registerElement,
		unregisterElement,
	} = useLayout();

	const { getIcon } = useAppFunctions();
	const location = useLocation();
	const theme = useTheme();

	const [isSettingsPage, setSettingsPage] = useState(true);
	const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

	const onLayoutChange = (_: string, contentRect: DOMRectReadOnly) => {
		setContainerSize({ width: contentRect.width, height: contentRect.height });
	};

	const containerRef = useLayoutObserver<HTMLDivElement>('container', 'size', onLayoutChange);

	useEffect(() => {
		if (!isSettingsPage) {
			registerElement(
				elementName,
				'top',
				containerSize.height + remToPixels(1 + containerPadding * 2),
			);
		} else {
			unregisterElement(elementName);
		}

		return () => {
			unregisterElement(elementName);
		};
	}, [isSettingsPage, containerSize]);

	useEffect(() => {
		if (location.pathname === '/settings') {
			setSettingsPage(true);
			setBreadCrumbs([
				{
					label: 'breadCrumbs.settings',
					icon: <SettingsIcon />,
				},
			]);
		} else {
			setSettingsPage(false);
		}
	}, [location]);

	function getButton(appFunction: AppFunction) {
		if (containerSize.width === 0) {
			return null;
		}

		const icon = getIcon(appFunction, undefined, true);
		const isSelected = location.pathname === `/settings/${appFunction}`;
		const gap = remToPixels(iconsGap);

		const getSize = (ipr: number) =>
			Math.ceil((containerSize.width - gap * (ipr - 1) - ipr * 2) / ipr);

		const fullButtonSize = getSize(fullIconsPerRow);
		const smallButtonSize = getSize(smallIconsPerRow);

		const fontSize = pixelsToRem(fullButtonSize / 12);

		return (
			<Link
				to={appFunction}
				style={{
					textDecoration: 'none',
				}}
			>
				<Button
					variant='contained'
					color='success'
					disableElevation
					sx={{
						minHeight: isSettingsPage ? `${fullButtonSize}px` : '2rem',
						minWidth: isSettingsPage ? `${fullButtonSize}px` : `${smallButtonSize}px`,
						maxHeight: isSettingsPage ? `${fullButtonSize}px` : '2.5rem',
						maxWidth: isSettingsPage ? `${fullButtonSize}px` : `${smallButtonSize}px`,
						display: 'flex',
						gap: '0.5rem',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '1rem',
						backgroundColor: isSelected
							? theme.palette.primary.main
							: isSettingsPage
							? theme.palette.primary.main
							: 'white',
						color: isSelected ? 'white' : isSettingsPage ? 'white' : theme.palette.primary.main,
						border: isSelected ? '1px solid white ' : '1px solid transparent',
						transform: isSelected ? 'scale(1.1)' : 'scale(0.95)',
						boxShadow: isSelected ? '0 0 0.5rem 0.2rem rgba(255, 255, 255, 0.2)' : '',
						transition: 'all 0.4s',
						'&:hover': {
							backgroundColor: isSettingsPage ? 'white' : theme.palette.primary.main,
							transform: isSettingsPage ? 'scale(1.01)' : isSelected ? 'scale(1.1)' : 'scale(1)',
							color: isSettingsPage ? theme.palette.primary.main : 'white',
							border: isSettingsPage
								? `1px solid ${theme.palette.primary.main}`
								: '1px solid rgba(255, 255, 255, 0.8)',
							boxShadow: '0 0 0.5rem 0.2rem rgba(255, 255, 255, 0.2)',
						},
					}}
				>
					{icon && (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: isSettingsPage ? `${fontSize * 1.5}rem` : '0.8vw',
							}}
						>
							{icon}
						</div>
					)}
					<div
						style={{
							fontSize: isSettingsPage ? '0.7vw' : '0.5vw',
						}}
					>
						{t(`appFunctions.${appFunction}`)}
					</div>
				</Button>
			</Link>
		);
	}

	return (
		<>
			<FadeWrapper fadeTime={1500}>
				<Box
					ref={containerRef}
					sx={{
						margin: '0 1.9rem 1rem 1.9rem',
						padding: `${containerPadding}rem`,
						background: 'transparent',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: isSettingsPage ? 'stretch' : 'space-evenly',
						alignItems: 'center',
						gap: isSettingsPage ? `${iconsGap}rem` : '0.5rem',
						position: 'relative',
						transition: 'all 0.9s ease-out',
						maxHeight: `calc(100vh - ${visiblePadding.top}px)`,
						width: 'calc(100% - 3.8rem)',
						overflow: 'hidden',
					}}
				>
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							background: theme.palette.gradient.primary,
							opacity: isSettingsPage ? 0 : 1,
							borderRadius: isSettingsPage ? 0 : '1rem',
							zIndex: 0,
							transition: 'opacity 2s, border-radius 1s',
						}}
					/>
					{getButton(AppFunction.User)}
					{getButton(AppFunction.Role)}
					{getButton(AppFunction.Location)}
					{getButton(AppFunction.Merchant)}
					{getButton(AppFunction.CustomerType)}
					{getButton(AppFunction.JobTitle)}
					{getButton(AppFunction.Carrier)}
					{getButton(AppFunction.EntityGroup)}
					{getButton(AppFunction.Image)}
					{getButton(AppFunction.Country)}
					{getButton(AppFunction.RegionType)}
					{getButton(AppFunction.TaskChainArchetype)}
					{getButton(AppFunction.TaskArchetype)}
					{getButton(AppFunction.TaskFieldArchetype)}
					{getButton(AppFunction.TaskFieldParameter)}
					{getButton(AppFunction.TaskFieldSetArchetype)}
				</Box>
			</FadeWrapper>
			<Routes>
				<Route path='user' element={<UserList />} />
				<Route path='role' element={<SectionList />} />
				<Route path='location' element={<WarehouseList />} />
				<Route path='merchant' element={<MerchantList />} />
				<Route path='customertype' element={<CustomerTypesList />} />
				<Route path='jobtitle' element={<JobTitleList />} />
				<Route path='carrier' element={<CarrierList />} />
				<Route path='country' element={<CountryList />} />
				<Route path='regiontype' element={<RegionTypeList />} />
				<Route path='image' element={<ImagesList />} />
				<Route path='entitygroup' element={<GroupsList />} />
				<Route path='taskfieldarchetype' element={<TaskFieldArchetypesList />} />
				<Route path='taskchainarchetype' element={<ChainArchetypesList />} />
				<Route path='taskarchetype' element={<TaskArchetypeList />} />
				<Route path='taskfieldsetarchetype' element={<TaskFieldSetList />} />
				<Route path='taskfieldparameter' element={<TaskFieldParameterList />} />
			</Routes>
			<Outlet />
		</>
	);
};
