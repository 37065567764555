import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { Box, Grid, TextField } from '@mui/material';
import { pushSnackbar } from '../../store/app';
import { doCreateCustomerBilling } from '../../store/customers';
import { CustomerBillingRequest, CustomerBillingsResponse } from 'common';
import { CustomerBilling } from './CustomerBilling';
import { AddElementButton } from '../common/AddElementButton';

interface Props {
	customerId: number;
	billings: CustomerBillingsResponse;
	view?: boolean;
}

export const CustomerBillings = ({ customerId, billings, view }: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [id, setId] = useState(customerId);
	const [isCreating, setCreating] = useState(false);
	const [editingBilling, setEditingBilling] = useState<number>(-1);
	const [expandedId, setExpandedId] = useState<number | null>(null);
	const [newBilling, setNewBilling] = useState({
		id: -1,
		customerId: customerId,
		accountName: '',
		bankName: '',
	});

	useEffect(() => {
		setId(customerId);
	}, [customerId]);

	const onBillingCreate = async () => {
		const tempBilling: CustomerBillingRequest = newBilling;
		await dispatch(doCreateCustomerBilling(tempBilling));
		setNewBilling({
			id: -1,
			customerId: customerId,
			accountName: '',
			bankName: '',
		});
		setCreating(false);
		dispatch(pushSnackbar('new billing created'));
	};

	const onBillingCreateCancel = () => {
		setCreating(false);
		setNewBilling({
			id: -1,
			customerId: customerId,
			accountName: '',
			bankName: '',
		});
	};

	const onStartBillingCreating = () => {
		setCreating(true);
		setExpandedId(-1);
		setEditingBilling(-1);
	};

	const onStartBillingEditing = (id: number) => {
		setEditingBilling(id);
		if (id !== -1) {
			setCreating(false);
		}
	};

	const billingsList = () => {
		if (id !== -1) {
			const billingComponents: JSX.Element[] = [];

			billings.map((x, i) => {
				billingComponents.push(
					<CustomerBilling
						key={i}
						index={i}
						data={x}
						isEditing={editingBilling === x.id}
						onStartEdit={onStartBillingEditing}
						onExpand={(id: number | null) => setExpandedId(id)}
						expandedId={expandedId}
						view={view}
					/>,
				);
			});

			if (billings.length > 0) {
				return <>{billingComponents}</>;
			} else {
				return <></>;
			}
		} else {
			return <></>;
		}
	};

	return (
		<>
			{billingsList()}
			{!view && (
				<AddElementButton
					isCreating={isCreating}
					onStartCreating={onStartBillingCreating}
					onCreate={onBillingCreate}
					onCancel={onBillingCreateCancel}
					scrollToBottom
				>
					<Box>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant='outlined'
									id='accountName'
									label={t('customer.accountName')}
									fullWidth
									onChange={(e) => {
										setNewBilling({
											...newBilling,
											accountName: e.target.value,
										});
									}}
									value={newBilling.accountName ?? ''}
								/>
							</Grid>
						</Grid>
					</Box>
				</AddElementButton>
			)}
		</>
	);
};
