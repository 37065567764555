import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	value: number | null | undefined;
	view?: boolean;
	prefix: string;
	disableByPiece?: boolean;
	onChange: (value: number | null | undefined) => void;
}

type UnitType = 'piece' | 'weight';

export const UnitSelector = ({ value, view, prefix, disableByPiece, onChange }: Props) => {
	const { t } = useTranslation();
	const canEdit = view ? !view : true;

	const [weight, setWeight] = useState(value);

	const calculateValue = (newValue: number | null | undefined): UnitType => {
		if (newValue != undefined && newValue !== 0) {
			return 'weight';
		}
		return 'piece';
	};
	const [unitType, setUnitType] = useState<UnitType>(calculateValue(value));

	useEffect(() => {
		const unitValue = disableByPiece ? 'weight' : calculateValue(value);
		if (unitValue !== unitType) {
			const newValue = (!value || value === 0) && disableByPiece ? 1 : value;
			setWeight(newValue);
			setUnitType(unitValue);
			onChange(newValue);
		}
	}, [value, disableByPiece]);

	const onValueChange = (type: UnitType | number | null) => {
		if (typeof type === 'string') {
			setUnitType(type);
			switch (type) {
				case 'piece':
					setWeight(0);
					onChange(0);
					break;
				case 'weight':
					setWeight(1);
					onChange(weight === 0 ? 1 : weight);
					break;
			}
		} else {
			setWeight(type);
			onChange(type);
		}
	};

	return (
		<Grid item xs={12} sx={{ minHeight: '2rem', display: 'flex', justifyItems: 'center' }}>
			<FormControl
				fullWidth
				size='small'
				sx={{
					marginTop: '0px',
					marginBottom: '0px',
					display: 'flex',
					justifyContent: 'center',
					border: '1px solid rgba(0,0,0,.24)',
					borderRadius: '4px',
				}}
			>
				<FormLabel
					id='unit-selector-group-label'
					style={{
						marginLeft: '10px',
						padding: '0 2px 0 2px',
						fontSize: '0.72em',
						transform: 'translate(0px, -4px)',
						color: 'rgba(0, 0, 0, 0.6)',
						position: 'absolute',
						top: '-7px',
						backgroundColor: 'white',
						flexGrow: 1,
					}}
				>
					{t(`product.${prefix}Type`)}
				</FormLabel>
				{canEdit ? (
					<RadioGroup
						aria-labelledby='unit-selector-group-label'
						row
						name='radio-buttons-group'
						onChange={(ev) => onValueChange(ev.target.value as UnitType)}
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
						}}
						value={unitType}
					>
						<FormControlLabel
							value='piece'
							control={<Radio />}
							disabled={disableByPiece}
							label={t(`product.${prefix}ByPiece`)}
						/>
						<FormControlLabel
							value='weight'
							control={<Radio />}
							label={
								unitType === 'weight' ? (
									<TextField
										fullWidth
										select
										type='number'
										size='small'
										value={weight ?? 0}
										inputProps={{ min: 1 }}
										onChange={(ev) => onValueChange(+ev.target.value)}
									>
										<MenuItem value={1}>1g</MenuItem>
										<MenuItem value={100}>100g</MenuItem>
										<MenuItem value={500}>500g</MenuItem>
										<MenuItem value={1000}>1Kg</MenuItem>
									</TextField>
								) : (
									t(`product.${prefix}ByWeight`)
								)
							}
						/>
					</RadioGroup>
				) : (
					<div
						style={{
							translate: '-2.5px 0px',
							width: '100%',
							font: 'inherit',
							padding: '14px 16.5px 14px 16.5px',
							boxSizing: 'border-box',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<>
							{unitType === 'piece'
								? t(`product.${prefix}ByPiece`)
								: `${t(`product.${prefix}ByWeight`)} - ${weight}g`}
						</>
					</div>
				)}
			</FormControl>
		</Grid>
	);
};
