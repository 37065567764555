import useAppFunctions from '@hooks/useAppFunctions';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TableLocationBodyProps {
	warehouseId?: number;
	warehousesId?: number[];
	onChangeWarehouse?: (warehouseId: number | 'all', subIds?: number[]) => void;
}

// TODO: Change the gfx and clean the code
export const TableLocationBody = <T extends TableLocationBodyProps>({
	warehouseId,
	warehousesId,
	onChangeWarehouse,
}: T): JSX.Element | null => {
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const { warehouseList } = useWarehouses();
	const { getIcon } = useAppFunctions();
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const boxRef = useRef<HTMLDivElement | null>(null); // Create a ref for the "culo" box

	const handleClickOutside = (event: MouseEvent) => {
		if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
			setOpen(false); // Close the box or execute any other callback
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	if (!warehouseId && !warehousesId) {
		return null;
	}

	if (warehousesId !== undefined) {
		const warehouses = warehouseList.filter((w) => warehousesId.includes(w.id));

		return (
			<Box overflow='visible' position='relative'>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: open ? 2 : 0,
						width: open ? '260%' : '100%',
						opacity: open ? 1 : 0,
						height: open
							? `calc(${warehouses.length * 100}% + ${(warehouses.length - 1) * 0.5 + 0.4}rem)`
							: '100%',
						backgroundColor: theme.palette.success.main,
						transform: `translate(-${open ? 30 : 0}%, -${open ? 100 / warehouses.length / 2 : 0}%)`,
						transition: open ? 'all 0.5s, transform 0.1s' : 'all 0.5s, transform 0.6s',
						borderRadius: '0.5rem',
						padding: '0.2rem 0.5rem',
						border: `1px solid ${theme.palette.success.light}`,
					}}
					ref={boxRef}
				>
					<Stack
						direction='column'
						gap={1}
						display='flex'
						alignItems='center'
						sx={{
							height: '100%',
						}}
					>
						{warehouses.map((w, index) => (
							<Box
								key={w.id}
								sx={{
									opacity: open ? 1 : 0,
									transform: open ? 'scale(1)' : 'scale(0)',
									transition: 'all 0.4s',
									transitionDelay: `${index * 0.1}s`,
									width: '100%',
								}}
							>
								<Button
									key={w.id}
									variant='contained'
									size='small'
									onClick={() => {
										setOpen(false);
										onChangeWarehouse?.(w.id);
									}}
									color='info'
									sx={{
										borderRadius: '0.5rem',
										padding: '0.1rem 0.5rem',
										display: 'flex',
										width: '100%',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									{getIcon(AppFunction.Location, w.storageType, false, { fontSize: '1.2rem' })}
									<Box flexGrow={1} justifyContent='center' alignItems='center'>
										{getTranslatedString(AppFunction.Location, w.id, TranslationTypes.name)}
									</Box>
								</Button>
							</Box>
						))}
					</Stack>
				</Box>
				<Button
					variant='contained'
					size='small'
					onClick={() => {
						if (warehousesId.length > 1) {
							setOpen(!open);
						} else {
							onChangeWarehouse?.(warehousesId[0]);
						}
					}}
					color='success'
					sx={{
						opacity: open ? 0 : 1,
						borderRadius: '0.5rem',
						padding: '0.2rem 0.5rem',
						display: 'flex',
						zIndex: 1,
					}}
				>
					{warehouses.length === 1 &&
						getIcon(AppFunction.Location, warehouses?.[0].storageType, false, {
							fontSize: '1.2rem',
						})}
					<Box flexGrow={1} justifyContent='center' alignItems='center'>
						{warehousesId.length > 1
							? t('inventory.accrued')
							: getTranslatedString(AppFunction.Location, warehousesId[0], TranslationTypes.name)}
					</Box>
				</Button>
			</Box>
		);
	}

	if (warehouseId !== undefined) {
		const warehouse = warehouseList.find((w) => w.id === warehouseId);
		if (!warehouse) {
			return null;
		}

		return (
			<Button
				variant='outlined'
				size='small'
				color='success'
				sx={{
					borderRadius: '0.5rem',
					padding: '0.1rem 0.5rem',
					display: 'flex',
					pointerEvents: 'none',
				}}
			>
				{getIcon(AppFunction.Location, warehouse.storageType, false, { fontSize: '1.2rem' })}
				<Box flexGrow={1} justifyContent='center'>
					{getTranslatedString(AppFunction.Location, warehouseId, TranslationTypes.name)}
				</Box>
			</Button>
		);
	}

	return null;
};
