import { useLayout } from '@contexts/index';
import { ArrowRight, Close } from '@mui/icons-material';
import { IconButton, Slide, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

interface Props {
	children: ReactNode;
	menuContent?: ReactNode;
}

// TODO: the background should be outside of the slider
//       and triggered by sliderOpen.
export const SliderMenu = ({ children, menuContent }: Props) => {
	const theme = useTheme();
	const { visiblePadding, drawerOpen } = useLayout();
	const [sliderOpen, setSliderOpen] = useState<boolean>(false);
	const [isOverDragArea, setIsOverDragArea] = useState<boolean>(false);
	const [closedMenuX, setClosedMenuX] = useState<number>(0);

	const [lastDrawerState, setLastDrawerState] = useState<boolean>(drawerOpen);
	const [sliderDirty, setSliderDirty] = useState<boolean>(false);

	useEffect(() => {
		if (lastDrawerState !== drawerOpen) {
			setSliderDirty(true);
			setLastDrawerState(drawerOpen);
		}
	}, [drawerOpen]);

	useEffect(() => {
		const handleKeyDown = (event: { key: string }) => {
			if (event.key === 'Escape' && sliderOpen) {
				setSliderOpen(false);
			}
		};
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [sliderOpen]);

	useEffect(() => {
		setSliderDirty(false);
		setClosedMenuX(menuContent ? visiblePadding.left - 25 : visiblePadding.left - 50);
		if (!menuContent && sliderOpen) {
			setSliderOpen(false);
		}
	}, [menuContent, visiblePadding.left]);

	return (
		<div style={{ display: 'flex', position: 'relative', height: '100%' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'fixed',
					left:
						sliderOpen || sliderDirty
							? -200
							: isOverDragArea
							? visiblePadding.left - 10
							: closedMenuX,
					height: 'calc(100vh - 64px)',
					top: '64px',
					width: '25px',
					transition: sliderDirty ? '' : 'left 0.3s ease-in-out, opacity 0.2s ease-in-out',
					zIndex: 11,
					opacity: sliderDirty ? 0 : isOverDragArea ? 1 : 0.5,
					background: theme.palette.gradient.primary,
					cursor: 'pointer',
				}}
				onMouseEnter={setIsOverDragArea.bind(null, true)}
				onMouseLeave={setIsOverDragArea.bind(null, false)}
				onClick={() => setSliderOpen((open) => !open)}
			>
				<div
					style={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						height: '100px',
						width: '50px',
						display: 'flex',
						justifyContent: 'right',
						alignItems: 'center',
						borderRadius: '0 5% 5% 0',
					}}
				>
					<div style={{ transform: 'rotate(-90deg)', pointerEvents: 'none' }}>MENU</div>
					<ArrowRight sx={{ height: '30px', width: '30px', position: 'absolute', right: -20 }} />
				</div>
			</div>
			<IconButton
				style={{
					position: 'fixed',
					right: '10px',
					top: '75px',
					transform: `rotate(${sliderOpen ? 180 : 0}deg) scale(${sliderOpen ? 1 : 0})`,
					transition: 'transform 0.4s ease-in-out',
					zIndex: 11,
				}}
				onClick={() => setSliderOpen(false)}
			>
				<Close />
			</IconButton>
			<Slide direction='right' in={sliderOpen} mountOnEnter unmountOnExit timeout={400}>
				<div
					style={{
						background: theme.palette.gradient.primaryAlpha,
						height: 'calc(100vh - 64px)',
						top: '64px',
						width: '100%',
						position: 'fixed',
						left: visiblePadding.left,
						zIndex: 10,
						backdropFilter: 'blur(1px)',
					}}
					onClick={() => setSliderOpen(false)}
				>
					<div
						style={{
							backgroundColor: 'white',
							height: '100%',
							width: '200px',
							zIndex: 12,
							textAlign: 'center',
						}}
					>
						{menuContent}
					</div>
				</div>
			</Slide>
			<div
				style={{
					flexGrow: 1,
					paddingLeft: sliderOpen ? '200px' : 0,
					transition: 'padding-left 0.4s ease-in-out',
				}}
			>
				{children}
			</div>
		</div>
	);
};
