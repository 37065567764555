import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@mui/material';
import { AnimatedIconButton } from './AnimatedIconButton';
import { Add, Cancel, Check } from '@mui/icons-material';

export interface AddElementButtonProps {
	isCreating: boolean;
	onStartCreating: () => void;
	onCreate: () => void;
	onCancel: () => void;
	children: JSX.Element;
	scrollToBottom?: boolean;
}

export const AddElementButton = (props: AddElementButtonProps) => {
	const { t } = useTranslation();
	const [isCreating, setCreating] = useState(props.isCreating);

	useEffect(() => {
		setCreating(props.isCreating);
	}, [props.isCreating]);

	useEffect(() => {
		if (bottomElement.current === null) return;
		if (!props.scrollToBottom) return;
		if (isCreating) {
			bottomElement.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	}, [isCreating]);

	const bottomElement = useRef<null | HTMLElement>(null);

	return (
		<>
			{!isCreating ? (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<AnimatedIconButton
						text='operations.create'
						translate
						icon={<Add />}
						onClick={props.onStartCreating}
					/>
				</div>
			) : (
				<Box sx={{ marginBottom: '24px' }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{props.children}
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.create'
								translate
								colorType='success'
								icon={<Check />}
								onClick={props.onCreate}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								translate
								colorType='error'
								icon={<Cancel />}
								onClick={props.onCancel}
							/>
						</Grid>
						<Box id={'el'} ref={bottomElement} />
					</Grid>
				</Box>
			)}
		</>
	);
};
