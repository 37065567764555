import { Avatar, useTheme } from '@mui/material';
import { ImagesResponse } from 'common';

interface Props {
	images: ImagesResponse;
}

export const AvatarRowBodyTemplate = ({ images }: Props) => {
	const theme = useTheme();
	let image =
		'https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg';
	let alt = 'no image';
	if (images?.length) {
		image = images[0].url;
		alt = images[0].name ?? 'no image';
	}
	return (
		<Avatar
			alt={alt}
			src={image}
			sx={{
				border: '1px solid',
				borderColor: theme.palette.secondary.main,
				boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
				transform: 'scale(1)',
				transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
				pointerEvents: images?.length ? 'auto' : 'none',
				cursor: images?.length ? 'pointer' : 'default',
				'&:hover': {
					transform: images?.length ? 'scale(1.2)' : 'scale(1)',
				},
			}}
		/>
	);
};
