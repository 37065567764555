import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import { CloseOrderValue } from '../../config';
import { useEffect, useState } from 'react';
import { List } from '@mui/material';
import { CloseOrderFieldValues } from '@contexts/taskContext/config/closeOrderFieldConfig';
import moment from 'moment';
import { InboundCloseOrderFieldItem } from './InboundCloseOrderFieldItem';
import { ContainerComponentProps } from '@components/taskworkflow/tasks/config';
import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';
import { MoveFromToBanner } from '@components/common/banners/MoveFromToBanner';

export const InboundCloseOrderFieldContainer: React.FC<ContainerComponentProps> = ({
	taskFieldId,
}: ContainerComponentProps) => {
	const { targetId, setTaskFieldValues } = useTask();
	const { orderList } = useOrders();
	const [values, setValues] = useState<{ [key: number]: CloseOrderValue }>([]);
	const order = orderList.find((order) => order.id === targetId);

	/**
	 * Initializes the field values based on either the context or the current order.
	 */
	useEffect(() => {
		if (order) {
			const newValues: { [key: number]: CloseOrderValue } = {};
			order.content
				?.filter((c) => c.variantId !== undefined && c.variantId !== null)
				.forEach((content) => {
					const orderProductId = getParsedId(content.id);
					if (!orderProductId) {
						return;
					}
					console.log(content);
					// This is an import order, it won't ever be connected to other orders
					// and it's always the first order in the list
					// so we don't have parents/sources
					const value = {
						expiresAt: content.expiresAt ? moment(content.expiresAt) : null,
						arrivedQty: content.arrivedQty ?? 0,
						variantId: content.variantId ?? -1,
						sourceId: null,
						weight: content.weight ?? 0,
						estimated: content.estimated ?? false,
					};
					newValues[orderProductId] = {
						oldValues: value,
						newValues: [value],
					};
				});

			setValues(newValues);
			setValuesInContext(newValues);
		}
	}, [targetId, order]);

	/**
	 * Converts values from local state to context state
	 * @param {{[key: number]: CloseOrderValue}} values
	 */
	const setValuesInContext = (values: { [key: number]: CloseOrderValue }) => {
		const newValues: CloseOrderFieldValues = Object.entries(values).map(([key, value]) => ({
			orderProductId: Number(key),
			variantId: value.oldValues.variantId,
			warehouseId: order?.toId ?? -1,
			oldValues: {
				...value.oldValues,
				expiresAt: value.oldValues.expiresAt ? value.oldValues.expiresAt.toDate() : null,
				actualExpiration: value.oldValues.actualExpiration
					? value.oldValues.actualExpiration.toDate()
					: null,
			},
			newValues: value.newValues.map((v) => ({
				...v,
				expiresAt: v.expiresAt ? v.expiresAt.toDate() : null,
				actualExpiration: v.actualExpiration ? v.actualExpiration.toDate() : null,
			})),
		}));
		setTaskFieldValues(taskFieldId, newValues);
	};

	if (!order || !order.content?.[0]) {
		return null;
	}

	return (
		<div style={{ padding: '1rem' }}>
			<MoveFromToBanner order={order} />
			<List>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content, it) => {
						const orderProductId = getParsedId(content.id);
						if (!orderProductId) {
							return <></>;
						}
						const contentValues = values[orderProductId];
						if (contentValues) {
							return (
								<InboundCloseOrderFieldItem
									key={it}
									value={contentValues}
									content={content}
									iterator={it}
								/>
							);
						}
					})}
			</List>
		</div>
	);
};
