// import { useGenericForm } from '@contexts/formContext/FormContext';
// import useImages from '@hooks/useImages';
import { Grid } from '@mui/material';

export const PicturesTab = () => {
	// const { entityId } = useGenericForm();
	// const { imageList } = useImages();

	return (
		<Grid
			container
			sx={{
				margin: '2rem 0 2rem 0',
				padding: '0 2rem 2rem 2rem',
				borderRadius: '2rem',
				backgroundColor: 'white',
			}}
		>
			PicturesTab
		</Grid>
	);
};
