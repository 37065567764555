import { useLayout } from '../../../contexts/layoutContext/LayoutContext';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingBox } from './LoadingBox';
import { AppFunction } from 'common';

const fadeTime = 400;

// TODO: use the loadingData from the layout context (allow a text instead of the loading text and a custom icon)
export const LoadingHandler = () => {
	const { loadingData, popLoadingData } = useLayout();
	const [loading, setLoading] = useState(false);
	const [cachedData, setCachedData] = useState(loadingData);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (loadingData && !loading) {
			setLoading(true);
			setCachedData(loadingData);
		} else if (!loadingData && loading) {
			timer = setTimeout(() => {
				setCachedData(null);
				setLoading(false);
			}, fadeTime);
		}

		return () => clearTimeout(timer);
	}, [loadingData, loading]);

	return (
		<Box
			sx={{
				position: 'fixed',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backdropFilter: loadingData ? 'blur(5px)' : 'blur(0px)',
				visibility: loading ? 'visible' : 'hidden',
				transition: 'all 0.4s ease-in-out',
				zIndex: 9998,
			}}
			onClick={() => popLoadingData()}
		>
			<div
				style={{
					transform: `scale(${loadingData ? 1 : 0})`,
					transition: `all ${fadeTime}ms cubic-bezier(0.34, 1.2, 0.84, 1)`,
					position: 'relative',
				}}
			>
				<LoadingBox appFunction={cachedData?.contentType as AppFunction} />
			</div>
		</Box>
	);
};
