import { useOrderEditor } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CutOrderHeader = () => {
	const { control, getValues, renderType } = useOrderEditor();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const fromList = () => {
		return warehouseList.map((value, it) => (
			<MenuItem key={it} value={value.id}>
				{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
			</MenuItem>
		));
	};

	return (
		<Grid item container columnSpacing={2} rowGap={2}>
			{/* <Grid item xs={3}>
				<Controller
					name='fromId'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='from'>{t('order.from.merchant')}</InputLabel>
							<Select
								labelId='from'
								id='from'
								size='small'
								label={t('order.from.merchant')}
								value={field.value ?? -1}
								fullWidth
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? undefined : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.from.undefined')}
								</MenuItem>
								{fromList()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid> */}
		</Grid>
	);
};
