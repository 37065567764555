import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { TaskFieldArchetypeResponse } from 'common';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getIcon } from '../../contexts/chainEditorContext/ChainEditorContext';

interface Props {
	archetype: TaskFieldArchetypeResponse;
	isDragging: boolean;
	provided?: DraggableProvided;
}

export const TaskFieldListItem = ({ archetype, isDragging, provided }: Props) => {
	return (
		<ListItem
			ref={provided?.innerRef}
			{...provided?.draggableProps}
			{...provided?.dragHandleProps}
			style={provided ? provided.draggableProps.style : {}}
			sx={{
				backgroundColor: 'white',
				opacity: provided ? 1 : 0.5,
				transform: isDragging ? undefined : 'translate(0px, 0px) !important',
				borderRadius: '3px',
				border: provided ? '1px solid black' : '1px dashed black',
				marginBottom: '3px',
				padding: '0.3rem',
			}}
		>
			<ListItemIcon sx={{ minWidth: '30px' }}>{getIcon(archetype.type)}</ListItemIcon>
			<ListItemText sx={{ userSelect: 'none' }}>[{archetype.name}]</ListItemText>
		</ListItem>
	);
};
