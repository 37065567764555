import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { AppFunction, PersonResponse, PersonType, UserEditRequest, UserResponse } from 'common';
import useUsers from '@hooks/useUsers';
import { Control, useForm } from 'react-hook-form';
import { useGenericForm } from '../FormContext';
import usePeople from '@hooks/usePeople';
import _ from 'lodash';

interface UserFormContextType {
	editDisabled?: boolean;
	roles: number[];
	setRoles: (roles: number[]) => void;
	workplaces: number[];
	setWorkplaces: (workplaces: number[]) => void;
	control: Control<PersonResponse>;
}

const defaultContext: UserFormContextType = {
	roles: [],
	setRoles: () => console.error('UserFormContext not initialized'),
	workplaces: [],
	setWorkplaces: () => console.error('UserFormContext not initialized'),
	control: {} as Control<PersonResponse>,
};

interface UserFormContextProps {
	children: ReactNode;
	entityId: number;
}

const UserFormContext = createContext<UserFormContextType>(defaultContext);

export const UserFormContextProvider = ({ children, entityId }: UserFormContextProps) => {
	const { setAfterCreate, setAfterEdit, formAction, setForcedDirtyState } =
		useGenericForm<UserEditRequest>();
	const { userList, linkUserToRoles, linkUserToWorkplaces } = useUsers();
	const { editPerson, createPerson } = usePeople();

	const [cachedRoles, setCachedRoles] = useState<number[]>([]);
	const [roles, setRoles] = useState<number[]>([]);

	const [cachedWorkplaces, setCachedWorkplaces] = useState<number[]>([]);
	const [workplaces, setWorkplaces] = useState<number[]>([]);

	const [cachedValues, setCachedValues] = useState<PersonResponse | null>(null);

	const editDisabled = formAction === 'view';

	const cascadeRequestsRef = useRef<(data: UserEditRequest) => Promise<boolean>>(() =>
		Promise.reject(),
	);

	const { control, getValues, reset, watch } = useForm<PersonResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: '',
			surname: '',
			type: PersonType.user,
			addresses: [],
		},
	});

	useEffect(() => {
		const user = userList.find((c) => c.id === entityId);
		if (user) {
			const newRoles = user.roles?.map((role) => role.sectionId) ?? [];
			const newWorkplaces = user.workplaces?.map((workplace) => +workplace.warehouseId) ?? [];
			const personResponse = {
				id: user.person?.id ?? -1,
				populatableId: user.id,
				name: user.person?.name ?? '',
				surname: user.person?.surname ?? '',
				type: PersonType.user,
			};
			reset(personResponse);
			setRoles(newRoles);
			setCachedRoles(newRoles);
			setWorkplaces(newWorkplaces);
			setCachedWorkplaces(newWorkplaces);
			setCachedValues(personResponse);
			setForcedDirtyState(false, AppFunction.Person);
		}
	}, [entityId, userList, reset]);

	useEffect(() => {
		const hasPersonDataChanged = !_.isEqual(getValues(), cachedValues);
		setForcedDirtyState(hasPersonDataChanged, AppFunction.Person);
	}, [watch('name'), watch('surname')]);

	useEffect(() => {
		const haveRolesChanged = !_.isEqual(_.sortBy(roles), _.sortBy(cachedRoles));
		setForcedDirtyState(haveRolesChanged, AppFunction.Role);
	}, [roles]);

	useEffect(() => {
		const haveWorkplacesChanged = !_.isEqual(_.sortBy(workplaces), _.sortBy(cachedWorkplaces));
		setForcedDirtyState(haveWorkplacesChanged, AppFunction.Location);
	}, [workplaces]);

	cascadeRequestsRef.current = async (data: UserEditRequest): Promise<boolean> => {
		try {
			await linkUserToWorkplaces(
				workplaces.map((workplace) => ({
					userId: data.id,
					warehouseId: workplace,
				})),
			);
			await linkUserToRoles(
				roles.map((role) => ({
					userId: data.id,
					sectionId: role,
				})),
			);

			const personData = getValues();
			if (personData.id && personData.id !== -1) {
				await editPerson({
					...personData,
					populatableId: data.id,
				});
			} else {
				await createPerson({
					...personData,
					addresses: personData.addresses ?? [],
					populatableId: data.id,
				});
			}
			setCachedValues(personData);
			setCachedRoles(roles);
			setCachedWorkplaces(workplaces);
			setForcedDirtyState(false);
			return true;
		} catch (error) {
			return Promise.reject(error);
		}
	};

	useEffect(() => {
		setAfterCreate((data: UserEditRequest): Promise<boolean> => {
			cascadeRequestsRef.current(data).catch((error) => {
				return Promise.reject(error);
			});
			return Promise.resolve(true);
		});
	}, [setAfterCreate]);

	useEffect(() => {
		setAfterEdit((data: UserEditRequest): Promise<boolean> => {
			cascadeRequestsRef.current(data).catch((error) => {
				return Promise.reject(error);
			});
			return Promise.resolve(true);
		});
	}, [setAfterEdit]);

	const contextValue = {
		editDisabled,
		roles,
		setRoles,
		workplaces,
		setWorkplaces,
		control,
	};

	return <UserFormContext.Provider value={contextValue}>{children}</UserFormContext.Provider>;
};

export const useUserForm = () => useContext(UserFormContext);
