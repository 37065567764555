import useLocale from '@hooks/useLocale';
import {
	AppFunction,
	CarrierPlanResponse,
	CarrierPlanSchema,
	CarrierPriceResponse,
	CarrierPricesResponse,
	StorageConditionType,
	TranslationTypes,
} from 'common';
import { useEffect, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
	AccordionDetails,
	Box,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Tooltip,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PresetInputLangGroup } from '@components/PresetInputLangGroup';
import { Add, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { DeliveryPrice } from './DeliveryPrice';
import { ContextualDropdownMenu } from '@components/ContextualDropdownMenu';
import { useTranslation } from 'react-i18next';
import { useDeliveryPlans } from '@contexts/deliveryPlansContext/DeliveryPlansContext';
import useCarriers from '@hooks/useCarriers';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import useAppFunctions from '@hooks/useAppFunctions';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	plan: CarrierPlanResponse;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion elevation={1} {...props} />)(
	() => ({
		border: '1px solid rgba(0, 0, 0, .050)',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&::before': {
			display: 'none',
		},
	}),
);

interface ExtendedAccordionSumamryProps extends AccordionSummaryProps {
	canExpand?: boolean;
}

const AccordionSummary = styled((props: ExtendedAccordionSumamryProps) => {
	const { canExpand, ...otherProps } = props;
	return (
		<MuiAccordionSummary
			expandIcon={canExpand && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...otherProps}
		/>
	);
})(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
	'&.Mui-focusVisible': {
		backgroundColor:
			theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	},
}));

export const DeliveryPlan = ({ plan }: Props) => {
	const {
		creating,
		editing,
		creatingPrice,
		editingPrice,
		setCreating,
		setEditing,
		setCreatingPrice,
		callDeleteDeliveryPlanDialog,
	} = useDeliveryPlans();
	const { createCarrierPlan } = useCarriers();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();

	const [isExpanded, setIsExpanded] = useState(false);
	const [headPrices, setHeadPrices] = useState<CarrierPricesResponse>([]);
	const [subPrices, setSubPrices] = useState<Record<string, CarrierPricesResponse>>({});

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CarrierPlanResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: { ...plan },
		resolver: zodResolver(CarrierPlanSchema),
	});

	useEffect(() => {
		const headPrices: Record<string, CarrierPriceResponse> = {};
		const subPrices: Record<string, CarrierPricesResponse> = {};
		plan.carrierPrices?.forEach((price) => {
			const key = `${price.originAreaId}-${price.destinationAreaId}`;
			if (!headPrices[key]) {
				headPrices[key] = price;
			} else {
				if (!subPrices[key]) {
					subPrices[key] = [];
				}
				subPrices[key].push(price);
			}
		});
		setHeadPrices(Object.values(headPrices));
		setSubPrices(subPrices);
	}, [plan]);

	const onSubmit = async (data: CarrierPlanResponse) => {
		if (data.id === -1) {
			await createCarrierPlan(data);
			setCreating(false);
		} else {
			setEditing(null);
		}
	};

	const onCancel = () => {
		if (creating) {
			setCreating(false);
		} else {
			setEditing(null);
			reset(plan);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				gap: '1rem',
			}}
		>
			<Accordion
				expanded={isExpanded && !creating && editing === null}
				onChange={() => {
					if (plan.id !== -1 && !editing) {
						setIsExpanded((oldValue) => !oldValue);
					}
				}}
				sx={{
					flexGrow: 1,
					flexBasis: 0,
				}}
			>
				<AccordionSummary canExpand={plan.id !== -1 && !editing}>
					{plan.id === -1 || editing === plan.id ? (
						<Grid container rowGap={1}>
							<Controller
								name='strings'
								control={control}
								render={({ field }) => (
									<PresetInputLangGroup
										useReactHookForm
										showOnlyCurrent
										type={TranslationTypes.name}
										strings={field.value ?? []}
										onChange={field.onChange}
									/>
								)}
							/>
							<Controller
								name='planStorageType'
								control={control}
								render={({ field }) => (
									<InputGridField
										muiLabel={{
											label: t('carrier.storageType'),
											labelId: 'storage-type',
										}}
										type={ComponentType.Select}
									>
										<Select
											value={field.value}
											onChange={(e) => {
												const value = e.target.value as StorageConditionType;
												field.onChange(value);
											}}
											fullWidth
											disabled={!creating && !editing}
											size='small'
											labelId='storage-type'
											variant='outlined'
											label={t('carrier.storageType')}
											error={!!errors.planStorageType}
										>
											{Object.values(StorageConditionType).map((type, it) => (
												<MenuItem key={it} value={type}>
													{t(`${AppFunction.Carrier}.storageTypes.${type}`)}
												</MenuItem>
											))}
										</Select>
									</InputGridField>
								)}
							/>
							<Grid item xs={12}>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<IconButton aria-label='submit' color='success' onClick={handleSubmit(onSubmit)}>
										<Save />
									</IconButton>
									<IconButton aria-label='delete' color='error' onClick={onCancel}>
										<Cancel />
									</IconButton>
								</Box>
							</Grid>
						</Grid>
					) : (
						<Box
							sx={{
								position: 'relative',
								width: 'calc(100% - 1rem)',
								marginLeft: '1rem',
								display: 'flex',
								justifyContent: 'center',
								// TODO: maybe add colors to differentiate between storage types
								// this could be part of a bigger color map connected to the appfunction config
								backgroundColor: '#e0e0e0',
								padding: '0.5rem',
								borderRadius: '0.5rem',
							}}
						>
							<Tooltip title={t(`${AppFunction.Carrier}.storageTypes.${plan.planStorageType}`)}>
								<Box
									sx={{
										position: 'absolute',
										left: '1rem',
									}}
								>
									{getIcon(AppFunction.CarrierPlan, plan.planStorageType)}
								</Box>
							</Tooltip>
							{getTranslatedString(AppFunction.CarrierPlan, plan.id, TranslationTypes.name)}
						</Box>
					)}
				</AccordionSummary>
				<AccordionDetails>
					<Stack
						gap={0.5}
						sx={{
							width: '100%',
							marginTop: '1rem',
						}}
					>
						<div>
							{headPrices.map((p, it) => (
								<DeliveryPrice
									key={it}
									price={p}
									subPrices={subPrices[`${p.originAreaId}-${p.destinationAreaId}`]}
								/>
							))}
							{creatingPrice === plan.id.toString() && (
								<DeliveryPrice
									price={{
										id: -1,
										planId: plan.id,
										originAreaId: 1,
										destinationAreaId: 1,
										price: 0,
										deliveryOffset: 1,
										deliveryTimeId: 1,
										size: '',
										weight: 0,
									}}
								/>
							)}
						</div>
						<Box
							sx={{
								marginTop: '1rem',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<AnimatedIconButton
								text={t('operations.create')}
								icon={<Add />}
								onClick={() => setCreatingPrice(plan.id.toString())}
								disabled={!!editing || creating || editingPrice !== null || creatingPrice !== null}
							/>
						</Box>
					</Stack>
				</AccordionDetails>
			</Accordion>
			{editing !== plan.id && plan.id !== -1 && (
				<Box
					sx={{
						flexShrink: 1,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<ContextualDropdownMenu
						disabled={
							creating || editing !== null || editingPrice !== null || creatingPrice !== null
						}
						entries={[
							{
								entryName: t('operations.edit'),
								entryIcon: <Edit />,
								entryAction: () => {
									setEditing(plan.id);
								},
							},
							{
								entryName: t('operations.delete'),
								entryIcon: <Delete />,
								entryAction: () => callDeleteDeliveryPlanDialog({ id: plan.id }),
							},
						]}
					/>
				</Box>
			)}
		</Box>
	);
};
