import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGenericForm } from '@contexts/formContext/FormContext';
import useCustomerTypes from '@hooks/useCustomerTypes';

import { Grid, TextField } from '@mui/material';

import { AppFunction, CustomerResponse, TranslationTypes } from 'common';

import { TranslatableAutocompleteWithCallback } from '@components/TranslatableAutocompleteWithCallback';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { CustomerCode } from './CustomerCode';
import { CompanyCode } from './CompanyCode';
import { CompanyStructure } from './CompanyStructure';
import { PeopleData } from './PeopleData';
import { ReferentSelector } from './ReferentSelector';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useFeedbacks } from '@contexts/index';
import { QuickCustomerTypeDialog } from '@components/dialogs/customerType/QuickCustomerTypeDialog';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const GeneralInfo = () => {
	const { control, errors, formAction, setValue } = useGenericForm<CustomerResponse>();
	const { pushComponentDialog } = useFeedbacks();
	const { t } = useTranslation();
	const { customerTypesList } = useCustomerTypes();

	const canEdit = formAction !== 'view';

	function onCreateNewCustomerType(newName: string) {
		pushComponentDialog({
			title: t('operations.createItem', { item: t('customer.customerType') }),
			component: QuickCustomerTypeDialog,
			data: {
				newName,
			},
			minWidth: 80,
			type: 'component',
		}).then(async (result) => {
			if (result !== false) {
				// TODO: akshually we should parse the result to get the id
				if (result.id && typeof result.id === 'number') {
					setValue('typeId', result.id);
				}
			}
		});
	}

	return (
		<>
			<TabInnerTitle title='customer.generalInfo' translate />
			<Grid container spacing={2}>
				<CustomerCode />
				<CompanyCode />
				<Controller
					name='qbbrNumber'
					control={control}
					render={({ field }) => (
						<InputGridField width={12} type={ComponentType.TextField}>
							<TextField
								variant='outlined'
								{...field}
								size='small'
								fullWidth
								multiline
								disabled={!canEdit}
								id='qbbrNumber'
								label={t('customer.qbbrNumber')}
								value={field.value ?? ''}
							/>
						</InputGridField>
					)}
				/>
				<Controller
					name='typeId'
					control={control}
					render={({ field }) => (
						<InputGridField width={12} type={ComponentType.Autocomplete}>
							<TranslatableAutocompleteWithCallback
								data={customerTypesList ?? []}
								value={field.value ?? -1}
								translationSettings={{
									translatableType: AppFunction.CustomerType,
									translationType: TranslationTypes.name,
									namespace: 'locale',
								}}
								id='typeId'
								label={t('customer.customerType')}
								error={!!errors.typeId}
								onChange={(value) => field.onChange(value)}
								size='small'
								disabled={!canEdit}
								onCreateNew={(newName) => onCreateNewCustomerType(newName)}
							/>
						</InputGridField>
					)}
				/>
				<CompanyStructure />
				<ReferentSelector />
				<PeopleData />
			</Grid>
		</>
	);
};
