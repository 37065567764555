import React from 'react';
import { OrderBaseResponse, OrderProductResponse, OrderType } from 'common';
import { ManualEntryContainer } from './ManualEntry/ManualEntryContainer';
import { OutboundContainer } from './Outbound/OutboundContainer';
import { InboundContainer } from './Inbound/InboundContainer';

export interface OrderDetailsContainerProps {
	data: OrderBaseResponse;
	orderProduct?: OrderProductResponse;
}

type OrderDetailsEntryConfig = {
	[key in OrderType]: React.FC<OrderDetailsContainerProps> | undefined;
};

export const orderDetailsEntryConfig: OrderDetailsEntryConfig = {
	[OrderType.Manual]: ManualEntryContainer,
	[OrderType.Inbound]: InboundContainer,
	[OrderType.Outbound]: OutboundContainer,
	[OrderType.Internal]: InboundContainer,
	[OrderType.Processing]: undefined,
	[OrderType.Split]: undefined,
	[OrderType.Modify]: undefined,
	[OrderType.Delete]: undefined,
};
