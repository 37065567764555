import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import usePeople from '../hooks/usePeople';
import useFeedbacks from '../hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DeleteItemDialog } from './DeleteItemDialog';
import { AppFunction, PersonCreateSchema, PersonResponse, PersonSchema } from 'common';

import styles from './PersonComponent.module.css';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from '@mui/material';
import { EditShowGridField } from './common/EditShowGridField';
import { ContextualDropdownMenu } from './ContextualDropdownMenu';
import { AddressList } from './addresses/AddressList';
import { JobTitlesSelector } from './JobTitlesSelector';

import { FocusedElementType } from '../store/app';
import { ScopedComponent } from './scopedlayer/ScopedComponent';
import { AnimatedIconButton } from './common/AnimatedIconButton';
import { Cancel, Check } from '@mui/icons-material';
import { ScopedShadowComponent } from './scopedlayer/ScopedShadowComponent';
import { ScopedLayer } from './scopedlayer/ScopedLayer';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
	person: PersonResponse;
	expandedId?: number | null;
	view?: boolean;
	onStopCreating?: () => void;
	onExpand?: (id: number | null) => void;
}

export const PersonComponent = ({ person, expandedId, view, onStopCreating, onExpand }: Props) => {
	const { peopleList, createPerson, editPerson, deletePerson } = usePeople();
	const { showSnackBar } = useFeedbacks();
	const { t } = useTranslation();

	const [canEdit, setCanEdit] = useState(view ? !view : true);
	const [isEditing, setEditing] = useState(view === true ? !view : person.id === -1 ?? false);
	const [expanded, setExpanded] = useState(view === true ? !view : person.id === -1 ?? false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<PersonResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: person,
		resolver: zodResolver(PersonSchema),
	});

	useEffect(() => {
		if (person) {
			const newPerson = peopleList.find((p) => p.id === person.id);
			reset(newPerson);
		}
	}, [peopleList]);

	useEffect(() => {
		if (expandedId !== person.id) {
			setExpanded(false);
		}
	}, [expandedId]);

	useEffect(() => {
		setCanEdit(view ? !view : true);
	}, [view]);

	const changeExpanded = (toggle: boolean) => {
		setExpanded(toggle);
		if (!toggle) {
			setEditing(false);
			onExpand?.(null);
		} else {
			onExpand?.(person.id);
		}
	};

	const onStartEdit = () => {
		setEditing(true);
		setExpanded(true);
		onExpand?.(person.id);
	};

	const onStartDelete = () => {
		setDeleteDialogOpen(true);
	};

	const onSubmit = async (data: PersonResponse) => {
		// we won't need this, we will have to check if the id is -1
		if (person.id === -1) {
			const request = PersonCreateSchema.safeParse(data);
			if (request.success) {
				await createPerson(request.data);
				showSnackBar('created');
			}
			if (onStopCreating) {
				onStopCreating();
			}
		} else {
			await editPerson(data);
			showSnackBar('edited');
			setEditing(false);
		}
	};

	const renderDialog = () => {
		// leaving show extended dialog here
		// we use it when you are about to delete a person which is in use somewhere
		const onDeletePerson = async (showExtended: boolean) => {
			const idToDelete = person.id;
			if (idToDelete) {
				await deletePerson({ id: idToDelete });
				showSnackBar('deleted');
			}
		};

		// TODO: Translate
		const text = 'delete person';

		return (
			<DeleteItemDialog
				title='delete person'
				text={text}
				dialogOpen={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
				onAccept={() => onDeletePerson(false)}
			/>
		);
	};

	return (
		<ScopedComponent
			data={{ id: person.id, type: FocusedElementType.person, zIndex: 200 }}
			title={person.id !== -1 ? 'operations.edit' : 'operations.create'}
			active={canEdit && isEditing}
			translateTitle
			titleShadow
		>
			{renderDialog()}
			<ScopedLayer scopes={[FocusedElementType.address]} />
			<Grid
				container
				spacing={2}
				className={`${styles.personGrid} ${
					!isEditing ? (expanded ? styles.animateOut : '') : styles.animateIn
				}`}
				sx={{ marginBottom: '1rem' }}
			>
				<Grid item xs={!canEdit ? 12 : isEditing ? 12 : 11} container spacing={0}>
					<ScopedShadowComponent elementType={FocusedElementType.person} elementId={person.id}>
						<Accordion sx={{ width: '100%' }} expanded={expanded}>
							<AccordionSummary
								expandIcon={isEditing ? <></> : <ExpandMoreIcon />}
								onClick={() => {
									if (!isEditing) {
										changeExpanded(!expanded);
									}
								}}
								sx={{ cursor: !isEditing ? 'pointer' : 'default !important' }}
							>
								{person.name} {person.surname}
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									<Controller
										control={control}
										name={'name'}
										render={({ field }) => (
											<EditShowGridField isEditing={isEditing} width={6} label>
												<TextField
													variant='outlined'
													id='person-name'
													label={t('person.name')}
													error={!!errors.name}
													helperText={errors.name && errors.name.message}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</EditShowGridField>
										)}
									/>
									<Controller
										control={control}
										name={'surname'}
										render={({ field }) => (
											<EditShowGridField isEditing={isEditing} width={6} label>
												<TextField
													variant='outlined'
													id='person-surname'
													label={t('person.surname')}
													error={!!errors.surname}
													helperText={errors.surname && errors.surname.message}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</EditShowGridField>
										)}
									/>
									{person.id !== -1 && (
										<>
											<Grid item xs={12}>
												<JobTitlesSelector person={person} isEditing={isEditing} />
											</Grid>
											<Grid item xs={12}>
												<AddressList
													entityId={person.id}
													hideBox
													isEditing={isEditing}
													entityType={AppFunction.Person}
													onAfterDelete={() => console.log('deleted')}
												/>
											</Grid>
										</>
									)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</ScopedShadowComponent>
				</Grid>
				{canEdit && person.id !== -1 && !isEditing && (
					<Grid item xs={1}>
						<ContextualDropdownMenu onEdit={onStartEdit} onDelete={onStartDelete} />
					</Grid>
				)}
				{canEdit && isEditing && (
					<>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.save'
								colorType='success'
								translate
								icon={<Check />}
								onClick={handleSubmit(onSubmit)}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								colorType='error'
								translate
								icon={<Cancel />}
								onClick={() => {
									if (person.id === -1 && onStopCreating) {
										reset();
										onStopCreating();
									} else {
										setEditing(false);
									}
								}}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</ScopedComponent>
	);
};
