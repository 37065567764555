import { z } from 'zod';
import {
	TaskApiActionData,
	TaskFieldConfig,
	parseObjectToValues,
	parseValuesToString,
} from '../taskFieldConfig';
import {
	AffectedRowsResponse,
	OrderProductResponse,
	OrderProductsResponse,
	OrderProductsUpdateRequest,
	TaskFieldValueResponse,
	TaskFieldValuesRequest,
	TaskFieldValuesResponse,
} from 'common';
import { doSetTaskValues } from '../../../store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import { doSetOrderProductsData } from '../../../store/order';

const ModifyOrderFieldValueSchema = z.object({
	orderProductId: z.number(),
	quantity: z.number(),
	totalWeight: z.nullable(z.optional(z.number())),
	expirationDate: z.date().nullable(),
});
const ModifyOrderFieldValuesSchema = z.array(ModifyOrderFieldValueSchema);

export type ModifyOrderFieldValue = z.infer<typeof ModifyOrderFieldValueSchema>;
export type ModifyOrderFieldValues = z.infer<typeof ModifyOrderFieldValuesSchema>;

async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(ModifyOrderFieldValuesSchema, data.values);
	const setValuesRequest: TaskFieldValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject(new Error('Set task values API call failed'));
	}
}

export const modifyOrderFieldConfig: TaskFieldConfig = {
	stringToValues: (inputString: string): ModifyOrderFieldValues =>
		parseObjectToValues<ModifyOrderFieldValues>(ModifyOrderFieldValuesSchema, inputString),
	valuesToString: (values: unknown[]): string =>
		parseValuesToString(ModifyOrderFieldValuesSchema, values),
	validateStructure: (values: unknown[]): boolean => {
		const parsed = ModifyOrderFieldValuesSchema.safeParse(values);
		if (parsed.success) {
			return !parsed.data.some((value) => value.expirationDate === null);
		} else {
			return false;
		}
	},
	executeApiAction: async (data: TaskApiActionData): Promise<void> => {
		const values = ModifyOrderFieldValuesSchema.safeParse(data.values);
		if (!values.success) return Promise.reject(new Error('Invalid values'));

		const entriesRequest: OrderProductsResponse =
			data.orderProducts?.map((product: OrderProductResponse) => {
				const value = values.data.find((value) => value.orderProductId === product.id);
				/**
				 * totalWeight refers to the total weight of a variable product in grams
				 * we keep the original weight if the value is not provided
				 * This is not the weight of a single product, since when dealing with variable weight products
				 * we need to constantly store the total weight of the prodcuts that come from a single batch.
				 * When the product is picked, the weight of the picked product is subtracted from the total weight
				 * (and of course also the number of pieces is decremented by the order quantity)
				 */
				return {
					...product,
					expiresAt: value?.expirationDate ?? product.expiresAt,
					arrivedQty: value?.quantity ?? product.orderQty,
					weight: value?.totalWeight ?? product.weight,
				};
			}) ?? [];

		const setProductDataRequest: OrderProductsUpdateRequest = {
			entries: entriesRequest,
			customUpdateFields: ['arrivedQty', 'expiresAt', 'weight'],
		};

		const setProductDataResult = (await data.dispatch(
			doSetOrderProductsData(setProductDataRequest),
		)) as PayloadAction<AffectedRowsResponse>;
		if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
			return Promise.reject(new Error('Set products data API call failed'));
		}

		await saveTaskValues(data);

		return Promise.resolve();
	},
	saveStub: async (data: TaskApiActionData): Promise<void> => {
		console.log(data);
		return await saveTaskValues(data);
	},
};
