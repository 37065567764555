import { EnhancedOrderProduct } from '@contexts/orderEditorContext/types';
import useCustomers from '@hooks/useCustomers';
import useProducts from '@hooks/useProducts';
import { Box } from '@mui/material';
import { OrderDirectionType } from 'common';
import { useMemo } from 'react';

interface InventoryProductPriceProps {
	data: EnhancedOrderProduct;
	useCustomPrice?: boolean;
	direction?: OrderDirectionType;
	directionId?: number;
}

// TODO: add direction
const OrderProductPrice = ({
	data,
	useCustomPrice,
	direction,
	directionId,
}: InventoryProductPriceProps): JSX.Element | null => {
	const { variantList } = useProducts();
	const { customerList } = useCustomers();

	const value = useMemo(() => {
		return variantList.find((x) => x.id === data.variantId);
	}, [data, variantList]);

	const getPriceValue = () => {
		// TODO: we will have to differentiate customer from direct sale (to customer's client)
		const customerId = directionId ?? 0;

		let price = value?.sellPrice ?? 0;
		const customerPrice = value?.sellingPriceCustomer?.find((x) => x.customerId === customerId);

		if (customerPrice) {
			price = customerPrice.price;
		} else {
			const sellingTypePrice = value?.sellingPriceType?.find(
				(x) => x.typeId === customerList.find((x) => x.id === customerId)?.typeId,
			);
			price = sellingTypePrice ? sellingTypePrice.price : price;
		}

		return price;
	};

	const actualPrice = useCustomPrice || data.process ? data.actualPrice ?? 0 : getPriceValue();

	// if (data.unit === 'grams') {

	// }

	const compoundPrice =
		actualPrice *
		(data.process ? (data.pickedWeight ?? 0) * (data.orderQty ?? 1) : data.orderQty ?? 1);

	return (
		<Box display='flex' alignItems='center' justifyContent='center'>
			{compoundPrice.toLocaleString('en-US', { style: 'currency', currency: 'JPY' })}
		</Box>
	);
};

OrderProductPrice.displayName = 'OrderProductPrice';
export default OrderProductPrice;
