import { useEffect, useState } from 'react';
import useApp from '../../hooks/useApp';
import { FocusedElementType } from '../../store/app';
import styles from './ScopedLayer.module.css';

interface Props {
	scopes: FocusedElementType[];
}

export const ScopedLayer = ({ scopes }: Props) => {
	const { editingElements } = useApp();
	const [active, setActive] = useState(false);
	const [deactivate, setDeactivate] = useState(false);

	useEffect(() => {
		let activeState = false;
		editingElements.map((ee) => {
			if (scopes.includes(ee.type)) {
				activeState = true;
			}
		});
		if (active) {
			setDeactivate(!activeState);
		}
		setActive(activeState);
	}, [editingElements]);

	return (
		<div
			className={`${styles.overlay} ${active ? styles.active : ''} ${
				!active && deactivate ? styles.inactive : ''
			} `}
		/>
	);
};
