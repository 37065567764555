import { defaultSettings } from './defaultSettings';
import SettingsDatabase from './models/settingsDatabase';

let settingsDb: SettingsDatabase;

const doesDbExist = (dbName: string): Promise<boolean> => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName);

		let dbExists = true;

		request.onupgradeneeded = function () {
			dbExists = false;
		};

		request.onsuccess = function () {
			request.result.close();
			resolve(dbExists);
		};

		request.onerror = function () {
			reject(request.error);
		};
	});
};

export const initializeDb = async (userId: number) => {
	const exists = await doesDbExist('SettingsDatabase');

	settingsDb = new SettingsDatabase();
	if (!exists) {
		try {
			await settingsDb.settings.add(defaultSettings(userId));
			console.log(`Database created and initialized settings for userId ${userId}`);
		} catch (error) {
			console.error('Error creating database and adding settings:', error);
		}
	}

	const userDb = await settingsDb.settings.get(userId);
	if (!userDb) {
		console.log('No settings found for user, adding default settings');
		await settingsDb.settings.add(defaultSettings(userId));
	}
};

export { settingsDb };
