import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/entityGroups/GeneralInfo';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormAction } from '@contexts/formContext/types';
import useGroups from '@hooks/useGroups';
import { Settings } from '@mui/icons-material';
import { AppFunction, EntityGroupResponse, EntityGroupSchema, GroupableType } from 'common';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

const GroupFormContent = withFormLogic<EntityGroupResponse>(() => <></>);

const GroupForm = ({ forcedId, formAction }: Props) => {
	const { groupList, createGroup, editGroup } = useGroups();

	return (
		<GroupFormContent
			appFunction={AppFunction.EntityGroup}
			createFunction={createGroup}
			editFunction={editGroup}
			forcedId={forcedId}
			formAction={formAction}
			list={groupList}
			validationSchema={EntityGroupSchema}
			defaultValues={{ id: -1, type: GroupableType.customer }}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			hideMenu
			sideFabs={[FormSidefabType.Image, FormSidefabType.Info, FormSidefabType.Notes]}
			customAfterCreateLink='entityGroup/:id/edit'
		>
			{() => (
				<TabbedElement title='group.generalInfo'>
					<GeneralInfo />
				</TabbedElement>
			)}
		</GroupFormContent>
	);
};

export default GroupForm;
