import { z, ZodSchema } from 'zod';
import {
	TaskApiActionData,
	TaskFieldConfig,
	parseObjectToValues,
	parseValuesToString,
} from '../taskFieldConfig';
import { doSetTaskValues } from '@store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	AffectedRowsResponse,
	ProcessingOrderSubType,
	GenericIdRequest,
	OrderBaseResponse,
	OrderProductResponse,
	OrderProductsResponse,
	OrderProductsUpdateRequest,
	OrderType,
	RecipeType,
	TaskFieldValueResponse,
	TaskFieldValuesResponse,
	WarehouseProductCutRequest,
	WarehouseProductRequest,
	WarehouseProductSplitRequest,
	InternalOrderSubType,
	WarehouseProductCutItem,
	WarehouseProductCutItemSchema,
	WarehouseProductCutItemsSchema,
	WarehouseProductCutItems,
	WarehouseProductModifyRequest,
} from 'common';
import { doCloseOrder, doSetOrderProductsData } from '@store/order';
import {
	doCutInventoryProduct,
	doModifyInventoryProduct,
	doSplitInventoryProduct,
} from '@store/inventory';
import _ from 'lodash';

const CloseOrderFieldSchema = z.object({
	arrivedQty: z.number(),
	weight: z.number(),
	expiresAt: z.date().nullable(),
	sourceId: z.number().nullable(),
	estimated: z.boolean(),
	actualExpiration: z.optional(z.nullable(z.date())),
});

// TODO: check why we are using z.string() for variantId
const CloseOrderFieldValueSchema = z.object({
	variantId: z.number(),
	orderProductId: z.number(),
	warehouseId: z.number(),
	oldValues: CloseOrderFieldSchema,
	newValues: z.array(CloseOrderFieldSchema),
	notArrived: z.optional(z.nullable(z.boolean())),
	returnValue: z.optional(z.nullable(CloseOrderFieldSchema)),
	inspectionValue: z.optional(z.nullable(CloseOrderFieldSchema)),
});
const CloseOrderFieldValuesSchema = z.array(CloseOrderFieldValueSchema);

export type CloseOrderFieldValue = z.infer<typeof CloseOrderFieldValueSchema>;
export type CloseOrderFieldValues = z.infer<typeof CloseOrderFieldValuesSchema>;

type CustomUpdateFields = (
	| 'weight'
	| 'arrivedQty'
	| 'actualPrice'
	| 'actualCurrency'
	| 'importTaxes'
	| 'transportFees'
	| 'expiresAt'
	| 'pickedWeight'
	| 'estimated'
)[];

async function saveTaskValues<T extends ZodSchema>(
	data: TaskApiActionData,
	schema: T,
): Promise<void> {
	const stringified = parseValuesToString(schema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	console.log('set values request', setValuesRequest);
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
	return Promise.resolve();
}

/**
 * Execute the split action for the close order field
 * We split the products in the new location if there are any changes
 * - If there is more than one order product or if the order product's arrived quantity or
 * expiration date has changed we need to modify/split the products in the new location.
 * - The split products api creates a split order, fills it and closes it.
 * @param { TaskApiActionData } data - The data of the task field
 * @param { CloseOrderFieldValues } values - The values of the task field
 * @returns { Promise<void> } - A promise that resolves when the action is executed
 */
async function executeSplitApiAction(
	data: TaskApiActionData,
	values: CloseOrderFieldValues,
): Promise<void> {
	const splitProductsRequest: WarehouseProductSplitRequest[] = [];
	const modifyProductsRequest: WarehouseProductModifyRequest[] = [];
	data.orderProducts?.map((orderProduct) => {
		const newProductData = values.find((value) => Number(value.orderProductId) === orderProduct.id);
		if (!newProductData) throw new Error('No new product data found');
		if (!orderProduct.orderId) throw new Error('No product id found');
		// If the new values are the same as the old values we don't need to do anything
		if (newProductData.newValues.length === 1) {
			if (_.isEqual(newProductData.newValues[0], newProductData.oldValues)) {
				return;
			}
			modifyProductsRequest.push({
				oldProduct: {
					warehouseId: newProductData.warehouseId,
					orderId: Number(orderProduct.orderId),
					quantity: newProductData.oldValues.arrivedQty,
					expiresAt: newProductData.oldValues.expiresAt
						? new Date(newProductData.oldValues.expiresAt)
						: new Date(),
					weight: newProductData.oldValues.weight,
					variantId: Number(orderProduct.variantId),
					unit: orderProduct.unit,
					estimated: newProductData.oldValues.estimated,
					actualExpiration: newProductData.oldValues.actualExpiration ?? new Date(),
				},
				newProduct: {
					warehouseId: newProductData.warehouseId,
					orderId: Number(orderProduct.orderId),
					quantity: newProductData.newValues[0].arrivedQty,
					expiresAt: newProductData.newValues[0].expiresAt
						? new Date(newProductData.newValues[0].expiresAt)
						: new Date(),
					weight: newProductData.newValues[0].weight,
					variantId: Number(orderProduct.variantId),
					unit: orderProduct.unit,
					estimated: newProductData.newValues[0].estimated,
					actualExpiration: newProductData.newValues[0].actualExpiration ?? new Date(),
				},
				sourceOrderPrductId: orderProduct.id ? Number(orderProduct.id) : -1,
			});
		} else if (newProductData.newValues.length > 1) {
			splitProductsRequest.push({
				oldProduct: {
					warehouseId: newProductData.warehouseId,
					orderId: Number(orderProduct.orderId),
					quantity: newProductData.oldValues.arrivedQty,
					expiresAt: newProductData.oldValues.expiresAt
						? new Date(newProductData.oldValues.expiresAt)
						: new Date(),
					weight: newProductData.oldValues.weight,
					variantId: Number(orderProduct.variantId),
					unit: orderProduct.unit,
					estimated: newProductData.oldValues.estimated,
					actualExpiration: newProductData.oldValues.actualExpiration ?? new Date(),
				},
				newProducts:
					newProductData?.newValues.map((newValue) => {
						const pickedWeight =
							newValue.weight !== newProductData.oldValues.weight ? newValue.weight : undefined;
						return {
							warehouseId: newProductData.warehouseId,
							orderId: orderProduct.orderId ? Number(orderProduct.orderId) : 0,
							quantity: newValue.arrivedQty ?? 0,
							expiresAt: newValue.expiresAt ? new Date(newValue.expiresAt) : new Date(),
							weight: newValue.weight ?? 0,
							pickedWeight,
							variantId: Number(orderProduct.variantId),
							unit: orderProduct.unit,
							estimated: newValue.estimated,
							actualExpiration: newValue.actualExpiration ?? newValue.expiresAt ?? new Date(),
						};
					}) ?? [],
				// TODO: this will always be -1, let's pass with the task data too
				sourceOrderPrductId: orderProduct.id ? Number(orderProduct.id) : -1,
			});
		}
	}) ?? [];

	if (splitProductsRequest.length > 0) {
		const splitProductPromises = splitProductsRequest.map((splitProductRequest) =>
			data.dispatch(doSplitInventoryProduct(splitProductRequest)),
		);

		try {
			const results = await Promise.all(splitProductPromises);
			if (results.some((result) => result.type !== 'inventoryState/split/fulfilled')) {
				throw new Error('Split products API call failed');
			}
		} catch (error) {
			return Promise.reject(new Error('Split products API call failed'));
		}
	}

	if (modifyProductsRequest.length > 0) {
		const modifyProductPromises = modifyProductsRequest.map((modifyProductRequest) =>
			data.dispatch(doModifyInventoryProduct(modifyProductRequest)),
		);

		try {
			const results = await Promise.all(modifyProductPromises);
			if (results.some((result) => result.type !== 'inventoryState/modify/fulfilled')) {
				throw new Error('Modify products API call failed');
			}
		} catch (error) {
			return Promise.reject(new Error('Modify products API call failed'));
		}
	}
	return Promise.resolve();
}

// #region Processing[rgba(0, 255, 0, 0.04)]
function getProcessingOrderEntriesRequest(
	data: TaskApiActionData,
	values: WarehouseProductCutItems,
	recipeType: RecipeType,
	orderSubType: ProcessingOrderSubType | null,
): OrderProductsResponse {
	if (recipeType === RecipeType.division) {
		const product = data.orderProducts?.[0];
		if (!product) return [];

		const totalWeight = values.reduce((acc, value) => {
			const cutWeight = value.cutItems.reduce((acc, value) => acc + value.cutWeight, 0);
			return acc + cutWeight;
		}, 0);

		// testing only this for now
		if (
			orderSubType !== ProcessingOrderSubType.simple &&
			orderSubType !== ProcessingOrderSubType.multiple
		) {
			return [];
		}

		const entries: OrderProductsResponse = [
			{
				...product,
				weight: totalWeight,
				pickedWeight: totalWeight,
			},
		];
		return entries;
	}
	// TODO: stub, do it when dealing with processing type recipes
	return [];
}

async function executeSimpleCutApiAction(
	data: TaskApiActionData,
	warehouseId: number,
	sourceOrderProductId: number,
	orderId: number,
	values: CloseOrderFieldValues,
): Promise<void> {
	// const cutPiece: WarehouseProductCutItem = {
	// 	cutWeight: values[0].newValues[0].arrivedQty,
	// 	cutExpiresAt: values[0].newValues[0].actualExpiration ?? new Date(),
	// 	leftWeight: values[0].newValues[1].arrivedQty,
	// 	leftExpiresAt: values[0].newValues[1].actualExpiration ?? new Date(),
	// 	estimated: values[0].newValues[1].estimated,
	// 	discardedWeight: values[0].newValues[2]?.arrivedQty ?? 0,
	// };

	// const cutRequest: WarehouseProductCutRequest = {
	// 	products: [cutPiece],
	// 	recipeType: RecipeType.division,
	// 	cutType: ProcessingOrderSubType.simple,
	// 	orderId,
	// 	sourceOrderProductId,
	// };

	// console.log('cut request', cutRequest);
	return Promise.reject(new Error('Not implemented'));

	// const cutProductResult = (await data.dispatch(
	// 	doCutInventoryProduct(cutRequest),
	// )) as PayloadAction<AffectedRowsResponse>;
	// if (cutProductResult.type !== 'inventoryState/cut/fulfilled') {
	// 	console.log('didnt cut product, no task closing');
	// 	return Promise.reject(new Error('Cut product API call failed'));
	// }
}

async function executeMultipleCutApiAction(
	data: TaskApiActionData,
	warehouseId: number,
	sourceOrderProductId: number,
	orderId: number,
	values: WarehouseProductCutItems,
): Promise<void> {
	const cutRequest: WarehouseProductCutRequest = {
		products: values,
		recipeType: RecipeType.division,
		cutType: ProcessingOrderSubType.multiple,
		orderId,
		sourceOrderProductId,
	};

	const cutProductResult = (await data.dispatch(
		doCutInventoryProduct(cutRequest),
	)) as PayloadAction<AffectedRowsResponse>;
	if (cutProductResult.type !== 'inventoryState/cut/fulfilled') {
		console.log('didnt cut product, no task closing');
		return Promise.reject(new Error('Cut product API call failed'));
	}
}

async function executeCompoundcutCutApiAction(
	data: TaskApiActionData,
	warehouseId: number,
	orderId: number,
	values: CloseOrderFieldValues,
): Promise<void> {
	return Promise.reject(new Error('Not implemented'));
}

async function executeProcessingApiAction(data: TaskApiActionData): Promise<void> {
	const values = WarehouseProductCutItemsSchema.safeParse(data.values);
	if (!values.success) return Promise.reject(new Error('Invalid values'));

	// let entriesRequest: OrderProductsResponse = [];
	const recipeType = data.additionalData?.['recipeType'] as RecipeType;
	if (!recipeType) {
		return Promise.reject(new Error('No recipe type found'));
	}

	console.log(values.data);

	// const entriesRequest = getProcessingOrderEntriesRequest(
	// 	data,
	// 	values.data,
	// 	recipeType,
	// 	data.additionalData?.['orderSubType'] as ProcessingOrderSubType,
	// );

	// console.log('entries request - processing shit', entriesRequest);

	// // Ok here we should update the order product data
	// // with the new cut value and the new expiration date/s
	// const setProductData: OrderProductsUpdateRequest = {
	// 	entries: entriesRequest,
	// 	// we don't update the weight because it would affect the weight of the connected product
	// 	// we don't even need to do it since the weight here is a marker that we need to
	// 	// recognize the product, there is no other purpose in it
	// 	customUpdateFields: ['arrivedQty', 'expiresAt', 'pickedWeight', 'estimated'],
	// };

	// const setProductDataResult = (await data.dispatch(
	// 	doSetOrderProductsData(setProductData),
	// )) as PayloadAction<AffectedRowsResponse>;
	// if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
	// 	return Promise.reject(new Error('Set products data API call failed'));
	// }

	// Setting the values as they are in the task field
	await saveTaskValues(data, WarehouseProductCutItemsSchema);

	if (!data.orderProducts || data.orderProducts.length === 0 || !data.orderProducts[0].orderId)
		return Promise.reject(new Error('No order products data'));

	// // TODO: bring to its own function after it works
	if (recipeType === RecipeType.division) {
		const orderId = data.additionalData?.['orderId'] as number;
		const warehouseId = data.additionalData?.['warehouseId'] as number;
		if (!orderId) {
			return Promise.reject(new Error('No order id found'));
		}
		if (!warehouseId) {
			return Promise.reject(new Error('No warehouse id found'));
		}
		// TODO: cast it properly ffs
		const cutType = data.additionalData?.['orderSubType'] as ProcessingOrderSubType;
		if (!cutType) {
			return Promise.reject(new Error('No cut type found'));
		}

		const sourceOrderPrductId = data.additionalData?.['sourceOrderProductId'] as number | null;
		if (!sourceOrderPrductId) {
			return Promise.reject(new Error('No source order product id found'));
		}

		switch (cutType) {
			case ProcessingOrderSubType.simple:
				// await executeSimpleCutApiAction(
				// 	data,
				// 	warehouseId,
				// 	sourceOrderPrductId,
				// 	orderId,
				// 	values.data,
				// );
				break;
			case ProcessingOrderSubType.multiple:
				await executeMultipleCutApiAction(
					data,
					warehouseId,
					sourceOrderPrductId,
					orderId,
					values.data,
				);
				break;
			case ProcessingOrderSubType.compound:
				return Promise.reject(new Error('Not implemented'));
			case ProcessingOrderSubType.compoundCut:
				// await executeCompoundcutCutApiAction(data, warehouseId, orderId, values.data);
				return Promise.reject(new Error('Not implemented'));
		}
	} else {
		// TODO: later.
		return Promise.reject(new Error('Not implemented'));
	}

	const closeOrderRequest: GenericIdRequest = {
		id: data.orderProducts[0].orderId,
	};

	const closeOrderResult = (await data.dispatch(
		doCloseOrder(closeOrderRequest),
	)) as PayloadAction<AffectedRowsResponse>;

	if (closeOrderResult.type !== 'orders/close/fulfilled') {
		console.error('Close order API call failed');
		return Promise.reject(new Error('Close order API call failed'));
	}

	return Promise.resolve();
}
// #endregion

// #region Move
/**
 * Perform the move action for internal orders
 * @param {TaskApiActionData} data
 * @returns {Promise<void>}
 */
async function executeInternalApiAction(data: TaskApiActionData): Promise<void> {
	const values = CloseOrderFieldValuesSchema.safeParse(data.values);
	if (!values.success) return Promise.reject(new Error('Invalid values'));

	let hasSplit = false;

	const entriesRequest: OrderProductsResponse =
		data.orderProducts?.map((product: OrderProductResponse) => {
			const newProductData = values.data.find(
				(value) => Number(value.orderProductId) === product.id,
			);
			if (!newProductData) throw new Error('No new product data found');
			// here we define if we have a split or not
			hasSplit =
				newProductData.newValues.length > 1 ||
				!_.isEqual(newProductData.newValues[0], newProductData.oldValues);

			return {
				...product,
				arrivedQty: newProductData.oldValues.arrivedQty ?? product.arrivedQty,
				expiresAt: newProductData.oldValues.expiresAt ?? product.expiresAt,
				sourceId: newProductData.oldValues.sourceId ?? product.sourceId,
				weight: newProductData.oldValues.weight ?? product.weight,
				estimated: newProductData.oldValues.estimated ?? false,
				actualExpiration: newProductData.oldValues.actualExpiration ?? new Date(),
				unit: product.unit,
			};
		}) ?? [];

	const customUpdateFields: CustomUpdateFields = [];
	const orderSubType = data.additionalData?.['orderSubType'] as InternalOrderSubType;

	if (orderSubType) {
		switch (orderSubType) {
			case InternalOrderSubType.afterImport:
			case InternalOrderSubType.simple:
				customUpdateFields.push('arrivedQty', 'expiresAt', 'weight', 'pickedWeight', 'estimated');
				break;
			case InternalOrderSubType.afterCut:
			case InternalOrderSubType.beforeCut:
				// we do not need to change anything if we're just moving stuff to a processing room in order to cut it
				break;
		}
	}

	const setProductData: OrderProductsUpdateRequest = {
		entries: entriesRequest,
		customUpdateFields,
	};

	const setProductDataResult = (await data.dispatch(
		doSetOrderProductsData(setProductData),
	)) as PayloadAction<AffectedRowsResponse>;
	if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
		return Promise.reject(new Error('Set products data API call failed'));
	}

	// Setting the values as they are in the task field
	await saveTaskValues(data, CloseOrderFieldValuesSchema);

	if (!data.orderProducts || data.orderProducts.length === 0 || !data.orderProducts[0].orderId)
		return Promise.reject(new Error('No order products data'));

	// We close the order
	const closeOrderRequest: GenericIdRequest = {
		id: data.orderProducts[0].orderId,
	};

	const closeOrderResult = (await data.dispatch(
		doCloseOrder(closeOrderRequest),
	)) as PayloadAction<AffectedRowsResponse>;
	if (closeOrderResult.type !== 'orders/close/fulfilled') {
		console.error('Close order API call failed');
		return Promise.reject(new Error('Close order API call failed'));
	}

	// BeforeCut, AfterCut orders are not subject to splitting
	// check if there are other subOrderTypes that don't need splitting
	if (
		orderSubType === InternalOrderSubType.beforeCut ||
		orderSubType === InternalOrderSubType.afterCut
	) {
		return Promise.resolve();
	}
	// We run the split order check to see if we need to split/change the products
	if (hasSplit) {
		return await executeSplitApiAction(data, values.data);
	}
}
// #endregion

async function executeOutboundApiAction(data: TaskApiActionData): Promise<void> {
	console.log(data);

	const values = CloseOrderFieldValuesSchema.safeParse(data.values);
	if (!values.success) return Promise.reject(new Error('Invalid values'));

	let entriesRequest: OrderProductsResponse = [];
	// We first set the product data to the old values in order to succesfully close the order
	// and transfer the orderProducts from the old location to the new location
	// We don't check here if the product number or the expiration date have changed
	// because we will do that in the next step
	entriesRequest =
		data.orderProducts?.map((product: OrderProductResponse) => {
			const newProductData = values.data.find(
				(value) => Number(value.orderProductId) === product.id,
			);
			return {
				...product,
				arrivedQty: newProductData?.oldValues.arrivedQty ?? product.arrivedQty,
				expiresAt: newProductData?.oldValues.expiresAt ?? product.expiresAt,
				sourceId: newProductData?.oldValues.sourceId ?? product.sourceId,
				// TODO: this thing was done for outbound orders
				// please check if it works and relegate to its own function (in the switch statement above)
				weight:
					product.unit === 'pieces'
						? product.weight
						: newProductData?.oldValues.weight ?? product.weight,
				unit: product.unit,
				estimated: newProductData?.oldValues.estimated ?? false,
			};
		}) ?? [];

	const setProductData: OrderProductsUpdateRequest = {
		entries: entriesRequest,
		customUpdateFields: ['arrivedQty', 'expiresAt', 'weight', 'pickedWeight', 'estimated'],
	};

	const setProductDataResult = (await data.dispatch(
		doSetOrderProductsData(setProductData),
	)) as PayloadAction<AffectedRowsResponse>;
	if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
		return Promise.reject(new Error('Set products data API call failed'));
	}

	// Setting the values as they are in the task field
	await saveTaskValues(data, CloseOrderFieldValuesSchema);

	if (!data.orderProducts || data.orderProducts.length === 0 || !data.orderProducts[0].orderId)
		return Promise.reject(new Error('No order products data'));

	// We close the order
	const closeOrderRequest: GenericIdRequest = {
		id: data.orderProducts[0].orderId,
	};

	const closeOrderResult = (await data.dispatch(
		doCloseOrder(closeOrderRequest),
	)) as PayloadAction<AffectedRowsResponse>;
	if (closeOrderResult.type !== 'orders/close/fulfilled') {
		console.error('Close order API call failed');
		return Promise.reject(new Error('Close order API call failed'));
	}

	return Promise.resolve();
}

async function executeInboundApiAction(data: TaskApiActionData): Promise<void> {
	const values = CloseOrderFieldValuesSchema.safeParse(data.values);
	if (!values.success) return Promise.reject(new Error('Invalid values'));

	let entriesRequest: OrderProductsResponse = [];
	// We first set the product data to the old values in order to succesfully close the order
	// and transfer the orderProducts from the old location to the new location
	// We don't check here if the product number or the expiration date have changed
	// because we will do that in the next step
	entriesRequest =
		data.orderProducts?.map((product: OrderProductResponse) => {
			const newProductData = values.data.find(
				(value) => Number(value.orderProductId) === product.id,
			);
			return {
				...product,
				arrivedQty: newProductData?.oldValues.arrivedQty ?? product.arrivedQty,
				expiresAt: newProductData?.oldValues.expiresAt ?? product.expiresAt,
				sourceId: newProductData?.oldValues.sourceId ?? product.sourceId,
				// TODO: this thing was done for outbound orders
				// please check if it works and relegate to its own function (in the switch statement above)
				// weight:
				// 	product.unit === 'pieces'
				// 		? product.weight
				// 		: newProductData?.oldValues.weight ?? product.weight,
				weight: product.weight,
				unit: product.unit,
				estimated: newProductData?.oldValues.estimated ?? false,
			};
		}) ?? [];

	const setProductData: OrderProductsUpdateRequest = {
		entries: entriesRequest,
		customUpdateFields: ['arrivedQty', 'expiresAt', 'weight', 'pickedWeight', 'estimated'],
	};

	const setProductDataResult = (await data.dispatch(
		doSetOrderProductsData(setProductData),
	)) as PayloadAction<AffectedRowsResponse>;
	if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
		return Promise.reject(new Error('Set products data API call failed'));
	}

	// Setting the values as they are in the task field
	await saveTaskValues(data, CloseOrderFieldValuesSchema);

	if (!data.orderProducts || data.orderProducts.length === 0 || !data.orderProducts[0].orderId)
		return Promise.reject(new Error('No order products data'));

	// We close the order
	const closeOrderRequest: GenericIdRequest = {
		id: data.orderProducts[0].orderId,
	};

	const closeOrderResult = (await data.dispatch(
		doCloseOrder(closeOrderRequest),
	)) as PayloadAction<AffectedRowsResponse>;
	if (closeOrderResult.type !== 'orders/close/fulfilled') {
		console.error('Close order API call failed');
		return Promise.reject(new Error('Close order API call failed'));
	}

	return executeSplitApiAction(data, values.data);
}

export const closeOrderFieldConfig: TaskFieldConfig = {
	stringToValues: (inputString: string): CloseOrderFieldValues =>
		parseObjectToValues<CloseOrderFieldValues>(CloseOrderFieldValuesSchema, inputString),
	valuesToString: (values: unknown[]): string =>
		parseValuesToString(CloseOrderFieldValuesSchema, values),
	validateStructure: (values: unknown[], orderType?: OrderType): boolean => {
		switch (orderType) {
			case OrderType.Processing:
				return true;
		}
		// console.log('validating structure', values);
		const parsed = CloseOrderFieldValuesSchema.safeParse(values);
		if (!parsed.success) {
			return false;
		}

		// // If the order is a processing order we don't need to check for validity / we need to check in a different way (TBD)
		// // Maybe only checking that the new values are not null
		// if (orderType && orderType === OrderType.Processing) {
		// 	return true;
		// }

		for (const value of parsed.data) {
			const expiresAtSet = new Set<string>();
			for (const v of value.newValues) {
				if (v.arrivedQty === null || v.expiresAt === null) {
					return false;
				}
				const expiresAtStr = v.expiresAt.toISOString();
				if (expiresAtSet.has(expiresAtStr)) {
					return false; // Found duplicate expiresAt
				}
				expiresAtSet.add(expiresAtStr);
			}
		}

		return true;
	},
	executeApiAction: async (data: TaskApiActionData): Promise<void> => {
		switch (data.orderType) {
			case OrderType.Internal:
				return await executeInternalApiAction(data);
			case OrderType.Processing:
				return await executeProcessingApiAction(data);
			case OrderType.Outbound:
				return await executeOutboundApiAction(data);
			case OrderType.Inbound:
				return await executeInboundApiAction(data);
			default:
				return Promise.reject(
					new Error(`Order type ${data.orderType} not supported at the moment.`),
				);
		}
	},
	saveStub: async (data: TaskApiActionData): Promise<void> => {
		switch (data.orderType) {
			case OrderType.Processing:
				return await saveTaskValues(data, WarehouseProductCutItemsSchema);
			default:
				return await saveTaskValues(data, CloseOrderFieldValuesSchema);
		}
	},
	getParentProduct: (
		orderType: OrderType,
		order: OrderBaseResponse,
		content: OrderProductResponse,
	): OrderProductResponse | undefined => {
		switch (orderType) {
			case OrderType.Processing:
				return order.content?.[0];
			default:
				return order.content?.find((c) => c.id === content.sourceId);
		}
	},
};
