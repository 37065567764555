import { useMemo } from 'react';
import { useOrderEditor } from '@contexts/index';
import useCustomers from '@hooks/useCustomers';

import { Grid } from '@mui/material';
import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';

export const OutboundSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { orderType, subOrders, config, getValues } = useOrderEditor();
	const { customerList } = useCustomers();

	if (!config) return null;

	/**
	 * Fetches the current suborder from the suborders list
	 */
	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	const header = useMemo((): JSX.Element => {
		const customerId = getValues('toId');
		const customer = customerList.find((c) => c.id === customerId);
		if (!customer || !customer.customerBranches || customer.customerBranches.length <= 0)
			return <></>;

		return (
			<Grid item container spacing={2} display='flex'>
				{customer.customerBranches.map((branch) => (
					<div key={branch.id}>{branch.customerId}</div>
				))}
			</Grid>
		);
	}, [getValues('toId'), customerList]);

	return (
		<Grid item xs={12} p={'1rem 1rem 1rem 2rem'} container spacing={2} display='flex'>
			{header}
			{currentSubOrder?.toId !== -1 && orderType !== null && config.subOrderProductsContainer && (
				<config.subOrderProductsContainer
					key={`${subOrderCode}-${orderType.toLowerCase()}`}
					subOrder={currentSubOrder}
				/>
			)}
		</Grid>
	);
};
