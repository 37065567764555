import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../i18n';
import { AffectedRowsResponse, LocaleRawResponses } from 'common';
import { ThemeNames } from '../styles/themes/themes';
import {
	apiDrawerStateSettingsEdit,
	apiLanguageSettingsEdit,
	apiListSettingsEdit,
	apiSettingsList,
	apiTextSizeSettingsEdit,
} from './internalApi/controllers/settingsController';
import { NetworkOperationStatus } from '.';
import { ListSettingsRequest, ListSettingsResponse } from './internalApi/interfaces/listSettings';
import {
	DrawerStateSettingsRequest,
	DrawerStateSettingsResponse,
	LanguageSettingsRequest,
	LanguageSettingsResponse,
	TextSizeSettingsRequest,
	TextSizeSettingsResponse,
} from './internalApi/interfaces/generalSettings';

import * as api from './externalApi/common/apiCalls';

export type AvailableLangs = 'jp' | 'it' | 'en';
export const AvailableLangsArray = ['jp', 'it', 'en'] as AvailableLangs[];

export interface Name {
	translatableId: number;
	translatableType: string;
	type: string;
	langCode: AvailableLangs;
	value: string;
}

/**
 * `LayerData` interface represents information associated with a layer used to obscure a part of the document
 *  when editing/creating an entry.
 *
 * @export
 * @interface LayerData
 * @property {string} layerId - A unique identifier for the layer. It helps tracking or manipulating the specific layer in the system.
 * @property {FocusedElementType[]} scopes - An array of `FocusedElementType` that defines the focus or scope within the layer.
 */
export interface LayerData {
	layerId: string;
	scopes: FocusedElementType[];
}

export enum FocusedElementType {
	person = 'person',
	address = 'address',
	invoicingtime = 'invoicingtime',
	billing = 'billing',
	branch = 'branch',
}

export interface FocusedElementData {
	id: number;
	type: FocusedElementType;
	zIndex: number;
}

/**
 * `SnackBarMessage` interface represents information transmitted to the [SnackBarHandler](../components/feedbacks/SnackBarHandler.tsx)
 *
 * @exports
 * @interface SnackBarMessage
 * @property {string} message - Text of the message.
 * @property {('error' | 'warning' | 'info' | 'success')} [severity] - The severity of the message, which can be one of the following:
 *    - 'error': An error message.
 *    - 'warning': A warning message.
 *    - 'info': An informational message.
 *    - 'success': A success message.
 * @property {number} [autoHideDuration] - The number of milliseconds before the snack bar automatically hides itself. If not specified, a default value will be used.
 */
export interface SnackBarMessage {
	message: string;
	severity?: 'error' | 'warning' | 'info' | 'success';
	autoHideDuration?: number;
}

/**
 * `DeletionDialogSettings` interface represents information transmitted
 * to the [DeletionDialog](../components/feedbacks/DeletionDialog.tsx)
 * when a deletion is requested.
 * @exports
 * @interface DeletionDialogSettings
 * @property {string} [dialogId] - A unique identifier for the dialog. It helps tracking or manipulating the specific dialog in the system.
 * @property {string} message - Text of the message.
 * @property {() => Promise<AffectedRowsResponse>} [onConfirm] - A callback function that will be executed when the user confirms the deletion.
 * @property {() => void} [onCancel] - A callback function that will be executed when the user cancels the deletion.
 * @property {string} [confirmButtonText] - Text of the confirm button.
 * @property {string} [cancelButtonText] - Text of the cancel button.
 */
export interface DeletionDialogSettings {
	dialogId?: string;
	title: string;
	message: string;
	onConfirm?: () => Promise<AffectedRowsResponse>;
	onCancel?: () => void;
	confirmButtonText?: string;
	cancelButtonText?: string;
}

// #Region Settings
// TODO: we will likely move these settings to the common module
// 		 for now let's leave them here
export interface ListSettings {
	columns: Record<string, boolean>;
	showFilters: boolean;
	expanded: boolean;
	rowsPerPage: number;
}

export interface Settings {
	userId: number;
	listSettings: Record<string, ListSettings>;
	showDrawer: boolean;
	textSize: number;
	// TODO: we need to get these from the backend somehow
	language: 'jp' | 'it' | 'en';
}
// #region

export interface AppState {
	opStatus: NetworkOperationStatus;
	theme: ThemeNames;
	notifications: string[];
	messages: SnackBarMessage[];
	deletionDialog: DeletionDialogSettings | null;
	nextDeleteDialogId: string | null;
	focusedElements: FocusedElementData[];
	layers: Record<string, FocusedElementType[]>;
	settings: Settings | null;
}

const initialState: AppState = {
	opStatus: 'idle',
	theme: ThemeNames.light,
	notifications: [],
	messages: [],
	deletionDialog: null,
	nextDeleteDialogId: null,
	focusedElements: [],
	layers: {},
	settings: null,
};

export const doFetchLocale = api.apiList<LocaleRawResponses>('locale/list', 'strings');

export const doFetchSettings = createAsyncThunk(
	'settings/list',
	async (userId: number): Promise<Settings> => {
		return await apiSettingsList(userId);
	},
);

export const doEditListSettings = createAsyncThunk(
	'settings/list/edit',
	async (params: ListSettingsRequest): Promise<ListSettingsResponse> => {
		return await apiListSettingsEdit(params);
	},
);

export const doEditLanguageSettings = createAsyncThunk(
	'settings/language/edit',
	async (params: LanguageSettingsRequest): Promise<LanguageSettingsResponse> => {
		return await apiLanguageSettingsEdit(params);
	},
);

export const doEditTextSizeSettings = createAsyncThunk(
	'settings/textSize/edit',
	async (params: TextSizeSettingsRequest): Promise<TextSizeSettingsResponse> => {
		return await apiTextSizeSettingsEdit(params);
	},
);

export const doEditDrawerStateSettings = createAsyncThunk(
	'settings/drawerState/edit',
	async (params: DrawerStateSettingsRequest): Promise<DrawerStateSettingsResponse> => {
		return await apiDrawerStateSettingsEdit(params);
	},
);

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		appendNotification: (state, action: PayloadAction<string>) => {
			state.notifications.push(action.payload);
		},
		pushSnackbar: (state, action: PayloadAction<string | SnackBarMessage>) => {
			if (typeof action.payload === 'string') {
				state.messages.push({ message: action.payload });
			} else if ('message' in action.payload) {
				state.messages.push(action.payload);
			}
		},
		popSnackbar: (state) => {
			state.messages.pop();
		},
		pushDeletionDialog: (state, action: PayloadAction<DeletionDialogSettings>) => {
			if (action.payload.dialogId !== undefined) {
				state.deletionDialog = action.payload;
			}
		},
		popDeletionDialog: (state, action: PayloadAction<boolean>) => {
			if (action.payload) {
				state.nextDeleteDialogId = state.deletionDialog?.dialogId ?? null;
			}
			state.deletionDialog = null;
		},
		clearNextDeletionDialogId: (state) => {
			state.nextDeleteDialogId = null;
		},
		pushFocusedElement: (state, action: PayloadAction<FocusedElementData>) => {
			state.focusedElements.push(action.payload);
		},
		removeFocusedElement: (state, action: PayloadAction<FocusedElementData>) => {
			const newState = state.focusedElements.filter(
				(ee) => ee.id !== action.payload.id && ee.type !== action.payload.type,
			);
			state.focusedElements = newState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(doFetchLocale.fulfilled, (_, action) => {
			// i18n doesn't provide a method to override the whole
			// bundle, so let's stick with this unless we find something better.
			AvailableLangsArray.forEach((locale) => {
				i18n.removeResourceBundle(locale, 'locale');
			});

			action.payload.forEach((item) => {
				i18n.addResources(item.langCode, 'locale', {
					[item.translatableType + '-' + item.translatableId + '-' + item.type]: item.value,
				});
			});
		});
		builder.addCase(doFetchSettings.fulfilled, (state, action) => {
			state.settings = action.payload;
			state.opStatus = 'succeeded';
		});
		builder.addCase(doFetchSettings.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doFetchSettings.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doEditListSettings.fulfilled, (state, action) => {
			if (state.settings) {
				const newFunctionName = `${action.payload.functionName}${
					action.payload.tableVariant ? '_' + action.payload.tableVariant : ''
				}`;
				state.settings.listSettings[newFunctionName] = {
					columns: action.payload.updatedListSettings ?? {},
					showFilters: action.payload.showFilters ?? true,
					expanded: action.payload.expanded ?? false,
					rowsPerPage: action.payload.rowsPerPage ?? 10,
				};
			}
			state.opStatus = 'succeeded';
		});
		builder.addCase(doEditListSettings.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doEditListSettings.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doEditLanguageSettings.fulfilled, (state, action) => {
			if (state.settings) {
				state.settings.language = action.payload.language ?? 'jp';
			}
			state.opStatus = 'succeeded';
		});
		builder.addCase(doEditLanguageSettings.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doEditLanguageSettings.rejected, (state) => {
			state.opStatus = 'failed';
		});
	},
});

export const {
	appendNotification,
	pushSnackbar,
	popSnackbar,
	pushDeletionDialog,
	popDeletionDialog,
	clearNextDeletionDialogId,
	pushFocusedElement,
	removeFocusedElement,
} = appSlice.actions;
export default appSlice.reducer;
