import {
	Avatar,
	Grid,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import useOrders from '../../hooks/useOrders';
import {
	AirplanemodeActive,
	ArrowRightAlt,
	Storage,
	SupervisedUserCircleOutlined,
	Waves,
} from '@mui/icons-material';
import { Fragment, ReactNode, useRef, useState } from 'react';
import {
	AppFunction,
	OrderDirectionType,
	OrderTemplateResponse,
	OrderType,
	TranslationTypes,
} from 'common';
import useWarehouses from '../../hooks/useWarehouses';
import useLocale from '../../hooks/useLocale';
import { useLayout } from '@contexts/index';

interface Props {
	orderType: OrderType | null;
	loadTemplate: (templateId: string | number | undefined) => void;
}

export const OrderLeftMenu = ({ orderType, loadTemplate }: Props) => {
	const { templateList } = useOrders();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();

	const [hovering, setHovering] = useState<string | number | undefined>(undefined);
	const [lastYPos, setLastYPos] = useState<number>(0);

	const hovered = useRef<HTMLElement | null>(null);

	const toIcon = (toType: OrderDirectionType | undefined, toId: number | undefined): ReactNode => {
		switch (toType) {
			case 'warehouse': {
				let icon = <></>;
				switch (warehouseList.find((w) => w.id === toId)?.storageType) {
					case 'port':
						icon = <Waves />;
						break;
					case 'airport':
						icon = <AirplanemodeActive />;
						break;
					case 'subStorage':
						icon = <Storage />;
						break;
					case 'main':
						icon = <Storage />;
						break;
					default:
						icon = <></>;
				}
				return icon;
			}
			case 'merchant':
				return <AirplanemodeActive />;
			case 'customer':
				return <SupervisedUserCircleOutlined />;
			default:
				return <></>;
		}
	};

	const simpleTemplate = (template: OrderTemplateResponse): ReactNode => {
		if (template) {
			return (
				<Grid container spacing={1} sx={{ padding: 2 }}>
					<Grid item xs={12}>
						{template.name}
					</Grid>
					{template.content?.map((c, it) => (
						<Grid item key={it} xs={12}>
							<Typography variant='body2'>
								{getTranslatedString(AppFunction.Variant, c.variantId, TranslationTypes.name)} x{' '}
								{c.orderQty}
							</Typography>
						</Grid>
					))}
				</Grid>
			);
		}
		return <></>;
	};

	const getTemplateDetails = (template: OrderTemplateResponse): ReactNode => {
		const subOrderIcons = template.children?.map((ch, it) => {
			return <Fragment key={it}>{toIcon(ch.toType, ch.toId)}</Fragment>;
		});

		return (
			<>
				<ArrowRightAlt /> {subOrderIcons}
			</>
		);
	};

	const filteredTemplates = templateList.filter((t) => t.orderType === orderType);

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'absolute',
					top: lastYPos,
					right: '-320px',
					width: '300px',
					minHeight: hovering !== undefined ? '40px' : '0px',
					backgroundColor: 'white',
					borderRadius: '10px',
					transition:
						'right 0.3s ease-in-out, opacity 0.5s ease-in-out, min-height 0.3s ease-in-out, top 0.2s ease-in-out',
					opacity: hovering !== undefined ? 1 : 0,
					zIndex: 9,
					margin: '0 0 0 2rem',
					boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
				}}
			>
				{simpleTemplate(templateList.find((t) => t.id === hovering) as OrderTemplateResponse)}
			</div>
			<List dense sx={{ zIndex: 10 }}>
				{filteredTemplates.map((t) => (
					<ListItemButton
						key={t.id}
						onMouseEnter={(e) => {
							// TODO: remember to dinamically get the header height instead of hardcoding 64
							setLastYPos(e.currentTarget.getBoundingClientRect().top - 64);
							setHovering(t.id);
							hovered.current = e.currentTarget;
						}}
						onMouseLeave={() => {
							setHovering(undefined);
							hovered.current = null;
						}}
						onClick={() => {
							loadTemplate(t.id);
						}}
					>
						<ListItemAvatar>
							<Avatar>{toIcon(t.toType, t.toId)}</Avatar>
						</ListItemAvatar>
						<ListItemText primary={t.name} secondary={getTemplateDetails(t)} />
					</ListItemButton>
				))}
			</List>
		</div>
	);
};
