import useOrders from '@hooks/useOrders';

import { config } from './config';
import { useTask } from '@contexts/index';

interface Props {
	taskFieldId: number;
}

export const ModifyOrderField = ({ taskFieldId }: Props) => {
	const { targetId } = useTask();
	const { orderList } = useOrders();

	const order = orderList.find((order) => order.id === targetId);
	if (!order || !order.orderType) {
		return null;
	}

	const orderConfig = config[order.orderType];
	if (!orderConfig) {
		return null;
	}

	return (
		<div style={{ padding: '1rem' }}>
			<orderConfig.containerComponent taskFieldId={taskFieldId} />
		</div>
	);
};
