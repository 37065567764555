import { CommissionThreshold } from '@components/forms/shared/CommissionThreshold';
import { EditShowGridField } from '@components/common/EditShowGridField';
import { CustomerBillings } from '@components/customers/CustomerBillings';
import { ScopedLayer } from '@components/scopedlayer/ScopedLayer';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { useCustomerForm } from '@contexts/formContext/subContexts/CustomerFormContext';
import {
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@mui/material';
import { CustomerResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FocusedElementType } from 'store/app';
import { useAppSelector } from 'store/hooks';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const PaymentInfo = () => {
	const { entityId, control, formValues, watch, formAction } = useGenericForm<CustomerResponse>();
	const { cachedParent } = useCustomerForm();
	const { t } = useTranslation();
	// TODO: delete this later
	const currencies = useAppSelector((state) => state.products.currencies);

	const separateData = watch('separateData');

	const editDisabled = formAction === 'view';

	const renderBillings = () => {
		const billings =
			cachedParent && !separateData ? cachedParent.customerBillings : formValues?.customerBillings;
		return (
			// eslint-disable-next-line react/jsx-no-undef
			<CustomerBillings
				customerId={entityId}
				billings={billings ?? []}
				view={cachedParent && !separateData ? true : editDisabled}
			/>
		);
	};

	return (
		<>
			<ScopedLayer scopes={[FocusedElementType.billing]} />
			<TabInnerTitle title='customer.payments' translate />
			<Grid container spacing={2}>
				<Controller
					name='creditLineLimit'
					control={control}
					render={({ field }) => (
						<InputGridField width={10} type={ComponentType.TextField}>
							<TextField
								variant='outlined'
								type='number'
								id='creditLineLimit'
								fullWidth
								size='small'
								label={t('customer.creditLineLimit')}
								disabled={!(cachedParent && !separateData ? false : !editDisabled)}
								inputProps={{
									min: 0,
								}}
								value={field.value ?? 0}
								onChange={(ev) => field.onChange(ev.target.value ? +ev.target.value : 0)}
							/>
						</InputGridField>
					)}
				/>
				<Controller
					name='creditLineCurrencyId'
					control={control}
					render={({ field }) => (
						<InputGridField
							width={2}
							muiLabel={{
								label: t('customer.creditLineCurrency'),
								labelId: 'credit-line-currency',
							}}
							type={ComponentType.Select}
						>
							<Select
								labelId='credit-line-currency'
								id='creditLineCurrency'
								label={t('customer.creditLineCurrency')}
								fullWidth
								size='small'
								value={field.value ?? 1}
								disabled={!(cachedParent && !separateData ? false : !editDisabled)}
								onChange={(event) => field.onChange(event.target.value ? +event.target.value : 0)}
							>
								{currencies.length > 0 &&
									currencies.map((currency) => (
										<MenuItem key={currency.id} value={currency.id}>
											{t(`currency.${currency.alphabeticCode}`)}
										</MenuItem>
									))}
							</Select>
						</InputGridField>
					)}
				/>
				<Controller
					name='commissionThreshold'
					control={control}
					render={({ field }) => (
						<CommissionThreshold
							value={field.value}
							onChange={(value) => field.onChange(value)}
							view={cachedParent && !separateData ? true : editDisabled}
						/>
					)}
				/>
				<Controller
					name='prebanking'
					control={control}
					render={({ field }) => (
						<EditShowGridField
							width={12}
							isEditing={cachedParent && !separateData ? false : !editDisabled}
							label
						>
							<FormLabel
								id='demo-radio-buttons-group-label'
								style={{
									paddingLeft: '14px',
									fontSize: '0.72em',
									transform: 'translate(0px, -4px)',
									color: 'rgba(0, 0, 0, 0.6)',
									position: 'absolute',
									top: '-7px',
								}}
							>
								{t('customer.commission.prebankingTitle')}
							</FormLabel>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								row
								name='radio-buttons-group'
								onChange={(ev) => field.onChange(ev.target.value === 'pre' ? true : false)}
								sx={{ display: 'flex', alignItems: 'center' }}
								value={field.value === true ? 'pre' : 'post'}
							>
								<FormControlLabel
									value='pre'
									control={<Radio />}
									label={t('customer.commission.prebanking')}
								/>
								<FormControlLabel
									value='post'
									control={<Radio />}
									label={t('customer.commission.postbanking')}
								/>
							</RadioGroup>
						</EditShowGridField>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TabInnerTitle title='customer.bankAccount' translate />
			</Grid>
			{renderBillings()}
		</>
	);
};
