import { Settings } from '@mui/icons-material';
import { TableContextProvider, useFeedbacks, useLayout } from '@contexts/index';
import useTasks from '@hooks/useTasks';

import { Column } from 'primereact/column';
import { AppFunction, GenericIdRequest, TaskFieldArchetypeResponse } from 'common';

import { useEffect } from 'react';
import useAppFunctions from '@hooks/useAppFunctions';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { DropDownActionType } from '@contexts/tableContext/types';
import { TableBoolBody } from '@components/common/tablecells/TableBoolBody';
import useTaskFieldParameters from '@hooks/useTaskFieldParameters';

const appFunction = AppFunction.TaskFieldArchetype;

export const TaskFieldArchetypesList = () => {
	const { taskFieldArchetypeList, deleteTaskFieldArchetype } = useTasks();
	const { taskFieldParametersList } = useTaskFieldParameters();

	const { t } = useTranslation();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	// TODO: add type name translations
	const typeBodyTemplate = (rowData: TaskFieldArchetypeResponse) => {
		const icon = getIcon(AppFunction.TaskFieldArchetype, rowData.type) ?? <></>;
		return (
			<Chip
				label={t(`${AppFunction.TaskFieldArchetype}.types.${rowData.type}`)}
				size='small'
				icon={icon}
			/>
		);
	};

	const paramBodyTemplate = (rowData: TaskFieldArchetypeResponse) => {
		const params = taskFieldParametersList.find((param) => param.id === rowData.paramId)?.params
			.length;
		return <span>{params}</span>;
	};

	function callDeleteTaskFieldArchetypeDialog(data: GenericIdRequest) {
		const taskFieldArchetypeName = taskFieldArchetypeList.find(
			(taskField) => taskField.id === data.id,
		)?.name;
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('dialog.confirmDelete', { deleting: taskFieldArchetypeName }),
			actions: [
				{
					label: t('dialog.cancel'),
					action: () => void 0,
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteTaskFieldArchetype(data).then((response) => {
							if (response) {
								console.log('deleted');
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={taskFieldArchetypeList}
			pageIdentifier={appFunction}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: callDeleteTaskFieldArchetypeDialog,
				},
			]}
		>
			<Column field='name' header='name' sortable />
			<Column field='typeId' header='type' body={typeBodyTemplate} />
			<Column field='paramId' header='parameters' body={paramBodyTemplate} />
			<Column
				field='optional'
				header='optional'
				body={(rowData) => <TableBoolBody value={rowData.optional} />}
			/>
		</TableContextProvider>
	);
};
