import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import { Grid, MenuItem, Select, TextField, ToggleButton } from '@mui/material';
import { AppFunction, TaskFieldArchetypeResponse, TaskFieldType } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropDownParameters } from './DropDownParameters';

export const GeneralInfo = () => {
	const { control, formAction } = useGenericForm<TaskFieldArchetypeResponse>();
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='taskFieldArchetype.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='optional'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.ToggleButton}>
						<ToggleButton
							value='check'
							color='success'
							disabled={formAction === 'view'}
							selected={field.value ?? false}
							onChange={() => field.onChange(!field.value)}
							size='small'
							fullWidth
						>
							{field.value
								? t(`${AppFunction.TaskFieldArchetype}.optional`)
								: t(`${AppFunction.TaskFieldArchetype}.notOptional`)}
						</ToggleButton>
					</InputGridField>
				)}
			/>
			<Controller
				name='type'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'taskFieldArchetype-type',
							label: t('taskFieldArchetype.type'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='taskFieldArchetype-type'
							id='taskFieldArchetype'
							value={field.value ?? -1}
							label={t('taskFieldArchetype.type')}
							disabled={formAction === 'view'}
							size='small'
							fullWidth
							onChange={(event) => field.onChange(event.target.value as TaskFieldType)}
						>
							<MenuItem value='-1' key={-1}>
								{t('common.none')}
							</MenuItem>
							{(Object.keys(TaskFieldType) as (keyof typeof TaskFieldType)[]).map((key, index) => {
								return (
									<MenuItem value={TaskFieldType[key]} key={index}>
										{t(`${AppFunction.TaskFieldArchetype}.types.${TaskFieldType[key]}`)}
									</MenuItem>
								);
							})}
						</Select>
					</InputGridField>
				)}
			/>
			<Controller
				name='name'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.TextField}>
						<TextField
							id='taskFieldArchetype-name'
							type='text'
							size='small'
							fullWidth
							label={t('taskFieldArchetype.name')}
							placeholder={t('taskFieldArchetype.name')}
							className='input'
							disabled={formAction === 'view'}
							value={field.value ?? ''}
							onChange={field.onChange}
						/>
					</InputGridField>
				)}
			/>
			<DropDownParameters />
		</Grid>
	);
};
