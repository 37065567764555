import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { QuickTaskFieldParameterDialog } from '@components/dialogs/taskFieldParameter/QuickTaskFieldParameterDialog';
import { TranslatableAutocompleteWithCallback } from '@components/TranslatableAutocompleteWithCallback';
import { useFeedbacks, useGenericForm } from '@contexts/index';
import useTaskFieldParameters from '@hooks/useTaskFieldParameters';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AppFunction, TaskFieldArchetypeResponse, TranslationTypes } from 'common';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DropDownParameters = () => {
	const { getValues, setValue, control } = useGenericForm<TaskFieldArchetypeResponse>();
	const { pushComponentDialog } = useFeedbacks();
	const { taskFieldParametersList } = useTaskFieldParameters();
	const { t } = useTranslation();

	const [parameters, setParameters] = useState<string[]>([]);

	function onCreateNewParameters(newName: string) {
		pushComponentDialog({
			title: t('operations.createItem', { item: t('taskFieldArchetype.parameter_other') }),
			component: QuickTaskFieldParameterDialog,
			data: {
				newName,
			},
			minWidth: 80,
			type: 'component',
		}).then(async (result) => {
			if (result !== false) {
				// TODO: akshually we should parse the result to get the id
				if (result.id && typeof result.id === 'number') {
					setValue('paramId', result.id);
				}
			}
		});
	}

	function onEditParameters(editId: number) {
		pushComponentDialog({
			title: t('operations.editItem', { item: t('taskFieldArchetype.parameter_other') }),
			component: QuickTaskFieldParameterDialog,
			data: {
				editId,
			},
			minWidth: 80,
			type: 'component',
		}).then(async (result) => {
			if (result !== false) {
				console.log('yo');
			}
		});
	}

	useEffect(() => {
		if (getValues('paramId') === null) {
			setParameters([]);
			return;
		}

		const param = taskFieldParametersList.find((param) => param.id === getValues('paramId'));
		if (param) {
			if (typeof param.params !== 'string') {
				setParameters(param.params);
			}
		}
	}, [getValues('paramId'), taskFieldParametersList]);

	if (getValues('type') !== 'dropdown') {
		return null;
	}

	return (
		<Grid item xs={12} container gap={1}>
			<Controller
				name='paramId'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.Select}>
						<TranslatableAutocompleteWithCallback
							id='taskFieldArchetype-paramId-autocomplete'
							data={taskFieldParametersList}
							value={field.value ?? -1}
							label={t('taskFieldArchetype.parameter_other')}
							size='small'
							translationSettings={{
								translatableType: AppFunction.TaskFieldParameter,
								translationType: TranslationTypes.name,
								namespace: 'strings',
							}}
							onChange={(newValue) => {
								if (newValue === -1) {
									field.onChange(null);
								} else {
									field.onChange(newValue);
								}
							}}
							onCreateNew={(newEntry) => onCreateNewParameters(newEntry)}
							onEdit={(value) => onEditParameters(value)}
						/>
					</InputGridField>
				)}
			/>
			{parameters.length > 0 && (
				<Grid item xs={12}>
					<List
						sx={{
							backgroundColor: 'grey.100',
							borderRadius: '5px',
						}}
					>
						{parameters.map((param, index) => (
							<ListItem key={index}>
								<ListItemAvatar>
									<Avatar
										sx={{
											width: '1.2rem',
											height: '1.2rem',
											fontSize: '1rem',
										}}
									>
										{index + 1}
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={param} />
							</ListItem>
						))}
					</List>
				</Grid>
			)}
		</Grid>
	);
};
