import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocale from '../hooks/useLocale';
import _ from 'lodash';

import { GenericIdRequest, TranslatableAppFunction, TranslationTypes } from 'common';

import { Autocomplete, Button, MenuItem, SxProps, TextField, Theme } from '@mui/material';
import { AutocompleteValue } from './common/EditShowGridField';

import styles from './TranslatableAutocompleteWithCallback.module.css';

interface TranslationSettings {
	translatableType: TranslatableAppFunction;
	translationType: TranslationTypes;
	namespace: string;
}

interface Props<T extends GenericIdRequest> {
	data: T[];
	value: number;
	translationSettings: TranslationSettings;
	id: string;
	label?: string;
	error?: boolean;
	disabled?: boolean;
	size?: 'small' | 'medium';
	selectedSx?: SxProps<Theme>;
	onChange: (newIndex: number) => void;
	onCreateNew: (newName: string) => void;
	onEdit?: (index: number) => void;
}

// TODO: make it compatible with InputGridField
export const TranslatableAutocompleteWithCallback = <T extends GenericIdRequest>({
	data,
	value,
	translationSettings,
	id,
	label,
	error,
	disabled,
	size,
	selectedSx,
	onChange,
	onCreateNew,
	onEdit,
}: Props<T>) => {
	const { t } = useTranslation();
	const { getTranslatedString, renderI18NField, getI18NString, getCurrentLanguage } = useLocale();
	const [newEntry, setNewEntry] = useState('');
	const [isNewEntry, setIsNewEntry] = useState(false);
	const [options, setOptions] = useState<AutocompleteValue[]>([]);

	const translateLabel = (id: number | string) => {
		return t(
			`${translationSettings.translatableType}-${id}-${translationSettings.translationType}`,
			{ ns: translationSettings.namespace, default: ' ' },
		);
	};

	useEffect(() => {
		const newData: AutocompleteValue[] = [];
		data.map((d) => {
			newData.push({
				id: +d.id,
				label: translateLabel(d.id),
			});
		});
		setOptions((oldOptions) => {
			if (!_.isEqual(newData, oldOptions)) {
				return newData;
			} else {
				return oldOptions;
			}
		});
	}, [data, getCurrentLanguage()]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			const target = event.target as HTMLInputElement;
			const match = options.find((op) => op.label.toLowerCase() === target.value.toLowerCase());
			if (match) {
				onChange(match.id);
			} else if (target.value !== '') {
				setNewEntry('');
				setIsNewEntry(false);
				onCreateNew(target.value);
			}
		} else if (event.key === 'Escape') {
			onChange(-1);
		}
	};

	// FIX: switch beteween onCreate and onEdit state is not pleasing to the eye, fix when you have time
	return (
		<Autocomplete
			id={`translatable-autocomplete-${id}`}
			freeSolo
			options={options}
			size={size}
			getOptionLabel={(option) => {
				if (typeof option === 'object' && option !== null) {
					if (option.id !== -1) {
						return getTranslatedString(
							translationSettings.translatableType,
							option.id,
							translationSettings.translationType,
						);
					} else {
						return newEntry;
					}
				} else {
					return option;
				}
			}}
			onKeyDown={handleKeyDown}
			isOptionEqualToValue={(option, value) => option?.id === value?.id}
			renderOption={(props, option, { selected }) => (
				<MenuItem
					{...props}
					key={`${option.id}-${option.label}`}
					sx={{
						...(selected && selectedSx),
					}}
				>
					{renderI18NField(
						getI18NString(
							translationSettings.translatableType,
							option.id,
							translationSettings.translationType,
						),
					)}
				</MenuItem>
			)}
			value={{
				id: value,
				label: value !== -1 ? translateLabel(value) : 'none',
			}}
			onChange={(_, newValue) => {
				if (typeof newValue === 'object') {
					setNewEntry('');
					setIsNewEntry(false);
					onChange(newValue ? +newValue.id : -1);
				}
			}}
			onInputChange={(_, newValue, type) => {
				if (type === 'input') {
					const match = options.find((op) => op.label.toLowerCase() === newValue.toLowerCase());
					if (!match && newValue !== '') {
						onChange(-1);
						setIsNewEntry(true);
						setNewEntry(newValue);
					} else {
						setIsNewEntry(false);
						setNewEntry(newValue);
					}
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label ?? ''}
					variant='outlined'
					size='small'
					error={error}
					disabled={disabled}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<div style={{ overflow: 'hidden' }}>
								{params.InputProps.endAdornment}
								<Button
									variant='contained'
									className={isNewEntry ? styles.visibleButton : styles.hiddenButton}
									size='small'
									// disabled={!isNewEntry}
									onClick={() => {
										setNewEntry('');
										setIsNewEntry(false);
										onCreateNew(newEntry);
									}}
								>
									{t('operations.createNew')}
								</Button>
								{onEdit && value !== -1 && value !== null && (
									<Button
										variant='contained'
										className={!isNewEntry ? styles.visibleButton : styles.hiddenButton}
										size='small'
										// disabled={!isNewEntry}
										onClick={() => {
											onEdit(value);
										}}
									>
										{t('operations.edit')}
									</Button>
								)}
							</div>
						),
					}}
				/>
			)}
			// sx={{ marginTop: '10px', marginBottom: '10px' }}
		/>
	);
};
