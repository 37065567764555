import { WarehouseChip } from '@components/WarehouseChip';
import { useOrderEditor } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { ArrowRight } from '@mui/icons-material';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import {
	AppFunction,
	ShippingType,
	StorageType,
	TranslationTypes,
	WarehouseResponse,
} from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const InternalOrderHeader = () => {
	const { control, getValues, renderType } = useOrderEditor();
	const theme = useTheme();
	const { warehouseList, getWarehouseIdPath } = useWarehouses();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();

	const getWarehouseItem = (value: WarehouseResponse | undefined) => {
		if (!value) return <>{t(`${AppFunction.Order}.from.undefined`)}</>;
		const path = getWarehouseIdPath(value.id);
		if (path.length > 0) {
			path.pop();
		}
		const namePath = path.map((id) =>
			getTranslatedString(AppFunction.Location, id, TranslationTypes.name),
		);
		return (
			<>
				<WarehouseChip data={value} />
				{namePath.length > 0 && (
					<Box
						sx={{
							display: 'flex',
							gap: 1,
							backgroundColor: theme.palette.info.main,
							color: theme.palette.info.contrastText,
							borderRadius: '1rem',
							padding: '0 0.5rem 0 0.5rem',
						}}
					>
						{namePath.map((name, it) => (
							<span
								key={it}
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 1,
								}}
							>
								{name}
								<ArrowRight />
							</span>
						))}
					</Box>
				)}
				{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
			</>
		);
	};

	const fromList = () => {
		return warehouseList.map((value, it) => (
			<MenuItem
				key={it}
				value={value.id}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 1,
				}}
			>
				{getWarehouseItem(value)}
			</MenuItem>
		));
	};

	const shippingList = () => {
		const fromId = getValues('fromId');
		if (fromId) {
			const from = warehouseList.find((w) => w.id === fromId);
			if (from) {
				switch (from.storageType) {
					case StorageType.office:
					case StorageType.subStorage:
					case StorageType.trash:
					case StorageType.processing:
						return [
							ShippingType.notdefined,
							ShippingType.handDelivered,
							ShippingType.internalCarrier,
							ShippingType.externalCarrier,
						];
					case StorageType.store:
					case StorageType.external:
						return [
							ShippingType.notdefined,
							ShippingType.internalCarrier,
							ShippingType.externalCarrier,
						];
					default:
						return [];
				}
			}
		}
		return [
			ShippingType.notdefined,
			ShippingType.externalCarrier,
			ShippingType.internalCarrier,
			ShippingType.handDelivered,
		];
	};

	const toList = () => {
		const shippingType = getValues('shippingType');
		switch (shippingType) {
			case ShippingType.handDelivered:
				return warehouseList
					.filter(
						(w) =>
							w.storageType === StorageType.main ||
							w.storageType === StorageType.office ||
							w.storageType === StorageType.subStorage ||
							w.storageType === StorageType.trash ||
							w.storageType === StorageType.processing,
					)
					.map((value, it) => (
						<MenuItem
							key={it}
							value={value.id}
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 1,
							}}
						>
							{getWarehouseItem(value)}
						</MenuItem>
					));
			case ShippingType.internalCarrier:
			case ShippingType.externalCarrier:
				return warehouseList
					.filter(
						(w) =>
							w.storageType === StorageType.main ||
							w.storageType === StorageType.store ||
							w.storageType === StorageType.external,
					)
					.map((value, it) => (
						<MenuItem
							key={it}
							value={value.id}
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 1,
							}}
						>
							{getWarehouseItem(value)}
						</MenuItem>
					));
			default:
				return [];
		}
	};

	return (
		<Grid
			item
			container
			columnSpacing={2}
			rowGap={2}
			display='flex'
			alignItems='center'
			justifyContent='center'
		>
			<Grid item flexGrow={1} flexBasis={0}>
				<Controller
					name='fromId'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='from'>{t('order.from.warehouse')}</InputLabel>
							<Select
								labelId='from'
								id='from'
								size='small'
								label={t('order.from.warehouse')}
								value={field.value ?? -1}
								fullWidth
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? undefined : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
								renderValue={(value) => (
									<Box
										sx={{
											display: 'flex',
											gap: 1,
											alignItems: 'center',
										}}
									>
										{getWarehouseItem(warehouseList.find((w) => w.id === value) ?? undefined)}
									</Box>
								)}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.from.undefined')}
								</MenuItem>
								{fromList()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item flexGrow={1} flexBasis={0} height='100%'>
				<Controller
					name='shippingType'
					control={control}
					render={({ field }) => {
						return (
							<FormControl fullWidth sx={{ height: '100%' }}>
								<InputLabel id='ship-by'>{t('order.shipBy')}</InputLabel>
								<Select
									labelId='ship-by'
									id='ship-by'
									size='small'
									label={t('order.shipBy')}
									value={
										field.value !== undefined && field.value !== null
											? field.value
											: ShippingType.notdefined
									}
									disabled={false}
									onChange={(event) => {
										field.onChange(event.target.value as ShippingType);
									}}
									MenuProps={
										renderType === 'popup'
											? {
													style: { zIndex: 9999 },
											  }
											: {}
									}
									sx={{
										height: '100%',
									}}
								>
									{shippingList().map((value, it) => (
										<MenuItem key={it} value={value}>
											{t(`${AppFunction.Order}.shippingTypes.${value.toLowerCase()}`)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					}}
				/>
			</Grid>
			<Grid item flexGrow={1} flexBasis={0} height='100%'>
				<Controller
					name='toId'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth sx={{ height: '100%' }}>
							<InputLabel id='to'>{t('order.to.merchant')}</InputLabel>
							<Select
								labelId='to'
								id='to'
								size='small'
								label={t('order.to.merchant')}
								value={field.value ?? -1}
								fullWidth
								disabled={false}
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? undefined : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
								renderValue={(value) => (
									<Box
										sx={{
											display: 'flex',
											gap: 1,
											alignItems: 'center',
										}}
									>
										{getWarehouseItem(warehouseList.find((w) => w.id === value) ?? undefined)}
									</Box>
								)}
								sx={{
									height: '100%',
								}}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.to.undefined')}
								</MenuItem>
								{toList()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
		</Grid>
	);
};
