import { Cancel, Check } from '@mui/icons-material';
import {
	Chip,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	useTheme,
} from '@mui/material';
import { AppFunction, TaskResponse } from 'common';
import { useLayout } from '@contexts/index';
import { TaskView } from '../views/singleTask/TaskView';
import { uniqueId } from 'lodash';
import useTasks from '@hooks/useTasks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAppFunctions from '@hooks/useAppFunctions';
import { OrderButton } from '../shared/OrderButton';

interface Props {
	task: TaskResponse;
	it: number;
}

// TODO: add takeOver task and commands for admins
export const CompactTask = ({ task, it }: Props) => {
	const theme = useTheme();
	const { pushPopup } = useLayout();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const {
		taskFieldArchetypeList,
		isInTheFuture,
		assignTask,
		unassignTask,
		canAssignTaskTo,
		canUnassign,
		getOrderFromTask,
	} = useTasks();

	const [icons, setIcons] = useState<JSX.Element[]>([]);

	const isFutureTask = isInTheFuture(task.assignableAt);
	const canAssignTask = canAssignTaskTo(task);
	const canUnassignTask = canUnassign(task);
	const order = getOrderFromTask(task);

	useEffect(() => {
		const tempIcons: JSX.Element[] = [];
		task.archetype?.taskFieldArchetypeId.map((id) => {
			const field = taskFieldArchetypeList.find((field) => field.id === id);
			if (field) {
				const icon = getIcon(AppFunction.TaskFieldArchetype, field.type);
				if (icon) {
					tempIcons.push(
						<Tooltip key={id} title={t(`${AppFunction.TaskFieldArchetype}.types.${field.type}`)}>
							{icon}
						</Tooltip>,
					);
				}
			}
		});
		setIcons(tempIcons);
	}, [taskFieldArchetypeList, task]);

	const taskChip = (): JSX.Element => {
		return (
			<Chip
				label={t(`${AppFunction.Task}.statuses.${task.status}`)}
				color='lightWarning'
				size='small'
				icon={getIcon(AppFunction.Task, task.status ?? 'default') ?? <></>}
			/>
		);
	};

	return (
		<ListItem
			key={task.id}
			sx={{
				background: theme.palette.gradient.primaryAlpha,
				borderRadius: '1rem',
				marginTop: it === 0 ? 0 : 1,
				cursor: isFutureTask ? 'not-allowed' : 'pointer',
				opacity: isFutureTask ? 0.5 : 1,
				transform: isFutureTask ? 'scale(0.95)' : 'scale(1)',
				transition: 'all 0.4s',
				'&:hover': {
					boxShadow: isFutureTask ? 'none' : '0 0 0.5rem 1rem rgba(255, 255, 255, 0.2)',
				},
			}}
			onClick={() => {
				if (isFutureTask) return;
				const customId = uniqueId('popup_');
				pushPopup(<TaskView taskId={task.id} popupId={customId} />, false, true, customId);
			}}
		>
			<ListItemAvatar>{icons}</ListItemAvatar>
			<ListItemText sx={{ width: '100%' }} primary={task.title} />
			<Grid
				container
				display='flex'
				sx={{
					paddingRight: '0.5rem',
				}}
			>
				{order && (
					<Grid item flexGrow={1}>
						<OrderButton orderId={order.id} />
					</Grid>
				)}
				<Grid
					item
					flexShrink={1}
					flexBasis={0}
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{taskChip()}
				</Grid>
			</Grid>
			{canUnassignTask && (
				<IconButton
					size='small'
					onClick={(e) => {
						e.stopPropagation();
						unassignTask(task.id);
					}}
				>
					<Cancel color='error' />
				</IconButton>
			)}
			{canAssignTask && (
				<IconButton
					size='small'
					disabled={isInTheFuture(task.assignableAt)}
					onClick={(e) => {
						assignTask(task.id);
						e.stopPropagation();
					}}
				>
					<Check color='success' />
				</IconButton>
			)}
		</ListItem>
	);
};
