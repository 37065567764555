import { PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/hooks';
import { RootState } from '@store/index';
import {
	doCreateTaskFieldParameter,
	doDeleteTaskFieldParameter,
	doEditTaskFieldParameter,
} from '@store/tasks';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	TaskFieldParameterCreateSchema,
	TaskFieldParameterRequest,
	TaskFieldParameterResponse,
	TaskFieldParameterSchema,
} from 'common';
import { useSelector } from 'react-redux';

const selectTaskFieldParameters = (state: RootState) => state.tasks.taskFieldParameters;

function useTaskFieldParameters() {
	const dispatch = useAppDispatch();
	const taskFieldParametersList = useSelector(selectTaskFieldParameters);

	const createTaskFieldParameters = async (
		data: TaskFieldParameterRequest,
	): Promise<TaskFieldParameterResponse> => {
		const parsedData = TaskFieldParameterCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid task field parameter data');
		}
		const response = (await dispatch(
			doCreateTaskFieldParameter(parsedData.data),
		)) as PayloadAction<TaskFieldParameterResponse>;
		if (response.type === 'taskFieldParameters/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('tasks.createFieldParameter.rejected');
	};

	const editTaskFieldParameters = async (
		data: TaskFieldParameterResponse,
	): Promise<AffectedRowsResponse> => {
		const parsedData = TaskFieldParameterSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid task field parameter data');
		}
		const response = (await dispatch(
			doEditTaskFieldParameter(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'taskFieldParameters/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('tasks.editFieldParameter.rejected');
	};

	const deleteTaskFieldParameters = async (
		data: GenericIdRequest,
	): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(
			doDeleteTaskFieldParameter(data),
		)) as PayloadAction<AffectedRowsResponse>;
		return response.payload;
	};

	return {
		taskFieldParametersList,
		createTaskFieldParameters,
		editTaskFieldParameters,
		deleteTaskFieldParameters,
	};
}

export default useTaskFieldParameters;
