import { useOrderEditor } from '@contexts/index';
import { useTranslation } from 'react-i18next';

import {
	Autocomplete,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { AppFunction, TranslationTypes } from 'common';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { OutboundOrderTimeScheduler } from './OutboundOrderTimeScheduler';

export const OutboundOrderHeader = () => {
	const { config, orderType, control, renderType } = useOrderEditor();
	const { customerList } = useCustomers();
	const { warehouseList } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	if (!config || !orderType) {
		return null;
	}

	const pickingSiteMenuItems = () => {
		return warehouseList
			.filter((w) => w.isPickingSite)
			.map((value, it) => (
				<MenuItem key={it} value={value.id}>
					{getTranslatedString(AppFunction.Location, value.id, TranslationTypes.name)}
				</MenuItem>
			));
	};

	return (
		<Grid item container columnSpacing={2} rowGap={2} display='flex'>
			<Grid item flexGrow={1}>
				<Controller
					name='code'
					control={control}
					render={({ field }) => (
						<TextField
							variant='outlined'
							size='small'
							label={t('order.code')}
							value={field.value}
							fullWidth
							onChange={(event) => field.onChange(event.target.value)}
						/>
					)}
				/>
			</Grid>
			<Grid item flexGrow={1}>
				<Controller
					name='pickingSite'
					control={control}
					render={({ field }) => (
						<FormControl fullWidth>
							<InputLabel id='from'>{t('order.pickingSite')}</InputLabel>
							<Select
								labelId='from'
								id='from'
								size='small'
								label={t('order.pickingSite')}
								value={field.value ?? -1}
								fullWidth
								onChange={(event) => {
									const asNum = event.target.value as number;
									field.onChange(asNum === -1 ? null : asNum);
								}}
								MenuProps={
									renderType === 'popup'
										? {
												style: { zIndex: 9999 },
										  }
										: {}
								}
							>
								<MenuItem key={-1} value={-1}>
									{t('order.from.undefined')}
								</MenuItem>
								{pickingSiteMenuItems()}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item flexGrow={2}>
				<Controller
					name='toId'
					control={control}
					render={({ field }) => {
						// const validated = customerList.find((c) => c.id === field.value);
						return (
							<FormControl fullWidth>
								<Autocomplete
									id='through'
									size='small'
									options={customerList}
									getOptionLabel={(option) =>
										option.customerCode.replace('-', '') +
										' ' +
										(option.id != undefined && option.id !== -1
											? t(`${AppFunction.Customer}-${option.id}-${TranslationTypes.name}`, {
													ns: 'locale',
											  })
											: '')
									}
									value={
										field.value !== undefined
											? customerList.find((customer) => customer.id === field.value) || null
											: null
									}
									onChange={(_, newValue) => {
										field.onChange(newValue?.id === -1 ? null : newValue?.id ?? -1);
									}}
									isOptionEqualToValue={(option, value) => {
										return value ? option.id === value.id : option.id === -1;
									}}
									renderInput={(params) => {
										const inputValue = params.inputProps.value;
										const selectedOption = customerList.find(
											(customer) => customer.customerCode === inputValue,
										);

										return (
											<TextField
												{...params}
												label={t('order.target.customer')}
												value={
													selectedOption ? (
														<div style={{ display: 'flex' }}>
															<div style={{ display: 'inline-flex', marginRight: '1rem' }}>
																{selectedOption.customerCode}
															</div>
															<div style={{ display: 'inline-flex' }}>
																{selectedOption?.id ? (
																	<>
																		{getTranslatedString(
																			AppFunction.Customer,
																			selectedOption?.id ?? -1,
																			TranslationTypes.name,
																		)}
																	</>
																) : (
																	<></>
																)}
															</div>
														</div>
													) : (
														inputValue || ''
													)
												}
											/>
										);
									}}
								/>
							</FormControl>
						);
					}}
				/>
			</Grid>
			<OutboundOrderTimeScheduler />
		</Grid>
	);
};
