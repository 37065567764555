import { ReactNode } from 'react';
import { useAppSelector } from '@store/hooks';
import { TaskFieldType } from 'common';
import {
	ModifyOrderField,
	CheckboxField,
	FileInputField,
	StringField,
	NumberField,
	DateField,
	DropdownField,
	CloseOrderField,
	ConfirmPricesField,
	SelectVesselField,
	PickingField,
} from '../../fieldtypes';

interface Props {
	archetypeId: number;
	targetId: number;
}

export const TaskFieldView = ({ archetypeId, targetId }: Props) => {
	const taskFields = useAppSelector((state) => state.tasks.taskFieldArchetypes);

	const field = (): ReactNode => {
		const archetype = taskFields.find((archetype) => archetype.id === archetypeId);
		if (archetype) {
			switch (archetype.type) {
				case TaskFieldType.checkbox:
					return <CheckboxField taskFieldId={archetypeId} />;
				case TaskFieldType.closeorder:
					return <CloseOrderField orderId={targetId} taskFieldId={archetypeId} />;
				case TaskFieldType.date:
					return <DateField taskFieldId={archetypeId} />;
				case TaskFieldType.dropdown:
					return <DropdownField taskFieldId={archetypeId} />;
				case TaskFieldType.fileinput:
					return <FileInputField taskFieldId={archetypeId} />;
				case TaskFieldType.modifyorder:
					return <ModifyOrderField taskFieldId={archetypeId} />;
				case TaskFieldType.number:
					return <NumberField taskFieldId={archetypeId} />;
				case TaskFieldType.string:
					return <StringField taskFieldId={archetypeId} />;
				case TaskFieldType.selectvessel:
					return <SelectVesselField orderId={targetId} taskFieldId={archetypeId} />;
				case TaskFieldType.confirmprices:
					return <ConfirmPricesField taskFieldId={archetypeId} />;
				case TaskFieldType.picking:
					return <PickingField orderId={targetId} taskFieldId={archetypeId} />;
			}
		}
	};

	return <>{field()}</>;
};
