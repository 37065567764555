import React, { MouseEvent, useEffect, useState } from 'react';
import { Close, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { FadeWrapper } from '../common/FadeWrapper';
import useCurrentBreakpoint from '@hooks/useCurrentBreakpoint';

interface Props {
	content: React.ReactNode;
	zIndex: number;
	movable?: boolean;
	expandable?: boolean;
	id: string;
}

const popupWidth: Record<string, number> = {
	xs: 100,
	sm: 100,
	md: 90,
	lg: 80,
	xl: 60,
};

export const PopupWrapper = ({ content, zIndex, movable, expandable, id }: Props) => {
	const { popPopup, popupStates, setHasClickedInPopup, visiblePadding } = useLayout();

	const [dragging, setDragging] = useState(false);
	const [popupPosition, setPopupPosition] = useState({ x: 50, y: 50 });
	const [dragOffsetPixels, setDragOffsetPixels] = useState({ x: 0, y: 0 });
	const [rect, setRect] = useState({ top: 0, left: 0, width: 0, height: 0 });
	const [visible, setVisible] = useState(true);
	const [expanded, setExpanded] = useState(false);

	const currentBreakpoint = useCurrentBreakpoint();

	const theme = useTheme();

	const menuHeight = visiblePadding.top * 2;

	const handleMouseMove = (event: { clientX: number; clientY: number }) => {
		if (!dragging) return;

		let newX = ((event.clientX - dragOffsetPixels.x) / window.innerWidth) * 100;
		let newY = ((event.clientY - dragOffsetPixels.y) / window.innerHeight) * 100;

		const popupWidthInPercent = (rect.width / window.innerWidth) * 100;
		const popupHeightInPercent = (rect.height / window.innerHeight) * 100;

		newX = Math.max(newX, popupWidthInPercent / 2);
		newX = Math.min(newX, 100 - popupWidthInPercent / 2);

		newY = Math.max(newY, popupHeightInPercent / 2);
		newY = Math.min(newY, 100 - popupHeightInPercent / 2);

		setPopupPosition({ x: newX, y: newY });
	};

	useEffect(() => {
		if (!movable) {
			return;
		}

		if (dragging) {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', () => setDragging(false), { once: true });
		}

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', () => setDragging(false));
		};
	}, [dragging, dragOffsetPixels, movable]);

	useEffect(() => {
		if (!popupStates[id]) {
			setVisible(false);
		}
	}, [popupStates, id]);

	const handleDragMouseDown = (event: MouseEvent<HTMLElement>) => {
		if (!movable) return;

		const popup = event.currentTarget.parentElement;
		if (!popup) return;

		const rect = popup.getBoundingClientRect();
		setRect(rect);
		const offsetX = event.clientX - (rect.left + rect.width / 2);
		const offsetY = event.clientY - (rect.top + rect.height / 2);

		setDragOffsetPixels({ x: offsetX, y: offsetY });
		setDragging(true);
	};

	return (
		<FadeWrapper
			fadeTime={300}
			forcedVisibility={visible}
			onCloseAnimationEnd={() => {
				if (!dragging) {
					popPopup(id || '');
				}
			}}
		>
			<div
				style={{
					position: 'fixed',
					top: expanded ? `calc(${popupPosition.y}% + ${menuHeight / 4}px)` : `${popupPosition.y}%`,
					left: `${popupPosition.x}%`,
					height: expanded ? `calc(100% - ${menuHeight / 2}px)` : 'auto',
					maxHeight: expanded ? '100%' : `calc(100% - ${menuHeight}px)`,
					width: expanded ? '100%' : `${popupWidth[currentBreakpoint]}%` ?? '60%',
					maxWidth: '100%',
					transform: 'translate(-50%, -50%)',
					transition: dragging ? '' : 'all 0.3s',
					zIndex,
				}}
				onClick={(e) => e.stopPropagation()}
				onMouseDown={() => setHasClickedInPopup(true)}
				onMouseUp={() => setHasClickedInPopup(false)}
			>
				{movable && !expanded && (
					<Box
						onMouseDown={handleDragMouseDown}
						onDoubleClick={() => {
							setPopupPosition({ x: 50, y: 50 });
							setDragOffsetPixels({ x: 0, y: 0 });
						}}
						sx={{
							cursor: 'move',
							background: 'white',
							width: 'auto',
							padding: '1rem',
							borderRadius: '1rem',
							'&:hover': {
								background: theme.palette.primary.main,
								color: 'white',
							},
							marginBottom: '10px',
							height: '46px',
							boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
						}}
					/>
				)}
				{expandable && (
					<IconButton
						style={{
							position: 'absolute',
							top: '4px',
							right: '44px',
							zIndex: zIndex + 1,
							backgroundColor: 'white',
						}}
						onClick={() => setExpanded((oldToggle) => !oldToggle)}
					>
						{expanded ? <FullscreenExit color='action' /> : <Fullscreen color='action' />}
					</IconButton>
				)}
				<IconButton
					style={{
						position: 'absolute',
						top: '4px',
						right: '4px',
						zIndex: zIndex + 1,
						backgroundColor: 'white',
					}}
					onClick={() => setVisible(false)}
				>
					<Close color='error' />
				</IconButton>
				<div
					style={{
						background: 'transparent',
						borderRadius: expanded ? '0px' : '1rem',
						boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
						overflow: 'scroll',
						height: expanded ? '100%' : 'auto',
						maxHeight: expanded ? '' : `calc(100vh - ${menuHeight + 20}px)`,
					}}
				>
					{content}
				</div>
			</div>
		</FadeWrapper>
	);
};
