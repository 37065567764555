import { SxProps, Theme } from '@mui/material';
import { SingleChip } from './SingleChip';
import _ from 'lodash';
import { MultipleChip } from './MultipleChip';

/**
 * @description The properties of the expiration date chip
 * @property {Date | null | undefined} [expiresAt] - The expiration date of the product
 * @property {Date | null | undefined} [actualExpiration] - The actual expiration date of the product (if it was modified)
 */
interface ExpirationProps {
	expiresAt?: string | Date | Date[] | null;
	actualExpiration?: string | Date | Date[] | null;
	sx?: SxProps<Theme>;
}

/**
 * @description A chip component that displays the expiration date and changes color based on it
 *
 * The color changes based on the expiration date:
 * - **Red**: If the expiration date is in the past
 * - **Orange**: If the expiration date is in the next 2 days
 * - **Blue**: If the expiration date is in the next 7 days
 * - **Green**: If the expiration date is in more than 7 days
 *
 * @param {ExpirationProps} props - Accepts {@link ExpirationProps} or classes that extend it
 * @returns {JSX.Element | null} - Returns a Chip component with the expiration date
 */
const ExpiresAtChip = <T extends ExpirationProps>({
	expiresAt,
	actualExpiration,
	sx,
}: T): JSX.Element | null => {
	if (!expiresAt) return null;
	if (Array.isArray(expiresAt) && Array.isArray(actualExpiration)) {
		if (_.isEqual(expiresAt[0], expiresAt[1])) {
			return <SingleChip expiresAt={expiresAt[0]} actualExpiration={actualExpiration[0]} sx={sx} />;
		}
		return <MultipleChip expiresAt={expiresAt} actualExpiration={actualExpiration} sx={sx} />;
	} else if (!Array.isArray(expiresAt) && !Array.isArray(actualExpiration)) {
		return <SingleChip expiresAt={expiresAt} actualExpiration={actualExpiration} sx={sx} />;
	}
	return null;
};

ExpiresAtChip.displayName = 'ExpiresAtChip';
export default ExpiresAtChip;
