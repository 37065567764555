import { ComponentType } from '@components/common/InputGridField/config/Index';
import { TooltipTool } from '@components/common/InputGridField/GridFieldTooltip';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { EnhancedOrderProduct, EnhancedVariant } from '@contexts/orderEditorContext/types';
import { InputAdornment, RegularBreakpoints, TextField } from '@mui/material';
import { AppFunction } from 'common';
import { clamp, floor, uniqueId } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderProductQuantityProps extends RegularBreakpoints {
	data: EnhancedOrderProduct;
	value: EnhancedVariant | null;
	onChange: (value: number | null) => void;
}

const componentName = 'OrderProductQuantity';

// TODO: width must be modifiable from outside
// TODO: make it so we can modify weight or pickedweight
const OrderProductQuantity = ({
	data,
	value,
	onChange,
}: OrderProductQuantityProps): JSX.Element => {
	const { t } = useTranslation();
	const index = uniqueId();

	const maxValue = useMemo(() => {
		if (data.booked) {
			return 9999;
		}

		if (data.process) {
			// TODO: check why we get weight as single weight pls
			let remaningWeight = value?.remainingWeight;

			if (!remaningWeight) {
				remaningWeight = (value?.weight ?? 1) * (value?.maxAmount ?? 1);
			}

			return remaningWeight / (value?.maxAmount ?? 1);
		} else {
			return value?.maxAmount;
		}
	}, [data, value]);

	// TODO: lol
	if (data.process && !value?.remainingWeight && !value?.weight) {
		return <>nope</>;
	}

	const actualValue = data.process ? data.pickedWeight ?? 0 : data.orderQty ?? 0;

	const tools = useMemo((): TooltipTool[] => {
		const tempTools: TooltipTool[] = [];
		tempTools.push({
			title: 'max',
			onClick: () => onChange(maxValue ?? null),
		});
		if (data.process) {
			tempTools.push(
				{
					title: 'half',
					onClick: () => onChange(floor((maxValue ?? 1) / 2)),
				},
				{
					title: 'third',
					onClick: () => onChange(floor((maxValue ?? 1) / 3)),
				},
				{
					title: 'quarter',
					onClick: () => onChange(floor((maxValue ?? 1) / 4)),
				},
			);
		}
		return tempTools;
	}, [data.process, value, maxValue, onChange]);

	return (
		<InputGridField width={2} tools={tools} type={ComponentType.TextField}>
			<TextField
				id={`order-quantity-idx-${index}`}
				fullWidth
				variant='outlined'
				size='small'
				label={t(`${AppFunction.Product}.quantity`)}
				type='number'
				value={actualValue}
				InputProps={{
					inputProps: {
						min: 0,
						max: maxValue,
					},
					endAdornment: (
						<InputAdornment position='end'>
							{t(`${AppFunction.Product}.units.${data.process ? 'grams' : 'pieces'}`, {
								count: actualValue,
							})}
						</InputAdornment>
					),
					style: { color: actualValue >= (maxValue ?? 0) ? 'red' : 'inherit' },
				}}
				onChange={(event) => {
					const parsedValue = parseInt(event.target.value);
					onChange(isNaN(parsedValue) ? null : clamp(parsedValue, maxValue ?? 1));
				}}
			/>
		</InputGridField>
	);
};

OrderProductQuantity.displayName = componentName;
export default OrderProductQuantity;
