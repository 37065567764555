import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';
import { SkuChip } from '@components/common/chips/SkuChip';
import { WeightChip } from '@components/common/chips/WeightChip';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useOrderModify } from '@contexts/orderModifyContext/OrderModifyContext';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import useTasks from '@hooks/useTasks';
import { Close, Delete, Edit, Replay } from '@mui/icons-material';
import { Chip, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import {
	AppFunction,
	OrderBaseResponse,
	OrderProductResponse,
	TaskFieldType,
	TaskResponse,
	TranslationTypes,
} from 'common';
import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface Props {
	orderProduct: OrderProductResponse;
	field: ControllerRenderProps<OrderBaseResponse, 'children'>;
}

export const InboundModifySuborderProduct = ({ orderProduct, field }: Props) => {
	const { cachedData, formAction } = useOrderModify();
	const { getTranslatedString } = useLocale();
	const { getTaskInChainByOrderIdFieldType } = useTasks();
	const { variantList } = useProducts();
	const [editWeight, setEditWeight] = useState(false);
	const [beforeDeletionQty, setBeforeDeletionQty] = useState<number | undefined>(undefined);
	const [beforeEditWeight, setBeforeEditWeight] = useState<number | undefined>(undefined);

	const variant = variantList.find((v) => v.id === orderProduct.variantId);

	if (!variant) {
		return null;
	}

	/**
	 * Retrieves the task in the chain by order ID and field type for the given cached data.
	 *
	 * @constant
	 * @type {TaskResponse | undefined}
	 * @param {number} cachedData?.id - The ID of the cached data, defaults to -1 if undefined.
	 * @param {TaskFieldType} TaskFieldType.modifyorder - The field type to search for in the task chain. (Modify Order Task)
	 * @param {boolean} true - A boolean flag indicating whether to include completed tasks.
	 * @returns {TaskResponse | null} The task found in the chain, or undefined if no matching task is found.
	 */
	const modifyOrderTask: TaskResponse | null = getTaskInChainByOrderIdFieldType(
		cachedData?.id ?? -1,
		TaskFieldType.modifyorder,
		true,
	);

	const singleWeight = Math.round(
		variant.variable
			? (orderProduct.weight ?? 1) / (orderProduct.orderQty ?? 1)
			: orderProduct.weight ?? 1,
	);
	const totalWeight = variant.variable
		? orderProduct.weight
		: (orderProduct.weight ?? 1) * (orderProduct.orderQty ?? 1);

	return (
		<Grid container display='flex'>
			<Grid
				item
				display='flex'
				alignItems='center'
				xs={1}
				sx={{
					padding: '0.3rem',
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
			>
				<SkuChip
					sku={variant.sku}
					orderProductId={orderProduct.id ? +orderProduct.id : -1}
					sx={{
						width: '100%',
						transition: 'opacity 0.3s',
						opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
					}}
				/>
			</Grid>
			<Grid
				item
				xs={formAction === 'edit' ? 3 : undefined}
				flexGrow={formAction === 'edit' ? undefined : 1}
				display='flex'
				alignItems='center'
				gap={1}
				sx={{
					padding: '0.3rem',
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
			>
				<Typography>
					{getTranslatedString(AppFunction.Product, variant.productId ?? -1, TranslationTypes.name)}
				</Typography>
				<Typography>
					{getTranslatedString(
						AppFunction.Variant,
						orderProduct.variantId ?? -1,
						TranslationTypes.name,
					)}
				</Typography>
			</Grid>
			<InputGridField
				width={1}
				type={ComponentType.TextField}
				sx={{
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
			>
				<TextField
					type='number'
					size='small'
					variant='outlined'
					disabled={beforeDeletionQty !== undefined || formAction !== 'edit'}
					value={orderProduct.orderQty}
					onChange={(e) => {
						orderProduct.orderQty = +e.target.value;
						orderProduct.arrivedQty = orderProduct.orderQty;
						const variant = variantList.find((v) => v.id === orderProduct.variantId);
						let newWeight = orderProduct.weight;
						if (variant) {
							newWeight = variant.variable
								? variant.weight * (orderProduct.orderQty ?? 0)
								: variant.weight;
						}
						if (
							!editWeight &&
							orderProduct.weight !== newWeight &&
							modifyOrderTask?.status !== 'completed'
						) {
							orderProduct.weight = newWeight;
						}
						field.onChange(field.value);
					}}
					InputProps={{
						inputProps: {
							min: 0,
						},
					}}
				/>
			</InputGridField>
			<Grid
				item
				display='flex'
				alignItems='center'
				xs={1}
				sx={{
					padding: '0.3rem 1rem 0.3rem 1rem',
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
			>
				<WeightChip
					weight={beforeDeletionQty === undefined ? singleWeight : 0}
					singleWeight
					estimated={variant.variable}
					sx={{ width: '100%' }}
				/>
			</Grid>
			<InputGridField
				width={1}
				type={ComponentType.TextField}
				sx={{
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
			>
				<TextField
					type='number'
					size='small'
					variant='outlined'
					disabled={
						!editWeight ||
						beforeDeletionQty !== undefined ||
						modifyOrderTask?.status !== 'completed' ||
						formAction !== 'edit'
					}
					value={beforeDeletionQty !== undefined ? 0 : totalWeight}
					onChange={(e) => {
						orderProduct.weight = +e.target.value;
						field.onChange(field.value);
					}}
					InputProps={{
						inputProps: {
							min: 0,
						},
						endAdornment:
							variant.variable &&
							beforeDeletionQty === undefined &&
							modifyOrderTask?.status === 'completed' &&
							formAction === 'edit' ? (
								<InputAdornment position='end'>
									<IconButton
										sx={{
											fontSize: '1rem',
										}}
										size='small'
										onClick={() => {
											const newValue = !editWeight;
											if (newValue) {
												setBeforeEditWeight(orderProduct.weight ?? 0);
											} else {
												orderProduct.weight = beforeEditWeight ?? 0;
											}
											setEditWeight(newValue);
										}}
									>
										{editWeight ? (
											<Close fontSize='inherit' color='error' />
										) : (
											<Edit fontSize='inherit' color='info' />
										)}
									</IconButton>
								</InputAdornment>
							) : null,
					}}
				/>
			</InputGridField>
			<Grid
				item
				xs={1}
				sx={{
					padding: '0.3rem 1rem 0.3rem 1rem',
					transition: 'opacity 0.3s',
					opacity: beforeDeletionQty !== undefined ? 0.5 : 1,
				}}
				display='flex'
				alignItems='center'
			>
				{orderProduct.expiresAt ? (
					<ExpiresAtChip expiresAt={orderProduct.expiresAt} sx={{ width: '100%' }} />
				) : (
					<Chip label='N/A' size='small' sx={{ width: '100%' }} />
				)}
			</Grid>
			{formAction === 'edit' && <Grid item flexGrow={1} />}
			{formAction === 'edit' && (
				<Grid item justifySelf='flex-end'>
					<IconButton
						onClick={() => {
							if (beforeDeletionQty !== undefined) {
								setBeforeDeletionQty(undefined);
								orderProduct.orderQty = beforeDeletionQty;
								orderProduct.arrivedQty = orderProduct.orderQty;
								field.onChange(field.value);
							} else {
								setBeforeDeletionQty(orderProduct.orderQty);
								orderProduct.orderQty = 0;
								orderProduct.arrivedQty = 0;
								field.onChange(field.value);
							}
						}}
					>
						{beforeDeletionQty === undefined ? <Delete /> : <Replay />}
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};
