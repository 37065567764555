import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { SplitOrderProductRow } from './SplitSuborderProductRow';

export const SplitSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { subOrders, setSuborderProperties, removeSubOrder } = useOrderEditor();
	const currentSubOrder = subOrders.find((so) => so.code === subOrderCode);

	if (!currentSubOrder || !currentSubOrder.code) {
		return null;
	}

	return (
		// <Grid
		//     item
		//     container
		//     p={1}
		//     sx={{
		//         margin: '1rem 0 1rem 0',
		//         overflow: 'show',
		//     }}
		//     rowGap={2}
		//     display='flex'
		// >
		<Grid
			item
			container
			p={1}
			m={2}
			sx={{
				overflow: 'show',
			}}
			rowGap={2}
			display='flex'
		>
			<SplitOrderProductRow subOrder={currentSubOrder} />
		</Grid>
	);
};
