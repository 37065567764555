import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { DeliveryAreas } from '@components/forms/carriers/DeliveryAreas';
import { DeliveryTimes } from '@components/forms/carriers/DeliveryTimes';
import { GeneralInfo } from '@components/forms/carriers/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { DeliveryPlansProvider } from '@contexts/deliveryPlansContext/DeliveryPlansContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import useCarriers from '@hooks/useCarriers';
import { Settings } from '@mui/icons-material';
import { AppFunction, CarrierResponse, CarrierSchema, CarrierType } from 'common';

interface Props {
	formAction: FormAction;
	renderType?: FormRenderType;
	forcedId?: number;
	formType?: FormType;
}

const CarrierFormContent = withFormLogic<CarrierResponse>(() => <></>);

export const CarrierForm = ({ formAction, forcedId, formType }: Props) => {
	const { carrierList, createCarrier, editCarrier } = useCarriers();

	return (
		<CarrierFormContent
			forcedId={forcedId}
			formType={formType}
			formAction={formAction}
			list={carrierList}
			validationSchema={CarrierSchema}
			createFunction={createCarrier}
			editFunction={editCarrier}
			// TODO: delete name from carrier
			defaultValues={{ id: -1, name: '', carrierType: CarrierType.external } as CarrierResponse}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			sideFabs={[FormSidefabType.Image, FormSidefabType.Info, FormSidefabType.Notes]}
			appFunction={AppFunction.Carrier}
			customAfterCreateLink='carrier/:id/edit'
			onDataSaved={(id) => console.log('data saved:', id)}
		>
			{(id) => (
				<>
					<TabbedElement title='carrier.generalInfo'>
						<GeneralInfo />
					</TabbedElement>
					<TabbedElement
						title='carrier.deliveryTime'
						hideEditButton
						onBeforeShowTab={() => id !== -1}
					>
						<DeliveryTimes />
					</TabbedElement>
					<TabbedElement title='carrier.areas' hideEditButton onBeforeShowTab={() => id !== -1}>
						<DeliveryAreas />
					</TabbedElement>
					<TabbedElement title='carrier.plans' hideEditButton onBeforeShowTab={() => id !== -1}>
						<DeliveryPlansProvider />
					</TabbedElement>
				</>
			)}
		</CarrierFormContent>
	);
};
