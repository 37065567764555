import { MutableRefObject, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash';

const delay = 400;

type Mode = 'size' | 'position' | 'both';

function useLayoutObserver<T extends HTMLElement>(
	id: string,
	mode: Mode,
	callback: (id: string, contentRect: DOMRectReadOnly, mode: 'size' | 'position') => void,
): MutableRefObject<T | null> {
	const [sizeCache, setSizeCache] = useState({ width: 0, height: 0 });
	const [positionCache, setPositionCache] = useState({ x: 0, y: 0 });
	const ref = useRef<T | null>(null);
	const reset = useRef<boolean>(false);

	if (reset.current) {
		setSizeCache({ width: 0, height: 0 });
		setPositionCache({ x: 0, y: 0 });
		reset.current = false;
	}

	const registerSizeCallback = useCallback(
		(id: string, contentRect: DOMRectReadOnly) => {
			const { width, height } = contentRect;
			if (width !== sizeCache.width || height !== sizeCache.height) {
				setSizeCache({ width, height });
				callback(id, contentRect, 'size');
			}
		},
		[callback, id, sizeCache],
	);

	const debouncedSizeCallback = useCallback(debounce(registerSizeCallback, delay), [
		registerSizeCallback,
	]);

	const registerPositionCallback = useCallback(
		(id: string, contentRect: DOMRectReadOnly) => {
			const { x, y } = contentRect;
			if (x !== positionCache.x || y !== positionCache.y) {
				setPositionCache({ x, y });
				callback(id, contentRect, 'position');
			}
		},
		[callback, id, positionCache],
	);

	const debouncedPositionCallback = useCallback(debounce(registerPositionCallback, delay), [
		registerPositionCallback,
	]);

	useLayoutEffect(() => {
		const element = ref.current;
		if (!element) {
			reset.current = true;
			return;
		}

		const observer = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === element) {
					const { width, height } = entry.contentRect;

					// Handle size changes if needed
					if (
						(mode === 'size' || mode === 'both') &&
						(width !== sizeCache.width || height !== sizeCache.height)
					) {
						debouncedSizeCallback(id, entry.contentRect);
					}

					// Handle position changes if needed
					if (mode === 'position' || mode === 'both') {
						const boundingClientRect = entry.target.getBoundingClientRect();
						const { x, y } = boundingClientRect;
						if (x !== positionCache.x || y !== positionCache.y) {
							debouncedPositionCallback(id, boundingClientRect);
						}
					}
				}
			}
		});

		observer.observe(element);

		return () => {
			observer.unobserve(element);
			debouncedSizeCallback.cancel();
			debouncedPositionCallback.cancel();
		};
	}, [debouncedSizeCallback, debouncedPositionCallback, id, sizeCache, mode]);

	return ref;
}

export default useLayoutObserver;
