import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/index';
import { Grid } from '@mui/material';
import { CountryResponse } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control, formAction } = useGenericForm<CountryResponse>();
	const { t } = useTranslation();

	return (
		<Grid container spacing={2} sx={{ minHeight: '15rem' }}>
			<Grid item xs={12}>
				<TabInnerTitle title='country.generalInfo' translate size='small' />
			</Grid>
			<Grid item xs={12}></Grid>
		</Grid>
	);
};
