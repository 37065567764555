import { useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import useGeography, { ParsedRegionResponse } from '../../hooks/useGeography';

import { Button, CircularProgress, Grid, Stack, TextField } from '@mui/material';
import { TabInnerTitle } from '../tabs/TabInnerTitle';
import { Controller, useForm } from 'react-hook-form';
import { EditShowGridField } from '../common/EditShowGridField';
import { TranslatableAutocompleteWithCallback } from '../TranslatableAutocompleteWithCallback';
import { RegionSelector } from '../RegionSelector';
import { zodResolver } from '@hookform/resolvers/zod';

import addressStyles from '../addresses/AddressComponent.module.css';
import { AppFunction, TranslationTypes } from 'common';
import { useTranslation } from 'react-i18next';

const QuickPersonDataRequestSchema = z.object({
	titleId: z.number(),
	name: z.string(),
	surname: z.string(),
	countryId: z.number(),
	zip: z.string(),
	regionId: z.number(),
	address: z.string(),
	number: z.string(),
	building: z.string(),
	tel: z.string(),
	fax: z.string(),
	email: z.string(),
});

export type QuickPersonDataRequest = z.infer<typeof QuickPersonDataRequestSchema>;

interface Props {
	titleId: number;
	onChangeValue: (data: QuickPersonDataRequest, titleId: number) => void;
}

export const QuickPersonCreate = ({ titleId, onChangeValue }: Props) => {
	const { t } = useTranslation();
	const { countryList, getAddressByZipCode, parseAddress } = useGeography();

	const [isValidZip, setValidZip] = useState(false);
	const [currentZip, setCurrentZip] = useState('');
	const [loadingAddress, setLoadingAddress] = useState(false);
	const [strings, setStrings] = useState<string[]>([]);

	const {
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<QuickPersonDataRequest>({
		mode: 'onChange',
		defaultValues: {
			titleId: titleId,
			name: '',
			surname: '',
			countryId: 1,
			zip: '',
			regionId: -1,
			address: '',
			number: '',
			building: '',
			tel: '',
			fax: '',
			email: '',
		},
		resolver: zodResolver(QuickPersonDataRequestSchema),
	});

	const data = watch();
	const currentCountry = watch('countryId');
	const regionId = watch('regionId');

	useEffect(() => {
		setValidZip(currentZip?.length === 7 || currentZip?.length === 8);
	}, [currentZip]);

	useEffect(() => {
		onChangeValue(data, titleId);
	}, [data]);

	const renderSelectAddressDialog = (strings: string[]) => {
		if (strings.length > 1) {
			return (
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						zIndex: '400',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backdropFilter: 'blur(0.5px)',
					}}
					onClick={() => {
						setStrings([]);
					}}
				>
					<Stack
						spacing={1}
						sx={{
							backgroundColor: 'white',
							padding: '1rem',
							borderRadius: '1rem',
							boxShadow: '1px 1px 14px -2px rgba(0,0,0,0.45)',
						}}
					>
						{strings.map((s, it) => {
							return (
								<Button key={it} onClick={() => parseAddress(s)} variant='contained'>
									〒{s}
								</Button>
							);
						})}
					</Stack>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const resolveParsedAddress = (address: ParsedRegionResponse | undefined) => {
		if (address?.regions) {
			setValue('regionId', address.regions[address.regions.length - 1].region.id);
			setValidZip(false);
			setStrings([]);
		} else {
			setValue('regionId', -1);
			setValidZip(false);
			setStrings([]);
		}
		setValue('address', address?.address ?? '');
		setValue('number', address?.number ?? '');
	};

	const onChangeZip = async (data: string) => {
		setLoadingAddress(true);
		const split = data.split('-');
		let zip = data;
		if (split.length === 2) {
			zip = split.join('');
		}
		const res = (await getAddressByZipCode(zip)) as string[];
		if (res.length === 0) {
			parseAddress('').then((result) => {
				resolveParsedAddress(result);
			});
		} else if (res.length === 1) {
			parseAddress(res[0]).then((result) => {
				resolveParsedAddress(result);
			});
		}
		setStrings(res);
		setLoadingAddress(false);
	};

	const regions = useMemo(() => {
		return (
			<Controller
				name={'regionId'}
				control={control}
				render={({ field }) => (
					<RegionSelector
						countryId={currentCountry}
						regionId={field.value}
						isEditing
						onChange={(value) => field.onChange(value)}
					/>
				)}
			/>
		);
	}, [regionId]);

	return (
		<>
			{renderSelectAddressDialog(strings)}
			<Grid
				item
				xs={12}
				container
				spacing={2}
				sx={{ position: 'relative', marginTop: '0px', paddingTop: '0px' }}
			>
				<div
					style={{
						position: 'absolute',
						width: 'calc(100% + 1.5rem)',
						height: '100%',
						zIndex: '400',
						visibility: loadingAddress ? 'visible' : 'hidden',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(255,255,255,0.7)',
						backdropFilter: 'blur(0.5px)',
					}}
				>
					<CircularProgress />
				</div>
				<Grid item xs={12}>
					<TabInnerTitle
						title={`[${t(`jobTitle-${titleId}-name`, { ns: 'locale' })}] ${t('operations.create')}`}
						translate
						size='medium'
					/>
				</Grid>
				<Controller
					name={'name'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={6} label>
							<TextField
								variant='outlined'
								id='person-name'
								label={t('person.name')}
								error={!!errors.name}
								helperText={errors.name && errors.name.message}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'surname'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={6} label>
							<TextField
								variant='outlined'
								id='person-surname'
								label={t('person.surname')}
								error={!!errors.surname}
								helperText={errors.surname && errors.surname.message}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'countryId'}
					control={control}
					render={({ field }) => (
						<EditShowGridField
							width={6}
							label
							isEditing
							forceLabel={t('address.country')}
							valueTranslationRule={{
								translatableType: AppFunction.Country,
								translationType: TranslationTypes.name,
								namespace: 'locale',
							}}
						>
							<TranslatableAutocompleteWithCallback
								data={countryList}
								value={field.value}
								translationSettings={{
									translatableType: AppFunction.Country,
									translationType: TranslationTypes.name,
									namespace: 'locale',
								}}
								id='countryId'
								label={t('address.country')}
								onChange={(value) => field.onChange(value)}
								onCreateNew={(newName) => console.log(`create new name: ${newName}`)}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'zip'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={6} label={true}>
							<TextField
								variant='outlined'
								id='address-zip'
								label={t('address.zip')}
								error={!!errors.zip}
								helperText={errors.zip && errors.zip.message}
								value={field.value ?? ''}
								onChange={(ev) => {
									field.onChange(ev.target.value ?? '');
									setCurrentZip(ev.target.value ?? '');
								}}
								onKeyDown={(ev) => {
									if (ev.key === 'Enter' && isValidZip) onChangeZip(field.value ?? '');
								}}
								InputProps={{
									endAdornment: (
										<div style={{ overflow: 'hidden', width: '100px' }}>
											<Button
												variant='contained'
												className={
													isValidZip ? addressStyles.visibleButton : addressStyles.hiddenButton
												}
												onClick={() => onChangeZip(field.value ?? '')}
											>
												{t('operations.search')}
											</Button>
										</div>
									),
								}}
							/>
						</EditShowGridField>
					)}
				/>
				{regions}
				<Controller
					name={'address'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={9} label={true}>
							<TextField
								variant='outlined'
								id='address-address'
								label={t('address.address')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'number'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={3} label={true}>
							<TextField
								variant='outlined'
								id='address-number'
								label={t('address.number')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'building'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={12} label={true}>
							<TextField
								variant='outlined'
								id='address-building'
								label={t('address.building')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'tel'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={6} label={true}>
							<TextField
								variant='outlined'
								id='address-tel'
								label={t('address.tel')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'fax'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={6} label={true}>
							<TextField
								variant='outlined'
								id='address-fax'
								label={t('address.fax')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
				<Controller
					name={'email'}
					control={control}
					render={({ field }) => (
						<EditShowGridField isEditing width={12} label={true}>
							<TextField
								variant='outlined'
								id='address-email'
								label={t('address.email')}
								value={field.value ?? ''}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</EditShowGridField>
					)}
				/>
			</Grid>
		</>
	);
};
