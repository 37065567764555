import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';

interface Props {
	onFileUploaded?: (file: File) => void;
	onFileDeleted?: () => void;
	onFileDrop?: (file: File) => void;
	disabled?: boolean;
}

export const FileDropZone = ({ onFileUploaded, onFileDeleted, onFileDrop, disabled }: Props) => {
	const [isDragging, setIsDragging] = useState(false);
	const [file, setFile] = useState<File | null>(null);

	// #region Drag Events
	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		if (disabled) return;
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		if (disabled) return;
		setIsDragging(false);
	};

	const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		if (disabled) return;
		e.preventDefault();
		setIsDragging(false);

		const files = e.dataTransfer.files;
		if (files.length) {
			if (files[0].type.startsWith('application/pdf')) {
				// await upload(files[0]);
				const culo = files[0];
				setFile(culo);
				onFileDrop?.(files[0]);
			}
		}
	};
	// #endregion

	return (
		<div
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			style={{
				minWidth: '100%',
				minHeight: '5rem',
				backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.25)' : 'transparent',
				border: isDragging ? '1px dashed rgba(0, 0, 0, 1)' : '1px dashed rgba(0, 0, 0, 0.25)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				transform: isDragging ? 'scale(1.01)' : 'scale(1)',
				transition: 'all 200ms ease-in-out',
			}}
		>
			{file ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						padding: '0 1rem 0 1rem',
					}}
				>
					<div
						style={{
							border: '1px solid rgba(0, 0, 0, 0.25)',
							backgroundColor: 'rgba(0, 0, 0, 0.05)',
							flexGrow: 1,
							padding: '1rem',
							marginRight: '1rem',
						}}
					>
						{file.name}
					</div>
					<IconButton
						onClick={() => {
							setFile(null);
							onFileDeleted?.();
						}}
					>
						<Delete color='error' />
					</IconButton>
				</div>
			) : (
				<p>Drop your file here</p>
			)}
		</div>
	);
};
