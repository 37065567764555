import { AppFunction } from 'common';
import { Role } from './store/role';

export const checkPermissions = (
	userPermissions: Role[] | null,
	against: AppFunction,
	checkRead = true,
	checkWrite?: boolean,
) => {
	if (!userPermissions) return false;

	const hasPermission = userPermissions.some(
		(role) =>
			role.active &&
			role.permissions.some((permission) => {
				if (permission.function !== against) return false;

				const readCondition = checkRead ? permission.read === true : true;
				const writeCondition = checkWrite !== undefined ? permission.write === checkWrite : true;
				return readCondition && writeCondition;
			}),
	);

	const hasSpecialRole = userPermissions.some(
		(role) => role.name === 'admin' || role.name === 'superadmin',
	);

	return hasPermission || hasSpecialRole;
};

export const checkReadPermission = (userPermissions: Role[] | null, against: AppFunction) => {
	return checkPermissions(userPermissions, against, true);
};

export const checkWritePermission = (userPermissions: Role[] | null, against: AppFunction) => {
	return checkPermissions(userPermissions, against, true, true);
};
