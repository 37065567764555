import useLocale from '@hooks/useLocale';
import useMerchants from '@hooks/useMerchants';
import { Chip, SxProps, Theme } from '@mui/material';
import { AppFunction, TranslationTypes } from 'common';

interface Props {
	merchantId: number;
	sx?: SxProps<Theme>;
}

export const MerchantChip = ({ merchantId, sx }: Props): JSX.Element | null => {
	const { merchantList } = useMerchants();
	const { getTranslatedString } = useLocale();

	const merchant = merchantList.find((merchant) => merchant.id === merchantId);

	if (!merchant) {
		return null;
	}

	return (
		<Chip
			size='small'
			label={getTranslatedString(AppFunction.Merchant, merchant.id, TranslationTypes.name)}
			sx={{ ...sx }}
		/>
	);
};
