import { ReactElement, useEffect, useState } from 'react';
import { Fade } from '@mui/material';

interface Props {
	fadeTime?: number;
	ignore?: boolean;
	children: ReactElement;
	onCloseAnimationEnd?: () => void;
	forcedVisibility?: boolean;
}

export const FadeWrapper = (props: Props) => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setVisible(true);
	}, []);

	useEffect(() => {
		if (props.forcedVisibility !== undefined) {
			setVisible(props.forcedVisibility);
		}
	}, [props.forcedVisibility]);

	if (props.ignore) {
		return props.children;
	}
	return (
		<Fade
			in={visible}
			timeout={props.fadeTime || 500}
			onTransitionEnd={() => {
				if (!visible) {
					props.onCloseAnimationEnd?.();
				}
			}}
		>
			{props.children}
		</Fade>
	);
};
