import {
	AffectedRowsResponse,
	EntitiesEntityGroupRequest,
	EntitiesEntityGroupResponse,
	EntityGroupRequest,
	EntityGroupResponse,
	EntityGroupsResponse,
	GenericIdRequest,
} from 'common';
import { NetworkOperationStatus } from '.';
import { createSlice } from '@reduxjs/toolkit';

import * as api from './externalApi/common/apiCalls';

export interface GroupsState {
	op_status: NetworkOperationStatus;
	groups: EntityGroupsResponse;
	links: EntitiesEntityGroupResponse;
}

const initialState: GroupsState = {
	op_status: 'idle',
	groups: [],
	links: [],
};

export const doListGroups = api.apiList<EntityGroupsResponse>('groups/list', 'entitygroups/');

export const doCreateGroup = api.apiCreate<EntityGroupRequest, EntityGroupResponse>(
	'groups/create',
	'entitygroups',
);

export const doEditGroup = api.apiEdit<EntityGroupResponse, AffectedRowsResponse>(
	'groups/edit',
	'entitygroups',
);

export const doDeleteGroup = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'groups/delete',
	'entitygroups',
);

export const doListEntitiesEntityGroup = api.apiList<EntitiesEntityGroupResponse>(
	'groups/entitygroups/list',
	'entitygroups/links',
);

export const doLinkEntityToGroup = api.apiBulkCreate<
	EntitiesEntityGroupRequest,
	AffectedRowsResponse
>('groups/link', 'entitygroups/links');

export const doUnlinkEntityFromGroup = api.apiBulkDelete<
	EntitiesEntityGroupRequest,
	AffectedRowsResponse
>('groups/unlink', 'entitygroups/links/delete');

export const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doListGroups.pending.type,
					doCreateGroup.pending.type,
					doEditGroup.pending.type,
					doDeleteGroup.pending.type,
					doListEntitiesEntityGroup.pending.type,
					doLinkEntityToGroup.pending.type,
					doUnlinkEntityFromGroup.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListGroups.fulfilled.type,
					doCreateGroup.fulfilled.type,
					doEditGroup.fulfilled.type,
					doDeleteGroup.fulfilled.type,
					doListEntitiesEntityGroup.fulfilled.type,
					doLinkEntityToGroup.fulfilled.type,
					doUnlinkEntityFromGroup.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				if (action.type === doListGroups.fulfilled.type) {
					state.groups = action.payload as EntityGroupsResponse;
				} else if (action.type === doListEntitiesEntityGroup.fulfilled.type) {
					state.links = action.payload;
				}
				state.op_status = 'succeeded';
			},
		);
		builder.addMatcher(
			(action) =>
				[
					doListGroups.rejected.type,
					doCreateGroup.rejected.type,
					doEditGroup.rejected.type,
					doDeleteGroup.rejected.type,
					doListEntitiesEntityGroup.rejected.type,
					doLinkEntityToGroup.rejected.type,
					doUnlinkEntityFromGroup.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
	},
});

export default groupsSlice.reducer;
