import {
	Autocomplete,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from './NewInputGroup';
import { ChildType, NewInputGroupItemChildren } from './NewInputGroupItemChildren';

import { EditShowGridField } from './common/EditShowGridField';
import { AnimatedIconButton } from './common/AnimatedIconButton';
import { Delete } from '@mui/icons-material';

export interface InputGroupItemProps {
	id: number;
	options: Option[];
	optionsLabel: string;
	availableOptions: number[];
	selectedOption: Option;
	childrenSettings: ChildSettings[];
	autocomplete?: boolean;
	multiLine?: boolean;
	view?: boolean;
	onSelect: (selected: string, itemId: number) => void;
	onValueChange: (itemId: number, inputId: number, inputValue: string) => void;
	onDelete: (id: number) => void;
}

export interface ChildSettings {
	childType: ChildType;
	label: string;
	size: number | undefined | 'flexGrow';
	readonly?: boolean;
	menuItems?: MenuItem[];
	selectionItems?: string[];
}

export interface MenuItem {
	type: MenuItemType;
	action: (index: number, value: number) => void;
}

export enum MenuItemType {
	Edit = 'edit',
}

export const NewInputGroupItem = (props: InputGroupItemProps) => {
	const { t } = useTranslation();
	const [currentValue, setCurrentValue] = useState<{ label: string } | null>(null);
	const [filteredOptions, setFilteredOptions] = useState<{ label: string }[]>([]);
	const canEdit = props.view ? !props.view : true;

	useEffect(() => {
		if (props.selectedOption.selectionId) {
			const tempName =
				props.options.find((x) => x.selectionId === props.selectedOption.selectionId)
					?.selectionName ?? '';
			if (currentValue?.label !== tempName) {
				setCurrentValue({ label: tempName });
			}
		}
	}, [props.selectedOption.selectionId]);

	useEffect(() => {
		setFilteredOptions(filterOptions());
	}, [props.availableOptions]);

	const handleDropdownChange = (ev: SelectChangeEvent) => {
		ev.preventDefault();
		if (props.selectedOption.selectionId !== +ev.target.value) {
			props.onSelect(ev.target.value, props.id);
		}
	};

	const handleAutocompleteChange = (newValue: string) => {
		const id = props.options.find((x) => x.selectionName === newValue)?.selectionId ?? -1;
		if (props.selectedOption.selectionId !== id) {
			props.onSelect(id.toString(), props.id);
		}
	};

	const storeValue = (inputValue: string, inputIdx: number) => {
		props.onValueChange(props.selectedOption.selectionId, inputIdx, inputValue);
	};

	const calculateDropdownValue = (): string => {
		if (props.availableOptions.length === 1 && props.selectedOption.selectionId === -1) {
			return props.availableOptions[0].toString();
		} else {
			return props.selectedOption.selectionId !== -1
				? props.selectedOption.selectionId.toString()
				: '';
		}
	};

	const onReset = (index: number) => {
		storeValue(
			props.selectedOption.inputs
				? props.selectedOption.inputs[index].defaultValue?.toString() ?? ''
				: '',
			index,
		);
	};

	const filterOptions = () => {
		const filteredOptions: { label: string }[] = [];
		if (props.selectedOption.selectionName !== '') {
			filteredOptions.push({ label: props.selectedOption.selectionName });
		}
		props.options.map((x) => {
			if (props.availableOptions.includes(x.selectionId)) {
				filteredOptions.push({ label: x.selectionName });
			}
		});
		return filteredOptions;
	};

	const renderSelection = () => {
		return (
			<FormControl fullWidth>
				{props.autocomplete && (
					<EditShowGridField
						isEditing={canEdit && props.availableOptions.length > 0}
						label
						forceLabel={t(props.optionsLabel)}
					>
						<Autocomplete
							id={`input-group-selection-${props.id}`}
							options={filteredOptions}
							autoComplete
							isOptionEqualToValue={(option, value) =>
								option.label === value.label || props.availableOptions.length === 0
							}
							onChange={(
								event: SyntheticEvent<Element, Event>,
								value: { label: string } | null,
							) => {
								handleAutocompleteChange(value?.label ?? '');
							}}
							value={currentValue ?? null}
							disabled={props.availableOptions.length === 0}
							renderInput={(params) => <TextField {...params} label={t(props.optionsLabel)} />}
						/>
					</EditShowGridField>
				)}
				{!props.autocomplete && (
					<>
						<InputLabel id='input-group-item-label'>{t(props.optionsLabel)}</InputLabel>
						<Select
							labelId='input-group-item-label'
							id={`input-group-selection-${props.id}`}
							value={calculateDropdownValue()}
							label={t(props.optionsLabel)}
							onChange={handleDropdownChange}
							disabled={canEdit && props.availableOptions.length === 0}
						>
							{props.options.map((op, it) => {
								if (
									props.availableOptions.includes(op.selectionId) ||
									op.selectionId === props.selectedOption.selectionId
								) {
									return (
										<MenuItem key={it} value={op.selectionId}>
											yo
											{op.selectionName}
										</MenuItem>
									);
								}
							})}
						</Select>
					</>
				)}
			</FormControl>
		);
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
		>
			{props.multiLine && (
				<Grid item xs={canEdit ? 10 : 12} container spacing={2}>
					<Grid item xs={12}>
						{renderSelection()}
					</Grid>
					<Grid item xs={12} container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
						<NewInputGroupItemChildren
							selectedOption={props.selectedOption}
							childrenSettings={props.childrenSettings}
							onReset={onReset}
							onStoreValue={storeValue}
							view={props.view}
						/>
					</Grid>
				</Grid>
			)}
			{!props.multiLine && (
				<>
					<Grid item xs={3}>
						{renderSelection()}
					</Grid>
					<Grid
						item
						xs={canEdit ? 7 : 9}
						container
						spacing={2}
						sx={{ display: 'flex', alignItems: 'center' }}
						display='flex'
					>
						<NewInputGroupItemChildren
							selectedOption={props.selectedOption}
							childrenSettings={props.childrenSettings}
							onReset={onReset}
							onStoreValue={storeValue}
							view={props.view}
						/>
					</Grid>
				</>
			)}
			{canEdit && (
				<Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
					<AnimatedIconButton
						text='operations.delete'
						translate
						colorType='error'
						icon={<Delete />}
						onClick={() => props.onDelete(props.id)}
					/>
				</Grid>
			)}
		</Grid>
	);
};
