import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useTask } from '@contexts/index';
import useProducts from '@hooks/useProducts';
import { Grid, TextField } from '@mui/material';
import { OrderProductResponse } from 'common';

import { ModifyOrderFieldListItem } from '../../common/ModifyOrderFieldListItem';
import { ModifyOrderFieldValue } from '@contexts/taskContext/config/modifyOrderFieldConfig';
import AdvancedDatePicker from '@components/common/inputs/common/AdvancedDatePicker';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';

interface Props {
	iterator?: number;
	content: OrderProductResponse;
	setValuesInContext: (values: ModifyOrderFieldValue) => void;
	values: ModifyOrderFieldValue;
}

/**
 *
 * @param {Props} props
 * @returns {JSX.Element | null}
 */
export const InboundModifyOrderFieldItem = ({
	iterator,
	content,
	setValuesInContext,
	values,
}: Props): JSX.Element | null => {
	const { taskState } = useTask();
	const { variantList } = useProducts();
	const { dateFormat } = useLocationTimeZone();

	const variant = variantList.find((v) => v.id === content?.variantId);

	if (!variant) {
		return null;
	}

	const isVariable = variant.variable;

	return (
		<ModifyOrderFieldListItem
			content={content}
			iterator={iterator ?? -1}
			values={values}
			forceEdit={taskState === 'in_progress' && !values?.expirationDate}
		>
			<Grid container display='flex' gap={1} p={1}>
				<InputGridField flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
					<TextField
						type='number'
						size='small'
						fullWidth
						label='数量'
						sx={{
							backgroundColor: 'white',
						}}
						disabled={taskState !== 'in_progress' && taskState !== 'stub'}
						value={values?.quantity ?? content.orderQty ?? 0}
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							const oldValues = values;
							setValuesInContext({ ...oldValues, quantity: newValue });
						}}
					/>
				</InputGridField>
				{isVariable && (
					<InputGridField flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
						<TextField
							type='number'
							size='small'
							fullWidth
							label='総重量'
							sx={{
								backgroundColor: 'white',
							}}
							disabled={taskState !== 'in_progress' && taskState !== 'stub'}
							value={values?.totalWeight ?? (content.orderQty ?? 1) * (variant.weight ?? 1)}
							onChange={(e) => {
								const newValue = parseInt(e.target.value);
								const oldValues = values;
								setValuesInContext({ ...oldValues, totalWeight: newValue });
							}}
						/>
					</InputGridField>
				)}
				<Grid
					item
					flexGrow={1}
					flexBasis={0}
					sx={{
						display: 'flex',
						justifyContent: 'right',
					}}
				>
					{/** TODO: When language changes we should change the displayed date too */}
					<AdvancedDatePicker
						isPopup
						value={values?.expirationDate ?? null}
						onChange={(newValue) => {
							const oldValues = values;
							setValuesInContext({ ...oldValues, expirationDate: newValue });
						}}
						disablePast
						inputFormat={dateFormat}
						renderInput={(params) => <TextField {...params} error={!values?.expirationDate} />}
						// TODO: we'll have to work with zIndexes later on
						PopperProps={{
							sx: { zIndex: 9999 },
						}}
						disabled={taskState !== 'in_progress' && taskState !== 'stub'}
					/>
				</Grid>
			</Grid>
		</ModifyOrderFieldListItem>
	);
};
