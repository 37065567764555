import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCustomerTypes from '@hooks/useCustomerTypes';
import useCustomers from '@hooks/useCustomers';
import { TextField } from '@mui/material';
import { CustomerResponse } from 'common';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CompanyCode = () => {
	const { control, watch, formAction } = useGenericForm<CustomerResponse>();
	const { t } = useTranslation();
	const { customerTypesList } = useCustomerTypes();
	const { customerList } = useCustomers();
	const [cachedParent, setCachedParent] = useState<CustomerResponse | undefined>(undefined);

	const canEdit = formAction !== 'view';
	const typeId = watch('typeId');
	const parentId = watch('parentId');

	useEffect(() => {
		if (parentId) {
			const parent = customerList.find((ct) => ct.id === parentId);
			if (parent) {
				setCachedParent(parent);
			}
		}
	}, [parentId]);

	let element: JSX.Element = <></>;
	if (typeId) {
		const customerType = customerTypesList.find((ct) => ct.id === typeId);
		if (customerType?.canHaveChildren) {
			element = (
				<Controller
					name='companyCode'
					control={control}
					render={({ field }) => (
						<InputGridField width={6} type={ComponentType.TextField}>
							<TextField
								variant='outlined'
								id='company-code'
								fullWidth
								size='small'
								label={t('customer.companyCode')}
								value={field.value ?? ''}
								disabled={!canEdit}
								onChange={(ev) => field.onChange(ev.target.value ?? '')}
							/>
						</InputGridField>
					)}
				/>
			);
		}
		if (customerType?.canHaveParent) {
			if (cachedParent) {
				element = (
					<InputGridField width={6} type={ComponentType.TextField}>
						<TextField
							variant='outlined'
							id='company-code'
							size='small'
							disabled
							label={t('customer.parentCompanyCode')}
							value={cachedParent.companyCode ?? ''}
						/>
					</InputGridField>
				);
			}
		}
	}
	return element;
};
