import { Avatar, Grid, Typography } from '@mui/material';
import { SkuChip } from '../chips/SkuChip';
import { QuantityChip } from '../chips/QuantityChip';
import { WeightChip } from '../chips/WeightChip';
import ExpiresAtChip from '../chips/ExpiresAtChip/ExpiresAtChip';
import useProducts from '@hooks/useProducts';
import { AppFunction, TranslationTypes, WarehouseInventoryResponse } from 'common';
import useLocale from '@hooks/useLocale';
import { useMemo } from 'react';
import useImages from '@hooks/useImages';
import { LocationRow } from '@components/orders/suborder/common/LocationRow';

interface Props {
	inventoryItem: WarehouseInventoryResponse;
}

export const InventoryItemBanner = ({ inventoryItem }: Props): JSX.Element | null => {
	const { variantList, productList } = useProducts();
	const { imageList } = useImages();
	const { getTranslatedString } = useLocale();

	const variant = inventoryItem?.variant;

	const imageUrl = useMemo(() => {
		let image = imageList.find((image) => image.id === variant?.images?.[0]?.id ?? -1);
		if (!image) {
			image = imageList.find(
				(image) =>
					image.id === productList.find((p) => p.id === variant?.productId)?.images?.[0]?.id ?? -1,
			);
		}

		return image?.url ?? '';
	}, [inventoryItem, variantList, productList, imageList]);

	if (!variant) {
		return null;
	}

	return (
		<Grid
			xs={12}
			item
			container
			display='flex'
			columnGap={1}
			rowGap={2}
			sx={{
				margin: '0 0 0 1rem',
				padding: '1rem 1rem 1rem 0',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderRadius: '1rem',
				boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
			}}
		>
			<Grid xs={12} item>
				<LocationRow locationId={inventoryItem.warehouseId} />
			</Grid>
			<Grid item display='flex' alignItems='center'>
				<Avatar
					src={imageUrl}
					imgProps={{
						style: {
							objectFit: 'cover',
						},
					}}
				/>
			</Grid>
			<Grid
				item
				sx={{
					position: 'relative',
					display: 'flex',
					gap: '0.5rem',
					alignItems: 'center',
				}}
				flexGrow={1}
			>
				<SkuChip {...variant} />
				<Typography display='flex' alignItems='center' height='100%'>
					{getTranslatedString(AppFunction.Product, variant.productId ?? -1, TranslationTypes.name)}
				</Typography>
				<Typography
					display='flex'
					alignItems='center'
					height='100%'
					sx={{
						flexGrow: 1,
						flexBasis: 0,
					}}
				>
					{getTranslatedString(
						AppFunction.Variant,
						inventoryItem.variantId ?? -1,
						TranslationTypes.name,
					)}
				</Typography>
				<QuantityChip {...inventoryItem} />
				<WeightChip {...inventoryItem} />
				<ExpiresAtChip {...inventoryItem} />
			</Grid>
			{/* <Grid item flexGrow={1} flexBasis={0}>
                {content.notes}
            </Grid> */}
		</Grid>
	);
};
