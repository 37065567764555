import { CombinedIcon } from '@components/common/CombinedIcon';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';
import { CalendarMonth, Edit } from '@mui/icons-material';
import { Chip, ChipProps, SxProps, Theme, Tooltip } from '@mui/material';
import { AppFunction } from 'common';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @description The properties of the expiration date chip
 * @property {Date | null | undefined} [expiresAt] - The expiration date of the product
 * @property {Date | null | undefined} [actualExpiration] - The actual expiration date of the product (if it was modified)
 */
interface ExpirationProps {
	expiresAt?: string | Date | null;
	actualExpiration?: string | Date | null;
	sx?: SxProps<Theme>;
}

export const SingleChip = ({ expiresAt, actualExpiration, sx }: ExpirationProps) => {
	const [calendarColor, setCalendarColor] = useState<ChipProps['color']>();
	const { timeZone, dateFormat } = useLocationTimeZone();
	const expirationDate = actualExpiration || expiresAt;
	const { t } = useTranslation();

	useEffect(() => {
		if (!expirationDate) return;

		const exp = new Date(expirationDate);
		const now = new Date();
		let tempColor: ChipProps['color'] = 'default';

		if (isNaN(exp.getTime())) {
			console.error('Invalid expiration date');
			return;
		}

		const diffInMilliseconds = exp.getTime() - now.getTime();
		const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

		if (diffInMilliseconds < 0) {
			tempColor = 'error';
		} else if (diffInDays < 2) {
			tempColor = 'warning';
		} else if (diffInDays < 7) {
			tempColor = 'info';
		} else {
			tempColor = 'success';
		}

		setCalendarColor(tempColor);
	}, [expirationDate]);

	if (!expirationDate) return null;

	const modified = actualExpiration && actualExpiration !== expiresAt;

	return (
		<Tooltip
			title={`${t(`${AppFunction.Inventory}.originalExpiration`)}: ${moment(expiresAt)
				.tz(timeZone)
				.format(dateFormat)}`}
			disableFocusListener={!modified}
			disableHoverListener={!modified}
			disableTouchListener={!modified}
			placement='left'
			color={calendarColor}
			disableInteractive
		>
			<Chip
				label={moment(expirationDate).tz(timeZone).format(dateFormat)}
				color={calendarColor}
				icon={
					modified ? (
						<CombinedIcon
							main={<CalendarMonth />}
							sub={<Edit />}
							subSx={{ fontSize: '0.5em' }}
							fontSize='1.3rem'
						/>
					) : (
						<CalendarMonth />
					)
				}
				variant={modified ? 'outlined' : undefined}
				size='small'
				sx={{
					'& .MuiChip-label': {
						flexGrow: 1,
						display: 'flex',
						justifyContent: 'center',
					},
					display: 'flex',
					cursor: modified ? 'help' : 'default',
					...sx,
				}}
			/>
		</Tooltip>
	);
};
