import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	AccruedWarehouseInventoriesResponse,
	AffectedRowsResponse,
	GenericIdRequest,
	WarehouseInventoriesResponse,
} from 'common';
import _ from 'lodash';
import { doDeleteInventoryProduct, doFetchAllInventoryStates } from '../store/inventory';
import { PayloadAction } from '@reduxjs/toolkit';
import { WarehouseProductDeleteRequest } from 'common/interfaces/inventorystate';
import moment from 'moment';

function useInventory() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.inventoryState.op_status);
	const inventorySlice = useAppSelector((state) => state.inventoryState.inventoryStates);

	const [inventories, setInventories] = useState<{ [key: number]: WarehouseInventoriesResponse }>(
		{},
	);
	const [accruedInventory, setAccruedInventory] = useState<AccruedWarehouseInventoriesResponse>([]);

	useEffect(() => {
		setInventories((oldInventories) => {
			if (!_.isEqual(oldInventories, inventorySlice)) {
				return inventorySlice;
			}
			return oldInventories;
		});

		setAccruedInventory((oldAccruedInventory) => {
			const newAccruedInventory = Object.values(inventorySlice)
				.flatMap((item) => item)
				.reduce<AccruedWarehouseInventoriesResponse>((acc, item) => {
					const existingItem = acc.find((i) => i.variantId === item.variantId);
					if (existingItem) {
						existingItem.quantity += item.quantity;
						existingItem.remainingWeight =
							(existingItem.remainingWeight ?? 0) + (item.remainingWeight ?? 0);
						if (!existingItem.warehousesId.includes(item.warehouseId)) {
							existingItem.warehousesId.push(item.warehouseId);
						}
						if (item.sourceId && !existingItem.sourceIds?.includes(item.sourceId)) {
							existingItem.sourceIds?.push(item.sourceId);
						}
						const orderProduct = item.orderProduct;
						if (
							orderProduct &&
							!existingItem.orderProducts?.find((op) => op.id === orderProduct.id)
						) {
							if (!existingItem.orderProducts) {
								existingItem.orderProducts = [];
							}
							existingItem.orderProducts.push(orderProduct);
						}
						if (item.actualExpiration) {
							const currentMinExpiration = existingItem.actualExpiration
								? moment.min(
										moment(existingItem.actualExpiration[0]),
										moment(item.actualExpiration),
								  )
								: moment(item.actualExpiration);

							const currentMaxExpiration = existingItem.actualExpiration
								? moment.max(
										moment(existingItem.actualExpiration[1]),
										moment(item.actualExpiration),
								  )
								: moment(item.actualExpiration);

							existingItem.actualExpiration = [
								currentMinExpiration.toDate(),
								currentMaxExpiration.toDate(),
							];
						}
						if (item.expiresAt) {
							const currentMinExpiration = existingItem.expiresAt
								? moment.min(moment(existingItem.expiresAt[0]), moment(item.expiresAt))
								: moment(item.expiresAt);

							const currentMaxExpiration = existingItem.expiresAt
								? moment.max(moment(existingItem.expiresAt[1]), moment(item.expiresAt))
								: moment(item.expiresAt);

							existingItem.expiresAt = [
								currentMinExpiration.toDate(),
								currentMaxExpiration.toDate(),
							];
						}
					} else {
						acc.push({
							...item,
							expiresAt: [item.expiresAt, item.expiresAt],
							actualExpiration: item.actualExpiration
								? [item.actualExpiration, item.actualExpiration]
								: null,
							warehousesId: [item.warehouseId],
							orderProducts: item.orderProduct ? [item.orderProduct] : [],
						});
					}

					return acc;
				}, []);

			if (!_.isEqual(oldAccruedInventory, newAccruedInventory)) {
				return newAccruedInventory;
			}
			return oldAccruedInventory;
		});
	}, [inventorySlice]);

	const deleteInventoryProduct = async (
		data: GenericIdRequest,
	): Promise<AffectedRowsResponse | null> => {
		const warehouseProduct = findInventoryEntryById(+data.id);
		if (!warehouseProduct) {
			return null;
		}
		const deleteRequest: WarehouseProductDeleteRequest = {
			variantId: warehouseProduct.variantId,
			expiresAt: warehouseProduct.expiresAt,
			orderId: warehouseProduct.orderProduct?.id ? +warehouseProduct.orderProduct.id : -1,
			warehouseId: warehouseProduct.warehouseId,
			quantity: warehouseProduct.quantity,
			estimated: false,
			sourceOrderPrductId: warehouseProduct.id ?? -1,
		};

		// TODO: maybe validate the schema

		const response = (await dispatch(
			doDeleteInventoryProduct(deleteRequest),
		)) as PayloadAction<AffectedRowsResponse>;

		if (response.type === 'inventoryState/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	// TODO: this is here temporarily, remove it when the inventory is properly implemented
	const fetchShit = () => dispatch(doFetchAllInventoryStates());

	function findInventoryEntryById(targetId: number) {
		for (const key of Object.keys(inventories)) {
			const castedKey = Number(key);
			const warehouseInventory = inventories[castedKey].find(
				(inventory) => inventory.orderProduct?.id === targetId,
			);
			if (warehouseInventory) {
				return warehouseInventory;
			}
		}
		return undefined;
	}

	function findAllInventoryEntriesByVariantId(variantId: number) {
		const allInventories: WarehouseInventoriesResponse = Object.values(inventories).flat();
		return allInventories.filter((inventory) => inventory.variantId === variantId);
	}

	return {
		opStatus,
		inventories,
		accruedInventory,
		fetchShit,
		findInventoryEntryById,
		deleteInventoryProduct,
		findAllInventoryEntriesByVariantId,
	};
}

export default useInventory;
