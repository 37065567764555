import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import { CustomerBranches } from '@components/customers/CustomerBranches';
import { AddressList } from '@components/forms/customers/general/AddressList';
import { DeliveryInfo } from '@components/forms/customers/general/DeliveryInfo';
import { GeneralInfo } from '@components/forms/customers/general/GeneralInfo';
import { InvoicingInfo } from '@components/forms/customers/general/InvoicingInfo';
import { PaymentInfo } from '@components/forms/customers/general/PaymentInfo';
import { PeopleList } from '@components/forms/customers/general/PeopleList';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { CustomerFormContextProvider } from '@contexts/formContext/subContexts/CustomerFormContext';
import useCustomers from '@hooks/useCustomers';
import { AppFunction, BranchType, CustomerResponse, CustomerSchema, PersonType } from 'common';

import { FocusedElementType } from 'store/app';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';

interface Props {
	formAction: FormAction;
}

export const CustomerForm = ({ formAction }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { customerList, createCustomer, editCustomer } = useCustomers();

	const [formValues, setFormValues] = useState<CustomerResponse>({} as CustomerResponse);
	const [id, setId] = useState<number>(-1);

	function onBeforeShowTab(): boolean {
		return id !== -1;
	}

	const setValues = (newId: number) => {
		const customer = customerList.find((c) => c.id === newId);
		if (customer) {
			setFormValues(customer);
		}
	};

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (id !== routeId && routeId !== -1) {
			setValues(id);
		}
	}, [id]);

	useEffect(() => {
		const newValues = customerList.find((c) => c.id === id);
		if (newValues && !_.isEqual(newValues, formValues)) {
			setFormValues(newValues);
		}
	}, [customerList]);

	return (
		<FormContextProvider
			appFunction={AppFunction.Customer}
			createFunction={createCustomer}
			editFunction={editCustomer}
			renderType={FormRenderType.standalone}
			entityId={routeId}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			useFab
			sideFabs={[
				FormSidefabType.Image,
				FormSidefabType.Info,
				FormSidefabType.Address,
				FormSidefabType.Notes,
			]}
			formComponent={CustomerFormContextProvider}
			validationSchema={CustomerSchema}
			defaultValues={{ id: routeId ?? -1 } as CustomerResponse}
		>
			<TabbedElement title='customer.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
			<TabbedElement title='customer.contacts' onBeforeShowTab={onBeforeShowTab} hideEditButton>
				<PeopleList entityType={PersonType.client} />
			</TabbedElement>
			<TabbedElement title='customer.addresses' onBeforeShowTab={onBeforeShowTab} hideEditButton>
				<AddressList
					chips={[
						{
							label: 'fiscal',
							id: formValues?.fiscalAddressId ?? -1,
						},
						{
							label: 'physical',
							id: formValues?.physicalAddressId ?? -1,
						},
						{
							label: 'delivery',
							id: formValues?.deliveryAddressId ?? -1,
						},
						{
							label: 'details',
							id: formValues?.detailsAddressId ?? -1,
						},
					]}
				/>
			</TabbedElement>
			<TabbedElement title='customer.deliveryInfo' onBeforeShowTab={onBeforeShowTab}>
				<DeliveryInfo />
			</TabbedElement>
			<TabbedElement
				title='customer.invoicingInfo'
				onBeforeShowTab={onBeforeShowTab}
				scopes={[FocusedElementType.invoicingtime]}
			>
				<InvoicingInfo />
			</TabbedElement>
			<TabbedElement title='customer.payments' onBeforeShowTab={onBeforeShowTab}>
				<PaymentInfo />
			</TabbedElement>
			<TabbedElement
				title='customer.directDelivery'
				onBeforeShowTab={onBeforeShowTab}
				hideEditButton
			>
				<CustomerBranches
					customerId={id}
					branchTypeOptions={[BranchType.subCustomer]}
					view={formAction === 'view'}
				/>
			</TabbedElement>
		</FormContextProvider>
	);
};
