import { LooksOne, Scale } from '@mui/icons-material';
import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { AppFunction } from 'common';
import { useTranslation } from 'react-i18next';
import { CombinedIcon } from '../CombinedIcon';

interface WeightChipProps {
	weight?: number | null;
	remainingWeight?: number | null;
	estimated?: boolean | null;
	singleWeight?: boolean | null;
	sx?: SxProps<Theme>;
}

export const WeightChip = ({
	weight,
	remainingWeight,
	estimated,
	singleWeight,
	sx,
}: WeightChipProps): JSX.Element | null => {
	const { t } = useTranslation();

	if (!weight && !remainingWeight) {
		return null;
	}

	const getLabel = (): string => {
		const response = remainingWeight ?? weight ?? 0;
		if (response < 1000) {
			return `${response}g`;
		}
		return `${(response / 1000).toLocaleString()}Kg`;
	};

	return (
		<Tooltip
			title={t(`${AppFunction.Inventory}.estimatedWeight`)}
			disableFocusListener={!estimated}
			disableHoverListener={!estimated}
			disableTouchListener={!estimated}
			disableInteractive
			placement='left'
			PopperProps={{
				disablePortal: true,
			}}
		>
			<Chip
				size='small'
				label={getLabel()}
				color={estimated ? 'warning' : 'default'}
				icon={
					singleWeight ? (
						<CombinedIcon main={<Scale />} sub={<LooksOne />} subSx={{ fontSize: '1rem' }} />
					) : (
						<Scale />
					)
				}
				sx={{
					...sx,
					display: 'flex',
					flexDirection: 'row',
					gap: '0.2rem',
					'&:after': estimated
						? {
								content: '"*"',
								display: 'inline-block',
								fontSize: '10px',
								fontWeight: 'bold',
								transform: 'translate(-0.5rem, -0.3rem)',
						  }
						: {},
					cursor: estimated ? 'help' : 'default',
				}}
			/>
		</Tooltip>
	);
};
