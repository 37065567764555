import { QrCode } from '@mui/icons-material';
import { Chip, SxProps, Theme } from '@mui/material';

interface SkuProps {
	sku?: string;
	orderProductId?: number;
	sx?: SxProps<Theme>;
}

export const SkuChip = <T extends SkuProps>({ sku, orderProductId, sx }: T): JSX.Element => {
	return (
		<Chip
			label={sku}
			size='small'
			color={orderProductId ? 'default' : 'error'}
			icon={<QrCode />}
			sx={sx}
		/>
	);
};
