import { Close, Menu } from '@mui/icons-material';
import { Box, Button, IconButton, keyframes, Stack, Tooltip } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface TooltipTool {
	icon?: ReactElement;
	title?: string;
	dontTranslate?: boolean;
	text?: string;
	onClick: () => void;
}

interface Props {
	tools?: TooltipTool[];
	rect?: DOMRect | null;
	disabled?: boolean;
	componentName: string;
	children: ReactElement;
}

const popAnimation = keyframes`
  0% {
    transform: scale(0) translateY(200%);
  }
  50% {
    transform: scale(1.1) translateY(-10%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
`;

export const GridFieldTooltip = ({ tools, rect, disabled, componentName, children }: Props) => {
	const { t } = useTranslation();
	const [currentButton, setCurrentButton] = useState<number | null>(null);
	const [open, setOpen] = useState<boolean>(false);

	return (
		<Box
			display='flex'
			flexDirection='row'
			alignItems='center'
			justifyContent='center'
			onMouseLeave={() => setOpen(false)}
			position='relative'
		>
			<Tooltip
				title={
					<Box
						p='0.5rem 0.5rem 0 0.5rem'
						overflow='hidden'
						sx={{
							position: 'relative',
						}}
					>
						<Stack direction='row' spacing={1} justifyContent='center' alignItems='flex-start'>
							{tools?.map((tool, index) => (
								<Button
									key={index}
									variant='contained'
									size='small'
									onClick={tool.onClick}
									onMouseEnter={() => setCurrentButton(index)}
									onMouseLeave={() => setCurrentButton(null)}
									color='primary'
									sx={{
										transform: open ? 'scale(0) translate(200%)' : 'none',
										animation: `${
											open ? `${popAnimation} 0.3s ease ${index * 0.1}s forwards` : 'none'
										}`,
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										gap: 1,
									}}
								>
									{tool.icon}
									{tool.dontTranslate ? tool.title : t(`tooltips.${componentName}.${tool.title}`)}
								</Button>
							))}
						</Stack>
						{/* <Box
                            sx={{
                                position: 'absolute',
                                bottom: '-1.5rem',
                                backgroundColor: 'gray',
                                width: '100%',
                                zIndex: 2
                            }}
                        >
                            <Typography 
                                variant='caption'
                                color='black'
                            >
                                {
                                    currentButton !== null && (
                                        <>{tools?.[currentButton]?.text}</>
                                    )
                                }
                            </Typography>
                        </Box> */}
					</Box>
				}
				arrow
				placement='top'
				open={open}
				componentsProps={{
					tooltip: {
						sx: {
							transform: 'translateY(0.4rem) !important',
							padding: '0 0 0.3rem 0',
							bgcolor: 'transparent',
							backdropFilter: 'blur(0.5rem)',
							minWidth: rect ? `calc(${rect.width}px - 4rem)` : 'auto',
							borderRadius: 0,
							'& .MuiTooltip-arrow': {
								color: 'black',
							},
							'&:after': {
								content: '""',
								position: 'absolute',
								bottom: 0,
								left: 0,
								width: '100%',
								height: '1px',
								backgroundColor: 'black',
								borderRadius: 'inherit',
							},
						},
					},
					popper: {
						sx: {
							zIndex: '9999 !important',
						},
					},
				}}
			>
				{children}
			</Tooltip>
			<IconButton
				onClick={() => {
					if (disabled) {
						return;
					}
					setOpen(!open);
				}}
				disabled={disabled}
				sx={{
					transform: open ? 'rotate(90deg) scale(0.7)' : 'rotate(0deg) scale(0.8)',
					transition: 'transform 0.3s, background 0.3s',
				}}
			>
				{open ? <Close /> : <Menu />}
			</IconButton>
		</Box>
	);
};
