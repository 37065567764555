import { ArrowRight } from '@mui/icons-material';
import { AppFunction, TranslationTypes, TriggerResponse } from 'common';
import useMerchants from '../../../hooks/useMerchants';
import useLocale from '../../../hooks/useLocale';
import useWarehouses from '../../../hooks/useWarehouses';
import useCustomers from '../../../hooks/useCustomers';
import { Chip } from '@mui/material';
import useAppFunctions from '@hooks/useAppFunctions';
import { ReactElement } from 'react';
import useGroups from '@hooks/useGroups';
import { useTranslation } from 'react-i18next';

interface Props {
	trigger: TriggerResponse;
}

export const ChainListDirectionTemplate = ({ trigger }: Props) => {
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const { merchantList } = useMerchants();
	const { warehouseList } = useWarehouses();
	const { customerList } = useCustomers();
	const { groupList } = useGroups();

	const directionData = (directionType: 'from' | 'to') => {
		const type = directionType === 'from' ? trigger.fromType : trigger.toType;
		const id = directionType === 'from' ? trigger.fromId : trigger.toId;
		let label = '';
		let icon: ReactElement = <></>;
		switch (type) {
			case 'merchant': {
				const merchant = merchantList.find((merchant) => merchant.id === id);
				icon = getIcon(AppFunction.Merchant) ?? <></>;
				if (merchant) {
					label = getTranslatedString(AppFunction.Merchant, merchant?.id, TranslationTypes.name);
				} else if (id === -2) {
					label = `[${t(`appFunctions.${AppFunction.Merchant}`)}] ${t('common.all')}`;
				}
				break;
			}
			case 'warehouse': {
				const warehouse = warehouseList.find((warehouse) => warehouse.id === id);
				icon = getIcon(AppFunction.Location) ?? <></>;
				if (warehouse) {
					label = getTranslatedString(AppFunction.Location, warehouse?.id, TranslationTypes.name);
				} else if (id === -2) {
					label = `[${t(`appFunctions.${AppFunction.Location}`)}] ${t('common.all')}`;
				}
				break;
			}
			case 'customer': {
				const customer = customerList.find((customer) => customer.id === id);
				icon = getIcon(AppFunction.Customer) ?? <></>;
				if (customer && customer.id) {
					label = getTranslatedString(AppFunction.Customer, customer.id, TranslationTypes.name);
				} else if (id === -2) {
					label = `[${t(`appFunctions.${AppFunction.Customer}`)}] ${t('common.all')}`;
				}
				break;
			}
			case 'branch':
				label = 'branch';
				icon = getIcon(AppFunction.CustomerBranch) ?? <></>;
				break;
			case 'entityGroup': {
				const group = groupList.find((group) => group.id === id);
				icon = getIcon(AppFunction.EntityGroup) ?? <></>;
				if (group) {
					label = getTranslatedString(AppFunction.EntityGroup, group.id, TranslationTypes.name);
				} else if (id === -2) {
					label = `[${t(`appFunctions.${AppFunction.EntityGroup}`)}] ${t('common.all')}`;
				}
				break;
			}
			default:
				label = '??';
				break;
		}

		return <Chip label={label} icon={icon} size='small' />;
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{directionData('from')} <ArrowRight /> {directionData('to')}
		</div>
	);
};
