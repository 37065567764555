import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

// Add token to all requests
instance.interceptors.request.use(
	async (config) => {
		const accessToken: string | undefined = Cookies.get('access_token');
		if (accessToken != undefined && config.headers != null) {
			if (config.headers['Authorization'] == null)
				config.headers['Authorization'] = 'Bearer ' + accessToken;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);
export default instance;
