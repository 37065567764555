import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useGeography from '../../hooks/useGeography';
import useAddresses from '../../hooks/useAddresses';
import useFeedbacks from '../../hooks/useFeedbacks';

import { zodResolver } from '@hookform/resolvers/zod';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Chip,
	CircularProgress,
	Grid,
	Stack,
	TextField,
} from '@mui/material';
import {
	AddressCreateSchema,
	AddressRequest,
	AddressResponse,
	AppFunction,
	TranslationTypes,
} from 'common';

import { EditShowGridField } from '../common/EditShowGridField';
import { ContextualDropdownMenu } from '../ContextualDropdownMenu';
import { DeleteItemDialog } from '../DeleteItemDialog';
import { FocusedElementType } from '../../store/app';

import { PayloadAction } from '@reduxjs/toolkit';
import { RegionSelector } from '../RegionSelector';
import { TranslatableAutocompleteWithCallback } from '../TranslatableAutocompleteWithCallback';

import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { ScopedComponent } from '../scopedlayer/ScopedComponent';
import { ScopedShadowComponent } from '../scopedlayer/ScopedShadowComponent';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Cancel, Check } from '@mui/icons-material';

import styles from './AddressComponent.module.css';
import { TabInnerTitle } from '../tabs/TabInnerTitle';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export interface AddressChip {
	label: string;
	id: number;
}

interface Props {
	address: AddressResponse;
	canEdit?: boolean;
	expandedId?: number | null;
	chips?: AddressChip[];
	singleMode?: boolean;
	onStopCreating?: () => void;
	onAfterDelete?: (parentId: number) => void;
	onExpand?: (id: number | null) => void;
	onSingleModeEdit?: (data: AddressRequest) => void;
}

// TODO: destructure props
// TODO: check addresses refresh and shit to avoid unnecessary reloading
// TODO: reference QuickPersonCreate to refactor address creation
export const AddressComponent = (props: Props) => {
	const { t } = useTranslation();
	const { createAddress, editAddress, deleteAddress } = useAddresses();
	const { showSnackBar } = useFeedbacks();
	const { countryList, getAddressByZipCode, parseAddress, tempParsedAddress } = useGeography();

	const [isEditing, setEditing] = useState(props.address.id === -1 ?? false);
	const [expanded, setExpanded] = useState(props.address.id === -1 ?? false);
	const [deleteDialgOpen, setDeleteDialogOpen] = useState(false);
	const [isValidZip, setValidZip] = useState(false);
	const [currentZip, setCurrentZip] = useState('');

	const [loadingAddress, setLoadingAddress] = useState(false);
	const [parsing, setParsing] = useState(false);
	const [strings, setStrings] = useState<string[]>([]);

	const [isSingleMode] = useState(props.singleMode || false);
	const {
		control,
		reset,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<AddressRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: props.address,
		resolver: zodResolver(AddressCreateSchema),
	});

	const currentCountry = watch('countryId');
	const singleModeData = isSingleMode ? watch() : null;

	useEffect(() => {
		if (props.expandedId !== props.address.id) {
			setExpanded(false);
		}
	}, [props.expandedId]);

	useEffect(() => {
		if (!parsing) {
			return;
		}

		if (tempParsedAddress?.regions) {
			setValue(
				'regionId',
				tempParsedAddress.regions[tempParsedAddress.regions.length - 1].region.id,
			);
			setValidZip(false);
			setStrings([]);
		} else {
			setValue('regionId', -1);
			setValidZip(false);
			setStrings([]);
		}
		setValue('address', tempParsedAddress?.address ?? '');
		setValue('number', tempParsedAddress?.number ?? '');
		setParsing(false);
	}, [tempParsedAddress]);

	useEffect(() => {
		setValidZip(currentZip?.length === 7 || currentZip?.length === 8);
	}, [currentZip]);

	useEffect(() => {
		if (!isSingleMode || singleModeData === null) {
			return;
		}
		props.onSingleModeEdit?.(singleModeData);
	}, [singleModeData]);

	const changeExpanded = (toggle: boolean) => {
		setExpanded(toggle);
		if (!toggle) {
			setEditing(false);
			props.onExpand?.(null);
		} else {
			props.onExpand?.(props.address.id ?? null);
		}
	};

	const onStartEdit = () => {
		setEditing(true);
		setExpanded(true);
		props.onExpand?.(props.address.id ?? -1);
	};

	const onStartDelete = () => {
		setDeleteDialogOpen(true);
	};

	// TODO: USE ADDRESSRESPONSE and cast to request when you create
	const onSubmit = async (data: AddressRequest) => {
		if (props.address.id === -1) {
			const res = (await createAddress(data)) as PayloadAction<AddressResponse>;
			if (res.type === 'addresses/create/fulfilled') {
				const resData = res.payload;
				if (resData?.id) {
					showSnackBar({ message: 'new address created', severity: 'success' });
				} else {
					showSnackBar({ message: 'new address created fail', severity: 'error' });
				}
				if (props.onStopCreating) {
					props.onStopCreating();
				}
			}
		} else {
			const editData: AddressResponse = {
				...data,
				id: props.address.id,
			};
			await editAddress(editData);
			showSnackBar('edited');
			setEditing(false);
		}
	};

	// TODO: simplify code
	const onChangeZip = async (data: string) => {
		const zip = data.replace('-', '');
		if (zip.length === 7) {
			setLoadingAddress(true);
			const res = (await getAddressByZipCode(data)) as string[];

			setParsing(true);
			if (res.length === 0) {
				parseAddress('');
			} else if (res.length === 1) {
				parseAddress(res[0]).then((result) => {
					console.log('result', result);
				});
			}
			setStrings(res);
			setLoadingAddress(false);
		}
	};

	const Chips = () => {
		const chips: JSX.Element[] = [];

		props.chips?.map((chip, it) => {
			if (chip.id === props.address.id) {
				const margin = it !== 0 ? '0.3rem' : 0;
				chips.push(
					<Chip
						key={it}
						label={t(`customer.${chip.label}`)}
						size='small'
						sx={{ marginLeft: margin }}
						color='primary'
					/>,
				);
			}
		});

		if (chips.length > 0) {
			return (
				<Grid item xs={12} sx={{ position: 'absolute', top: '-24px', right: '0px' }}>
					{chips}
				</Grid>
			);
		} else {
			return <></>;
		}
	};

	const renderDialog = () => {
		const onDeleteAddress = async (showExtended: boolean) => {
			const idToDelete = props.address.id;
			if (idToDelete) {
				await deleteAddress({ id: +idToDelete });
				showSnackBar('deleted address');
				if (showExtended && props.onAfterDelete) {
					props.onAfterDelete(props.address.id ?? -1);
				}
			}
		};

		let showExtendedDialog = false;

		if (props.address.id) {
			props.chips?.map((chip) => {
				if (props.address.id === chip.id) {
					showExtendedDialog = true;
				}
			});
		}

		const text = showExtendedDialog
			? 'Yo you are trying to delete an address in use. If you delete it the addresses will be reset to none'
			: 'delete address?';

		return (
			<DeleteItemDialog
				title='delete address'
				text={text}
				dialogOpen={deleteDialgOpen}
				onClose={() => setDeleteDialogOpen(false)}
				onAccept={() => onDeleteAddress(showExtendedDialog)}
			/>
		);
	};

	const renderSelectAddressDialog = (strings: string[]) => {
		if (strings.length > 1) {
			return (
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						zIndex: '400',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backdropFilter: 'blur(0.5px)',
					}}
					onClick={() => {
						setStrings([]);
					}}
				>
					<Stack
						spacing={1}
						sx={{
							backgroundColor: 'white',
							padding: '1rem',
							borderRadius: '1rem',
							boxShadow: '1px 1px 14px -2px rgba(0,0,0,0.45)',
						}}
					>
						{strings.map((s, it) => {
							return (
								<Button key={it} onClick={() => parseAddress(s)} variant='contained'>
									〒{s}
								</Button>
							);
						})}
					</Stack>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const padding = useMemo(() => (isSingleMode ? { padding: 0 } : {}), [props.singleMode]);
	const boxShadow = useMemo(() => (isSingleMode ? { boxShadow: 'none' } : {}), [props.singleMode]);

	return (
		<ScopedComponent
			data={{ id: props.address.id ?? -1, type: FocusedElementType.address, zIndex: 201 }}
			title={props.address.id === -1 ? 'operations.create' : 'operations.edit'}
			translateTitle
			active={isEditing && !isSingleMode}
		>
			{!isSingleMode && renderDialog()}
			{renderSelectAddressDialog(strings)}
			<Grid
				container
				spacing={2}
				// TODO: use flex here
				className={`${styles.addressGrid} ${
					props.canEdit && !isEditing ? (expanded ? styles.animateOut : '') : styles.animateIn
				}`}
				sx={padding}
			>
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						zIndex: '400',
						visibility: loadingAddress ? 'visible' : 'hidden',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</div>
				<Grid
					item
					xs={props.canEdit && !isEditing ? 11 : 12}
					container
					spacing={0}
					sx={{ opacity: loadingAddress ? '0.3' : '1' }}
				>
					<ScopedShadowComponent
						elementType={FocusedElementType.address}
						elementId={props.address.id ?? -1}
						disabled={isSingleMode}
					>
						<Accordion sx={{ width: '100%' }} expanded={expanded} style={boxShadow}>
							<AccordionSummary
								expandIcon={isEditing ? <></> : <ExpandMoreIcon />}
								onClick={() => {
									if (!isEditing) {
										changeExpanded(!expanded);
									}
								}}
								sx={{ cursor: isEditing ? 'default !important' : 'pointer', ...padding }}
							>
								<Grid container spacing={1}>
									{Chips()}
									{isSingleMode && (
										<Grid item xs={12}>
											<TabInnerTitle title='address.address' translate size='small' />
										</Grid>
									)}
									<Controller
										name={'shortCut'}
										control={control}
										render={({ field }) => (
											<InputGridField width={isEditing ? 12 : 11} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-shortcut'
													label={t('address.shortCut')}
													fullWidth
													size='small'
													disabled={!isEditing}
													error={!!errors.zip}
													helperText={errors.zip && errors.zip.message}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
								</Grid>
							</AccordionSummary>
							<AccordionDetails sx={padding}>
								<Grid container spacing={2}>
									<Controller
										name={'countryId'}
										control={control}
										render={({ field }) => (
											<InputGridField width={12} type={ComponentType.Autocomplete}>
												<TranslatableAutocompleteWithCallback
													data={countryList}
													value={field.value}
													translationSettings={{
														translatableType: AppFunction.Country,
														translationType: TranslationTypes.name,
														namespace: 'locale',
													}}
													id='countryId'
													label={t('address.country')}
													onChange={(value) => field.onChange(value)}
													onCreateNew={(newName) => console.log(`create new name: ${newName}`)}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'zip'}
										control={control}
										render={({ field }) => (
											<InputGridField width={6} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-zip'
													label={t('address.zip')}
													error={!!errors.zip}
													fullWidth
													size='small'
													helperText={errors.zip && errors.zip.message}
													value={field.value ?? ''}
													onChange={(ev) => {
														field.onChange(ev.target.value ?? '');
														setCurrentZip(ev.target.value ?? '');
													}}
													onKeyDown={(ev) => {
														if (ev.key === 'Enter' && isValidZip) onChangeZip(field.value ?? '');
													}}
													InputProps={{
														endAdornment: (
															<div style={{ overflow: 'hidden', width: '100px' }}>
																<Button
																	variant='contained'
																	size='small'
																	className={
																		isValidZip ? styles.visibleButton : styles.hiddenButton
																	}
																	onClick={() => onChangeZip(field.value ?? '')}
																>
																	{t('operations.search')}
																</Button>
															</div>
														),
													}}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'regionId'}
										control={control}
										render={({ field }) => (
											<RegionSelector
												countryId={currentCountry}
												regionId={field.value}
												isEditing={isEditing}
												onChange={(value) => field.onChange(value)}
											/>
										)}
									/>
									<Controller
										name={'address'}
										control={control}
										render={({ field }) => (
											<InputGridField width={9} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-address'
													fullWidth
													disabled={!isEditing}
													size='small'
													label={t('address.address')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'number'}
										control={control}
										render={({ field }) => (
											<InputGridField width={3} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-number'
													fullWidth
													size='small'
													disabled={!isEditing}
													label={t('address.number')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'building'}
										control={control}
										render={({ field }) => (
											<InputGridField width={12} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-building'
													fullWidth
													size='small'
													disabled={!isEditing}
													label={t('address.building')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'tel'}
										control={control}
										render={({ field }) => (
											<InputGridField width={6} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-tel'
													fullWidth
													size='small'
													disabled={!isEditing}
													label={t('address.tel')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'fax'}
										control={control}
										render={({ field }) => (
											<InputGridField width={6} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-fax'
													fullWidth
													size='small'
													disabled={!isEditing}
													label={t('address.fax')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
									<Controller
										name={'email'}
										control={control}
										render={({ field }) => (
											<InputGridField width={12} type={ComponentType.TextField}>
												<TextField
													variant='outlined'
													id='address-email'
													fullWidth
													size='small'
													disabled={!isEditing}
													label={t('address.email')}
													value={field.value ?? ''}
													onChange={(ev) => field.onChange(ev.target.value ?? '')}
												/>
											</InputGridField>
										)}
									/>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</ScopedShadowComponent>
				</Grid>
				{!isEditing && props.canEdit && (
					<Grid item xs={1}>
						<ContextualDropdownMenu onEdit={onStartEdit} onDelete={onStartDelete} />
					</Grid>
				)}
				{isEditing && !isSingleMode && (
					<>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.save'
								translate
								colorType='success'
								icon={<Check />}
								onClick={handleSubmit(onSubmit)}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								translate
								colorType='error'
								icon={<Cancel />}
								onClick={() => {
									if (props.address.id === -1 && props.onStopCreating) {
										reset();
										props.onStopCreating();
									} else {
										reset(props.address);
										setEditing(false);
									}
								}}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</ScopedComponent>
	);
};
