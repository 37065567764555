import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderEditor } from '@contexts/index';

import { OrderProductResponse } from 'common';

import { Chip, Grid, IconButton, SxProps, TextField, useTheme } from '@mui/material';
import { AddComment, Cancel, CommentsDisabled } from '@mui/icons-material';

import { FadeWrapper } from '@components/common/FadeWrapper';

interface OrderProductRowWrapperProps {
	index: number;
	subOrderCode: string;
	children: ReactNode;
	data: OrderProductResponse;
	hasValue?: boolean;
	hideNotes?: boolean;
	isBooked?: boolean;
	isDivided?: boolean;
	disabled?: boolean;
	hideDelete?: boolean;
	sx?: SxProps;
}

export const OrderProductRowWrapper = ({
	index,
	subOrderCode,
	children,
	data,
	hasValue,
	hideNotes,
	isBooked,
	isDivided,
	disabled,
	hideDelete,
	sx,
}: OrderProductRowWrapperProps): JSX.Element | null => {
	const { deleteVariant, changeOrderProduct } = useOrderEditor();
	const { t } = useTranslation();

	const [highlighted, setHighlighted] = useState(false);
	const [showNotes, setShowNotes] = useState(false);

	const theme = useTheme();

	return (
		<FadeWrapper>
			<Grid
				container
				columnSpacing={2}
				item
				xs={12}
				justifyContent='center'
				flex={'horizontal'}
				sx={{
					position: 'relative',
					'&:before': {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						content: `"${index + 1}"`,
						position: 'absolute',
						left: '0.8rem',
						top: '50%',
						transform: 'translateY(calc(-50% + 0.6rem))',
						borderRadius: '50%',
						backgroundColor: highlighted ? theme.palette.error.main : 'black',
						color: 'white',
						opacity: hasValue ? 0.4 : 0.1,
						width: '1.2rem',
						height: '1.2rem',
					},
					...sx,
				}}
				onContextMenu={(event) => {
					event.preventDefault();
					event.stopPropagation();
					console.log('Right click', event);
				}}
			>
				<Grid
					item
					flexGrow={1}
					flexBasis={0}
					columnGap={1}
					rowGap={2}
					container
					sx={{
						position: 'relative',
						'&:after': {
							content: '""',
							position: 'absolute',
							top: 0,
							left: highlighted ? '1.1rem' : '1.2rem',
							borderRadius: highlighted ? '0.3rem' : '0.5rem',
							width: highlighted ? 'calc(100% - 1rem)' : 0,
							height: '100%',
							backgroundColor: theme.palette.error.light,
							opacity: highlighted ? 0.4 : 0,
							zIndex: 0,
							transition: 'width 0.2s ease-in-out, opacity 0.2s ease-in-out',
							pointerEvents: 'none',
							backdropFilter: 'blur(1rem)',
						},
					}}
				>
					{isBooked && (
						<Grid item flexShrink={1} display='flex' alignItems='center'>
							<Chip size='small' label='予約' color='error' />
						</Grid>
					)}
					{isDivided && (
						<Grid item flexShrink={1} display='flex' alignItems='center'>
							<Chip size='small' label='カット' color='info' />
						</Grid>
					)}
					{children}
					{!hideNotes && showNotes && (
						<Grid item xs={12}>
							<TextField
								id={`order-notes-idx-${index}`}
								fullWidth
								variant='outlined'
								size='small'
								label={t('general.notes')}
								value={data.notes ?? ''}
								onChange={(event) =>
									changeOrderProduct({ notes: event.target.value }, subOrderCode, index)
								}
							/>
						</Grid>
					)}
				</Grid>
				{(!hideNotes || data.notes !== '') && (
					<Grid item xs='auto' display='flex' justifyContent='center' alignItems='center'>
						<IconButton
							onClick={() => {
								setShowNotes(!showNotes);
								if (showNotes) {
									changeOrderProduct({ notes: '' }, subOrderCode, index);
								}
							}}
							sx={{
								position: 'relative',
								maxWidth: '3rem',
								maxHeight: '3rem',
								color: theme.palette.primary.main,
								transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
								transform: 'scale(1)',
								'&:hover': {
									transform: 'scale(1.2)',
								},
							}}
						>
							{showNotes ? <CommentsDisabled color='error' /> : <AddComment color='primary' />}
						</IconButton>
					</Grid>
				)}
				{!hideDelete && (
					<Grid
						item
						xs='auto'
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<IconButton
							onClick={() => deleteVariant(index, subOrderCode)}
							sx={{
								maxWidth: '3rem',
								maxHeight: '3rem',
								color: theme.palette.error.main,
								transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
								transform: 'scale(1)',
								'&:hover': {
									transform: 'scale(1.2)',
								},
							}}
							onMouseEnter={() => setHighlighted(true)}
							onMouseLeave={() => setHighlighted(false)}
						>
							<Cancel />
						</IconButton>
					</Grid>
				)}
			</Grid>
		</FadeWrapper>
	);
};
