import { FormControlLabel, FormGroup, Switch, useTheme } from '@mui/material';
import useGeography from '../../hooks/useGeography';
import useLocale from '../../hooks/useLocale';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppFunction, CountryResponse, GenericIdRequest, TranslationTypes } from 'common';

import { useEffect, useState } from 'react';
import { NestedRegion } from '../../store/geography';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Settings } from '@mui/icons-material';
import { TableContextProvider, useFeedbacks } from '@contexts/index';
import { useTranslation } from 'react-i18next';
import useAppFunctions from '@hooks/useAppFunctions';
import { DropDownActionType } from '@contexts/tableContext/types';

const appFunction = AppFunction.Country;

export const CountryList = () => {
	const { countryList, regionList, deleteCountry, deleteRegion } = useGeography();
	const { getI18NString, renderI18NField } = useLocale();
	const { pushDialog } = useFeedbacks();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { t } = useTranslation();
	const theme = useTheme();

	const [showRegions, setShowRegions] = useState(false);
	const [expandedRegionRows, setExpandedRegionRows] = useState<NestedRegion[]>();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const subRegionsDatatable = (subRegions: NestedRegion[], idx: number) => {
		const recursionIdx = idx + 1;
		const leftSpace = 0.3 * recursionIdx;
		const nameWidth = 20 - 0.3 * recursionIdx;

		return (
			<DataTable
				value={subRegions}
				className='p-datatable-common'
				rowClassName={(data) => {
					if (data.subRegions.length > 0) {
						return '';
					}
					return 'no-expansion sub-icon';
				}}
				rows={10}
				rowsPerPageOptions={[10, 25, 50]}
				expandedRows={expandedRegionRows}
				onRowToggle={(e) => setExpandedRegionRows(e.data)}
				rowExpansionTemplate={(data) => subRowExpansionTemplate(data, recursionIdx)}
				dataKey='id'
				rowHover
				responsiveLayout='scroll'
				size='small'
				globalFilterFields={[]}
				emptyMessage='No regions found.'
				currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
				style={{ fontSize: theme.typography.fontSize, width: '100%' }}
			>
				<Column
					frozen
					style={{ minWidth: `${leftSpace}rem`, maxWidth: `${leftSpace}rem` }}
				></Column>
				<Column expander style={{ width: '4rem', maxWidth: '4rem' }} frozen={true} />
				<Column
					field='name'
					header='Name'
					body={(data) =>
						renderI18NField(getI18NString(AppFunction.Region, data.id, TranslationTypes.name))
					}
					style={{ width: `${nameWidth}rem` }}
				/>
				<Column
					header='Type'
					body={(data) =>
						renderI18NField(
							getI18NString(AppFunction.RegionType, data.regionTypeId, TranslationTypes.name),
						)
					}
				/>
			</DataTable>
		);
	};

	const subRowExpansionTemplate = (data: NestedRegion, recursionIdx: number) => {
		return subRegionsDatatable(data.subRegions ?? [], recursionIdx);
	};

	const rowExpansionTemplate = (data: CountryResponse) => {
		const subRegions = regionList.filter((r) => r.countryId === data.id && r.parentId === null);
		return subRegionsDatatable(subRegions, 0);
	};

	function callDeleteRegionDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteRegion(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	function CallDeleteCountryDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete'),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteCountry(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			values={countryList}
			rowExpansionTemplate={rowExpansionTemplate}
			pageIdentifier={appFunction}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{ type: DropDownActionType.delete, action: CallDeleteCountryDialog },
			]}
			customHeader={
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								defaultChecked={false}
								onChange={(_, v) => {
									setShowRegions(v);
								}}
							/>
						}
						label={t('country.showRegions')}
						sx={{ marginLeft: '15px' }}
					/>
				</FormGroup>
			}
		>
			{showRegions && <Column expander style={{ width: '4rem', maxWidth: '4rem' }} frozen={true} />}
			<Column
				field='name'
				header='name'
				body={(data) =>
					renderI18NField(getI18NString(AppFunction.Country, data.id, TranslationTypes.name))
				}
			/>
			<Column header='type' body='Country' style={{ minWidth: '80%' }} />
		</TableContextProvider>
	);
};
