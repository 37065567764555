import { useTable } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import {
	CloseFullscreen,
	FilterAlt,
	FilterAltOff,
	OpenInFull,
	Settings as SettingsIcon,
} from '@mui/icons-material';
import {
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CsvImporter } from '@components/CsvImporter/CsvImporter';
import useCurrentBreakpoint from '@hooks/useCurrentBreakpoint';

const grow = ['lg', 'xl'];

export const TableHeader = () => {
	const { i18n, t } = useTranslation();
	const breakpoint = useCurrentBreakpoint();
	const {
		pageIdentifier,
		subIdentifier,
		setHiddenColumn,
		toggleFilters,
		toggleExpanded,
		customHeader,
		hiddenColumns,
		hasFilters,
		showFilters,
		expanded,
	} = useTable();

	const { getIcon } = useAppFunctions();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menuItems, setMenuItems] = useState<JSX.Element[]>([]);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleColumn = async (columnName: string) => {
		const oldColumnValue = hiddenColumns?.[columnName];
		setHiddenColumn?.(columnName, !oldColumnValue);
	};

	useEffect(() => {
		const tempMenuItems: JSX.Element[] = [];
		Object.entries(hiddenColumns ?? {}).forEach(([key, value]) => {
			tempMenuItems.push(
				<MenuItem
					key={key}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
					onClick={() => toggleColumn?.(key)}
				>
					{t(key)}
					<Switch id={`${key}-switch`} checked={!value} size='small' />
				</MenuItem>,
			);
		});
		setMenuItems(tempMenuItems);
	}, [i18n.language, hiddenColumns, showFilters, pageIdentifier, subIdentifier]);

	return (
		<div>
			<Grid
				container
				display='flex'
				justifyContent='right'
				alignItems='center'
				padding='0.5rem 1rem'
				gap={1}
			>
				<Grid item order={0} flexGrow={grow.includes(breakpoint) && customHeader ? undefined : 1}>
					<Typography
						sx={{
							flexGrow: customHeader ? 0 : 1,
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							textShadow: '0px 0px 4px rgba(255, 255, 255, 0.5)',
							userSelect: 'none',
						}}
						fontSize='2rem'
					>
						{pageIdentifier &&
							getIcon(pageIdentifier, subIdentifier, undefined, {
								fontSize: '3rem',
								marginRight: '1rem',
								textShadow: '0px 0px 4px rgba(255, 255, 255, 0.5)',
							})}
						{t('general.list', { listElement: t(`appBar.${pageIdentifier}`) })}
					</Typography>
				</Grid>
				{customHeader && (
					<Grid
						item
						order={grow.includes(breakpoint) ? 1 : 2}
						flexGrow={grow.includes(breakpoint) ? 1 : undefined}
						flexBasis={grow.includes(breakpoint) ? 0 : undefined}
						xs={!grow.includes(breakpoint) ? 12 : undefined}
						p={1}
						sx={{
							backgroundColor: 'white',
							borderRadius: '0.5rem',
							margin: '0 0.5rem',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						{customHeader}
					</Grid>
				)}
				<Grid item order={grow.includes(breakpoint) ? 2 : 1}>
					<Stack direction='row' spacing={1}>
						<Tooltip
							title={expanded ? t('operations.contract') : t('operations.expand')}
							placement='top'
							arrow
						>
							<IconButton
								sx={{
									color: '#6200ea',
									backgroundColor: 'white',
									transform: 'scale(1)',
									transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
									'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
								}}
								onClick={() => toggleExpanded?.()}
							>
								{expanded ? <CloseFullscreen /> : <OpenInFull />}
							</IconButton>
						</Tooltip>
						{
							// TODO: this is a shitty hack because I have no time
							// implement a proper customButtons or something like that in the props
							pageIdentifier === 'customer' && <CsvImporter appFunction={pageIdentifier} />
						}
						{hasFilters && (
							<Tooltip
								title={showFilters ? t('operations.hideFilters') : t('operations.showFilters')}
								placement='top'
								arrow
							>
								<div>
									<IconButton
										color={showFilters ? 'success' : 'error'}
										onClick={() => toggleFilters?.()}
										sx={{
											backgroundColor: 'white',
											transform: 'scale(1)',
											transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
											'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
										}}
									>
										{showFilters ? <FilterAlt /> : <FilterAltOff />}
									</IconButton>
								</div>
							</Tooltip>
						)}
						{hiddenColumns && (
							<Tooltip title={t('appBar.settings')} placement='top' arrow>
								<div>
									<IconButton
										onClick={handleClick}
										color='info'
										sx={{
											backgroundColor: 'white',
											transform: 'scale(1)',
											transition: 'transform 0.4s cubic-bezier(0.34, 2, 0.84, 1)',
											'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
										}}
									>
										<SettingsIcon />
									</IconButton>
								</div>
							</Tooltip>
						)}
					</Stack>
				</Grid>
			</Grid>
			<Menu
				id='column-settings-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{menuItems}
			</Menu>
		</div>
	);
};
