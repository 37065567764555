import {
	AffectedRowsResponse,
	GenericIdRequest,
	NoteRequest,
	NoteResponse,
	NotesResponse,
} from 'common';
import { NetworkOperationStatus } from '.';
import * as api from './externalApi/common/apiCalls';
import { createSlice } from '@reduxjs/toolkit';

export interface NoteState {
	notes: NotesResponse;
	op_status: NetworkOperationStatus;
}

const initialState: NoteState = {
	notes: [],
	op_status: 'idle',
};

export const doFetchNotes = api.apiList<NotesResponse>('notes/list', 'notes');

export const doCreateNote = api.apiCreate<NoteRequest, NotesResponse>('notes/create', 'notes');

export const doEditNote = api.apiEdit<NoteResponse, AffectedRowsResponse>('notes/edit', 'notes');

export const doDeleteNote = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'notes/delete',
	'notes',
);

export const noteState = createSlice({
	name: 'noteState',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) =>
				[
					doFetchNotes.pending.type,
					doCreateNote.pending.type,
					doEditNote.pending.type,
					doDeleteNote.pending.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'pending';
			},
		);

		builder.addMatcher(
			(action) =>
				[
					doFetchNotes.fulfilled.type,
					doCreateNote.fulfilled.type,
					doEditNote.fulfilled.type,
					doDeleteNote.fulfilled.type,
				].includes(action.type),
			(state, action) => {
				state.op_status = 'succeeded';
				if (action.type === 'notes/list/fulfilled') {
					state.notes = action.payload;
				}
			},
		);

		builder.addMatcher(
			(action) =>
				[
					doFetchNotes.rejected.type,
					doCreateNote.rejected.type,
					doEditNote.rejected.type,
					doDeleteNote.rejected.type,
				].includes(action.type),
			(state) => {
				state.op_status = 'failed';
			},
		);
	},
});

export default noteState.reducer;
