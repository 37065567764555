import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useCarriers from '../../hooks/useCarriers';
import useFeedbacks from '../../hooks/useFeedbacks';

import {
	AppFunction,
	CarrierDeliveryTimeCreateSchema,
	CarrierDeliveryTimeResponse,
	CarrierDeliveryTimeSchema,
} from 'common';

import { Grid, IconButton } from '@mui/material';
import VectorTwoInput, { Vector2 } from '../VectorTwoInput';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ContextualDropdownMenu } from '../ContextualDropdownMenu';
import { useTranslation } from 'react-i18next';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { Cancel, Save } from '@mui/icons-material';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	time: CarrierDeliveryTimeResponse;
	isCreating?: boolean;
	onCancelCreate?: () => void;
}

export const DeliveryTime = ({
	time,
	isCreating: forceCreate,
	onCancelCreate: onStopCreating,
}: Props) => {
	const { createDeliveryTime, editDeliveryTime, deleteDeliveryTime } = useCarriers();
	const { showSnackBar } = useFeedbacks();
	const { t } = useTranslation();

	const [isEditing, setEditing] = useState(false);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CarrierDeliveryTimeResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: { ...time },
		resolver: zodResolver(CarrierDeliveryTimeSchema),
	});

	const onSubmit = useCallback(
		async (data: CarrierDeliveryTimeResponse) => {
			if (data.id === -1) {
				const createData = CarrierDeliveryTimeCreateSchema.safeParse(data);
				if (createData.success) {
					await createDeliveryTime(createData.data);
					showSnackBar({
						message: t('operations.success', {
							item: t(`appFunctions.${AppFunction.DeliveryTime}`),
							action: t('operations.create'),
						}),
						severity: 'success',
					});
				}
				onStopCreating?.();
			} else {
				await editDeliveryTime(data);
				showSnackBar({
					message: t('operations.success', {
						item: t(`appFunctions.${AppFunction.DeliveryTime}`),
						action: t('operations.edit'),
					}),
					severity: 'success',
				});
				setEditing(false);
			}
		},
		[time],
	);

	const onDelete = async () => {
		await deleteDeliveryTime({ id: time.id });
		showSnackBar('Delivery time deleted');
	};

	const onCancel = () => {
		if (forceCreate) {
			onStopCreating?.();
		} else {
			setEditing(false);
			reset(time);
		}
	};

	const fieldToValue = (value: string): Vector2 | null => {
		const regex = /^(\d+):(\d+):(\d+)$/;
		const match = value.match(regex);

		if (match) {
			const [, num1, num2] = match;
			const result: Vector2 = { x: Number(num1), y: Number(num2) };
			return result;
		}

		return null;
	};

	const valueToField = (field: Vector2): string => {
		const padValue = (num: number): string => (num < 10 ? `0${num}` : `${num}`);
		return `${padValue(field.x)}:${padValue(field.y)}:00`;
	};

	return (
		<Grid
			item
			xs={12}
			sx={{
				marginTop: '1rem',
				marginBottom: '1rem',
			}}
			container
			spacing={2}
			display='flex'
		>
			<Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<AccessTimeIcon />
			</Grid>
			<Controller
				name={'startTime'}
				control={control}
				render={({ field }) => (
					<InputGridField flexGrow={1} type={ComponentType.VectorTwoInput}>
						<VectorTwoInput
							value={fieldToValue(field.value) ?? { x: 0, y: 0 }}
							onChange={(value) => field.onChange(valueToField(value))}
							disabled={!isEditing && !forceCreate}
							label={t('carrier.deliveryTimes.startTime')}
						/>
					</InputGridField>
				)}
			/>
			<Grid
				item
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				~
			</Grid>
			<Controller
				name={'endTime'}
				control={control}
				render={({ field }) => (
					<InputGridField flexGrow={1} type={ComponentType.VectorTwoInput}>
						<VectorTwoInput
							value={fieldToValue(field.value) ?? { x: 0, y: 0 }}
							onChange={(value) => field.onChange(valueToField(value))}
							disabled={!isEditing && !forceCreate}
							label={t('carrier.deliveryTimes.endTime')}
						/>
					</InputGridField>
				)}
			/>
			{isEditing || forceCreate ? (
				<Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
					<IconButton type='submit' onClick={handleSubmit(onSubmit)} color='success'>
						<Save />
					</IconButton>
					<IconButton type='submit' onClick={onCancel} color='error'>
						<Cancel />
					</IconButton>
				</Grid>
			) : (
				!forceCreate && (
					<Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ContextualDropdownMenu onEdit={() => setEditing(true)} onDelete={onDelete} />
					</Grid>
				)
			)}
		</Grid>
	);
};
