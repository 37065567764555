import {
	Children,
	ReactElement,
	ReactNode,
	createContext,
	isValidElement,
	useContext,
	useMemo,
	useState,
} from 'react';

import { useGenericForm } from '@contexts/formContext/FormContext';
import { FormRenderType, FormType } from '@contexts/formContext/types';
import { Box, Stack, Tab, Tabs, Tooltip, useTheme } from '@mui/material';
import { TabbedElement, TabbedElementProps } from '@components/tabs/TabbedElement';
import CheckTextWidth from 'helpers/checkTextWidth';
import { useTranslation } from 'react-i18next';

import { TabPanel } from '@components/tabs/TabPanel';
import { FocusedElementType } from '@store/app';
import { TabheaderBody } from '@components/tabs/contextelements/TabHeaderBody';
import { TabFabContainer } from '@components/tabs/contextelements/TabFabContainer';

import styles from './TabContext.module.css';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';

interface TabElement {
	tabButton: ReactNode;
	tabContent: ReactNode;
	showButton: boolean;
	scopes?: FocusedElementType[];
}

interface TabContextType {
	tabValue: number;
	setTabValue: (value: number) => void;
	editButtonArray: number[];
	setOpenFab?: (value: FormSidefabType | null) => void;
	openFab?: FormSidefabType | null;
}

const defaultContext: TabContextType = {
	tabValue: 0,
	setTabValue: () => console.error('TabContextProvider not found'),
	editButtonArray: [],
};

interface TabContextProps {
	children?: ReactNode;
	footerBody?: JSX.Element;
	hideMenu?: boolean;
	naTooltipText?: string;
}

const TabContext = createContext<TabContextType>(defaultContext);

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const TabContextProvider = ({
	children,
	footerBody,
	hideMenu,
	naTooltipText,
}: TabContextProps) => {
	const { formAction, renderType, formType, setShowFooterButton } = useGenericForm();
	const theme = useTheme();

	const [tabValue, setTabValue] = useState(0);
	const [editButtonArray, setEditButtonArray] = useState<number[]>([]);
	const [openFab, setOpenFab] = useState<FormSidefabType | null>(null);

	const { t } = useTranslation();

	function findTabbedElements(children: ReactNode): ReactElement[] {
		let foundElements: React.ReactElement[] = [];

		Children.forEach(children, (child) => {
			if (isValidElement(child)) {
				if (child.type === TabbedElement) {
					foundElements.push(child);
				} else if (child.props && child.props.children) {
					foundElements = foundElements.concat(findTabbedElements(child.props.children));
				}
			}
		});

		return foundElements;
	}

	const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
		const element = findTabbedElements(children)[newValue];
		if (element.props.onBeforeShowTab && !element.props.onBeforeShowTab()) {
			return;
		}
		const hasEditButton = editButtonArray.includes(newValue);
		setShowFooterButton?.(hasEditButton);
		setTabValue(newValue);
	};

	const tabs = () => {
		if (!children) return null;

		const tabbedElements = findTabbedElements(children);
		let tabsWidth = 0;
		const tabs: TabElement[] = [];
		const haveEditArray: number[] = [];

		tabbedElements.forEach((child, index) => {
			if (!isValidElement<TabbedElementProps>(child)) {
				return;
			}

			const elementProps = child.props as TabbedElementProps;
			const textLengthInRem = CheckTextWidth(t(elementProps.title));
			tabsWidth = Math.max(tabsWidth, textLengthInRem);

			const show = elementProps.onBeforeShowTab ? elementProps.onBeforeShowTab() : true;

			const tabComponent = (
				<Tab
					label={
						show ? (
							t(elementProps.title)
						) : (
							<Tooltip title={t(naTooltipText ?? 'general.not_available')} arrow placement='right'>
								<span>{t(elementProps.title)}</span>
							</Tooltip>
						)
					}
					{...a11yProps(index)}
					key={index}
					disableRipple={!show}
					sx={{
						alignItems: 'flex-end',
						cursor: show ? 'pointer' : 'not-allowed',
						color: show ? 'inherit' : 'gray',
						pointerEvents: tabValue === index ? 'none' : 'auto',
						backgroundColor: tabValue === index ? 'white' : 'transparent',
						width: '100%',
						textAlign: 'right',
						position: 'relative',
						overflow: 'visible',
						flexWrap: 'nowrap',
						'&:after': {
							content: '" "',
							position: 'absolute',
							right: '-20px',
							backgroundColor: 'white',
							width: '20px',
							opacity: tabValue === index ? 1 : 0,
							height: '100%',
							zIndex: 2,
							transition: 'opacity 200ms',
						},
					}}
				/>
			);

			if (!elementProps.hideEditButton) {
				haveEditArray.push(index);
			}

			tabs.push({
				tabButton: tabComponent,
				showButton: show,
				tabContent: elementProps.children,
				scopes: elementProps.scopes,
			});
		});

		setEditButtonArray(haveEditArray);

		// Use the tabs array to render the Tabs component and corresponding TabPanels
		return (
			<>
				{!hideMenu && (
					<Box className={styles.menuContainer}>
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							aria-label='tabbed section'
							orientation='vertical'
							TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
							sx={{ overflow: 'visible', width: `${tabsWidth + 2}rem` }}
						>
							{tabs.map((tab) => tab.tabButton)}
						</Tabs>
					</Box>
				)}
				{tabs.map((tab, index) => (
					<TabPanel
						key={index}
						index={index}
						tabValue={tabValue}
						scopes={tab.scopes ?? []}
						fullScreen={formType === FormType.quick}
					>
						{tab.tabContent}
					</TabPanel>
				))}
			</>
		);
	};
	const memoizedTabs = useMemo(() => tabs(), [children, footerBody, tabValue]);

	const contextValue = {
		tabValue,
		setTabValue,
		editButtonArray,
		setOpenFab,
		openFab,
	};

	return (
		<TabContext.Provider value={contextValue}>
			{renderType !== FormRenderType.popup && formAction !== 'view' && <TabFabContainer />}
			<div
				className={styles.wrapper}
				style={{
					minHeight: '10rem',
					background: theme.palette.gradient.secondary,
					marginBottom: renderType === FormRenderType.popup ? '0px' : '2.5rem',
					height: renderType === FormRenderType.popup ? '100%' : 'auto',
					transition:
						'transform 0.6s cubic-bezier(0.34, 1.2, 0.84, 1), opacity 0.3s cubic-bezier(0.34, 1.2, 0.84, 1)',
					opacity: openFab !== null ? 0.4 : 1,
					transform: openFab !== null ? 'translateX(calc(-100% - 2rem))' : 'translateX(0)',
					pointerEvents: openFab !== null ? 'none' : 'auto',
					zIndex: openFab !== null ? 2 : 1,
				}}
			>
				<TabheaderBody />
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='stretch'
					spacing={0}
					className={styles.container}
					sx={{ overFlow: 'hidden' }}
				>
					{memoizedTabs}
				</Stack>
				<Box className={styles.footer}>{footerBody}</Box>
			</div>
		</TabContext.Provider>
	);
};

export const useTabs = () => useContext(TabContext);
