import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation, getI18n } from 'react-i18next';

import { Box, Breadcrumbs, Chip, SxProps, Theme } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import { Home, NavigateNext } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { TranslatableAppFunction, TranslationTypes } from 'common';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
	const backgroundColor =
		theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800];
	return {
		backgroundColor,
		height: theme.spacing(3),
		color: theme.palette.text.primary,
		fontWeight: theme.typography.fontWeightRegular,
		transition: 'width 450ms ease',
		'&:hover, &:focus': {
			backgroundColor: emphasize(backgroundColor, 0.06),
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(backgroundColor, 0.12),
		},
	};
}) as typeof Chip;

interface TranslationSettings {
	translatableType: TranslatableAppFunction;
	translationType: TranslationTypes;
}

interface Props {
	translate?: TranslationSettings[];
	sx?: SxProps<Theme>;
}

function getIcon(pathName: string): JSX.Element {
	switch (pathName) {
		case 'Settings':
			return <SettingsIcon className='rotate' fontSize='small' />;
		default:
			return <></>;
	}
}

export const RouterBreadcrumbs = (props: Props) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [paths, setPaths] = useState<{ label: string; route: string }[]>([]);

	useEffect(() => {
		const newPaths: { label: string; route: string }[] = [];
		const pathNames = location.pathname.split('/').filter((x) => x);
		let translateIdx = 0;
		pathNames.map((path, index) => {
			const isNumber = !isNaN(+path);
			const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
			let label = '';
			if (isNumber && props.translate) {
				const tempTranslateIdx =
					translateIdx < props.translate?.length ? translateIdx : props.translate?.length - 1;
				label = t(
					`${props.translate[tempTranslateIdx].translatableType}-${path}-${props.translate[tempTranslateIdx].translationType}`,
					{ ns: 'locale', default: '' },
				);
				translateIdx++;
			} else {
				label = t(`breadCrumbs.${path}`);
			}
			newPaths.push({ label: label, route: routeTo });
		});
		setPaths(newPaths);
	}, [location.pathname, getI18n().language, t]);

	return (
		<Box
			sx={{
				marginTop: '15px',
				marginBottom: '15px',
				opacity: paths.length > 0 ? 1 : 0,
				transition: '500ms opacity',
				...props.sx,
			}}
		>
			<Breadcrumbs aria-label='breadcrumbs' separator={<NavigateNext fontSize='small' />}>
				{paths.length > 0 ? (
					<StyledBreadcrumb
						icon={<Home fontSize='small' />}
						onClick={() => navigate('/')}
						label={t('breadCrumbs.dashboard')}
					/>
				) : (
					<StyledBreadcrumb label={t('appBar.dashboard')} sx={{ pointerEvents: 'none' }} />
				)}
				{paths.map((path, index) => {
					const isLast = index === paths.length - 1;
					return isLast ? (
						<StyledBreadcrumb
							icon={getIcon(path.label)}
							key={index}
							label={path.label}
							sx={{ pointerEvents: 'none' }}
						/>
					) : (
						<StyledBreadcrumb
							icon={getIcon(path.label)}
							key={index}
							onClick={() => navigate(path.route)}
							label={path.label}
						/>
					);
				})}
			</Breadcrumbs>
		</Box>
	);
};
