import { useEffect, useRef, useState } from 'react';

import { Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { useInitializeFilters } from './hooks/useInitializeFilters';
import { AuthProvider, useAuth } from './hooks/auth';
import { CookiesProvider } from 'react-cookie';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { initializeDb } from './store/internalApi';
import SignIn from './components/Signin';
import MainAppBar from './components/navigation/MainAppBar';
import FirebaseContext, { defaultFirebaseState, useFirebase } from './store/firebaseContext';
import { Box, LinearProgress, ThemeProvider } from '@mui/material';
import { AppRoutes } from './routes';

import SnackbarHandler from './components/feedbacks/SnackBarHandler';
import { fetchMap } from './store/fetchMap';
import { AppFunction } from 'common';
import { themes } from './styles/themes/themes';
import { doFetchSettings } from './store/app';

import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';
import DeletionDialogHandler from './components/feedbacks/DeletionDialogHandler';
import { LayoutProvider } from './contexts/layoutContext/LayoutContext';
import { BreadCrumbsHandler } from './components/layout/BreadCrumbsHandler';
import { SliderMenuHandler } from './components/layout/SliderMenuHandler';
import { FabsHandler } from '@components/layout/FabsHandler';
import { WebsocketProvider } from '@contexts/websocketContext/WebsocketContext';
import { LocationTimeZoneProvider } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';
import { SubMenuHandler } from '@components/layout/submenu/SubMenuHandler';

enum AuthState {
	Unlogged,
	Loading,
	Ready,
}

const excludeFromInitialFetch: AppFunction[] = [AppFunction.CarrierPrice];

function App() {
	const navigate = useNavigate();
	const location = useLocation();
	useInitializeFilters();

	const [pageState, setPageState] = useState(AuthState.Unlogged);
	const theme = useAppSelector((state) => state.app.theme);

	const { logged, user } = useAuth();

	useFirebase();
	const dispatch = useAppDispatch();
	const lastPathRef = useRef(location.pathname);

	useEffect(() => {
		function handleBeforeUnload() {
			localStorage.setItem('lastPath', lastPathRef.current);
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		lastPathRef.current = location.pathname;
	}, [location.pathname]);

	useEffect(() => {
		if (!user) {
			return;
		}
		const fetchInitialData = async () => {
			setPageState(AuthState.Loading);

			const fetchData = async () => {
				for (const action of Object.keys(fetchMap)) {
					// casting using as, this is not likely the best approach
					// will think about this later
					const fetchAction = action as AppFunction;
					if (!excludeFromInitialFetch.includes(fetchAction)) {
						const dispatcher = fetchMap[fetchAction];
						if (dispatcher) {
							await dispatch(dispatcher());
						}
					}
				}
			};

			await fetchData();
			await dispatch(doFetchSettings(user?.id));

			const lastPath = localStorage.getItem('lastPath');
			if (lastPath) {
				navigate(lastPath);
			}
			setPageState(AuthState.Ready);
		};

		if (logged) {
			initializeDb(user.id)
				.then(() => {
					console.log('db initialized');
				})
				.catch((err) => {
					console.error('error initializing db', err);
				});
			fetchInitialData();
		} else {
			setPageState(AuthState.Unlogged);
		}
	}, [logged, user]);

	useEffect(() => {
		if (pageState === AuthState.Unlogged && location.pathname !== '/') {
			navigate('/');
		}
	}, [location, pageState]);

	return (
		<WebsocketProvider>
			<AuthProvider>
				{pageState === AuthState.Unlogged && <SignIn />}
				{pageState === AuthState.Loading && <LinearProgress />}
				{pageState === AuthState.Ready && (
					<FirebaseContext.Provider value={defaultFirebaseState}>
						<CookiesProvider>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<LocationTimeZoneProvider>
									<ThemeProvider theme={themes[theme]}>
										<Box
											sx={{
												display: 'flex',
												backgroundColor: themes[theme].palette.background.default,
											}}
										>
											<LayoutProvider>
												<MainAppBar />
												<SnackbarHandler />
												<DeletionDialogHandler />
												<Box
													component='main'
													flexGrow={1}
													sx={{
														background: (theme) => theme.palette.gradient.primaryAlpha,
														flexGrow: 1,
														height: 'calc(100vh - 64px)',
														overflow: 'auto',
														marginTop: '64px',
														position: 'relative',
													}}
												>
													<SliderMenuHandler>
														<SubMenuHandler />
														<BreadCrumbsHandler />
														<Routes>{AppRoutes()}</Routes>
													</SliderMenuHandler>
												</Box>
												<FabsHandler />
											</LayoutProvider>
										</Box>
									</ThemeProvider>
								</LocationTimeZoneProvider>
							</LocalizationProvider>
						</CookiesProvider>
					</FirebaseContext.Provider>
				)}
			</AuthProvider>
		</WebsocketProvider>
	);
}

export default App;
