import {
	AppFunction,
	ProductResponse,
	ShipBy,
	TranslationTypes,
	VariantResponse,
	VariantSchema,
} from 'common';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGenericForm } from '../FormContext';
import { FormAction } from '../types';
import useProducts from '@hooks/useProducts';
import { List, ListItem, useTheme } from '@mui/material';
import useLocale from '@hooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { DefaultVariant } from '@components/forms/products/DefaultVariant';
import { Control, FormState, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import i18n from 'i18n';

interface ProductFormContextType {
	formAction: FormAction;
	cachedParent: ProductResponse | null;
	control: Control<VariantResponse>;
	errors: FormState<VariantResponse>['errors'];
}

const defaultContext: ProductFormContextType = {
	formAction: 'create',
	cachedParent: null,
	control: {} as Control<VariantResponse>,
	errors: {},
};

interface ProductFormContextProps {
	children: ReactNode;
	entityId: number;
}

const ProductFormContext = createContext<ProductFormContextType>(defaultContext);

export const ProductFormContextProvider = ({ children }: ProductFormContextProps) => {
	const navigate = useNavigate();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();
	const { createVariant } = useProducts();
	const { entityId, setAfterCreate, formAction, subFunction } = useGenericForm<ProductResponse>();
	const theme = useTheme();
	const { variantList } = useProducts();
	const [cachedParent, setCachedParent] = useState<ProductResponse | null>(null);

	const {
		control,
		getValues,
		formState: { errors },
	} = useForm<VariantResponse>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		// substitute with createEmptyProduct inside useProducts()
		defaultValues: {
			productId: -1,
			id: -1,
			sku: '',
			janCode: '',
			shelfLife: 0,
			shipBy: ShipBy.sea,
			sizePerBox: '',
			sizePerPiece: '',
			purchasePrice: 0,
			purchaseCurrency: 0,
			purchaseUnitWeight: 0,
			sellUnitWeight: 0,
			sellPrice: 0,
			sellCurrency: 1,
			status: 0,
			variable: false,
			unitsPerBox: 1,
			weight: 0,
			currentlySold: true,
		},
		resolver: zodResolver(VariantSchema),
	});

	useEffect(() => {
		setAfterCreate(async (data: ProductResponse): Promise<boolean> => {
			try {
				const request = getValues();
				request.productId = data.id;
				const response = await createVariant(request);
				if (response) {
					return Promise.resolve(true);
				} else {
					return Promise.resolve(false);
				}
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		});
	}, [setAfterCreate]);

	const variants = variantList.filter((v) => v.productId === entityId);

	const formAppendix = useMemo(() => {
		if (entityId === -1) {
			return <DefaultVariant />;
		}
		if (variants && variants.length > 0) {
			return (
				<List>
					{variants.map((variant, idx) => {
						return (
							<ListItem
								key={idx}
								sx={{
									cursor: 'pointer',
									background: theme.palette.gradient.secondaryAlpha,
									borderRadius: '2rem',
									padding: '1rem',
									marginBottom: '1rem',
									boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)',
									transition:
										'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1), box-shadow 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
									'&:hover': {
										background: theme.palette.gradient.secondary,
										transform: 'scale(1.02)',
										boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
									},
								}}
								onClick={() => {
									navigate(
										formAction === 'edit'
											? `/${subFunction}/${entityId}/edit/${variant.id}`
											: `/${subFunction}/${entityId}/${variant.id}`,
									);
								}}
							>
								{getTranslatedString(AppFunction.Variant, variant.id, TranslationTypes.name)}
							</ListItem>
						);
					})}
					<ListItem
						sx={{
							cursor: 'pointer',
							background: theme.palette.gradient.secondaryAlpha,
							borderRadius: '2rem',
							padding: '1rem',
							marginBottom: '1rem',
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)',
							transition:
								'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1), box-shadow 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
							'&:hover': {
								background: theme.palette.gradient.secondary,
								transform: 'scale(1.02)',
								boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
							},
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						onClick={() => {
							navigate(`/${subFunction}/${entityId}/create`);
						}}
					>
						<Add /> {t('operations.createItem', { item: t(`appFunctions.${AppFunction.Variant}`) })}
					</ListItem>
				</List>
			);
		}
		return null;
	}, [variantList, entityId, i18n.language]);

	const contextValue = {
		formAction,
		cachedParent,
		control,
		errors,
	};

	return (
		<ProductFormContext.Provider value={contextValue}>
			{children}
			{formAppendix}
		</ProductFormContext.Provider>
	);
};

export const useProductForm = () => useContext(ProductFormContext);
