import { LocaleEntries, VariantResponse } from 'common';
import { FilterService } from 'primereact/api';
import { useEffect } from 'react';
import useUsers from './useUsers';
import useCustomerTypes from './useCustomerTypes';
import useProducts from './useProducts';
import { Moment } from 'moment';

export enum CustomFilter {
	filterStrings = 'filterStrings',
	filterAdvancedDate = 'filterAdvancedDate',
	filterWarehouseEntry = 'filterWarehouseEntry',
	filterCustomerCode = 'filterCustomerCode',
	filterInternalReferent = 'filterInternalReferent',
	filterCustomerType = 'filterCustomerType',
}

export const useInitializeFilters = () => {
	const { userList } = useUsers();
	const { customerTypesList } = useCustomerTypes();
	const { productList } = useProducts();

	useEffect(() => {
		FilterService.register(
			CustomFilter.filterStrings,
			(a: LocaleEntries | undefined | null, b: string) => {
				if (b === '' || b === null) {
					return true;
				}
				if (a === undefined || a == null) {
					return true;
				}
				return a?.some((str) => str.value.toUpperCase().includes(b.toUpperCase())) || false;
			},
		);
		FilterService.register(CustomFilter.filterCustomerCode, (a: string, b: string) => {
			if (b === '' || b === null) {
				return true;
			}
			return a.replace('-', '').toUpperCase().includes(b.toUpperCase());
		});
		FilterService.register(
			CustomFilter.filterAdvancedDate,
			(a: Moment | undefined | null, b: string) => {
				console.log(a, b);
				return false;
			},
		);
	}, []);

	useEffect(() => {
		FilterService.register(
			CustomFilter.filterWarehouseEntry,
			(a: VariantResponse | undefined | null, b: string) => {
				if (b === '' || b === null) {
					return true;
				}
				if (a === undefined || a == null) {
					return true;
				}
				const someVariant =
					a?.strings?.some((str) => str.value.toUpperCase().includes(b.toUpperCase())) || false;
				const product = productList.find((product) => product.id === a.productId);
				const someProduct =
					product?.strings?.some((str) => str.value.toUpperCase().includes(b.toUpperCase())) ||
					false;
				return someVariant || someProduct;
			},
		);
	}, [productList]);

	useEffect(() => {
		FilterService.register(
			CustomFilter.filterInternalReferent,
			(a: number | null | undefined, b: string) => {
				if (b === '' || b === null) {
					return true;
				}
				if (a === undefined || a === null) {
					return false;
				}
				const user = userList.find((user) => user.id === a);
				if ((user && user.person?.name) || (user && user.person?.surname)) {
					return (
						user.person?.name.toUpperCase().includes(b.toUpperCase()) ||
						user.person?.surname.toUpperCase().includes(b.toUpperCase())
					);
				}
				return false;
			},
		);
	}, [userList]);

	useEffect(() => {
		FilterService.register(
			CustomFilter.filterCustomerType,
			(a: number | null | undefined, b: string) => {
				if (b === '' || b === null) {
					return true;
				}
				if (a === undefined || a === null) {
					return false;
				}

				return (
					customerTypesList
						.find((ct) => ct.id === a)
						?.strings?.some((str) => str.value.toUpperCase().includes(b.toUpperCase())) ?? false
				);
			},
		);
	}, [customerTypesList]);
};
