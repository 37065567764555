import { useLayout } from '@contexts/index';
import { TaskSignal, useMultipleTask } from '@contexts/multipleTaskContext/MultipleTaskContext';
import { Box, Button } from '@mui/material';
import { AppFunction, TaskStatus } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	popupId?: string;
}

export const CloseAllButton = ({ popupId }: Props) => {
	const { t } = useTranslation();
	const { tasks, canCloseAll, sendSignal } = useMultipleTask();
	const { popPopup } = useLayout();

	const handleCloseAllTasks = async () => {
		const result = await sendSignal(TaskSignal.CLOSE_ALL_TASKS);
		if (result) {
			console.log('all tasks closed');
			popupId && popPopup(popupId);
		} else {
			console.log('failed to close all tasks');
		}
	};

	const handleAcceptAllTasks = async () => {
		const result = await sendSignal(TaskSignal.ACCEPT_ALL_TASKS);
		if (result) {
			console.log('all tasks accepted');
		} else {
			console.log('failed to accept all tasks');
		}
	};

	const tasksStatuses = (): TaskStatus | null => {
		if (!tasks) {
			return null;
		}
		const statuses = Object.values(tasks).map((task) => task.status);
		// if all the statueses are the same, return the status
		if (statuses.every((status) => status === statuses[0])) {
			return statuses[0];
		}
		return null;
	};

	return (
		<Box
			sx={{
				padding: '1rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{tasksStatuses() === TaskStatus.pending && (
				<Button onClick={handleAcceptAllTasks} variant='contained'>
					{t(`${AppFunction.Task}.acceptAll`)}
				</Button>
			)}
			{(tasksStatuses() === TaskStatus.inprogress || tasksStatuses() === TaskStatus.stub) && (
				<Button onClick={handleCloseAllTasks} variant='contained' disabled={!canCloseAll}>
					{t(`${AppFunction.Task}.closeAll`)}
				</Button>
			)}
		</Box>
	);
};
