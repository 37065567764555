import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setUserRoles } from '../store/role';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { User } from '../store/user';
import Cookies from 'js-cookie';

const initialAuthState = {
	logged: false,
	user: null as User | null,
};

const AuthContext = createContext(initialAuthState);

export function useAuth() {
	const dispatch = useAppDispatch();
	const auth = useAppSelector((state) => state.user);
	const [authed, setAuth] = useState(initialAuthState);

	useEffect(() => {
		setAuth({
			logged: auth.loggedIn,
			user: auth.userData,
		});
		if (auth.userData) {
			dispatch(setUserRoles(auth.userData.roles));
		}
	}, [auth]);

	return {
		logged: authed.logged,
		user: authed.user,
		logout() {
			Cookies.remove('access_token');
			Cookies.remove('refresh_token');
			Cookies.remove('user');
			setAuth({ logged: false, user: null });
			window.location.reload();
		},
	};
}

export function AuthProvider({ children }: { children: ReactNode }) {
	const auth = useAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
	return useContext(AuthContext);
}
