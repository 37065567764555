import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';
import {
	AddressesByZipResponse,
	AffectedRowsResponse,
	CountriesResponse,
	CountryCreateSchema,
	CountryRequest,
	CountryResponse,
	CountrySchema,
	GenericIdRequest,
	LocaleEntryAction,
	RegionRequest,
	RegionResponse,
	RegionTypeRequest,
	RegionTypeResponse,
	RegionTypesResponse,
	TranslationTypes,
} from 'common';
import {
	NestedRegion,
	doCreateCountry,
	doCreateRegion,
	doCreateRegionType,
	doDeleteCountry,
	doDeleteRegion,
	doDeleteRegionType,
	doEditCountry,
	doEditRegion,
	doSearchByZipCode,
} from '../store/geography';
import { PayloadAction } from '@reduxjs/toolkit';

interface RegionView {
	region: NestedRegion;
	regionType: RegionTypeResponse;
}

export interface ParsedRegionResponse {
	postalCode?: string;
	address?: string;
	name?: string;
	number?: string;
	regions?: RegionView[];
}

function useGeography() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.geography.op_status);
	const countriesSlice = useAppSelector((state) => state.geography.countries);
	const regionsSlice = useAppSelector((state) => state.geography.regions);
	const regionTypesSlice = useAppSelector((state) => state.geography.regionTypes);

	const [countryList, setCountryList] = useState<CountriesResponse>(countriesSlice);
	const [regionList, setRegionList] = useState<NestedRegion[]>(regionsSlice);
	const [regionTypeList, setRegionTypeList] = useState<RegionTypesResponse>(regionTypesSlice);
	const [tempParsedAddress, setTempParsedAddress] = useState<ParsedRegionResponse>();

	useEffect(() => {
		setCountryList((currentList) => {
			if (!_.isEqual(currentList, countriesSlice)) {
				return countriesSlice;
			}
			return currentList;
		});
	}, [countriesSlice]);

	useEffect(() => {
		setRegionList((currentList) => {
			if (!_.isEqual(currentList, regionsSlice)) {
				return regionsSlice;
			}
			return currentList;
		});
	}, [regionsSlice]);

	useEffect(() => {
		setRegionTypeList((currentList) => {
			if (!_.isEqual(currentList, regionTypesSlice)) {
				return regionTypesSlice;
			}
			return currentList;
		});
	});

	// #region Api Calls
	const createCountry = async (data: CountryRequest): Promise<CountryResponse> => {
		const parsedData = CountryCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid country data');
		}
		const response = (await dispatch(
			doCreateCountry(parsedData.data),
		)) as PayloadAction<CountryResponse>;
		if (response.type === 'country/create/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error creating country');
	};

	const editCountry = async (data: CountryResponse): Promise<AffectedRowsResponse> => {
		const parsedData = CountrySchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid country data');
		}
		const response = (await dispatch(
			doEditCountry(parsedData.data),
		)) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'country/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing country');
	};

	const deleteCountry = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteCountry(data));
	};

	const createRegion = async (data: RegionRequest) => {
		return await dispatch(doCreateRegion(data));
	};

	const editRegion = async (data: RegionResponse) => {
		return await dispatch(doEditRegion(data));
	};

	const deleteRegion = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteRegion(data));
	};

	const createRegionType = async (data: RegionTypeRequest) => {
		return await dispatch(doCreateRegionType(data));
	};

	const deleteRegionType = async (data: GenericIdRequest) => {
		return await dispatch(doDeleteRegionType(data));
	};

	const getAddressByZipCode = async (zipCode: string): Promise<string[]> => {
		const res = (await dispatch(
			doSearchByZipCode({ id: zipCode }),
		)) as PayloadAction<AddressesByZipResponse>;

		const addresses: string[] = [];

		if (res.type === 'zipcode/list/fulfilled' && res.payload !== null) {
			res.payload.map((ad) => {
				addresses.push(`${ad.zipcode}${ad.address1}${ad.address2}${ad.address3}`);
			});
		}

		return addresses;
	};
	// #endregion

	const createEmptyRegion = (countryId: number, parentId?: number): RegionResponse => {
		return {
			id: -1,
			countryId: countryId,
			parentId: parentId ?? null,
			regionTypeId: -1,
			strings: [
				{
					langCode: 'en',
					type: TranslationTypes.name,
					value: 'New Region',
					action: LocaleEntryAction.CREATE,
				},
				{
					langCode: 'it',
					type: TranslationTypes.name,
					value: 'Nuova Regione',
					action: LocaleEntryAction.CREATE,
				},
				{
					langCode: 'jp',
					type: TranslationTypes.name,
					value: '新しいリージョン',
					action: LocaleEntryAction.CREATE,
				},
			],
		};
	};

	const getRegionPath = (regionId: number | undefined | null): NestedRegion[] => {
		const tempRegions: NestedRegion[] = [];
		const recursiveRegionParse = (
			list: NestedRegion[],
			regionId: number | undefined,
			path: NestedRegion[] = [],
		): NestedRegion | null => {
			if (!regionId) {
				return null;
			}

			for (const r of list) {
				const currentPath = [...path, r];
				if (r.id === regionId) {
					tempRegions.push(...currentPath);
					return r;
				} else if (r.subRegions) {
					const foundObject = recursiveRegionParse(r.subRegions, regionId, currentPath);
					if (foundObject) {
						return foundObject;
					}
				}
			}

			return null;
		};

		if (regionId) {
			recursiveRegionParse(regionList, regionId, []);
		}

		return tempRegions;
	};

	let tempParsed: ParsedRegionResponse = {};

	// TODO: retire setTempParsedAddress and use the promise resolve instead
	const recursiveParseRegion = (
		processingAddress: string,
		recursionIdx: number,
		region?: NestedRegion,
		callback?: (result: ParsedRegionResponse) => void,
	) => {
		const parseKnown = (list: NestedRegion[]) => {
			let matchedName = '';
			const matchedRegion = list.find((region) => {
				const regionNames = region.strings?.map((str) => str.value);
				const found = regionNames?.some((name) => processingAddress.includes(name));
				if (found) {
					matchedName = regionNames?.find((name) => processingAddress.includes(name)) || '';
				}
				return found;
			});

			if (matchedRegion) {
				const tempRegionType = regionTypeList.find((rt) => rt.id === matchedRegion.regionTypeId);
				const tempRegion = matchedRegion;

				if (tempRegionType) {
					let oldregions: RegionView[] = [];
					if (tempParsed?.regions) {
						oldregions = tempParsed.regions;
					}
					tempParsed = {
						...tempParsed,
						regions: [...oldregions, { region: tempRegion, regionType: tempRegionType }],
					};

					setTempParsedAddress(tempParsed);
				}

				processingAddress = processingAddress.replace(matchedName, '');
				recursiveParseRegion(processingAddress, recursionIdx + 1, matchedRegion, callback);
			} else {
				tempParsed = { ...tempParsed, address: processingAddress };
				setTempParsedAddress((oldRes) => {
					return { ...oldRes, address: processingAddress };
				});

				if (callback) {
					callback(tempParsed);
				}
			}
		};

		if (region) {
			if (region.subRegions && region.subRegions.length > 0) {
				parseKnown(region.subRegions);
			} else {
				const regex = /(\d+-)+\d+/g;
				const undesired = /\b\d{3}-\d{4}\b/;
				const matches = processingAddress.match(regex) || [];
				if (matches) {
					for (let i = 0; i < matches.length; i++) {
						if (undesired.test(matches[i])) {
							matches.splice(i, 1);
							i--;
						}
					}
				}
				tempParsed = { ...tempParsed, number: matches.join(' ') };
				setTempParsedAddress(tempParsed);
				matches.map((m) => (processingAddress = processingAddress.replace(m, '')));
				tempParsed = { ...tempParsed, address: processingAddress };
				setTempParsedAddress(tempParsed);

				if (callback) {
					callback(tempParsed);
				}
			}
		} else {
			console.log(processingAddress);
			parseKnown(regionList);
		}
	};

	/**
	 * Parses a text address and returns a ParsedRegionResponse object
	 * @param {string} tempAddress
	 * @returns {Promise<ParsedRegionResponse>}
	 */
	const parseAddress = (tempAddress: string): Promise<ParsedRegionResponse> => {
		return new Promise<ParsedRegionResponse>((resolve) => {
			tempParsed = {};
			if (tempAddress === '') {
				setTempParsedAddress({});
				resolve({});
			} else {
				let processingAddress = tempAddress;
				const postalCodeRegex = /\b(\d{3}-?\d{4})(?!\d)/g;

				setTempParsedAddress({});

				const postalCodeMatch = postalCodeRegex.exec(processingAddress);
				if (postalCodeMatch) {
					const normalizedPostalCode = postalCodeMatch[1].replace(/-/g, '-');
					setTempParsedAddress((oldRes) => {
						return { ...oldRes, postalCode: normalizedPostalCode };
					});
					tempParsed = { ...tempParsed, postalCode: normalizedPostalCode };
					processingAddress = processingAddress.replace(postalCodeMatch[0], '');
				}
				recursiveParseRegion(processingAddress, 0, undefined, (result) => {
					if (result && ((result.regions?.length ?? 0) > 0 || result.postalCode)) {
						resolve(result);
					} else {
						resolve({});
					}
				});
			}
		});
	};

	return {
		opStatus,
		countryList,
		regionList,
		regionTypeList,
		createCountry,
		editCountry,
		deleteCountry,
		createRegion,
		editRegion,
		deleteRegion,
		createRegionType,
		deleteRegionType,
		createEmptyRegion,
		getRegionPath,
		getAddressByZipCode,
		parseAddress,
		tempParsedAddress,
	};
}

export default useGeography;
