import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/tasks/taskArchetype/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormAction, FormType } from '@contexts/formContext/types';
import useTasks from '@hooks/useTasks';
import { Settings } from '@mui/icons-material';
import { AppFunction, TaskArchetypeResponse, TaskArchetypeSchema } from 'common';

interface Props {
	forcedId?: number;
	formAction: FormAction;
	formType?: FormType;
	onDataSaved?: (data: TaskArchetypeResponse) => void;
	forcedValues?: TaskArchetypeResponse;
}

const TaskArchetypeFormContent = withFormLogic<TaskArchetypeResponse>(() => <></>);

export const TaskArchetypeForm = ({
	forcedId,
	formAction,
	formType,
	onDataSaved,
	forcedValues,
}: Props) => {
	const { taskArchetypesList, createTaskArchetype, editTaskArchetype } = useTasks();

	return (
		<TaskArchetypeFormContent
			forcedId={forcedId}
			formAction={formAction}
			formType={formType}
			list={taskArchetypesList}
			createFunction={createTaskArchetype}
			editFunction={editTaskArchetype}
			defaultValues={
				forcedValues ??
				({
					id: -1,
					name: '',
					assignableTo: {
						users: [],
						roleWorkplaces: [],
					},
					taskFieldArchetypeId: [],
					fieldSets: [],
				} as TaskArchetypeResponse)
			}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			hideMenu
			customAfterCreateLink='settings/taskArchetype'
			appFunction={AppFunction.TaskArchetype}
			onDataSaved={onDataSaved}
			validationSchema={TaskArchetypeSchema}
		>
			{() => (
				<TabbedElement title={`${AppFunction.TaskArchetype}.generalInfo`}>
					<GeneralInfo />
				</TabbedElement>
			)}
		</TaskArchetypeFormContent>
	);
};
