import { useOrderEditor } from '@contexts/index';
import { Controller } from 'react-hook-form';
import { EditShowGridField } from '@components/common/EditShowGridField';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OrderHeaderCode = () => {
	const { control, configValues } = useOrderEditor();
	const { t } = useTranslation();

	if (!configValues?.code || !configValues?.code.isValueOfType<boolean>()) {
		return null;
	}

	return configValues['code'].value === true ? (
		<Controller
			name='code'
			control={control}
			render={({ field }) => (
				<EditShowGridField isEditing={true} width={2}>
					<TextField
						variant='outlined'
						size='small'
						label={t('order.code')}
						value={field.value}
						onChange={(event) => field.onChange(event.target.value)}
					/>
				</EditShowGridField>
			)}
		/>
	) : null;
};
