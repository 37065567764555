import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	value: number | null | undefined;
	view?: boolean;
	onChange: (value: number | null | undefined) => void;
}

type CommissionType = 'ours' | 'theirs' | 'variable';

export const CommissionThreshold = ({ value, view, onChange }: Props) => {
	const { t } = useTranslation();
	const canEdit = view ? !view : true;

	const [minValue, setMinValue] = useState(value);
	const calculateValue = (newValue: number | null | undefined): CommissionType => {
		if (newValue != undefined && newValue !== 0) {
			return 'variable';
		} else if (newValue != undefined) {
			return 'ours';
		}
		return 'theirs';
	};
	const [commissionType, setCommissionType] = useState<CommissionType>(calculateValue(value));

	useEffect(() => {
		const commissionValue = calculateValue(value);
		if (commissionValue !== commissionType) {
			setMinValue(value);
			setCommissionType(commissionValue);
		}
	}, [value]);

	const onValueChange = (type: CommissionType | number | null) => {
		if (typeof type === 'string') {
			setCommissionType(type);
			switch (type) {
				case 'ours':
					setMinValue(0);
					onChange(0);
					break;
				case 'theirs':
					setMinValue(null);
					onChange(null);
					break;
				case 'variable':
					onChange(minValue);
					break;
			}
		} else {
			setMinValue(type);
			onChange(type);
		}
	};

	function RenderCommissionType(): JSX.Element {
		let text = '';
		switch (commissionType) {
			case 'ours':
				text = t('customer.commission.ours');
				break;
			case 'theirs':
				text = t('customer.commission.theirs');
				break;
			case 'variable':
				text = `${t('customer.commission.variable')} (${minValue})`;
				break;
		}

		return <div>{text}</div>;
	}

	return (
		<>
			<Grid item xs={commissionType === 'ours' || commissionType === 'theirs' ? 12 : 8}>
				<FormControl
					fullWidth
					sx={{
						marginTop: '0px',
						marginBottom: '0px',
					}}
				>
					<FormLabel
						id='demo-radio-buttons-group-label'
						style={{
							paddingLeft: '14px',
							fontSize: '0.72em',
							transform: 'translate(0px, -4px)',
							color: 'rgba(0, 0, 0, 0.6)',
							position: 'absolute',
							top: '-7px',
						}}
					>
						{t('customer.commission.payment')}
					</FormLabel>
					{canEdit ? (
						<RadioGroup
							aria-labelledby='demo-radio-buttons-group-label'
							row
							name='radio-buttons-group'
							onChange={(ev) => onValueChange(ev.target.value as CommissionType)}
							sx={{ display: 'flex', alignItems: 'center' }}
							value={commissionType}
						>
							<FormControlLabel
								value='ours'
								control={<Radio />}
								label={t('customer.commission.ours')}
							/>
							<FormControlLabel
								value='theirs'
								control={<Radio />}
								label={t('customer.commission.theirs')}
							/>
							{(commissionType === 'ours' || commissionType === 'theirs') && (
								<FormControlLabel
									value='variable'
									control={<Radio />}
									label={t('customer.commission.variable')}
								/>
							)}
						</RadioGroup>
					) : (
						<div
							style={{
								translate: '-2.5px 0px',
								width: '100%',
								font: 'inherit',
								padding: '14px 16.5px 14px 16.5px',
								boxSizing: 'border-box',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<RenderCommissionType />
						</div>
					)}
				</FormControl>
			</Grid>
			{canEdit && commissionType === 'variable' && (
				<Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						fullWidth
						type='number'
						size='small'
						value={minValue ?? 0}
						inputProps={{ min: 0 }}
						label={t('customer.commission.variable')}
						onChange={(ev) => onValueChange(+ev.target.value)}
					/>
				</Grid>
			)}
		</>
	);
};
