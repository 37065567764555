import { Delete } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import useProducts from '@hooks/useProducts';
import { ConfirmPricesFieldOthers } from '@contexts/taskContext/config/confirmpricesFieldConfig';

interface Props {
	values: ConfirmPricesFieldOthers[];
	setValues: (values: ConfirmPricesFieldOthers[]) => void;
}

export const ConfirmPricesOthers = ({ values, setValues }: Props) => {
	const { currencies } = useProducts();

	return (
		<>
			{values.map((value, index) => {
				return (
					<Grid key={`culett-${index}`} container spacing={2} sx={{ marginBottom: '1rem' }}>
						<Grid item xs={3}>
							<TextField
								label={`${value.name}`}
								fullWidth
								size='small'
								value={value.name}
								onChange={(e) => {
									const newValues = [...values];
									newValues[index].name = e.target.value;
									setValues(newValues);
								}}
							/>
						</Grid>
						<Grid item flexGrow={1}>
							<TextField
								key={index}
								label={`${value.name} Fees`}
								fullWidth
								size='small'
								value={value.value}
								onChange={(e) => {
									const newValue = parseFloat(e.target.value);
									const newValues = [...values];
									newValues[index].value = !isNaN(newValue) ? newValue : 0;
									setValues(newValues);
								}}
								InputProps={{
									inputProps: { min: 0, type: 'number' },
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<FormControl fullWidth size='small'>
								<InputLabel id={`others-currency-label-${index}`}>Currency</InputLabel>
								<Select
									labelId={`others-currency-label-${index}`}
									id={`others-currency-${index}`}
									label='Currency'
									value={value.currency}
									onChange={(event) => {
										const newValues = [...values];
										newValues[index].currency = event.target.value as number;
										setValues(newValues);
									}}
									MenuProps={{ style: { zIndex: 9999 } }}
								>
									{currencies.length > 0 &&
										currencies.map((currency) => (
											<MenuItem key={currency.id} value={currency.id}>
												{currency.currency}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<IconButton
								onClick={() => {
									const newValues = [...values];
									newValues.splice(index, 1);
									setValues(newValues);
								}}
							>
								<Delete />
							</IconButton>
						</Grid>
					</Grid>
				);
			})}
			<Button
				variant='contained'
				color='primary'
				fullWidth
				onClick={() => {
					const newValues = [...values, { name: 'new fee', value: 0, currency: 1 }];
					setValues(newValues);
				}}
			>
				add Fees
			</Button>
		</>
	);
};
