import API from '../index';
import { CurrenciesResponse } from 'common';

export const execute = async (): Promise<CurrenciesResponse> => {
	const response = await API.get('/api/v1/inventory/currencies');
	if (response.status !== 200 && response.status !== 404) {
		throw new Error(response.data);
	}
	const data = (await response.data.payload) as CurrenciesResponse;
	return data;
};
