import { useLayout, useTask } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useOrders from '@hooks/useOrders';
import { Box, Button, List, Typography } from '@mui/material';
import { AppFunction, DocumentType, TranslationTypes } from 'common';
import { useEffect, useState } from 'react';
import { PickingFieldListItem } from './PickingFieldListItem';
import { PictureAsPdf } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { DownloadPdfDialog } from '@components/dialogs/DownloadPdfDialog';
import { execute } from '@store/externalApi/io/apiDownloadPdf';

interface Props {
	taskFieldId: number;
	orderId: number;
}

export const PickingField = ({ taskFieldId, orderId }: Props) => {
	const { getTranslatedString } = useLocale();
	const { setTaskFieldValues, getTaskFieldValues, taskState } = useTask();
	const { pushPopup, popLoadingData, pushLoadingData } = useLayout();
	const { t } = useTranslation();
	const { pushComponentDialog } = useFeedbacks();
	const { orderList } = useOrders();

	const [values, setValues] = useState<{
		[key: number]: {
			picked: boolean;
			pickedQty: number;
			pickedOldWeight?: number;
			pickedWeight?: number;
			shorted?: boolean;
		};
	}>({});

	const order = orderList.find((order) => order.id === orderId);

	useEffect(() => {
		if (order) {
			const newValues: {
				[key: number]: { picked: boolean; pickedQty: number; pickedOldWeight: number };
			} = {};
			order.content?.forEach((content) => {
				if (content.id) {
					newValues[+content.id] = {
						picked: false,
						pickedQty: content?.orderQty ?? 0,
						pickedOldWeight: content?.pickedWeight ?? 0,
					};
				}
			});
			setValues(newValues);
		}
	}, [order]);

	useEffect(() => {
		// console.log(values);
		setValuesInContext(values);
	}, [values]);

	const setValuesInContext = (values: {
		[key: number]: {
			picked: boolean;
			pickedQty: number;
			pickedWeight?: number;
			pickedOldWeight?: number;
			shorted?: boolean;
		};
	}) => {
		const newValues = Object.entries(values).map(([key, value]) => {
			return {
				orderProductId: +key,
				picked: value.picked,
				pickedQty: value.pickedQty,
				pickedWeight: value.pickedWeight,
				pickedOldWeight: value.pickedOldWeight,
				shorted: value.shorted,
			};
		});
		setTaskFieldValues(taskFieldId, newValues);
	};

	const setProductAsPicked = (
		orderProductId: number | undefined,
		pickedQuantity?: number,
		pickedWeight?: number,
		shorted?: boolean,
	) => {
		if (!orderProductId) return;
		setValues((oldValues) => {
			return {
				...oldValues,
				[orderProductId]: {
					picked: !oldValues[orderProductId].picked,
					pickedQty: pickedQuantity ?? oldValues[orderProductId].pickedQty,
					pickedWeight: pickedWeight ?? oldValues[orderProductId].pickedWeight,
					pickedOldWeight: oldValues[orderProductId].pickedOldWeight,
					shorted,
				},
			};
		});
	};

	const callDownloadPdfDialog = async (orderId: number) => {
		pushComponentDialog({
			title: t('operations.downloadItem', { item: 'pdf' }),
			component: DownloadPdfDialog,
			data: {
				orderId: orderId,
			},
			fullScreen: false,
			type: 'component',
		}).then(async (result) => {
			if (result !== false) {
				pushLoadingData({ contentType: AppFunction.Order });
				const url = await execute({
					orderId: result.orderId,
					language: result.language ?? 'jp',
					fullOrder: result.fullOrder ?? false,
					type: DocumentType.pickingList,
				});
				popLoadingData();
				pushPopup(
					<Box
						sx={{
							position: 'relative',
							width: '100%',
							height: '80vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<iframe src={url} style={{ width: '100%', height: '100%' }} title='PDF Preview' />
					</Box>,
				);
			} else {
				console.log('Canceled');
			}
		});
	};

	if (!order) return null;

	const culi = order?.subOrders?.flatMap((subOrder) => subOrder.content);

	return (
		<div style={{ padding: '1rem' }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					margin: '1rem 0 1rem 0',
				}}
			>
				<Typography variant='h6'>
					{getTranslatedString(AppFunction.TaskFieldArchetype, taskFieldId, TranslationTypes.name)}
				</Typography>
				<Button
					size='small'
					variant='contained'
					color='primary'
					onClick={async () => callDownloadPdfDialog(order.id)}
					sx={{
						borderRadius: '2rem',
					}}
				>
					<PictureAsPdf />
				</Button>
			</Box>
			<List>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content) => {
						return (
							<PickingFieldListItem
								key={content.id}
								content={content}
								sourceContent={culi?.find((c) => c?.id === content.sourceId)}
								setProductAsPicked={setProductAsPicked}
							/>
						);
					})}
			</List>
		</div>
	);
};
