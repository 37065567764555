import {
	cloneElement,
	HTMLAttributes,
	JSXElementConstructor,
	ReactElement,
	ReactNode,
	useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import _, { uniqueId } from 'lodash';
import moment from 'moment';

import useLocale from '@hooks/useLocale';

import { AppFunction, TranslationTypes } from 'common';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	Box,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	useTheme,
} from '@mui/material';

import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';
import { QuantityChip } from '@components/common/chips/QuantityChip';
import { RecipeChip } from '@components/common/chips/RecipeChip';
import { SkuChip } from '@components/common/chips/SkuChip';
import { WeightChip } from '@components/common/chips/WeightChip';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';
import { EnhancedVariant } from '@contexts/orderEditorContext/types';
import ArrivesAtChip from '@components/common/chips/ArrivesAtChip';

/**
 * The props for the {@link InventoryProductAutocomplete} component
 * @param {EnhancedVariant[]} availableItems - the available items to select from
 * @param {number | EnhancedVariant | null} value - the selected value
 * @param {(value: EnhancedVariant | null) => void} onChange - the function to call when the value changes
 * @param {boolean} [showUnavailable] - whether to show unavailable items in the list
 */
interface Props {
	availableItems: EnhancedVariant[];
	value: number | EnhancedVariant | null;
	onChange: (value: EnhancedVariant | null) => void;
	showUnavailable?: boolean;
}

/**
 * Renders an {@link Autocomplete} component for selecting a product variant from a list of available items.
 * - The selected value can be a number, an object or null.
 * - The available items are a list of {@link EnhancedVariant} objects.
 * - The component will display the selected value's code as a {@link SkuChip} and the remaining weight as a {@link QuantityChip}.
 * - The component will display the selected value's recipe as a {@link RecipeChip}, weight as a {@link WeightChip} and expiration as a {@link ExpiresAtChip}.
 * - The component will filter the available items based on the input value.
 * @param {Props} param0
 * @returns {JSX.Element} - the rendered {@link Autocomplete} component
 */
const InventoryProductAutocomplete = ({
	availableItems,
	value,
	onChange,
	showUnavailable = false,
}: Props): JSX.Element => {
	const { getTranslatedString } = useLocale();
	const { timeZone, dateFormat } = useLocationTimeZone();
	const { t } = useTranslation();
	const theme = useTheme();
	const index = uniqueId();

	/**
	 * Gets the selected value from the available variants
	 * - If the value is a number it will find the variant with the orderProductId equal to the value (rarely used)
	 * - If the value is an object it will find the variant with the same orderProductId, expiresAt and actualExpiration
	 * but only if the expiration is set (commonly used)
	 * - If the value is an object without an expiration it will find the variant with the same id (used for bookings)
	 */
	const selectedValue = useMemo((): EnhancedVariant | null => {
		if (value === undefined || value === null) {
			return null;
		}
		if (typeof value === 'number') {
			return availableItems.find((v) => v.orderProductId === value) ?? null;
		} else if (typeof value === 'object' && !value.expiresAt) {
			if (value.ead !== undefined && value.ead !== null) {
				return (
					availableItems.find((v) => v.id === value?.id && _.isEqual(v.ead, value.ead)) ?? null
				);
			} else {
				return availableItems.find((v) => v.id === value?.id && !v.ead) ?? null;
			}
		} else {
			return (
				availableItems.find(
					(v) =>
						v.orderProductId === value?.orderProductId &&
						v.remainingWeight === value?.remainingWeight &&
						v.expiresAt === value?.expiresAt &&
						v.actualExpiration === value?.actualExpiration,
				) ?? null
			);
		}
	}, [availableItems, value, showUnavailable]);

	/**
	 * Gets the label for the selected option
	 * @param {EnhancedVariant} option - the option to get the label for
	 * @returns {string} - the label for the selected option
	 */
	const getOptionLabel = (option: EnhancedVariant): string => {
		const productName = getTranslatedString(
			AppFunction.Product,
			option.productId ?? -1,
			TranslationTypes.name,
		);
		const variantName = getTranslatedString(AppFunction.Variant, option.id, TranslationTypes.name);
		return `${productName} ${variantName}`;
	};

	/**
	 * Compares the selected option with the available value.
	 * - if the option has an orderProductId, it compares the orderProductId, expiresAt and actualExpiration.
	 * - if the option does not have an orderProductId, it compares the id (only when {@link showUnavailable} is true).
	 * @param {EnhancedVariant} option - the selected option to compare
	 * @param {EnhancedVariant} value - the available value to compare
	 * @returns {boolean} - true if the options are equal, false otherwise
	 */
	const isOptionEqualToValue = (option: EnhancedVariant, value: EnhancedVariant): boolean => {
		if (!value?.orderProductId && showUnavailable) {
			if (value.ead !== undefined && value.ead !== null) {
				return option?.id === value?.id && _.isEqual(option?.ead, value?.ead);
			} else {
				return option?.id === value?.id && !option?.ead;
			}
		}
		return (
			option.orderProductId === value?.orderProductId &&
			option?.expiresAt === value?.expiresAt &&
			option?.actualExpiration === value?.actualExpiration &&
			option?.remainingWeight === value?.remainingWeight
		);
	};

	/**
	 * Renders the {@link Autocomplete} options
	 * @param {HTMLAttributes<HTMLLIElement>} props - the props for the {@link MenuItem}
	 * @param {EnhancedVariant} option - the option to render
	 * @returns {ReactNode} - the rendered option
	 */
	const renderOption = (
		props: HTMLAttributes<HTMLLIElement>,
		option: EnhancedVariant,
		state: AutocompleteRenderOptionState,
	): ReactNode => {
		let productName = 'none';
		let variantName = 'none';
		if (option.id !== -1) {
			variantName = getTranslatedString(AppFunction.Variant, option.id, TranslationTypes.name);
			productName = getTranslatedString(
				AppFunction.Product,
				option.productId ?? -1,
				TranslationTypes.name,
			);
		}
		const label = `${productName} ${variantName}`;
		let key: string = option.id.toString();
		if (option.orderProductId) {
			key += `-${option.orderProductId}`;
		}
		if (option.maxAmount) {
			key += `-${option.maxAmount}`;
		}
		return (
			<MenuItem
				{...props}
				key={key}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: theme.spacing(1),
				}}
			>
				<SkuChip {...option} />
				<div
					style={{
						marginLeft: '1rem',
						flexGrow: 1,
						flexBasis: 0,
						display: 'flex',
						justifyContent: 'flex-start',
						fontWeight: state.selected ? 'bold' : 'normal',
						gap: '1rem',
					}}
				>
					{label}
					{option.ead && <ArrivesAtChip {...option} />}
				</div>
				<QuantityChip {...option} />
				<RecipeChip {...option} />
				<WeightChip {...option} />
				<ExpiresAtChip {...option} />
			</MenuItem>
		);
	};

	/**
	 * Renders the input for the {@link Autocomplete}
	 * @param {AutocompleteRenderInputParams} params - the input parameters
	 * @returns {JSX.Element} - the rendered input
	 */
	const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
		const clonedEndAdornment = cloneElement(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			params.InputProps?.endAdornment as ReactElement<any, string | JSXElementConstructor<any>>,
			{
				style: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					top: 0,
					height: '100%',
				},
			},
		);
		return (
			<TextField
				{...params}
				value={`${params.inputProps.value}`}
				label={t(`appFunctions.${AppFunction.Variant}`)}
				variant='outlined'
				sx={{ fontWeight: 'bold !important' }}
				InputProps={{
					...params.InputProps,
					startAdornment: <>{selectedValue && <SkuChip {...selectedValue} />}</>,
					endAdornment: (
						<Box display='flex' flexDirection='row' alignItems='center'>
							<InputAdornment position='end'>
								<Stack direction='row' spacing={1}>
									<ArrivesAtChip {...selectedValue} />
									<QuantityChip {...selectedValue} sx={{ flex: 1 }} />
									<RecipeChip {...selectedValue} sx={{ flex: 1 }} />
									<WeightChip {...selectedValue} sx={{ flex: 1 }} />
									<ExpiresAtChip {...selectedValue} sx={{ flex: 1 }} />
								</Stack>
							</InputAdornment>
							{/* {params.InputProps.endAdornment} */}
							{clonedEndAdornment}
						</Box>
					),
					// inputProps: {
					// 	...params.inputProps,
					// 	onMouseDown:
					// }
				}}
			/>
		);
	};

	/**
	 * Filters the options based on the input value
	 * @param {EnhancedVariant[]} options - the available options
	 * @param {object} param1 - the input value
	 * @returns {EnhancedVariant[]} - the filtered options
	 */
	const filterOptions = (
		options: EnhancedVariant[],
		{ inputValue }: { inputValue: string },
	): EnhancedVariant[] => {
		return options.filter((option) => {
			const productName = getTranslatedString(
				AppFunction.Product,
				option.productId ?? -1,
				TranslationTypes.name,
			).toLowerCase();
			const variantName = getTranslatedString(
				AppFunction.Variant,
				option.id,
				TranslationTypes.name,
			).toLowerCase();
			const fullName = `${productName} ${variantName}`;
			const sku = option.sku.toLowerCase();
			const date = option.expiresAt
				? moment(option.expiresAt).tz(timeZone).format(dateFormat).toLowerCase()
				: '';

			return (
				productName.includes(inputValue.toLowerCase()) ||
				variantName.includes(inputValue.toLowerCase()) ||
				fullName.includes(inputValue.toLowerCase()) ||
				sku.includes(inputValue.toLowerCase()) ||
				date.includes(inputValue.toLowerCase())
			);
		});
	};

	// TODO: implement this
	// const handleMouseDown = (event: React.MouseEvent) => {
	//     if (event.button === 2) { // 2 is the right mouse button
	//         event.preventDefault();
	//     } else if (onMouseDown) {
	//         onMouseDown(event); // Call the original onMouseDown handler if it exists
	//     }
	// };

	return (
		<Autocomplete
			id={`order-product-idx-${index}`}
			size='small'
			options={availableItems}
			value={selectedValue}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			renderOption={renderOption}
			renderInput={renderInput}
			filterOptions={filterOptions}
			onChange={(_, newValue) => {
				if (typeof newValue === 'object' && newValue !== null) {
					onChange(newValue);
				} else {
					onChange(null);
				}
			}}
			// TODO: implement this
			// disablePortal={renderType === 'popup'}
		/>
	);
};

InventoryProductAutocomplete.displayName = 'InventoryProductAutocomplete';
export default InventoryProductAutocomplete;
