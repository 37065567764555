import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import useLocale from '@hooks/useLocale';
import {
	AccruedWarehouseInventoriesResponse,
	AccruedWarehouseInventoryResponse,
	AppFunction,
	GenericIdRequest,
	OrderType,
	ProductType,
	TranslationTypes,
	WarehouseInventoriesResponse,
	WarehouseInventoryResponse,
} from 'common';
import { useLayout } from '@contexts/layoutContext/LayoutContext';
import { Build, CallSplit, KeyboardReturn, PictureAsPdf, Warehouse } from '@mui/icons-material';
import { Column, ColumnSortOrderType, ColumnSortParams } from 'primereact/column';
import useInventory from '@hooks/useInventory';
import useOrders from '@hooks/useOrders';
import { TableContextProvider } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import { OrderCreate } from '@pages/Orders/OrderCreate';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { OrderCodeBodyTemplate } from '@components/tables/fields/OrderCodeBodyTemplate';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';
import _ from 'lodash';
import { CustomFilterInput } from '@components/filterInputs/CustomFilterTextInput';
import commonStyles from '@styles/Common.module.css';
import { CustomFilter } from '@hooks/useInitializeFilters';
import { execute } from '@store/externalApi/io/apiDownloadPdf';
import { DownloadPdfDialog } from '@components/dialogs/DownloadPdfDialog';
import { TableProductNameBody } from '@components/common/tablecells/TableProductNameBody';
import { TablePriceBody } from '@components/common/tablecells/TablePriceBody';
import { TableTotalWeightBody } from '@components/common/tablecells/TableTotalWeightBody';
import { TableQuantityBody } from '@components/common/tablecells/TableQuantityBody';
import { TableSingleWeightBody } from '@components/common/tablecells/TableSingleWeightBody';
import { InventoryListHeader } from './InventoryListHeader';
import useProducts from '@hooks/useProducts';
import { TableLocationBody } from '@components/common/tablecells/TableLocationBody';

const appFunction = AppFunction.Inventory;

const isAccruedWarehouseInventory = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
): data is AccruedWarehouseInventoryResponse => {
	return (
		Array.isArray(data.warehousesId) &&
		Array.isArray(data.expiresAt) &&
		data.expiresAt.length === 2 &&
		(!data.actualExpiration ||
			(Array.isArray(data.actualExpiration) && data.actualExpiration.length === 2))
	);
};

export const Inventory = () => {
	const { t } = useTranslation();
	const { inventories, accruedInventory, fetchShit, deleteInventoryProduct } = useInventory();
	const { productList } = useProducts();
	const { orderList } = useOrders();
	const { getTranslatedString, getCurrentLanguage } = useLocale();
	const { setBreadCrumbs, pushPopup, pushLoadingData, popLoadingData } = useLayout();
	const { pushDialog, pushComponentDialog } = useFeedbacks();

	const [items, setItems] = useState<
		WarehouseInventoriesResponse | AccruedWarehouseInventoriesResponse
	>([]);
	const [locationFilter, setLocationFilter] = useState<number | 'all'>('all');
	const [productTypeFilter, setProductTypeFilter] = useState<ProductType[]>([ProductType.product]);

	const locationFilterRef = useRef<number | 'all'>();

	useEffect(() => {
		fetchShit();
		setLocationFilter('all');
		refreshBreadCrumbs('all');
		locationFilterRef.current = 'all';
	}, []);

	useEffect(() => {
		// TODO: this is not working,
		// we should be back to the old warehouse when something changes in the inventory but we don'T
		// find out why
		if (locationFilter !== 'all') {
			console.log('locationFilter', locationFilter, 'items', inventories[locationFilter]);
			setItems(inventories[locationFilter]);
			locationFilterRef.current = locationFilter;
		}
	}, [inventories]);

	useEffect(() => {
		setItems(accruedInventory);
	}, [accruedInventory]);

	const refreshBreadCrumbs = (affix: string) => {
		setBreadCrumbs([
			{
				label: `${t(`appBar.${appFunction}`)} - ${affix}`,
				icon: <Warehouse />,
			},
		]);
	};

	const unitBody = (rowData: WarehouseInventoryResponse | AccruedWarehouseInventoryResponse) => {
		if (!rowData.variant) return <div>{t(`${AppFunction.Product}.noVariant`)}</div>;
		const purchaseUnitWeight = rowData.variant.purchaseUnitWeight;
		if (purchaseUnitWeight === null || purchaseUnitWeight === undefined) {
			return <div>{t(`${AppFunction.Product}.units.variable`)}</div>;
		}
		return (
			<div>
				{purchaseUnitWeight === 0
					? t(`${AppFunction.Product}.units.pieces_other`)
					: `${purchaseUnitWeight}g`}
			</div>
		);
	};

	const orderIdBody = (
		rowData: WarehouseInventoryResponse | AccruedWarehouseInventoryResponse,
		type: 'this' | 'parent',
	) => {
		if (isAccruedWarehouseInventory(rowData)) {
			return;
		}
		if (!rowData.orderProduct) return <div>{t('inventory.noOrder')}</div>;
		const order = orderList.find((o) => o.id === rowData.orderProduct?.orderId);
		if (!order) return <div>{t('inventory.noOrder')}</div>;
		return (
			<OrderCodeBodyTemplate
				rowData={order}
				type={type}
				useIcon={locationFilterRef.current !== 'all'}
				orderProduct={locationFilterRef.current !== 'all' ? rowData.orderProduct : undefined}
				sx={{ width: '100%' }}
			/>
		);
	};

	function callModifyInventoryProductPopup(data: GenericIdRequest) {
		if (items.length < 0) {
			return;
		}
		if (isAccruedWarehouseInventory(items[0])) {
			const product = (items as AccruedWarehouseInventoriesResponse).find(
				(item) => item.id === data.id,
			);
			console.log(product?.orderProducts);
			return;
		} else {
			const product = (items as WarehouseInventoriesResponse).find((item) => item.id === data.id);
			if (!product) return;
			const productId = product.orderProduct?.id;
			if (!productId) return;
			pushPopup(
				<OrderCreate
					orderType={OrderType.Modify}
					renderType='popup'
					forceId={productId ? +productId : -1}
					popupId={`modify-${productId}`}
				/>,
				false,
				true,
				`modify-${productId}`,
			);
		}
	}

	function callSplitInventoryProductPopup(data: GenericIdRequest) {
		if (items.length < 0) {
			return;
		}
		if (isAccruedWarehouseInventory(items[0])) {
			const product = (items as AccruedWarehouseInventoriesResponse).find(
				(item) => item.id === data.id,
			);
			console.log(product?.orderProducts);
			return;
		} else {
			const product = (items as WarehouseInventoriesResponse).find((item) => item.id === data.id);
			if (!product) return;
			const productId = product.orderProduct?.id;
			if (!productId) return;
			pushPopup(
				<OrderCreate
					orderType={OrderType.Split}
					renderType='popup'
					forceId={productId ? +productId : -1}
					popupId={`split-${productId}`}
				/>,
				false,
				true,
				`split-${productId}`,
			);
		}
	}

	function callDeleteInventoryProductDialog(data: GenericIdRequest) {
		if (items.length < 0) {
			return;
		}
		if (isAccruedWarehouseInventory(items[0])) {
			const product = (items as AccruedWarehouseInventoriesResponse).find(
				(item) => item.id === data.id,
			);
			console.log(product?.orderProducts);
			return;
		} else {
			const product = (items as WarehouseInventoriesResponse).find((item) => item.id === data.id);
			if (!product) return;
			const productId = product.orderProduct?.id;
			if (!productId) return;
			pushDialog({
				title: t('dialog.areYouSure'),
				type: 'withActions',
				message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
				actions: [
					{
						label: t('dialog.cancel'),
					},
					{
						label: t('dialog.ok'),
						action: async () => {
							deleteInventoryProduct({ id: productId }).then((response) => {
								if (response) {
									console.log('deleted', productId);
								} else {
									console.log('error');
								}
							});
						},
					},
				],
			});
		}
	}

	/**
	 * Calls the Manual Order create action
	 * - Opens a popup {@link OrderCreate} with the order type set to {@link OrderType.Manual}
	 */
	function callOrderCreateAction() {
		pushPopup(
			<OrderCreate
				orderType={OrderType.Manual}
				renderType='popup'
				popupId={`manual-${Date.now()}`}
			/>,
			false,
			true,
			`manual-${Date.now()}`,
		);
	}

	/**
	 * Calls the download pdf dialog
	 * @param {GenericIdRequest} data Dialog data.
	 * - The {@link GenericIdRequest} with the inventory item id to be used for the dialog.
	 * - The id is automatically supplied by the {@link TableContextProvider} when the action is called.
	 */
	const callDownloadPdfDialog = async (data: GenericIdRequest) => {
		if (items.length < 0) {
			return;
		}
		if (isAccruedWarehouseInventory(items[0])) {
			const product = (items as AccruedWarehouseInventoriesResponse).find(
				(item) => item.id === data.id,
			);
			console.log(product?.orderProducts);
			return;
		} else {
			const product = (items as WarehouseInventoriesResponse).find((item) => item.id === data.id);
			if (!product || !product.orderProduct) {
				return;
			}

			const findFirst = (orderId?: number): number | null => {
				const order = orderList.find((o) => o.id === orderId);
				if (order && order.parentId) {
					return findFirst(order.parentId);
				}
				if (order?.id) {
					return order.id;
				}
				return null;
			};

			if (!product.orderProduct?.orderId) {
				return null;
			}

			const orderId = findFirst(+product.orderProduct?.orderId);
			if (!orderId) {
				console.log('no order found');
				return;
			}

			pushComponentDialog({
				title: t('operations.downloadItem', { item: 'pdf' }),
				component: DownloadPdfDialog,
				data: {
					orderId: orderId,
				},
				fullScreen: false,
				type: 'component',
			}).then(async (result) => {
				if (result !== false) {
					pushLoadingData({ contentType: AppFunction.Order });
					const url = await execute({
						orderId: result.orderId,
						language: result.language ?? 'jp',
						fullOrder: result.fullOrder ?? false,
						type: result.pdfType,
					});
					popLoadingData();
					pushPopup(
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								height: '80vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<iframe src={url} style={{ width: '100%', height: '100%' }} title='PDF Preview' />
						</Box>,
					);
				} else {
					console.log('Canceled');
				}
			});
		}
	};

	const sortByName = (data: ColumnSortParams): WarehouseInventoriesResponse => {
		function sortByName(
			objectArray: WarehouseInventoriesResponse,
			currentLanguage: string,
			sortOrder: ColumnSortOrderType,
		) {
			const fallbackOrder: Record<string, string[]> = {
				jp: ['it', 'en'],
				it: ['jp', 'en'],
				en: ['jp', 'it'],
			};
			return _.orderBy(
				objectArray,
				(obj) => {
					const variant = obj.variant;
					if (!variant?.strings) return '';
					return _.get(
						_.find(variant?.strings, (s) => s.langCode === currentLanguage) ||
							_.find(variant?.strings, (s) =>
								_.includes(fallbackOrder[currentLanguage], s.langCode),
							),
						'value',
						'',
					);
				},
				sortOrder === 1 ? 'asc' : 'desc',
			);
		}

		if (data.order === 1 || data.order === -1) {
			const sortedArray = sortByName(data.rowData, getCurrentLanguage(), data.order);
			return sortedArray;
		}
		return data.rowData;
	};

	const changeWarehouse = (id: number | 'all', subIds?: number[]) => {
		if (id === 'all') {
			setItems(accruedInventory);
			refreshBreadCrumbs('all');
		} else {
			if (subIds && subIds.length > 0) {
				const accruedItems: WarehouseInventoriesResponse = [];
				subIds.forEach((subId) => {
					const subItems = inventories[subId] ?? [];
					accruedItems.push(...subItems);
				});
				setItems(accruedItems);
			} else {
				setItems(inventories[id]);
			}
			refreshBreadCrumbs(getTranslatedString(AppFunction.Location, id, TranslationTypes.name));
		}
		setLocationFilter(id);
		locationFilterRef.current = id;
	};

	return (
		<TableContextProvider
			key={orderList.length}
			values={(items as WarehouseInventoriesResponse)?.filter((item) => {
				const parent = productList.find((p) => p.id === item.variant?.productId);
				if (!parent) return [];
				return productTypeFilter.includes(parent.productType);
			})}
			pageIdentifier={appFunction}
			customHeader={
				<InventoryListHeader
					currentWarehouseId={locationFilter}
					onChangeWarehouse={changeWarehouse}
					currentProductTypes={productTypeFilter}
					onChangeProductTypes={setProductTypeFilter}
				/>
			}
			actions={[
				{
					label: 'operations.modify',
					customIcon: <Build />,
					action: callModifyInventoryProductPopup,
				},
				{
					label: 'operations.split',
					customIcon: <CallSplit />,
					action: callSplitInventoryProductPopup,
				},
				{
					label: `${AppFunction.Order}.pdf`,
					action: callDownloadPdfDialog,
					customIcon: <PictureAsPdf />,
				},
				{
					label: 'operations.return',
					action: (v) => console.log(v),
					customIcon: <KeyboardReturn />,
				},
				{
					type: DropDownActionType.delete,
					action: callDeleteInventoryProductDialog,
				},
			]}
			customCreateAction={callOrderCreateAction}
		>
			<Column
				field='variant'
				header='name'
				body={TableProductNameBody}
				style={{ minWidth: '20rem' }}
				sortable
				sortFunction={sortByName}
				filter
				filterElement={CustomFilterInput}
				filterHeaderClassName={commonStyles.noFilterButton}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				filterMatchMode={CustomFilter.filterWarehouseEntry}
				showFilterMenu={false}
			/>
			<Column
				field='expiresAt'
				header='expiration'
				body={(data) => <ExpiresAtChip {...data} sx={{ width: '100%' }} />}
				sortable
				filter={true}
				filterField='date'
				dataType='date'
				showFilterMatchModes
				showFilterMenuOptions
				showFilterOperator
				showFilterMenu
			/>
			<Column
				field='orderProduct?.orderId'
				header='baseOrder#'
				body={(data) => orderIdBody(data, 'parent')}
			/>
			<Column
				field='orderProduct?.orderId'
				header='thisOrder#'
				body={(data) => orderIdBody(data, 'this')}
			/>
			<Column field='unit' header='unit' body={unitBody} />
			<Column field='actualPrice' header='price' body={TablePriceBody} />
			<Column
				field='warehouse'
				header='location'
				body={(data) => <TableLocationBody {...data} onChangeWarehouse={changeWarehouse} />}
			/>
			<Column field='quantity' header='quantity' body={TableQuantityBody} />
			<Column field='weight' header='singleWeight' body={TableSingleWeightBody} />
			<Column field='weight' header='totalWeight' body={TableTotalWeightBody} />
			<Column
				field='sourceId'
				header='source'
				body={(data) => (data.sourceId ? data.sourceId.toString() : t('common.none'))}
			/>
		</TableContextProvider>
	);
};
