import { Grid, MenuItem, Select } from '@mui/material';
import useTasks from '@hooks/useTasks';
import useLocale from '@hooks/useLocale';
import { useTask } from '@contexts/index';
import { AppFunction, TaskStatus, TranslationTypes } from 'common';
import { DropdownFieldValue } from '@contexts/taskContext/config/dropdownFieldConfig';
import { useEffect } from 'react';
import useTaskFieldParameters from '@hooks/useTaskFieldParameters';

interface Props {
	taskFieldId: number;
}

export const DropdownField = ({ taskFieldId }: Props) => {
	const { taskFieldArchetypeList } = useTasks();
	const { taskFieldParametersList } = useTaskFieldParameters();
	const { getTranslatedString } = useLocale();
	const { cachedValues, getTaskFieldValues, setTaskFieldValues, taskState } = useTask();

	const field = taskFieldArchetypeList.find((archetype) => archetype.id === taskFieldId);
	const parameters = taskFieldParametersList.find((parameter) => parameter.id === field?.paramId);

	const fieldData = getTaskFieldValues<DropdownFieldValue>(taskFieldId);

	useEffect(() => {
		const stubData = getTaskFieldValues<DropdownFieldValue>(taskFieldId);
		if (stubData.length > 0) {
			setTaskFieldValues(taskFieldId, [{ value: stubData[0].value }]);
		}
	}, [cachedValues]);

	return (
		<Grid container style={{ padding: '1rem' }} alignItems='center'>
			<Grid item xs={4}>
				{getTranslatedString(
					AppFunction.TaskFieldArchetype,
					field?.id ?? -1,
					TranslationTypes.name,
				)}
			</Grid>
			<Grid item flexGrow={1}>
				<Select
					onChange={(e) => setTaskFieldValues(taskFieldId, [{ value: e.target.value }])}
					value={fieldData.length > 0 ? fieldData[0].value : 'none'}
					MenuProps={{
						style: { zIndex: 9999 },
					}}
					fullWidth
					disabled={taskState !== TaskStatus.inprogress && taskState !== TaskStatus.stub}
				>
					<MenuItem value={'none'} key={'none'}>
						None
					</MenuItem>
					{parameters &&
						parameters.params instanceof Array &&
						parameters.params.map((value, it) => (
							<MenuItem key={it} value={value}>
								{value}
							</MenuItem>
						))}
				</Select>
			</Grid>
		</Grid>
	);
};
