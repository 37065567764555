import { useLayout } from '@contexts/layoutContext/LayoutContext';
import useLayoutObserver from '@hooks/useLayoutObserver';
import { Box, Button, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { baseSx } from './styles';
import { SubMenuElement } from '@contexts/layoutContext/types';
import { ArrowRight } from '@mui/icons-material';

interface Props {
	index: number[];
	recursionIndex?: number;
	accumulatedWidth?: number;
}

const getElement = (
	elements: SubMenuElement[] | null,
	index: number[] | null,
	recursionIndex: number,
): SubMenuElement | undefined => {
	let element: SubMenuElement | undefined = undefined;
	if (!elements || !index) {
		return element;
	}
	if (recursionIndex === 0) {
		element = elements[index[0]];
	} else {
		for (let i = 0; i <= recursionIndex; i++) {
			if (i === 0) {
				element = elements[index[i]];
			} else {
				if (element?.nestedElements) {
					element = element.nestedElements?.[index[i]];
				}
			}
		}
	}

	return element;
};

export const SubMenuButton = ({ index, recursionIndex = 0, accumulatedWidth = 0 }: Props) => {
	const {
		selectedSubMenuArray,
		subMenuElements,
		subMenuFullScreen,
		subMenuPosition,
		selectSubMenuElement,
	} = useLayout();
	const [lastPosition, setLastPosition] = useState<DOMRect | null>(null);

	const { t } = useTranslation();
	const theme = useTheme();

	const element = getElement(subMenuElements, index, recursionIndex);
	const isSelected = selectedSubMenuArray?.[recursionIndex] === index[recursionIndex];
	const isClickable =
		!isSelected || (isSelected && selectedSubMenuArray?.length > recursionIndex + 1);

	const xtransform = useMemo(() => {
		if (!lastPosition || !subMenuPosition) {
			return '';
		}
		if (element?.nestedElements && element.nestedElements.length > 0 && recursionIndex === 0) {
			const x = (lastPosition.x - subMenuPosition.x) / 1.1 - 40 - accumulatedWidth;
			return `translateX(${-x}px)`;
		}
		// else if (recursionIndex > 0) {
		// 	const x = ((lastPosition.width ?? 0) * 1.1 - (lastPosition.width ?? 0)) / 2;
		// 	return `translateX(${x}px)`;
		// }
		return '';
	}, [lastPosition, isSelected, subMenuPosition]);

	const observe = (id: string, rect: DOMRectReadOnly, mode: 'size' | 'position') => {
		if (!isSelected && mode === 'position' && id === `submenu-button-${index}`) {
			setLastPosition(rect);
		}
	};

	const buttonRef = useLayoutObserver<HTMLButtonElement>(
		`submenu-button-${index}`,
		'position',
		observe,
	);

	if (!element) {
		return null;
	}

	const { label, icon, onClick, translate } = element;

	return (
		<>
			<Button
				key={index.reduce((acc, curr) => acc + '-' + curr.toString(), '')}
				ref={buttonRef}
				variant='contained'
				color='success'
				disableElevation
				sx={{
					...baseSx,
					minHeight: subMenuFullScreen ? '17rem' : '2rem',
					minWidth: subMenuFullScreen ? '17rem' : '2rem',
					backgroundColor:
						isSelected && isClickable
							? theme.palette.primary.main
							: subMenuFullScreen
							? theme.palette.primary.main
							: 'white',
					color:
						isSelected && isClickable
							? 'white'
							: subMenuFullScreen
							? 'white'
							: theme.palette.primary.main,
					border: isSelected && isClickable ? '1px solid white ' : '1px solid transparent',
					transform: isSelected ? `scale(1.1) ${xtransform}` : 'scale(0.95)',
					boxShadow: isSelected && isClickable ? '0 0 0.5rem 0.2rem rgba(255, 255, 255, 0.2)' : '',
					opacity:
						selectedSubMenuArray && selectedSubMenuArray.length > recursionIndex && !isSelected
							? 0
							: 1,
					'&:hover': {
						backgroundColor: subMenuFullScreen ? 'white' : theme.palette.primary.main,
						transform: subMenuFullScreen
							? 'scale(1.01)'
							: isSelected
							? `scale(1.1) ${xtransform}`
							: 'scale(1)',
						color: subMenuFullScreen ? theme.palette.primary.main : 'white',
						border: subMenuFullScreen
							? `1px solid ${theme.palette.primary.main}`
							: '1px solid rgba(255, 255, 255, 0.8)',
						boxShadow: '0 0 0.5rem 0.2rem rgba(255, 255, 255, 0.2)',
					},
				}}
				onClick={() => {
					console.log('clickable', isClickable);
					if (!isSelected || (isSelected && selectedSubMenuArray?.length > recursionIndex + 1)) {
						onClick && onClick();
						selectSubMenuElement(index[recursionIndex], recursionIndex);
					}
				}}
			>
				{icon && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							fontSize: subMenuFullScreen ? '3rem' : '1.3rem',
						}}
					>
						{icon}
					</Box>
				)}
				<Box
					sx={{
						fontSize: subMenuFullScreen ? '1.5rem' : '0.8rem',
					}}
				>
					{translate ? t('label') : label}
				</Box>
			</Button>
			{isSelected && element.nestedElements && element.nestedElements.length > 0 && (
				<Box
					display='flex'
					justifyContent='flex-start'
					gap={1}
					sx={{
						position: 'absolute',
						left: `${(lastPosition?.width ?? 0) * 1.1 + (recursionIndex === 0 ? 34 : 0) + 10}px`,
						width: `calc(100% - ${
							(lastPosition?.width ?? 0) * 1.1 + (recursionIndex === 0 ? 34 : 0) + 10
						}px)`,
						zIndex: `${9900 + recursionIndex}`,
					}}
				>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='center'
						sx={{
							width: recursionIndex + 1 === (selectedSubMenuArray?.length ?? 0) ? '2rem' : '0rem',
							fontSize:
								recursionIndex + 1 === (selectedSubMenuArray?.length ?? 0) ? '2rem' : '0rem',
							color: theme.palette.primary.contrastText,
							transition: 'all 0.3s',
						}}
					>
						<ArrowRight color='inherit' fontSize='inherit' />
					</Box>
					{element.nestedElements.map((_, subIndex) => {
						return (
							<SubMenuButton
								key={subIndex}
								index={[...index, subIndex]}
								recursionIndex={recursionIndex + 1}
								accumulatedWidth={accumulatedWidth + (lastPosition?.width ?? 0) * 1.1}
							/>
						);
					})}
				</Box>
			)}
		</>
	);
};
