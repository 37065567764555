import { SxProps, Theme } from '@mui/material';

export const baseSx: SxProps<Theme> = {
	padding: '0.1rem 0.2rem',
	display: 'flex',
	gap: '0.5rem',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '0.4rem',
	transition: 'all 0.3s ease-out',
};
