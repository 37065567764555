import { Box, List, ToggleButton } from '@mui/material';
import {
	AppFunction,
	OrderBaseResponse,
	Recipe,
	WarehouseProductCutItem,
	WarehouseProductCutItems,
	WarehouseProductCutItemsCut,
} from 'common';
import { CloseProcessingOrderValueMap } from '../../../../config';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import _, { debounce, floor, isEqual } from 'lodash';
import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';
import { DivisionItem } from './DivisionItem';
import { useTranslation } from 'react-i18next';

/**
 * Props for the Multiple Division Container.
 * @interface Props
 * @property {OrderBaseResponse} order - The order to process.
 * @property {Recipe} recipe - The recipe for the order.
 * @property {number} taskFieldId - The task field id.
 */
interface Props {
	order: OrderBaseResponse;
	recipe: Recipe;
	taskFieldId: number;
}

/**
 * Multiple Division Container
 * @param {Props} param0 - The {@link Props}.
 * @returns {JSX.Element} Container for the Multiple Division Processing Close Order Field.
 */
export const MultipleDivisionContainer = ({ order, taskFieldId }: Props): JSX.Element => {
	const { targetId, setTaskFieldValues, cachedValues } = useTask();
	const { findContentById } = useOrders();
	const { t } = useTranslation();
	const [values, setValues] = useState<CloseProcessingOrderValueMap>({});
	const [combined, setCombined] = useState<boolean>(true);
	const [initialized, setInitialized] = useState<boolean>(false);

	/**
	 * Debounced function to set task field values in the context.
	 * - This is used to prevent setting the values on every change.
	 * - The values are set after the user stops typing for 500ms.
	 */
	const debouncedSetTaskFieldValues = useMemo(
		() => debounce(setTaskFieldValues, 500),
		[setTaskFieldValues],
	);

	/**
	 * Get the values from the order and set them in the state.
	 * This is done when the targetId or order changes.
	 */
	useEffect(() => {
		const newValues = getValues(order);
		// TODO: we need a different equality check here
		// basically if we switch "combined" we need to see if we have changed the values or not
		// before clicking it so we can reflect the values correctly (getValues initializes everything)
		if (!isEqual(newValues, values)) {
			// console.log('actually different');
			setValues(newValues);
		}
	}, [targetId, order, combined]);

	/**
	 * Set the task field values in the context when the values change.
	 */
	useEffect(() => {
		debouncedSetTaskFieldValues(taskFieldId, Object.values(values).flat());
	}, [values, taskFieldId]);

	/**
	 * Get the values for the order.
	 * @param {OrderBaseResponse} order - The current order.
	 * @returns {CloseProcessingOrderValueMap} The values for the order.
	 */
	const getValues = (order: OrderBaseResponse): CloseProcessingOrderValueMap => {
		// TODO: should we cache this expensive function??
		const newValues: CloseProcessingOrderValueMap = {};
		// k let's see if we have saved values
		if (
			cachedValues &&
			cachedValues[taskFieldId] &&
			((combined && cachedValues[taskFieldId]?.length === 1) ||
				(!combined && cachedValues[taskFieldId]?.length !== 1) ||
				!initialized)
		) {
			// console.log('going the cached route');
			const id = order.content?.[0].id;
			const parsedId = getParsedId(id);
			if (!parsedId) {
				return newValues;
			}
			newValues[parsedId] = cachedValues[taskFieldId] as WarehouseProductCutItems;
			if (!initialized) {
				setCombined(cachedValues[taskFieldId]?.length === 1);
			}
		} else {
			// console.log('order content', order.content);
			order.content
				?.filter((c) => c.variantId !== undefined && c.variantId !== null)
				.forEach((content) => {
					const parsedId = getParsedId(content.id);
					if (!parsedId) {
						return;
					}

					const originalExpiration = content.expiresAt
						? moment(content.expiresAt).startOf('day').toDate()
						: new Date();
					if (combined) {
						const cutWeight = (order.targetQuantity ?? 0) * (content.pickedWeight ?? 0);
						const newValue: WarehouseProductCutItem = {
							cutItems: [
								{
									cutWeight,
									cutExpiresAt: originalExpiration,
								},
							],
							leftWeight: (order.subOrders?.[0].content?.[0].pickedWeight ?? 0) - cutWeight,
							leftExpiresAt: originalExpiration,
							estimated: true,
							discardedWeight: null,
						};
						newValues[parsedId] = [newValue];
					} else {
						if (!content.sourceId) {
							console.error('No source id found for content', content);
							return;
						}
						const parsedSourceId = getParsedId(content.sourceId);
						if (!parsedSourceId) {
							console.error('No parsed id found for source id', content.sourceId);
							return;
						}
						const source = findContentById(parsedSourceId);
						if (!source) {
							console.error('No source found for content', content);
							return;
						}

						// we have the source, we can calculate the cut weight distributed by pieces
						let leftItems = order.targetQuantity ?? 0;
						for (let i = 0; i < (source?.arrivedQty ?? 0); i++) {
							const cutWeight = content.pickedWeight ?? 0;

							const itemsInSinglePiece = floor(
								(source.pickedWeight ?? 1) / (source.arrivedQty ?? 1) / cutWeight,
							);
							const cutItems: WarehouseProductCutItemsCut = [];
							let tempItemsNum = 0;
							for (let j = 0; j < itemsInSinglePiece; j++) {
								leftItems -= 1;
								if (leftItems >= 0) {
									tempItemsNum++;
									cutItems.push({
										cutWeight,
										cutExpiresAt: originalExpiration,
									});
								}
							}

							const newValue: WarehouseProductCutItem = {
								cutItems,
								leftWeight:
									(source.pickedWeight ?? 1) / (source.arrivedQty ?? 1) - cutWeight * tempItemsNum,
								leftExpiresAt: originalExpiration,
								estimated: true,
								discardedWeight: null,
							};
							// console.log('new value', newValue);
							if (!newValues[parsedId]) {
								newValues[parsedId] = [newValue];
							} else {
								newValues[parsedId].push(newValue);
							}
						}
					}
					return newValues;
				});
		}

		setInitialized(true);
		return newValues;
	};

	/**
	 * Update the value in the state.
	 * This gets passed down to {@link DivisionItem} to update the values.
	 * {@link DivisionItem} will call this function when the value changes and uses the iterator as {@link idx}
	 * and the content id as {@link key}.
	 * @param {Partial<WarehouseProductCutItem>} partialValue - The partial value to update.
	 * @param {number} idx - The index of the value to update.
	 * @param {number} key - The key of the value to update.
	 */
	const updateValue = (
		partialValue: Partial<WarehouseProductCutItem>,
		idx: number,
		key: number,
	) => {
		const newValues = _.cloneDeep(values);
		const newValue = newValues[key][idx];
		Object.assign(newValue, partialValue);
		setValues(newValues);
	};

	return (
		<List>
			<Box
				m='1rem 0 0 0'
				p='1rem'
				borderRadius='1rem'
				sx={{
					backgroundColor: 'rgba(0, 0, 0, 0.05)',
				}}
				display='flex'
				flexDirection='column'
				gap={1}
			>
				<ToggleButton
					value='check'
					selected={combined}
					onChange={() => setCombined((prevValue) => !prevValue)}
					fullWidth
					size='small'
				>
					{combined
						? t(`${AppFunction.Task}.close.processing.combined`)
						: t(`${AppFunction.Task}.close.processing.separate`)}
				</ToggleButton>
			</Box>
			{Object.values(values).map((value, it) => {
				const elements: JSX.Element[] = [];
				value?.forEach((v, vIt) => {
					if (order.content?.[0]) {
						elements.push(
							<DivisionItem
								key={`${it}-${vIt}`}
								iterator={vIt}
								content={order.content[0]}
								sourceOrderProduct={findContentById(
									order.content[0].sourceId !== null
										? getParsedId(order.content[0].sourceId) ?? -1
										: -1,
								)}
								value={v}
								compound={value.length === 1}
								updateValue={updateValue}
							/>,
						);
					}
				});
				return elements;
			})}
		</List>
	);
};
