/**
 * EditShowGridField
 * Takes the following components as children
 * - TextField
 * - Select
 * - Autocomplete
 * - TranslatableAutocompleteWithCallback
 * - VectorInput (vector two and three)
 * The above elements can be used in cunjunction with an InputLabel
 * This component is rendered as a child of a MUI Grid in container mode
 * so there is no need to put it inside a grid.
 *
 * @typedef {Object} TranslationSettings
 * @property {TranslatableAppFunction} translatableType - The translatable type.
 * @property {TranslationTypes} translationType - The translation type.
 * @property {string} namespace - The namespace.
 *
 * @typedef {Object} ValueException - When using ids like -1, which are not translated according to the
 * i18n translation rules, we can define exceptions. Those exceptions will be visualized "as they are".
 * I might add a TranslationSettings option inside the exceptions if one is needed.
 * @property {string} value - The value.
 * @property {string} substitution - The substitution.
 *
 * @typedef {Object} EditShowGridFieldProps
 * @property {boolean} isEditing - Whether the component is in editing mode.
 * @property {JSX.Element[] | JSX.Element} children - The child elements to render.
 * @property {number} [width] - The width in 1/12 (to use in combination with a MUI Grid).
 * @property {boolean} [icon] - Whether to display an icon if present. The icon is automatically extracted.
 * @property {boolean} [label] - Whether to display a label.
 * @property {string} [forceLabel] - Force a label when you don't want to use the provided one.
 * In components that don't support InputLabel (like Autocomplete) pass here the label.
 * @property {TranslationSettings} [valueTranslationRule] - The value translation rule.
 * @property {ValueException[]} [valueExceptions] - The value exceptions.
 * @property {boolean} [stopPropagation] - stop the click from propagating.
 * @property {ErrorSettings} [error] - error settings: error presence and message.
 *
 * @returns {JSX.Element} - The rendered component.
 */

import {
	Autocomplete,
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	InputLabel,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { isValidElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocale, { FixedTranlsationType } from '../../hooks/useLocale';

import { TranslatableAppFunction, TranslationTypes } from 'common';

import { VectorThreeInput } from '../VectorThreeInput';
import { TranslatableAutocompleteWithCallback } from '../TranslatableAutocompleteWithCallback';
import VectorTwoInput, { Vector2 } from '../VectorTwoInput';

import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import styles from './EditShowGridField.module.css';

interface TranslationSettings {
	translatableType: TranslatableAppFunction;
	translationType: TranslationTypes;
	namespace: string;
}

interface ValueException {
	value: string;
	substitution: string;
}

interface ErrorSettings {
	error: boolean;
	errorText?: string;
}

interface EditShowGridFieldProps {
	isEditing: boolean | undefined;
	children: JSX.Element[] | JSX.Element;
	width?: number | 'flexGrow';
	icon?: boolean;
	label?: boolean;
	forceLabel?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: JSX.Element | ((value: any) => JSX.Element);
	fixedTranslationType?: FixedTranlsationType;
	valueTranslationRule?: TranslationSettings;
	valueExceptions?: ValueException[];
	stopPropagation?: boolean;
	error?: ErrorSettings;
	noTranslation?: boolean;
}

export interface AutocompleteValue {
	id: number;
	label: string;
}

export const EditShowGridField = (props: EditShowGridFieldProps) => {
	const { t } = useTranslation();
	const { getI18NString, getFixedTranslation } = useLocale();
	const [isEditing, setEditing] = useState(props.isEditing);
	const [value, setValue] = useState('');
	const [label, setLabel] = useState('');
	const [icon, setIcon] = useState(undefined);

	useEffect(() => {
		setEditing(props.isEditing);
	}, [props.isEditing]);

	const applyTranslationRules = (id: number, originalValue: number) => {
		if (props.noTranslation) {
			setValue(`${originalValue}`);
			return;
		}
		if (props.valueTranslationRule) {
			let value = '';
			if (props.valueExceptions) {
				// shallow comparison here since we might get a number as a value
				// might be done better, don't want to do it now
				const substitution = props.valueExceptions.find(
					(sub) => sub.value == originalValue.toString(),
				);
				if (substitution) {
					value = substitution.substitution;
				} else {
					value = getI18NString(
						props.valueTranslationRule.translatableType,
						originalValue,
						props.valueTranslationRule.translationType,
					);
				}
			} else {
				value = getI18NString(
					props.valueTranslationRule.translatableType,
					originalValue,
					props.valueTranslationRule.translationType,
				);
			}
			setValue(value);
		} else if (props.fixedTranslationType) {
			setValue(getFixedTranslation(`${id}.${originalValue}`, props.fixedTranslationType));
		} else {
			setValue(`${id}.${originalValue}`);
		}
	};

	const setValues = (element: JSX.Element) => {
		switch (element.type) {
			case InputLabel:
				setLabel(element.props.children.toString());
				break;
			case TextField:
				if (element.props.InputProps != undefined) {
					if (element.props.InputProps.startAdornment != undefined) {
						setIcon(element.props.InputProps.startAdornment);
					}
				}
				setValue(element.props.value);
				setLabel(element.props.label ?? '');
				break;
			case Select:
				applyTranslationRules(element.props.id, element.props.value);
				break;
			case FormControlLabel:
				setValue(element.props.control.props.value ? 'true' : 'false');
				setLabel(element.props.control.props.name);
				break;
			case Autocomplete: {
				const castedValue = element.props.value as AutocompleteValue;
				setValue(castedValue?.label ?? '10');
				break;
			}
			case VectorTwoInput: {
				setLabel(element.props.label ?? '');
				const culo = element.props.value as Vector2;
				setValue(`${culo.x}:${culo.y}`);
				break;
			}
			case VectorThreeInput:
				setLabel(element.props.label ?? '');
				setValue(element.props.value as string);
				break;
			case TranslatableAutocompleteWithCallback:
				setLabel(element.props.label ?? '');
				applyTranslationRules(element.props.id, element.props.value);
				break;
			case RadioGroup: {
				const value = element.props.value;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				element.props.children.map((c: any) => {
					if (c.props.value === value) {
						setValue(c.props.label as string);
					}
				});
				break;
			}
			case FormLabel:
				setLabel(element.props.children);
				break;
			default:
				break;
		}
	};

	const evaluateChildren = (children: JSX.Element | JSX.Element[]) => {
		if (!children) return;

		if (children.constructor.name === 'Array') {
			const castedChildren = children as JSX.Element[];
			castedChildren.map((x) => {
				setValues(x);
			});
		} else {
			const castedChild = children as JSX.Element;
			setValues(castedChild);
			evaluateChildren(castedChild.props.children);
		}
	};

	useEffect(() => {
		evaluateChildren(props.children);
	}, [props]);

	const parseValue = (): JSX.Element => {
		const parsedValue = props.noTranslation
			? value
			: t(value, {
					ns: props.valueTranslationRule ? props.valueTranslationRule.namespace : '',
					default: '',
			  });

		switch (value) {
			case 'true':
				return <PanoramaFishEyeIcon />;
			case 'false':
				return <ClearIcon />;
			default:
				return <>{parsedValue}</>;
		}
	};

	const showValue = () => {
		if (value === '' || value == null) {
			return <></>;
		} else {
			return (
				<Box className={styles['fieldWithLabel']}>
					<>
						{props.label && (
							<Typography
								component={'span'}
								variant='caption'
								gutterBottom
								className={styles['label']}
							>
								{props.forceLabel ?? label}
							</Typography>
						)}
						{props.body ? (
							<div className={styles['text']} style={{ width: '100%', display: 'block' }}>
								{isValidElement(props.body) && props.body}
								{typeof props.body === 'function' && props.body(value)}
							</div>
						) : (
							<Typography component={'span'} className={styles['text']}>
								{icon}
								{parseValue()}
							</Typography>
						)}
					</>
				</Box>
			);
		}
	};

	if ((value === '' || value == null) && !isEditing) {
		return <></>;
	} else {
		const content = (
			<FormControl
				fullWidth
				error={props.error?.error}
				id={`${label}-form-control`}
				sx={{
					marginTop: props.error?.error ? '10px' : '0px',
					marginBottom: props.error?.error ? '10px' : '0px',
				}}
			>
				{isEditing ? props.children : showValue()}
				{isEditing && props.error?.error && (
					<FormHelperText>{t(props.error?.errorText ?? 'generic error')}</FormHelperText>
				)}
			</FormControl>
		);

		if (props.width) {
			return (
				<Grid
					item
					xs={typeof props.width === 'number' ? props.width : undefined}
					flexGrow={typeof props.width === 'string' && props.width === 'flexGrow' ? 1 : 0}
					sx={{ minHeight: value ? '72px' : '0px' }}
					className={styles['gridItem']}
					onClick={(e) => {
						if (props.stopPropagation && props.isEditing) {
							e.stopPropagation();
						}
					}}
				>
					<FormControl
						fullWidth
						error={props.error?.error}
						id={`${label}-form-control`}
						sx={{
							marginTop: props.error?.error ? '10px' : '0px',
							marginBottom: props.error?.error ? '10px' : '0px',
						}}
					>
						{isEditing ? props.children : showValue()}
						{isEditing && props.error?.error && (
							<FormHelperText>{t(props.error?.errorText ?? 'generic error')}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);
		}

		return <>{content}</>;
	}
};
