import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as apiCurrenciesList from './externalApi/utils/apiCurrenciesList';
import * as apiEditCustomerPrices from './externalApi/products/apiEditCustomerPrices';
import * as apiDeleteCustomerPrices from './externalApi/products/apiDeleteCustomerPrices';
import * as apiEditCustomerTypePrices from './externalApi/products/apiEditCustomerTypePrices';
import * as apiDeleteCustomerTypePrices from './externalApi/products/apiDeleteCustomerTypePrices';
import * as apiEditBuyingPrices from './externalApi/products/apiEditBuyingPrices';
import * as apiDeleteBuyingPrices from './externalApi/products/apiDeleteBuyingPrices';

import * as api from './externalApi/common/apiCalls';

import {
	AffectedRowsResponse,
	BuyingPricesRequest,
	BuyingPricesResponse,
	CurrenciesResponse,
	GenericIdRequest,
	GenericIdsRequest,
	PriceCustomersRequest,
	PriceTypesRequest,
	ProductRequest,
	ProductResponse,
	ProductsResponse,
	VariantRequest,
	VariantResponse,
} from 'common';

export interface ProductsState {
	products: ProductsResponse;
	op_status: NetworkOperationStatus;
	currencies: CurrenciesResponse;
}

const initialState: ProductsState = {
	products: [],
	op_status: 'idle',
	currencies: [],
};

export const doListProducts = api.apiList<ProductsResponse>('products/list', 'inventory/products');

export const doCreateProduct = api.apiCreate<ProductRequest, ProductResponse>(
	'products/create',
	'inventory/products',
);

export const doEditProduct = api.apiEdit<ProductResponse, AffectedRowsResponse>(
	'products/edit',
	'inventory/products',
);

export const doDeleteProduct = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'products/delete',
	'inventory/products',
);

export const doCreateProductVariant = api.apiCreate<VariantRequest, VariantResponse>(
	'variants/create',
	'inventory/products/:productId/variants',
);

export const doEditProductVariant = api.apiEdit<VariantResponse, AffectedRowsResponse>(
	'variants/edit',
	'inventory/variants',
);

export const doDeleteVariant = api.apiDelete<GenericIdRequest, AffectedRowsResponse>(
	'variants/delete',
	'inventory/variants',
);

export const doListCurrencies = createAsyncThunk(
	'products/currencies',
	async (): Promise<CurrenciesResponse> => await apiCurrenciesList.execute(),
);

export const doEditCustomerPrices = createAsyncThunk(
	'products/variants/customerprices/edit',
	async (params: PriceCustomersRequest): Promise<AffectedRowsResponse> => {
		return await apiEditCustomerPrices.execute(params);
	},
);

export const doDeleteCustomerPrices = createAsyncThunk(
	'products/variants/customerprices/delete',
	async (params: GenericIdsRequest): Promise<AffectedRowsResponse> => {
		return await apiDeleteCustomerPrices.execute(params);
	},
);

export const doEditCustomerTypePrices = createAsyncThunk(
	'products/variants/customertypeprices/edit',
	async (params: PriceTypesRequest): Promise<AffectedRowsResponse> => {
		return await apiEditCustomerTypePrices.execute(params);
	},
);

export const doDeleteCustomerTypePrices = createAsyncThunk(
	'products/variants/customertypeprices/delete',
	async (params: GenericIdsRequest): Promise<AffectedRowsResponse> => {
		return await apiDeleteCustomerTypePrices.execute(params);
	},
);

export const doEditBuyingPrices = createAsyncThunk(
	'products/variants/buyingprices/edit',
	async (params: BuyingPricesRequest): Promise<BuyingPricesResponse> => {
		return await apiEditBuyingPrices.execute(params);
	},
);

export const doDeleteBuyingPrices = createAsyncThunk(
	'products/variants/buyingprices/',
	async (params: GenericIdsRequest): Promise<apiDeleteBuyingPrices.Response> => {
		return await apiDeleteBuyingPrices.execute(params);
	},
);

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		clearProducts(state) {
			state.products = [];
		},
	},
	extraReducers: (builder) => {
		// #region List products
		builder.addCase(doListProducts.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListProducts.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.products = action.payload;
		});
		builder.addCase(doListProducts.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion

		// #region Create Product
		builder.addCase(doCreateProduct.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateProduct.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doCreateProduct.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion

		// #region Edit Product
		builder.addCase(doEditProduct.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditProduct.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditProduct.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion

		// #region Delete Product
		builder.addCase(doDeleteProduct.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteProduct.fulfilled, (state) => {
			state.op_status = 'succeeded';
			// state.products.push(action.payload);
		});
		builder.addCase(doDeleteProduct.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion

		// #region Create Variant
		builder.addCase(doCreateProductVariant.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doCreateProductVariant.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			console.log(action);
		});
		builder.addCase(doCreateProductVariant.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion

		// #region List currencies
		builder.addCase(doListCurrencies.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doListCurrencies.fulfilled, (state, action) => {
			state.op_status = 'succeeded';
			state.currencies = action.payload;
		});
		builder.addCase(doListCurrencies.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Edit Customer Prices
		builder.addCase(doEditCustomerPrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditCustomerPrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditCustomerPrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Delete Customer Prices
		builder.addCase(doDeleteCustomerPrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteCustomerPrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteCustomerPrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Edit Customer Type Prices
		builder.addCase(doEditCustomerTypePrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditCustomerTypePrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditCustomerTypePrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Delete Customer Type Prices
		builder.addCase(doDeleteCustomerTypePrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteCustomerTypePrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteCustomerTypePrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Edit Buying Prices
		builder.addCase(doEditBuyingPrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doEditBuyingPrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doEditBuyingPrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
		// #region Delete Buying Prices
		builder.addCase(doDeleteBuyingPrices.pending, (state) => {
			state.op_status = 'pending';
		});
		builder.addCase(doDeleteBuyingPrices.fulfilled, (state) => {
			state.op_status = 'succeeded';
		});
		builder.addCase(doDeleteBuyingPrices.rejected, (state) => {
			state.op_status = 'failed';
		});
		// #endregion
	},
});

export const { clearProducts } = productsSlice.actions;
export default productsSlice.reducer;
