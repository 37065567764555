import { useTask } from '@contexts/index';
import { SelectVesselFieldValue } from '@contexts/taskContext/config/selectvesselFieldConfig';
import { TextField } from '@mui/material';

interface Props {
	taskFieldId: number;
	values: SelectVesselFieldValue;
	setValues: (values: SelectVesselFieldValue) => void;
}

export const CarrierCodeField = ({ taskFieldId, values, setValues }: Props) => {
	const { setTaskFieldValues } = useTask();

	return (
		<TextField
			fullWidth
			size='small'
			value={values?.code ?? ''}
			onChange={(e) => {
				const newValues = { ...values, code: e.target.value };
				setTaskFieldValues(taskFieldId, [newValues]);
				setValues(newValues);
			}}
		/>
	);
};
