import { FadeWrapper } from '@components/common/FadeWrapper';
import useProducts from '@hooks/useProducts';
import { Box, Grid, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { AppFunction, OrderProductResponse, ProductType } from 'common';
import { useOrderEditor } from '@contexts/index';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Cancel, Note, Numbers } from '@mui/icons-material';
import ProductAutocomplete from '@components/common/inputs/products/ProductAutocomplete';
import { useTranslation } from 'react-i18next';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	orderProduct: OrderProductResponse;
	subOrderCode: string;
	iterator: number;
}

export const ManualSuborderProduct = ({
	orderProduct,
	subOrderCode,
	iterator,
}: Props): JSX.Element => {
	const { variantList } = useProducts();
	const { changeOrderProduct: changeVariant, deleteVariant } = useOrderEditor();
	const { t } = useTranslation();
	const theme = useTheme();

	const variant = variantList.find((v) => v.id === orderProduct.variantId);

	return (
		<FadeWrapper>
			<Grid container item display='flex'>
				<Grid item alignContent='center' p={1}>
					<Box
						borderRadius='50%'
						bgcolor={theme.palette.primary.main}
						width='1.5rem'
						height='1.5rem'
						color={theme.palette.primary.contrastText}
						display='flex'
						alignItems='center'
						justifyContent='center'
					>
						{iterator + 1}
					</Box>
				</Grid>
				<Grid container item flexGrow={1} flexBasis={0} spacing={2}>
					<Grid item xs={12}>
						<ProductAutocomplete
							onChange={(value) => {
								changeVariant(
									{
										variantId: value?.id ?? -1,
										unit: 'pieces',
										weight: value?.weight ?? 0,
									},
									subOrderCode,
									iterator,
								);
							}}
							value={orderProduct?.variantId ?? null}
							allowedProductTypes={[ProductType.product, ProductType.packaging]}
						/>
					</Grid>
					<Grid item xs={variant?.variable ? 4 : 6}>
						<TextField
							size='small'
							type='number'
							label={t(`${AppFunction.Product}.quantity`)}
							variant='outlined'
							fullWidth
							value={orderProduct.orderQty ?? undefined}
							InputProps={{
								inputProps: { min: 0 },
								startAdornment: (
									<InputAdornment position='start'>
										<Numbers />
									</InputAdornment>
								),
							}}
							onChange={(e) => {
								changeVariant(
									{
										orderQty: parseInt(e.target.value) ?? 0,
									},
									subOrderCode,
									iterator,
								);
							}}
						/>
					</Grid>
					{variant?.variable && (
						<InputGridField
							width={4}
							type={ComponentType.TextField}
							tools={[
								{
									title: 'average',
									onClick: () => {
										const newWeight = variant?.weight
											? variant.weight * (orderProduct?.orderQty ?? 1)
											: 0;
										changeVariant(
											{
												weight: newWeight,
												pickedWeight: newWeight,
											},
											subOrderCode,
											iterator,
										);
									},
								},
							]}
						>
							<TextField
								size='small'
								fullWidth
								label={t(`${AppFunction.Product}.weight`)}
								type='number'
								variant='outlined'
								value={orderProduct.pickedWeight !== 0 ? orderProduct.pickedWeight ?? '' : ''}
								InputProps={{
									inputProps: { min: 0 },
								}}
								onChange={(e) => {
									changeVariant(
										{
											pickedWeight: +e.target.value,
											weight: +e.target.value,
										},
										subOrderCode,
										iterator,
									);
								}}
							/>
						</InputGridField>
					)}
					<Grid item xs={variant?.variable ? 4 : 6}>
						<DatePicker
							label={t(`${AppFunction.Inventory}.expiration`)}
							value={moment(orderProduct.expiresAt).startOf('day')}
							disablePast
							onChange={(newValue) => {
								changeVariant(
									{
										expiresAt: newValue?.startOf('day').toDate(),
									},
									subOrderCode,
									iterator,
								);
							}}
							renderInput={(params) => (
								<TextField {...params} size='small' variant='outlined' fullWidth />
							)}
							PopperProps={{ style: { zIndex: 9999 } }}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							size='small'
							fullWidth
							label={t(`${AppFunction.Inventory}.price`)}
							variant='outlined'
							value={orderProduct.actualPrice ?? ''}
							onChange={(e) => {
								changeVariant(
									{
										actualPrice: +e.target.value,
									},
									subOrderCode,
									iterator,
								);
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							size='small'
							fullWidth
							label={t(`${AppFunction.Inventory}.importTaxes`)}
							variant='outlined'
							value={orderProduct.importTaxes ?? ''}
							onChange={(e) => {
								changeVariant(
									{
										importTaxes: +e.target.value,
									},
									subOrderCode,
									iterator,
								);
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							size='small'
							fullWidth
							label={t(`${AppFunction.Inventory}.transportFees`)}
							variant='outlined'
							value={orderProduct.transportFees ?? ''}
							onChange={(e) => {
								changeVariant(
									{
										transportFees: +e.target.value,
									},
									subOrderCode,
									iterator,
								);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							size='small'
							fullWidth
							label={t('general.notes')}
							variant='outlined'
							value={orderProduct.notes}
							onChange={(e) => {
								changeVariant(
									{
										notes: e.target.value,
									},
									subOrderCode,
									iterator,
								);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Note />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid item alignContent='center' pl={1}>
					<IconButton
						onClick={() => {
							deleteVariant(iterator, subOrderCode);
						}}
						sx={{ color: theme.palette.error.main }}
					>
						<Cancel />
					</IconButton>
				</Grid>
			</Grid>
		</FadeWrapper>
	);
};
