import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import _ from 'lodash';

import {
	AffectedRowsResponse,
	GenericIdRequest,
	ProductCreateSchema,
	ProductRequest,
	ProductResponse,
	ProductsResponse,
	VariantCreateSchema,
	VariantRequest,
	VariantResponse,
	VariantSchema,
	VariantsResponse,
} from 'common';
import {
	doCreateProduct,
	doCreateProductVariant,
	doDeleteProduct,
	doDeleteVariant,
	doEditProduct,
	doEditProductVariant,
} from '../store/products';
import { PayloadAction } from '@reduxjs/toolkit';

function useProducts() {
	const dispatch = useAppDispatch();
	const productsSlice = useAppSelector((state) => state.products.products);
	const currencies = useAppSelector((state) => state.products.currencies);
	const [productList, setProductList] = useState<ProductsResponse>(productsSlice);

	const getVariants = (): VariantsResponse => {
		const variants: VariantsResponse = [];
		productsSlice.map((p) => {
			if (p.variants) {
				variants.push(...p.variants);
			}
		});
		return variants;
	};

	const [variantList, setVariantList] = useState<VariantsResponse>(getVariants());

	useEffect(() => {
		setProductList((currentList) => {
			if (!_.isEqual(currentList, productsSlice)) {
				setVariantList(getVariants());
				return productsSlice;
			}
			return currentList;
		});
	}, [productsSlice]);

	const getProductById = (id: number): ProductResponse | undefined => {
		return productList.find((pr) => pr.id === id);
	};

	const getVariantById = (id: number): VariantResponse | undefined => {
		const product = productList.filter(
			(pr) => pr.variants?.find((vr) => vr.id === id) !== undefined,
		);
		return product[0].variants?.find((vr) => vr.id === id);
	};

	const createProduct = async (
		data: ProductRequest | ProductResponse,
	): Promise<ProductResponse> => {
		const parsedData = ProductCreateSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid data');
		} else {
			const response = (await dispatch(doCreateProduct(data))) as PayloadAction<ProductResponse>;
			if (response.type === 'products/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Error creating product');
		}
	};

	const editProduct = async (data: ProductResponse): Promise<AffectedRowsResponse> => {
		const response = (await dispatch(doEditProduct(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'products/edit/fulfilled') {
			return response.payload;
		}
		return Promise.reject('Error editing product');
	};

	const deleteProduct = async (data: GenericIdRequest): Promise<AffectedRowsResponse | null> => {
		const response = (await dispatch(doDeleteProduct(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'products/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	const createVariant = async (
		data: VariantRequest | VariantResponse,
	): Promise<VariantResponse> => {
		console.log(data);
		const parsedData = VariantCreateSchema.safeParse(data);
		if (!parsedData.success) {
			console.log(parsedData.error.format());
			return Promise.reject('Invalid data');
		} else {
			const response = (await dispatch(
				doCreateProductVariant(parsedData.data),
			)) as PayloadAction<VariantResponse>;
			if (response.type === 'variants/create/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Error creating variant');
		}
	};

	const editVariant = async (data: VariantResponse): Promise<AffectedRowsResponse> => {
		const parsedData = VariantSchema.safeParse(data);
		if (!parsedData.success) {
			return Promise.reject('Invalid data');
		} else {
			const response = (await dispatch(
				doEditProductVariant(parsedData.data),
			)) as PayloadAction<AffectedRowsResponse>;
			if (response.type === 'variants/edit/fulfilled') {
				return response.payload;
			}
			return Promise.reject('Error editing variant');
		}
	};

	const deleteVariant = async (data: GenericIdRequest): Promise<AffectedRowsResponse | null> => {
		const response = (await dispatch(doDeleteVariant(data))) as PayloadAction<AffectedRowsResponse>;
		if (response.type === 'products/variants/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	const getTotalWeight = (
		variantId: number,
		amount: number,
	): { weight: number; estimated: boolean } | null => {
		const variant = variantList.find((variant) => variant.id === variantId);
		if (!variant) {
			return null;
		}

		const totalWeight = variant.weight * amount;
		const estimated = variant.variable;

		return { weight: totalWeight, estimated };
	};

	return {
		productList,
		variantList,
		currencies,
		getProductById,
		getVariantById,
		createProduct,
		editProduct,
		deleteProduct,
		createVariant,
		editVariant,
		deleteVariant,
		getTotalWeight,
	};
}

export default useProducts;
