import { AnimatedIconButton } from '@components/common/AnimatedIconButton';
import { DeliveryArea } from '@components/delivery/DeliveryArea';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useCarriers from '@hooks/useCarriers';
import { Add } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { AppFunction, CarrierDeliveryAreasResponse, CarrierResponse } from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DeliveryAreas = () => {
	const { entityId } = useGenericForm<CarrierResponse>();
	const { deliveryAreaList } = useCarriers();
	const { t } = useTranslation();
	const [isCreating, setCreating] = useState(false);

	const filterDeliveryAreas = (
		areas: CarrierDeliveryAreasResponse,
	): CarrierDeliveryAreasResponse => {
		return areas.filter((a) => a.carrierId === entityId);
	};
	const [deliveryAreas, setDeliveryAreas] = useState<CarrierDeliveryAreasResponse>(
		filterDeliveryAreas(deliveryAreaList),
	);

	useEffect(() => {
		const newAreas = filterDeliveryAreas(deliveryAreaList);
		setDeliveryAreas((oldAreas) => {
			if (!_.isEqual(oldAreas, newAreas)) {
				return newAreas;
			}
			return oldAreas;
		});
	}, [deliveryAreaList]);

	const ngAreas = deliveryAreas.flatMap((a) => a.regions?.flatMap((r) => r.id) ?? []);

	return (
		<Grid container rowGap={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='carrier.areas' translate size='small' />
			</Grid>
			<Grid item xs={12}>
				{deliveryAreas.map((area, it) => (
					<DeliveryArea key={it} area={area} ngAreas={ngAreas} />
				))}
				{isCreating ? (
					<DeliveryArea
						area={{ id: -1, carrierId: entityId, regions: [] }}
						isCreating
						onCancelCreate={() => setCreating(false)}
						ngAreas={ngAreas}
					/>
				) : (
					<Box
						sx={{
							marginTop: '0.5rem',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<AnimatedIconButton
							text={t('operations.createItem', {
								item: t(`appFunctions.${AppFunction.CarrierDeliveryArea}`),
							})}
							icon={<Add />}
							onClick={() => setCreating(true)}
							colorType='info'
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};
