import {
	Box,
	FormControl,
	Grid,
	GridSize,
	InputLabel,
	InputProps,
	SxProps,
	Theme,
	Tooltip,
	useTheme,
} from '@mui/material';
import { ReactElement, cloneElement, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ComponentType, inputGridFieldConfigMap, noSxComponents } from './config/Index';
import { useLayout } from '@contexts/index';
import { GridFieldTooltip, TooltipTool } from './GridFieldTooltip';
import { deepmerge } from '@mui/utils';

interface MuiLabelData {
	label: string;
	labelId: string;
}

interface Props {
	width?: GridSize;
	type: ComponentType;
	children: ReactElement<{
		disabled?: boolean;
		required?: boolean;
		sx?: SxProps<Theme>;
		inputProps?: InputProps;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value?: any;
		renderTags?: (
			value: unknown[],
			getTagProps: (index: number) => Record<string, unknown>,
		) => ReactElement;
	}>;
	muiLabel?: MuiLabelData;
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: number;
	sx?: SxProps<Theme>;
	tools?: TooltipTool[];
	tooltipWhenDisabled?: string;
}

export const InputGridField = ({
	width,
	type,
	children,
	muiLabel,
	flexGrow,
	flexShrink,
	flexBasis,
	sx,
	tools,
	tooltipWhenDisabled,
	...props
}: Props) => {
	const { showHelp } = useLayout();
	const theme = useTheme();

	const gridRef = useRef<HTMLDivElement>(null);

	let rect: DOMRect | null = null;
	if (gridRef.current) {
		rect = gridRef.current.getBoundingClientRect();
	}

	const isSimulatedDisabled = children.props.disabled;
	const isRequired = children.props.required;

	/** Fetch the conditional sx from {@link inputGridFieldConfigMap} */
	const conditionalSx = inputGridFieldConfigMap[type] || {};
	/** Get the default sx from children props */
	const defaultSx: SxProps<Theme> = children.props.sx || {};
	/** Conditional sx is applied only if we are simulating the disable prop from MUI */
	const appliedConditionalSx: SxProps<Theme> = isSimulatedDisabled ? conditionalSx : {};

	const newSx: SxProps<Theme> = deepmerge(defaultSx, appliedConditionalSx);

	const childWithProps = useMemo(() => {
		let element;
		if (noSxComponents.includes(type)) {
			element = cloneElement(children, {
				...props,
				disabled: undefined,
			});
		} else {
			element = cloneElement(children, {
				...props,
				disabled: isSimulatedDisabled,
				sx: {
					...newSx,
					...(tooltipWhenDisabled && { cursor: 'help' }),
				},
			});
		}
		if (isSimulatedDisabled && tooltipWhenDisabled) {
			element = (
				<Tooltip
					title={tooltipWhenDisabled}
					arrow
					placement='top'
					componentsProps={{
						popper: {
							sx: {
								zIndex: '9999 !important',
							},
						},
					}}
				>
					{element}
				</Tooltip>
			);
		}
		return <div style={{ width: '100%' }}>{element}</div>;
	}, [noSxComponents, children, props, newSx, type]);

	const childWithToolbar = useMemo(() => {
		return (
			<GridFieldTooltip
				tools={tools}
				rect={rect}
				componentName={type}
				disabled={isSimulatedDisabled}
			>
				{childWithProps}
			</GridFieldTooltip>
		);
	}, [tools, childWithProps]);

	if (
		isSimulatedDisabled &&
		(children.props.value === null ||
			children.props.value === undefined ||
			children.props.value === '' ||
			children.props.value.length === 0)
	) {
		return <Grid item xs={width} />;
	}

	if (width || flexGrow || flexShrink || flexBasis || sx) {
		return (
			<Grid
				item
				xs={!flexGrow && width}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '1rem',
					...sx,
				}}
				flexShrink={flexShrink}
				flexGrow={flexGrow}
				flexBasis={flexBasis}
				ref={gridRef}
			>
				{showHelp && (
					<Box
						sx={{
							width: '100%',
							backgroundColor: theme.palette.info.main,
							color: theme.palette.info.contrastText,
							border: `1px solid ${theme.palette.info.dark}`,
							borderRadius: '0.5rem',
							padding: '0.5rem',
						}}
					>
						help test
					</Box>
				)}
				<FormControl fullWidth>
					{type === ComponentType.DatePicker ? (
						<Box sx={newSx}>
							{muiLabel && (
								<InputLabel id={muiLabel.labelId} required={isRequired}>
									{muiLabel.label}
								</InputLabel>
							)}
							{tools && tools.length > 0 ? childWithToolbar : childWithProps}
						</Box>
					) : (
						<>
							{muiLabel && (
								<InputLabel required={isRequired} id={muiLabel.labelId}>
									{muiLabel.label}
								</InputLabel>
							)}
							{tools && tools.length > 0 ? childWithToolbar : childWithProps}
						</>
					)}
				</FormControl>
			</Grid>
		);
	} else {
		// TODO: this doesn't have the tooltips, are we ok with that?
		return (
			<FormControl fullWidth>
				{muiLabel && (
					<InputLabel id={muiLabel.labelId} required={isRequired}>
						{muiLabel.label}
					</InputLabel>
				)}
				{childWithProps}
			</FormControl>
		);
	}
};

InputGridField.propTypes = {
	children: PropTypes.element.isRequired,
};
