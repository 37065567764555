import useProducts from '@hooks/useProducts';
import { CloseOrderValue } from '../../config';
import { Grid } from '@mui/material';
import { InternalOrderSubType, OrderProductResponse } from 'common';
import { ArrowRight } from '@mui/icons-material';
import { CloseOrderFieldListItem } from '../../shared/CloseOrderFieldListItem';
import useOrders from '@hooks/useOrders';
import { QuantityChip } from '@components/common/chips/QuantityChip';
import { WeightChip } from '@components/common/chips/WeightChip';

/**
 * Props for the StandardItem component.
 * @interface Props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 */
interface Props {
	content: OrderProductResponse;
	value: CloseOrderValue;
	iterator: number;
	subType?: InternalOrderSubType | null;
}

/**
 * Internal Order standard item component.
 * Used when a more specific item component is not avaialble.
 * @param {Props} props - StandardItem component props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 * @returns {JSX.Element | null} StandardItem component
 */
export const StandardItem = ({ content, value, iterator, subType }: Props): JSX.Element | null => {
	const { variantList } = useProducts();
	const { findContentById, orderList } = useOrders();
	const variant = variantList.find((v) => v.id === content.variantId);
	const prevOrderProduct = findContentById(content.sourceId ? +content.sourceId : -1);
	const prevOrder = orderList.find((o) => o.id === prevOrderProduct?.orderId);

	if (!variant || !prevOrderProduct || !prevOrder) {
		return null;
	}

	return (
		<CloseOrderFieldListItem content={content} value={value} iterator={iterator} subType={subType}>
			<Grid
				item
				display='flex'
				alignItems='center'
				justifyContent='center'
				gap={1}
				flexGrow={1}
				flexBasis={0}
				p={1}
				sx={{
					// backgroundColor: 'grey[200]',
					borderRadius: '1rem',
				}}
			>
				<QuantityChip quantity={prevOrderProduct.arrivedQty} />
				<WeightChip {...prevOrderProduct} />
			</Grid>
			<Grid item>
				<ArrowRight />
			</Grid>
			<Grid
				item
				display='flex'
				alignItems='center'
				justifyContent='center'
				gap={1}
				flexGrow={1}
				flexBasis={0}
				p={1}
				sx={{
					// backgroundColor: 'grey[200]',
					borderRadius: '1rem',
				}}
			>
				<QuantityChip quantity={content.orderQty} />
				<WeightChip
					weight={content.pickedWeight ?? prevOrderProduct.weight}
					estimated={content.estimated}
				/>
			</Grid>
		</CloseOrderFieldListItem>
	);
};
