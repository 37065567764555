import { AppFunction, OrderType } from 'common';
import { OrderEditorProvider } from '../../contexts/orderEditorContext/OrderEditorContext';
import { OrderEditor } from '../../components/orders/OrderEditor';
import { useEffect } from 'react';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { Add, Sell } from '@mui/icons-material';
import { CombinedIcon } from '@components/common/CombinedIcon';
import { useTranslation } from 'react-i18next';
import useAppFunctions from '@hooks/useAppFunctions';

interface Props {
	orderType: OrderType;
	renderType?: 'standalone' | 'popup';
	popupId?: string;
	forceId?: number;
}

export const OrderCreate = ({ orderType, popupId, forceId, renderType = 'standalone' }: Props) => {
	const { setBreadCrumbs } = useLayout();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		if (renderType === 'popup') return;
		setBreadCrumbs([
			{
				label: t('appFunctions.order'),
				icon: <Sell />,
				route: '/orders',
			},
			{
				label: t('operations.createItem', {
					item: t(`order.orderTypes.${orderType.toLowerCase()}`),
				}),
				icon: (
					<CombinedIcon main={getIcon(AppFunction.Order, orderType) ?? <Sell />} sub={<Add />} />
				),
			},
		]);
	}, [orderType]);

	return (
		<OrderEditorProvider orderType={orderType} renderType={renderType} popupId={popupId}>
			<OrderEditor inventoryProductId={forceId} />
		</OrderEditorProvider>
	);
};
