import { Container, ImageList, Paper } from '@mui/material';
import useImages from '../../hooks/useImages';

import { ImageListComponent } from '../../components/images/ImageListComponent';
import { useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import { useEffect } from 'react';
import { Settings } from '@mui/icons-material';
import { AppFunction } from 'common';

const appFunction = AppFunction.Image;

export const ImagesList = () => {
	const { imageList } = useImages();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	return (
		<Container maxWidth={false}>
			<Paper sx={{ padding: '20px', borderRadius: '20px' }}>
				<ImageList variant='masonry' cols={8} gap={8}>
					{imageList.map((i, it) => (
						<ImageListComponent key={it} image={i} />
					))}
				</ImageList>
			</Paper>
		</Container>
	);
};
