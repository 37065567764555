import { settingsDb as db } from '../';
import { ListSettingsRequest, ListSettingsResponse } from '../interfaces/listSettings';
import { Settings } from '../../app';
import { defaultSettings } from '../defaultSettings';
import {
	DrawerStateSettingsRequest,
	DrawerStateSettingsResponse,
	LanguageSettingsRequest,
	LanguageSettingsResponse,
	TextSizeSettingsRequest,
	TextSizeSettingsResponse,
} from '../interfaces/generalSettings';

export const apiSettingsList = async (userId: number): Promise<Settings> => {
	const settings = await db.settings.get(userId);
	if (!settings) {
		throw new Error('Settings not found');
	}

	return settings;
};

export const apiListSettingsEdit = async (
	data: ListSettingsRequest,
): Promise<ListSettingsResponse> => {
	try {
		const settingsArray = await db.settings.toArray();
		const settings = settingsArray.find((settings) => settings.userId === data.userId);

		if (!settings) {
			return {
				success: false,
				functionName: data.functionName,
				message: 'Could not find a record for the selected user',
			};
		}

		const functionIdentifier = `${data.functionName}${
			data.tableVariant ? `_${data.tableVariant}` : ''
		}`;

		settings.listSettings[functionIdentifier] = {
			showFilters:
				data.showFilters !== undefined
					? data.showFilters
					: settings.listSettings?.[functionIdentifier]?.showFilters,
			columns:
				data.columns ??
				settings.listSettings?.[functionIdentifier]?.columns ??
				defaultSettings(data.userId).listSettings?.[functionIdentifier]?.columns,
			expanded:
				data.expanded ??
				settings.listSettings?.[functionIdentifier]?.expanded ??
				defaultSettings(data.userId).listSettings?.[functionIdentifier]?.expanded,
			rowsPerPage:
				data.rowsPerPage ??
				settings.listSettings?.[functionIdentifier]?.rowsPerPage ??
				defaultSettings(data.userId).listSettings?.[functionIdentifier]?.rowsPerPage,
		};

		await db.settings.put(settings, settings.userId);

		return {
			success: true,
			functionName: data.functionName,
			tableVariant: data.tableVariant,
			updatedListSettings: settings.listSettings[functionIdentifier].columns,
			showFilters: settings.listSettings?.[functionIdentifier]?.showFilters,
			expanded: settings.listSettings?.[functionIdentifier]?.expanded,
			rowsPerPage: settings.listSettings?.[functionIdentifier]?.rowsPerPage,
		};
	} catch (error) {
		console.error(error);
		return {
			success: false,
			functionName: data.functionName,
			tableVariant: data.tableVariant,
			message: 'An error occurred while updating settings',
		};
	}
};

export const apiLanguageSettingsEdit = async (
	data: LanguageSettingsRequest,
): Promise<LanguageSettingsResponse> => {
	try {
		const settingsArray = await db.settings.toArray();
		const settings = settingsArray.find((settings) => settings.userId === data.userId);

		if (!settings) {
			return {
				success: false,
				message: 'Could not find a record for the selected user',
			};
		}

		settings.language = data.language;

		await db.settings.put(settings, settings.userId);

		return {
			success: true,
			language: data.language,
		};
	} catch (error) {
		return { success: false, message: 'An error occurred while updating settings' };
	}
};

export const apiTextSizeSettingsEdit = async (
	data: TextSizeSettingsRequest,
): Promise<TextSizeSettingsResponse> => {
	try {
		const settingsArray = await db.settings.toArray();
		const settings = settingsArray.find((settings) => settings.userId === data.userId);

		if (!settings) {
			return {
				success: false,
				message: 'Could not find a record for the selected user',
			};
		}

		settings.textSize = data.textSize;

		await db.settings.put(settings, settings.userId);

		return {
			success: true,
			textSize: data.textSize,
		};
	} catch (error) {
		return { success: false, message: 'An error occurred while updating settings' };
	}
};

export const apiDrawerStateSettingsEdit = async (
	data: DrawerStateSettingsRequest,
): Promise<DrawerStateSettingsResponse> => {
	try {
		const settingsArray = await db.settings.toArray();
		const settings = settingsArray.find((settings) => settings.userId === data.userId);

		if (!settings) {
			return {
				success: false,
				message: 'Could not find a record for the selected user',
			};
		}

		settings.showDrawer = data.showDrawer;

		await db.settings.put(settings, settings.userId);

		return {
			success: true,
			showDrawer: data.showDrawer,
		};
	} catch (error) {
		return { success: false, message: 'An error occurred while updating settings' };
	}
};
