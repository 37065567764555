import { Translation, useTranslation } from 'react-i18next';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Chip,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from '@mui/material';
import { EditShowGridField } from '../common/EditShowGridField';
import { PresetInputLangGroup } from '../PresetInputLangGroup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
// import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
// import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
// import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
// import FaxTwoToneIcon from '@mui/icons-material/FaxTwoTone';
import CurrencyYenTwoToneIcon from '@mui/icons-material/CurrencyYenTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

import styles from './CustomerBranch.module.css';
import {
	AddressRequest,
	AppFunction,
	BranchType,
	CustomerBranchCreateSchema,
	CustomerBranchResponse,
	CustomerBranchSchema,
	LocaleEntries,
	TransactionType,
	TranslationTypes,
} from 'common';
import { Controller, useForm } from 'react-hook-form';
import { AddressList } from '../addresses/AddressList';
import useCustomers from '../../hooks/useCustomers';
import processLocaleEntries from '../../helpers/processLocaleEntries';
import useFeedbacks from '../../hooks/useFeedbacks';
import { ScopedLayer } from '../scopedlayer/ScopedLayer';
import { FocusedElementType } from '../../store/app';
import { ContextualDropdownMenu } from '../ContextualDropdownMenu';
import { DeleteItemDialog } from '../DeleteItemDialog';
import { ScopedComponent } from '../scopedlayer/ScopedComponent';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { Cancel, Check } from '@mui/icons-material';
import { AddressComponent } from '../addresses/AddressComponent';
import useAddresses from '../../hooks/useAddresses';
import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { ComponentType } from '@components/common/InputGridField/config/Index';

interface Props {
	index: number;
	branchData: CustomerBranchResponse;
	branchTypeOptions?: BranchType[];
	transactionTypeOptions?: TransactionType[];
	expandedId?: number | null;
	view?: boolean;
	onExpand?: (id: number | null) => void;
}

export const CustomerBranch = ({
	index,
	branchData,
	branchTypeOptions,
	transactionTypeOptions,
	expandedId,
	view,
	onExpand,
}: Props) => {
	const { t } = useTranslation();
	const { createCustomerBranch, editCustomerBranch, deleteCustomerBranch } = useCustomers();
	const { createAddress } = useAddresses();
	const { showSnackBar } = useFeedbacks();
	const { createEmptyAddress } = useAddresses();

	const [isEditing, setEditing] = useState(branchData.id === -1 ?? false);
	const [expanded, setExpanded] = useState(branchData.id === -1 ?? false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [branchTypeName, setBranchTypeName] = useState(
		branchData.type.charAt(0).toUpperCase() + branchData.type.slice(1),
	);
	const [cachedData, setCachedData] = useState<CustomerBranchResponse>(branchData);
	const [addressData, setAddressData] = useState<AddressRequest | null>(null);

	const canEdit = view ? !view : true;

	const { control, handleSubmit, watch, reset } = useForm<CustomerBranchResponse>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: branchData,
	});

	const branchType = watch('type');

	const onSubmit = useCallback(
		async (data: CustomerBranchResponse) => {
			if (data.id === -1) {
				const createData = CustomerBranchCreateSchema.safeParse(data);
				if (createData.success) {
					const res = (await createCustomerBranch(
						createData.data,
					)) as PayloadAction<CustomerBranchResponse>;
					if (res.type === 'customers/branches/create/fulfilled') {
						if (addressData) {
							const addressCreateData = { ...addressData, addressableId: res.payload.id };
							const addressRes = await createAddress(addressCreateData);
							if (addressRes.type === 'addresses/create/fulfilled') {
								showSnackBar({ message: t('addresses.createSuccess'), severity: 'success' });
							} else {
								showSnackBar({ message: t('addresses.createSuccess'), severity: 'error' });
							}
						}
						showSnackBar({ message: t('customers.createSuccess'), severity: 'success' });
					} else {
						showSnackBar({ message: t('customers.createFail'), severity: 'error' });
					}
				}
			} else {
				const newStrings = processLocaleEntries(data.strings ?? [], branchData.strings ?? []);
				const editData = CustomerBranchSchema.safeParse({ ...data, strings: newStrings });
				if (editData.success) {
					const res = await editCustomerBranch(editData.data);
					if (res.type === 'customers/branches/edit/fulfilled') {
						showSnackBar('Branch edited successfully');
					} else {
						showSnackBar('Could not edit branch');
					}
				}
			}
			setEditing(false);
		},
		[branchData, addressData],
	);

	useEffect(() => {
		const newName = branchType.charAt(0).toUpperCase() + branchType.slice(1);
		setBranchTypeName(newName);
	}, [branchType]);

	useEffect(() => {
		if (!_.isEqual(branchData, cachedData)) {
			setCachedData(branchData);
			reset(branchData);
		}
	}, [branchData]);

	useEffect(() => {
		if (expandedId !== branchData.id) {
			setExpanded(false);
		}
	}, [expandedId]);

	useEffect(() => {
		if (!isEditing) {
			if (branchData.id === -1) {
				onExpand?.(null);
			}
		}
		setExpanded(isEditing);
	}, [isEditing]);

	const onCloseAccordion = (e: SyntheticEvent, expanded: boolean) => {
		setExpanded(expanded);
		onExpand?.(branchData.id);
		if (!expanded && isEditing) {
			setEditing(false);
		}
		setDeleteDialogOpen(false);
	};

	const renderI18NField = (string: string) => {
		return (
			<div className={styles['translation-wrapper']}>
				<Translation>
					{(t) => {
						const value = t(string, { ns: 'locale', default: '' });
						if (string !== value) {
							return <p>{value}</p>;
						} else {
							return <p></p>;
						}
					}}
				</Translation>
			</div>
		);
	};

	const renderTransactionType = (type: TransactionType) => {
		switch (type) {
			case TransactionType.canBeBilled:
				return (
					<Tooltip title={t('customers.branches.Billing')}>
						<Box>
							<CurrencyYenTwoToneIcon />
						</Box>
					</Tooltip>
				);
			case TransactionType.canOrder:
				return (
					<Tooltip title={t('customers.branches.Receiving')}>
						<Box>
							<ShoppingCartTwoToneIcon />
						</Box>
					</Tooltip>
				);
			case TransactionType.both:
				return (
					<Tooltip title={t('customers.branches.Both')}>
						<Box>
							<CurrencyYenTwoToneIcon sx={{ marginRight: '6px' }} />
							<ShoppingCartTwoToneIcon />
						</Box>
					</Tooltip>
				);
			default:
				break;
		}
	};

	function BranchTransactionType() {
		const typeSelection = (
			<Controller
				name='type'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={6}
						muiLabel={{
							label: t('customers.branches.type'),
							labelId: 'branch-type',
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='branch-type'
							id='customers.branches'
							defaultValue={BranchType.subCustomer}
							fullWidth
							size='small'
							disabled={!isEditing}
							label={t('customers.branches.type')}
							{...field}
							value={field.value}
							onChange={(ev) => field.onChange(ev.target.value as BranchType)}
						>
							{(Object.keys(BranchType) as (keyof typeof BranchType)[]).map((key, index) => {
								return (
									<MenuItem value={BranchType[key]} key={index}>
										{t(`customers.branches.${BranchType[key]}`)}
									</MenuItem>
								);
							})}
						</Select>
					</InputGridField>
				)}
			/>
		);

		const transactionTypeSelection = (
			<Controller
				name='transactionType'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={6}
						muiLabel={{
							label: t('customers.branches.transactiontype'),
							labelId: 'branch-transaction-type',
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='branch-transaction-type'
							id='customers.branches'
							defaultValue={TransactionType.both}
							fullWidth
							size='small'
							disabled={!isEditing}
							label={t('customers.branches.transactiontype')}
							{...field}
							value={field.value}
							onChange={(ev) => field.onChange(ev.target.value as TransactionType)}
						>
							{(Object.keys(TransactionType) as (keyof typeof TransactionType)[]).map(
								(key, index) => {
									return (
										<MenuItem value={TransactionType[key]} key={index}>
											{t(`customers.branches.${TransactionType[key]}`)}
										</MenuItem>
									);
								},
							)}
						</Select>
					</InputGridField>
				)}
			/>
		);

		let content: JSX.Element = (
			<>
				<Grid item xs={12}>
					<TabInnerTitle title='customer.branchType' translate size='small' />
				</Grid>
				{(!branchTypeOptions || (branchTypeOptions && branchTypeOptions.length > 1)) &&
					typeSelection}
				{(!transactionTypeOptions ||
					(transactionTypeOptions && transactionTypeOptions.length > 1)) &&
					transactionTypeSelection}
			</>
		);

		if (
			branchTypeOptions &&
			branchTypeOptions.length <= 1 &&
			transactionTypeOptions &&
			transactionTypeOptions.length <= 1
		) {
			content = <></>;
		}

		return content;
	}

	function AccordionHeader() {
		const icon = () => {
			switch (branchData.type) {
				case BranchType.branch:
					return <StoreTwoToneIcon />;
				case BranchType.hq:
					return <BusinessTwoToneIcon />;
				case BranchType.subCustomer:
					return <GroupsIcon />;
			}
		};

		const chip = (
			<Chip
				icon={icon()}
				label={t(`customers.branches.${branchData.type}.item`)}
				style={
					branchData.type == BranchType.hq
						? { backgroundColor: 'rgb(255, 202, 202)' }
						: { backgroundColor: 'rgb(255, 213, 169)' }
				}
			/>
		);

		return (
			<Grid container spacing={2}>
				<Grid item xs={2}>
					{chip}
				</Grid>
				<Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
					{renderI18NField(`customerBranch-${branchData.id}-name`)}
				</Grid>
				<Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{renderTransactionType(branchData.transactionType)}
				</Grid>
			</Grid>
		);
	}

	const renderDialog = () => {
		// leaving show extended dialog here
		// we use it when you are about to delete a person which is in use somewhere
		const onDeletePerson = async (showExtended: boolean) => {
			const idToDelete = branchData.id;
			if (idToDelete) {
				await deleteCustomerBranch({ id: idToDelete });
				showSnackBar('deleted');
			}
		};

		// Translate
		const text = 'delete person';

		return (
			<DeleteItemDialog
				title='delete person'
				text={text}
				dialogOpen={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
				onAccept={() => onDeletePerson(false)}
			/>
		);
	};

	const onAddressDataChanged = useCallback((data: AddressRequest) => {
		setAddressData(data);
	}, []);

	const newAddress = useMemo(
		() => (
			<AddressComponent
				address={createEmptyAddress(AppFunction.CustomerBranch, branchData.id)}
				canEdit={true}
				expandedId={-1}
				singleMode
				onSingleModeEdit={onAddressDataChanged}
			/>
		),
		[],
	);

	return (
		<ScopedComponent
			data={{ id: branchData.id, type: FocusedElementType.branch, zIndex: 200 }}
			active={isEditing}
			title={branchData.id === -1 ? 'operations.create' : 'operations.edit'}
			translateTitle
		>
			{renderDialog()}
			{branchData.id !== -1 && <ScopedLayer scopes={[FocusedElementType.address]} />}
			<Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
				<Grid item xs={!isEditing && canEdit ? 11 : 12}>
					<Accordion key={index} onChange={onCloseAccordion} expanded={expanded}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${index}a-content`}
							id={`panel${index}a-header`}
							sx={{ display: 'flex', justifyItems: 'center' }}
						>
							{isEditing ? <Box>{t(`operations.edit${branchTypeName}`)}</Box> : <AccordionHeader />}
						</AccordionSummary>
						<AccordionDetails sx={{ borderRadius: '15px' }}>
							<Grid container spacing={2}>
								<BranchTransactionType />
								<Grid item xs={12}>
									<Typography
										variant='overline'
										display='block'
										gutterBottom
										sx={{ fontSize: '0.9em' }}
									>
										<span style={{ backgroundColor: 'white' }}>
											{t(`customers.branches.${branchData.type}.name`)}
										</span>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name='strings'
										control={control}
										render={({ field }) => (
											<PresetInputLangGroup
												useReactHookForm
												showOnlyCurrent
												type={TranslationTypes.name}
												strings={field.value ?? []}
												onChange={(data: LocaleEntries) => {
													field.onChange(data);
												}}
												disableEdit={!isEditing}
											/>
										)}
									/>
								</Grid>
								{branchData.id !== -1 ? (
									<Grid item xs={12}>
										<AddressList
											entityId={branchData.id}
											entityType={AppFunction.CustomerBranch}
											hideBox
											maxAddresses={1}
											isEditing={isEditing}
										/>
									</Grid>
								) : (
									<Grid item xs={12} sx={{ position: 'relative' }}>
										{newAddress}
									</Grid>
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{isEditing && (
					<>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.create'
								translate
								colorType='success'
								icon={<Check />}
								onClick={handleSubmit(onSubmit)}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
							<AnimatedIconButton
								text='operations.cancel'
								translate
								colorType='error'
								icon={<Cancel />}
								onClick={() => setEditing(false)}
							/>
						</Grid>
					</>
				)}
				{!isEditing && canEdit && (
					<Grid
						item
						xs={1}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<ContextualDropdownMenu
							onEdit={() => setEditing(true)}
							onDelete={() => setDeleteDialogOpen(true)}
						/>
					</Grid>
				)}
			</Grid>
		</ScopedComponent>
	);
};
