import useProducts from '@hooks/useProducts';
import { CloseOrderValue } from '../../config';
import useImages from '@hooks/useImages';
import { Avatar, Box, Grid, ListItem, ListItemAvatar } from '@mui/material';
import useLocale from '@hooks/useLocale';
import { AppFunction, InternalOrderSubType, OrderProductResponse, TranslationTypes } from 'common';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMemo } from 'react';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip/ExpiresAtChip';

/**
 * Props for the StandardItem component.
 * @interface Props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 */
interface Props {
	content: OrderProductResponse;
	value: CloseOrderValue;
	iterator: number;
	subType?: InternalOrderSubType | null;
}

/**
 * Internal Order standard item component.
 * Used when a more specific item component is not avaialble.
 * @param {Props} props - StandardItem component props
 * @property {OrderProductResponse} content - The order product content.
 * @property {CloseOrderValue} value - The close order value.
 * @property {number} iterator - The iterator number.
 * @returns {JSX.Element | null} StandardItem component
 */
export const OutboundCloseOrderFieldItem = ({
	content,
	value,
	iterator,
	subType,
}: Props): JSX.Element | null => {
	const { variantList, productList } = useProducts();
	const { imageList } = useImages();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const variant = variantList.find((v) => v.id === content.variantId);

	if (!variant || !value.oldValues.expiresAt) {
		return null;
	}

	const imageUrl = useMemo(() => {
		let image = imageList.find((image) => image.id === variant?.images?.[0]?.id ?? -1);
		if (!image) {
			image = imageList.find(
				(image) =>
					image.id === productList.find((p) => p.id === variant.productId)?.images?.[0]?.id ?? -1,
			);
		}

		return image?.url ?? '';
	}, [content.variantId, variantList, productList, imageList]);

	const jstDate = moment(value.oldValues.expiresAt).utcOffset('Asia/Tokyo').format('YYYY/MM/DD');

	return (
		<ListItem
			sx={{
				margin: '1rem 0 1rem 0',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderRadius: '1rem',
				transition: 'all 0.2s ease-in-out',
				boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
				'&:hover': {
					boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
				},
				'&:before': {
					content: `"${(iterator ?? 0) + 1}"`,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					fontSize: '0.8rem',
					position: 'absolute',
					top: 'calc(50% - 0.6rem)',
					backgroundColor: 'black',
					color: 'white',
					left: '-1.6rem',
					width: '1.2rem',
					height: '1.2rem',
					zIndex: 0,
					borderRadius: '1rem',
					opacity: 0.4,
				},
			}}
		>
			<ListItemAvatar>
				<Avatar
					src={imageUrl}
					imgProps={{
						style: {
							objectFit: 'cover',
						},
					}}
				/>
			</ListItemAvatar>
			<Grid container display='flex'>
				<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
					<div>[{variant?.sku}]</div>
					<div>
						{getTranslatedString(
							AppFunction.Product,
							variant.productId ?? -1,
							TranslationTypes.name,
						)}
					</div>
					<div>
						{getTranslatedString(
							AppFunction.Variant,
							content.variantId ?? -1,
							TranslationTypes.name,
						)}
					</div>
				</Grid>
				<Grid item xs={3} display='flex' alignItems='center' justifyContent='center' gap={1}>
					<Box>
						{content.arrivedQty ?? content.orderQty}
						{variant.purchaseUnitWeight === 0
							? t(`${AppFunction.Product}.units.pieces`, { count: content.orderQty })
							: t(`${AppFunction.Product}.units.grams`)}
					</Box>
					{content.pickedWeight && (
						<>
							<Box>{'/'}</Box>
							<Box
								sx={{
									position: 'relative',
									'&:after': content.estimated
										? {
												content: '"*"',
												position: 'absolute',
												top: 0,
												right: '-0.5rem',
												fontSize: '0.8rem',
										  }
										: {},
								}}
							>
								{content.pickedWeight}
								{t(`${AppFunction.Product}.units.grams`)}
							</Box>
						</>
					)}
					{/* {subType && subType === InternalOrderSubType.beforeCut && (
						<Box>al macello</Box>
					)}
					{subType && subType === InternalOrderSubType.afterCut && (
						<Box>dopo il macello</Box>
					)} */}
				</Grid>
				<Grid
					item
					flexGrow={1}
					flexBasis={0}
					display='flex'
					alignItems='center'
					justifyContent='right'
				>
					<ExpiresAtChip {...content} />
				</Grid>
				{content.notes && (
					<Grid item xs={12}>
						{content.notes}
					</Grid>
				)}
			</Grid>
		</ListItem>
	);
};
