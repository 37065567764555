import { ModifySubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { Box, Stack } from '@mui/material';
import { InboundModifySuborderProduct } from './InboundModifySuborderProduct';
import { InboundModifySuborderContainerHeader } from './InboundModifySuborderContainerHeader';

export const InboundModifySuborderContainer = ({
	subOrder,
	field,
}: ModifySubOrderProductsContainerProps) => {
	if (!subOrder || subOrder === undefined) {
		return null;
	}

	return (
		<Box>
			<InboundModifySuborderContainerHeader subOrder={subOrder} />
			<Stack direction='column' spacing={1}>
				{subOrder.content?.map((child, index) => {
					return <InboundModifySuborderProduct key={index} orderProduct={child} field={field} />;
				})}
			</Stack>
		</Box>
	);
};
