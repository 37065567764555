import { GeneralInfo } from '@components/forms/merchants/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import useMerchants from '@hooks/useMerchants';
import { Settings } from '@mui/icons-material';
import { AppFunction, MerchantResponse, MerchantSchema, MerchantType } from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

export const MerchantForm = ({ forcedId, formAction }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { merchantList, createMerchant, editMerchant } = useMerchants();

	const [formValues, setFormValues] = useState<MerchantResponse>({} as MerchantResponse);
	const [id, setId] = useState<number>(-1);

	const setValues = (newId: number) => {
		const merchant = merchantList.find((c) => c.id === newId);
		if (merchant) {
			setFormValues(merchant);
		}
	};

	useEffect(() => {
		if (routeId !== id && routeId !== -1) {
			setId(routeId);
			setValues(routeId);
		}
	}, [routeId]);

	useEffect(() => {
		if (id !== routeId && routeId !== -1) {
			setValues(id);
		}
	}, [id]);

	useEffect(() => {
		if (forcedId) {
			setId(forcedId);
			setValues(forcedId);
		}
	}, [forcedId]);

	useEffect(() => {
		const newValues = merchantList.find((c) => c.id === id);
		const parsedNewValues = MerchantSchema.safeParse(newValues);
		if (!parsedNewValues.success) {
			return;
		}
		if (parsedNewValues.data && !_.isEqual(parsedNewValues.data, formValues)) {
			setFormValues(parsedNewValues.data);
		}
	}, [merchantList]);

	return (
		<FormContextProvider
			appFunction={AppFunction.Merchant}
			createFunction={createMerchant}
			editFunction={editMerchant}
			renderType={forcedId ? FormRenderType.popup : FormRenderType.standalone}
			entityId={forcedId ?? routeId}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			validationSchema={MerchantSchema}
			defaultValues={{ id: -1, type: MerchantType.both } as MerchantResponse}
			customAfterCreateLink='merchant/:id/edit'
			useFab
			sideFabs={[
				FormSidefabType.Image,
				FormSidefabType.Info,
				FormSidefabType.Address,
				FormSidefabType.Notes,
			]}
		>
			<TabbedElement title='merchant.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
		</FormContextProvider>
	);
};
