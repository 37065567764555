import { OrderDirectionType, ShippingType, StorageType } from 'common';

export type OrderElementReturnType =
	| boolean
	| ShippingType[]
	| StorageType[]
	| OrderDirectionType[]
	| null;

/**
 * The `OrderElementReturnItem` class is used to represent and check the return value of {@link OrderElementConfigItem}.
 * It offers methods to verify if the contained value matches one of the expected types from {@link OrderElementReturnType}.
 *
 * NOTE: This class's type safety relies on the correct implementation of its type-checking methods, such as
 * {@link isBool}, {@link isValueOfShippingTypeArray}, and {@link isValueOfStorageTypeArray}. These methods only return `true`
 * if the value conforms to the expected types defined in {@link OrderElementReturnType}. Users should ensure that these checks
 * are accurately implemented for reliable type verification.
 */
export class OrderElementReturnItem {
	private _value: OrderElementReturnType;

	public get value(): OrderElementReturnType {
		return this._value;
	}

	constructor(value: OrderElementReturnType) {
		this._value = value;
	}

	isBool = (): this is { _value: boolean } => {
		return typeof this._value === 'boolean';
	};

	isValueOfShippingTypeArray(): this is { _value: ShippingType[] } {
		// return Array.isArray(this._value) && this._value.every(val => /* logic to verify ShippingType */);
		return Array.isArray(this._value);
	}

	isValueOfStorageTypeArray(): this is { _value: StorageType[] } {
		return Array.isArray(this._value);
	}

	isValueOfOrderDirectionTypeArray(): this is { _value: OrderDirectionType[] } {
		return Array.isArray(this._value);
	}

	isValueOfType<T extends OrderElementReturnType>(): this is { value: T } {
		if (this.isValueOfShippingTypeArray()) {
			return true;
		}
		if (this.isValueOfStorageTypeArray()) {
			return true;
		}
		if (this.isBool()) {
			return true;
		}
		if (this.isValueOfOrderDirectionTypeArray()) {
			return true;
		}
		return false;
	}
}
