import { EditShowGridField } from '@components/common/EditShowGridField';
import {
	DirectionType,
	createAdornment,
} from '@components/taskworkflow/chain/DestinationTypeSelector';
import { useChainArchetypeEditor } from '@contexts/index';
import useGroups from '@hooks/useGroups';
import useLocale from '@hooks/useLocale';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AppFunction, OrderDirectionType, TranslationTypes } from 'common';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
	directionType: DirectionType;
}

export const EntityGroupSelector = ({ directionType }: Props) => {
	const { allowedFromTypes, allowedToTypes, triggerControl, setTriggerValue, getTriggerValues } =
		useChainArchetypeEditor();
	const { groupList } = useGroups();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const currentId = directionType === 'from' ? getTriggerValues().fromId : getTriggerValues().toId;
	const currentType =
		directionType === 'from' ? getTriggerValues().fromType : getTriggerValues().toType;

	const currentSubType =
		directionType === 'from' ? getTriggerValues().fromSubType : getTriggerValues().toSubType;

	if (
		(currentType !== OrderDirectionType.entityGroup && currentId !== -1) ||
		(currentSubType !== null && currentSubType !== -1)
	) {
		return null;
	}
	const allowedDestinationTypes = directionType === 'from' ? allowedFromTypes : allowedToTypes;

	return (
		<Controller
			name={directionType === 'from' ? 'fromId' : 'toId'}
			control={triggerControl}
			render={({ field }) => (
				<EditShowGridField isEditing={true} width={12}>
					<InputLabel id='group-select-label'>
						{t(`appFunctions.${AppFunction.EntityGroup}`)}
					</InputLabel>
					<Select
						id='group-select'
						fullWidth
						label={t(`appFunctions.${AppFunction.EntityGroup}`)}
						labelId='group-select-label'
						value={
							groupList.some(
								(g) =>
									g.type.toString() === allowedDestinationTypes[0].toString() &&
									g.id === field.value,
							)
								? field.value
								: -1
						}
						size='small'
						onChange={(e) => {
							const groupId = e.target.value as number;
							if (!groupId || isNaN(groupId)) {
								return;
							}
							if (groupId === -1) {
								setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							} else {
								console.log('set trigger value to group');
								setTriggerValue(
									directionType === 'from' ? 'fromType' : 'toType',
									OrderDirectionType.entityGroup,
								);
							}
							field.onChange(groupId);
						}}
						endAdornment={createAdornment(field.value ?? -1, () => {
							setTriggerValue(directionType === 'from' ? 'fromType' : 'toType', undefined);
							field.onChange(-1);
						})}
					>
						<MenuItem
							value={-1}
							sx={{
								backgroundColor: 'rgba(255, 0, 0, 0.2)',
							}}
						>
							{t('common.none')}
						</MenuItem>
						{groupList
							.filter((g) => g.type === allowedDestinationTypes[0].toString())
							.map((group) => (
								<MenuItem key={`group_${group.id}`} value={group.id}>
									{getTranslatedString(AppFunction.EntityGroup, group.id, TranslationTypes.name)}
								</MenuItem>
							))}
					</Select>
				</EditShowGridField>
			)}
		/>
	);
};
