import { Button } from '@mui/material';
import { useTask, useLayout } from '@contexts/index';
import { AppFunction, TaskStatus } from 'common';
import { useTranslation } from 'react-i18next';

interface Props {
	popupId?: string;
}

export const SaveTaskStubButton = ({ popupId }: Props) => {
	const { taskState, saveTaskStub } = useTask();
	const { popPopup } = useLayout();
	const { t } = useTranslation();

	return (
		<div
			style={{
				padding: '1rem',
			}}
		>
			{(taskState === TaskStatus.inprogress || taskState === TaskStatus.stub) && (
				<Button
					fullWidth
					onClick={() => {
						saveTaskStub();
						popupId && popPopup(popupId);
					}}
					variant='contained'
				>
					{t(`${AppFunction.Task}.saveStub`)}
				</Button>
			)}
		</div>
	);
};
