import API from '../index';
// import { AxiosResponse } from 'axios';

const prefix = '/api/v1/';

export const execute = async <Req extends Record<string, unknown>, Res>(
	params: Req,
	// url: string
): Promise<string> => {
	console.log(params);
	const response = await API.post(`${prefix}outputs/pdf`, params, {
		responseType: 'blob',
	});

	if (response.status < 200 || response.status >= 300) {
		throw new Error(response.data as unknown as string);
	}

	// console.log(response);
	const url = URL.createObjectURL(response.data);

	return url;
};
