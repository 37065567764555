import { z } from 'zod';
import { TaskApiActionData, TaskFieldConfig, parseValuesToString } from '../taskFieldConfig';
import { doSetTaskValues } from 'store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import { TaskFieldValueResponse, TaskFieldValuesResponse } from 'common';

const SelectVesselFieldValueSchema = z.object({
	code: z.nullable(z.string()),
	id: z.nullable(z.number()),
});
const SelectVesselFieldValuesSchema = z.array(SelectVesselFieldValueSchema);

export type SelectVesselFieldValue = z.infer<typeof SelectVesselFieldValueSchema>;
export type SelectVesselFieldValues = z.infer<typeof SelectVesselFieldValuesSchema>;

/**
 * Saves task values to the server
 * @param {TaskApiActionData} data
 * @returns {Promise<void>}
 */
async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(SelectVesselFieldValuesSchema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
}

export const selectvesselFieldConfig: TaskFieldConfig = {
	stringToValues: (value: string) => [value],
	valuesToString: (values: unknown[]) => parseValuesToString(SelectVesselFieldValuesSchema, values),
	validateStructure: (values: unknown[]) => {
		const parsed = SelectVesselFieldValuesSchema.safeParse(values);
		if (parsed.success) {
			return parsed.data.every(
				(value) =>
					(value.code !== null && value.code !== undefined && value.code !== '') ||
					value.id !== null ||
					value.id !== undefined,
			);
		} else {
			return false;
		}
	},
	executeApiAction: async (data: TaskApiActionData) => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
	saveStub: async (data: TaskApiActionData) => {
		await saveTaskValues(data);
		return Promise.resolve();
	},
};
