import { createSlice } from '@reduxjs/toolkit';
import { NetworkOperationStatus } from '.';

import * as api from './externalApi/common/apiCalls';

import {
	AffectedRowsResponse,
	EntityImageResponse,
	EntityImagesRequest,
	EntityImagesResponse,
	GenericIdsRequest,
	ImageRequest,
	ImageResponse,
	ImagesResponse,
} from 'common';

export interface ImagesState {
	opStatus: NetworkOperationStatus;
	images: ImagesResponse;
}

const initialState: ImagesState = {
	opStatus: 'idle',
	images: [],
};

export const doListImages = api.apiList<ImagesResponse>('images/list', 'images/');

export const doEditImage = api.apiEdit<ImageResponse, AffectedRowsResponse>(
	'images/edit',
	'images/',
);

export const doDeleteImages = api.apiBulkDeleteIds<GenericIdsRequest, AffectedRowsResponse>(
	'images/delete',
	'images/',
);

export const doListEntityImageLinks = api.apiList<EntityImagesResponse>(
	'images/entityimage/list',
	'images/entityimage',
);

export const doCreateEntityImageLinks = api.apiBulkCreate<
	EntityImagesRequest,
	AffectedRowsResponse
>('images/entityimage/create', 'images/entityimages');

export const doEditEntityImageLink = api.apiEdit<EntityImageResponse, AffectedRowsResponse>(
	'images/entityimage/edit',
	'images/entityimage',
);

export const doDeleteEntityImageLinks = api.apiBulkDelete<
	EntityImagesRequest,
	AffectedRowsResponse
>('images/entityimages/delete', 'images/entityimages/delete');

export const imagesSlice = createSlice({
	name: 'images',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(doListImages.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doListImages.fulfilled, (state, action) => {
			state.images = action.payload;
			state.opStatus = 'succeeded';
		});
		builder.addCase(doListImages.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doEditImage.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doEditImage.fulfilled, (state) => {
			state.opStatus = 'succeeded';
		});
		builder.addCase(doEditImage.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doDeleteImages.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doDeleteImages.fulfilled, (state) => {
			state.opStatus = 'succeeded';
		});
		builder.addCase(doDeleteImages.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doCreateEntityImageLinks.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doCreateEntityImageLinks.fulfilled, (state) => {
			state.opStatus = 'succeeded';
		});
		builder.addCase(doCreateEntityImageLinks.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doEditEntityImageLink.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doEditEntityImageLink.fulfilled, (state) => {
			state.opStatus = 'succeeded';
		});
		builder.addCase(doEditEntityImageLink.rejected, (state) => {
			state.opStatus = 'failed';
		});
		builder.addCase(doDeleteEntityImageLinks.pending, (state) => {
			state.opStatus = 'pending';
		});
		builder.addCase(doDeleteEntityImageLinks.fulfilled, (state) => {
			state.opStatus = 'succeeded';
		});
		builder.addCase(doDeleteEntityImageLinks.rejected, (state) => {
			state.opStatus = 'failed';
		});
	},
});

export default imagesSlice.reducer;
