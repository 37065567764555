import { useEffect, useState } from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import _ from 'lodash';

import { Box } from '@mui/material';
import { ChildSettings, NewInputGroupItem } from './NewInputGroupItem';
import { AnimatedIconButton } from './common/AnimatedIconButton';

import { Add } from '@mui/icons-material';

export interface InputGroupProps {
	options: Option[];
	optionsLabel: string;
	childrenSettings: ChildSettings[];
	multiLine?: boolean;
	autocomplete?: boolean;
	translate?: TranslationSettings;
	view?: boolean;
	onRetrieveValues: (options: Option[]) => void;
}

export interface Option {
	selectionId: number;
	selectionName: string;
	optionId?: number;
	inputs?: NewChild[];
}

export interface NewChild {
	defaultValue?: boolean | number | string;
	value?: boolean | number | string;
}

export interface TranslationSettings {
	category: string;
	type: string;
}

export const NewInputGroup = (props: InputGroupProps) => {
	const { t } = useTranslation();
	const canEdit = props.view ? !props.view : true;

	const [cachedOptions, setCachedOptions] = useState<Option[]>(props.options);
	const [inputRows, setInputRows] = useState<Option[]>([]);
	const [availableOptions, setAvailableOptions] = useState<number[]>([]);
	const [translatedOptions, setTranslatedOptions] = useState<Option[]>([]);

	useEffect(() => {
		setCachedOptions(_.cloneDeep(props.options));
	}, [props.options]);

	useEffect(() => {
		if (props.translate) {
			const tempOptions: Option[] = [];
			cachedOptions.forEach((val) => tempOptions.push(Object.assign({}, val)));

			tempOptions.map((x) => {
				if (props.translate) {
					x.selectionName = t(
						`${props.translate.category}-${x.selectionName}-${props.translate.type}`,
						{ ns: 'locale', default: '' },
					);
				}
			});

			setTranslatedOptions(
				tempOptions.sort((a, b) => a.selectionName.localeCompare(b.selectionName)),
			);
		} else {
			setTranslatedOptions(cachedOptions);
		}
	}, [cachedOptions, getI18n().language]);

	useEffect(() => {
		const existingRows: Option[] = [];
		translatedOptions.map((option) => {
			let push = false;
			if (option.inputs) {
				option.inputs.map((input, it) => {
					if (input.defaultValue && !props.childrenSettings[it].readonly) {
						push = true;
					}
				});
				if (push) existingRows.push(option);
			}
		});

		const selectedOptions = existingRows.map((x) => x.selectionId);
		const newAvailableOptions = translatedOptions
			.map((op) => op.selectionId)
			.filter((x) => !selectedOptions.includes(x));
		setAvailableOptions(newAvailableOptions);

		setInputRows(existingRows);
	}, [translatedOptions]);

	useEffect(() => {
		props.onRetrieveValues(inputRows);
	}, [inputRows]);

	const onAddRow = () => {
		if (availableOptions.length === 1) {
			setInputRows((currentRows) => {
				const option = translatedOptions.find((x) => x.selectionId === availableOptions[0]);
				return [
					...currentRows,
					{
						selectionId: availableOptions[0],
						selectionName: option?.selectionName ?? '',
						inputs: option?.inputs,
					},
				];
			});
			setAvailableOptions([]);
		} else {
			const newOption = availableOptions[0];
			const option = translatedOptions.find((x) => x.selectionId === newOption);
			setInputRows((currentRows) => {
				return [
					...currentRows,
					{
						selectionId: newOption,
						selectionName: option?.selectionName ?? '',
						inputs: option?.inputs,
					},
				];
			});
			setAvailableOptions(availableOptions.slice(1));
		}
	};

	const onDeleteRow = (id: number) => {
		if (id === -1) return;
		// If I don't do this here somehow props.options gets modified
		// couldn't find the reason why. smh.
		// BECAUSE YOU ARE A FUCKING MORON
		// we should be set now
		const test = inputRows[id];
		if (test.inputs) {
			test.inputs.map((x) => {
				x.value = x.defaultValue;
			});
		}
		const oldValue = inputRows[id].selectionId;
		const rowsInstance = Array.from(inputRows);
		if (!availableOptions.includes(oldValue)) {
			setAvailableOptions((currentAvailableOptions) => {
				return [...currentAvailableOptions, oldValue];
			});
		}
		rowsInstance.splice(id, 1);
		setInputRows(rowsInstance);
	};

	const onSelect = (selectedId: string, rowId: number) => {
		const oldOption = translatedOptions.find((x) => x.selectionId === +selectedId);
		const rowsInstance = Array.from(inputRows);
		let oldId = -1;
		if (oldOption) {
			oldId = rowsInstance[rowId].selectionId;
			rowsInstance[rowId] = {
				...rowsInstance[rowId],
				selectionId: +selectedId,
				selectionName: oldOption.selectionName,
				inputs: oldOption.inputs,
			};
		}
		setInputRows(rowsInstance);

		const indexToDelete = availableOptions.findIndex((x) => x === +selectedId);
		if (indexToDelete !== -1) {
			const optionsInstance = Array.from(availableOptions);
			optionsInstance.splice(indexToDelete, 1);
			if (oldId !== -1 && !availableOptions.includes(oldId)) {
				setAvailableOptions([...optionsInstance, oldId]);
			} else if (oldId === -1) {
				setAvailableOptions(optionsInstance);
			}
		}
	};

	const onValueChange = (rowId: number, inputId: number, inputValue: string) => {
		const inputRowIdx = inputRows.findIndex((x) => x.selectionId === rowId);

		const inputRowsInstance = Array.from(inputRows);
		const inputs = inputRowsInstance[inputRowIdx].inputs;

		if (inputs != undefined && inputs.length > inputId) {
			inputs[inputId] = { ...inputs[inputId], value: inputValue };
			setInputRows(inputRowsInstance);
		}
	};

	return (
		<Box>
			{inputRows.map((row, it) => {
				return (
					<NewInputGroupItem
						key={it}
						onDelete={onDeleteRow}
						id={it}
						options={translatedOptions}
						multiLine={props.multiLine}
						availableOptions={availableOptions}
						optionsLabel={props.optionsLabel}
						onSelect={onSelect}
						selectedOption={row}
						onValueChange={onValueChange}
						childrenSettings={props.childrenSettings}
						autocomplete={props.autocomplete}
						view={props.view}
					/>
				);
			})}
			{canEdit && inputRows.length >= 0 && availableOptions.length !== 0 && (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<AnimatedIconButton
						text='operations.create'
						translate
						colorType='info'
						icon={<Add />}
						onClick={onAddRow}
						disabled={inputRows.filter((x) => x.selectionId === -1).length !== 0}
					/>
				</div>
			)}
		</Box>
	);
};
