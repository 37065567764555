import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerTypes from '../../hooks/useCustomerTypes';
import useLocale from '../../hooks/useLocale';

import { AppFunction, CustomerTypeResponse, GenericIdRequest, TranslationTypes } from 'common';

import { Column } from 'primereact/column';

import { Attribution, Settings } from '@mui/icons-material';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import { TableContextProvider, useFeedbacks } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import { TableBoolBody } from '@components/common/tablecells/TableBoolBody';

const appFunction = AppFunction.CustomerType;

export const CustomerTypesList = () => {
	const { t } = useTranslation();
	const { customerTypesList, deleteCustomerType } = useCustomerTypes();
	const { getTranslatedString } = useLocale();
	const { setBreadCrumbs } = useLayout();
	const { pushDialog } = useFeedbacks();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: 'breadCrumbs.customerTypes',
				icon: <Attribution />,
			},
		]);
	}, []);

	function callDeleteCustomerTypeDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.products_one', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteCustomerType(data).then((response) => {
							if (response) {
								console.log('deleted', data.id);
							} else {
								console.log('error');
							}
						});
					},
				},
			],
		});
	}

	return (
		<TableContextProvider
			pageIdentifier={AppFunction.CustomerType}
			values={customerTypesList}
			actions={[
				{ type: DropDownActionType.edit },
				{ type: DropDownActionType.details },
				{
					type: DropDownActionType.delete,
					action: (data: GenericIdRequest) => callDeleteCustomerTypeDialog(data),
				},
			]}
		>
			<Column
				field='name'
				header='name'
				body={(row) => getTranslatedString(appFunction, row.id, TranslationTypes.name)}
			/>
			<Column
				field='customerCodePrefix'
				header='codePrefix'
				body={(row: CustomerTypeResponse) => row.customerCodePrefix}
			/>
			<Column
				field='description'
				header='description'
				body={(row: CustomerTypeResponse) =>
					getTranslatedString(appFunction, row.id, TranslationTypes.description)
				}
			/>
			<Column
				field='canHaveParent'
				header='canHaveParent'
				body={(row: CustomerTypeResponse) => <TableBoolBody value={row.canHaveParent} />}
			/>
			<Column
				field='canHaveChildren'
				header='canHaveChildren'
				body={(row: CustomerTypeResponse) => <TableBoolBody value={row.canHaveChildren} />}
			/>
		</TableContextProvider>
	);
};
